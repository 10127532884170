import {useCallback, useState} from 'react';

export interface UseBooleanState {
  toggle: () => void;
  setTrue: () => void;
  setFalse: () => void;
  value: boolean;
}

export function useBoolean(initialState: boolean): UseBooleanState {
  const [bool, setBool] = useState<boolean>(initialState);

  return {
    toggle: useCallback(() => setBool(!bool), [bool]),
    setTrue: useCallback(() => setBool(true), []),
    setFalse: useCallback(() => setBool(false), []),
    value: bool,
  };
}
