import PropTypes from 'prop-types';
import React from 'react';
import $ from 'jquery';
import StudentReferrals from 'src/components/StudentReferrals';
import Modal from 'shared/components/modal';
import TextButton from 'src/components/TextButton';
import CopyableTextbox from 'shared/components/copyable-textbox';
import UserService from 'src/services/UserService';

class MultipleReferralModal extends React.Component {
  static propTypes = {
    addReferral: PropTypes.func.isRequired,
    dismissReferralsCta: PropTypes.func.isRequired,
    referrals: PropTypes.arrayOf(PropTypes.object),
    sendReferrals: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    updateReferral: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
  };

  state = {referralUrl: ''};

  componentDidMount() {
    UserService.getData(['referralUrl']).then(({referralUrl}) => this.setState({referralUrl}));
  }

  convertReferralsToObjects = () => {
    const {referrals, updateReferral} = this.props;
    const convertedReferrals = [];

    referrals.forEach((referral, i) => {
      convertedReferrals.push({
        contactInfo: {
          ...referral,
          onChange: updateReferral(i),
        },
      });
    });

    return convertedReferrals;
  };

  referralsValid() {
    const {referrals} = this.props;
    if (!referrals.some((referral) => referral.value)) {
      return false;
    }

    return this.props.referrals.every((referral) => !referral.value || !referral.invalid);
  }

  scrollToBottomOfReferrals() {
    const referralItemHeight = $('.StudentReferralItem').first().height();

    setTimeout(() => {
      $('.multiple-referral-modal-referrals').scrollTop(
        (referralItemHeight + 10) * (this.props.referrals.length + 1)
      );
    }, 500);
  }

  addReferralAndScroll = () => {
    this.props.addReferral();
    this.scrollToBottomOfReferrals();
  };

  dismissReferrals = () => this.props.dismissReferralsCta();

  render() {
    const {addReferral, show, referrals, amount} = this.props;

    if (!referrals) return null;

    return (
      <Modal show={show} onClose={this.dismissReferrals}>
        <Modal.TextHeader onClose={this.dismissReferrals}>
          Start earning right away!
        </Modal.TextHeader>
        <Modal.Body>
          <p>
            Invite your friends—you&apos;ll both earn a ${amount} referral micro-scholarship when
            they join RaiseMe (up to ${(amount * 10).toLocaleString()} maximum).
          </p>
          <p className="shared-font subheading bold">Email or mobile</p>
          <StudentReferrals
            referrals={this.convertReferralsToObjects()}
            addReferral={addReferral}
            minReferralsLength={3}
            placeholderText="Email or mobile"
            referralsEnabled
            showAddButton={false}
            showRemoveButton={false}
            title=""
          />
          <TextButton
            type="button"
            color="blue"
            onClick={this.addReferralAndScroll}
            buttonName="multiple-referral-modal add-more"
          >
            + Add more
          </TextButton>
          <p className="shared-font subheading bold">Or use invite link</p>
          <CopyableTextbox text={this.state.referralUrl} textAria="Invite link" />
        </Modal.Body>
        <Modal.Footer
          primaryButton={{
            text: 'Send Referrals',
            onClick: this.props.sendReferrals.bind(this, referrals),
            disabled: !this.referralsValid(),
          }}
          secondaryButton={{
            text: 'Skip',
            onClick: this.dismissReferrals,
            buttonName: 'multiple-referral-modal-skip',
          }}
        />
      </Modal>
    );
  }
}

export default MultipleReferralModal;
