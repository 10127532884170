import {collegeSearchReducer} from 'src/redux/collegeSearch/reducer';
import accountConversionReducer from 'high-school-student/account-conversion/reducers/account-conversion';
import disabilitiesReducer from 'src/redux/disabilities/reducer';
import admissionLikelihoodReducer from 'high-school-student/college-earnings/reducers/admission-likelihood';
import educatorConnectionsReducer from 'src/redux/educatorConnections/reducer';
import {
  RECEIVE_LETTERS,
  REMOVE_LETTERS,
} from 'high-school-student/financial-aid-award-letter/actions/letters';
import {onTrackReducer} from 'high-school-student/on-track/reducers/on-track';
import {studentNotificationBanner} from 'src/redux/studentNotificationBanner/reducer';
import {bulkEntryCoursesReducer} from 'portfolio/reducers/bulk-entry/courses';
import {referralsReducer} from 'portfolio/reducers/referrals';
import {recommendationsReducer} from 'recommendations/redux';
import {reducer as formReducer} from 'redux-form';
import notificationSettingsReducer from 'shared/account-settings/reducers/notification-settings';
import {
  CLEAR_COLLEGE,
  CLEAR_COLLEGES,
  RECEIVE_COLLEGE,
  RECEIVE_COLLEGES,
  UPDATE_COLLEGE,
} from 'shared/actions/college';
import {INITIALIZE_FILTERS} from 'shared/actions/filters';
import {START_LOADING, STOP_LOADING} from 'shared/actions/loading';
import {RECEIVE_PHONE_NUMBER} from 'shared/actions/verify-mobile';
import collegeApplicationStatusReducer from 'shared/college-application-status/reducers/college-application-status-reducer';
import collegeApplicationStatusesReducer from 'shared/college-application-status/reducers/college-application-statuses-reducer';
import followedCollegesReducer from 'shared/college-application-status/reducers/followed-colleges-reducer';
import {collegeEarningsReducer} from 'shared/college-earnings/reducers/college-earnings';
import {collegeProfileReducer} from 'shared/college-profile/reducers/college-profile';
import {startsWithAcademicInterestsReducer} from 'shared/college-profile/reducers/starts-with-academic-interests-reducer';
import {collegeSearchBarReducer} from 'shared/college-search-bar/reducers';
import collegeSuccessItemTypesReducer from 'shared/college-success/reducers/college-success-item-types';
import detailedOpportunityCardsReducer from 'shared/micro-scholarships/reducers/detailed-opportunity-cards';
import opportunityCardsReducer from 'shared/micro-scholarships/reducers/opportunity-cards';
import {missingHighSchoolModalReducer} from 'shared/missing-high-school-modal/reducers/missing-high-school-modal';
import {
  NO_REMAINING_MESSAGES,
  RECEIVE_SCHOLARSHIP_PROGRAM_SUBJECTS,
  REMAINING_MESSAGES,
} from 'shared/notification-center/actions/notification-messages';
import notificationCenterReducers from 'shared/notification-center/reducers';
import {RECEIVE_COLLEGE_INTERACTION_OPTIONS} from 'shared/portfolio/actions/college-events';
import {
  LOADING_FALSE,
  LOADING_TRUE,
  RECEIVE_PORTFOLIO,
  RESET_PORTFOLIO,
} from 'shared/portfolio/actions/portfolio';
import {
  activitiesReducer,
  activityDescriptionReducer,
  leadershipPositionsReducer,
} from 'shared/portfolio/reducers/activities';
import {collegeCoursesReducer} from 'shared/portfolio/reducers/college-courses';
import {collegeEventsReducer} from 'shared/portfolio/reducers/college-events';
import {collegeSuccessItemsReducer} from 'shared/portfolio/reducers/college-success-items';
import {communityServiceReducer} from 'shared/portfolio/reducers/community-service';
import {
  attendanceReducer,
  gradesReducer,
  preselectsReducer,
} from 'shared/portfolio/reducers/courses';
import {honorAwardsReducer} from 'shared/portfolio/reducers/honor-awards';
import {institutionsReducer, institutionsReducer2} from 'shared/portfolio/reducers/institutions';
import {multimediaReducer} from 'shared/portfolio/reducers/multimedia';
import studentReducer from 'shared/portfolio/reducers/student';
import {testScoresReducer} from 'shared/portfolio/reducers/test-scores';
import {experiencesReducer} from 'shared/portfolio/reducers/work-experiences';
import {accessibilityAnnouncementReducer} from 'shared/reducers/accessibility-announcement';
import {avatarReducer} from 'shared/reducers/avatar';
import {awardNotificationsReducer} from 'shared/reducers/award-notifications';
import {booleanReducer} from 'shared/reducers/boolean-reducer';
import {experimentsReducer} from 'shared/reducers/experiments';
import featureFlagsReducer from 'shared/reducers/feature-flags';
import {featureInteractionsReducer} from 'shared/reducers/feature-interactions';
import {filtersReducer} from 'shared/reducers/filters';
import {genericReducer} from 'shared/reducers/generic-reducer';
import {immutableBoolReducer} from 'shared/reducers/immutable-reducers';
import {loadingReducer} from 'shared/reducers/loading';
import {messagingReducer} from 'student-search/reducers/messaging';
import {modalReducer} from 'shared/reducers/modal';
import {notificationsReducer} from 'shared/reducers/notifications';
import {objectReducer} from 'shared/reducers/object-reducer';
import {overlayReducer} from 'shared/reducers/overlay';
import {popoversReducer} from 'shared/reducers/popovers';
import {userConnectionsReducer} from 'shared/reducers/user-connections';
import requestInstitutionModalReducer from 'shared/reducers/request-institution-modal';
import {listReducer} from 'src/redux/lists';
import {referralModalReducer} from 'src/redux/studentReferralModal/reducer';
import toolbarNotificationReducer from 'src/redux/toolbar-notification/reducer';
import checklistReducer from 'src/redux/checklist/reducer';
import checklistUserStatusReducer from 'src/redux/checklistUserStatus/reducer';

export const hsReducers = {
  accessibilityAnnouncement: accessibilityAnnouncementReducer,
  accountConversion: accountConversionReducer,
  accountSettingsLoading: booleanReducer({
    defaultState: true,
    falseType: STOP_LOADING,
    trueType: START_LOADING,
  }),
  activities: activitiesReducer,
  activityDescription: activityDescriptionReducer,
  activityModalLoading: booleanReducer({
    defaultState: true,
    falseType: STOP_LOADING,
    trueType: START_LOADING,
  }),
  admissionLikelihood: admissionLikelihoodReducer,
  attendance: attendanceReducer,
  avatar: avatarReducer,
  awardNotifications: awardNotificationsReducer,
  banners: studentNotificationBanner,
  bulkEntryCourses: bulkEntryCoursesReducer,
  checklist: checklistReducer,
  checklistUserStatus: checklistUserStatusReducer,
  college: objectReducer({
    actionProperty: 'college',
    emptyObjectAction: CLEAR_COLLEGE,
    mergeObjectAction: RECEIVE_COLLEGE,
  }),
  collegeApplicationStatus: collegeApplicationStatusReducer,
  collegeApplicationStatuses: collegeApplicationStatusesReducer,
  collegeCourses: collegeCoursesReducer,
  collegeEarnings: collegeEarningsReducer,
  collegeEvents: collegeEventsReducer,
  collegeInteractionOptions: genericReducer({
    actionProperty: 'collegeInteractionOptions',
    defaultState: {},
    setAction: RECEIVE_COLLEGE_INTERACTION_OPTIONS,
  }),
  collegeProfile: collegeProfileReducer,
  collegeSearch: collegeSearchReducer,
  collegeSearchBar: collegeSearchBarReducer,
  collegeSuccessItemTypes: collegeSuccessItemTypesReducer,
  collegeSuccessItems: collegeSuccessItemsReducer,
  colleges: objectReducer({
    actionProperty: 'colleges',
    emptyObjectAction: CLEAR_COLLEGES,
    mergeObjectAction: UPDATE_COLLEGE,
    replaceObjectAction: RECEIVE_COLLEGES,
  }),
  communityServiceEvents: communityServiceReducer,
  coursePreselects: preselectsReducer,
  detailedOpportunityCards: detailedOpportunityCardsReducer,
  disabilities: disabilitiesReducer,
  educatorConnections: educatorConnectionsReducer,
  experiments: experimentsReducer,
  featureFlags: featureFlagsReducer,
  featureInteractions: featureInteractionsReducer,
  filters: filtersReducer,
  filtersInitialized: booleanReducer({defaultState: false, trueType: INITIALIZE_FILTERS}),
  financialAidAwardLetters: objectReducer({
    actionProperty: 'letters',
    emptyObjectAction: REMOVE_LETTERS,
    mergeObjectAction: RECEIVE_LETTERS,
  }),
  followedColleges: followedCollegesReducer,
  form: formReducer,
  grades: gradesReducer,
  honorAwards: honorAwardsReducer,
  institutions: institutionsReducer,
  institutions2: institutionsReducer2,
  leadershipPositions: leadershipPositionsReducer,
  lists: listReducer,
  loadMoreNotificationMessages: booleanReducer({
    defaultState: true,
    falseType: NO_REMAINING_MESSAGES,
    trueType: REMAINING_MESSAGES,
  }),
  loading: booleanReducer({defaultState: true, falseType: LOADING_FALSE, trueType: LOADING_TRUE}),
  messaging: messagingReducer,
  microscholarshipsLoading: loadingReducer,
  missingHighSchoolModal: missingHighSchoolModalReducer,
  modal: modalReducer,
  multimedia: multimediaReducer,
  notificationSettings: notificationSettingsReducer,
  notifications: notificationsReducer,
  onTrack: onTrackReducer,
  opportunityCards: opportunityCardsReducer,
  overlay: overlayReducer,
  popovers: popoversReducer,
  portfolioEmpty: genericReducer({
    actionProperty: 'portfolioEmpty',
    defaultState: false,
    setAction: RECEIVE_PORTFOLIO,
  }),
  portfolioInitiated: booleanReducer({
    defaultState: false,
    falseType: RESET_PORTFOLIO,
    trueType: RECEIVE_PORTFOLIO,
  }),
  recommendations: recommendationsReducer,
  referralModal: referralModalReducer,
  referrals: referralsReducer,
  requestInstitutionModal: requestInstitutionModalReducer,
  // @ts-expect-error: scholarshipProgramSubjects also defined on
  // notificationCenterReducers so this value will always be overriden
  scholarshipProgramSubjects: objectReducer({
    mergeObjectAction: RECEIVE_SCHOLARSHIP_PROGRAM_SUBJECTS,
  }),
  showClubClaimVerificationModal: genericReducer({
    actionProperty: 'showClubClaimVerificationModal',
    defaultState: false,
    setAction: RECEIVE_PORTFOLIO,
  }),
  showHSAccountConversionModal: genericReducer({
    actionProperty: 'showHSAccountConversionModal',
    defaultState: false,
    setAction: RECEIVE_PORTFOLIO,
  }),
  startsWithAcademicInterests: startsWithAcademicInterestsReducer,
  student: studentReducer,
  testScores: testScoresReducer,
  toolbarNotification: toolbarNotificationReducer,
  transfer: immutableBoolReducer,
  userConnections: userConnectionsReducer,
  userType: genericReducer({
    actionProperty: 'userType',
    defaultState: '',
    setAction: RECEIVE_PORTFOLIO,
  }),
  verifyMobile: genericReducer({
    actionProperty: 'payload',
    defaultState: '',
    setAction: RECEIVE_PHONE_NUMBER.toString(),
  }),
  workExperiences: experiencesReducer,
  ...notificationCenterReducers,
};
