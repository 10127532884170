import req from 'shared/req';
import {collegePortalErrorMessage} from 'student-search/error-helpers';
import {stopLoading} from 'shared/actions/loading';
import CampaignService from 'src/services/CampaignService';

export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
export const SAVE_GROUP = 'SAVE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const RETRIEVE_GROUP = 'RETRIEVE_SAVED_GROUP';

export const LOADING_IDENTIFIERS = {
  groups: 'savedGroups',
  counts: 'savedGroupCounts',
};

const sendPostGroup = (name, studentSearchType, filters, campaignId) =>
  req({
    method: 'post',
    url: '/v1/college-portal-users/me/saved-student-searches',
    data: {name, studentSearchType, filters, campaignId},
  });

const sendDeleteGroup = (groupId) =>
  req({
    method: 'delete',
    url: `/v1/college-portal-users/me/saved-student-searches/${groupId}`,
  });

const sendUpdateGroup = (groupId, filters, campaignId) =>
  req({
    method: 'patch',
    url: `/v1/college-portal-users/me/saved-student-searches/${groupId}`,
    data: {filters, campaignId},
  });

export const receiveGroups = (groups) => ({
  type: RECEIVE_GROUPS,
  groups,
});

const SAVED_GROUP_FIELDS = ['name', 'default', 'filters', 'studentSearchType', 'campaignId'];

const getSavedGroupCounts = () => (dispatch) =>
  req({
    url: '/v1/college-portal-users/me/saved-student-searches?fields=count',
  }).then((groupsWithCounts) => {
    dispatch(receiveGroups(groupsWithCounts));
    dispatch(stopLoading(LOADING_IDENTIFIERS.counts));
  });

export const getSavedGroups =
  (fields = []) =>
  (dispatch) => {
    const queryFields = Array.from(new Set([...SAVED_GROUP_FIELDS, ...fields])).join(',');

    req({
      url: `/v1/college-portal-users/me/saved-student-searches?fields=${queryFields}`,
    })
      .then((groups) => {
        addCampaignFollowsCountToSavedGroups(groups).then((modifiedGroups) => {
          dispatch(receiveGroups(modifiedGroups));
          dispatch(stopLoading(LOADING_IDENTIFIERS.groups));
        });
      })
      .then(() => dispatch(getSavedGroupCounts()));
  };

const addCampaignFollowsCountToSavedGroups = async (savedGroups) =>
  CampaignService.index().then((campaigns) => {
    savedGroups.map((savedGroup) => {
      if (savedGroup.campaignId) {
        savedGroup.campaignFollowsCount = campaigns.find(
          (campaign) => campaign.id === savedGroup.campaignId
        )?.campaignFollowsCount;
      }
      return savedGroup;
    });
    return savedGroups;
  });

export const saveGroup =
  ({name, campaignId}) =>
  (dispatch, getState) => {
    const {
      filters,
      studentResults: {totalStudentsCount, studentSearchType},
    } = getState();
    return sendPostGroup(name, studentSearchType, filters, campaignId)
      .then(({_id}) =>
        dispatch({
          type: SAVE_GROUP,
          count: totalStudentsCount,
          name,
          filters,
          _id,
          campaignId,
          studentSearchType,
        })
      )
      .catch(() => dispatch(collegePortalErrorMessage()));
  };

export const deleteGroup =
  ({groupId}) =>
  (dispatch) =>
    sendDeleteGroup(groupId)
      .then(() =>
        dispatch({
          type: DELETE_GROUP,
          _id: groupId,
        })
      )
      .catch(() => dispatch(collegePortalErrorMessage()));

export const updateGroup = (groupId, filters, campaignId) => (dispatch) =>
  sendUpdateGroup(groupId, filters, campaignId)
    .then(({name}) =>
      dispatch({
        type: UPDATE_GROUP,
        _id: groupId,
        name,
        filters,
        campaignId,
        campaignFollowsCount: 0,
      })
    )
    .catch(() => dispatch(collegePortalErrorMessage));

export const retrieveGroup = ({filters}) => ({
  type: RETRIEVE_GROUP,
  filters,
});
