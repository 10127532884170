import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from '../section-header';
import Icon from 'shared/components/icon';
import Card from 'src/components/Cards/Card';
import {formatEarnings} from 'src/utils/formatters';

class SpotOrReplacementAward extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    sponsorName: PropTypes.string.isRequired,
  };

  renderTitle() {
    const props = this.props;
    const earnings = formatEarnings(props.amount, 4);
    return (
      <div className="college-profile-spot-award-title">
        {props.name}
        <div className="college-profile-spot-award-title-amount-container">
          <div className="college-profile-spot-award-title-amount">
            <Icon iconType="check-circle" /> ${earnings.annualAmount()}
          </div>
          <div className="college-profile-spot-award-title-amount-per-year">
            ${earnings.termDescription()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {name, sponsorName} = this.props;

    return (
      <Card>
        <div className="college-profile-spot-award" data-testid="spot-or-replacement-award">
          <SectionHeader
            title={this.renderTitle()}
            subtitle={`Issued Directed from ${sponsorName}`}
          />
          <div className="college-profile-spot-award-description">
            <p className="college-profile-spot-award-congrats">Congratulations!</p>

            <p>
              Based on your RaiseMe portfolio, {sponsorName} has awarded you the
              <span className="college-profile-spot-award-bold"> {name}</span>. We will continue to
              keep track of additional micro-scholarships you earn, and you will receive either the{' '}
              {name} or the value of the microscholarships, whichever is higher by the {sponsorName}{' '}
              deadline.
            </p>
            <p>
              To redeem your {name}, you must be eligible for {sponsorName}&apos;s program and
              apply, be admitted, and enroll in {sponsorName}. To remain eligible, you must ensure
              your portfolio is complete and accurate and maintain your RaiseMe GPA.
            </p>
          </div>
        </div>
      </Card>
    );
  }
}

export default SpotOrReplacementAward;
