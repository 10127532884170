import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import GoogleButton from 'shared/components/google-button';
import Separator from 'shared/components/separator';
import GoogleOAuth from 'shared/modules/google-oauth';
import FormButton from 'src/components/Button';
import FormikCredentialsInput, {
  loginCredentialsInputFormSchema,
} from 'src/components/inputs/formik-credentials-input/formik-credentials-input';
import Layout from 'src/views/public/shared/layout';
import {getHiddenParams} from 'src/views/public/utils';
import {Form, Formik} from 'formik';
import {setNavigationAnnouncement} from 'shared/actions/accessibility-announcement';
import {useDispatch} from 'react-redux';

interface Props {
  googleLogin: () => void;
  onSubmit: (credentials: {identifier: string; password: string}) => void;
}

const Login = ({googleLogin, onSubmit}: Props) => {
  const params: {eiid?: string; rej?: string; cpiid?: string; token?: string; next?: string} =
    getHiddenParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationAnnouncement('Log In'));
    GoogleOAuth.init([]);
  }, [dispatch]);

  return (
    <Layout page="login">
      <div className="auth-login auth-shared-credentials-container">
        <h1 className="auth-login-welcome-header">Welcome Back!</h1>
        <GoogleButton
          size="medium"
          buttonName="auth-login-google"
          className="auth-shared-google-button"
          onClick={googleLogin}
        >
          Log in with Google
        </GoogleButton>
        <Separator>or Log In with Email or Phone Number</Separator>
        <Formik
          initialValues={{
            identifier: '',
            password: '',
          }}
          onSubmit={async (values, formik) => {
            const credentials = {identifier: values.identifier, password: values.password};

            try {
              await onSubmit(credentials);
            } catch (errors: any) {
              Object.keys(errors).forEach((field) => {
                if (errors[field]) {
                  formik.setFieldError(field, errors[field]);
                }
              });
            }
          }}
          validationSchema={loginCredentialsInputFormSchema}
        >
          {(formikProps) => (
            <Form>
              <FormikCredentialsInput />
              {params.eiid && <input type="hidden" name="eiid" value={params.eiid} />}
              {params.rej && <input type="hidden" name="rej" value={params.rej} />}
              {params.cpiid && <input type="hidden" name="cpiid" value={params.cpiid} />}
              {params.token && <input type="hidden" name="token" value={params.token} />}
              {params.next && <input type="hidden" name="next" value={params.next} />}
              <FormButton
                size="large"
                type="submit"
                className="auth-shared-submit-button"
                width="100%"
                disabled={formikProps.isSubmitting || !formikProps.isValid}
                cy="desktop-login-submit-button"
                testId="desktop-login-submit-button"
                id="desktop-login-submit-button"
              >
                {formikProps.isSubmitting ? 'Logging In...' : 'Log In'}
              </FormButton>
            </Form>
          )}
        </Formik>
        <Link to="/forgot-password" className="auth-shared-link auth-login-forgot-password">
          Forgot your password?
        </Link>
        <p className="auth-login-no-account">
          Don&#39;t have an account?{' '}
          <Link to="/signup" className="auth-shared-link">
            Sign up here
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default Login;
