import req from 'shared/req';
import {flashMessage} from 'shared/actions/notifications';
import {collegePortalErrorMessage} from 'student-search/error-helpers';
import strings from 'student-search/strings.json';

export const SET_RECIPIENT = 'SET_RECIPIENT';
export const SET_ACTIVE_THREAD = 'SET_ACTIVE_THREAD';
export const RECEIVE_THREADS = 'RECEIVE_THREADS';
export const RECEIVE_THREAD_MESSAGES = 'RECEIVE_THREAD_MESSAGES';
export const UPDATE_CURRENT_THREAD = 'UPDATE_CURRENT_THREAD';

const createMessageObject = (message, currentThreadMessages) => {
  const timestamp = new Date();
  const fakeId = 'tempResponse' + timestamp;

  return {
    ...currentThreadMessages[0],
    id: fakeId,
    timeSent: timestamp,
    body: message,
    bulkId: null,
  };
};

export const setRecipient = (recipient) => ({type: SET_RECIPIENT, recipient});

// Adds the newly submitted message to the frontend before sidekiq finishes
export const updateCurrentThread = (message) => (dispatch, getState) => {
  const {
    messaging: {currentThreadMessages},
  } = getState();
  const messageObject = createMessageObject(message, currentThreadMessages);

  return dispatch({
    type: UPDATE_CURRENT_THREAD,
    message: {
      ...messageObject,
      timeSent: messageObject.timeSent.toISOString(),
    },
  });
};

/**
 * Send a message to multiple recipients.
 */
export const sendMessages =
  (filters, senderEmail, message, studentType = 'high-school') =>
  (dispatch) => {
    const data = {
      body: message,
      senderEmail: senderEmail,
      educationPhase: studentType,
      filters,
    };
    req({
      url: '/v1/messages',
      method: 'post',
      data,
    })
      .then(() => {
        dispatch(flashMessage(strings.text.messageSent));
      })
      .catch(() => {
        dispatch(collegePortalErrorMessage());
      });
  };

/**
 * Set active message
 */
export const setActiveThread = (threadIndex) => ({
  type: SET_ACTIVE_THREAD,
  threadIndex,
});

/**
 * Receive thread
 */
export const receiveThreads = (threads) => ({
  type: RECEIVE_THREADS,
  threads,
});

/**
 * Receive thread messages
 */
export const receiveThreadMessages = (threadMessages) => ({
  type: RECEIVE_THREAD_MESSAGES,
  threadMessages,
});

/**
 * Fetch messages for active thread
 */
export const fetchThreadMessages = (threadId) => (dispatch) => {
  req({
    url: `/v1/messages/${threadId}`,
    method: 'get',
  })
    .then((response) => {
      dispatch(receiveThreadMessages(response));
    })
    .catch(() => {
      dispatch(collegePortalErrorMessage());
    });
};

/**
 * Fetch Messages
 */

export const fetchThreads = () => (dispatch) => {
  req({
    url: '/v1/messages',
    method: 'get',
  })
    .then((response) => {
      dispatch(receiveThreads(response));
      if (response[0]) {
        dispatch(fetchThreadMessages(response[0].id));
      } else {
        dispatch(receiveThreadMessages([]));
      }
    })
    .catch(() => {
      dispatch(collegePortalErrorMessage());
    });
};
