import {getCollege} from 'shared/actions/college';
import {receiveCollege} from 'shared/college-profile/actions/college-profile';
import req from 'shared/req';
import {fetchProgramEndedFeatureInteraction} from 'undergraduate-student/actions/program-ended';

export const RECEIVE_SUBMISSIONS = 'RECEIVE_SUBMISSIONS';

export const getSubmissions = (universityId) => (dispatch) =>
  req({
    url: `/v1/submissions?fields=
  minimumEarningsToRedeem,redeemableTermName,submissionDeadline,partnerScholarships,scholarshipProgramId,universityId,earnings,confirmed,
  &universityId=${universityId}`,
  }).then((submissions) => dispatch(receiveSubmissions(submissions)));

const receiveSubmissions = (submissions) => ({
  type: RECEIVE_SUBMISSIONS,
  submissions,
});

export function fetchBasicPartnerProgramData(id) {
  return async (dispatch) => {
    const college = await getCollege(
      id,
      [
        'partnerSubmissionDeadlineDay',
        'partnerSubmissionDeadlineMonth',
        'partnerSubmissionDeadlineYear',
        'programId',
        'universityId',
        'primaryLogoColor',
      ].join(',')
    );

    dispatch(receiveCollege(college));
    dispatch(getSubmissions(college.universityId));
    dispatch(fetchProgramEndedFeatureInteraction(college.programId));
  };
}
