import PropTypes from 'prop-types';
import React from 'react';
import TextButton from 'src/components/TextButton';

class HighSchoolCriterion extends React.Component {
  static propTypes = {
    criterionType: PropTypes.string.isRequired,
    displayString: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onHighSchoolListClick: PropTypes.func.isRequired,
  };

  openHighSchoolModal = () => this.props.onHighSchoolListClick(this.props.id);

  render() {
    const {criterionType, displayString} = this.props;

    return (
      <div className="flex-row">
        <span>{criterionType}</span>
        <TextButton
          margin={false}
          onClick={this.openHighSchoolModal}
          className="high-school-list-link"
          testid="high-school-list-link"
        >
          {displayString}
        </TextButton>
      </div>
    );
  }
}

export default HighSchoolCriterion;
