import React, {ReactElement, useEffect, useState} from 'react';
import Icon from 'shared/components/icon';
import useFunctionChain, {MiddlewareFn} from 'src/hooks/useFunctionChain';
import classnames from 'classnames';

export type NotificationFnResult =
  | undefined
  | {
      canDismiss?: boolean;
      notification?: ReactElement;
      onDismiss?: () => void;
      // Time in ms before notification dismissal
      timeout?: number;
    };

export interface GlobalNotificationProps<Context> {
  context: Context;
  notificationChain: MiddlewareFn<Context, NotificationFnResult>[];
  className?: string;
}

export default function GlobalNotification<Context>(
  props: GlobalNotificationProps<Context>
): ReactElement | null {
  const [dismissed, setDismissed] = useState<boolean>(false);
  const notification = useFunctionChain<Context, NotificationFnResult>(
    props.notificationChain,
    props.context
  );

  const handleDismiss = (): void => {
    if (!dismissed) {
      setDismissed(true);
      if (notification && notification.onDismiss) {
        notification.onDismiss();
      }
    }
  };

  useEffect(() => {
    if (notification && Number(notification.timeout) > 0) {
      const token = setTimeout(handleDismiss, notification.timeout);
      return (): void => clearTimeout(token);
    }
  }, [notification]);

  if (dismissed || !notification || !notification.notification) {
    return null;
  }

  return (
    <div
      className={classnames('shared-global-notification', props.className)}
      data-testid="global-notification"
    >
      <span className="shared-global-notification-content">
        {notification.notification}
        {notification.canDismiss && (
          <Icon
            iconType="times-r"
            onClick={handleDismiss}
            className="shared-global-notification-dismiss"
            testid="global-notification-dismiss"
          />
        )}
      </span>
    </div>
  );
}
