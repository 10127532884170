import req from 'shared/req';
import {isMobile, validateEmail} from 'src/utils/validations';

interface ForgotPassword {
  message?: string;
  redirect?: boolean;
}

interface MobileVerification {
  resetToken: string;
}

interface ResetPasswordData {
  resetToken: string;
  password: string;
}

const signupType = (value: string): string => {
  if (isMobile(value)) {
    return 'mobile';
  } else if (validateEmail(value)) {
    return 'email';
  }

  return '';
};

export default class ForgotPasswordService {
  static forgotPassword(emailOrMobile): Promise<ForgotPassword> {
    const url = '/forgot-password';
    const data = {
      emailOrMobile,
      signupType: signupType(emailOrMobile),
    };

    return req<ForgotPassword>({url, data, method: 'POST'});
  }

  static async mobileVerificationCode(verificationCode: string): Promise<MobileVerification> {
    const url = '/mobile-verification-code';
    const data = {verificationCode};

    try {
      const response = await req<MobileVerification>({url, data, method: 'POST'});
      if (response.resetToken) {
        await this.setForgottenPasswordToken(response.resetToken);
      }
      return response;
    } catch (err) {
      throw err;
    }
  }

  static setForgottenPasswordToken(resetToken: string): Promise<void> {
    return req({url: `/set-forgotten-password-token/${resetToken}`, method: 'POST'});
  }

  static resetPassword(data: ResetPasswordData): Promise<void> {
    const url = '/reset-forgotten-password';

    return req({url, data, method: 'POST'});
  }
}
