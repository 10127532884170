import {omit, some} from 'lodash';
import {
  DELETE_COMMUNITY_SERVICE_EVENT,
  EDIT_COMMUNITY_SERVICE_EVENT,
  RECEIVE_COMMUNITY_SERVICE_EVENT,
  NEW_COMMUNITY_SERVICE_EVENT,
  CANCEL_EDIT_COMMUNITY_SERVICE_EVENT,
  SCROLL_TO_SERVICE,
  SCROLLED_TO_SERVICE,
  RECEIVE_COMMUNITY_SERVICE_EVENTS,
} from 'shared/portfolio/actions/community-service';

export const communityServiceReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_COMMUNITY_SERVICE_EVENT:
      return {
        ...state,
        [action.id]: {
          ...action.communityServiceEvent,
          editing: true,
        },
      };
    case DELETE_COMMUNITY_SERVICE_EVENT:
      return omit(state, action.id);
    case CANCEL_EDIT_COMMUNITY_SERVICE_EVENT:
      if (action.communityServiceEvent.new) {
        return omit(state, action.id);
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.communityServiceEvent,
            editing: false,
            scrollTo: true,
          },
        };
      }
    case RECEIVE_COMMUNITY_SERVICE_EVENT:
      return {
        ...state,
        [action.id]: {
          ...action.communityServiceEvent,
        },
      };
    case NEW_COMMUNITY_SERVICE_EVENT:
      if (some(state, 'new')) {
        return state;
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.communityServiceEvent,
          },
        };
      }
    case SCROLL_TO_SERVICE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          scrollTo: true,
        },
      };
    case SCROLLED_TO_SERVICE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          scrollTo: false,
        },
      };
    case RECEIVE_COMMUNITY_SERVICE_EVENTS:
      return {
        ...state,
        ...action.communityServiceEvents,
      };
    default:
      return state;
  }
};
