import {map} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import CriterionFactory from './CriterionFactory';

class OrCriterion extends React.Component {
  static propTypes = {
    criteria: PropTypes.array.isRequired,
    onHighSchoolListClick: PropTypes.func,
  };

  render() {
    return (
      <div className="or-criterion">
        <div className="header">Students must also meet ONE of these criteria sets:</div>
        {map(this.props.criteria, (criterion, i) => (
          <div key={i} className="or-criterion-content">
            {i === 0 ? null : (
              <div className="or-separator">
                <span>or</span>
                <hr />
              </div>
            )}
            <CriterionFactory
              criterion={criterion}
              onHighSchoolListClick={this.props.onHighSchoolListClick}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default OrCriterion;
