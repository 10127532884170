// This array determines the order the cards appear on the micro-scholarships page
export default [
  'oppTimeSensitiveItem',
  'oppGradeA',
  'oppGradeB',
  'oppPerfectAttendance',
  'oppIncreaseGPA',
  'oppApIb',
  'oppHonors',
  'oppCollegeLevel',
  'oppForeignLanguage',
  'oppActivity',
  'oppPopularActivity',
  'oppLeadershipActivity',
  'oppSport',
  'oppLeadershipSport',
  'oppCommunityService',
  'oppCertification',
  'oppWorkExperience',
  'oppCourseSubject',
  'oppGoldAward',
  'oppEagleScout',
  'oppNhsMember',
  'oppApScore',
  'oppPsatPreAct',
  'oppPsatPreActScore',
  'oppSatAct',
  'oppSatActScore',
  'oppRegents',
  'oppCollegeVisit',
  'oppCollegeFair',
  'oppCollegeReception',
  'oppSummerProgram',
  'oppCollege101Event',
  'oppReferStudent',
  'oppFafsa',
  'oppMultimedia',
  'oppAssociateDegreeProgress',
];
