export const genericReducer = (args) => {
  const {setAction, resetAction, actionProperty = 'payload', defaultState = null} = args;

  return (state = defaultState, action) => {
    if (setAction && action.type === setAction) {
      return action[actionProperty];
    }

    if (resetAction && action.type === resetAction) {
      return defaultState;
    }

    return state;
  };
};
