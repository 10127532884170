import React from 'react';
import Text from 'shared/components/text';
import Icon from 'shared/components/icon';

class ChurnedPartnerText extends React.Component {
  render() {
    return (
      <div className="shared-college-card-status-text-churned">
        <Icon className="shared-college-card-status-text-default-flag" iconType="flag" />
        <Text weight={6} inline showOverflow>
          No longer awarding micro-scholarships
        </Text>
      </div>
    );
  }
}

export default ChurnedPartnerText;
