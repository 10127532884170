import {map, filter} from 'lodash';
import req from 'shared/req';
import {closeModal} from 'shared/actions/modal';
import {flashMessage} from 'shared/actions/notifications';
import {setModalPending} from 'shared/college-profile/actions/college-profile';

export const ADD_REFERRAL = 'ADD_REFERRAL';
export const UPDATE_REFERRAL = 'UPDATE_REFERRAL';
export const MULTIPLE_REFERRAL_MODAL_IDENTIFIER = 'multiple-referral-modal';

export const addReferral = () => ({
  type: ADD_REFERRAL,
});

export const updateReferral = (index, referral) => ({
  type: UPDATE_REFERRAL,
  index,
  referral,
});

export const sendReferrals = (referrals) => (dispatch) => {
  referrals = map(filter(referrals), 'value');

  return req({
    url: '/students/create-referrals',
    method: 'post',
    data: {referrals, referralType: 'onboarding-cta'},
  })
    .then(() => {
      dispatch(dismissReferralsCta());
    })
    .then(() => {
      dispatch(setModalPending('referral'));
      dispatch(closeModal(MULTIPLE_REFERRAL_MODAL_IDENTIFIER));
      dispatch(flashMessage('Awesome! Referrals sent.'));
    });
};

export const dismissReferralsCta = () => (dispatch) =>
  req({
    url: '/students/dismiss-referrals-cta',
    method: 'post',
  }).then(() => {
    dispatch(closeModal(MULTIPLE_REFERRAL_MODAL_IDENTIFIER));
  });
