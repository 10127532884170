import {camelCase, flow, mapKeys, mapValues, startCase} from 'lodash/fp';
import req from 'shared/req';

const mapValuesWithKey = mapValues.convert({cap: false});

export const MISSING_INSTITUTION_REQUESTED = 'MISSING_INSTITUTION_REQUESTED';

export function schoolRequested(email) {
  return {
    type: MISSING_INSTITUTION_REQUESTED,
    email,
  };
}

export const requestInstitution = (args) => (dispatch) => {
  const {state, city, name, website, address, schoolType} = args;

  return req({
    method: 'post',
    url: '/request-add-institution',
    data: {
      subdivision: state,
      city,
      address,
      institution_website: website,
      school_name: name,
      school_type: schoolType,
    },
  })
    .then(({email}) => {
      dispatch(schoolRequested(email));
    })
    .catch(({errors}) => {
      throw flow(
        mapKeys(camelCase),
        mapValuesWithKey((error, fieldName) => `${startCase(fieldName)} ${error}`)
      )(errors);
    });
};
