import React, {useEffect} from 'react';
import {Link, Redirect, useParams} from 'react-router-dom';
import GoogleButton from 'shared/components/google-button';
import Separator from 'shared/components/separator';
import GoogleOAuth from 'shared/modules/google-oauth';
import FormButton from 'src/components/Button';
import FormikCredentialsInput, {
  hsSignupFormSchema,
  transferSignupFormSchema,
} from 'src/components/inputs/formik-credentials-input/formik-credentials-input';
import {ADULT_LEARNER, TOO_YOUNG} from '../../utils';
import CommunityCollegeStudent from 'shared/img/cc-student.svg';
import Educator from 'shared/img/educator.svg';
import HighSchoolStudent from 'shared/img/hs-student.svg';
import ParentOrGuardian from 'shared/img/parent-guardian.svg';
import {Form, Formik} from 'formik';
import FormikTextInput from '../../../../components/inputs/formik-text-input';
import Label from '../../../../../shared/components/label';
import errorTypes from '../../../../components/form/onboarding/text.json';
import usePlatformContext from '../../../../hooks/usePlatform';
import {SignUpUserType} from 'src/types/enums';
import placeholders from 'src/static/placeholders.json';

const USER_MAP = {
  'hs-student': {
    icon: HighSchoolStudent,
    text: 'High School Student',
  },
  'comm-college-student': {
    icon: CommunityCollegeStudent,
    text: 'College Transfer Student',
  },
  'cc-student': {
    icon: CommunityCollegeStudent,
    text: 'College Transfer Student',
  },
  teacher: {
    icon: Educator,
    text: 'Teacher',
  },
  counselor: {
    icon: Educator,
    text: 'Counselor',
  },
  mentor: {
    icon: Educator,
    text: 'Mentor',
  },
  communityCollegeStaff: {
    icon: Educator,
    text: 'Community College Staff Member',
  },
  parent: {
    icon: ParentOrGuardian,
    text: 'Parent or Guardian',
  },
  [ADULT_LEARNER]: {
    icon: CommunityCollegeStudent,
    text: 'Adult Learner',
  },
};

interface Props {
  size: number;
  googleSignUp: () => void;
  onSubmit: (formValues: {
    identifier: string;
    password: string;
    firstName: string;
    lastName: string;
    birthday: Date;
  }) => void;
  setNavigationAnnouncement: (message: string) => void;
}

const indefiniteArticle = (word: string) => {
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  return vowels.includes(word[0].toLowerCase()) ? 'an' : 'a';
};

const usertypeData = (userType: string, userSubtype?: string) =>
  USER_MAP[userSubtype!] ? USER_MAP[userSubtype!] : USER_MAP[userType];

const UserSignUp = ({size, googleSignUp, onSubmit, setNavigationAnnouncement}: Props) => {
  const {userType, userSubtype} = useParams<{userType: string; userSubtype?: string}>();
  const {isIonic} = usePlatformContext();

  useEffect(() => {
    setNavigationAnnouncement(`${usertypeData(userType, userSubtype).text} Sign Up`);
    GoogleOAuth.init([]);
  }, [userType, userSubtype, setNavigationAnnouncement]);

  const {icon: Icon, text} = usertypeData(userType, userSubtype);

  // Redirect for adult learners if the URL structure is incorrect
  if (userType === ADULT_LEARNER && location.pathname.slice(1).split('/').length < 4) {
    return <Redirect to="/signup" />;
  }

  const backToWelcomeLink = (
    <div className="back-to-welcome-link">
      <Link to={isIonic ? '/welcome' : '/signup'}>
        Did you mean to sign up as a different type of user?
      </Link>
    </div>
  );

  const showBackToWelcomeLink = (error) =>
    [
      errorTypes.birthdayErrors.overage,
      errorTypes.birthdayErrors.underageCommunityCollege,
    ].includes(error)
      ? backToWelcomeLink
      : '';

  return (
    <>
      <div className="auth-signup-user-icon">
        <Icon width={size} height={size} />
      </div>
      <h1 className="auth-signup-user-header">
        Sign Up as {indefiniteArticle(text)} {text}
      </h1>
      <Formik
        initialValues={{
          identifier: '',
          password: '',
          firstName: '',
          lastName: '',
          birthday: '',
        }}
        onSubmit={async (values, formik) => {
          const formData = {
            identifier: values.identifier,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            birthday: new Date(values.birthday),
          };

          try {
            await onSubmit(formData);
          } catch (errors: any) {
            Object.keys(errors).forEach((field) => {
              if (errors[field]) {
                formik.setFieldError(field, errors[field]);
              }
            });
          }
        }}
        validationSchema={
          userType === SignUpUserType.HIGH_SCHOOL ? hsSignupFormSchema : transferSignupFormSchema
        }
        validateOnBlur
        validateOnChange={false}
      >
        {(formikProps) => (
          <Form>
            <legend className="shared-required-legend"></legend>
            <Label required>
              First Name
              <FormikTextInput
                name="firstName"
                showCheck
                placeholder={placeholders.signUpFirstName}
                autoComplete="given-name"
                required
              />
            </Label>
            <Label required>
              Last Name
              <FormikTextInput
                name="lastName"
                showCheck
                placeholder={placeholders.signUpLastName}
                autoComplete="family-name"
                required
              />
            </Label>
            <Label required>
              Date of Birth
              <FormikTextInput name="birthday" type="date" required />
            </Label>
            {showBackToWelcomeLink(formikProps.errors.birthday)}
            <FormikCredentialsInput
              signUp
              identifierHeader={userSubtype ? 'Official Work Email' : 'Email'}
              showRequiredLegend={false}
            />
            <p className="auth-signup-disclaimer">
              By agreeing and continuing, you acknowledge and agree to the{' '}
              <Link to="/terms_of_use" className="auth-shared-link caption">
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link to="/privacy-policy" className="auth-shared-link caption">
                Privacy and Cookie Policy
              </Link>
              .
            </p>
            <FormButton
              size="large"
              type="submit"
              className="auth-shared-submit-button"
              cy="signup"
              width="100%"
              disabled={
                window.sessionStorage.getItem(TOO_YOUNG) === 'true' ||
                formikProps.isSubmitting ||
                !formikProps.isValid
              }
            >
              {formikProps.isSubmitting ? 'Signing Up...' : 'Agree & Continue'}
            </FormButton>
            <Separator>Or</Separator>
            <GoogleButton
              size="medium"
              buttonName="auth-login-google"
              className="auth-shared-google-button"
              onClick={googleSignUp}
            >
              Agree & Sign Up with Google
            </GoogleButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserSignUp;
