import {RoutedModalConfig} from 'src/components/ModalContext/types';
import {lazy} from 'react';
import {DeleteGroupParams} from './delete-campaign-modal';

export const deleteCampaignConfig: RoutedModalConfig<DeleteGroupParams> = {
  type: 'delete-campaign',
  routed: true,
  Component: lazy(
    () => import(/* webpackChunkName: "delete-campaign-modal" */ './delete-campaign-modal')
  ),
  validateProps: (props): boolean => Boolean(props.groupId && props.campaignId),
};
