import req from 'shared/req';

export const RECEIVE_DATA_FOR_STUDENT_SEARCH = 'RECEIVE_DATA_FOR_STUDENT_SEARCH';

const receiveDataForStudentSearch = (data, columnSettings) => ({
  type: RECEIVE_DATA_FOR_STUDENT_SEARCH,
  data: {
    ...data,
    columnSettings,
  },
});

export const getDataForStudentSearch = (columnSettings) => (dispatch) =>
  req({
    url: '/rest/students/data-for-search',
  }).then((data) => dispatch(receiveDataForStudentSearch(data, columnSettings)));
