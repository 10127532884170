import React, {useEffect, useState} from 'react';
import Dropdown from '../../../dropdown';

const RADIUS_OPTIONS = [10, 25, 50, 100, 250, 500];

interface RadiusOption {
  _id: string;
  text: string;
  value: string;
}

interface Props {
  adjustRadiusFilter: (radius: string) => void;
  getColleges: () => void;
  filters: object[];
}

const RadiusDropdown = ({adjustRadiusFilter, getColleges, filters}: Props) => {
  const [radius, setRadius] = useState('Any');
  const [coordinates, setCoordinates] = useState('');
  const [persistedRadius, setPersistedRadius] = useState('');

  useEffect(() => {
    const locationFilter = filters['location'];
    const hasRadiusFilter = filters.hasOwnProperty('radius-location');
    const existingCords = locationFilter?.[0]?.loc;
    const existingMiles = filters['radius-location']?.split(',')[2];
    const hasLocationFilter = filters?.hasOwnProperty('location');

    if (hasLocationFilter && locationFilter.some((location) => location.hasOwnProperty('city'))) {
      setPersistedRadius(hasRadiusFilter ? existingMiles : 'Any');
      setCoordinates(existingCords ? existingCords.toString() : null);
    }
    if (!hasRadiusFilter) {
      setRadius('Any');
    } else if (!hasLocationFilter) {
      adjustRadiusFilter('Any');
      getColleges();
    }
  }, [filters]);

  const radiusOptions: RadiusOption[] = [
    {_id: 'Any', text: 'Any', value: 'Any'},
    ...RADIUS_OPTIONS.map((distance) => ({
      _id: distance.toString(),
      text: `${distance} Miles`,
      value: `${coordinates},${distance}`,
    })),
  ];

  const handleRadiusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    adjustRadiusFilter(value);
    setRadius(value);
    getColleges();
  };

  return (
    <>
      <legend className="college-search-filters-section-legend">
        <label className="college-search-filters-section-label" htmlFor="typeahead-location">
          Distance
        </label>
      </legend>
      <Dropdown
        onChange={handleRadiusChange}
        options={radiusOptions}
        value={radius}
        placeholder={persistedRadius !== 'Any' ? `${persistedRadius} Miles` : persistedRadius}
      />
    </>
  );
};

export default RadiusDropdown;
