import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import StudentScholarships from '../components/scholarships/student-scholarships';

import {openModal} from 'shared/actions/modal';

const mapStateToProps = (state) => ({
  collegeProfile: state.collegeProfile,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({openModal}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentScholarships);
