import {createFactory} from 'react';

// see https://github.com/acdlite/recompose/blob/master/src/packages/recompose/defaultProps.js
const defaultProps = (props) => (BaseComponent) => {
  const factory = createFactory(BaseComponent);
  const DefaultProps = (ownerProps) => factory(ownerProps);
  DefaultProps.defaultProps = props;
  return DefaultProps;
};

export default defaultProps;
