import req from 'shared/req';
import {keys} from 'lodash';
import {changesSaved, errorMessage} from 'shared/actions/notifications';

export const RECEIVE_COLLEGE = 'RECEIVE_COLLEGE';

export const receiveCollege = (college) => ({type: RECEIVE_COLLEGE, college});

export const getCollege = (college) => (dispatch) =>
  req({
    url: `/v1/colleges/${college._id}?fields=_id,name,state,city,unitId,campuses`,
    method: 'get',
  }).then((receivedCollege) => {
    const formattedCollege = {
      id: receivedCollege._id,
      unitId: receivedCollege.unitId,
      name: receivedCollege.name,
      city: receivedCollege.city,
      state: receivedCollege.state,
      campuses: receivedCollege.campuses,
    };
    dispatch(receiveCollege(formattedCollege));
    return receiveCollege(formattedCollege);
  });

export const submitForm = (college) => {
  const fields = keys(college).join(',');
  const url = `/admin/college_listing_campuses/${college._id}?fields=${fields}`;

  return (dispatch) =>
    req({
      url,
      method: 'PATCH',
      data: college,
    })
      .then(() => {
        dispatch(changesSaved());
        return dispatch(getCollege(college));
      })
      .catch(() => {
        dispatch(errorMessage());
      });
};
