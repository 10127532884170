import gapi from 'gapi';
import $ from 'jquery';
import GoogleOAuth from 'shared/modules/google-oauth';

let apiLoaded = false;
let bearerToken = null;
const callbacksToRun = [];
const SCOPES = ['https://www.googleapis.com/auth/contacts.readonly'];

const DISCOVERY_DOCS = ['https://people.googleapis.com/$discovery/rest?version=v1'];

const GoogleApiContacts = {
  callApi: (cb) => {
    if (apiLoaded) {
      cb();
    } else {
      callbacksToRun.push(cb);
      GoogleApiContacts.loadApi();
    }
  },

  getBearer: () => bearerToken,

  loadApi: () => {
    GoogleOAuth.init(SCOPES, DISCOVERY_DOCS, () => {
      GoogleOAuth.authorize().then((googleUser) => {
        bearerToken = googleUser.authentication.accessToken;

        gapi.client.load('people', 'v1').then(() => {
          apiLoaded = true;
          callbacksToRun.forEach((cb) => {
            cb();
          });
        });
      });
    });
  },
  logout: () => {
    apiLoaded = false;
  },
  getContacts: () => {
    const promise = $.Deferred();
    const contacts = [];

    const collectContacts = (firstRequest, nextPageToken) => {
      const params = {
        resourceName: 'people/me',
        pageSize: 100,
        personFields: 'names,emailAddresses,photos',
      };

      if (!firstRequest) {
        params.pageToken = nextPageToken;
      }

      if (!firstRequest && !nextPageToken) {
        return promise.resolve(contacts);
      }

      GoogleApiContacts.callApi(() => {
        gapi.client.people.people.connections.list(params).then(
          (response) => {
            const nextPageOfConnections = response.result.connections || [];
            nextPageOfConnections.forEach((connection) => {
              let email = null;
              let photo = null;
              let name = null;

              if (connection.emailAddresses && connection.emailAddresses.length) {
                email = connection.emailAddresses[0].value;
              }

              if (connection.names && connection.names.length) {
                name = connection.names[0].displayName;
              }

              if (connection.photos && connection.photos.length) {
                photo = connection.photos[0].url;
              }

              if (email) {
                contacts.push({
                  email,
                  photo,
                  name,
                });
              }
            });

            return collectContacts(false, response.result.nextPageToken);
          },
          (errors) => promise.reject(errors)
        );
      });
    };

    collectContacts(true);
    return promise;
  },
};

export default GoogleApiContacts;
