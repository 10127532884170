import PropTypes from 'prop-types';
import React from 'react';
import {map, size} from 'lodash';
import Card from 'src/components/Cards/Card';
import CollegeListItem from 'shared/components/college-list-item';

class FixedSimilarCollegesCard extends React.Component {
  static propTypes = {
    similarPartnerColleges: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    followCollege: PropTypes.func.isRequired,
    unfollowCollege: PropTypes.func.isRequired,
    collegeProfile: PropTypes.object.isRequired,
  };

  render() {
    const {similarPartnerColleges, followCollege, unfollowCollege, collegeProfile} = this.props;

    if (size(similarPartnerColleges) === 0) {
      return null;
    }

    return (
      <Card>
        <div className="college-profile-right-rail-similar-colleges">
          <header className="college-profile-right-rail-secondary-header">
            Similar Colleges You Can Earn From
          </header>
          <ul className="college-profile-right-rail-similar-colleges-list">
            {map(similarPartnerColleges, (col, i) => (
              <CollegeListItem
                key={`fixed-suggested-partner-college-${i}`}
                college={{
                  ...col,
                  following: col.isFollowing,
                  logoUrl: col.logo,
                  id: col._id,
                  status: {status: col.earningRelationship.statusType},
                  bucketShownEventId: col.bucketShownEventId,
                }}
                followCollege={followCollege}
                unfollowCollege={unfollowCollege}
                recsShownEventId={collegeProfile.eventIds.recsShownEventId}
              />
            ))}
          </ul>
        </div>
      </Card>
    );
  }
}

export default FixedSimilarCollegesCard;
