import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

class ProgressBar extends React.Component {
  static propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fillAmount: PropTypes.string,
    color: PropTypes.oneOf(['blue', 'green', 'red', 'gray', 'blue-gradient']),
    barClassName: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    height: 10,
    fillAmount: '0%',
    color: 'green',
  };

  render() {
    const {height, width, fillAmount, color, className, barClassName} = this.props;

    return (
      <div
        className={classnames('shared-progress-bar', className)}
        style={{height: height, width: width}}
        data-testid="progress-bar-element"
      >
        <div
          className={classnames('shared-progress-bar-fill', color, barClassName)}
          style={{width: fillAmount}}
          data-testid="progress-bar-fill"
        ></div>
      </div>
    );
  }
}

export default ProgressBar;
