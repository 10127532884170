import $ from 'jquery';
import {once} from 'lodash';
import UserService from 'src/services/UserService';
import {track} from 'src/utils/analytics';
import vars from 'src/utils/vars';
import {idempotentPromptForPushNotifications} from '../../src/services/push-notifications-service';

const defaultNotificationServerAddress = vars.NOTIFICATIONS_URL || false;

const FIRST_EARNINGS_PROMPT_KEY = 'hasPromptedForPushOnFirstEarnings';
const FULL_CREDITS_PROMPT_KEY = 'hasPromptedForPushOnFullCredits';

let initialized = false;

/**
 * In-App Notifications
 *
 * Creates a client function for displaying real-time notifications.
 *
 *
 * initNotificationClient
 * - Loads the socket.io script
 * - Intializes a connection with the node server
 * - Listens for emitted socket events
 * - Displays appropriate frontend notifications based on those events
 * @param notificationServerAddress The address of the node.js server that includes sockets.io
 * @return void
 */
window.initNotificationClient = async (
  userId = null,
  notificationServerAddress = defaultNotificationServerAddress
) => {
  if (typeof notificationServerAddress !== 'string') {
    console.error('Missing notification server address', window.location.href);
    return;
  }

  if (initialized) {
    return;
  }

  initialized = true;

  const userData = await UserService.getData(['type']);

  // dependencies
  const raise = window.Raise;
  const vent = raise.vent;
  let portalUser;
  if (userData.type === 'CollegePortalUser') {
    portalUser = userData;
  }
  const educator = window.gon && window.gon.educator;

  /**
   * SOCKET_IO_SCRIPT
   * The location of the socket.io script, whether from CDN or locally hosted.
   * @type const String
   */
  const SOCKET_IO_SCRIPT = 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/4.5.4/socket.io.js';

  /**
   * includeSocketScript
   * Includes the socket.io script in asynchronous fashion.
   * Initializes sockets when loading complete.
   * @preconditions:
   * @return void
   */
  const includeSocketScript = function (callback) {
    let r = false;
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = SOCKET_IO_SCRIPT;
    s.onload = s.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        callback();
      }
    };
    const t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  };

  /**
   * initSockets
   * Creates the socket.io connection
   */
  function initSockets() {
    // Create a socket connection
    const socket = io.connect(notificationServerAddress, {
      reconnectionAttempts: 5,
    });

    // eslint-disable-next-line prefer-const
    let reportError;

    raise.socket = socket;

    socket
      .on('connect_error', function () {
        reportError();
      })
      .on('serverWelcome', function () {
        if (!userId) {
          if (portalUser) {
            userId = portalUser._id;
          } else if (educator) {
            userId = educator._id;
          } else {
            console.error('No userId to subscribe to web socket connection');
          }
        }

        socket.emit('userId', userId);
      })
      // Notify user they have new notifications in the notifications center
      .on('has_new', function (hasNew) {
        if (hasNew === 'true') {
          $('.nav-notification-center').addClass('notification');
          vent.trigger('notification-center:new');
        } else {
          $('.nav-notification-center').removeClass('notification');
        }
      })
      .on('evaluated_followed_colleges', function ({first_award: firstAward}) {
        if (firstAward) {
          idempotentPromptForPushNotifications(FIRST_EARNINGS_PROMPT_KEY);
        }

        vent.trigger('eval:followed:colleges');
      })
      .on('portal_report_complete', function () {
        vent.trigger('report:notification:complete');
      })
      .on('messageError', function (data) {
        vent.trigger('message:error', data);
      })
      .on('messageSuccess', function (data) {
        vent.trigger('message:success', data);
      })
      .on('broadcast', function (data) {
        switch (data.type) {
          case 'BreakingChange':
            vent.trigger('breaking:change', data);
            break;
          default:
            break;
        }
      })
      .on('scholarshipAwarded', (data) => {
        vent.trigger('award:notification', data);
      })
      .on('checklist', (data) => {
        vent.trigger('checklist', data);
      })
      .on('totalCreditsUpdated', (data) => {
        if (data >= 15) {
          idempotentPromptForPushNotifications(FULL_CREDITS_PROMPT_KEY);
        }

        vent.trigger('totalCreditsUpdated', data);
      })
      .on('totalTransferCreditsUpdated', (data) => {
        if (data >= 12) {
          idempotentPromptForPushNotifications(FULL_CREDITS_PROMPT_KEY);
        }
      });

    reportError = once(function () {
      track('Failed to Connect to Notifications', {
        errorText: 'Failed to connect to notifications server',
      });
    });
  }

  // Include the socket script, and initialize the sockets.
  includeSocketScript(initSockets);
};
