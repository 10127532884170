import {useMemo} from 'react';

export type MiddlewareFn<Context, ReturnValue> = (context: Context) => undefined | ReturnValue;

/**
 * Hook for executing a chain of middleware functions that execute on any change to the provided context.
 * See GlobalNotificationChain and usages for an example
 * @param fns Array of functions that return a truthy or nullish value depending on the provided context
 * @param fnContext Context supplied to each function
 */
export default function useFunctionChain<Context, ReturnValue>(
  fns: MiddlewareFn<Context, ReturnValue>[],
  fnContext: Context
): undefined | ReturnValue {
  const value = useMemo<ReturnValue | undefined>(() => {
    for (const fn of fns) {
      const returnValue = fn(fnContext);
      if (returnValue) {
        return returnValue;
      }
    }

    return undefined;
  }, [fns, JSON.stringify(fnContext)]);

  return value;
}
