import {SELECT_TAB} from '../actions/tab';
import {CHECK_UNSAVED_CHANGES, DISMISS_UNSAVED_CHANGES} from '../actions/unsaved-changes';

const defaultState = {
  checkUnsavedChanges: false,
  tabSelection: null,
};

function unsavedChangesReducer(state = defaultState, action) {
  switch (action.type) {
    case CHECK_UNSAVED_CHANGES:
      return {
        ...state,
        checkUnsavedChanges: true,
        tabSelection: action.tab,
      };
    case SELECT_TAB:
    case DISMISS_UNSAVED_CHANGES:
      return {
        ...state,
        checkUnsavedChanges: false,
        tabSelection: null,
      };
    default:
      return state;
  }
}

export default unsavedChangesReducer;
