import React, {PropsWithChildren} from 'react';
import TextButton from 'src/components/TextButton';
import {useBoolean} from 'src/hooks/useBoolean';

type Props = PropsWithChildren<{
  filterType: string;
  startClosed: boolean;
}>;

const ExpandableFilter = ({filterType, startClosed = true, children}: Props) => {
  const expanded = useBoolean(!startClosed);

  const toggleShow = () => expanded.toggle();

  const className = expanded.value ? 'show' : 'hidden';
  const filterText = filterType ? `${filterType} ` : '';

  return (
    <div>
      <div className={className}>{children}</div>
      <TextButton
        buttonName="more-less-type-filters"
        color="blue"
        onClick={toggleShow}
        aria-expanded={expanded.value}
      >
        {(expanded.value ? 'Fewer' : 'More') + ` ${filterText}Filters`}
      </TextButton>
    </div>
  );
};

export default ExpandableFilter;
