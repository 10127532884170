import {ObjectOf} from 'src/types/store';
import {ActionType} from 'src/actions/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReducerFn<Reducer, Action = ActionType<any>> = (
  state: Reducer,
  action: Action
) => Reducer;

export type ReducerMap<Type> = ObjectOf<ReducerFn<Type>>;

export function createReducer<Type>(map: ReducerMap<Type>, initialState: Type) {
  return (state: Type = initialState, action: ActionType<unknown>): Type => {
    if (map[action.type]) {
      return map[action.type](state, action);
    }

    return {...state};
  };
}
