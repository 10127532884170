import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty, map} from 'lodash';
import Card from 'src/components/Cards/Card';
import CollapsibleSection from 'src/components/collapsible-section';
import {watchMedia, getMedia} from 'shared/mq';

class EnrollmentCriteriaCard extends React.Component {
  static propTypes = {
    ongoingRequirements: PropTypes.array,
  };

  componentDidMount() {
    this.mq = watchMedia(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.mq.remove();
  }

  render() {
    const {ongoingRequirements} = this.props;
    const mq = getMedia();

    if (isEmpty(ongoingRequirements)) return null;

    return (
      <Card>
        <CollapsibleSection
          title="Ongoing Requirements"
          subtitle="Ongoing requirements once students are enrolled in college"
          disableCollapse={mq.MD_AND_UP}
        >
          <div className="college-profile-header-spacing">
            {map(ongoingRequirements, (requirement, i) => (
              <p key={i} dangerouslySetInnerHTML={{__html: requirement}}></p>
            ))}
          </div>
        </CollapsibleSection>
      </Card>
    );
  }
}

export default EnrollmentCriteriaCard;
