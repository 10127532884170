import {compact, map} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import MiniInfoCard from 'shared/components/college-profile/mini-info-card';
import MiniInfoCardContainer from 'shared/components/college-profile/mini-info-card-container';
import InfoTooltip from 'shared/components/info-tooltip';
import {titleize, withCommas} from 'shared/utils';
import {UserType} from 'src/types/enums';

const usNewsListTypeMap = (listType) =>
  `US News ${titleize((listType || 'national').replaceAll('_', ' '))}`;

class QuickStats extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    userInfo: PropTypes.shape({
      user: PropTypes.string,
    }),
  };

  getSubtitle() {
    const {
      college: {control, setting, category, profitStatus, size},
    } = this.props;

    return (
      <ul className="college-profile-quick-stats-list">
        {map(compact([control, setting, size, category, profitStatus]), (item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  }

  render() {
    const {
      college: {
        averageNetPrice,
        acceptanceRate,
        totalUndergraduates,
        tuition,
        salaryAfterGraduation,
        fourYearGraduationRate,
        partnerSubmissionDeadlineMonth,
        partnerSubmissionDeadlineDay,
        forbesRank,
        forbesRankYear,
        usNewsRank,
        usNewsRankYear,
        usNewsListType,
        customQuickStats,
      },
      userInfo: {user},
    } = this.props;

    if (
      !averageNetPrice &&
      !acceptanceRate &&
      !totalUndergraduates &&
      !tuition &&
      !salaryAfterGraduation &&
      !fourYearGraduationRate &&
      !forbesRank &&
      !usNewsRank &&
      !partnerSubmissionDeadlineMonth &&
      !partnerSubmissionDeadlineDay &&
      !customQuickStats.length
    ) {
      return null;
    }

    return (
      <section className="college-profile-quick-stats">
        <header className="college-profile-quick-stats-header-container">
          <div className="college-profile-quick-stats-header">Quick Stats</div>
          <div className="college-profile-quick-stats-subtitle">{this.getSubtitle()}</div>
        </header>
        <div className="college-profile-quick-stats-slider">
          <MiniInfoCardContainer>
            {customQuickStats.map(({iconType, label, value, _id}) => (
              <MiniInfoCard iconType={iconType} label={label} value={value} key={_id} />
            ))}
            <MiniInfoCard
              iconType="money-bill-alt"
              label="Average Annual Cost"
              value={averageNetPrice ? `$${withCommas(Math.round(averageNetPrice))}` : null}
            />
            <MiniInfoCard
              iconType="envelope"
              label="Acceptance Rate"
              value={acceptanceRate ? `${acceptanceRate}%` : null}
            />
            <MiniInfoCard
              iconType="users"
              label="Undergrad Students"
              value={totalUndergraduates ? withCommas(totalUndergraduates) : null}
            />
            <MiniInfoCard
              iconType="dollar-sign"
              label={
                <span>
                  Annual Tuition
                  <br />
                  and Fees{' '}
                  {user === UserType.PUBLIC && (
                    <InfoTooltip className="college-profile-quick-stats-tuition-info" iconSize={14}>
                      The tuition listed the out-of-state amount. Sign up or log in to see in-state
                      tuition.
                    </InfoTooltip>
                  )}
                </span>
              }
              value={tuition ? `$${withCommas(tuition)}` : null}
            />
            <MiniInfoCard
              iconType="graduation-cap"
              label="Salary After Graduating"
              value={salaryAfterGraduation ? `$${withCommas(salaryAfterGraduation)}` : null}
            />
            <MiniInfoCard
              iconType="graduation-cap"
              label="Four-Year Grad Rate"
              value={fourYearGraduationRate ? `${Math.round(fourYearGraduationRate)}%` : null}
            />
            <MiniInfoCard
              iconType="chart-line"
              label={
                <>
                  {forbesRankYear}
                  {forbesRankYear && <br />}
                  Forbes{forbesRankYear ? ' ' : <br />}Rank
                </>
              }
              value={forbesRank}
            />
            <MiniInfoCard
              iconType="globe"
              label={
                <>
                  {usNewsRankYear}
                  {usNewsRankYear && <br />}
                  <div title={usNewsListTypeMap(usNewsListType)}>
                    {usNewsListTypeMap(usNewsListType)}
                  </div>
                </>
              }
              value={usNewsRank}
            />
            {user === UserType.EDUCATOR && (
              <MiniInfoCard
                iconType="calendar-alt"
                label="Earning Deadline"
                value={
                  partnerSubmissionDeadlineMonth && partnerSubmissionDeadlineDay
                    ? `${partnerSubmissionDeadlineMonth + 1}/${partnerSubmissionDeadlineDay}`
                    : null
                }
              />
            )}
          </MiniInfoCardContainer>
        </div>
      </section>
    );
  }
}

export default QuickStats;
