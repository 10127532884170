import {Schema, arrayOf} from 'normalizr';
import {institution} from './shared';

const activity = new Schema('activities', {
  idAttribute: (data) => `activity-${data._id}`,
});

const leadershipPosition = new Schema('leadershipPositions', {
  idAttribute: (position) => `leadership-${position._id}`,
});

activity.define({
  leadershipPositions: arrayOf(leadershipPosition),
  institution: institution,
});

const activities = arrayOf(activity);

export {activity, activities};
