import req from 'shared/req';
import {changesSaved, errorMessage} from 'shared/actions/notifications';
import {AppThunk} from 'src/types/store';

export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';
export const RECEIVE_NOTIFICATION_SETTINGS = 'RECEIVE_NOTIFICATION_SETTINGS';

interface NotificationSetting {
  notificationTopic: string;
  channel: string;
  enabled: boolean;
}

interface UpdateOperation {
  op: string;
  path: string;
  value: boolean;
}

export const toggleNotification = ({
  notificationTopic,
  channel,
  enabled,
}: $TSFixMe): NotificationSetting & {type: string} => ({
  type: TOGGLE_NOTIFICATION,
  notificationTopic,
  channel,
  enabled,
});

export function saveNotifications(notificationSettings: NotificationSetting[]): AppThunk {
  const patch = notificationSettings.map<UpdateOperation>(function (
    setting: NotificationSetting
  ): UpdateOperation {
    return {
      op: 'replace',
      path: `/${setting.notificationTopic}/${setting.channel}`,
      value: setting.enabled,
    };
  });

  let url = '/v1/users/me/notification-settings';
  const token = new URLSearchParams(window.location.search.substring(1)).get('token');
  if (token !== null) {
    url += `?token=${token}`;
  }

  return (dispatch): Promise<void> => {
    // optimistically update redux
    notificationSettings.forEach(function (setting: NotificationSetting) {
      dispatch(
        toggleNotification({
          notificationTopic: setting.notificationTopic,
          channel: setting.channel,
          enabled: setting.enabled,
        })
      );
    });

    return req({
      url,
      method: 'patch',
      data: {patch},
    })
      .then(() => {
        notificationSettings.forEach(function (setting: NotificationSetting) {
          dispatch(
            toggleNotification({
              notificationTopic: setting.notificationTopic,
              channel: setting.channel,
              enabled: setting.enabled,
            })
          );
        });
        dispatch(changesSaved());
      })
      .catch(() => {
        dispatch(errorMessage());
      });
  };
}

export function saveNotification({notificationTopic, channel, enabled}: $TSFixMe): AppThunk {
  return saveNotifications([{notificationTopic, channel, enabled}]);
}

export function receiveNotificationSettings(notificationSettings: object): {
  type: string;
  notificationSettings: object;
} {
  return {
    type: RECEIVE_NOTIFICATION_SETTINGS,
    notificationSettings,
  };
}

export interface SettingDefinition {
  setting: string;
  topic: string;
  text: string;
}

// TODO: the following settings are deprecated and should be removed,
// whenever we do not need to prettify titles of deprecated
// notification settings channels from old emails that users may click.
export const DEPRECATED_NOTIFICATION_SETTINGS: SettingDefinition[] = [
  {
    setting: 'How to use RaiseMe',
    topic: 'updatesAndTips',
    text: 'Learn how to utilize RaiseMe on your college journey and to reach your full earning potential.',
  },
  {
    setting: 'Community Network',
    topic: 'personalizedRecommendations',
    text: "Get notified when your RaiseMe community grows, like when a college you've followed becomes a RaiseMe partner, or when a classmate signs up from your invitation!",
  },
  {
    setting: 'RaiseMe Marketing and Updates',
    topic: 'blogAndNews',
    text: "Stay up to date on what's happening at RaiseMe from newsletters to contests!",
  },
  {
    setting: 'RaiseMe Marketing and Updates',
    topic: 'contestAndPromotions',
    text: "Stay up to date on what's happening at RaiseMe from newsletters to contests!",
  },
];

export const NOTIFICATION_SETTINGS: SettingDefinition[] = [
  {
    setting: 'RaiseMe Marketing and Updates',
    topic: 'marketingUpdates',
    text: "Stay up to date on what's happening at RaiseMe from newsletters to contests!",
  },
  {
    setting: 'Community Network',
    topic: 'mentionsAndConnectionRequests',
    text: "Get notified when your RaiseMe community grows, like when a college you've followed becomes a RaiseMe partner, or when a classmate signs up from your invitation!",
  },
  {
    setting: 'Critical deadline reminders',
    topic: 'criticalDeadlineReminders',
    text: 'Not recommended to unsubscribe. These notifications are critical for submissions deadlines to colleges.',
  },
  {
    setting: 'How to use RaiseMe',
    topic: 'howToAndAccountCompletion',
    text: 'Learn how to utilize RaiseMe on your college journey and to reach your full earning potential.',
  },
];
