import React from 'react';
import {Grid, Box} from '@mui/material';
import BlueLogo from 'shared/img/primary-logo-blue.svg';
import AppStoreBadge from 'shared/img/app-store-badge.svg';
import FacebookImg from 'assets/images/static/footer/facebook.png';
import TwitterImg from 'assets/images/static/footer/twitter.png';

import styles from './styles.module.scss';

const linkColumns = [
  [
    {text: 'About', link: '/about'},
    {text: 'Jobs', link: '/jobs'},
    {text: 'Help', link: 'https://help.raise.me'},
    {text: 'Contact', link: 'https://help.raise.me/hc/en-us/requests/new'},
  ],
  [
    {text: 'Educators', link: '/educators'},
    {text: 'Parents', link: '/parents'},
    {text: 'Higher Ed', link: '/college-administrators'},
    {text: 'Blog', link: '/blog'},
    {text: 'Privacy Policy', link: '/privacy-policy'},
  ],
  [
    {text: 'Cookie Settings', link: '#cookie-settings'},
    {text: 'Terms', link: '/terms_of_use'},
    {text: 'College search', link: '/college-search'},
    {text: 'Sitemap', link: '/sitemap'},
  ],
];

const FooterLinks = (): React.ReactElement => (
  <>
    <Grid container sx={{justify: 'space-between'}}>
      {linkColumns.map((column, i) => (
        <Grid key={i} item>
          <ul className={styles.list}>
            {column.map((item) => (
              <li key={item.text} className={styles.listItem}>
                <a href={item.link} className={styles.link}>
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        </Grid>
      ))}
      <a href="https://help.raise.me/hc/en-us/articles/360050316594" className={styles.infoLink}>
        Do Not Sell My Personal Info
      </a>
    </Grid>
  </>
);

const PublicFooter = (): React.ReactElement => (
  <Box className={styles.footerArea} display="flex" justifyContent="center" flexShrink={1}>
    <Box maxWidth={1180} py={7} mt={2} px={6}>
      <Box mb={2}>
        <BlueLogo className={styles.logo} />
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={4} md={4}>
          <p className="shared-font body">
            RaiseMe partners with universities to offer students scholarships for their achievements
            during high school and college.
          </p>
          <Box mt={2}>RaiseMe © {new Date().getFullYear()}</Box>
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <FooterLinks />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <p className="shared-font body bold">Download our iOS app!</p>
          <AppStoreBadge className={styles.appStoreBadge} />
          <Box mt={1}>
            <a href="https://twitter.com/raiselabs" target="_blank" rel="noopener noreferrer">
              {/* <TwitterIcon className={styles.socialIcons} /> */}
              <img className={styles.socialIcons} src={TwitterImg} alt="RaiseMe on Twitter" />
            </a>
            <a href="https://www.facebook.com/RaiseLabs" target="_blank" rel="noopener noreferrer">
              <img className={styles.socialIcons} src={FacebookImg} alt="RaiseMe on Facebook" />
              {/* <FacebookIcon className={styles.socialIcons} /> */}
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Box>
);

export default PublicFooter;
