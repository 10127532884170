import React, {useContext, useEffect, useState} from 'react';
import {SkipToContentContext} from './context';
import styles from './SkipToContent.module.scss';

function createAnchor(href: string): HTMLElement {
  const anchor = document.createElement('a');
  anchor.setAttribute('id', styles.SkipToContent);
  anchor.setAttribute('href', href);
  anchor.text = 'Skip to Content';
  document.body.insertBefore(anchor, document.body.childNodes[0]);
  return anchor;
}

function destroyElement(element: HTMLElement | null): void {
  element && element.remove();
}

export default function SkipToContent(): React.ReactElement | null {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const {mainContentId} = useContext(SkipToContentContext);

  useEffect(() => {
    setAnchor(createAnchor(`#${mainContentId}`));
    return (): void => destroyElement(anchor);
  }, [mainContentId]);

  return null;
}
