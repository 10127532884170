import {createAction} from 'redux-actions';
import FeatureInteractionService from 'src/services/FeatureInteractionService';

export const RECEIVE_FEATURE_INTERACTION_CHANGE = createAction(
  'RECEIVE_FEATURE_INTERACTION_CHANGE'
);
export const RECEIVE_FEATURE_INTERACTION = createAction('RECEIVE_FEATURE_INTERACTION');
export const RECEIVE_FEATURE_INTERACTION_CREATION = createAction(
  'RECEIVE_FEATURE_INTERACTION_CREATION'
);

// TODO update usages to use the service directly
export const postFeatureInteraction = (name, data = {}) =>
  FeatureInteractionService.post(name, data);

export const getFeatureInteraction = (name, fields = []) =>
  new Promise((resolve, reject) => {
    FeatureInteractionService.get(name, fields).then((feature) => {
      if (feature === undefined) {
        reject();
      } else {
        resolve(feature);
      }
    });
  });

export const patchFeatureInteraction = (name, featureData = {}) =>
  FeatureInteractionService.patch(name, featureData);

export const changeFeatureInteraction = (featureName, data) => (dispatch) =>
  patchFeatureInteraction(featureName, data).then((featureData) => {
    dispatch(RECEIVE_FEATURE_INTERACTION_CHANGE({featureName, featureData}));
  });

export const fetchFeatureInteraction = (featureName, fields) => (dispatch) =>
  getFeatureInteraction(featureName, fields)
    .then((featureData) => dispatch(RECEIVE_FEATURE_INTERACTION({featureName, featureData})))
    .catch(() => dispatch(RECEIVE_FEATURE_INTERACTION({featureName, featureData: {}})));
