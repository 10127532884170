import {createReducer, ReducerMap} from 'src/redux/type';
import {ActionType} from 'src/actions/utils';

export const RECEIVE_COLLEGE_NAMES = 'RECEIVE_COLLEGE_NAMES';
export const RECEIVE_PROGRAM_COLLEGE = 'RECEIVE_PROGRAM_COLLEGE';

interface College {
  slug: string;
  name: string;
}

export interface CollegeProgramReducerType {
  colleges: Record<string, College>;
  programs: Record<string, string>;
}

const initialState: CollegeProgramReducerType = {
  colleges: {},
  programs: {},
};

const reducerMap: ReducerMap<CollegeProgramReducerType> = {
  [RECEIVE_COLLEGE_NAMES]: (
    state,
    action: ActionType<{colleges: Record<string, College>}>
  ): CollegeProgramReducerType => ({
    ...state,
    colleges: {
      ...state.colleges,
      ...action.payload.colleges,
    },
  }),
  [RECEIVE_PROGRAM_COLLEGE]: (
    state,
    action: ActionType<{programs: Record<string, string>}>
  ): CollegeProgramReducerType => ({
    ...state,
    programs: {
      ...state.programs,
      ...action.payload.programs,
    },
  }),
};

export const collegeProgramReducer = createReducer<CollegeProgramReducerType>(
  reducerMap,
  initialState
);
export default collegeProgramReducer;
