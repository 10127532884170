import PropTypes from 'prop-types';
import React from 'react';
import HorizontalBoxPlot from './horizontal-box-plot';
import AcceptanceClassificationTooltip from './acceptance-likelihood/acceptance-classification-tooltip';
import {acceptanceLikelihoodClassification} from 'shared/college-profile/utils';

class ACTSection extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    showAdmissability: PropTypes.bool,
  };

  acceptanceLikelihoodClassification() {
    const {
      college: {actComposite},
      userInfo: {actScore},
    } = this.props;

    if (!actComposite) {
      return '';
    }

    const twentyFifthPercentile = actComposite[0];
    const seventyFifthPercentile = actComposite[1];

    return acceptanceLikelihoodClassification(
      actScore.score,
      twentyFifthPercentile,
      seventyFifthPercentile
    );
  }

  render() {
    const {
      college,
      userInfo: {actScore},
      showAdmissability,
    } = this.props;

    if (!(college.actComposite && college.actComposite.length > 1)) {
      return null;
    }

    const points = [];
    if (showAdmissability && actScore) {
      points.push({
        value: actScore.score,
        color: 'blue',
      });
    }

    return (
      <div className="college-profile-sat-act">
        <header className="college-profile-sat-act-header">ACT Range</header>
        {showAdmissability && (
          <div className="college-profile-sat-act-subheader">
            {actScore ? (
              <span>{`Compare your ACT score to ${college.name}’s averages.`}</span>
            ) : (
              <a href="/portfolio#scores" className="">
                Enter your ACT Score to compare to ACT averages.
              </a>
            )}
          </div>
        )}
        <div className="college-profile-sat-act-range">
          <div className="college-profile-sat-act-plot">
            {showAdmissability && actScore && (
              <AcceptanceClassificationTooltip
                label="Your ACT"
                studentValue={actScore.score}
                classification={this.acceptanceLikelihoodClassification()}
                xValue={((actScore.score - 10) / (36 - 10)) * 100}
              />
            )}
            <HorizontalBoxPlot
              lower={college.actComposite[0]}
              upper={college.actComposite[1]}
              max={36}
              min={10}
              points={points}
              tooltip={showAdmissability && !!actScore}
            />
          </div>
          <div className="college-score-range-label">
            <div className="college-score-range-label-content">
              {college.actComposite.join('-')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ACTSection;
