import {createSelector, Selector} from 'reselect';
import {get, groupBy, sortBy} from 'lodash';
import {ObjectOf, Store} from 'src/types/store';
import {ActivityGroupings, ActivityGroupOrders} from './cardGroupings';
import {ActiveExperiments} from 'src/services/ExperimentService';
import {Activity} from 'src/types/activities';

interface GroupSettings {
  cardGroupingOrder: string[];
  cardGroupings: ObjectOf<{
    key: string;
    subtitle: string;
  }>;
}

export const getHighSchoolName = (state: Store): string => state.student.currentInstitution.name;

export const getPopularActivityTreatment: Selector<Store, string> = (state: Store) =>
  state.experiments[ActiveExperiments.PopularActivities.name];

export const getCards: Selector<Store, Activity[]> = (state: Store) =>
  get(state, 'opportunityCards.cards', []);

export const getOpportunityFeatureFlag: Selector<Store, boolean> = (state: Store) =>
  get(state, 'featureFlags.flags.clubOpportunityCards', false);

export const getSortedOpportunityCardsSelector = createSelector<
  Store,
  Activity[],
  ObjectOf<Activity[]>
>([getCards], (cards) => {
  const groupedCards = groupBy(cards, 'cardInfo.group');

  for (const key in groupedCards) {
    groupedCards[key] = sortBy(groupedCards[key], 'cardInfo.rank');
  }

  return groupedCards;
});

const getOriginalFormat = (): GroupSettings => ({
  cardGroupingOrder: [ActivityGroupings.discovery.key],
  cardGroupings: {
    discovery: {
      ...ActivityGroupings.discovery,
      subtitle: '',
    },
  },
});

export const getGroupSettings = createSelector(
  [getOpportunityFeatureFlag, getPopularActivityTreatment, getSortedOpportunityCardsSelector],
  (clubOpportunityFeatureFlag, treatment, cards) => {
    if (clubOpportunityFeatureFlag) {
      const popularSectionCardCount = get(cards, [ActivityGroupings.popular.key, 'length'], 0);
      const isTreatment = treatment === ActiveExperiments.PopularActivities.treatment;

      if (isTreatment && popularSectionCardCount) {
        return {
          cardGroupingOrder: ActivityGroupOrders,
          cardGroupings: ActivityGroupings,
        };
      }
    }

    return getOriginalFormat();
  }
);

export const getCardCount = createSelector(getSortedOpportunityCardsSelector, (groupedCards) =>
  Object.keys(groupedCards).reduce((count, key) => groupedCards[key].length + count, 0)
);
