import {filter} from 'lodash';

// this could be used to split more than just emails, but calling it splitEmails
// because it splits on things not found in emails, where as for other strings you wouldn't
// necessarily want to split on spaces or semi colons.
export const splitEmails = (str) => filter((str || '').split(/[\n\t ;,"']{1,}/));

// This is using the exact same regex as the invalid_emails method in app/models/student_inviter.rb
export const isEmail = (email) => !!email.match(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i);

// Takes an array of emails and returns a hash with the keys valid and invalid
// each of those keys has a value of a hash where the keys are the emails that are
// valid or invalid with values of true (basically hashes that are acting as sets)
export const separateValidEmails = (emails) => {
  // Storing these as hashes and converting to arrays later so we can guarantee uniqueness without iterating later
  // I would use the Set type, but that isn't well-supported by IE
  const emailsByType = {valid: {}, invalid: {}};

  emails.forEach((email) => {
    if (isEmail(email)) {
      emailsByType.valid[email] = true;
    } else {
      emailsByType.invalid[email] = true;
    }
  });

  return emailsByType;
};

export const convertSlugToTitle = (slug) =>
  slug
    .split('-')
    .map((word) => word[0].toUpperCase() + word.substr(1))
    .join(' ');
