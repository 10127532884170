import React from 'react';
import PropTypes from 'prop-types';
import {filterColleges} from '../search';
import AccessibleTypeahead from 'shared/components/accessible-typeahead';
import {withRouter} from 'react-router-dom';

class Search extends React.Component {
  static propTypes = {
    fetchCollegeSearchBarData: PropTypes.func.isRequired,
    abbreviations: PropTypes.array,
    colleges: PropTypes.array,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  componentDidMount() {
    if (!this.props.colleges) {
      this.props.fetchCollegeSearchBarData();
    }
  }

  performQuery = (query) => {
    const {colleges, abbreviations} = this.props;
    let results;

    if (colleges) {
      results = filterColleges(query, abbreviations, colleges);
    } else {
      results = [{url: '#', name: 'Colleges loading...'}];
    }

    return new Promise((resolve) => resolve(results));
  };

  goToEduPage = ({slug}) => this.props.history.push(`/edu/${slug}`);

  render() {
    return (
      <AccessibleTypeahead
        id="educator-college-search-typeahead"
        labelKey="name"
        onSearch={this.performQuery}
        onSelect={this.goToEduPage}
        placeholder="Search for Colleges"
        postFixIcon="search"
        size="small"
      />
    );
  }
}

export default withRouter(Search);
