import {FOLLOW_COLLEGE} from 'src/redux/collegeSearch/actions';

export const termNotOfferedModalReducer = (state = {college: {}}, action) => {
  switch (action.type) {
    case FOLLOW_COLLEGE: {
      return {
        ...state,
        collegeId: action.id,
      };
    }
    default:
      return state;
  }
};
