// referrals
import {UPDATE_REFERRAL, ADD_REFERRAL} from 'portfolio/components/multiple-referral-modal/actions';

export function referralsReducer(state = [], action) {
  let newState;

  switch (action.type) {
    case ADD_REFERRAL:
      return [...state, {invalid: true, touched: false, value: ''}];
    case UPDATE_REFERRAL:
      newState = [...state];
      newState[action.index] = action.referral;
      return newState;
    default:
      return state;
  }
}
