import PropTypes from 'prop-types';
import React from 'react';

import RangeFilter from 'shared/components/filters/range-filter';
import InfoTooltip from 'shared/components/info-tooltip';
import Text from 'shared/components/text';
import ExpandableFilter from 'src/components/CollegeSearch/Filters/expandable-filter';
import {currencyFormat, percentFormat} from 'src/utils/collegeSearch';

class CostFilters extends React.Component {
  static propTypes = {
    getColleges: PropTypes.func.isRequired,
    transfer: PropTypes.bool.isRequired,
  };

  render() {
    const {getColleges, transfer} = this.props;

    return (
      <div className="college-search-filters-section">
        <fieldset role="group" aria-label="Filter by College Cost">
          <legend className="college-search-filters-section-legend">
            <Text weight={5} emphasis>
              Cost
            </Text>
          </legend>
          <span className="college-search-filters-section-tooltip">
            <Text id="college-search-tuition" weight={5} emphasis inline>
              Tuition
            </Text>
            <InfoTooltip iconSize={14}>
              Automatically showing in-state or out-of-state tuition based on your ZIP code.
            </InfoTooltip>
          </span>
          <RangeFilter
            name="tuition"
            step={1000}
            minValue={0}
            maxValue={60000}
            labelFunc={currencyFormat}
            onChange={getColleges}
            labelledby="college-search-tuition"
          />
          {!transfer && (
            <ExpandableFilter filterType="Cost">
              <div className="college-search-subdivider"></div>
              <span className="college-search-filters-section-tooltip">
                <Text id="college-search-avg-net-price" weight={5} emphasis inline>
                  Average Net Price
                </Text>
                <InfoTooltip iconSize={14}>
                  Total cost of one year of college minus financial aid based on the in-state or
                  out-of-state tuition that is relevant to your ZIP code.
                </InfoTooltip>
              </span>
              <RangeFilter
                name="average-net-price"
                step={1000}
                minValue={0}
                maxValue={60000}
                labelFunc={currencyFormat}
                onChange={getColleges}
                labelledby="college-search-avg-net-price"
              />
              <div className="college-search-subdivider"></div>
              <Text id="college-search-avg-need-met" weight={5} emphasis>
                Average % of Need Met
              </Text>
              <RangeFilter
                name="percent-need-met"
                step={1}
                minValue={0}
                maxValue={100}
                labelFunc={percentFormat}
                onChange={getColleges}
                labelledby="college-search-avg-need-met"
              />
            </ExpandableFilter>
          )}
        </fieldset>
      </div>
    );
  }
}

export default CostFilters;
