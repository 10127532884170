import PopularActivities from 'shared/micro-scholarships/components/popular-activities';

export const ActivityGroupings = {
  popular: {
    key: 'popular',
    subtitle: (institutionName) => {
      if (institutionName && institutionName.length <= 28) {
        return `Popular at ${institutionName}`;
      }

      return 'Popular at your high school';
    },
    Container: PopularActivities,
  },
  discovery: {
    key: 'discovery',
    subtitle: 'Discover other micro-scholarships',
  },
};

export const ActivityGroupOrders = [ActivityGroupings.popular.key, ActivityGroupings.discovery.key];
