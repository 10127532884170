import {createAction} from 'redux-actions';
import ExperimentService from 'src/services/ExperimentService';

export const RECEIVE_EXPERIMENT_TREATMENT = createAction('RECEIVE_EXPERIMENT_TREATMENT');

export const fetchExperimentTreatmentFor =
  (experimentName, defaultTreatment = null) =>
  async (dispatch, getState) => {
    const experiment = getState().experiments[experimentName];
    if (experiment) return;

    const treatmentName = await ExperimentService.getTreatment(experimentName);
    dispatch(
      RECEIVE_EXPERIMENT_TREATMENT({
        experimentName,
        treatmentName: treatmentName || defaultTreatment,
      })
    );
  };
