import {concat, find, forEach, pick} from 'lodash';
import {SAVE_GROUP, DELETE_GROUP, UPDATE_GROUP, RECEIVE_GROUPS} from '../actions/saved-groups';

function extractGroup(action) {
  return pick(action, [
    'name',
    'filters',
    'count',
    '_id',
    'studentSearchType',
    'campaignId',
    'campaignFollowsCount',
  ]);
}

export const savedGroupsReducer = (searches = [], action) => {
  switch (action.type) {
    case SAVE_GROUP:
      return concat(searches, extractGroup(action));
    case DELETE_GROUP:
      return searches.filter((search) => search._id !== action._id);
    case UPDATE_GROUP: {
      return searches.map((search) => {
        if (search._id === action._id) {
          return {...search, ...extractGroup(action)};
        }
        return search;
      });
    }
    case RECEIVE_GROUPS: {
      const newState = [];

      forEach(action.groups, (group) => {
        const existingGroup = find(searches, {_id: group._id}) || {};

        newState.push({...existingGroup, ...group});
      });

      return newState;
    }
    default:
      return searches;
  }
};
