import {START_UPLOADING_ID, FINISH_UPLOADING_ID} from '../actions/official-id';

export default function officialIdUploadingReducer(state = false, action) {
  switch (action.type) {
    case START_UPLOADING_ID:
      return true;
    case FINISH_UPLOADING_ID:
      return false;
    default:
      return state;
  }
}
