import React from 'react';
import PropTypes from 'prop-types';
import CheckboxGroupFilter from 'shared/components/filters/checkbox-group-filter';
import Text from 'shared/components/text';

class NcaaFilters extends React.Component {
  static propTypes = {
    getColleges: PropTypes.func.isRequired,
  };

  render() {
    const {getColleges} = this.props;

    return (
      <div className="college-search-filters-section">
        <fieldset role="group" aria-label="Filter by NCAA Sports Division">
          <legend className="college-search-filters-section-legend">
            <Text weight={5} emphasis>
              NCAA Sports Division
            </Text>
          </legend>
          <CheckboxGroupFilter
            options={{I: 'I', II: 'II', III: 'III'}}
            name="ncaa-division"
            onChange={getColleges}
          />
        </fieldset>
      </div>
    );
  }
}

export default NcaaFilters;
