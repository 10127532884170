import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {map} from 'lodash';
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import Carousel from 'shared/components/carousel';
import Label from 'shared/components/label';
import ResourceCard from 'shared/components/resource-card';
import {TODAY} from 'shared/utils';
import Awards from './awards';
import ScholarshipCTALink from 'shared/college-profile/components/scholarship-cta-link';

class Scholarship extends React.Component {
  static propTypes = {
    scholarship: PropTypes.object.isRequired,
    actionable: PropTypes.bool,
    educator: PropTypes.bool,
    pending: PropTypes.bool,
    openModal: PropTypes.func,
    userInfo: PropTypes.object.isRequired,
    showScholarshipCta: PropTypes.bool,
    showScholarshipResources: PropTypes.bool,
    collegeName: PropTypes.string,
    collegeId: PropTypes.string,
    linkToScholarshipDetails: PropTypes.bool,
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        thumbnail: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
  };

  static defaultProps = {
    actionable: true,
  };

  startDate = () => {
    const {
      scholarship: {startDateMonth, startDateDay, startDateYear},
    } = this.props;

    // eslint-disable-next-line eqeqeq
    if (startDateMonth == null || !startDateDay || !startDateYear) {
      return null;
    } else {
      return new Date(startDateYear, startDateMonth, startDateDay);
    }
  };

  render() {
    const {
      userInfo,
      scholarship,
      actionable,
      educator,
      pending,
      openModal,
      showScholarshipCta,
      showScholarshipResources,
      collegeName,
      collegeId,
      linkToScholarshipDetails,
      resources,
    } = this.props;

    const showResources = showScholarshipResources && !!resources;

    return (
      <div
        className={classnames('college-profile-scholarship', {
          'in-future': TODAY < this.startDate(),
        })}
        key={scholarship.title}
      >
        <div className="college-profile-scholarship-info-container">
          <div className="college-profile-scholarship-text">
            <header className="college-profile-scholarship-title">{scholarship.title}</header>
            <div dangerouslySetInnerHTML={{__html: scholarship.description}} />
            {(scholarship.scholarshipType === 'TimeSensitiveItemScholarship' ||
              scholarship.scholarshipType === 'SurveyScholarship') && (
              <>
                <br />
                <div dangerouslySetInnerHTML={{__html: scholarship.goalAwardText}} />
              </>
            )}
          </div>
          {!educator && actionable && !linkToScholarshipDetails && (
            <ScholarshipCTALink
              scholarship={scholarship}
              pending={pending}
              userInfo={userInfo}
              source="scholarships"
              openModal={openModal}
              showScholarshipCta={showScholarshipCta}
              collegeName={collegeName}
              collegeId={collegeId}
            />
          )}
          {linkToScholarshipDetails && (
            <Link
              className="college-profile-scholarship-cta-link"
              to={`${location.pathname}/${scholarship._id}/details`}
            >
              Details
            </Link>
          )}
        </div>
        {showResources && (
          <Label className="college-profile-scholarship-resources-carousel caption bold">
            Recommended Resources
            <Carousel>
              {map(resources, (resource) => (
                <ResourceCard
                  key={resource.title}
                  title={resource.title}
                  link={resource.link}
                  caption={resource.description}
                  thumbnail={resource.thumbnail}
                  linkName={resource.title}
                />
              ))}
            </Carousel>
          </Label>
        )}
        {!educator && <Awards awards={scholarship.awards} pending={pending} />}
      </div>
    );
  }
}

export default Scholarship;
