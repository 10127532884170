import {filter, find, forEach, includes, isNil, map, uniqBy} from 'lodash';
import {UserType, VerificationStatus} from 'src/types/enums';

export const eligiblePrograms = (scholarshipPrograms, userInfo) => {
  const {type} = userInfo;

  // If educator is a mentor, they can see all programs
  if (type === 'Mentor' || type === UserType.COLLEGE_PORTAL_USER) return scholarshipPrograms;

  // show appropriate programs for educator type
  const programs = filter(scholarshipPrograms, {
    eligiblePhase: type === 'CommunityCollegeStaff' ? 'community-college' : 'high-school',
  });

  return programs;
};

// Calculate if the college is above or below the
// national average +/- 9%.
export const aboveBelowAverage = (value, average) => {
  const offset = average * 0.09;
  const lowerAverage = average - offset;
  const upperAverage = average + offset;

  if (value > upperAverage) {
    return 'Above Average';
  } else if (value < lowerAverage) {
    return 'Below Average';
  } else {
    return 'Average';
  }
};

const addPopularMajors = (majors, majorsParticipation, count = 5) => {
  const filledInMajors = [...majors];
  const chosenCipCodes = map(majors, (major) => major.cipCode);

  for (let i = 0; i < count; i++) {
    const major = majorsParticipation[i];

    if (major && !includes(chosenCipCodes, major.cipCode)) {
      filledInMajors.push({...major});
      chosenCipCodes.push(major.cipCode);
    }
  }

  return filledInMajors;
};

const matchingMajorsForInterest = (majorsParticipation, interest) => {
  const matchedMajors = [];

  for (let i = 0; i < majorsParticipation.length; i++) {
    const major = majorsParticipation[i];

    // if we have an exact match on the interest name
    // then that is the only match we want to return
    if (major.name === interest.name) {
      return [{...major}];
    }

    // if we have an exact match on the cip code we want
    // to capture that as a match, but still check for further matches
    // We also have to account for potentially 0 prefixed cipcodes on majors
    if (major.cipCode === interest.cip || major.cipCode === `0${interest.cip}`) {
      matchedMajors.push({...major});
    }
  }

  if (!interest.cip) {
    return matchedMajors;
  }

  const regex = new RegExp(`^0?${interest.cip}${interest.cip.indexOf('.') > -1 ? '' : '\\.'}`);

  for (let i = 0; i < majorsParticipation.length; i++) {
    const major = majorsParticipation[i];

    // if the cip code matches the interest one we want to add it as a match
    // but not if the cip codes are identical we would have already added it previously
    if (major.cipCode.match(regex) && major.cipCode !== interest.cip) {
      matchedMajors.push({...major});
    }

    // we only want to return
    if (matchedMajors.length >= 3) {
      break;
    }
  }

  return matchedMajors;
};

// Returns array of majors that match interests
export const matchAcademicInterests = (allInterests, majorsParticipation, interestIds) => {
  let majors = [];

  forEach(interestIds, (id) => {
    const interest = find(allInterests, {_id: id});

    if (interest) {
      majors = majors.concat(matchingMajorsForInterest(majorsParticipation, interest));
    }
  });

  forEach(majors, (major) => (major.highlighted = true));

  majors = addPopularMajors(majors, majorsParticipation);

  // de-duplicate majors
  majors = uniqBy(majors, 'cipCode');

  // sort majors by percent and return them
  majors.sort((a, b) => b.percent - a.percent);
  return majors;
};

// returns Reach/Likely/Safety based on a score and upper and lower boundaries
export const acceptanceLikelihoodClassification = (studentScore, lowerBound, upperBound) => {
  if (studentScore < lowerBound) {
    return 'Reach';
  } else if (studentScore > upperBound) {
    return 'Safety';
  } else {
    return 'Likely';
  }
};

export const stateGrantLinks = {
  AK: 'https://acpe.alaska.gov/FINANCIAL-AID',
  AL: 'http://www.ache.alabama.gov/Content/Departments/StudentAsst/StudentAsst.aspx',
  AR: 'https://scholarships.adhe.edu/',
  AZ: 'https://azgrants.az.gov/available-grants',
  CA: 'https://www.csac.ca.gov/financial-aid-programs',
  CO: 'https://cof.college-assist.org/',
  CT: 'https://www.ctohe.org/SFA/sfa.shtml#Governor',
  DC: 'https://osse.dc.gov/dctag',
  DE: 'http://delawaregoestocollege.org/',
  FL: 'http://www.floridastudentfinancialaid.org/SSFAD/home/uamain.htm',
  GA: 'https://www.gafutures.org/hope-state-aid-programs/',
  HI: 'https://www.oha.org/scholarships',
  IA: 'https://www.iowacollegeaid.gov/financialaid',
  ID: 'https://boardofed.idaho.gov/scholarships/',
  IL: 'https://www.ibhe.org/gpo.html',
  IN: 'https://www.in.gov/che/4497.htm',
  KS: 'https://www.kansasregents.org/scholarships_and_grants',
  KY: 'https://www.kheaa.com/website/kheaa/kheaaprograms?main=1',
  LA: 'http://www.osfa.la.gov/SandG.htm',
  MA: 'http://www.mass.edu/osfa/programs/quickguide.asp',
  MD: 'http://mhec.maryland.gov/preparing/Pages/FinancialAid/descriptions.aspx',
  ME: 'https://www.famemaine.com/education/topics/paying-for-college/',
  MI: 'https://www.michigan.gov/mistudentaid/0,4636,7-128-60969_61016---,00.html',
  MN: 'https://www.ohe.state.mn.us/mPg.cfm?pageID=1296',
  MO: 'https://dhe.mo.gov/ppc/grants/',
  MS: 'https://www.ms.gov/sfa/normalEntry.jsp',
  MT: 'https://mus.edu/Prepare/Pay/Scholarships/',
  NC: 'https://www.cfnc.org/paying/schol/all_schols.jsp',
  ND: 'http://ndus.edu/students/paying-for-college/grants-scholarships/',
  NE: 'https://ccpe.nebraska.gov/nebraska-opportunity-grant-nog',
  NH: 'https://www.nhheaf.org/scholarships.asp',
  NJ: 'https://www.hesaa.org/Pages/NJGrantsHome.aspx',
  NM: 'http://www.hed.state.nm.us/students/fa_quicklist.aspx',
  NV: 'http://grant.nv.gov/uploadedFiles/grantnvgov/Content/Grant_Resources/MasterScholarship.pdf',
  NY: 'https://www.hesc.ny.gov/pay-for-college/financial-aid/types-of-financial-aid.html',
  OH: 'https://www.ohiohighered.org/sgs',
  OK: 'https://www.okcollegestart.org/Financial_Aid_Planning/Scholarships/Scholarships.aspx',
  OR: 'https://oregonstudentaid.gov/',
  PA: 'https://www.pheaa.org/funding-opportunities/state-grant-program/',
  RI: 'https://www.rischolarships.org/?__hssc=4661932.7.1532404478420&__hstc=4661932.ac899349970327801606b940696fea39.1532404478420.1532404478420.1532404478420.1&hsCtaTracking=698e6791-d569-4451-bbd3-1f270c34f3d8%7C0b95902e-339f-4451-b4fe-54e417dcc07e',
  SC: 'http://www.che.sc.gov/Students,FamiliesMilitary/PayingForCollege/FinancialAssistanceAvailable/ScholarshipsGrantsforSCResidents.aspx',
  SD: 'http://doe.sd.gov/scholarships/',
  TN: 'https://www.tn.gov/collegepays/money-for-college.html',
  TX: 'http://www.collegeforalltexans.com/apps/financialaid/tofa.cfm?Kind=GS',
  UT: 'https://stepuputah.com/paying-for-college/',
  VA: 'http://www.schev.edu/index/tuition-aid/financialaid/state-student-aid',
  VT: 'http://www.vsac.org/pay/student-aid-options/grants',
  WA: 'https://www.wsac.wa.gov/sfa-overview',
  WI: 'http://heab.state.wi.us/programs.html#WG',
  WV: 'https://secure.cfwv.com/Financial_Aid_Planning/Scholarships/_default.aspx',
  WY: 'http://www.uwyo.edu/admissions/scholarships/residents/',
};

export const EARNING_STATUSES = [
  'earning',
  'submitted',
  'earning_deadline_soon',
  'submission_period_deferred',
];

export const SCHOLARSHIP_TYPES_WITH_NO_CTA = ['ClaimedAccountScholarship'];

export const scholarshipDeadlinePassed = (scholarship, programEnded = false) => {
  if (
    isNil(scholarship.endDateDay) ||
    isNil(scholarship.endDateMonth) ||
    isNil(scholarship.endDateYear)
  )
    return programEnded;

  const now = new Date();
  const deadline = new Date(
    scholarship.endDateYear,
    scholarship.endDateMonth,
    scholarship.endDateDay,
    23,
    59,
    59
  );

  return deadline <= now;
};

export const hasStartDatePassed = (scholarship) => {
  if (
    isNil(scholarship.startDateDay) ||
    isNil(scholarship.startDateMonth) ||
    isNil(scholarship.startDateYear)
  ) {
    return true; // default to true if start date not present or not properly set
  }
  const startDate = new Date(
    scholarship.startDateYear,
    scholarship.startDateMonth,
    scholarship.startDateDay,
    0,
    0,
    0
  );
  return startDate < new Date();
};

const activeVerification = (scholarship) =>
  scholarship.verificationStatus !== VerificationStatus.REJECTED;

export const groupCurrentAndCompleteScholarships = (partnerScholarships) => {
  const scholarships = Array.isArray(partnerScholarships) ? partnerScholarships : [];

  return scholarships.reduce(
    (result, scholarship) => {
      if (!hasStartDatePassed(scholarship)) return result;

      const isWithinDeadline = !scholarshipDeadlinePassed(scholarship);
      const hasAwards = scholarship.awards && scholarship.awards.length > 0;
      const hasActiveVerification = activeVerification(scholarship);

      if (hasAwards && (hasActiveVerification || isWithinDeadline)) {
        result.completedScholarships.push(scholarship);
        return result;
      }

      if (hasAwards && !hasActiveVerification && !isWithinDeadline) {
        result.currentScholarships.push(scholarship);
      } else if (!hasAwards && isWithinDeadline) {
        result.currentScholarships.push(scholarship);
      }

      return result;
    },
    {currentScholarships: [], completedScholarships: []}
  );
};
