import {getCollege} from 'shared/actions/college';
import {closeModal, openModal} from 'shared/actions/modal';
import {errorMessage, flashMessage} from 'shared/actions/notifications';
import {matchAcademicInterests} from 'shared/college-profile/utils';
import req from 'shared/req';
import {parseQueryString, uuid4} from 'shared/utils';
import {UserType} from 'src/types/enums';
import {INITIAL_FIELDS, SIMILAR_COLLEGE_FIELDS, fieldsForUser} from '../fields';

// college tags
import {getStudentCollegeTags} from './student-college-tags';

// modal pending
export const MODAL_SAVED = 'MODAL_SAVED';
export const EARNING_DEADLINES_MODAL_IDENTIFIER = 'college-earning-deadlines-modal';

export const GET_COLLEGE = 'GET_COLLEGE';
export const GET_SIMILAR_COLLEGES = 'GET_SIMILAR_COLLEGES';
export const GET_SIMILAR_PARTNER_COLLEGES = 'GET_SIMILAR_PARTNER_COLLEGES';
export const GET_COLLEGE_TESTIMONIALS = 'GET_COLLEGE_TESTIMONIALS';
export const FOLLOW_COLLEGE = 'FOLLOW_COLLEGE';
export const UNFOLLOW_COLLEGE = 'UNFOLLOW_COLLEGE';
export const FOLLOW_SIMILAR_COLLEGE = 'FOLLOW_SIMILAR_COLLEGE';
export const UNFOLLOW_SIMILAR_COLLEGE = 'UNFOLLOW_SIMILAR_COLLEGE';
export const CHANGE_TAB = 'CHANGE_TAB';
export const GET_OTHER_CAMPUS = 'GET_OTHER_CAMPUS';
export const UNFOLLOW_OTHER_CAMPUS = 'UNFOLLOW_OTHER_CAMPUS';
export const FOLLOW_OTHER_CAMPUS = 'FOLLOW_OTHER_CAMPUS';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const TOGGLE_LOADING_SCHOLARSHIPS = 'TOGGLE_LOADING_SCHOLARSHIPS';
export const CLEAR_COLLEGE_INFO = 'CLEAR_COLLEGE_INFO';
export const CHANGE_SCHOLARSHIP_PROGRAM = 'CHANGE_SCHOLARSHIP_PROGRAM';
export const UPDATE_EARNING_STATUS = 'COLLEGE_PROFILE/UPDATE_EARNING_STATUS';
export const SET_EARLY_SUBMISSION_SUBMITTING = 'COLLEGE_PROFILE/SET_EARLY_SUBMISSION_SUBMITTING';

const EARNING_STATUSES = {
  submitted: 'submitted',
};

const updateEarningStatus = (statusType) => ({
  type: UPDATE_EARNING_STATUS,
  statusType,
});

const setEarlySubmissionSubmitting = (submitting) => ({
  type: SET_EARLY_SUBMISSION_SUBMITTING,
  submitting,
});

export const openSubmitEarlyModal = () => (dispatch) => {
  dispatch(setEarlySubmissionSubmitting(true));
  dispatch(openModal('submitEarlyModal'));
};

export const closeSubmitEarlyModal = () => (dispatch) => {
  dispatch(setEarlySubmissionSubmitting(false));
  dispatch(closeModal('submitEarlyModal'));
};

export const openCollegeTaggingModal = () => (dispatch) => {
  dispatch(openModal('collegeTaggingModal'));
};

export const closeCollegeTaggingModal = () => (dispatch) => {
  dispatch(closeModal('collegeTaggingModal'));
};

export const submitToUniversity = (universityId) => (dispatch) => {
  dispatch(closeModal('submitEarlyModal'));

  return req({
    url: '/v1/university_submissions',
    method: 'post',
    data: {universityId},
  }).then(({earningStatus}) => {
    dispatch(setEarlySubmissionSubmitting(false));

    if (earningStatus !== EARNING_STATUSES.submitted) {
      dispatch(flashMessage('Congratulations! Your profile has been successfully submitted'));
      dispatch(updateEarningStatus(EARNING_STATUSES.submitted));
    } else {
      dispatch(errorMessage('Whoops! You could not be submitted at this time.  Please try again.'));
    }
  });
};

export const receiveCollege = (college) => ({
  type: GET_COLLEGE,
  college,
});

export const fetchInitialCollegeInfo = (id) => (dispatch) =>
  dispatch(fetchCollegeFields(id, INITIAL_FIELDS));

export const fetchCollegeFields = (id, fields) => (dispatch) =>
  getCollege(id, fields.join(',')).then((college) => dispatch(receiveCollege(college)));

export const fetchCollege = (id, user) => (dispatch) =>
  dispatch(fetchCollegeFields(id, fieldsForUser(user))).then(
    () => user === UserType.HS_STUDENT && dispatch(getStudentCollegeTags())
  );

export const fetchPartnerScholarships = (id) => (dispatch) =>
  dispatch(fetchCollegeFields(id, ['partnerScholarships']));

export function getCollegeTestimonials(id) {
  return (dispatch) => {
    const url = `/v1/colleges/${id}/testimonials`;
    return req({
      url: url,
      method: 'get',
    }).then((testimonials) => {
      dispatch({
        type: GET_COLLEGE_TESTIMONIALS,
        testimonials,
      });
    });
  };
}

export function getSimilarPartnerColleges(id) {
  const bucketShownEventId = uuid4();
  const url = `/v1/colleges/similar/${id}`;
  return (dispatch, getState) => {
    const {collegeProfile} = getState();
    return req({
      url: url,
      method: 'get',
      data: {
        limit: 3,
        'with-microscholarships': 1,
        fields: SIMILAR_COLLEGE_FIELDS.join(','),
        'num-buckets-shown': 2,
        'recommendations-shown-event-id': collegeProfile.eventIds.recsShownEventId,
        'bucket-shown-event-id': bucketShownEventId,
        'bucket-index': 0,
      },
    }).then((colleges) => {
      dispatch({
        type: GET_SIMILAR_PARTNER_COLLEGES,
        bucketShownEventId,
        colleges,
      });
    });
  };
}

// Bucket index is hardcoded.
// On the right-rail sidebar, Similar Partner Colleges is shown to high school students as the first bucket.
// Similar Colleges are shown as the second bucket on a College Profile page.
export function getSimilarColleges(id) {
  const bucketShownEventId = uuid4();
  const url = `/v1/colleges/similar/${id}`;
  return (dispatch, getState) => {
    const {collegeProfile} = getState();
    return req({
      url: url,
      method: 'get',
      data: {
        limit: 10,
        fields: SIMILAR_COLLEGE_FIELDS.join(','),
        'partner-pos': '3,5,7',
        'num-buckets-shown': 2,
        'recommendations-shown-event-id': collegeProfile.eventIds.recsShownEventId,
        'bucket-shown-event-id': bucketShownEventId,
        'bucket-index': 1,
      },
    }).then((colleges) => {
      dispatch({
        type: GET_SIMILAR_COLLEGES,
        bucketShownEventId,
        colleges,
      });
    });
  };
}

export function getOtherCampus(id) {
  const url = `/v1/colleges/${id}`;
  return (dispatch) =>
    req({
      url: url,
      method: 'get',
      data: {fields: SIMILAR_COLLEGE_FIELDS.join(',')},
    }).then((campus) => {
      dispatch({
        type: GET_OTHER_CAMPUS,
        campus,
      });
    });
}

export function toggleFollowCollege(id, toggle, collegeType = null, bucketShownEventId = null) {
  return (dispatch, getState) => {
    const {collegeProfile} = getState();
    const url = `/v1/colleges/${id}/${toggle}`;
    let data = {source: 'college-listing'};
    let type;

    let queryParams = {};
    const {search} = window.location;
    if (search && search.length) {
      queryParams = parseQueryString(search.substring(1));
    }
    const {bucketEventId, recsShownEventId} = queryParams;

    switch (collegeType) {
      case 'similar':
        type = `${toggle.toUpperCase()}_SIMILAR_COLLEGE`;
        // Similar Colleges are shown on a college profile page and be followed via a button on that page.  As there are no redirect involved, eventIds can be retrieved from app state.
        data = {
          ...data,
          recommendationsShownEventId: collegeProfile.eventIds.recsShownEventId,
          bucketShownEventId,
          fromCollegeId: collegeProfile.college._id,
        };
        break;
      case 'campus':
        type = `${toggle.toUpperCase()}_OTHER_CAMPUS`;
        data = {
          ...data,
          fromCollegeId: collegeProfile.college._id,
        };
        break;
      default:
        type = `${toggle.toUpperCase()}_COLLEGE`;
        // Redux Store is lost on a redirect from the Recommendations page.  To pass eventIds, we include them in the params.
        if (bucketEventId && recsShownEventId) {
          data = {
            ...data,
            recommendationsShownEventId: recsShownEventId,
            bucketShownEventId: bucketEventId,
            fromCollegeId: collegeProfile.college._id,
          };
        }
    }

    return req({
      url: url,
      method: 'post',
      data,
    }).then((college) => {
      const {relationship} = college;
      dispatch({
        type,
        id,
        relationship,
      });
    });
  };
}

export function changeTab(index) {
  return {type: CHANGE_TAB, index};
}

export function setMajors(academicInterestIds) {
  return (dispatch, getState) => {
    const state = getState();
    const majors = matchAcademicInterests(
      state.lists.academicInterests,
      state.collegeProfile.college.majorsParticipation,
      academicInterestIds
    );

    return {
      type: GET_COLLEGE,
      college: {majors},
      loading: false,
    };
  };
}

export function toggleLoading(loading) {
  return {type: TOGGLE_LOADING, loading};
}

export function toggleLoadingScholarships(loading) {
  return {type: TOGGLE_LOADING_SCHOLARSHIPS, loading};
}

export function clearCollegeInfo() {
  return {type: CLEAR_COLLEGE_INFO, recomendationShownEventId: uuid4()};
}

export function changeScholarshipProgram(name) {
  return {type: CHANGE_SCHOLARSHIP_PROGRAM, name};
}

export function setModalPending(category) {
  return {type: MODAL_SAVED, category};
}
