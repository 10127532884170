import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'shared/components/icon';
import linkify from 'shared/linkify';

const propTypes = {
  amount: PropTypes.number,
  name: PropTypes.string.isRequired,
  aboutEarningsText: PropTypes.string,
};

const AboutYourEarnings = ({amount, name, aboutEarningsText}) => {
  const roundedAmount = Math.round(amount) || 100;
  const formattedHypothetical = (roundedAmount + 1500).toLocaleString();
  const formattedAmount = roundedAmount.toLocaleString();

  if (aboutEarningsText) {
    return aboutEarningsText
      .split('\n')
      .map((value, i) => (
        <p key={i} dangerouslySetInnerHTML={{__html: linkify(value, {newTab: true})}} />
      ));
  }

  return (
    <div className="shared-college-profile-requirements">
      <p>
        Your Micro-Scholarships represent a minimum amount of scholarship and grant aid you will
        receive from {name} if you apply, are admitted, and enroll.
      </p>

      <p>
        You may be eligible for more aid based on your formal application for admission and
        financial aid forms. If you receive a higher amount of scholarship and grant aid in your
        final award letter from {name}, note that this includes the amount you earned on RaiseMe and
        is <strong>not in addition to</strong> your earnings on RaiseMe.{' '}
        <a
          target="_blank"
          href="https://s3.amazonaws.com/growth-resources/RaiseMe+Award+Letter+Breakdown.pdf"
          rel="noopener noreferrer"
        >
          Learn more about your award letter here <Icon iconType="external-link-alt" />
        </a>
      </p>

      <p>
        For example, if you&#39;ve earned ${formattedAmount} from {name} on RaiseMe, your $
        {formattedAmount} represents the minimum amount of scholarship and grant aid you will
        receive if you apply, are admitted, and enroll. If upon acceptance to {name}, you receive a
        larger financial aid package of ${formattedHypothetical}, it&#39;s important to understand
        that the ${formattedAmount} you earned on RaiseMe is included in the final $
        {formattedHypothetical} award you received and is <strong>not in addition to</strong> your
        earnings on RaiseMe.
      </p>
    </div>
  );
};

AboutYourEarnings.propTypes = propTypes;

export default AboutYourEarnings;
