import {without} from 'lodash';
import {RECEIVE_USER_CONNECTIONS, RECEIVE_USER_CONNECTION, REMOVE_USER_CONNECTION} from './actions';

export default function (state = [], action) {
  switch (action.type) {
    case RECEIVE_USER_CONNECTIONS:
      return action.userConnections;
    case RECEIVE_USER_CONNECTION:
      return [...state, action.userConnection];
    case REMOVE_USER_CONNECTION:
      return without(state, action.userConnection);
    default:
      return state;
  }
}
