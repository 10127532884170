import {apps, onboardingApps} from './apps';
import {isOnboardingPath, UserSession} from 'src/actions/validateSession';
import {UserIdentifier} from 'src/types/enums';
import {AppDeclaration} from 'src/apps/types';

export const getApp = (
  userIdentifier: UserIdentifier,
  next: UserSession['next']
): undefined | AppDeclaration => {
  if (isOnboardingPath(next)) {
    if (onboardingApps[userIdentifier]) {
      return onboardingApps[userIdentifier];
    }

    console.warn(`Missing onboarding application for ${userIdentifier}`);
    return undefined;
  }

  return apps[userIdentifier];
};

export default apps;
