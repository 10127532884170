import PropTypes from 'prop-types';
import React from 'react';
import GPASection from './gpa-section';
import SATSection from './sat-section';
import ACTSection from './act-section';
import ACTTable from './act-table';
import Icon from 'shared/components/icon';
import SATTable from './sat-table';
import ScholarshipCTA from 'shared/college-profile/containers/about-college/scholarship-cta';
import Card from 'src/components/Cards/Card';
import CollapsibleTeaserSection from 'shared/components/collapsible-teaser-section';
import MiniInfoCardContainer from 'shared/components/college-profile/mini-info-card-container';
import MiniInfoCard from 'shared/components/college-profile/mini-info-card';
import NewTabTooltip from 'shared/components/new-tab-tooltip';
import {gradeLevelForGraduationYear} from 'shared/utils';

class AboutAdmissions extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    showGPASection: PropTypes.bool,
    showTestsSection: PropTypes.bool,
    showTestScores: PropTypes.bool,
  };

  showAdmissability() {
    const {
      userInfo: {highSchoolInfo},
    } = this.props;

    if (!highSchoolInfo) {
      return false;
    }

    const gradeLevel = gradeLevelForGraduationYear(highSchoolInfo.graduationYear);

    return gradeLevel === 'senior' || gradeLevel === 'junior';
  }

  render() {
    const {
      college,
      college: {
        acceptanceRate,
        actPercentSubmitting,
        applicationFee,
        partnerScholarships,
        admissionsWebsite,
        applicationWebsite,
        satPercentSubmitting,
      },
      userInfo,
      showGPASection,
      showTestsSection,
      showTestScores,
    } = this.props;

    const showAdmissability = this.showAdmissability();

    return (
      <Card className="zero-bottom-padding">
        <CollapsibleTeaserSection
          title="Admissions"
          trackIdentifier="college-listing-about-submissons"
          className="college-profile-card-about-content"
        >
          <CollapsibleTeaserSection.Teaser>
            <MiniInfoCardContainer>
              <MiniInfoCard
                iconType="envelope"
                label="Acceptance Rate"
                value={acceptanceRate ? `${Math.round(acceptanceRate)}%` : null}
              />
              <MiniInfoCard
                iconType="money-bill-alt"
                label={
                  <span>
                    Application
                    <br />
                    Fee
                  </span>
                }
                value={applicationFee ? `$${applicationFee}` : null}
              />
              {showTestScores && (
                <MiniInfoCard
                  iconType="file-alt"
                  label={
                    <span>
                      SAT Scores on
                      <br />
                      Applications
                    </span>
                  }
                  value={satPercentSubmitting ? `${satPercentSubmitting}%` : null}
                />
              )}
              {showTestScores && (
                <MiniInfoCard
                  iconType="file-alt"
                  label={
                    <span>
                      ACT Scores on
                      <br />
                      Applications
                    </span>
                  }
                  value={actPercentSubmitting ? `${actPercentSubmitting}%` : null}
                />
              )}
            </MiniInfoCardContainer>
            {showGPASection && (
              <div>
                <GPASection
                  college={college}
                  userInfo={userInfo}
                  showAdmissability={showAdmissability}
                />
              </div>
            )}
          </CollapsibleTeaserSection.Teaser>
          <CollapsibleTeaserSection.Body>
            {showTestsSection && (
              <div>
                <SATSection
                  college={college}
                  userInfo={userInfo}
                  showAdmissability={showAdmissability}
                />
                <SATTable
                  college={college}
                  userInfo={userInfo}
                  showAdmissability={showAdmissability}
                />
                <ACTSection
                  college={college}
                  userInfo={userInfo}
                  showAdmissability={showAdmissability}
                />
                <ACTTable
                  college={college}
                  userInfo={userInfo}
                  showAdmissability={showAdmissability}
                />
                <ScholarshipCTA scholarships={partnerScholarships} type="scores" />
              </div>
            )}
            <div className="college-profile-section-link-menu bottom-padding">
              {!!admissionsWebsite && (
                <a
                  href={admissionsWebsite}
                  target="_blank"
                  aria-label="Admissions Site (opens in new tab)"
                  rel="noreferrer"
                >
                  <Icon iconType="external-link-alt" />
                  Admissions Site
                </a>
              )}
              {!!applicationWebsite && (
                <NewTabTooltip>
                  <a
                    href={applicationWebsite}
                    target="_blank"
                    aria-label="View Application (opens in new tab)"
                    rel="noreferrer"
                  >
                    <Icon iconType="file-alt" />
                    View Application
                  </a>
                </NewTabTooltip>
              )}
            </div>
          </CollapsibleTeaserSection.Body>
        </CollapsibleTeaserSection>
      </Card>
    );
  }
}

export default AboutAdmissions;
