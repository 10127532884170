import React from 'react';
import PropTypes from 'prop-types';
import Separator from 'shared/components/separator';

export default class NotAvailable extends React.Component {
  static propTypes = {
    setNavigationAnnouncement: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setNavigationAnnouncement('Sign Up - Not Available');
  }

  render() {
    return (
      <>
        <h1 className="auth-signup-header">
          Sorry, currently RaiseMe is just available to students in high school or at U.S. community
          colleges.
        </h1>
        <p className="learn-about-whos-eligible">
          <a
            href="https://help.raise.me/hc/en-us/articles/115001992367-Is-RaiseMe-only-for-high-school-students"
            className="auth-shared-link"
          >
            Learn more
          </a>{' '}
          about who is eligible
        </p>
        <Separator></Separator>
      </>
    );
  }
}
