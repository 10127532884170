import React, {ReactElement, useEffect} from 'react';
import StudentReferralItem from './StudentReferralItem';
import FormButton from 'src/components/Button';
import {ReduxFormField} from 'src/types/form';
import {noop} from 'lodash';
import styles from './styles.module.scss';

export interface StudentReferralProps {
  addReferral: () => void;
  minReferralsLength: number;
  placeholderText: string;
  referrals: {contactInfo: ReduxFormField}[];
  referralsEnabled: boolean;
  removeReferral: (index: number) => void;
  showAddButton: boolean;
  showRemoveButton: boolean;
  title: string;
}

export default function StudentReferrals(props: StudentReferralProps): ReactElement | null {
  const referrals = props.referrals;
  useEffect(() => {
    // update until reaches minimum count
    if (props.referralsEnabled && referrals.length < props.minReferralsLength) {
      props.addReferral();
    }
  }, [referrals.length]);

  if (!props.referralsEnabled) return null;

  return (
    <div data-testId="student-referrals">
      <h6 className={styles.label}>{props.title}</h6>
      {referrals.map((referral, i) => (
        <StudentReferralItem
          remove={(): void => props.removeReferral(i)}
          contactInfo={referral.contactInfo}
          placeholderText={props.placeholderText}
          showRemove={props.showRemoveButton !== undefined ? props.showRemoveButton : !!i}
          key={i}
        />
      ))}
      {props.showAddButton && (
        <div className={styles.button}>
          <FormButton
            color="primary"
            outline
            disabled={false}
            margin={false}
            onClick={props.addReferral}
            type="button"
            buttonName="portfolio student-referrals invite-another-friend"
          >
            Invite Another Friend
          </FormButton>
        </div>
      )}
    </div>
  );
}

StudentReferrals.defaultProps = {
  referralsEnabled: true,
  minReferralsLength: 1,
  removeReferral: noop,
  showAddButton: true,
} as Partial<StudentReferralProps>;
