import {compact, flow, map, split, trim, union} from 'lodash/fp';
import {UserType} from 'src/types/enums';

// Lodash `words` splits camelCase words :/
const words = flow(split(' '), map(trim), compact);

export const INITIAL_FIELDS = words('name logo bannerHero programId');

// Fields shared between educator and HS student college profile.
const COLLEGE_FIELDS = words(`
  aboutEarnings receivingMicroScholarships city state slug setting control isPartner firstYearRetention
  fourYearGraduationRate sixYearGraduationRate sixYearGraduationRateByEthnicity
  acceptanceRate averageGPA applicationFee actComposite actEnglish actMath
  actWriting actPercentSubmitting percentLivingOnCampus ethnicDiversity
  genderDiversity applicationWebsite salaryAfterGraduation missionStatement
  nationalCollegeData averageNetPrice tuition onCampusHousingCost
  offCampusHousingCost netPriceByHouseholdIncome facebook twitter loc majors tiktok
  majorsParticipation totalUndergraduates studentFacultyRatio
  averageFinancialAid studentsEarningAboveHighSchoolGrads
  pctStudentsPayingDownDebt admissionsWebsite typicalMonthlyStudentLoanPayment
  satPercentSubmitting avgSatScore size satCriticalReadingRange
  satMathematicsRange financialAidWebsite email financialAidBreakdown
  netPriceCalculator website category profitStatus
  partnerSubmissionDeadlineMonth partnerSubmissionDeadlineDay
  partnerSubmissionDeadlineYear partnerSubmissionDeadlineTerm instagram youtube
  nickname followable forbesRank forbesRankYear usNewsRank usNewsRankYear usNewsListType 
  otherCampusListingsIds campusesGroupName onCampusOtherCosts booksAndSuppliesCost earningsPossible
  universityId featureFlags popularTags collegeImages enableCampusReelVideos
  customQuickStats`);

// Fields specific to the HS student view of the college profile.
const HS_STUDENT_COLLEGE_FIELDS = words(`
  isFollowing amount maxEarnings earningRelationship unsanitizedPartnerOngoingRequirements
  partnerOngoingRequirements partnerMinGpa partnerMinCredits divisor
  partnerApplicationDeadlineDay partnerApplicationDeadlineMonth
  applicationStatus
`);

// Fields specific to the CC student view of the college profile.
const CC_STUDENT_COLLEGE_FIELDS = words(`
  isFollowing amount maxEarnings earningRelationship unsanitizedPartnerOngoingRequirements
  partnerOngoingRequirements partnerMinGpa partnerMinCredits
  partnerApplicationDeadlineDay
  partnerApplicationDeadlineMonth divisor
  partnerSubmissionDeadlineDay partnerSubmissionDeadlineMonth
  partnerSubmissionDeadlineYear partnerSubmissionDeadlineTerm
  partnerUpcomingSubmissionPeriods transferMissionStatement
`);

// Fields specific to the undergraduate view of the college profile.
const UNDERGRADUATE_COLLEGE_FIELDS = words(`
  name logo bannerHero city state amount earningRelationship
  partnerScholarships unsanitizedPartnerOngoingRequirements minimumEarningsToRedeem redeemableTerm
`);

// Fields to pull from recommendation engine.
export const SIMILAR_COLLEGE_FIELDS = words(
  'slug logo name city state earningRelationship isFollowing nickname'
);

export const fieldsForUser = (user) =>
  ({
    [UserType.HS_STUDENT]: union(COLLEGE_FIELDS, HS_STUDENT_COLLEGE_FIELDS),
    [UserType.CC_STUDENT]: union(COLLEGE_FIELDS, CC_STUDENT_COLLEGE_FIELDS),
    [UserType.EDUCATOR]: union(COLLEGE_FIELDS, ['scholarshipPrograms']),
    [UserType.COLLEGE_PORTAL_USER]: union(COLLEGE_FIELDS, ['scholarshipPrograms']),
    [UserType.UNDERGRADUATE_STUDENT]: union(COLLEGE_FIELDS, UNDERGRADUATE_COLLEGE_FIELDS),
    [UserType.ADULT_LEARNER]: union(COLLEGE_FIELDS, UNDERGRADUATE_COLLEGE_FIELDS),
  })[user] || COLLEGE_FIELDS;
