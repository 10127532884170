// admin/university
import {uploadPhotoListings} from 'src/redux/adminUniversity/actions/photoListing';

export const parseCommaSeparatedValue = (value) =>
  value
    .split(',')
    .map((s) => s.trim())
    .filter((s) => !!s);

export const toCommaSeparatedValue = (array) => {
  if (!array) return '';
  return array.join(',');
};

export const submitCollegePhotos = (university_id, addPhotos = [], dispatch) =>
  Promise.all(
    addPhotos.map((photo) => {
      const data = {
        college_image: photo[1].replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
        filename: photo[0],
      };
      return dispatch(uploadPhotoListings(university_id, data, dispatch));
    })
  );
