import {lazy} from 'react';
import {RoutedModalConfig} from '../ModalContext/types';
import {PortfolioItemModalParams} from '../PortfolioItemModal';

export const collegeCourseModalConfig: RoutedModalConfig<PortfolioItemModalParams> = {
  type: 'course',
  routed: true,
  Component: lazy(
    () => import(/* webpackChunkName: "CollegeCourseModal" */ './CollegeCourseModal')
  ),
};
