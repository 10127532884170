// account-conversion
import {INCREMENT_STEP, DECREMENT_STEP} from '../actions';
import {getPreviousStep, getNextStep} from '../navigation';

const accountConversionReducer = (state = {accountConversionStep: 'email'}, action) => {
  switch (action.type) {
    case INCREMENT_STEP: {
      const nextStep = getNextStep(state.accountConversionStep);

      return {...state, accountConversionStep: nextStep, animationDirection: 'left'};
    }
    case DECREMENT_STEP: {
      const prevStep = getPreviousStep(state.accountConversionStep);

      return {...state, accountConversionStep: prevStep, animationDirection: 'right'};
    }
    default:
      return state;
  }
};

export default accountConversionReducer;
