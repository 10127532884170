import {RefObject} from 'react';

export const DISMISS_TOOLBAR_NOTIFICATION = 'DISMISS_TOOLBAR_NOTIFICATION';
export const SHOW_TOOLBAR_NOTIFICATION = 'SHOW_TOOLBAR_NOTIFICATION';

interface ToolbarNotificationAction {
  type: string;
  payload?: {
    ref: RefObject<HTMLDivElement>;
  };
}
export const dismissToolbarNotification = (): ToolbarNotificationAction => ({
  type: DISMISS_TOOLBAR_NOTIFICATION,
});

export const showToolbarNotification = (ref): ToolbarNotificationAction => ({
  type: SHOW_TOOLBAR_NOTIFICATION,
  payload: {
    ref,
  },
});
