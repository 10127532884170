import React from 'react';
import {IonPage, IonSpinner} from '@ionic/react';
import PrimaryLogo from 'shared/img/primary-stacked-logo.svg';
import {Box} from '@mui/material';
import styles from './styles.module.scss';

export default function LaunchScreen(): React.ReactElement {
  return (
    <IonPage>
      <Box className={styles.IonicWrapperBox}>
        <div className={styles.centeredComponent}>
          <PrimaryLogo width={150} />
          <IonSpinner color="light" className={styles.loadingSpinnner} />
        </div>
      </Box>
    </IonPage>
  );
}
