import React from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from './header-container';
import vars from '../../src/utils/vars';
import Button from '../../src/components/Button';
import {handleUnauthorizedRequest} from '../../src/actions/validateSession';

class EmptyHeader extends React.Component {
  static propTypes = {
    signOutButton: PropTypes.bool,
    signInButton: PropTypes.bool,
    impostorUserId: PropTypes.string,
    switchUser: PropTypes.func,
  };

  static defaultProps = {
    signOutButton: true,
    signInButton: false,
  };

  render() {
    const {signOutButton, signInButton, switchUser, impostorUserId} = this.props;

    if (impostorUserId) {
      return (
        <HeaderContainer>
          <button
            className="nav-button impostor-exit-button"
            type="button"
            onClick={() => switchUser(impostorUserId)}
          >
            Exit
          </button>
        </HeaderContainer>
      );
    }

    const logoutButton = (
      <>
        {vars.isSPA ? (
          <Button
            onClick={handleUnauthorizedRequest}
            className="shared-button onboarding-logout-button"
          >
            Log Out
          </Button>
        ) : (
          <a href="/logout" key="logout" className="nav-button nav-signout">
            Log Out
          </a>
        )}
      </>
    );

    return (
      <HeaderContainer>
        {signOutButton && logoutButton}
        {signInButton && (
          <a data-cy="sign-in" className="nav-button nav-signout" href="/login">
            Log In
          </a>
        )}
      </HeaderContainer>
    );
  }
}

export const EmptyHeaderWithSignOutButton = () => <EmptyHeader signOutButton />;

export default EmptyHeader;
