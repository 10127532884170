import PropTypes from 'prop-types';
import React from 'react';
import {throttle} from 'lodash';
import LoadingCard from './loading-card';
import SubmitEarlyModal from 'shared/college-profile/containers/submit-early-modal';
import Notifications from 'shared/components/notifications';
import Sticky from 'src/components/Sticky';
import {getMedia, watchMedia} from 'shared/mq';

class Layout extends React.Component {
  static propTypes = {
    // Include the ID of the current college.
    collegeProfile: PropTypes.object.isRequired,
    // Function used to get bare minimum college data for the loading state.
    fetchInitialCollegeInfo: PropTypes.func.isRequired,
    // Function used for getting college data.
    fetchCollege: PropTypes.func.isRequired,
    // Function used for getting partner scholarships.
    fetchPartnerScholarships: PropTypes.func.isRequired,
    // Function used for getting college testimonials.
    getCollegeTestimonials: PropTypes.func.isRequired,
    // Function used to get information about the user
    getUserInfo: PropTypes.func.isRequired,
    // Whether or not to show similar colleges
    showSimilarColleges: PropTypes.bool,
    // Function used to get similar colleges from rec engine.
    getSimilarColleges: PropTypes.func.isRequired,
    // react router provided params if in routed app
    match: PropTypes.object,
    // Function to set loading value
    toggleLoading: PropTypes.func.isRequired,
    // Function to set loading value for scholarships.
    toggleLoadingScholarships: PropTypes.func.isRequired,
    // reset the navbar college search
    resetNavBarSearch: PropTypes.func.isRequired,
    // Function to clear logo and banner on component update
    clearCollegeInfo: PropTypes.func.isRequired,
    RightRail: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    // Function to notify screen reader users of a page change
    setNavigationAnnouncement: PropTypes.func,
    // whether or not to trigger a follow-up async request for partnerScholarships
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    id: PropTypes.string,
  };

  static defaultProps = {
    showSimilarColleges: false,
    getUserInfo: () => Promise.resolve(),
    RightRail: null,
    children: null,
  };

  state = {
    sticky: false,
    hideCards: false,
  };

  componentDidMount() {
    const {clearCollegeInfo, toggleLoading, toggleLoadingScholarships} = this.props;

    clearCollegeInfo();
    toggleLoading(true);
    toggleLoadingScholarships(true);

    this.loadData();
    this.rightRail = document.querySelector('.college-profile-right-rail');
    this.throttledHandleScroll = throttle(() => {
      this.handleScroll();
    }, 500);
    window.addEventListener('scroll', this.throttledHandleScroll);
    this.mq = watchMedia(() => this.forceUpdate());
  }

  componentDidUpdate(prevProps) {
    if (!this.props.match || !prevProps.match) {
      return;
    }

    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.props.clearCollegeInfo();
      this.props.toggleLoading(true);
      this.props.toggleLoadingScholarships(true);
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.mq.remove();
    window.removeEventListener('scroll', this.throttledHandleScroll);
  }

  loadData() {
    this.props.resetNavBarSearch();

    const {
      fetchCollege,
      fetchInitialCollegeInfo,
      fetchPartnerScholarships,
      getCollegeTestimonials,
      getUserInfo,
      showSimilarColleges,
      getSimilarColleges,
      match,
      collegeProfile: {
        userInfo: {user},
        id: collegeId,
      },
      toggleLoading,
      toggleLoadingScholarships,
      setNavigationAnnouncement,
      id,
    } = this.props;

    let collegeIdentifier = collegeId || id;

    // if in routed app, pull slug from react router provided params
    if (match && match.params && match.params.slug) {
      collegeIdentifier = match.params.slug;
    }

    const promises = [
      fetchInitialCollegeInfo(collegeIdentifier).then(() => {
        const {
          collegeProfile: {
            college: {name},
          },
        } = this.props;
        setNavigationAnnouncement(name);
      }),
      getUserInfo(),
      getCollegeTestimonials(collegeIdentifier),
    ];

    // Call this after the user data calls.
    promises.push(fetchCollege(collegeIdentifier, user));

    Promise.all(promises).then(() => {
      // loading is complete
      toggleLoading(false);

      fetchPartnerScholarships(collegeIdentifier).then(() => {
        toggleLoadingScholarships(false);
      });
    });

    // Don't add this to promises, it can finish at anytime.
    showSimilarColleges && getSimilarColleges(collegeIdentifier);
  }

  handleScroll = () => {
    const rightRail = document.querySelector('.college-profile-right-rail');

    if (!rightRail) return;

    if (rightRail.offsetHeight < window.innerHeight - 170) {
      this.setState({sticky: true});
    } else {
      const scrolledPassed = window.scrollY > rightRail.offsetHeight + 300;
      this.setState({sticky: scrolledPassed, hideCards: scrolledPassed});
    }
  };

  renderRightRail() {
    const {RightRail} = this.props;
    const {hideCards} = this.state;

    if (RightRail) {
      return <RightRail hideSecondaryCards={hideCards} />;
    }

    return null;
  }

  render() {
    const {
      children,
      collegeProfile: {college, loading},
    } = this.props;
    const {sticky} = this.state;
    const mq = getMedia();

    if (!college) {
      return null;
    }

    return (
      <div className="college-profile" data-testid="college-profile">
        <Notifications />
        <SubmitEarlyModal />
        {mq.MD_AND_UP && (
          <Sticky top={-78} enabled={sticky} innerZ={4}>
            <div className="college-profile-earnings-container">
              {loading ? <LoadingCard college={college} /> : this.renderRightRail()}
            </div>
          </Sticky>
        )}
        <img className="college-profile-hero" src={college.bannerHero} alt="" aria-hidden="true" />
        {children}
      </div>
    );
  }
}

export default Layout;
