// shared
import req from 'shared/req';
import {START_LOADING, STOP_LOADING} from 'shared/actions/loading';

export const RECEIVE_EDUCATORS = 'DISTRICT_ADMIN/RECEIVE_EDUCATORS';
export const CLEAR_EDUCATORS = 'DISTRICT_ADMIN/CLEAR_EDUCATORS';
export const RECEIVE_VERIFIED_EDUCATOR = 'RECEIVE_VERIFIED_EDUCATOR';

export const EDUCATORS_PER_PAGE = 20;

const FIELDS = [
  '_id',
  'firstName',
  'lastName',
  'email',
  'isVerified',
  'eligibleForVerificationAt',
  'lastActive',
].join(',');

export const fetchEducators =
  (schoolId, options = {}) =>
  (dispatch) => {
    const identifier = 'educators';

    dispatch({
      type: START_LOADING,
      identifier,
    });

    return req({
      url: '/v1/educators',
      method: 'get',
      data: {
        schoolId,
        fields: FIELDS,
        ...options,
      },
    }).then((educators) => {
      dispatch({
        type: RECEIVE_EDUCATORS,
        educators,
      });

      dispatch({
        type: STOP_LOADING,
        identifier,
      });
    });
  };

export const fetchSortedEducators =
  ({sortField, sortDirection, page, limit}) =>
  (dispatch, getState) => {
    const {
      school: {_id},
    } = getState();

    dispatch(fetchEducators(_id, {sortDirection, sortField, limit, skip: page * limit}));
  };

export const clearEducators = () => (dispatch) => dispatch({type: CLEAR_EDUCATORS});

export const receiveVerifiedEducator = (id) => ({
  type: RECEIVE_VERIFIED_EDUCATOR,
  id,
});

export const verifyEducator =
  ({id}) =>
  (dispatch) =>
    req({
      url: '/v1/educator-verification/verify',
      method: 'post',
      data: {
        _id: id,
      },
    })
      .then((verifiedEducator) => {
        dispatch(receiveVerifiedEducator(verifiedEducator._id));
        dispatch(flashMessage('Thank you, you have verified your colleague!'));
      })
      .catch(() => {
        dispatch(errorMessage());
      });
