import {connect} from 'react-redux';
import CollegeApplicationStatusMenu from '../components/college-application-status-menu';
import {setCollegeAppStatus, getCollegeAppStatus} from '../actions/college-application-status';

const mapStateToProps = ({collegeApplicationStatus}) => ({collegeApplicationStatus});

const mapDispatchToProps = (dispatch) => ({
  setCollegeAppStatus: (collegeId, status, segmentEventLocation) => {
    dispatch(setCollegeAppStatus(collegeId, status, segmentEventLocation));
  },
  getCollegeAppStatus: (collegeId) => {
    dispatch(getCollegeAppStatus(collegeId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CollegeApplicationStatusMenu);
