import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// college-search-bar
import CollegeSearchBar from '../components';
import UserSearchBar from '../components/educator';
import {
  fetchCollegeSearchBarData,
  updateQuery,
  receiveResults,
  updateSelected,
  resetSearch,
} from '../actions';

const mapStateToProps = ({
  collegeSearchBar: {
    collegeSearchBarData: {formattedColleges, abbreviations},
    query,
    selected,
    results,
  },
}) => ({
  colleges: formattedColleges,
  abbreviations,
  query,
  selected,
  results,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCollegeSearchBarData,
      updateQuery,
      receiveResults,
      updateSelected,
      resetSearch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CollegeSearchBar);

// TODO: make this the default when we migrate all the blue nav bars
// to the white one
export const AllUsersCollegeSearchBar = connect(mapStateToProps, mapDispatchToProps)(UserSearchBar);
