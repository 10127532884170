import React, {ReactElement} from 'react';
import styles from './styles.module.scss';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import Icon from '../../components/icon';
import {formatEarnings} from '../../../src/utils/formatters';
import FollowButton from '../../components/follow-button';
import {toggleFollowCollege} from '../actions/college-profile';
import {truncate} from 'lodash';
import browserHistory from '../../history';

interface CollegeDetailProps {
  schoolName: string;
  amount: number;
  following: boolean;
  _id: string;
  userType: string;
  isPartner: boolean;
}

const CollegeDetailNav = ({
  schoolName,
  amount,
  following,
  _id,
  userType,
  isPartner,
}: CollegeDetailProps): ReactElement => {
  const dispatch = useDispatch();

  const earnings = formatEarnings(amount);

  const followCollege = (id: string) => {
    dispatch(toggleFollowCollege(id, 'follow'));
  };
  const unfollowCollege = (id: string) => {
    dispatch(toggleFollowCollege(id, 'unfollow'));
  };

  const handleFollow = () => {
    if (following) {
      unfollowCollege(_id);
    } else if (userType === 'public') {
      browserHistory.push('/colleges/search/sign-up-first', {from: window.location.pathname});
    } else {
      followCollege(_id);
    }
  };

  return (
    <>
      <Link to="/colleges/search">
        <Icon iconType="arrow-left" className={styles.arrowDiv} />
      </Link>
      <div className={styles.middleDiv}>
        <h6 className={styles.topLine}>{truncate(schoolName, {length: 35})}</h6>
        {following ? (
          <p className={styles.bottomLine}>
            {isPartner
              ? `Earning: $${earnings.annualAmount()} • $${earnings.termDescription()}/ yr`
              : 'Scholarships Not Currently Available'}
          </p>
        ) : (
          <p className={styles.bottomLine}>Follow to earn micro-scholarships.</p>
        )}
      </div>
      <FollowButton
        following={following}
        compact
        onClick={handleFollow}
        collegeName={schoolName}
        track="notification-center-new-earnings-modal"
      />
    </>
  );
};

export default CollegeDetailNav;
