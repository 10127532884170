import {noop, pick} from 'lodash';
import {connect} from 'react-redux';
import {setNavigationAnnouncement} from 'shared/actions/accessibility-announcement';
import {expandFilter} from 'shared/actions/filters';
import {openModal} from 'shared/actions/modal';
import CollegeSearch from 'src/components/CollegeSearch/CollegeSearch/CollegeSearch';
import {TERM_NOT_OFFERED_MODAL} from 'src/components/CollegeSearch/TermNotOfferedModal';
import {
  getColleges,
  initializeCollegeSearch,
  resetPage,
  updateSortBy,
} from 'src/redux/collegeSearch/actions';
import {UserType} from 'src/types/enums';
import browserHistory from '../../../../shared/history';
import {Store} from 'src/types/store';

const mapStateToProps = (state: Store) => ({
  ...pick(state, [
    'collegeSearch',
    'filters',
    'filtersInitialized',
    'modal',
    'collegeApplicationStatuses',
    'student',
  ]),
  userType: UserType.PUBLIC,
  followCollege: () => browserHistory.push('/colleges/search/sign-up-first'),
  unfollowCollege: noop,
  getCollegeAppStatuses: noop,
  setCollegeAppStatus: noop,
  showWelcomeModal: noop,
});

export default connect(mapStateToProps, {
  initializeCollegeSearch: initializeCollegeSearch,
  getColleges,
  expandFilter,
  resetPage,
  updateSortBy,
  setNavigationAnnouncement,
  openTermNotOfferedModal: () => openModal(TERM_NOT_OFFERED_MODAL),
})(CollegeSearch);
