import req from 'shared/req';

export const RECIEVE_NOT_OFFERED_COLLEGE_TERMS = 'RECIEVE_NOT_OFFERED_COLLEGE_TERMS';

export function getFollowedCollegesNextTransferTerms(term) {
  return (dispatch) =>
    req({
      url: '/students/followed_colleges_next_transfer_terms',
      method: 'get',
      data: {term: term},
    }).then((colleges) => {
      dispatch(recieveFollowedCollegesNextTransferTerms(colleges));
      return recieveFollowedCollegesNextTransferTerms(colleges);
    });
}
export function recieveFollowedCollegesNextTransferTerms(colleges) {
  return {
    type: RECIEVE_NOT_OFFERED_COLLEGE_TERMS,
    payload: colleges,
  };
}
