import PropTypes from 'prop-types';
import React from 'react';
import LoadingCard from 'src/components/CollegeSearch/LoadingCard';

const propTypes = {
  height: PropTypes.object,
};

const LoadingSearch = ({height}) => (
  <section className="college-search-loading">
    <div className="college-search-loading-filters-sidebar">
      <div className="college-search-loading-filters-sidebar-section">
        <div className="college-search-loading-text header small shimmer"></div>
      </div>
      <div className="college-search-loading-filters-sidebar-section">
        <div className="college-search-loading-text x-small shimmer"></div>
        <div className="college-search-loading-text small shimmer"></div>
        <div className="college-search-loading-text x-small shimmer"></div>
      </div>
      <div className="college-search-loading-filters-sidebar-section">
        <div className="college-search-loading-text x-small shimmer"></div>
        <div className="college-search-loading-text small shimmer"></div>
        <div className="college-search-loading-text x-small shimmer"></div>
        <div className="college-search-loading-text small shimmer"></div>
        <div className="college-search-loading-text x-small shimmer"></div>
        <div className="college-search-loading-text small shimmer"></div>
        <div className="college-search-loading-text x-small shimmer"></div>
      </div>
      <div className="college-search-loading-filters-sidebar-section">
        <div className="college-search-loading-text x-small shimmer"></div>
        <div className="college-search-loading-text small shimmer"></div>
        <div className="college-search-loading-text x-small shimmer"></div>
        <div className="college-search-loading-text small shimmer"></div>
        <div className="college-search-loading-text x-small shimmer"></div>
      </div>
    </div>
    <div className="college-search-loading-body" style={height || {}}>
      <div className="college-search-loading-header">
        <div className="college-search-loading-text small shimmer"></div>
        <div className="college-search-loading-text medium shimmer"></div>
      </div>
      <div className="college-search-loading-filters">
        <div className="college-search-loading-text medium shimmer"></div>
      </div>
      <div className="college-search-loading-cards">
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
      </div>
    </div>
  </section>
);

LoadingSearch.propTypes = propTypes;

export default LoadingSearch;
