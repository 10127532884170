import {includes, times, first, sortBy, last, reduce, findIndex} from 'lodash';
import {objectId, ALLOWED_GRADUATION_YEARS} from 'shared/utils';

export function portfolioUrl(path, studentId) {
  return `/rest/students/${studentId}/portfolio${path}`;
}

export function getPeriodAbbreviation(periodsPerYear) {
  switch (Number(periodsPerYear)) {
    case 0:
      return 'F';
    case 2:
      return 'S';
    case 3:
      return 'T';
    case 4:
      return 'Q';
  }
}

const API_TEST_TYPES = {
  actPlan: 'ActPlanScore',
  preAct: 'PreActScore',
  psat: 'PsatScore',
  psat2015: 'PsatScore2015',
  act: 'ActScore',
  sat: 'SatScore',
  sat2016: 'SatScore2016',
  ap: 'ApScore',
  other: 'OtherTestScore',
};

export const apiTestTypeToModelType = (type) => API_TEST_TYPES[type];

export function initGradesForPeriod(periodsPerYear, gradeType, courseGradePeriods = []) {
  const ret = [];

  const periodAbbrev = getPeriodAbbreviation(periodsPerYear);
  for (let i = 1; i <= periodsPerYear; i++) {
    ret.push({
      gradeInput: null,
      gradeType,
      gpaValue: null,
      period: periodAbbrev + i.toString(),
      enabled:
        courseGradePeriods.length > 0
          ? includes(courseGradePeriods, periodAbbrev + i.toString())
          : true,
      _id: objectId(),
    });
  }
  ['F', 'S', 'W'].forEach((period) => {
    ret.push({
      gradeInput: null,
      gradeType,
      gpaValue: null,
      period,
      enabled:
        courseGradePeriods.length > 0 ? includes(courseGradePeriods, period) : period === 'F',
      _id: objectId(),
    });
  });

  return ret;
}

export const getCourseGradePeriods = (periodType, hasFinalGrade = false) => {
  switch (periodType) {
    case 'S':
    case 'W':
      return [periodType];
    default: {
      const periodsPerYear = Number(periodType);
      const periodAbbreviation = getPeriodAbbreviation(periodsPerYear);
      const gradePeriods = [];
      times(periodsPerYear, (n) => gradePeriods.push(`${periodAbbreviation}${n + 1}`));
      if (hasFinalGrade) {
        gradePeriods.push('F');
      }

      return gradePeriods;
    }
  }
};

export function changeCourseDefaults(periodsPerYear, grades, credits, newDefaults) {
  const newGrades = initGradesForPeriod(
    newDefaults.periodsPerYear,
    newDefaults.gradeType,
    newDefaults.courseGradePeriods
  );
  const numFinalGradeTypes = 3;

  times(periodsPerYear.value + numFinalGradeTypes, () => {
    grades.removeField();
  });
  newGrades.forEach((grade) => {
    grades.addField(grade);
  });
  credits.onChange(newDefaults.credits);
  periodsPerYear.onChange(newDefaults.periodsPerYear);
}

const gradeTypeMap = {
  'A+': 'L',
  A: 'L',
  'A-': 'L',
  'B+': 'L',
  B: 'L',
  'B-': 'L',
  'C+': 'L',
  C: 'L',
  'C-': 'L',
  'D+': 'L',
  D: 'L',
  'D-': 'L',
  F: 'L',
  P: 'P',
  W: 'M',
  I: 'M',
};

export function gradeTypeFromText(input, clickedGradeType) {
  if (!isNaN(parseInt(input, 10))) {
    return 'N';
  }
  if (input === 'F' && clickedGradeType === 'P') {
    return 'P';
  }

  return gradeTypeMap[input];
}

export function isFinalGradingPeriod(period) {
  return period === 'F' || period === 'S' || period === 'W';
}

export const finalGradingPeriodMap = {
  F: 'Final',
  S: 'Summer',
  W: 'Winter',
};

export function yearsToRanges(years = []) {
  const sorted = sortBy(years);

  return reduce(
    sorted,
    (p, year) => {
      if (p.length === 0) {
        return p.concat([year]);
      }

      const lastRange = last(p).split('-');
      const currentRange = year.split('-');

      if (first(currentRange) === last(lastRange)) {
        return p.slice(0, -1).concat([`${first(lastRange)}-${last(currentRange)}`]);
      } else {
        return p.concat([year]);
      }
    },
    []
  );
}

export function allScores(initialValues = []) {
  const scores = [
    {field: 'score', value: null},
    {field: 'score_english', value: null},
    {field: 'score_math', value: null},
    {field: 'score_reading', value: null},
    {field: 'score_science', value: null},
    {field: 'score_critical_reading', value: null},
    {field: 'score_mathematics', value: null},
    {field: 'score_writing', value: null},
    {field: 'score_writing_language', value: null},
    {field: 'score_essay', value: null},
    {field: 'score_multiple_choice', value: null},
    {field: 'score_reading_and_writing', value: null},
  ];

  initialValues.forEach((initialValue) => {
    const index = findIndex(scores, ({field}) => field === initialValue.field);

    scores[index] = initialValue;
  });

  return scores;
}

export function schoolYears(graduationYear) {
  const now = new Date(graduationYear, 1, 1);
  const current = now.getFullYear();

  return times(4, (i) => {
    const endYear = current - i;
    const startYear = current - (i + 1);

    const abbr = endYear - 2000;

    return {
      fullName: `Courses in ${startYear}-${abbr}`,
      start: `${startYear}`,
      end: `${endYear}`,
      name: `${startYear}-${endYear}`,
    };
  });
}

export const classYearsMap = {
  0: 'Senior',
  1: 'Junior',
  2: 'Sophomore',
  3: 'Freshman',
};

export function classYears(graduationYear) {
  const parsedGradYear = parseInt(graduationYear);
  const result = {};

  times(4, (i) => {
    result[`${parsedGradYear - (i + 1)}-${parsedGradYear - i}`] = classYearsMap[i];
  });

  return result;
}

// semesters is an array of strings formatted as "{year} {semester}" or "new"
// e.g. ["2017 Spring", "new", "2018 Fall"]
// Output is sorted in reverse order, with "new" always first
export function sortCollegeSemesters(semesters) {
  return semesters.sort((sem1, sem2) => {
    if (sem1 === 'new') {
      return -1;
    } else if (sem2 === 'new') {
      return 1;
    }

    const [year1, semester1] = sem1.split(' ');
    const [year2, semester2] = sem2.split(' ');
    const semesterOrder = ['Spring', 'Summer', 'Fall'];

    if (year1 < year2) {
      return 1;
    } else if (year1 > year2) {
      return -1;
    } else if (semester1 === semester2) {
      return 0;
    } else if (semesterOrder.indexOf(semester1) < semesterOrder.indexOf(semester2)) {
      return 1;
    } else {
      return -1;
    }
  });
}

export const CURRENT_SCHOOL_YEAR = (function () {
  const now = new Date();
  const current = now.getFullYear();
  if (now.getMonth() < 6) {
    return {
      start: `${current - 1}`,
      end: `${current}`,
      name: `${current - 1}-${current}`,
    };
  } else {
    return {
      start: `${current}`,
      end: `${current + 1}`,
      name: `${current}-${current + 1}`,
    };
  }
})();

export function classOfYears(graduationYear) {
  const allowedGraduationYears = [...ALLOWED_GRADUATION_YEARS];

  // If the graduation year is not included in the allowed years, then add it
  if (graduationYear < Math.min(...allowedGraduationYears)) {
    allowedGraduationYears.unshift(graduationYear);
  }

  return allowedGraduationYears.map((year) => ({
    _id: year,
    value: year.toString(),
    text: 'Class of ' + year,
  }));
}

export const CREDIT_OPTIONS = [
  {_id: '1-credit', value: '1', text: 'Full Credit'},
  {_id: '3/4-credit', value: '0.75', text: '3/4 Credit'},
  {_id: '2/3-credit', value: '0.66', text: '2/3 Credit'},
  {_id: '1/2-credit', value: '0.5', text: '1/2 Credit'},
  {_id: '1/3-credit', value: '0.33', text: '1/3 Credit'},
  {_id: '1/4-credit', value: '0.25', text: '1/4 Credit'},
  {_id: '0-credit', value: '0', text: 'No Credit'},
];
