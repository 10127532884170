import {GET_USER_INFO} from '../actions/student';

export const startsWithAcademicInterestsReducer = (state = false, action) => {
  let academicInterestIds;

  switch (action.type) {
    case GET_USER_INFO:
      academicInterestIds = action.info && action.info.academicInterestIds;
      if (academicInterestIds && academicInterestIds.length) {
        return true;
      }

      return false;
    default:
      return state;
  }
};
