import {educatorReducers} from 'educator/app/reducers/root-reducer';
import {lazy} from 'react';
import onboardingReducers from 'shared/student/registration/reducers';
import {UserType} from 'src/types/enums';

export const educatorsApp = {
  app: lazy(() => import('src/views/Educator/index')),
  reducers: educatorReducers,
  initialState: {
    collegeProfile: {
      userInfo: {
        user: UserType.EDUCATOR,
      },
      tab: 0,
      loading: true,
      modalPending: {},
    },
  },
};

export const educatorsOnboardingApp = {
  app: lazy(() => import('src/views/Educator/onboarding')),
  reducers: onboardingReducers,
  initialState: {},
};
