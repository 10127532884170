import React from 'react';
import classnames from 'classnames';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';

import 'stylesheets/components/shared-routed-tabs.scss';

interface Props {
  tabs: {
    label: React.ReactNode;
    onClick?: () => void;
    path: string;
    skipScrolling?: boolean;
  }[];
  className?: string;
  basePath?: string;
  replaceOnNavigation?: boolean;
}

const RoutedTabs = ({
  tabs,
  location: {pathname},
  match: {url},
  className,
  basePath,
  replaceOnNavigation = true,
}: Props & RouteComponentProps) => (
  <div className="shared-routed-tabs-outer" data-testid="routed-tabs">
    <div className={classnames('shared-routed-tabs', className)} role="tablist">
      {tabs.map(({label, onClick, path, skipScrolling}) => {
        const updatedUrl = basePath ? basePath : url;

        const fullPath = `${updatedUrl}${path}`;
        const active = pathname === fullPath;

        return (
          <Link
            key={path}
            className={classnames('shared-routed-tabs-tab', {active})}
            aria-selected={active}
            role="tab"
            replace={replaceOnNavigation}
            to={{
              pathname: fullPath,
              state: {skipScrolling},
            }}
            onClick={onClick}
            data-testid="routed-tab"
          >
            {label}
          </Link>
        );
      })}
    </div>
  </div>
);

export default withRouter(RoutedTabs);
