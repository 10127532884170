import {NOT_ON_TRACK} from '../actions/on-track';

const defaultState = {
  onTrack: true,
};

export function onTrackReducer(state = defaultState, action) {
  switch (action.type) {
    case NOT_ON_TRACK:
      return {
        ...state,
        onTrack: false,
      };
    default:
      return state;
  }
}
