import {changesSaved, errorMessage} from 'shared/actions/notifications';
import {RECEIVE_EDUCATOR} from 'educator/action-types/educator';

export const START_UPLOADING_ID = 'START_UPLOADING_ID';
export const FINISH_UPLOADING_ID = 'FINISH_UPLOADING_ID';

export function uploadOfficialId(file) {
  return (dispatch) => {
    dispatch({
      type: START_UPLOADING_ID,
    });

    const url = '/educator/official-id-upload';
    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);

    xhr.onload = () => {
      if (xhr.status === 200) {
        const data = JSON.parse(xhr.responseText);

        dispatch({
          type: FINISH_UPLOADING_ID,
        });
        dispatch({
          type: RECEIVE_EDUCATOR,
          payload: {officialIdPath: data.url},
        });
        dispatch(changesSaved());
      } else {
        dispatch({
          type: FINISH_UPLOADING_ID,
        });
        dispatch(errorMessage());
      }
    };

    const form = new FormData();
    form.append('official_id', file, file.name || 'image.png');

    xhr.send(form);
  };
}
