import {some, omit} from 'lodash';
import {
  NEW_COLLEGE_COURSE,
  RECEIVE_COLLEGE_COURSE_INSTITUTION_GRADES_PRESELECTS,
  EDIT_COLLEGE_COURSE,
  CANCEL_EDIT_COLLEGE_COURSE,
  COLLEGE_COURSE_DELETED,
  SCROLL_TO_COLLEGE_COURSE,
  SCROLLED_TO_COLLEGE_COURSE,
  RECEIVE_COLLEGE_COURSES,
} from 'shared/portfolio/actions/college-courses';

export const collegeCoursesReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_COLLEGE_COURSE_INSTITUTION_GRADES_PRESELECTS:
      return {
        ...state,
        [action.id]: {
          ...action.course,
        },
      };
    case NEW_COLLEGE_COURSE:
      if (some(state, 'new')) {
        return state;
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.course,
          },
        };
      }
    case CANCEL_EDIT_COLLEGE_COURSE:
      if (action.course.new) {
        return omit(state, action.id);
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.course,
            editing: false,
            scrollTo: true,
          },
        };
      }
    case EDIT_COLLEGE_COURSE:
      return {
        ...state,
        [action.id]: {
          ...action.course,
          editing: true,
        },
      };
    case SCROLL_TO_COLLEGE_COURSE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          scrollTo: true,
        },
      };
    case SCROLLED_TO_COLLEGE_COURSE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          scrollTo: false,
        },
      };
    case COLLEGE_COURSE_DELETED:
      return omit(state, action.id);
    case RECEIVE_COLLEGE_COURSES:
      return {
        ...state,
        ...action.collegeCourses,
      };
    default:
      return state;
  }
};
