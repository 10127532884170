import {pick} from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {clearFilters} from 'shared/actions/filters';
import {openModal} from 'shared/actions/modal';
import Header from 'src/components/CollegeSearch/Header/Header';
import {
  getColleges,
  resetPage,
  updateSortBy,
  updateSortOrder,
} from 'src/redux/collegeSearch/actions';

const mapStateToProps = (state) => ({
  ...pick(state, ['collegeSearch']),
  userType: state?.collegeProfile?.userInfo?.user,
});

const mapDispatchToProps = (dispatch) => {
  const basicActions = bindActionCreators(
    {
      updateSortBy,
      resetPage,
      getColleges,
      openModal,
      updateSortOrder,
    },
    dispatch
  );

  const otherActions = {
    clearFilters() {
      dispatch(clearFilters());
      dispatch(resetPage());
      dispatch(getColleges());
    },
  };

  return {
    ...basicActions,
    ...otherActions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
