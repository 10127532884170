import {reduce} from 'lodash';
import {RECEIVE_COLLEGES, UPDATE_COLLEGE} from 'shared/actions/college';
import {errorMessage} from 'shared/actions/notifications';
import {RECEIVE_STUDENT, getUser, updateUser} from 'shared/actions/student';
import req from 'shared/req';
import {UserType} from 'src/types/enums';
import {formatFormValues} from '../utils';

export const INCREMENT_STEP = 'ACCOUNT_CONVERSION/INCREMENT_STEP';
export const DECREMENT_STEP = 'ACCOUNT_CONVERSION/DECREMENT_STEP';

export const decrementStep = () => ({type: DECREMENT_STEP});

export const incrementStep = () => ({type: INCREMENT_STEP});

export const onSubmit = (values) => (dispatch) =>
  new Promise(() => {
    const data = formatFormValues(values);
    updateUser(data)
      .then(() => {
        window.location.href = '/portfolio';
      })
      .catch(() => {
        dispatch(errorMessage());
      });
  });

export const getSuggestedColleges = () => (dispatch) =>
  req({
    url: '/v1/lists/suggested-colleges',
    data: {educationPhase: UserType.CC_STUDENT},
  }).then((colleges) => {
    const formattedColleges = reduce(
      colleges,
      (result, value) => {
        result[value._id] = {...value};
        return result;
      },
      {}
    );

    dispatch({type: RECEIVE_COLLEGES, colleges: formattedColleges});

    return formattedColleges;
  });

export const checkEmailTaken = (email) =>
  req({
    url: '/v1/verify-email',
    method: 'POST',
    data: {email},
  });

export const followCollege = (id) => (dispatch, getState) => {
  const {colleges} = getState();

  return dispatch({
    type: UPDATE_COLLEGE,
    colleges: {[id]: {...colleges[id], following: true}},
  });
};

export const unfollowCollege = (id) => (dispatch, getState) => {
  const {colleges} = getState();

  return dispatch({
    type: UPDATE_COLLEGE,
    colleges: {[id]: {...colleges[id], following: false}},
  });
};

export const getUserInfo = () => (dispatch) =>
  getUser('me', 'email').then((student) => {
    dispatch({type: RECEIVE_STUDENT, student});
  });
