import React from 'react';
import PropTypes from 'prop-types';

class MenuListItem extends React.Component {
  render() {
    return <div className="menu-item-list">{this.props.children}</div>;
  }
}

const propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
};

MenuListItem.propTypes = propTypes;

export default MenuListItem;
