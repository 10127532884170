import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {kebabCase} from 'lodash';
import Icon from 'shared/components/icon';
import Tooltip from 'src/components/tooltip';
import 'stylesheets/components/shared-follow-button.scss';

const propTypes = {
  following: PropTypes.bool.isRequired,
  // Outline button style
  outline: PropTypes.bool,
  // Small version of follow button that is 80px wide and
  // collapses into just an icon when following=true. When
  // false, button takes up full width of parent element
  compact: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  collegeName: PropTypes.string,
  // Identifying string for segment tracking
  track: PropTypes.string,
};

const FollowButton = ({outline, following, compact, onClick, collegeName, track}) => {
  const Wrapper = compact && following ? Tooltip : Fragment;
  const wrapperProps = compact && following ? {title: 'Unfollow'} : {};
  const buttonName = [following ? 'unfollow' : 'follow'];

  if (track) buttonName.push(kebabCase(track));
  if (collegeName) buttonName.push(kebabCase(collegeName));

  return (
    <Wrapper {...wrapperProps}>
      <button
        onClick={onClick}
        className={classnames('shared-follow-button', {outline}, {compact}, {following})}
        aria-label={
          following
            ? `Following ${collegeName || ''}, click to unfollow`
            : `Follow ${collegeName || ''}`
        }
        data-segment-track="button"
        data-button-name={buttonName.join('-')}
        data-testid="button"
      >
        {following ? (
          <>
            <span className="shared-follow-button-following-text" data-testid="following"></span>
            <Icon iconType="check" className="shared-follow-button-icon-check" />
            <Icon iconType="times-r" className="shared-follow-button-icon-x" />
          </>
        ) : (
          'Follow'
        )}
      </button>
    </Wrapper>
  );
};

FollowButton.propTypes = propTypes;

export default FollowButton;
