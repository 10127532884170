import PropTypes from 'prop-types';
import React from 'react';
import InfoTooltip from 'shared/components/info-tooltip';
import ComparePctWithAverage from './compare-pct-with-average';

class CompareEarningAboveHighSchool extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    nationalCollegeData: PropTypes.object.isRequired,
  };

  render() {
    const {college, nationalCollegeData} = this.props;

    if (!college.studentsEarningAboveHighSchoolGrads) {
      return null;
    }

    const tooltip = (
      <InfoTooltip className="college-profile-tooltip">
        The share of former students earning more than $25,000, or about the average earnings of a
        high school graduate aged 25-34, 6 years after they first enroll
      </InfoTooltip>
    );

    return (
      <ComparePctWithAverage
        label="Students Earning above High School Grads"
        dataSource={nationalCollegeData}
        attributeInSource="studentsEarningAboveHighSchoolGrads"
        attributeOnCollege="studentsEarningAboveHighSchoolGrads"
        college={college}
        tooltip={tooltip}
      />
    );
  }
}

export default CompareEarningAboveHighSchool;
