import {reducer as formReducer} from 'redux-form';
import notificationSettingsReducer from 'shared/account-settings/reducers/notification-settings';
import {accessibilityAnnouncementReducer} from 'shared/reducers/accessibility-announcement';
import {genericReducer} from 'shared/reducers/generic-reducer';
import {notificationsReducer} from 'shared/reducers/notifications';
import {RECEIVE_URL_PARAMETERS} from 'src/redux/auth/actions/url-parameters';
import {INITIALIZE_FILTERS} from '../../../../shared/actions/filters';
import collegeApplicationStatusesReducer from '../../../../shared/college-application-status/reducers/college-application-statuses-reducer';
import {collegeProfileReducer} from '../../../../shared/college-profile/reducers/college-profile';
import {collegeSearchBarReducer} from '../../../../shared/college-search-bar/reducers';
import {booleanReducer} from '../../../../shared/reducers/boolean-reducer';
import {filtersReducer} from '../../../../shared/reducers/filters';
import {modalReducer} from '../../../../shared/reducers/modal';
import {collegeSearchReducer} from '../../collegeSearch/reducer';

export const publicReducers = {
  accessibilityAnnouncement: accessibilityAnnouncementReducer,
  collegeApplicationStatuses: collegeApplicationStatusesReducer,
  collegeProfile: collegeProfileReducer,
  collegeSearch: collegeSearchReducer,
  collegeSearchBar: collegeSearchBarReducer,
  filters: filtersReducer,
  filtersInitialized: booleanReducer({defaultState: false, trueType: INITIALIZE_FILTERS}),
  form: formReducer,
  modal: modalReducer,
  notificationSettings: notificationSettingsReducer,
  notifications: notificationsReducer,
  urlParameters: genericReducer({
    actionProperty: 'params',
    defaultState: '',
    setAction: RECEIVE_URL_PARAMETERS,
  }),
};
