import {omit} from 'lodash';
import {
  RECEIVE_NOTIFICATION_MESSAGES,
  REMOVE_NOTIFICATION_MESSAGE,
  REMOVE_NOTIFICATION_MESSAGES,
} from 'shared/notification-center/actions/notification-messages';
import {NOTIFICATION_BUNDLES_EVENT} from 'shared/notification-center/actions/notification-bundle';

export default function notificationMessagesReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NOTIFICATION_MESSAGES: {
      return {
        ...state,
        ...action.notificationMessages,
      };
    }
    case REMOVE_NOTIFICATION_MESSAGE:
      return omit(state, [action.sequenceNumber]);
    case REMOVE_NOTIFICATION_MESSAGES:
      return {};
    case NOTIFICATION_BUNDLES_EVENT:
      return {};
    default:
      return state;
  }
}
