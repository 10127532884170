import {isNil, omit} from 'lodash';
import browserHistory from 'shared/history';
import req from 'shared/req';
import {errorMessage} from 'shared/actions/notifications';
import {startLoading, stopLoading} from 'shared/actions/loading';

// edu app
import {
  RECEIVE_RECENT_STUDENTS,
  RECEIVE_STUDENTS,
  UPDATE_STUDENT_SEARCH_OPTIONS,
  UPDATE_STUDENT_COUNTS,
} from 'educator/action-types/students';
import {STUDENTS_PER_PAGE} from 'educator/components/constants';
import {AbortedRequest} from 'src/utils/errors';

export const RECENT_STUDENTS_LOADING_IDENTIFIER = 'recent-students';
export const STUDENTS_LOADING_IDENTIFIER = 'students';

export const RECEIVE_STUDENTS_FOR_SEARCH = 'RECEIVE_STUDENTS_FOR_SEARCH';

export const FIELDS = [
  'firstName',
  'lastName',
  'image',
  'photo',
  'followedCollegesEarnings',
  'totalCredits',
  'updatedAt',
  'code',
  'highSchoolInfo',
  'lastActive',
  'email',
  'caseloadId',
].join(',');

export const COMMUNITY_COLLEGE_STAFF_FIELDS = [
  'firstName',
  'lastName',
  'image',
  'photo',
  'followedCollegesEarnings',
  'totalCredits',
  'updatedAt',
  'code',
  'currentInstitution',
  'communityCollegeInfo',
  'lastActive',
  'email',
  'caseloadId',
].join(',');

export const MENTOR_FIELDS = [
  'firstName',
  'lastName',
  'image',
  'photo',
  'followedCollegesEarnings',
  'totalCredits',
  'updatedAt',
  'code',
  'currentInstitution',
  'highSchoolInfo',
  'communityCollegeInfo',
  'lastActive',
  'email',
].join(',');

export const DEFAULT_STUDENT_SEARCH_OPTIONS = {
  page: 1,
  query: '',
  resultsCount: 0,
  sortDirection: 'ascending',
  sortField: 'name',
};

export const updateQuery = (query) => updateStudentSearchOptions({query});

const fieldsForUser = (user) => {
  switch (user) {
    case 'Mentor':
      return MENTOR_FIELDS;
    case 'CommunityCollegeStaff':
      return COMMUNITY_COLLEGE_STAFF_FIELDS;
    default:
      return FIELDS;
  }
};

const updateStudentSearchOptions = (options) => ({
  type: UPDATE_STUDENT_SEARCH_OPTIONS,
  options,
});

const receiveRecentStudents = (recentStudents) => ({
  type: RECEIVE_RECENT_STUDENTS,
  recentStudents,
});

const receiveStudents = (students) => ({
  type: RECEIVE_STUDENTS,
  students,
});

export const assignSearchArguments = ({sortField, query, page, currentState}) => {
  const field = sortField || currentState.sortField;
  let sortDirection = currentState.sortDirection;
  const search = query || currentState.query;

  if (sortField) {
    sortDirection =
      sortField === currentState.sortField && sortDirection === 'ascending'
        ? 'descending'
        : 'ascending';
  }

  const currentPage = page || currentState.page;
  const limit = STUDENTS_PER_PAGE;

  // Page starts at 1 for user clarity, but we need it to be 0 indexed for calculating skip
  const skip = ((isNil(page) ? currentPage : page) - 1) * limit;

  const result = {skip, limit, page: currentPage, sortDirection, search, sortField: field};

  // If on the caseload tab, add caseload parameter to fetch call
  if (window.location.pathname.match(/\/caseload/)) {
    result.caseload = true;
  }

  return result;
};

export const fetchSearchResults =
  ({sortField, query, page}) =>
  (dispatch, getState) => {
    const state = getState();
    const queryOptions = assignSearchArguments({
      sortField,
      query,
      page,
      currentState: state.studentSearchOptions,
    });
    const educatorType = state.educator.type;

    dispatch(fetchStudentsForSearch(omit(queryOptions, ['page']), educatorType));
    dispatch(updateStudentSearchOptions(omit(queryOptions, ['limit', 'skip'])));

    // Change the page if page argument differs from the one the user is currently on
    if (page && page !== state.studentSearchOptions.page) {
      browserHistory.push(`${window.location.pathname}?page=${page}`);
    }
  };

const formatSearchResultsCount = (response, textStatus, xhr) => ({
  students: response,
  resultsCount: parseInt(xhr.getResponseHeader('X-SEARCH-RESULTS-COUNT')),
});

export const fetchStudentsForSearch =
  (options = {}, type) =>
  (dispatch) => {
    dispatch(startLoading(STUDENTS_LOADING_IDENTIFIER));
    return req({
      url: '/v1/students',
      method: 'get',
      data: {
        ...options,
        fields: fieldsForUser(type),
      },
      cancelPendingRequests: true,
      responseTransformer: formatSearchResultsCount,
    })
      .then(({students, resultsCount}) => {
        dispatch(receiveStudents(students));
        dispatch({
          type: UPDATE_STUDENT_SEARCH_OPTIONS,
          options: {resultsCount},
        });
        dispatch(stopLoading(STUDENTS_LOADING_IDENTIFIER));
      })
      .catch((error) => {
        if (!(error instanceof AbortedRequest)) {
          dispatch(errorMessage());
        }
      });
  };

export const fetchRecentStudents =
  ({type, limit}) =>
  (dispatch) => {
    dispatch(startLoading(RECENT_STUDENTS_LOADING_IDENTIFIER));

    return req({
      url: '/v1/students',
      method: 'get',
      data: {
        sortField: 'lastUpdated',
        fields: fieldsForUser(type),
        limit,
      },
    })
      .then((recentStudents) => {
        dispatch(receiveRecentStudents(recentStudents));
        dispatch(stopLoading(RECENT_STUDENTS_LOADING_IDENTIFIER));
      })
      .catch(() => {
        dispatch(errorMessage());
      });
  };

export const fetchStudentsCount = () => (dispatch) => {
  req({
    url: '/v1/students',
    method: 'get',
    data: {limit: 1, fields: '_id'},
    responseTransformer: formatSearchResultsCount,
  }).then(({resultsCount}) => {
    dispatch({
      type: UPDATE_STUDENT_COUNTS,
      payload: {
        totalStudentsCount: resultsCount,
      },
    });
  });

  req({
    url: '/v1/students',
    method: 'get',
    data: {limit: 1, fields: '_id', caseload: true},
    responseTransformer: formatSearchResultsCount,
  }).then(({resultsCount}) => {
    dispatch({
      type: UPDATE_STUDENT_COUNTS,
      payload: {
        caseloadStudentsCount: resultsCount,
      },
    });
  });
};
