import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TextButton from 'src/components/TextButton';
import Icon from 'shared/components/icon';

const MenuItem = ({name, className, checked, onClick}) => {
  const getClassName = classnames('menu-item', className, checked ? 'menu-item-selected' : '');
  return (
    <div className={getClassName}>
      <TextButton buttonName={name} color="dark-grey" onClick={onClick}>
        {checked && <Icon iconType="check" className="menu-item-selected-icon" />}
        {name}
      </TextButton>
    </div>
  );
};

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default MenuItem;
