import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormButton from 'src/components/Button';
import {buttonColors} from 'shared/constants';
import GoogleLogo from 'shared/img/google-logo.svg';
import 'stylesheets/components/shared-google-button.scss';

class GoogleButton extends React.Component {
  static propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    color: PropTypes.oneOf(buttonColors),
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    buttonName: PropTypes.string,
  };

  static defaultProps = {
    size: 'medium',
    className: '',
    color: 'gray',
  };

  render() {
    const {size, className, buttonName, color} = this.props;

    return (
      <FormButton
        {...this.props}
        buttonName={buttonName}
        color={color}
        outline
        className={classnames('shared-google-button', className)}
      >
        <GoogleLogo className={`shared-google-button-logo-${size}`} />
        {this.props.children}
      </FormButton>
    );
  }
}

export default GoogleButton;
