import {join} from 'lodash';
import req from 'shared/req';
import {RecommendationsService} from 'src/services/RecommendationsService';

export const ADMISSION_LIKELIHOOD_SCORES_UPDATED = 'ADMISSION_LIKELIHOOD_SCORES_UPDATED';

export const updateScores = ({scores}) => ({
  type: ADMISSION_LIKELIHOOD_SCORES_UPDATED,
  scores,
});

export const fetchAdmissionLikelihoodScores =
  (collegeListingIds, {breakCache} = {}) =>
  async (dispatch) => {
    const serviceState = await RecommendationsService.getState();
    if (serviceState.disableAdmissionLikelihood) return;

    let url = '/v1/admission-likelihood',
      data,
      response;
    if (breakCache) {
      data = {collegeListingIds};
    } else {
      url += `?collegeListingIds=${join(collegeListingIds, ',')}`;
    }

    try {
      response = await req({
        url,
        method: breakCache ? 'POST' : 'GET',
        data,
      });
    } catch (error) {
      return console.error('Failed to get likelihood scores', error);
    }

    dispatch(updateScores(response));
  };
