import React, {createContext, CSSProperties, useRef} from 'react';

import {useResizeObserver} from 'src/hooks/useResizeObserver';

import styles from './app-layout.module.scss';

interface Props {
  topToolbars: React.ReactNode;
  children: React.ReactNode;
}

export const AppLayoutContext = createContext({topToolbarHeight: 80});

const AppLayout = ({topToolbars, children}: Props) => {
  const topToolbarsRef = useRef<HTMLDivElement>(null);
  const {height: topToolbarHeight} = useResizeObserver(topToolbarsRef);

  return (
    <AppLayoutContext.Provider value={{topToolbarHeight}}>
      <div style={{'--top-toolbar-height': `${topToolbarHeight}px`} as CSSProperties}>
        <div className={styles.topToolbars} ref={topToolbarsRef}>
          {topToolbars}
        </div>
        {children}
      </div>
    </AppLayoutContext.Provider>
  );
};

export default AppLayout;
