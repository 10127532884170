import React, {ReactElement} from 'react';
import {FormikErrors} from 'formik';
import EmptyHeader from 'shared/components/empty-header';
import {Box} from '@mui/material';
import {useHistory} from 'react-router-dom';
import ResetPasswordService from 'src/services/ResetPasswordService';
import ResetPasswordBaseForm from 'src/components/ResetPasswordBaseForm';
import PublicFooter from 'src/components/PublicFooter/PublicFooter';

interface FormValues {
  verificationCode: string;
}

const validate = (values: FormValues): object => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.verificationCode) {
    errors.verificationCode = 'Verification code is required.';
  }

  return errors;
};

const MobileVerificationCode = (): ReactElement => {
  const history = useHistory();

  const handleSubmit = async (values, actions): Promise<void> => {
    actions.setSubmitting(true);
    await ResetPasswordService.mobileVerificationCode(values.verificationCode)
      .then((res) => history.push(`/reset-password/${res.resetToken}`))
      .catch((res) => {
        actions.setFieldError('verificationCode', res.error);
        actions.setSubmitting(false);
      });
  };

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" alignItems="center">
      <EmptyHeader signOutButton={false} signInButton />
      <Box display="flex" flexDirection="column" justifyContent="center" width={450}>
        <Box mt={20} display="flex" justifyContent="center">
          <div className="shared-font display-1">Phone Verification</div>
        </Box>
        <p className="shared-font body center">
          We&#39;re sending a verification code to your phone.
          <br />
          Enter it below to reset your password.
        </p>
      </Box>
      <Box display="flex" flexDirection="column" width={300} flexGrow={1}>
        <ResetPasswordBaseForm
          fieldName="verificationCode"
          handleSubmit={handleSubmit}
          validate={validate}
          placeholder="Verification Code"
          label="Verification Code"
        />
      </Box>
      <PublicFooter />
    </Box>
  );
};

export default MobileVerificationCode;
