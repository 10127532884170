import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function ShowHideButton(name, ParentComponent, ChildComponent) {
  return class extends React.Component {
    static displayName = name;
    static defaultProps = {transient: true};

    static propTypes = {
      className: PropTypes.string,
      parentProps: PropTypes.shape(ParentComponent.propTypes || {}),
      childProps: PropTypes.shape(ChildComponent.propTypes || {}),
    };

    constructor(props) {
      super(props);
      this.state = {isShown: false};
    }

    toggleChild = () => {
      this.setState(({isShown: wasShown}) => ({isShown: !wasShown}));
    };

    render() {
      const {className, parentProps = {}, childProps = {}} = this.props;

      const {isShown} = this.state;
      const childComponent = isShown ? <ChildComponent {...childProps} /> : null;

      return (
        <div className={className}>
          <ParentComponent
            {...parentProps}
            isShown={isShown}
            className={classnames(parentProps.className || null, {'is-shown': isShown})}
            onClick={this.toggleChild}
          />
          {childComponent}
        </div>
      );
    }
  };
}
