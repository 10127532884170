import {connect} from 'react-redux';
import MajorsTypeaheadFilter from 'src/components/CollegeSearch/Filters/MajorsTypeaheadFilter/MajorsTypeaheadFilter';
import {expandFilter} from 'shared/actions/filters';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelect(major, typeahead) {
    typeahead.clear();
    const onChange = ownProps.onChange || (() => {});
    dispatch(expandFilter({name: 'majors', value: major}));
    onChange(major);
  },
});

export default connect(null, mapDispatchToProps)(MajorsTypeaheadFilter);
