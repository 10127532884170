import req from 'shared/req';
import {errorMessage} from 'shared/actions/notifications';
import GoogleOAuth from 'shared/modules/google-oauth';
import {getReferralDataFromUrl} from 'src/views/public/utils';
import {removeParams} from 'src/redux/auth/actions/url-parameters';
import browserHistory from 'shared/history';
import {buildApiError} from 'src/utils/errors';
import {BasicLoginError} from 'src/utils/errors/BasicLoginError';
import {successfulLoginRedirectPath} from 'src/utils/storage';
import {mobileAllowedUserTypes} from '../../../utils/users';
import PlatformService from '../../../services/PlatformService';

export const onSubmit = (values, followCollegeId) => (dispatch, getState) =>
  new Promise((resolve, reject) =>
    req({
      url: '/v1/basic/login?webapp=true&fields=_id',
      method: 'post',
      data: {
        cookie: true,
        followCollegeId,
        ...getReferralDataFromUrl(getState()),
        ...values,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((errors) => {
        const loginError = buildApiError(BasicLoginError, errors);

        if (!loginError) {
          dispatch(errorMessage());
          return reject();
        }

        const fieldErrors = loginError.getFieldErrors();

        if (fieldErrors.hasWeakPassword) {
          browserHistory.push({
            pathname: '/set-password',
            state: {hideCredentials: true, hiddenCredentials: values},
          });
          return reject();
        }

        reject(fieldErrors);
      })
  );

export const googleLogin = () => (dispatch, getState) => {
  GoogleOAuth.authorize().then((googleUser) => {
    console.log('googleUser', JSON.stringify(googleUser, null, 2));

    return req({
      url: `/v1/google/login?runtime=${PlatformService.runtime()}&allowedUserTypes=${mobileAllowedUserTypes()}`,
      method: 'post',
      data: {token: googleUser.authentication.idToken, ...getReferralDataFromUrl(getState())},
    })
      .then((response) => {
        handleRedirect(response);
      })
      .catch((data) => {
        console.log('data', JSON.stringify(data, null, 2));
        dispatch(errorMessage(data.errors.google));
      });
  });
};

export const handleRedirect = (response) => {
  removeParams();
  window.location.href = successfulLoginRedirectPath.pop() || response.url;
};
