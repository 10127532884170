import req from 'shared/req';
import {changesSaved, errorMessage} from 'shared/actions/notifications';

export const RECEIVE_EDUCATOR_CONNECTIONS = 'RECEIVE_EDUCATOR_CONNECTIONS';
export const RECEIVE_EDUCATOR_CONNECTION = 'RECEIVE_EDUCATOR_CONNECTION';

export function receiveEducatorConnections(connections) {
  return {
    type: RECEIVE_EDUCATOR_CONNECTIONS,
    connections,
  };
}

export function receiveEducatorConnection(connection) {
  return {
    type: RECEIVE_EDUCATOR_CONNECTION,
    connection,
  };
}

export function getEducatorConnections() {
  return (dispatch) => {
    const url = '/students/educator_connections';
    return req({
      url: url,
      method: 'get',
    }).then((connections) => {
      dispatch(receiveEducatorConnections(connections));
      return receiveEducatorConnections(connections);
    });
  };
}

function updateEducatorConnection(id, status) {
  return (dispatch) => {
    const url = '/students/educator_connections';
    return req({
      url,
      method: 'patch',
      data: {
        id,
        status,
      },
    })
      .then((receivedEducatorConnection) => {
        dispatch(receiveEducatorConnection(receivedEducatorConnection));
        dispatch(changesSaved());
        return receiveEducatorConnection(receivedEducatorConnection);
      })
      .catch(() => {
        dispatch(errorMessage());
      });
  };
}

export function acceptEducatorConnection(id) {
  return updateEducatorConnection(id, 1);
}

export function rejectEducatorConnection(id) {
  return updateEducatorConnection(id, -1);
}
