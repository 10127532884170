import FeatureFlagService from 'src/services/FeatureFlagService';
import {formatColleges} from 'src/utils/collegeSearch';
import {FIELDS_REQUIRED_BY_BUCKET} from 'shared/components/college-card/contextual-data-text';
import {StatusUpdate} from 'src/services/StudentCollegeService';
import qs from 'qs';
import req from 'shared/req';

export const TARGET_ME_KEY = 'target-me';

export interface GetBucketOptions {
  // used only to get buckets for impostering student
  imposterStudentId?: string;
  skipCache?: boolean;
}

export interface GetBucketResponse {
  because: string;
  category: string | null;
  definition: string;
  id: string;
  short: string;
}

export type GetBucketCollegesOptions = GetBucketOptions & {
  bucketId: string;
  bucketIndex: number;
  totalBucketCount: number;
  recommendationsShownEventId: string;
  bucketEventId: string;
  limit?: number;
};

type NumericString = string | number;

export interface RecommendedCollege {
  acceptanceRate: NumericString;
  amount: NumericString;
  amountLoading: boolean;
  avgNetPrice: NumericString;
  bannerUrl: string;
  city: string;
  deadlineDay: NumericString;
  deadlineMonth: NumericString;
  deadlineYear: NumericString;
  earningRelationship: StatusUpdate;
  earningsPossible: boolean;
  forbesRank: NumericString;
  graduationRate: NumericString;
  id: string;
  isPartner: boolean;
  isFollowing: boolean;
  logoUrl: string;
  name: string;
  slug: string;
  state: string;
  studentSubmissionTerm: string;
  totalUndergraduates: NumericString;
  tuition: NumericString;
  usNewsRank: NumericString;
  // TargetMe campaign ID, present only on colleges recommended through sponsored recommendations
  campaignId?: number;
  // additional keys available on request
}

export interface StudentRecommendationBucket {
  bucketShownEventId: string;
  colleges: RecommendedCollege[];
  description: string;
  id: string;
  index: number;
  short: string;
  title: string;
}

export interface ServiceState {
  disableRecommendations: boolean;
  disableAdmissionLikelihood: boolean;
}

export const RECOMMENDATION_BUCKET_FIELDS = [
  'name',
  'logo',
  'banner',
  'city',
  'state',
  'isFollowing',
  'slug',
  'amount',
  'partnerSubmissionDeadlineMonth',
  'partnerSubmissionDeadlineDay',
  'partnerSubmissionDeadlineYear',
  'partnerSubmissionDeadlineTerm',
  'earningRelationship',
  'isPartner',
  'earningsPossible',
];

export class RecommendationsService {
  static async getBuckets(limit = 50, options: GetBucketOptions): Promise<GetBucketResponse[]> {
    const query = qs.stringify({
      limit,
      imposter: options.imposterStudentId,
      force: options.skipCache,
    });
    const url = `/v1/colleges/discover?${query}`;
    const response = await req({url});

    return response as GetBucketResponse[];
  }

  static async getRecommendationLimit(): Promise<number> {
    return FeatureFlagService.getFeatureFlagStatus('lessRecommendationColleges')
      .then((flagValue) => (flagValue ? 9 : 15))
      .catch(() => 9);
  }

  static async getRecommendationBucketCount(): Promise<number> {
    return FeatureFlagService.getFeatureFlagStatus('less_recommendation_buckets')
      .then((flagValue) => (flagValue ? 7 : 20))
      .catch(() => 7);
  }

  static async getState(): Promise<ServiceState> {
    return FeatureFlagService.getFeatureFlagStatuses<ServiceState>([
      'disableRecommendations',
      'disableAdmissionLikelihood',
    ]).catch(() => ({disableRecommendations: false, disableAdmissionLikelihood: false}));
  }

  static async getRecommendationBucketColleges(
    options: GetBucketCollegesOptions
  ): Promise<RecommendedCollege[]> {
    const limit = options.limit
      ? options.limit
      : await RecommendationsService.getRecommendationLimit();
    const fields = RECOMMENDATION_BUCKET_FIELDS.concat(
      FIELDS_REQUIRED_BY_BUCKET[options.bucketId] || []
    );
    const query = qs.stringify({
      limit,
      'bucket-index': options.bucketIndex,
      'bucket-shown-event-id': options.bucketEventId,
      'num-buckets-shown': options.totalBucketCount,
      'partner-pos': '3,5,7',
      'recommendations-shown-event-id': options.recommendationsShownEventId,
      'unfollow-pos': '2,6,10',
      fields: fields.join(','),
      force: options.skipCache || false,
      impostor: options.imposterStudentId || '',
    });

    const response = await req({url: `/v1/colleges/discover/${options.bucketId}?${query}`});

    return formatColleges(response);
  }
}
