import {includes, some} from 'lodash';
import {parseQueryString} from 'shared/utils';

// hidden form fields
const HIDDEN_QUERY_PARAMETER_KEYS = ['eiid', 'rej', 'cpiid', 'token', 'next'];

export const ADULT_LEARNER = 'adult-learner';
export const TOO_YOUNG = 'TOO_YOUNG';

export function getHiddenParams() {
  const queryParams = parseQueryString(location.search.slice(1));
  const result = {};

  for (const key in queryParams) {
    if (queryParams.hasOwnProperty(key) && HIDDEN_QUERY_PARAMETER_KEYS.indexOf(key) !== -1) {
      result[key] = queryParams[key];
    }
  }
  return result;
}

// educator email validation
const GENERIC_EMAIL_HOSTS = ['@gmail.com', '@yahoo.com', '@hotmail.com'];

export const invalidEducatorEmail = (email) =>
  some(GENERIC_EMAIL_HOSTS, (host) => includes(email, host));

export const getReferralDataFromUrl = (state?: {urlParameters}) => {
  const urlParameters = state?.urlParameters;

  const {
    invite_code: inviteCode,
    listing_code: listingCode,
    signup_form_location: signupFormLocation,
    student_invitation_id: studentInvitationId,
  } = parseQueryString((urlParameters || window.location.search).slice(1));

  return {
    cookie: true,
    referral: {
      signup_form_location: signupFormLocation,
      invite_code: inviteCode,
      listing_code: listingCode,
      student_invitation_id: studentInvitationId,
    },
  };
};
