import {camelCase} from 'lodash';
import {handleActions} from 'redux-actions';

// feature-interaction actions
import {
  RECEIVE_FEATURE_INTERACTION,
  RECEIVE_FEATURE_INTERACTION_CREATION,
  RECEIVE_FEATURE_INTERACTION_CHANGE,
} from 'shared/actions/feature-interactions';

const reduceFeatureInteractionResponse = (
  featureInteractions,
  {payload: {featureName, featureData}}
) => ({
  ...featureInteractions,
  [camelCase(featureName)]: {
    ...featureInteractions[camelCase(featureName)],
    ...featureData, // Merge data, since fetch has a fields parameter
  },
});

export const featureInteractionsReducer = handleActions(
  {
    [RECEIVE_FEATURE_INTERACTION]: reduceFeatureInteractionResponse,
    [RECEIVE_FEATURE_INTERACTION_CHANGE]: reduceFeatureInteractionResponse,
    [RECEIVE_FEATURE_INTERACTION_CREATION]: reduceFeatureInteractionResponse,
  },
  {}
);
