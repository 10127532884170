import {RECEIVE_ACHIEVEMENT_ITEMS} from '../actions/achievement-items';

export default function (state = [], action) {
  switch (action.type) {
    case RECEIVE_ACHIEVEMENT_ITEMS:
      return action.achievementItems;
    default:
      return state;
  }
}
