import {map, keys} from 'lodash';
import browserHistory from 'shared/history';
import req from 'shared/req';
import GoogleOAuth from 'shared/modules/google-oauth';
import GoogleApiClassroom from 'shared/modules/google-api-classroom';
import {addNotification, errorMessage, flashMessage} from 'shared/actions/notifications';
import {splitEmails, separateValidEmails} from 'shared/referral-modal/utils';
import {displayInvalidEmailsModal} from 'educator/actions/invalid-emails-modal';
import placeholders from 'src/static/placeholders.json';
export const OPEN_REFERRAL_MODAL = 'OPEN_REFERRAL_MODAL';
export const CLOSE_REFERRAL_MODAL = 'CLOSE_REFERRAL_MODAL';
export const FETCH_COURSES = 'FETCH_COURSES';
export const RECEIVE_COURSES = 'RECEIVE_COURSES';
export const TOGGLE_COURSE = 'TOGGLE_COURSE';
export const SENDING_INVITES = 'SENDING_INVITES';
export const SENT_INVITES = 'SENT_INVITES';

export const fetchCourses = () => (dispatch) =>
  GoogleOAuth.authorize()
    .then(() => {
      dispatch({type: FETCH_COURSES});

      return GoogleApiClassroom.getCoursesWithStudents((coursesData) => {
        const courses = map(coursesData, (course) => ({
          cid: course.id,
          name: course.name,
          students: map(course.students, 'profile.emailAddress'),
          selected: false,
        }));

        dispatch({type: RECEIVE_COURSES, courses});

        return courses;
      });
    })
    .fail(() => {});

export const toggleCourse = (index) => ({type: TOGGLE_COURSE, index});

export const openReferralModal = () =>
  browserHistory.push(`${location.pathname}/invite/share-link`);

export const closeReferralModal = () => ({type: CLOSE_REFERRAL_MODAL});

const invalidEmailErrorMessage = `Invalid emails found, please ensure emails follow this formatting "${placeholders.exampleEmail}"`;

const handleInvalidEmails = (sentEmailsCount, invalidEmails, dispatch) => {
  const invalidEmailsLength = invalidEmails.length;

  browserHistory.push(location.pathname.split('/invite')[0]);

  dispatch(
    addNotification({
      action: displayInvalidEmailsModal(invalidEmails),
      actionText: 'View invalid emails.',
      strongText: `${sentEmailsCount} Invite${
        sentEmailsCount > 1 ? 's were' : ' was'
      } successfully sent!`,
      template: 'action',
      text: `${invalidEmailsLength} email${invalidEmailsLength > 1 ? 's were' : ' was'} invalid.`,
    })
  );
};

export const inviteEmails = ({emails, message}, setFieldError) => {
  emails = splitEmails(emails);
  const emailsByType = separateValidEmails(emails);
  emails = keys(emailsByType.valid);

  return (dispatch) => {
    if (!emails.length) {
      setFieldError('emails', invalidEmailErrorMessage);
      return;
    }

    return req({
      url: '/educator/invite_students_ajax',
      method: 'POST',
      data: {emails, message},
    })
      .then(({invalidEmails}) => {
        const successfullySentEmailsCount = emails.length - invalidEmails.length;

        invalidEmails.forEach((email) => (emailsByType.invalid[email] = true));
        invalidEmails = keys(emailsByType.invalid);

        if (invalidEmails.length) {
          handleInvalidEmails(successfullySentEmailsCount, invalidEmails, dispatch);
        } else {
          dispatch(flashMessage('Invites Sent!'));
        }

        dispatch(closeReferralModal());
      })
      .catch((errors) => {
        if (errors && errors.length) {
          dispatch(flashMessage(errors.join(' '), 'error'));
        } else {
          dispatch(errorMessage());
        }
        dispatch(closeReferralModal());
      });
  };
};

export const inviteClassrooms = (courses) => (dispatch) => {
  dispatch({type: SENDING_INVITES});

  return req({
    url: '/educators/me/google-classroom-courses',
    method: 'PUT',
    data: {courses},
  })
    .then(() => {
      dispatch({type: SENT_INVITES, connectedCourses: courses});
      dispatch(flashMessage('Invites Sent!'));
    })
    .catch(() => {
      dispatch(errorMessage());
    })
    .finally(() => {
      dispatch(closeReferralModal());
    });
};
