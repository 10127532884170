import req from 'shared/req';
import {flashMessage} from 'shared/actions/notifications';
import browserHistory from 'shared/history';
import {EDIT_COLLEGE_SUCCESS_ITEM} from 'shared/portfolio/actions/college-success-items';
import {closeActivityModalPath} from '../utils';

export const ADD_FAFSA_SUBMISSION = 'ADD_FAFSA_SUBMISSION';
export const REMOVE_FAFSA_SUBMISSION = 'REMOVE_FAFSA_SUBMISSION';
export const isFafsaItem = (item) => item._type === 'FafsaSubmissionItem';

const addFafsaToCollegeSuccess = (fafsaSubmission) => ({
  type: EDIT_COLLEGE_SUCCESS_ITEM,
  item: fafsaSubmission,
});

// Can remove after fafsaSubmission removal script run.
export const addFafsaSubmission = (fafsaSubmission) => ({
  type: ADD_FAFSA_SUBMISSION,
  fafsaSubmission,
});

export const removeFafsaSubmission = () => ({
  type: REMOVE_FAFSA_SUBMISSION,
});

export const createFafsaSubmission = (values) => (dispatch) =>
  req({
    url: '/v1/users/me/fafsa-submissions',
    method: 'post',
    data: values,
  }).then((fafsa) => {
    dispatch(removeFafsaSubmission());
    dispatch(addFafsaSubmission(fafsa));
    dispatch(addFafsaToCollegeSuccess(fafsa));
    browserHistory.replace(closeActivityModalPath());
    dispatch(flashMessage('FAFSA submission added!'));
  });

export const deleteFafsaSubmission = (id) => (dispatch) =>
  req({
    url: `/v1/users/me/fafsa-submissions/${id}`,
    method: 'delete',
  }).then(() => {
    dispatch(removeFafsaSubmission());
    dispatch(flashMessage('FAFSA submission successfully removed.'));
  });
