import React from 'react';
import Icon from 'shared/components/icon';
import {gpaFormat} from 'shared/utils';
import browserHistory from 'shared/history';

interface Props {
  collegeProfile: {
    userInfo: {
      highSchoolInfo: {senior: boolean};
    };
    college: {
      partnerApplicationDeadlineDay: string;
      partnerApplicationDeadlineMonth: string;
      partnerSubmissionDeadlineDay: string;
      partnerSubmissionDeadlineMonth: string;
      earningRelationship: {
        statusType: string;
        minGPA: number;
      };
      isFollowing: boolean;
    };
  };
  missingCredits: number;
}

export const navigateToRequirements = (transfer) => {
  const pathnameSplit = browserHistory.location.pathname.split('/');
  const currentPath = pathnameSplit.pop();
  const basePath = pathnameSplit.join('/');
  const scrollToMicroScholarshipsSection = () => window.scrollTo(0, 1060);

  let targetPath;

  if (transfer) {
    if (currentPath === 'scholarships') {
      scrollToMicroScholarshipsSection();
      return;
    } else {
      targetPath = `${currentPath}/scholarships`;
    }
  } else {
    if (currentPath === 'scholarships') {
      targetPath = `${basePath}/requirements`;
    } else if (basePath === '/edu') {
      targetPath = `${currentPath}/requirements`;
    } else {
      scrollToMicroScholarshipsSection();
      return;
    }
  }

  browserHistory.push(targetPath);
  scrollToMicroScholarshipsSection();
};

const CollegeStatusText = ({
  missingCredits,
  collegeProfile: {
    userInfo: {highSchoolInfo},
    college,
  },
}: Props) => {
  const applicationDeadlineMonth = college.partnerApplicationDeadlineMonth + 1;
  const submissionDeadlineMonth = college.partnerSubmissionDeadlineMonth + 1;

  switch (college.earningRelationship.statusType) {
    case 'earning_not_following':
      return (
        <div className="college-profile-status-text">
          <p className="college-profile-status-text-awards">Awards scholarships on RaiseMe</p>
          <p>Follow to earn scholarships from this school.</p>
        </div>
      );
    case 'earning_deadline_soon': {
      if (missingCredits > 0) {
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-warning">
              <Icon className="college-profile-status-text-icon" iconType="flag" />
              Earnings Deadline
            </p>
            <p>
              {`Add at least ${missingCredits} courses by
                ${submissionDeadlineMonth}/${college.partnerSubmissionDeadlineDay}.`}
            </p>
          </div>
        );
      } else {
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-positive">
              <Icon className="college-profile-status-text-icon" iconType="flag" />
              On-Track for Submission
            </p>
            <p>
              {`Way to go! You're on track to have your earnings automatically submitted on
                ${submissionDeadlineMonth}/${college.partnerSubmissionDeadlineDay}.`}
            </p>
          </div>
        );
      }
    }
    case 'earning': {
      if (college.isFollowing) {
        if (highSchoolInfo.senior && missingCredits <= 0) {
          return (
            <div className="college-profile-status-text">
              <p className="college-profile-status-text-positive">
                <Icon className="college-profile-status-text-icon" iconType="flag" />
                On-Track for Submission
              </p>
              <p>
                {`Way to go! You're on track to have your earnings automatically submitted on
                  ${submissionDeadlineMonth}/${college.partnerSubmissionDeadlineDay}. Also, be sure to apply
                  by ${applicationDeadlineMonth}/${college.partnerApplicationDeadlineDay}.`}
              </p>
            </div>
          );
        } else {
          return null;
        }
      } else {
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-awards">Awards scholarships on RaiseMe</p>
            <p>Follow to earn scholarships from this school.</p>
          </div>
        );
      }
    }
    case 'submitted':
      return (
        <div className="college-profile-status-text">
          <p className="college-profile-status-text-positive">
            <Icon className="college-profile-status-text-icon" iconType="check" />
            You’ve Been Submitted!
          </p>
          <p>
            {`Apply by ${applicationDeadlineMonth}/${college.partnerApplicationDeadlineDay}, enroll, and `}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault(); // Prevents full page reload
                navigateToRequirements(false);
              }}
            >
              meet any additional requirements
            </a>
            {' set by the school (if needed) to be awarded.'}
          </p>
        </div>
      );
    case 'deadline_passed':
      return (
        <div className="college-profile-status-text">
          <p className="college-profile-status-text-warning">
            <Icon className="college-profile-status-text-icon" iconType="flag" />
            Deadline to Earn Has Passed
          </p>
          <p>
            {`The deadline to earn from this school on RaiseMe
            has passed. Visit their website to see what aid opportunities
            may be available to you.`}
          </p>
        </div>
      );
    case 'us_citizenship_required':
    case 'complete_profile':
      return (
        <div className="college-profile-status-text">
          <p className="college-profile-status-text-warning">
            <Icon className="college-profile-status-text-icon" iconType="flag" />
            Complete Your Profile
          </p>
          <p>Complete account settings to check eligibility.</p>
        </div>
      );
    case 'boost_gpa':
      return (
        <div className="college-profile-status-text">
          <p className="college-profile-status-text-warning">
            <Icon className="college-profile-status-text-icon" iconType="flag" />
            Boost Your GPA
          </p>
          <p>
            {`Add classes to boost your GPA to ${gpaFormat(college.earningRelationship.minGPA)}
            and earn from this school.`}
          </p>
        </div>
      );
    case 'us_citizens_only':
      return (
        <div className="college-profile-status-text">
          <p className="college-profile-status-text-neutral">
            <Icon className="college-profile-status-text-icon" iconType="flag" />
            Only Available to U.S. Citizens
          </p>
          {highSchoolInfo.senior ? (
            <p>
              {`This college only offers scholarships on RaiseMe to U.S. citizens
                and permanent residents, but you can still apply to go to this college by
                ${applicationDeadlineMonth}/${college.partnerApplicationDeadlineDay}.`}
            </p>
          ) : (
            <p>
              {`This college only offers scholarships on RaiseMe
                to U.S. citizens and permanent residents, but you
                can still follow this college.`}
            </p>
          )}
        </div>
      );
    case 'churned':
      return (
        <div className="college-profile-status-text">
          <p className="college-profile-status-text-warning">
            <Icon className="college-profile-status-text-icon" iconType="flag" />
            No Longer Awarding New Micro-Scholarships
          </p>
          <p>Learn more on the scholarships tab.</p>
        </div>
      );
    case 'scholarships_not_yet_available':
      return (
        <div className="college-profile-status-text">
          <p className="college-profile-status-text-neutral">
            <Icon className="college-profile-status-text-icon" iconType="flag" />
            Micro-Scholarships Not Currently Available
          </p>
          {highSchoolInfo.senior ? (
            <p>
              {`This college does not currently offer micro-scholarships on RaiseMe.
              You can still apply to go to this college.`}
            </p>
          ) : (
            <p>
              {`This college does not currently offer micro-scholarships on
              RaiseMe. Follow this college to be alerted if and when
              micro-scholarships become available.`}
            </p>
          )}
        </div>
      );
    case 'limited_scholarships':
      return (
        <div className="college-profile-status-text">
          <p className="college-profile-status-text-neutral">
            <Icon className="college-profile-status-text-icon" iconType="flag" />
            Only available to select students
          </p>
          <p>
            {`This college either does not currently have a micro-
            scholarship program or does not have a program
            for which you are eligible, based on your Raiseme
            profile. You can still apply and may be eligible for
            other types of institutional aid.
            `}
          </p>
        </div>
      );
    default:
      return null;
  }
};

export default CollegeStatusText;
