import React from 'react';
import {Props as TextInputProps} from './text-input';
import placeholders from 'src/static/placeholders.json';
import {emailOrPhoneRegex} from 'src/utils/validations';
import FormikTextInput from 'src/components/inputs/formik-text-input';
import {SpecificInputProps} from './utils';

const FormikEmailOrPhoneInput = ({
  name = 'identifier',
  ...props
}: TextInputProps & SpecificInputProps) => (
  <FormikTextInput
    {...props}
    name={name}
    placeholder={placeholders.phoneOrEmail}
    pattern={emailOrPhoneRegex.source}
  />
);

export default FormikEmailOrPhoneInput;
