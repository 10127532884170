import {merge} from 'lodash';
import {objectId} from 'shared/utils';
import {portfolioUrl} from 'shared/portfolio/utils';
import req, {rejectCamelizedErrors, rejectGenericError} from 'shared/req';

export const NEW_HONOR_AWARD = 'NEW_HONOR_AWARD';
export const SAVE_HONOR_AWARD = 'SAVE_HONOR_AWARD';
export const EDIT_HONOR_AWARD = 'EDIT_HONOR_AWARD';
export const CANCEL_HONOR_AWARD = 'CANCEL_HONOR_AWARD';
export const RECEIVE_HONOR_AWARD = 'RECEIVE_HONOR_AWARD';
export const HONOR_AWARD_DELETED = 'HONOR_AWARD_DELETED';
export const SCROLLED_TO_HONOR_AWARD = 'SCROLLED_TO_HONOR_AWARD';
export const RECEIVE_HONOR_AWARDS = 'RECEIVE_HONOR_AWARDS';

export function newHonorAward(options = {}) {
  const honorAward = {
    _id: objectId(),
    issuer: '',
    name: '',
    description: '',
    month: null,
    year: null,
    editing: true,
    new: true,
    scrollTo: !!options.scrollTo,
  };

  return {
    type: NEW_HONOR_AWARD,
    id: `honor-award-${honorAward._id}`,
    honorAward,
  };
}

export function cancelHonorAward(honorAward) {
  return {
    type: CANCEL_HONOR_AWARD,
    id: `honor-award-${honorAward._id}`,
    honorAward,
  };
}

export function deleteHonorAward(id) {
  return (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(`/honors/${id}`, _id),
      method: 'DELETE',
    }).then(() => {
      dispatch({
        type: HONOR_AWARD_DELETED,
        id: `honor-award-${id}`,
      });
    });
  };
}

export function editHonorAward(honorAward) {
  return {
    type: EDIT_HONOR_AWARD,
    id: `honor-award-${honorAward._id}`,
    honorAward,
  };
}

export function receiveHonorAward(honorAward) {
  return {
    type: RECEIVE_HONOR_AWARD,
    id: `honor-award-${honorAward._id}`,
    honorAward,
  };
}

export function scrolledToHonorAward(id) {
  return {
    type: SCROLLED_TO_HONOR_AWARD,
    id: `honor-award-${id}`,
  };
}

export const saveHonorAward = (honorAward) => (dispatch, getState) => {
  let url;
  let method;

  if (honorAward.new) {
    url = '/honors';
    method = 'POST';
  } else {
    url = `/honors/${honorAward._id}`;
    method = 'PATCH';
  }

  const {
    student: {_id},
  } = getState();
  return req({
    url: portfolioUrl(url, _id),
    method: method,
    data: honorAward,
  })
    .then((receivedHonorAward) => {
      dispatch(receiveHonorAward(merge({}, receivedHonorAward, {scrollTo: true})));
    })
    .catch(rejectCamelizedErrors)
    .catch(rejectGenericError);
};
