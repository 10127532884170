import req from 'shared/req';

export const GET_FEATURE_FLAGS = 'GET_FEATURE_FLAGS';
export const RECEIVE_FEATURE_FLAGS = 'RECEIVE_FEATURE_FLAGS';

export const getFeatureFlags = () => ({type: GET_FEATURE_FLAGS});
export const receiveFeatureFlags = (flags) => ({type: RECEIVE_FEATURE_FLAGS, flags});

export const fetchFeatureFlags = () => (dispatch) => {
  dispatch(getFeatureFlags());
  return req({url: '/v1/feature-flags'})
    .then((flags) => dispatch(receiveFeatureFlags(flags)))
    .catch((error) => dispatch(receiveFeatureFlags(error)));
};
