import React, {useState} from 'react';
import classnames from 'classnames';
import {kebabCase} from 'lodash';
import Collapse from '@mui/material/Collapse';
import Icon from 'shared/components/icon';
import {track} from 'src/utils/analytics';

import './collapsible-section.scss';

interface Props {
  children: React.ReactNode;
  disableCollapse?: boolean;
  disableOpen?: boolean;
  collapseLabel?: React.ReactNode;
  subtitle?: string;
  title: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  trackIdentifier?: string;
  onEnter?: () => void;
  subtitleTestid?: string;
  titleTestid?: string;
  // controlled component props
  open?: boolean;
  onClick?: () => void;
}

const CollapsibleSection = ({
  children,
  disableCollapse = false,
  disableOpen = false,
  collapseLabel,
  subtitle,
  title,
  className,
  isOpen = window.screen.width > 479,
  trackIdentifier,
  onEnter,
  subtitleTestid,
  titleTestid,
  open,
  onClick,
}: Props) => {
  // If disableCollapse is true, it should always start opened
  const [opened, setOpened] = useState(disableCollapse || isOpen);
  const controlled = typeof open === 'boolean' && onClick;
  const openValue = controlled ? open : opened;

  const handleTriggerClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (disableCollapse) return;

    onEnter?.();

    if (opened) {
      if (trackIdentifier) {
        track(`${kebabCase(trackIdentifier)}-close`, {});
      }

      setOpened(false);
    } else {
      if (trackIdentifier) {
        track(`${kebabCase(trackIdentifier)}-open`, {});
      }

      setOpened(true);
    }
  };

  const handeCustomClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (disableCollapse) return;

    onClick?.();

    if (open) {
      if (trackIdentifier) {
        track(`${kebabCase(trackIdentifier)}-close`, {});
      }
    } else {
      if (trackIdentifier) {
        track(`${kebabCase(trackIdentifier)}-open`, {});
      }
    }
  };

  return (
    <section
      className={classnames('collapsible-section', className, {opened: openValue})}
      data-testid="collapsible-section"
    >
      {!disableCollapse && !disableOpen ? (
        <button
          className="icon-button"
          aria-expanded={openValue}
          onClick={controlled ? handeCustomClick : handleTriggerClick}
          data-testid="collapsible-section-button"
        >
          <span className="collapsible-section-title" data-testid={titleTestid}>
            {title}
          </span>
          <div className="collapse-group">
            {collapseLabel && <span className="label">{collapseLabel}</span>}
            <Icon
              iconType={openValue ? 'chevron-up' : 'chevron-down'}
              className="collapsible-section-icon"
            />
          </div>
        </button>
      ) : (
        <div className="collapsible-section-title-container--disabled">
          <span className="collapsible-section-title" data-testid={titleTestid}>
            {title}
          </span>
          <div className="collapse-group">
            {collapseLabel && <span className="label">{collapseLabel}</span>}
          </div>
        </div>
      )}
      <Collapse in={openValue}>
        <div>
          {subtitle && (
            <div className="subtitle" data-testid={subtitleTestid}>
              {subtitle}
            </div>
          )}
          {children}
        </div>
      </Collapse>
    </section>
  );
};

export default CollapsibleSection;
