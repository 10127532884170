import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

const xs = 480;
const sm = 768;
const md = 992;
const lg = 1200;

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const XS = ({children}) => <MediaQuery maxWidth={xs - 1}>{children}</MediaQuery>;

const MS = ({children}) => (
  <MediaQuery minWidth={xs} maxWidth={sm - 1}>
    {children}
  </MediaQuery>
);

const SM = ({children}) => (
  <MediaQuery minWidth={sm} maxWidth={md - 1}>
    {children}
  </MediaQuery>
);

const MD = ({children}) => (
  <MediaQuery minWidth={md} maxWidth={lg - 1}>
    {children}
  </MediaQuery>
);

const LG = ({children}) => <MediaQuery minWidth={lg}>{children}</MediaQuery>;

const MS_AND_DOWN = ({children}) => <MediaQuery maxWidth={sm - 1}>{children}</MediaQuery>;

const SM_AND_DOWN = ({children}) => <MediaQuery maxWidth={md - 1}>{children}</MediaQuery>;

const MD_AND_DOWN = ({children}) => <MediaQuery maxWidth={lg - 1}>{children}</MediaQuery>;

const MS_AND_UP = ({children}) => <MediaQuery minWidth={xs}>{children}</MediaQuery>;

const SM_AND_UP = ({children}) => <MediaQuery minWidth={sm}>{children}</MediaQuery>;

const MD_AND_UP = ({children}) => <MediaQuery minWidth={md}>{children}</MediaQuery>;

const LG_AND_UP = ({children}) => <MediaQuery minWidth={lg}>{children}</MediaQuery>;

const print = ({children}) => <MediaQuery query="print">{children}</MediaQuery>;

XS.propTypes = propTypes;
MS.propTypes = propTypes;
SM.propTypes = propTypes;
MD.propTypes = propTypes;
LG.propTypes = propTypes;
MS_AND_DOWN.propTypes = propTypes;
SM_AND_DOWN.propTypes = propTypes;
MD_AND_DOWN.propTypes = propTypes;
MS_AND_UP.propTypes = propTypes;
SM_AND_UP.propTypes = propTypes;
MD_AND_UP.propTypes = propTypes;
LG_AND_UP.propTypes = propTypes;
print.propTypes = propTypes;

export default {
  XS,
  MS,
  SM,
  MD,
  LG,
  MS_AND_DOWN,
  SM_AND_DOWN,
  MD_AND_DOWN,
  MS_AND_UP,
  SM_AND_UP,
  MD_AND_UP,
  LG_AND_UP,
  print,
};
