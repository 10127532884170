import {track} from 'src/utils/analytics';
import cardTypeInfo from 'shared/micro-scholarships/cardTypeInfo';

export const onClickCard = (cardInfo) => {
  if (cardInfo.key === cardTypeInfo.oppPopularActivity.key) {
    track('Popular Activity Card Clicked', {
      title: cardInfo.title,
      group: cardInfo.group,
      rank: cardInfo.rank,
    });
  }
};
