import {START_UPLOADING, FINISH_UPLOADING, UPLOAD_ERROR} from 'shared/actions/avatar';

const defaultState = {
  uploading: false,
};

export function avatarReducer(state = defaultState, action) {
  switch (action.type) {
    case UPLOAD_ERROR:
      return {
        uploading: false,
      };
    case START_UPLOADING:
      return {
        uploading: true,
      };
    case FINISH_UPLOADING:
      return {
        uploading: false,
      };
    default:
      return state;
  }
}
