import {
  GET_USER_INFO,
  SENDING_VERIFY_EMAIL,
  SENT_VERIFY_EMAIL,
  HIDE_VERIFIED,
} from 'src/redux/studentNotificationBanner/actions';
import {PHONE_NUMBER_VERIFIED} from 'shared/actions/verify-mobile';

export const studentNotificationBanner = (state = {loading: true}, action) => {
  switch (action.type) {
    case GET_USER_INFO:
      return {
        ...state,
        ...action.user,
        loading: action.loading,
      };
    case SENDING_VERIFY_EMAIL:
      return {
        ...state,
        sendingVerifyEmail: true,
      };
    case SENT_VERIFY_EMAIL:
      return {
        ...state,
        sendingVerifyEmail: false,
      };
    case HIDE_VERIFIED:
      return {
        ...state,
        showVerifiedConfirmation: false,
      };
    case PHONE_NUMBER_VERIFIED.toString():
      return {
        ...state,
        mobileVerified: true,
      };
    default:
      return state;
  }
};
