import {concat, findIndex, inRange, map, uniqBy} from 'lodash';
import {
  APPEND_INVITATIONS,
  CLEAR_INPUT,
  CLOSE_REFERRAL_MODAL,
  FOLLOW_COLLEGE,
  OPEN_REFERRAL_MODAL,
  PREPEND_INVITATIONS,
  RECEIVE_CONTACTS,
  RECEIVE_PARTICIPATING_COLLEGES,
  SELECT_ALL_CONTACTS,
  SELECT_CONTACT,
  SENT_INVITE,
  SET_PAGE,
  UNFOLLOW_COLLEGE,
  UPDATE_FILTER,
  UPDATE_IDENTIFIER,
} from './actions';

const initialState = {
  contacts: [],
  filter: '',
  identifier: '',
  invitations: null,
  page: 0,
  sent: false,
  show: false,
};

export function referralModalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_REFERRAL_MODAL:
      return {...state, show: true};
    case CLOSE_REFERRAL_MODAL:
      return {...state, show: false};
    case UPDATE_IDENTIFIER:
      return {...state, identifier: action.identifier};
    case SET_PAGE:
      return {
        ...state,
        page: inRange(action.page, 0, 4) ? action.page : state.page,
      };
    case SENT_INVITE:
      return {...state, sent: true};
    case APPEND_INVITATIONS: {
      const invitations = uniqBy(
        concat(state.invitations || [], action.invitations || []),
        'display'
      );

      return {...state, invitations};
    }
    case PREPEND_INVITATIONS: {
      const invitations = uniqBy(
        concat(action.invitations || [], state.invitations || []),
        'display'
      );

      return {...state, invitations};
    }
    case CLEAR_INPUT:
      return {...state, identifier: '', sent: false};
    case RECEIVE_CONTACTS:
      return {...state, contacts: action.contacts, page: 3};
    case SELECT_CONTACT: {
      const newContacts = [...state.contacts];
      const index = findIndex(newContacts, {email: action.email});
      if (index >= 0) {
        newContacts[index].selected = !newContacts[index].selected;
      }

      return {...state, contacts: newContacts};
    }
    case SELECT_ALL_CONTACTS: {
      const newContacts = map(state.contacts, (contact) => {
        contact.selected = true;
        return contact;
      });
      return {...state, contacts: newContacts};
    }
    case UPDATE_FILTER:
      return {...state, filter: action.filter};
    case FOLLOW_COLLEGE: {
      const newColleges = [...state.colleges];
      const index = findIndex(newColleges, {id: action.collegeId});
      if (index >= 0) {
        newColleges[index].followed = true;
      }

      return {...state, colleges: newColleges};
    }
    case UNFOLLOW_COLLEGE: {
      const newColleges = [...state.colleges];
      const index = findIndex(newColleges, {id: action.collegeId});
      if (index >= 0) {
        newColleges[index].followed = false;
      }

      return {...state, colleges: newColleges};
    }
    case RECEIVE_PARTICIPATING_COLLEGES: {
      return {
        ...state,
        colleges: action.colleges,
      };
    }
    default:
      return state;
  }
}
