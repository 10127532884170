import gapi from 'gapi';
import $ from 'jquery';
import {reject, concat} from 'lodash';

let apiLoaded = false;
const callbacksToRun = [];

const GoogleApiClassroom = {
  callApi: (cb) => {
    if (apiLoaded) {
      cb();
    } else {
      callbacksToRun.push(cb);
      GoogleApiClassroom.loadApi();
    }
  },

  loadApi: () => {
    gapi.client.load('classroom', 'v1').then(() => {
      apiLoaded = true;
      callbacksToRun.forEach((cb) => {
        cb();
      });
    });
  },

  getCourses: () => {
    const promise = $.Deferred();
    let courses = [];

    const collectCourses = (firstRequest, nextPageToken) => {
      const params = {pageSize: 30};

      if (!firstRequest) {
        params.pageToken = nextPageToken;
      }

      if (!firstRequest && !nextPageToken) {
        return promise.resolve(courses);
      }

      GoogleApiClassroom.callApi(() => {
        gapi.client.classroom.courses.list(params).then(
          (response) => {
            let activeCourses;

            if (response.result.courses && response.result.courses.length) {
              // Do not return courses that have been archived in Google Classroom.
              activeCourses = reject(response.result.courses, {
                courseState: 'ARCHIVED',
              });
            } else {
              activeCourses = [];
            }

            courses = concat(courses, activeCourses);

            return collectCourses(false, response.result.nextPageToken);
          },
          (errors) => promise.reject(errors)
        );
      });
    };

    collectCourses(true);
    return promise;
  },

  getStudents: (courseId) => {
    const promise = $.Deferred();
    let students = [];

    const collectStudents = (firstRequest, nextPageToken) => {
      const params = {courseId: courseId, pageSize: 30};

      if (!firstRequest) {
        params.pageToken = nextPageToken;
      }

      if (!firstRequest && !nextPageToken) {
        return promise.resolve(students);
      }

      GoogleApiClassroom.callApi(() => {
        gapi.client.classroom.courses.students.list(params).then(
          (response) => {
            const nextPageOfStudents = response.result.students || [];
            students = concat(students, nextPageOfStudents);
            return collectStudents(false, response.result.nextPageToken);
          },
          (errors) => promise.reject(errors)
        );
      });
    };

    collectStudents(true);
    return promise;
  },

  // makes a call to get all the courses and the students for each course
  // Accepts a callback that is passed an array of course objects that each have
  // the students added to them
  getCoursesWithStudents: (cb, failureCb = () => {}) => {
    $.when(GoogleApiClassroom.getCourses())
      .then((courses) => {
        const promises = [];

        courses.forEach((course) => {
          const promise = $.Deferred();
          promises.push(promise);

          $.when(GoogleApiClassroom.getStudents(course.id)).then((students) => {
            course.students = students || [];
            promise.resolve();
          });
        });

        $.when
          .apply($, promises)
          .done(() => {
            cb(courses);
          })
          .fail((errors) => {
            failureCb(errors);
          });
      })
      .fail((errors) => {
        failureCb(errors);
      });
  },
};

export default GoogleApiClassroom;
