import {flatMap, identity, isArray} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {US_ABBV_MAP} from 'shared/utils';
import {LOCATIONS} from 'src/components/CollegeSearch/Filters/LocationFilters/location-filters';
import {SIZES} from 'src/components/CollegeSearch/Filters/SizeFilters';
import {TYPES} from 'src/components/CollegeSearch/Filters/TypeFilters';
import Pill from 'src/components/Pill';
import {UserType} from 'src/types/enums';
import {currencyFormat, percentFormat} from 'src/utils/collegeSearch';

// helper functions
const rangeFormat = (formatFunc, value) =>
  `between ${formatFunc(value.min)} and ${formatFunc(value.max)}`;

const locationTag = (value) => {
  if ('city' in value || 'state' in value) {
    return 'city' in value ? value.city : US_ABBV_MAP[value.state];
  } else {
    const key = Object.keys(value)[0];
    return LOCATIONS[key];
  }
};

// Mapping filter key value to a tag name.
// E.g. Where let filter = filters['institution-level'] = ['2']
//      TAG_NAME_MAP['institution-level'](filter) -> '2 year'
const TAG_NAME_MAP = {
  'college-name': (value) => `College Name: ${value}`,
  'radius-location': (value) => `Radius: ${value.split(',')[2]} miles`,
  location: (value) => locationTag(value),
  majors: (value) => value,
  'acceptance-rate': (value) => `Acceptance Rate ${rangeFormat(percentFormat, value)}`,
  'percent-yield': (value) => `Yield ${rangeFormat(percentFormat, value)}`,
  'average-sat': (value) => `Average SAT ${rangeFormat(identity, value)}`,
  'average-act': (value) => `Average ACT ${rangeFormat(identity, value)}`,
  'offering-microscholarships': () => 'Awards scholarships on RaiseMe',
  following: (_value, userType) =>
    userType === UserType.EDUCATOR ? 'Followed by my students' : 'Currently Following',
  tuition: (value) => `Tuition ${rangeFormat(currencyFormat, value)}`,
  'average-net-price': (value) => `Average Net Price ${rangeFormat(currencyFormat, value)}`,
  'percent-need-met': (value) => `% of Need Met ${rangeFormat(percentFormat, value)}`,
  diversity: (value) => `Diversity ${rangeFormat(percentFormat, value)}`,
  'six-year-grad-rate': (value) => `Graduation Rate ${rangeFormat(percentFormat, value)}`,
  settings: (value) => value,
  'ncaa-division': (value) => `NCAA Division ${value}`,
  size: (value) => SIZES[value],
  control: (value) => value,
  type: (value) => TYPES[value],
  'institution-level': (value) => `${value} year`,
  'single-sex': (value) => `${value} College`,
  'historically-black': () => 'Historically Black College',
  'hispanic-serving': () => 'Hispanic-Serving Institution',
  tribal: () => 'Tribal College / University',
  'community-college': () => 'Community College',
  'trade-school': () => 'Trade School',
  'top-college-tags': (value) => `Tag: ${value}`,
};

export const COLLEGE_SEARCH_FILTERS = Object.keys(TAG_NAME_MAP);

const tagFromFilterValue = (filterKey, filterValue, userType) => {
  try {
    return TAG_NAME_MAP[filterKey](filterValue, userType);
  } catch (error) {
    console.error(`Uh oh, looks like ${filterKey} is not a collegeSearch filter`);
    console.error(error);
  }
};

export const areValidCollegeSearchFilters = (filters) =>
  Object.keys(filters)
    .map((filter) => COLLEGE_SEARCH_FILTERS.includes(filter))
    .every(Boolean);

/*
 * Takes in a object of filters and their values and returns an object without any invalid filter keys
 * This prevents 1. Bugs during development or 2. users putting wrong filters in manually
 */
export const cleanCollegeSearchFilters = (filters) =>
  Object.fromEntries(
    Object.entries(filters).filter((filter) => COLLEGE_SEARCH_FILTERS.includes(filter[0]))
  );

class FilterTags extends React.Component {
  static propTypes = {
    tags: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired,
  };

  handleFilterTagClicked = (filterName, index) => {
    const {handleClose} = this.props;
    return () => {
      handleClose(filterName, index);
    };
  };

  render() {
    const {tags, userType} = this.props;

    return (
      <div className="filter-tags-list" role="group" aria-label="Active filters">
        {flatMap(tags, (values, filterName) => {
          values = isArray(values) ? values : [values];

          return values
            .map((value) => tagFromFilterValue(filterName, value, userType))
            .map((tagName, index) => (
              <Pill
                key={`${filterName}-${index}`}
                color="white"
                onClick={this.handleFilterTagClicked(filterName, index)}
                iconRight="times-r"
              >
                {tagName}
              </Pill>
            ));
        })}
      </div>
    );
  }
}

export default FilterTags;
