import {reducer as formReducer} from 'redux-form';
import disabilitiesReducer from 'src/redux/disabilities/reducer';
import {collegeReducer} from './college';
import {collegeSearchReducer} from 'src/redux/collegeSearch/reducer';
import {collegeProfileReducer} from 'shared/college-profile/reducers/college-profile';
import {startsWithAcademicInterestsReducer} from 'shared/college-profile/reducers/starts-with-academic-interests-reducer';
import {
  LOADING_FALSE,
  LOADING_TRUE,
  RECEIVE_PORTFOLIO,
  RESET_PORTFOLIO,
} from 'shared/portfolio/actions/portfolio';
import {
  activitiesReducer,
  activityDescriptionReducer,
  leadershipPositionsReducer,
} from 'shared/portfolio/reducers/activities';
import {
  attendanceReducer,
  gradesReducer,
  preselectsReducer,
} from 'shared/portfolio/reducers/courses';
import {collegeCoursesReducer} from 'shared/portfolio/reducers/college-courses';
import {institutionsReducer} from 'shared/portfolio/reducers/institutions';
import {collegeEventsReducer} from 'shared/portfolio/reducers/college-events';
import {honorAwardsReducer} from 'shared/portfolio/reducers/honor-awards';
import {communityServiceReducer} from 'shared/portfolio/reducers/community-service';
import {experiencesReducer} from 'shared/portfolio/reducers/work-experiences';
import {testScoresReducer} from 'shared/portfolio/reducers/test-scores';
import {multimediaReducer} from 'shared/portfolio/reducers/multimedia';
import {collegeSuccessItemsReducer} from 'shared/portfolio/reducers/college-success-items';
import studentReducer from 'shared/portfolio/reducers/student';
import {FINISH_UPLOADING, START_UPLOADING} from 'shared/actions/minerva';
import {collegeSearchBarReducer} from 'shared/college-search-bar/reducers';
import followedCollegesReducer from 'shared/college-application-status/reducers/followed-colleges-reducer';
import {CLEAR_SCHOLARSHIP, RECEIVE_SCHOLARSHIP} from 'undergraduate-student/actions/scholarships';
import {
  RECEIVE_GOALS,
  RECEIVE_SATISFACTION,
} from 'undergraduate-student/actions/education-onboarding';
import {popoversReducer} from 'shared/reducers/popovers';
import {modalReducer} from 'shared/reducers/modal';
import {overlayReducer} from 'shared/reducers/overlay';
import {notificationsReducer} from 'shared/reducers/notifications';
import {avatarReducer} from 'shared/reducers/avatar';
import {filtersReducer} from 'shared/reducers/filters';
import {booleanReducer} from 'shared/reducers/boolean-reducer';
import {genericReducer} from 'shared/reducers/generic-reducer';
import {START_LOADING, STOP_LOADING} from 'shared/actions/loading';
import {accessibilityAnnouncementReducer} from 'shared/reducers/accessibility-announcement';
import notificationSettingsReducer from 'shared/account-settings/reducers/notification-settings';
import {awardNotificationsReducer} from 'shared/reducers/award-notifications';
import {collegeEarningsReducer} from 'shared/college-earnings/reducers/college-earnings';
import collegeSuccessItemTypesReducer from 'shared/college-success/reducers/college-success-item-types';
import {CLEAR_COLLEGES, RECEIVE_COLLEGES, UPDATE_COLLEGE} from 'shared/actions/college';
import {objectReducer} from 'shared/reducers/object-reducer';
import {RECEIVE_COLLEGE_INTERACTION_OPTIONS} from 'shared/portfolio/actions/college-events';
import {RECEIVE_SUBMISSIONS} from 'undergraduate-student/actions/submissions';
import notificationCenterReducers from 'shared/notification-center/reducers';
import {loadingReducer} from 'shared/reducers/loading';
import {
  NO_REMAINING_MESSAGES,
  REMAINING_MESSAGES,
} from 'shared/notification-center/actions/notification-messages';
import {RECEIVE_PHONE_NUMBER} from 'shared/actions/verify-mobile';
import {studentNotificationBanner} from 'src/redux/studentNotificationBanner/reducer';
import {featureInteractionsReducer} from 'shared/reducers/feature-interactions';
import toolbarNotificationReducer from 'src/redux/toolbar-notification/reducer';
import checklistReducer from '../../src/redux/checklist/reducer';
import checklistUserStatusReducer from '../../src/redux/checklistUserStatus/reducer';

export const undergraduateReducers = {
  accessibilityAnnouncement: accessibilityAnnouncementReducer,
  accountSettingsLoading: booleanReducer({
    defaultState: true,
    falseType: STOP_LOADING,
    trueType: START_LOADING,
  }),
  activities: activitiesReducer,
  activityDescription: activityDescriptionReducer,
  activityModalLoading: booleanReducer({
    defaultState: true,
    falseType: STOP_LOADING,
    trueType: START_LOADING,
  }),
  attendance: attendanceReducer,
  avatar: avatarReducer,
  awardNotifications: awardNotificationsReducer,
  banners: studentNotificationBanner,
  checklist: checklistReducer,
  checklistUserStatus: checklistUserStatusReducer,
  college: collegeReducer,
  collegeCourses: collegeCoursesReducer,
  collegeEarnings: collegeEarningsReducer,
  collegeEvents: collegeEventsReducer,
  collegeInteractionOptions: genericReducer({
    actionProperty: 'collegeInteractionOptions',
    defaultState: {},
    setAction: RECEIVE_COLLEGE_INTERACTION_OPTIONS,
  }),
  collegeProfile: collegeProfileReducer,
  collegeSatisfaction: genericReducer({
    actionProperty: 'satisfaction',
    defaultState: '',
    setAction: RECEIVE_SATISFACTION,
  }),
  collegeSearch: collegeSearchReducer,
  collegeSearchBar: collegeSearchBarReducer,
  collegeSuccessItemTypes: collegeSuccessItemTypesReducer,
  collegeSuccessItems: collegeSuccessItemsReducer,
  colleges: objectReducer({
    actionProperty: 'colleges',
    emptyObjectAction: CLEAR_COLLEGES,
    mergeObjectAction: UPDATE_COLLEGE,
    replaceObjectAction: RECEIVE_COLLEGES,
  }),
  communityServiceEvents: communityServiceReducer,
  coursePreselects: preselectsReducer,
  disabilities: disabilitiesReducer,
  featureInteractions: featureInteractionsReducer,
  filters: filtersReducer,
  followedColleges: followedCollegesReducer,
  form: formReducer,
  goals: genericReducer({
    actionProperty: 'goals',
    defaultState: [],
    setAction: RECEIVE_GOALS,
  }),
  grades: gradesReducer,
  honorAwards: honorAwardsReducer,
  institutions: institutionsReducer,
  leadershipPositions: leadershipPositionsReducer,
  loadMoreNotificationMessages: booleanReducer({
    defaultState: true,
    falseType: NO_REMAINING_MESSAGES,
    trueType: REMAINING_MESSAGES,
  }),
  loading: booleanReducer({defaultState: true, falseType: LOADING_FALSE, trueType: LOADING_TRUE}),
  microscholarshipsLoading: loadingReducer,
  modal: modalReducer,
  multimedia: multimediaReducer,
  notificationSettings: notificationSettingsReducer,
  notifications: notificationsReducer,
  overlay: overlayReducer,
  popovers: popoversReducer,
  portfolioEmpty: genericReducer({
    actionProperty: 'portfolioEmpty',
    defaultState: false,
    setAction: RECEIVE_PORTFOLIO,
  }),
  portfolioInitiated: booleanReducer({
    defaultState: false,
    falseType: RESET_PORTFOLIO,
    trueType: RECEIVE_PORTFOLIO,
  }),
  scholarship: objectReducer({
    actionProperty: 'scholarship',
    defaultState: {},
    emptyObjectAction: CLEAR_SCHOLARSHIP,
    replaceObjectAction: RECEIVE_SCHOLARSHIP,
  }),
  startsWithAcademicInterests: startsWithAcademicInterestsReducer,
  student: studentReducer,
  submissions: genericReducer({
    actionProperty: 'submissions',
    defaultState: [],
    setAction: RECEIVE_SUBMISSIONS,
  }),
  testScores: testScoresReducer,
  toolbarNotification: toolbarNotificationReducer,
  uploading: booleanReducer({
    defaultState: false,
    falseType: FINISH_UPLOADING,
    trueType: START_UPLOADING,
  }),
  userType: genericReducer({
    actionProperty: 'userType',
    defaultState: '',
    setAction: RECEIVE_PORTFOLIO,
  }),
  verifyMobile: genericReducer({
    actionProperty: 'payload',
    defaultState: '',
    setAction: RECEIVE_PHONE_NUMBER.toString(),
  }),
  workExperiences: experiencesReducer,
  ...notificationCenterReducers,
};
