import {
  RECEIVED_EARNINGS,
  SET_LOADING,
  DONE_ANIMATING,
  SHOW_NEW_EARNINGS,
  INCREASE_EARNINGS,
  SET_APPLICATION_STATUS,
  SETTING_APPLICATION_STATUS,
  RECEIVE_EARNINGS,
  setEarningFilter,
} from '../actions/college-earnings';

export const defaultState = {
  animating: false,
  applicationStatusIndicators: {},
  earningFilter: 'all',
  earnings: [],
  loading: true,
  previousEarnings: [],
  showNewEarnings: false,
};

export const collegeEarningsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_EARNINGS:
      return {
        ...state,
        earnings: action.earnings,
      };
    case RECEIVED_EARNINGS:
      return {
        ...state,
        animating: (action.animating || false) && !state.loading,
        earnings: action.earnings,
        loading: false,
        previousEarnings: state.earnings,
      };
    case SHOW_NEW_EARNINGS:
      return {
        ...state,
        showNewEarnings: true,
      };
    case INCREASE_EARNINGS:
      return {
        ...state,
        showNewEarnings: false,
      };
    case DONE_ANIMATING:
      return {
        ...state,
        animating: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SETTING_APPLICATION_STATUS:
      return {
        ...state,
        applicationStatusIndicators: {
          ...state.applicationStatusIndicators,
          [action.collegeId]: action.indicatorState,
        },
      };
    case SET_APPLICATION_STATUS:
      var appStatus = action.appStatus;
      return {
        ...state,
        earnings: updateEarningsByCollegeId(state.earnings, appStatus.collegeId, {
          applicationStatus: appStatus,
        }),
      };
    case setEarningFilter.toString():
      return {...state, earningFilter: action.payload};
    default:
      return state;
  }
};

const updateEarningsByCollegeId = (earnings, collegeId, update) =>
  earnings.map((earning) => (earning._id === collegeId ? {...earning, ...update} : earning));
