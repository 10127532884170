import PropTypes from 'prop-types';
import React from 'react';
import InfoTooltip from 'shared/components/info-tooltip';
import CostComparisonWithAverage from './cost-comparison-with-average';

class CompareLoanPayment extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    nationalCollegeData: PropTypes.object.isRequired,
  };

  render() {
    const {college, nationalCollegeData} = this.props;

    if (!college.typicalMonthlyStudentLoanPayment) {
      return null;
    }

    const loanTooltip = (
      <InfoTooltip className="college-profile-tooltip">
        The median monthly loan payment for student borrowers who completed, if it were repaid over
        10 years at a 4.45% interest rate. The national average is for borrowers aged 20 to 30, via
        StudentLoanHero.
      </InfoTooltip>
    );

    return (
      <CostComparisonWithAverage
        label="Average Monthly Loan Payment"
        tooltip={loanTooltip}
        dataSource={nationalCollegeData}
        attributeInSource="typicalMonthlyStudentLoanPayment"
        attributeOnCollege="typicalMonthlyStudentLoanPayment"
        college={college}
      />
    );
  }
}

export default CompareLoanPayment;
