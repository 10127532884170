import classnames from 'classnames';
import {concat, find} from 'lodash';
import React, {ReactElement, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  DEPRECATED_NOTIFICATION_SETTINGS,
  NOTIFICATION_SETTINGS,
  receiveNotificationSettings,
} from 'shared/account-settings/actions/notification-settings';
import NotificationSettingsForm from 'shared/account-settings/containers/notification-settings-form';
import EmptyHeader from 'shared/components/empty-header';
import req from 'shared/req';
import 'stylesheets/components/account-settings-form-container.scss';
import 'stylesheets/components/account-settings-form-section.scss';
import styles from './styles.module.scss';

interface UserInfo {
  hasEmail: boolean;
  hasMobile: boolean;
  notificationSettings: object;
  unsubscribed?: string;
}

const ALL_NOTIFICATION_SETTINGS = concat(NOTIFICATION_SETTINGS, DEPRECATED_NOTIFICATION_SETTINGS);

export default function Unsubscribe(): ReactElement {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  useEffect(function () {
    req<UserInfo>({
      url: '/v1/users/me/notification-settings',
      method: 'delete',
      data: {
        token: new URLSearchParams(window.location.search.substring(1)).get('token'),
      },
    }).then((response: UserInfo) => {
      setUserInfo(response);
      dispatch(receiveNotificationSettings(response.notificationSettings));
    });
  }, []);

  let unsubscribedFrom: string | undefined;
  if (userInfo) {
    const setting = find(ALL_NOTIFICATION_SETTINGS, {topic: userInfo.unsubscribed});
    if (setting) {
      unsubscribedFrom = setting.setting;
    }
  }

  return (
    <>
      <EmptyHeader signOutButton={false} signInButton />
      <div className={classnames(styles.root, 'account-settings-form-wrapper')}>
        <div className="account-settings-form-container">
          {!userInfo && 'Loading your subscription preferences...'}
          {userInfo && (
            <NotificationSettingsForm
              unsubscribedFrom={unsubscribedFrom}
              availableNotificationSettings={NOTIFICATION_SETTINGS}
              hasMobile={userInfo.hasMobile}
              hasEmail={userInfo.hasEmail}
            />
          )}
        </div>
      </div>
    </>
  );
}
