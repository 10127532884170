import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFormikContext} from 'formik';

import {getAcademicInterestList} from 'src/redux/lists';
import {Store} from 'src/types/store';
import Multiselect from 'src/components/FormikMultiselect/Multiselect';
import Label from './label';

interface Props {
  submitOnChange?: boolean;
  label: string;
}

const AcademicInterests = ({label, submitOnChange}: Props) => {
  const dispatch = useDispatch();
  const academicInterests = useSelector((state: Store) => state.lists.academicInterests);
  const {
    values: {academicInterestIds},
    setFieldValue,
    submitForm,
  } = useFormikContext<{academicInterestIds: string[]}>();

  useEffect(() => {
    dispatch(getAcademicInterestList());
  }, [dispatch]);

  return (
    <Label>
      {label}
      <Multiselect
        id="academic-interests"
        selected={academicInterestIds}
        options={academicInterests}
        onSelect={(selectedInterestId: string) => {
          setFieldValue('academicInterestIds', [...academicInterestIds, selectedInterestId]);

          if (submitOnChange) {
            submitForm();
          }
        }}
        onDelete={(id: string) => {
          setFieldValue(
            'academicInterestIds',
            academicInterestIds.filter((academicInterestId) => academicInterestId !== id)
          );

          if (submitOnChange) {
            submitForm();
          }
        }}
        maximumSelections={3}
      />
    </Label>
  );
};

export default AcademicInterests;
