import {resizeImage} from 'shared/utils';
import {UserType} from 'src/types/enums';
import vars from '../../src/utils/vars';
import JWTService from '../../src/services/JWTService';

export const START_UPLOADING = 'START_UPLOADING';
export const FINISH_UPLOADING = 'FINISH_UPLOADING';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const RECEIVE_IMAGE = 'RECEIVE_IMAGE';

export function uploadProfilePicture(userType, file) {
  let userPathId;

  switch (userType) {
    case UserType.HS_STUDENT:
    case UserType.CC_STUDENT:
    case UserType.UNDERGRADUATE_STUDENT:
      userPathId = 'users';
      break;
    case UserType.PARENT:
      userPathId = 'parents';
      break;
  }

  return async (dispatch) => {
    dispatch({
      type: START_UPLOADING,
    });
    dispatch({
      type: RECEIVE_IMAGE,
      image: file.preview,
    });

    const url = `${vars.API_GATEWAY}/v1/${userPathId}/me/photos`;
    const xhr = new XMLHttpRequest();
    console.log('Uploading avatar to url: ', url);

    const token = await JWTService.token();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.onload = () => {
      if (xhr.status === 200) {
        const data = JSON.parse(xhr.responseText);

        dispatch({
          type: FINISH_UPLOADING,
        });
        dispatch({
          type: RECEIVE_IMAGE,
          image: data.photo,
        });
      } else {
        console.error('Error uploading image', xhr.status, xhr.responseText);
        dispatch({
          type: UPLOAD_ERROR,
        });
      }
    };

    resizeImage(file).then(
      (image) => {
        const form = new FormData();
        form.append('photo', image, image.name || 'image.png');

        xhr.send(form);
      },
      (err) => {
        dispatch({
          type: UPLOAD_ERROR,
          error: err,
        });
      }
    );
  };
}
