export const CREATE_POPOVER = 'CREATE_POPOVER';
export const DESTROY_POPOVER = 'DESTROY_POPOVER';

export function createPopover(id) {
  return {
    type: CREATE_POPOVER,
    id,
  };
}

export function destroyPopover(id) {
  return {
    type: DESTROY_POPOVER,
    id,
  };
}
