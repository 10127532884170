import {connect} from 'react-redux';
import {getColleges, resetPage} from 'src/redux/collegeSearch/actions';
import {
  clearFilter,
  dismissTag,
  expandFilter,
  setFilter,
} from '../../../../../shared/actions/filters';
import LocationFilters from 'src/components/CollegeSearch/Filters/LocationFilters/location-filters';

const mapStateToProps = ({filters}) => ({filters});

const mapDispatchToProps = (dispatch) => ({
  getColleges() {
    dispatch(resetPage());
    dispatch(getColleges());
  },
  removeCityFilter(city) {
    dispatch(clearFilter({name: 'location'}));
    dispatch(dismissTag({name: 'location', valueId: city}));
  },
  adjustRadiusFilter(radius) {
    if (radius === 'Any') {
      dispatch(clearFilter({name: 'radius-location'}));
      dispatch(dismissTag({name: 'radius-location', valueId: radius}));
    } else {
      dispatch(setFilter({name: 'radius-location', value: radius}));
      dispatch(expandFilter({name: 'radius-location', value: radius}));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationFilters);
