import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {openModal} from 'shared/actions/modal';
import ScholarshipCTA from 'shared/college-profile/components/scholarship-cta';

const mapStateToProps = (state) => ({collegeProfile: state.collegeProfile});

export const mapDispatchToProps = (dispatch) => bindActionCreators({openModal}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipCTA);
