import {useEffect, useState} from 'react';
import {FormOption} from 'src/types/form';
import {LoadState} from 'src/types/enums';
import {DropdownOption} from 'src/components/dropdown';

export type Loader = () => Promise<FormOption[]>;

export function useOptionsLoader(value: DropdownOption[] | Loader): [DropdownOption[], LoadState] {
  const [loadState, setLoading] = useState(LoadState.NONE);
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (typeof value === 'function') {
      setLoading(LoadState.LOADING);

      value()
        .then((response) => {
          setOptions(response);
          setLoading(LoadState.LOADED);
        })
        .catch((error) => {
          setLoading(LoadState.FAILED);
          console.error(`[${value.name}] failed to get options`, error.toString());
        });
    } else {
      setOptions(value);
    }
  }, [value]);

  return [options, loadState];
}
