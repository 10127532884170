import {Modals} from 'src/types/enums';
import {RoutedModalConfig} from 'src/components/ModalContext/types';
import {lazy} from 'react';

export interface MatchParams {
  activitySlug: string;
}

export const clubClaimedConfig: RoutedModalConfig<MatchParams> = {
  type: Modals.CLUB_CLAIM,
  Component: lazy(() => import(/* webpackChunkName: "ClubClaimed" */ './ClubClaimed')),
  validateProps: (props) => Boolean(props.activitySlug),
  routed: true,
};
