import {updateParent} from 'shared/actions/parent';
import {changesSaved} from 'shared/actions/notifications';
import {verifyPhoneNumber} from 'shared/actions/verify-mobile';
import {omit} from 'lodash';
import {getParent} from 'shared/actions/parent';
import {errorMessage} from 'shared/actions/notifications';
import {stopLoading} from 'shared/actions/loading';
import {receiveNotificationSettings} from 'shared/account-settings/actions/notification-settings';

export const RECEIVE_PARENT = 'RECEIVE_PARENT';

export const updateParentInfo = (parent) => (dispatch) => {
  if (parent.mobilePartOne && parent.mobilePartTwo && parent.mobilePartThree) {
    parent.mobile = `${parent.mobilePartOne}${parent.mobilePartTwo}${parent.mobilePartThree}`;
  }

  if (parent.newEmail && parent.newEmailRepeat && parent.newEmail === parent.newEmailRepeat) {
    parent.email = parent.newEmail;
  }

  return new Promise((resolve, reject) =>
    updateParent(parent, PARENT_FIELDS)
      .then((updatedParent) => {
        dispatch(receiveParent(updatedParent));
        if (updatedParent.mobile) {
          dispatch(verifyPhoneNumber(updatedParent.mobile, 'sms'));
        } else {
          dispatch(changesSaved());
        }
        resolve();
      })
      .catch((errs) => {
        const errors = {};

        errs.forEach((err) => {
          switch (err.field) {
            case 'mobile':
              errors.mobilePartOne = `Mobile ${err.message}`;
              break;
            case 'email':
              errors.newEmail = err.message;
              break;
            default:
              errors[err.field] = err.message;
          }
        });

        reject(errors);
      })
  );
};

export const receiveParent = (parent) => ({
  type: RECEIVE_PARENT,
  parent,
});

export const PARENT_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'mobile',
  'addressLine1',
  'addressLine2',
  'subdivision',
  'city',
  'country',
  'postalCode',
  'confirmedUnverifiedAddress',
  'addressVerified',
  'image',
  'type',
].join(',');

export const getParentInfo = () => (dispatch) =>
  getParent('me', `${PARENT_FIELDS},notificationSettings`)
    .then((parent) => {
      dispatch(receiveParent(omit(parent, ['notificationSettings'])));
      dispatch(stopLoading());
      dispatch(receiveNotificationSettings(parent.notificationSettings));
    })
    .catch(() => {
      dispatch(errorMessage());
    });
