import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from 'shared/components/checkbox';
import Text from 'shared/components/text';
import Title from 'src/components/AccountSettings/Title';
import IonLink from 'src/components/mobile-link';
import 'stylesheets/components/account-settings-form-container.scss';
import styles from '../../../src/components/AccountSettings/styles.module.scss';
import {withPlatformContext} from '../../../src/hooks/usePlatform';
import MediaQuery from '../../media-query';
import NotificationSettingsFormHeader from './NotificationSettingsFormHeader/NotificationSettingsFormHeader';
import NotificationSettingsFormFooter from './notification-settings-form-footer';
import PlatformService from '../../../src/services/PlatformService';
import {
  arePushNotificationsEnabled,
  onPermissionChange,
  promptForPushNotifications,
} from '../../../src/services/push-notifications-service';

class NotificationSettingsForm extends React.Component {
  static propTypes = {
    availableNotificationSettings: PropTypes.arrayOf(
      PropTypes.shape({
        setting: PropTypes.string.isRequired,
        topic: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
    notificationSettings: PropTypes.object,
    hasMobile: PropTypes.bool.isRequired,
    hasEmail: PropTypes.bool.isRequired,
    saveNotification: PropTypes.func.isRequired,
    saveNotifications: PropTypes.func.isRequired,
    setNavigationAnnouncement: PropTypes.func,
    unsubscribedFrom: PropTypes.string,
    platformContext: {
      isIonic: PropTypes.bool,
    },
  };

  state = {
    unsubscribedAll: false,
    pushNotificationsEnabled: false,
  };

  componentDidMount() {
    const {setNavigationAnnouncement} = this.props;

    if (setNavigationAnnouncement) {
      setNavigationAnnouncement('Notification Settings');
    }

    arePushNotificationsEnabled().then((pushNotificationsEnabled) => {
      this.setState({pushNotificationsEnabled});
    });

    // Listen for changes to push notification permissions, sometimes it happens outside of the app
    onPermissionChange((pushNotificationsEnabled) => {
      this.setState({pushNotificationsEnabled});
    });
  }

  unsubscribeAll = () => {
    const emailUnsubscribeTopics = this.props.availableNotificationSettings.map(function (setting) {
      return {
        notificationTopic: setting.topic,
        channel: 'email',
        enabled: false,
      };
    });
    this.props
      .saveNotifications(emailUnsubscribeTopics)
      .then(() => this.setState({unsubscribedAll: true}));
  };

  render() {
    const {
      availableNotificationSettings,
      notificationSettings,
      hasEmail,
      hasMobile,
      saveNotification,
      platformContext: {isIonic},
    } = this.props;

    const hasPush = PlatformService.isNativeApp();
    const {pushNotificationsEnabled} = this.state;

    return (
      <>
        {!isIonic && (
          <MediaQuery.MS_AND_DOWN>
            <div className={styles.title}>
              <IonLink to="/account-settings">Account Settings Menu</IonLink>
            </div>
          </MediaQuery.MS_AND_DOWN>
        )}
        <div className="account-settings-form">
          <Title>Notification Settings</Title>
          <NotificationSettingsFormHeader
            availableNotificationSettings={availableNotificationSettings}
            unsubscribedFrom={this.props.unsubscribedFrom}
            unsubscribedAll={this.state.unsubscribedAll}
          />
          <div>
            {availableNotificationSettings.map(({setting, topic, text}, i) => {
              const labelId = `account-settings-notification-title-${i}`;
              const descriptionId = `account-settings-notification-description-${i}`;

              return (
                <section className="account-settings-form-section" key={topic}>
                  <div className="form-container">
                    <fieldset
                      role="group"
                      aria-labelledby={labelId}
                      aria-describedby={descriptionId}
                    >
                      <legend className="account-settings-form-notification-legend">
                        <Text id={labelId} weight={4} emphasis showOverflow>
                          {setting}
                        </Text>
                        <Text id={descriptionId} weight={5} showOverflow>
                          {text}
                        </Text>
                      </legend>
                      {hasMobile && (
                        <Checkbox
                          checked={!!(notificationSettings[topic] || {}).sms}
                          label="Mobile"
                          aria-label={`Check Box to Send ${setting} to my Mobile Phone`}
                          onChange={(e) => {
                            saveNotification({
                              notificationTopic: topic,
                              channel: 'sms',
                              enabled: e.target.checked,
                            });
                          }}
                        />
                      )}
                      {hasEmail && (
                        <Checkbox
                          checked={!!(notificationSettings[topic] || {}).email}
                          label="Email"
                          aria-label={`Check Box to Send ${setting} to my Email`}
                          onChange={(e) => {
                            saveNotification({
                              notificationTopic: topic,
                              channel: 'email',
                              enabled: e.target.checked,
                            });
                          }}
                        />
                      )}
                      {hasPush && (
                        <Checkbox
                          checked={
                            // If push notifications are not enabled, don't show the checkbox as checked regardless of the setting
                            pushNotificationsEnabled && !!(notificationSettings[topic] || {}).push
                          }
                          label="Push"
                          aria-label={`Check Box to Send ${setting} to my Mobile Device`}
                          onChange={(e) => {
                            // save the setting first and then prompt for push notifications if they are not enabled
                            saveNotification({
                              notificationTopic: topic,
                              channel: 'push',
                              enabled: e.target.checked,
                            });

                            // If push notifications are not enabled and they're trying to turn on a setting, prompt the user to enable them
                            if (!pushNotificationsEnabled && e.target.checked) {
                              promptForPushNotifications().then((enabled) => {
                                this.setState({pushNotificationsEnabled: enabled});
                              });
                            }
                          }}
                        />
                      )}
                    </fieldset>
                  </div>
                </section>
              );
            })}
          </div>
          <NotificationSettingsFormFooter unsubscribeAll={this.unsubscribeAll} />
        </div>
      </>
    );
  }
}

export default withPlatformContext(NotificationSettingsForm);
