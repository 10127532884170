import React from 'react';
import PropTypes from 'prop-types';
import FormButton from 'src/components/Button';

export default class WasThisHelpfulButtons extends React.Component {
  static propTypes = {
    // section identifier string used for tracking
    section: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    const {section} = props;
    this.yesButton = `gpa-modal-${section}-helpful-yes`;
    this.noButton = `gpa-modal-${section}-helpful-no`;

    this.state = {
      yesSelected: window.localStorage.getItem(this.yesButton) === 'true',
      noSelected: window.localStorage.getItem(this.noButton) === 'true',
    };
  }

  render() {
    return (
      <div className="shared-gpa-modal-helpful-container">
        Was this helpful?
        <FormButton
          outline={!this.state.yesSelected}
          size="small"
          horizontalMargin
          buttonName={this.yesButton}
          onClick={() => {
            window.localStorage.setItem(this.yesButton, true);
            window.localStorage.setItem(this.noButton, false);
            this.setState({yesSelected: true});
            this.setState({noSelected: false});
          }}
        >
          Yes
        </FormButton>
        <FormButton
          outline={!this.state.noSelected}
          size="small"
          buttonName={this.noButton}
          onClick={() => {
            window.localStorage.setItem(this.noButton, true);
            window.localStorage.setItem(this.yesButton, false);
            this.setState({noSelected: true});
            this.setState({yesSelected: false});
          }}
        >
          No
        </FormButton>
      </div>
    );
  }
}
