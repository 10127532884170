import {decamelizeKeys} from 'humps';
import {map, merge} from 'lodash';
import {allScores, apiTestTypeToModelType, portfolioUrl} from 'shared/portfolio/utils';
import req from 'shared/req';
import {objectId} from 'shared/utils';
import {errorMessage, flashMessage} from '../../../shared/actions/notifications';

export const NEW_TEST_SCORE = 'NEW_TEST_SCORE';
export const EDIT_TEST_SCORE = 'EDIT_TEST_SCORE';
export const CANCEL_EDIT_TEST_SCORE = 'CANCEL_EDIT_TEST_SCORE';

export const RECEIVE_TEST_SCORE = 'RECEIVE_TEST_SCORE';
export const DELETE_TEST_SCORE = 'DELETE_TEST_SCORE';

export const SCROLLED_TO_SCORE = 'SCROLLED_TO_SCORE';
export const RECEIVE_TEST_SCORES = 'RECEIVE_TEST_SCORES';

export function receiveTestScore(testScore) {
  return {
    type: RECEIVE_TEST_SCORE,
    id: `test-score-${testScore._id}`,
    testScore,
  };
}

export function testScoreFactory(options = {}) {
  return {
    _id: objectId(),
    type: null,
    month: null,
    year: null,
    subtype: {},
    scores: [],
    editing: true,
    new: true,
    scrollTo: !!options.scrollTo,
  };
}

export function newTestScore(options = {}) {
  const testScore = testScoreFactory(options);

  return {
    type: NEW_TEST_SCORE,
    id: `test-score-${testScore._id}`,
    testScore,
  };
}

export function scrolledToScore(id) {
  return {
    type: SCROLLED_TO_SCORE,
    id: `test-score-${id}`,
  };
}

export const saveTestScore =
  (testScore, shouldScroll = true) =>
  (dispatch, getState) => {
    const path = testScore.new ? '/test_scores' : `/test_scores/${testScore._id}`;
    const method = testScore.new ? 'POST' : 'PATCH';

    testScore.type = apiTestTypeToModelType(testScore.type);

    function successMessage() {
      dispatch(flashMessage('Test score saved!'));
    }

    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(path, _id),
      method: method,
      data: decamelizeKeys(testScore),
    })
      .then((receivedTestScore) => {
        dispatch(receiveTestScore(merge({}, receivedTestScore, {scrollTo: shouldScroll})));
      })
      .then(successMessage)
      .catch((json) => {
        dispatch(errorMessage());
        const errors = {};
        errors.subtype = json.subtype;

        errors.scores = map(allScores(json.scores), (score) => ({value: score.error}));

        if (json.test_date) {
          errors._error = json.test_date;
        }
        return Promise.reject(errors);
      });
  };

export function editTestScore(testScore) {
  return {
    type: EDIT_TEST_SCORE,
    id: `test-score-${testScore._id}`,
    testScore,
  };
}

export function cancelEditTestScore(testScore) {
  return {
    type: CANCEL_EDIT_TEST_SCORE,
    id: `test-score-${testScore._id}`,
    testScore,
  };
}

export function deleteTestScore(id) {
  return (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(`/test_scores/${id}`, _id),
      method: 'DELETE',
    }).then(() => {
      dispatch({
        type: DELETE_TEST_SCORE,
        id: `test-score-${id}`,
      });
    });
  };
}
