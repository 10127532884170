import {includes} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'src/components/Button';
import FollowButton from 'shared/components/follow-button';
import Loading from 'shared/components/loading';
import NewTabTooltip from 'shared/components/new-tab-tooltip';
import {julyFirst} from 'shared/utils';
import {track} from 'src/utils/analytics';
import {Link} from 'react-router-dom';

class CtaButton extends React.Component {
  static propTypes = {
    missingCredits: PropTypes.number.isRequired,
    collegeProfile: PropTypes.shape({
      earlySubmissionSubmitting: PropTypes.bool,
      college: PropTypes.shape({
        _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        isFollowing: PropTypes.bool.isRequired,
        earningRelationship: PropTypes.object.isRequired,
        admissionsWebsite: PropTypes.string.isRequired,
        applicationStatus: PropTypes.object,
        name: PropTypes.string,
        applicationWebsite: PropTypes.string,
      }).isRequired,
      userInfo: PropTypes.shape({
        senior: PropTypes.bool,
        highSchoolInfo: PropTypes.shape({
          senior: PropTypes.bool,
        }),
      }).isRequired,
    }),
    followCollege: PropTypes.func.isRequired,
    unfollowCollege: PropTypes.func.isRequired,
    openSubmitEarlyModal: PropTypes.func.isRequired,
  };

  onLearnMoreClick = () => track('college-listing hero-learn-more');
  onApplyNowClick = () => track('college-listing hero-apply-now');

  handleFollowClick = () => {
    const {
      followCollege,
      unfollowCollege,
      collegeProfile: {
        college: {_id, isFollowing},
      },
    } = this.props;

    if (isFollowing) {
      unfollowCollege(_id);
    } else {
      followCollege(_id);
    }
  };

  render() {
    const {
      missingCredits,
      openSubmitEarlyModal,
      collegeProfile: {
        earlySubmissionSubmitting,
        college: {
          name,
          isFollowing,
          applicationWebsite,
          admissionsWebsite,
          applicationStatus,
          earningRelationship: {statusType},
        },
        userInfo: {highSchoolInfo},
      },
    } = this.props;
    const followButton = (
      <FollowButton
        following={isFollowing}
        onClick={this.handleFollowClick}
        collegeName={name}
        track="college-listing hero-follow"
      />
    );

    const TODAY = new Date();
    const JULY_FIRST = julyFirst();
    const SEP_1 = new Date(TODAY.getFullYear(), 8, 1);
    const showSubmitEarlyButton = TODAY < JULY_FIRST || TODAY > SEP_1;
    const statusTypeEarning = includes(['earning', 'earning_deadline_soon'], statusType);
    const collegeApplicationStatus = applicationStatus ? applicationStatus.status : '';

    switch (statusType) {
      case 'us_citizenship_required':
      case 'complete_profile':
        return (
          <section className="college-profile-cta-button">
            <Link to="/account-settings" className="college-profile-cta-button-link-outline">
              Edit Profile
            </Link>
            {followButton}
          </section>
        );
      case 'boost_gpa':
        return (
          <section className="college-profile-cta-button">
            <a href="/portfolio#courses" className="college-profile-cta-button-link-outline">
              Add Classes
            </a>
            {followButton}
          </section>
        );
      case 'submitted':
        return (
          <section className="college-profile-cta-button">
            <NewTabTooltip>
              {includes(['applied', 'accepted', 'planning-to-enroll'], collegeApplicationStatus) ? (
                <a
                  href={admissionsWebsite}
                  className="college-profile-cta-button-link-outline"
                  onClick={this.onLearnMoreClick}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Learn More (opens in new tab)"
                >
                  Learn More
                </a>
              ) : (
                <a
                  href={applicationWebsite}
                  className="college-profile-cta-button-link-outline"
                  onClick={this.onApplyNowClick}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Apply to This College (opens in new tab)"
                >
                  Apply
                </a>
              )}
            </NewTabTooltip>
            {followButton}
          </section>
        );
      default: {
        if (
          highSchoolInfo &&
          highSchoolInfo.senior &&
          isFollowing &&
          showSubmitEarlyButton &&
          statusTypeEarning &&
          missingCredits === 0
        ) {
          return (
            <section className="college-profile-cta-button">
              <Button
                disabled={earlySubmissionSubmitting}
                onClick={openSubmitEarlyModal}
                outline
                className="college-profile-submit-early"
                testId="college-profile-submit-early-button"
              >
                {earlySubmissionSubmitting ? <Loading /> : 'Submit Early'}
              </Button>
              {followButton}
            </section>
          );
        } else {
          return <section className="college-profile-cta-button">{followButton}</section>;
        }
      }
    }
  }
}

export default CtaButton;
