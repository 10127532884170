import classnames from 'classnames';
import React from 'react';
import AvatarImage from 'shared/components/avatar-image';
import './avatar-list-styles.scss';

const OverflowCountCircle = (overflow) => (
  <span key={`overflow-${overflow}`} className="overflow shared-avatar small">
    <span
      className={classnames('shared-avatar-initials', `digits-${String(overflow).length}`)}
      data-testid="shared-avatar-initials"
    >
      +{overflow}
    </span>
  </span>
);

const UserAvatarImage = (user, index) => {
  const userInfo = {...user, image: user.photo};
  const key = `avatar-${user.firstName}-${user.lastName}-${user.photo}-${index}`;
  return <AvatarImage key={key} size="small" userInfo={userInfo} />;
};

const Avatars = ({users, max}) => {
  const overflow = Math.max(users.length - max, 0);
  let avatars = users.slice(0, max).map(UserAvatarImage);
  if (overflow) avatars = avatars.concat(OverflowCountCircle(overflow));
  return avatars;
};

export default Avatars;
