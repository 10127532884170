import PropTypes from 'prop-types';
import React from 'react';
import {map, filter} from 'lodash';
import Scholarship from '../scholarship';
import {COLLEGE_PROFILE_SCHOLARSHIP_DATA} from '../constants';
import Title from './title';
import CollapsibleSection from 'src/components/collapsible-section';
import {scholarshipDeadlinePassed} from 'shared/college-profile/utils';

export default class ScholarshipType extends React.Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    scholarships: PropTypes.array.isRequired,
    awardsReplaced: PropTypes.bool,
    submitted: PropTypes.bool.isRequired,
    educator: PropTypes.bool,
    openModal: PropTypes.func,
    pendingModal: PropTypes.bool,
    showScholarshipCta: PropTypes.bool,
    showScholarshipResources: PropTypes.bool,
    collegeName: PropTypes.string,
    collegeId: PropTypes.string,
    linkToScholarshipDetails: PropTypes.bool,
    collapseCardsOnMobile: PropTypes.bool,
    resources: PropTypes.object,
  };

  static defaultProps = {
    awardsReplaced: false,
    resources: {},
  };

  earlyBirdScholarships = (scholarship) => {
    if (scholarship.scholarshipType !== 'TimeSensitiveItemScholarship') return [];

    return filter(
      this.props.scholarships,
      ({_id, timeSensitiveItemTypeId, awards}) =>
        _id !== scholarship._id &&
        timeSensitiveItemTypeId === scholarship.timeSensitiveItemTypeId &&
        awards.length > 0
    );
  };

  shouldHideScholarship = (scholarship) => {
    if (this.props.educator) {
      return false;
    }

    // Don't show scholarships where the deadline has passed or
    // the student has already earned for the same type
    return (
      scholarshipDeadlinePassed(scholarship) || this.earlyBirdScholarships(scholarship).length > 0
    );
  };

  render() {
    const {
      userInfo,
      scholarships,
      awardsReplaced,
      submitted,
      educator,
      pendingModal,
      openModal,
      type,
      showScholarshipCta,
      showScholarshipResources,
      collegeName,
      collegeId,
      linkToScholarshipDetails,
      collapseCardsOnMobile,
      resources,
    } = this.props;

    return (
      <CollapsibleSection
        title={<Title type={type} data={COLLEGE_PROFILE_SCHOLARSHIP_DATA[type]} />}
        isOpen={(collapseCardsOnMobile ? window.screen.width > 479 : true) && !awardsReplaced}
        modalPending={pendingModal}
        trackIdentifier={`college-listing-scholarships-${COLLEGE_PROFILE_SCHOLARSHIP_DATA[type].title}`}
      >
        <div
          className="college-profile-card-content"
          data-testid="college-profile-scholarship-card"
        >
          {map(scholarships, (scholarship, i) => {
            if (this.shouldHideScholarship(scholarship)) {
              return null;
            }

            return (
              <React.Fragment key={i}>
                <Scholarship
                  key={`${scholarship.title}-${i}`}
                  scholarship={scholarship}
                  userInfo={userInfo}
                  actionable={!(awardsReplaced || submitted)}
                  educator={educator}
                  openModal={openModal}
                  pending={pendingModal}
                  showScholarshipCta={showScholarshipCta}
                  showScholarshipResources={showScholarshipResources}
                  linkToScholarshipDetails={linkToScholarshipDetails}
                  collegeName={collegeName}
                  collegeId={collegeId}
                  resources={resources[scholarship.scholarshipType]}
                />
                {educator && <div className="college-profile-divider"></div>}
              </React.Fragment>
            );
          })}
        </div>
      </CollapsibleSection>
    );
  }
}
