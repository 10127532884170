import {
  RECEIVE_COLLEGE_APP_STATUSES,
  RECEIVE_COLLEGE_APP_STATUS,
} from '../actions/college-application-status';

function collegeApplicationStatusesReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_COLLEGE_APP_STATUSES:
      return action.payload;
    case RECEIVE_COLLEGE_APP_STATUS:
      return {
        ...state,
        [action.payload.collegeId]: action.payload,
      };
    default:
      return state;
  }
}

export default collegeApplicationStatusesReducer;
