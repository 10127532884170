import React, {
  ComponentPropsWithRef,
  forwardRef,
  KeyboardEvent,
  useImperativeHandle,
  useRef,
} from 'react';
import classnames from 'classnames';
import {uniqueId} from 'lodash';

import {DASH, PERIOD} from 'shared/keys';
import Icon from 'shared/components/icon';
import placeholders from 'src/static/placeholders.json';

import 'stylesheets/components/shared-error-icon.scss';
import 'stylesheets/components/shared-text-input.scss';

export interface Props extends Omit<ComponentPropsWithRef<'input'>, 'size'> {
  // redux form
  invalid?: boolean;
  touched?: boolean;
  active?: boolean;
  forceError?: boolean;
  valid?: boolean;

  error?: boolean | string;
  size?: 'small' | 'medium' | 'large';
  selectValueOnFocus?: boolean;
  showCheck?: boolean;
  showErrorIcon?: boolean;
  cy?: string;
}

const TextInput = forwardRef<HTMLInputElement, Props>(function TextInput(
  {
    // redux-form
    invalid,
    touched,
    active,
    valid,
    forceError,

    children,
    error,
    size = 'medium',
    className,
    selectValueOnFocus,
    onKeyDown,
    showCheck,
    showErrorIcon = false,
    cy,
    type = 'text',
    step = 1,
    ...inputProps
  },
  forwardedRef
) {
  const ref = useRef<HTMLInputElement>(null);

  // if forwarded a ref, expose existing input ref
  useImperativeHandle(forwardedRef, () => ref.current!, []);

  // extra validations for Safari which doesn't respect type number
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(e);
    }

    if (type === 'number') {
      // disallow alpha chars unless command key is pressed
      if (e.which >= 65 && e.which <= 90 && !e.metaKey) {
        e.preventDefault();
      }

      // disallow symbols with shift + digit key pattern
      if (e.shiftKey && e.which > 47 && e.which < 58) {
        e.preventDefault();
      }

      // don't allow symbols except for negative numbers
      if (e.which >= 186 && e.which <= 222 && e.which !== PERIOD && e.which !== DASH) {
        e.preventDefault();
      }

      // don't allow dash for positive numbers
      if (
        inputProps.min &&
        typeof inputProps.min === 'number' &&
        inputProps.min > 0 &&
        e.which === DASH
      ) {
        e.preventDefault();
      }

      // allow periods based on step's integer status
      if (typeof step === 'number' && step % 1 === 0 && e.which === PERIOD) {
        e.preventDefault();
      }
    }
  };

  const selfId = inputProps.id || uniqueId('shared-text-input');

  const usingReduxForm = [invalid, touched, active].every((prop) => prop !== undefined);

  const showError = usingReduxForm
    ? (invalid && touched && !active && (inputProps.required || !!inputProps.value)) ||
      (forceError && error)
    : error;

  const check =
    inputProps.value !== '' &&
    (usingReduxForm ? showCheck && valid && !active : showCheck && !error);

  const errorIcon = inputProps.value !== '' && showErrorIcon && !check;

  const ariaProps = {};
  const errorId = `${selfId}-error`;

  if (showError) {
    // the below will handle the case where multiple textbox errors exist
    ariaProps['aria-describedby'] = `${
      inputProps['aria-describedby'] ? `${inputProps['aria-describedby']} ` : ''
    }${errorId}`;

    ariaProps['aria-invalid'] = true;
  }

  return (
    <div className="shared-text-input">
      <input
        data-testid="shared-text-input"
        id={selfId}
        data-cy={cy}
        className={classnames(
          'hidden-ms-input shared-text-input-input',
          size,
          className,
          {disabled: inputProps.disabled},
          {error: showError},
          {number: type === 'number'}
        )}
        ref={ref}
        onKeyDown={handleKeyDown}
        onFocus={(e) => {
          if (selectValueOnFocus) {
            ref.current?.select();
          } else {
            ref.current?.focus();
          }

          inputProps.onFocus?.(e);
        }}
        type={type}
        step={step}
        placeholder={inputProps.placeholder || placeholders[type]}
        {...ariaProps}
        {...inputProps}
      />

      {check && (
        <Icon className={classnames('shared-text-input-checkmark', size)} iconType="check" />
      )}
      {errorIcon && (
        <Icon className={classnames('shared-error-icon', size)} iconType="exclamation-circle-r" />
      )}

      {children}
      {showError && (
        <>
          <Icon className={classnames('shared-error-icon', size)} iconType="exclamation-circle-r" />
          <div
            id={errorId}
            className="shared-error-text"
            role="alert"
            data-testid="shared-text-input-error"
          >
            {error}
          </div>
        </>
      )}
    </div>
  );
});

export default TextInput;
