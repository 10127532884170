import React, {useEffect, useRef, ComponentPropsWithoutRef} from 'react';
import classnames from 'classnames';
import 'stylesheets/components/shared-textarea.scss';

export interface Props extends ComponentPropsWithoutRef<'textarea'> {
  // remove once all instances are off redux-form
  active?: boolean;
  invalid?: boolean;
  touched?: boolean;
  forceError?: boolean;

  height?: number;
  className?: string;
  outerDivClassName?: string;
  error?: string;
}

const TextArea = ({
  // redux-form
  active,
  invalid,
  touched,
  forceError,

  height = 80,
  className,
  outerDivClassName,
  error,
  ...textAreaProps
}: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    ref.current.style.height = 'auto';
    ref.current.style.height = `${ref.current.scrollHeight || height}px`;
  }, [textAreaProps.value]);

  const usingReduxForm = [invalid, touched, active].every((prop) => prop !== undefined);
  const showError = usingReduxForm ? (invalid && touched && !active) || forceError : error;

  return (
    <div className={classnames('shared-textarea', outerDivClassName, {error: showError})}>
      <textarea
        ref={ref}
        className={classnames('shared-textarea-input', className, {error: showError})}
        style={{height}}
        data-testid="shared-textarea"
        {...textAreaProps}
      ></textarea>
      <div className="shared-textarea-error-message">{error}</div>
    </div>
  );
};

export default TextArea;
