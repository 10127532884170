import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import FiltersMobile from 'src/components/CollegeSearch/FiltersMobile/FiltersMobile';
import {getColleges, resetPage} from 'src/redux/collegeSearch/actions';
import {clearFilters} from 'shared/actions/filters';
import {closeModal} from 'shared/actions/modal';

const mapStateToProps = (state) => ({
  collegeSearch: state.collegeSearch,
  show: state.modal['college-search-filters'] || false,
});

const mapDispatchToProps = (dispatch) => {
  const basicActions = bindActionCreators(
    {
      closeModal,
    },
    dispatch
  );

  const otherActions = {
    clearFilters() {
      dispatch(clearFilters());
      dispatch(resetPage());
      dispatch(getColleges());
    },
  };

  return {
    ...basicActions,
    ...otherActions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersMobile);
