import React from 'react';
import PropTypes from 'prop-types';
import AboutYourEarnings from './about-your-earnings';
import Card from 'src/components/Cards/Card';
import CollapsibleSection from 'src/components/collapsible-section';

const propTypes = {
  amount: PropTypes.number,
  name: PropTypes.string.isRequired,
  aboutEarningsText: PropTypes.string.isRequired,
};

const AboutYourEarningsCard = ({amount = 0, name, aboutEarningsText}) => (
  <Card>
    <CollapsibleSection
      title="About Your Earnings"
      trackIdentifier="college-listing-requirements-about-your-earnings"
    >
      <div className="college-profile-card-content">
        <AboutYourEarnings amount={amount} name={name} aboutEarningsText={aboutEarningsText} />
      </div>
    </CollapsibleSection>
  </Card>
);

AboutYourEarningsCard.propTypes = propTypes;

export default AboutYourEarningsCard;
