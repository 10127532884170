import PropTypes from 'prop-types';
import React from 'react';
import {map, size} from 'lodash';
import CollegeListItem from 'shared/components/college-list-item';
import Card from 'src/components/Cards/Card';

class SimilarCollegesCard extends React.Component {
  static propTypes = {
    collegeProfile: PropTypes.object.isRequired,
    followCollege: PropTypes.func.isRequired,
    unfollowCollege: PropTypes.func.isRequired,
  };

  render() {
    const {
      followCollege,
      unfollowCollege,
      collegeProfile: {similarColleges},
      collegeProfile,
    } = this.props;

    if (size(similarColleges) === 0) {
      return null;
    }

    return (
      <div className="college-profile-similar-colleges-card">
        <Card>
          <header>
            <h1 className="college-profile-similar-colleges-card-header">Similar Colleges</h1>
          </header>
          {map(similarColleges, (college, i) => (
            <CollegeListItem
              key={`suggested-college-${i}`}
              college={{
                ...college,
                following: college.isFollowing,
                logoUrl: college.logo,
                id: college._id,
                status: {status: college.earningRelationship.statusType},
                bucketShownEventId: college.bucketShownEventId,
              }}
              followCollege={followCollege}
              unfollowCollege={unfollowCollege}
              recsShownEventId={collegeProfile.eventIds.recsShownEventId}
            />
          ))}
        </Card>
      </div>
    );
  }
}

export default SimilarCollegesCard;
