import PropTypes from 'prop-types';
import React from 'react';

class SortIcon extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
  };

  static defaultProps = {
    className: '',
    size: 24,
  };

  render() {
    const {className, size} = this.props;

    return (
      <span className={className}>
        <svg
          fill="#366aff"
          height={size}
          viewBox="0 0 24 24"
          width={size}
          xmlns="http://www.w3.org/2000/svg"
          role="presentation"
        >
          <title>Edit Sorting</title>
          <path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </span>
    );
  }
}

export default SortIcon;
