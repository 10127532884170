import {isEmpty, omit} from 'lodash';
import qs from 'qs';
import {UserType} from 'src/types/enums';

export const collegePortalInitialState = () => {
  const initialState = {
    loading: true,
    collegeProfile: {
      tab: 1,
      userInfo: {
        user: UserType.COLLEGE_PORTAL_USER,
      },
    },
    filters: {currentStudents: 1},
  };
  const parameters = qs.parse(location.search.substr(1));
  const filters = omit(parameters, 'educationPhase');
  const educationPhase = parameters.educationPhase;

  if (!isEmpty(filters)) {
    initialState.filters = filters;
  }

  if (!isEmpty(educationPhase)) {
    initialState['studentResults'] = {studentSearchType: educationPhase};
  }

  return initialState;
};
