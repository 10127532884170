import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'shared/components/icon';

class MiniInfoCard extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // If provided, an Icon will be displayed with the given type.
    iconType: PropTypes.string,
  };

  render() {
    const {iconType, label, value} = this.props;

    if (!value || !label) {
      return null;
    }

    return (
      <div className="college-profile-mini-info-card">
        {iconType && <Icon iconType={iconType} />}
        <div className="mini-info-card-label">{label}</div>
        <div className="mini-info-card-value">{value}</div>
      </div>
    );
  }
}

export default MiniInfoCard;
