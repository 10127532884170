import React, {ComponentPropsWithoutRef, useRef} from 'react';
import classnames from 'classnames';
import {groupBy, map, size as lodashSize, uniqueId} from 'lodash';

import {FormOption} from 'src/types/form';
import {LoadState} from 'src/types/enums';
import {Loader, useOptionsLoader} from 'src/hooks/useOptionsLoader';
import Icon from 'shared/components/icon';

import 'stylesheets/components/shared-dropdown.scss';

interface Option {
  _id?: string;
  value: string | number;
  text: string;
  'aria-label'?: string;
  group?: string;
  disabled?: boolean;
}

export type DropdownOption = Option | FormOption;

export interface Props extends Omit<ComponentPropsWithoutRef<'select'>, 'size'> {
  options: DropdownOption[] | Loader;
  error?: boolean | string;
  size?: 'small' | 'medium' | 'large';
  // if true, the dropdown display will show the 'value' for the selected option, not the 'text'
  useValueForDisplay?: boolean;
  cy?: string;
  testid?: string;
}

const Dropdown = ({
  error,
  className,
  size = 'medium',
  options = [],
  useValueForDisplay,
  testid,
  cy,
  value,
  ...selectProps
}: Props) => {
  const dropdown = useRef<HTMLSelectElement>(null);
  const generatedId = uniqueId('dropdown');
  const [loadedOptions, loadState] = useOptionsLoader(options);
  const disabled =
    loadedOptions.length === 0 || loadState === LoadState.LOADING || selectProps.disabled;
  const stringValue = value !== null && value !== undefined ? `${value}` : '';

  const ariaProps = {};

  if (error) {
    ariaProps['aria-describedby'] = `${generatedId}-error`;
    ariaProps['aria-invalid'] = true;
  }

  const renderOptions = () => {
    const groups = groupBy(loadedOptions, 'group');

    const optionify = (opts) =>
      map(opts, (option) => {
        const text = useValueForDisplay && option.value === value ? stringValue : option.text;

        return (
          <option
            key={`option-${option.value}`}
            value={option.value}
            disabled={option.disabled}
            aria-label={option['aria-label']}
          >
            {text}
          </option>
        );
      });

    if (lodashSize(groups) === 1) {
      return optionify(loadedOptions);
    }

    return map(groups, (opts, group) => (
      <optgroup key={group} label={group}>
        {optionify(opts)}
      </optgroup>
    ));
  };

  return (
    <div className={classnames('shared-dropdown', className)}>
      <select
        data-cy={cy}
        data-testid={testid}
        id={generatedId}
        className={classnames('shared-dropdown-select', size, {
          placeholder: !stringValue,
          error: error,
          disabled: disabled,
        })}
        ref={dropdown}
        disabled={disabled}
        value={stringValue}
        {...ariaProps}
        {...selectProps}
      >
        <option disabled hidden value="">
          {selectProps.placeholder || 'Select'}
        </option>
        {renderOptions()}
      </select>
      <Icon
        iconType="chevron-down"
        className={classnames('shared-dropdown-arrow', size, {disabled})}
      />
      {error && (
        <p
          data-cy={cy && `${cy}-error`}
          data-test-id={testid && `${testid}-error`}
          id={`${generatedId}-error`}
          className="shared-error-text"
          role="alert"
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default Dropdown;
