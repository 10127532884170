import {RoutedModalConfig} from 'src/components/ModalContext/types';
import {Modals} from 'src/types/enums';
import {lazy} from 'react';
import {ScholarshipProgramHighSchoolsProps} from './scholarship-program-high-schools';

export const scholarshipProgramHighSchoolsConfig: RoutedModalConfig<ScholarshipProgramHighSchoolsProps> =
  {
    type: Modals.SCHOLARSHIP_PROGRAM_HIGH_SCHOOL,
    routed: true,
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: "ScholarshipProgramHighSchools" */ './scholarship-program-high-schools'
        )
    ),
    validateProps: (props) => Boolean(props.criterionId) && Boolean(props.programId),
  };
