import {includes} from 'lodash';

// takes in a string and returns the string with any urls in it wrapped in an <a> tag with an href going to that
// url.  Adds the // if needed, ignores any links in "quotes"
const linkify = (string, options = {}) => {
  // to see how this regex works, see: https://regex101.com/r/hU0tJ2/5
  const re =
    /(?:^|\ |\n)(((?:(?:http(?:s)?\:)?(?:\/\/))|(?:\/\/))?(?:(?=[a-z0-9\-\.]{1,255}(?=\/|\ |$|\:|\n|\?|\,|\!))(?:(?:(?:[a-z0-9]{1}(?:[a-z0-9\-]{1,62})?\.){1,127})[a-z]{2,}(?:\.[a-z]{2})?))(?:[a-z0-9\/\-\_\%\?\&\!\$\'\,\.\(\)\*\+\=\;])*?)(?=$|\.(?=\n|\ |$)|\:|\n|\ |\?(?=\ |$)|\,|\!)/gi;

  return string.replace(re, (match, url, protocol) => {
    let startOfString = '';

    const firstCharacter = match.split('')[0];

    if (includes([' ', '\n'], firstCharacter)) {
      startOfString = firstCharacter;
    }

    const attributes = [`href="${protocol ? '' : '//'}${url}"`];

    if (options.newTab) {
      attributes.push('target="_blank"');
      attributes.push(`aria-label="${url} (opens in new tab)"`);
    }

    if (options.class) {
      attributes.push(`class="${options.class}"`);
    }

    return `${startOfString}<a ${attributes.join(' ')}>${url}</a>`;
  });
};

export default linkify;
