import PropTypes from 'prop-types';
import React from 'react';
import {map, size} from 'lodash';
import Card from 'src/components/Cards/Card';
import CollegeListItem from 'shared/components/college-list-item';

class CampusesCard extends React.Component {
  static propTypes = {
    otherCampuses: PropTypes.array,
    campusesGroupName: PropTypes.string,
    followCollege: PropTypes.func.isRequired,
    unfollowCollege: PropTypes.func.isRequired,
  };

  render() {
    const {otherCampuses, campusesGroupName, followCollege, unfollowCollege} = this.props;

    if (size(otherCampuses) === 0) {
      return null;
    }

    return (
      <Card>
        <div className="college-profile-right-rail-similar-colleges">
          <header className="college-profile-right-rail-secondary-header">
            {campusesGroupName || 'Other Campuses'}
          </header>
          <ul className="college-profile-right-rail-similar-colleges-list">
            {map(otherCampuses, (col, i) => (
              <CollegeListItem
                key={`other-campus-college-${i}`}
                college={{
                  ...col,
                  following: col.isFollowing,
                  logoUrl: col.logo,
                  id: col._id,
                  status: {status: col.earningRelationship.statusType},
                }}
                followCollege={followCollege}
                unfollowCollege={unfollowCollege}
                useNickname
              />
            ))}
          </ul>
        </div>
      </Card>
    );
  }
}

export default CampusesCard;
