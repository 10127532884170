import {connect} from 'react-redux';
import SimilarCollegesCard from '../components/similar-colleges-card';
import {toggleFollowCollege} from '../actions/college-profile';

const mapStateToProps = (state) => ({collegeProfile: state.collegeProfile});

const mapDispatchToProps = (dispatch) => ({
  followCollege(id, bucketShownEventId) {
    dispatch(toggleFollowCollege(id, 'follow', 'similar', bucketShownEventId));
  },
  unfollowCollege(id, bucketShownEventId) {
    dispatch(toggleFollowCollege(id, 'unfollow', 'similar', bucketShownEventId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SimilarCollegesCard);
