import React, {useEffect, useRef} from 'react';
import {times} from 'lodash';
import classnames from 'classnames';

import FooterButton, {FooterButtonType} from './footer-button';
import {useModalContext} from '.';

import './navigation-footer.scss';
import {useResizeObserver} from '../../../src/hooks/useResizeObserver';

interface Props {
  // Primary call to action button, solid color style, goes on the right
  primaryButton: FooterButtonType;
  // Secondary action button, outline style, goes on the left
  secondaryButton?: FooterButtonType;
  currentStep: number;
  stepCount: number;
}

const modalSteps = (stepCount, currentStep) => {
  const steps = times(stepCount, (step) => (
    <div
      className={classnames('shared-modal-navgiation-step', {active: step === currentStep})}
      key={step}
    ></div>
  ));

  return <div className="shared-modal-navgiation-steps">{steps}</div>;
};

const NavigationFooter = ({primaryButton, secondaryButton, currentStep, stepCount}: Props) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const {height: footerHeight} = useResizeObserver(footerRef);
  const {setFooterHeight, bodyOverflow} = useModalContext();

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerHeight);
    }
  }, [setFooterHeight, footerHeight]);

  return (
    <div
      id="shared-modal-footer"
      ref={footerRef}
      className={classnames('shared-modal-navigation-footer', {
        overflow: bodyOverflow,
      })}
    >
      {secondaryButton ? (
        <FooterButton buttonType="secondary" {...secondaryButton} />
      ) : (
        <div className="shared-modal-navgiation-footer-spacer"></div>
      )}
      {modalSteps(stepCount, currentStep)}
      {primaryButton && <FooterButton buttonType="primary" {...primaryButton} />}
    </div>
  );
};

export default NavigationFooter;
