import {every, difference, uniq} from 'lodash';
import {fetchSearchResults, fetchStudentsCount} from 'educator/actions/students';
import {REMOVE_STUDENT} from 'educator/action-types/students';
import req from 'shared/req';
import {flashMessage, errorMessage} from 'shared/actions/notifications';
import {parseQueryString} from 'shared/utils';

// action-types
export const RECEIVE_CASELOAD = 'RECEIVE_CASELOAD';

export const addStudentsToCaseload = (values) => (dispatch) => () =>
  req({
    url: '/v1/caseload-connections',
    method: 'post',
    data: {studentIds: values},
  })
    .then(() => {
      const {page} = parseQueryString(window.location.search.substring(1));

      dispatch(
        flashMessage(
          `${values.length} ${values.length > 1 ? 'students' : 'student'} added to caseload.`
        )
      );
      dispatch(fetchSearchResults({page}));
      dispatch(fetchStudentsCount());
      dispatch({type: RECEIVE_CASELOAD, studentIds: []});
    })
    .catch(() => {
      dispatch(errorMessage());
    });

export const handleSelectCaseload = (id) => (dispatch, getState) => () => {
  const {caseloadStudentIds} = getState();
  const indexToRemove = caseloadStudentIds.indexOf(id);
  let studentIds = [];

  // User already selected this student, need to deselect all
  if (indexToRemove < 0) {
    studentIds = uniq(caseloadStudentIds.concat(id));
  } else {
    studentIds = caseloadStudentIds
      .slice(0, indexToRemove)
      .concat(caseloadStudentIds.slice(indexToRemove + 1));
  }

  dispatch({type: RECEIVE_CASELOAD, studentIds});
};

export const handleSelectAllCaseloads = (ids) => (dispatch, getState) => () => {
  const {caseloadStudentIds} = getState();
  let studentIds = [];

  // User already selected all students on this page, need to deselect all
  if (every(ids, (id) => caseloadStudentIds.indexOf(id) > -1)) {
    studentIds = difference(caseloadStudentIds, ids);
  } else {
    studentIds = uniq(caseloadStudentIds.concat(ids));
  }

  dispatch({type: RECEIVE_CASELOAD, studentIds});
};

export const removeStudentFromCaseload = (studentId) => (dispatch) => () =>
  req({
    url: `/v1/caseload-connections/${studentId}`,
    method: 'delete',
  })
    .then(() => {
      dispatch(flashMessage('Student removed from your caseload.'));
      dispatch({type: REMOVE_STUDENT, student: {_id: studentId}});
      dispatch(fetchStudentsCount());
    })
    .catch(() => {
      dispatch(errorMessage());
    });
