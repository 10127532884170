import {AnyModalConfig, ModalProps, ModalType} from 'src/components/ModalContext/types';
import {Location as HistoryLocation} from 'history';
import qs from 'qs';
import browserHistory from 'shared/history';
import {localStorageItem} from 'src/utils/storage';
import {isBoolean} from 'lodash';

export type QueryParams = ModalProps<{
  view?: string;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseQueryParams = (location: HistoryLocation): [any, string] => [
  qs.parse(location.search.substr(1)),
  location.search.substr(1),
];

export function getQueryParams(location: HistoryLocation): QueryParams {
  let [params] = parseQueryParams(location);
  if (params.data) {
    try {
      const decodedData = atob(params.data);
      const unserializedData = JSON.parse(decodedData);
      delete params.data;
      params = {...params, ...unserializedData};
    } catch (_) {
      // validation will handle missing params
    }
  }

  return params;
}

export function getMatchingConfig(
  queryObj: QueryParams,
  configs: AnyModalConfig[]
): AnyModalConfig | undefined {
  if (!queryObj.view) return;
  return configs.find((config) => config.type === queryObj.view);
}

export function validateProps(config: AnyModalConfig, props: ModalProps): boolean {
  const valid = config.validateProps ? config.validateProps(props) : true;

  if (!valid) {
    console.warn(`[${config.type}.validateProps] failed`, props);
  }

  return valid;
}

export const previousSearchParams = localStorageItem('prev-search');

/**
 * Update query parameters to display routed modals.
 * Saves the current query string in session storage which is restored by ModalProvider
 * In the case of query parameter conflicts all props will be added to a query param called data.
 */
export function launchRoutedModal(
  view: ModalType,
  props: ModalProps,
  shouldEncode?: boolean
): void {
  const [currentSearch, currentSearchString] = parseQueryParams(browserHistory.location);
  shouldEncode = isBoolean(shouldEncode)
    ? shouldEncode
    : Object.keys(props || {}).some((key) => currentSearch.hasOwnProperty(key));

  if (shouldEncode) {
    props = {data: btoa(JSON.stringify(props))};
  }

  const searchString = qs.stringify({...currentSearch, view, ...props});
  previousSearchParams.set(currentSearchString);
  browserHistory.push({
    pathname: location.pathname,
    search: `?${searchString}`,
  });
}
