import CRUDService from 'src/services/CRUDService';
import PropTypes from 'prop-types';

export interface AcademicInterest {
  _id: string;
  name: string;
  cip: string;
}

export const AcademicInterestShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cip: PropTypes.string.isRequired,
});

export const StudentAcademicInterestShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
});

export interface StudentAcademicInterest {
  id: string;
  displayName: string;
}

const AcademicInterestService = new CRUDService<AcademicInterest>('/v1/academic-interests');
export default AcademicInterestService;
