import React from 'react';
import CourseIcon from 'shared/portfolio/components/courses/courses-icon';
import ActivitiesIcon from 'shared/portfolio/components/activities/activities-icon';
import CommunityServiceIcon from 'shared/portfolio/components/community-service/community-service-icon';
import WorkExperienceIcon from 'shared/portfolio/components/work_experiences/work-experience-icon';
import TestScoresIcon from 'shared/portfolio/components/test-scores/test-scores-icon';
import HonorAwardsIcon from 'shared/portfolio/components/honor-awards/honor-awards-icon';
import EventsIcon from 'shared/portfolio/components/college-events/events-icon';
import MultimediaIcon from 'shared/portfolio/components/multimedia/multimedia-icon';
import ReferralIcon from 'shared/img/referral.svg';
import FafsaIcon from 'shared/img/checkmark.svg';
import DegreeProgressIcon from 'shared/img/graduation-cap.svg';
import CollegeSuccessIcon from 'shared/img/portfolio/college-success.svg';

const ICON_SIZE = 24;

export const COLLEGE_PROFILE_SCHOLARSHIP_DATA = {
  academic: {
    title: 'Courses',
    icon: <CourseIcon size={ICON_SIZE} />,
  },
  degreeProgress: {
    title: 'Degree Progress',
    icon: <DegreeProgressIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  volunteer: {
    title: 'Community Service',
    icon: <CommunityServiceIcon size={ICON_SIZE} />,
  },
  activity: {
    title: 'Extracurriculars',
    icon: <ActivitiesIcon size={ICON_SIZE} />,
  },
  sport: {
    title: 'Sports',
    icon: <ActivitiesIcon size={ICON_SIZE} />,
  },
  honor: {
    title: 'Honors and Awards',
    icon: <HonorAwardsIcon size={ICON_SIZE} />,
  },
  scores: {
    title: 'Test Scores',
    icon: <TestScoresIcon size={ICON_SIZE} />,
  },
  visits: {
    title: 'College Readiness',
    icon: <EventsIcon size={ICON_SIZE} />,
  },
  referral: {
    title: 'Referral Scholarships',
    icon: <ReferralIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  work: {
    title: 'Work Experience',
    icon: <WorkExperienceIcon size={ICON_SIZE} />,
  },
  fafsa: {
    title: 'FAFSA',
    icon: <FafsaIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  multimedia: {
    title: 'Multimedia',
    icon: <MultimediaIcon size={ICON_SIZE} />,
  },
  collegeSuccess: {
    title: 'College Success',
    icon: <CollegeSuccessIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
};

export const SCHOLARSHIP_TYPE_MAP = {
  ApScoreScholarship: 'scores',
  AssociateDegreeProgressScholarship: 'degreeProgress',
  CampusVisitScholarship: 'visits',
  CertificationScholarship: 'academic',
  ChallengingClassScholarship: 'academic',
  ClaimedAccountScholarship: 'collegeSuccess',
  College101EventScholarship: 'visits',
  CollegeFairScholarship: 'visits',
  CollegeReceptionScholarship: 'visits',
  CollegeReplacementScholarship: 'hidden',
  CommunityServiceScholarship: 'volunteer',
  CourseGradeScholarship: 'academic',
  CourseSubjectScholarship: 'academic',
  CustomCollegeEventScholarship: 'visits',
  DiscoverGcuEventScholarship: 'visits',
  DiscoveryDaysEventScholarship: 'visits',
  ExtracurricularActivityScholarship: 'activity',
  FafsaScholarship: 'fafsa',
  ForeignLanguageScholarship: 'academic',
  FullCourseLoadScholarship: 'academic',
  GTechArchitectureEventScholarship: 'visits',
  GenericScholarship: 'hidden',
  HonorAwardScholarship: 'honor',
  LeadershipScholarship: 'activity',
  MultimediaScholarship: 'multimedia',
  OtherTestScholarship: 'scores',
  OverallGpaScholarship: 'academic',
  PerfectAttendanceScholarship: 'academic',
  PsatPreActScholarship: 'scores',
  PsatPreActScoreScholarshipBase: 'scores',
  ReferralScholarship: 'referral',
  RegentScholarship: 'scores',
  SatActScholarship: 'scores',
  SatActScoreScholarshipBase: 'scores',
  SportsActivityScholarship: 'sport',
  SummerProgramScholarship: 'visits',
  SurveyScholarship: 'collegeSuccess',
  Teacher101EventScholarship: 'visits',
  TimeSensitiveItemScholarship: 'collegeSuccess',
  WorkExperienceScholarship: 'work',
  PsatPreActScoreScholarship: 'scores',
  PsatPreActScoreScholarship2: 'scores',
  SatActScoreScholarship: 'scores',
};

// Statuses where the scholarships and requirements
// tabs are hidden.
export const HIDE_STATUSES = [
  'scholarships_not_yet_available',
  'limited_scholarships',
  'deadline_passed',
  'us_citizenship_required',
  'complete_profile',
  'us_citizens_only',
];
