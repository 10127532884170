import React, {useEffect, useState} from 'react';
import TextInput from '../../../inputs/text-input';

interface CollegeNameFilterProps {
  adjustCollegeNameFilter: (collegeName: string) => void;
  getColleges: () => void;
  filters: any[];
}

let timeoutId: NodeJS.Timer;

const CollegeNameFilter = ({
  adjustCollegeNameFilter,
  getColleges,
  filters,
}: CollegeNameFilterProps) => {
  const [collegeName, setCollegeName] = useState<string>('');

  useEffect(() => {
    if (filters && (filters['college-name'] === '' || !filters.hasOwnProperty('college-name'))) {
      setCollegeName('');
    }
  }, [filters]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setCollegeName(name);

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      adjustCollegeNameFilter(name);
      getColleges();
    }, 1000);
  };

  return (
    <div
      className="college-search-filters-section college-name-filter"
      data-testid="college-name-filter"
    >
      <legend className="college-search-filters-section-legend">
        <fieldset role="group" aria-label="Filter by College Name">
          <label className="college-search-filters-section-label">College Name</label>
          <div className="college-search-filters-section-typeahead">
            <TextInput
              value={collegeName}
              placeholder="Search for Colleges"
              type="text"
              onChange={handleInputChange}
              name="college name"
              aria-label="College Name"
            />
          </div>
        </fieldset>
      </legend>
    </div>
  );
};

export default CollegeNameFilter;
