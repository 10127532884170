import {earning} from 'shared/utils';
import {lowerCase} from 'lodash';

// Handles ellipsis truncation and partners who want a specific shortened name.
export function formatCollegeNameForCard(
  name: $TSFixMe,
  showDeadline: $TSFixMe,
  earningRelationship: $TSFixMe
): string {
  const maxLength =
    showDeadline && earningRelationship && earning(earningRelationship.statusType) ? 35 : 140;
  const truncate = name.length > maxLength;

  if (
    name ===
    'University of Minnesota Twin Cities - College of Food, Agricultural and Natural Resource Sciences'
  ) {
    return 'U of M - College of Food...Sciences';
  } else if (name === 'Arizona State University - Mary Lou Fulton Teachers College' && truncate) {
    return 'ASU Teachers College';
  } else if (truncate) {
    return `${name.slice(0, maxLength).trim()}...`;
  }

  return name;
}

export function showTermNotOfferedModal(
  college: $TSFixMe,
  communityCollegeInfo?: {transferSemester?: string}
): boolean {
  if (college.isFollowing || college.following) {
    return false;
  }

  if (
    !college.studentSubmissionTerm ||
    !communityCollegeInfo ||
    !communityCollegeInfo.transferSemester
  ) {
    return false;
  }

  return college.studentSubmissionTerm !== lowerCase(communityCollegeInfo.transferSemester);
}
