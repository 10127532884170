import React from 'react';
import Text from 'shared/components/text';
import {priceFormat, rankFormat, rateFormat} from 'shared/utils';
import {useSelector} from 'react-redux';
import {Store} from '../../../src/types/store';
import {camelCase} from 'lodash';
import InfoTooltip from '../info-tooltip';

const SORT_LABEL = {
  averageNetPrice: ({value}) => priceFormat(value, 'Average Net Price'),
  tuition: ({authenticated, value}) => {
    if (authenticated) {
      return priceFormat(value, 'Tuition');
    }

    return (
      <>
        Tuition: {priceFormat(value, '')}
        <InfoTooltip iconSize={14}>
          Out of State Tuition. To view in-state tuition log in or sign up.
        </InfoTooltip>
      </>
    );
  },
  acceptanceRate: ({value}) => rateFormat(value, 'Acceptance Rate'),
  sixYearGradRate: ({value}) => rateFormat(value, 'Graduation Rate'),
  forbesRanking: ({value}) => rankFormat(value, 'Forbes'),
  usNewsRanking: ({category, value}) => rankFormat(value, 'US News', category),
};

const SORT_TO_PROP = {
  'average-net-price': 'avgNetPrice',
  tuition: 'tuition',
  'acceptance-rate': 'acceptanceRate',
  'six-year-grad-rate': 'graduationRate',
  'forbes-ranking': 'forbesRank',
  'us-news-ranking': 'usNewsRank',
};

interface College {
  avgNetPrice: string | number;
  tuition: string | number;
  acceptanceRate: string | number;
  graduationRate: string | number;
  forbesRank: string | number;
  usNewsRank: string | number;
  category: string;
}

export interface SortingInfoProps {
  college: College;
  sortBy?: string;
}

const SortingInfo = (props: SortingInfoProps) => {
  const {
    sortBy,
    college,
    college: {category},
  } = props;

  const authenticated = useSelector((state: Store) => state.authenticated);

  const field = SORT_TO_PROP[sortBy || ''];
  if (!sortBy || !field) {
    return null;
  }

  const params = {
    value: college[field],
    category,
    authenticated,
  };

  return (
    <div className="shared-college-card-sorting-info" data-testid="sorting-info">
      <Text color="blue" weight={6} inline>
        {SORT_LABEL[camelCase(sortBy)](params)}
      </Text>
    </div>
  );
};

export default SortingInfo;
