export default {
  white: '#ffffff',
  n50: '#f7f8f9',
  n100: '#f1f2f3',
  n200: '#e6e9ec',
  n300: '#d6dbe0',
  n400: '#aeb8c2',
  n500: '#8b97a2',
  n600: '#6a7886',
  n700: '#505a65',
  n800: '#47525d',
  n900: '#313a42',
  n900_85: 'rgba(49, 58, 66, 0.85)',
  black: '#000000',

  b50: '#ecf5ff',
  b100: '#c0e0fd',
  b200: '#89bff8',
  b300: '#6ea6fd',
  b400: '#5586f8',
  b500: '#366aff',
  b600: '#285ff6',
  b700: '#2256e5',
  b800: '#224bbe',
  b900: '#1d3a8a',

  c50: '#ebfeff',
  c100: '#9bfcff',
  c200: '#6afaff',
  c300: '#6af0ff',
  c400: '#3debff',
  c500: '#14cce6',
  c600: '#10bfd8',
  c700: '#02a6bd',
  c800: '#008396',
  c900: '#056980',

  v50: '#f7f2ff',
  v100: '#ccaeff',
  v200: '#a97ff3',
  v300: '#8b5eda',
  v400: '#784aca',
  v500: '#673ab7',
  v600: '#5d2fb0',
  v700: '#4e229b',
  v800: '#401689',
  v900: '#2f0a70',

  p50: '#ffeaea',
  p100: '#ffcfd9',
  p200: '#ffa9ba',
  p300: '#ff8aa1',
  p400: '#fd7791',
  p500: '#ff6986',
  p600: '#e1516d',
  p700: '#cd4661',
  p800: '#af364e',
  p900: '#9a263d',

  r50: '#fff3f3',
  r100: '#ffdfdf',
  r200: '#f29999',
  r300: '#e97474',
  r400: '#e25656',
  r500: '#d83a3a',
  r600: '#cb3737',
  r700: '#bf2525',
  r800: '#b01515',
  r900: '#a20505',

  m50: '#fff9e5',
  m100: '#ffefbb',
  m200: '#ffe288',
  m300: '#ffd75d',
  m400: '#ffd040',
  m500: '#ffc000',
  m600: '#ff9800',
  m700: '#ef8006',
  m800: '#c94e00',
  m900: '#b54203',

  g50: '#deffeb',
  g100: '#9fffd5',
  g200: '#62ecac',
  g300: '#22d683',
  g400: '#1cc979',
  g500: '#3eb670',
  g600: '#2fa861',
  g700: '#1f874a',
  g800: '#147c3f',
  g900: '#0d6732',
};
