import $ from 'jquery';
import {once} from 'lodash';
import {ready} from 'shared/utils';
import req from '../req';

ready(() => {
  window.VERSION = {};

  const showUpdateBanner = once(function () {
    const $banner = $(`
    <div style="background-color: #ffd600; width: 100%; text-align: center; position: fixed; top: 0; z-index: 10000;">
  <a href="javascript:window.location.href=window.location.href" style="padding: 12px 24px; display: block; width: 100%; text-decoration: none">
    <p style="margin: 0; color: #47525d; font-family: 'Open Sans', sans-serif; font-weight: 300; font-size: 14px;">
      There's a new version of RaiseMe. Please <span style="font-weight: 400;">refresh</span> the page to update.
    </p>
  </a>
</div>
    `);

    $('body').prepend($banner);

    // Adjust ALL the tops to fit the banner
    const offset = $banner.height();
    const $header = $('.header-wrapper, #top-nav');
    const $secondaryNavbar = $('.navbar-secondary.affix');
    const $schoolProfile = $('.school-profile');
    const $educatorDashboard = $('.educator_portal.dashboard #main');
    const $page = $(
      [
        '#account-settings',
        '#dashboard-container',
        '#content-container',
        '#connections-container',
        '.colleges-container',
      ].join(', ')
    );

    if ($header.length) {
      $header.css('top', parseInt($header.css('top'), 10) + offset);
    }

    if ($secondaryNavbar.length) {
      $secondaryNavbar.css('top', parseInt($secondaryNavbar.css('top'), 10) + offset);
    }

    if ($schoolProfile.length) {
      $schoolProfile.css('top', $header.height() + offset);
    }

    if ($page.length) {
      $page.css('top', offset);
    }

    if ($educatorDashboard.length) {
      $educatorDashboard.css({position: 'relative', top: offset});
    }
  });

  const setVersion = function (version) {
    version.created_at = new Date(version.created_at);
    version.updated_at = new Date(version.updated_at);

    if (!window.VERSION._id) {
      window.VERSION = version;
    } else if (version.created_at > window.VERSION.created_at) {
      window.VERSION = version;
      showUpdateBanner();
    }
  };

  window.Raise.vent.on('breaking:change', function (version) {
    setVersion(version);
  });

  req({url: '/breaking_change'}).then((version) => {
    if (typeof version !== 'string' && version) {
      setVersion(version);
    }
  });
});
