import {omit} from 'lodash';
import req from 'shared/req';
// have to rename because there is a function inside this file by the same name
import {
  getCollegePortalUser,
  updateCollegePortalUser as saveCollegePortalUser,
} from 'shared/actions/college-portal-user';
import {changesSaved, errorMessage} from 'shared/actions/notifications';
import {verifyPhoneNumber} from 'shared/actions/verify-mobile';
import {receiveNotificationSettings} from 'shared/account-settings/actions/notification-settings';

export const RECEIVE_COLLEGE_PORTAL_USER = 'RECEIVE_COLLEGE_PORTAL_USER';

export const receiveCollegePortalUser = (collegePortalUser) => {
  collegePortalUser.allAccess = collegePortalUser.impostor && !collegePortalUser.universityId;

  return {
    type: RECEIVE_COLLEGE_PORTAL_USER,
    collegePortalUser,
  };
};

export const COLLEGE_PORTAL_USER_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'mobile',
  'title',
  'type',
  'schoolName',
  'department',
  'role',
  'impostor',
  'universityId',
  'listingName',
  'listingLogo',
  'listingId',
  'featureFlags',
  'canMessage',
  'internationalSearch',
  'scheduledReports',
  'educationPhasesDownloadable',
  'primaryMarketHighSchoolIds',
  'listingSlug',
  'lastUsedPreferences',
  'notifications',
];

export const fetchCollegePortalUser = () => (dispatch) =>
  getCollegePortalUser('me', `${COLLEGE_PORTAL_USER_FIELDS.join(',')},notificationSettings`)
    .then((collegePortalUser) => {
      dispatch(receiveCollegePortalUser(omit(collegePortalUser, ['notificationSettings'])));
      dispatch(receiveNotificationSettings(collegePortalUser.notificationSettings));
    })
    .catch(() => {
      dispatch(errorMessage());
    });

export const fetchLastUsedPreferences = () => (dispatch) =>
  getCollegePortalUser('me', 'lastUsedPreferences')
    .then((collegePortalUser) => {
      dispatch(receiveCollegePortalUser(collegePortalUser));
    })
    .catch(() => {
      dispatch(errorMessage());
    });

export const updateCollegePortalUser = (data) => (dispatch) =>
  req({
    url: '/v1/college-portal-users/me',
    method: 'patch',
    data,
  }).then((collegePortalUser) => dispatch(receiveCollegePortalUser(collegePortalUser)));

export const updateCollegePortalUserInfo = (collegePortalUser) => (dispatch) => {
  if (
    collegePortalUser.mobilePartOne &&
    collegePortalUser.mobilePartTwo &&
    collegePortalUser.mobilePartThree
  ) {
    collegePortalUser.mobile = `${collegePortalUser.mobilePartOne}${collegePortalUser.mobilePartTwo}${collegePortalUser.mobilePartThree}`;
  }

  if (
    collegePortalUser.newEmail &&
    collegePortalUser.newEmailRepeat &&
    collegePortalUser.newEmail === collegePortalUser.newEmailRepeat
  ) {
    collegePortalUser.email = collegePortalUser.newEmail;
  }

  if (collegePortalUser.role && collegePortalUser.role.customOption) {
    collegePortalUser.role = collegePortalUser.role.text;
  }

  return new Promise((resolve, reject) =>
    saveCollegePortalUser(collegePortalUser, COLLEGE_PORTAL_USER_FIELDS.join(','))
      .then((updatedCollegePortalUser) => {
        dispatch(receiveCollegePortalUser(updatedCollegePortalUser));
        if (updatedCollegePortalUser.mobile) {
          dispatch(verifyPhoneNumber(updatedCollegePortalUser.mobile, 'sms'));
        } else {
          dispatch(changesSaved());
        }
        resolve();
      })
      .catch((errs) => {
        const errors = {};

        errs.forEach((err) => {
          switch (err.field) {
            case 'mobile':
              errors.mobilePartOne = `Mobile ${err.message}`;
              break;
            case 'email':
              errors.newEmail = err.message;
              break;
            default:
              errors[err.field] = err.message;
          }
        });

        reject(errors);
      })
  );
};
