import {ObjectOf} from 'src/types/store';
import {AppDeclaration} from 'src/apps/types';
import {EducationPhase, UserIdentifier} from 'src/types/enums';
import {lazy} from 'react';
import {hsReducers} from 'src/views/HighSchool/reducers';
import hsModals from 'src/views/HighSchool/modals';
import transferModals from 'src/views/Transfer/modals';
import {transferReducers} from 'src/views/Transfer/reducers';
import {adminReducers} from 'src/views/Admin/reducers';
import {undergraduateReducers} from 'undergraduate-student/reducers';
import onboardingReducers from 'shared/student/registration/reducers';
import {parentReducers} from 'src/views/Parent/reducers';
import {reducers as collegePortalReducers} from 'src/redux/college-portal/reducers';
import {collegePortalInitialState} from 'src/redux/studentSearch/initialState';
import {cpModals} from 'src/views/college-portal/modals';
import {educatorsApp, educatorsOnboardingApp} from './Educator';
import {districtAdminReducers} from 'district-admin/reducers';
import FeatureFlagService from '../services/FeatureFlagService';

export const apps: ObjectOf<AppDeclaration> = {
  [UserIdentifier.HS_STUDENT]: {
    app: lazy(() => import('src/views/HighSchool')),
    mobileApp: lazy(() => import('src/views/HighSchool/high-school-mobile-application')),
    reducers: hsReducers,
    modalConfigs: hsModals,
    initialState: {
      collegeProfile: {
        userInfo: {
          user: EducationPhase.HIGH_SCHOOL,
        },
        tab: 0,
        loading: true,
        modalPending: {},
      },
    },
  },
  [UserIdentifier.CC_STUDENT]: {
    app: lazy(() => import('src/views/Transfer')),
    mobileApp: lazy(() => import('src/views/Transfer/transfer-mobile-application')),
    reducers: transferReducers,
    modalConfigs: transferModals,
    initialState: {
      collegeProfile: {
        userInfo: {
          user: EducationPhase.COMMUNITY_COLLEGE,
        },
        tab: 0,
        loading: true,
        modalPending: {},
      },
      transfer: true,
    },
  },
  [UserIdentifier.UG_STUDENT]: {
    app: lazy(() => import('src/views/Undergraduate')),
    mobileApp: lazy(() => import('src/views/Undergraduate/undergraduate-mobile-application')),
    reducers: undergraduateReducers,
    initialState: {
      collegeProfile: {
        userInfo: {
          user: EducationPhase.UNDERGRADUATE,
        },
        tab: 0,
        loading: true,
        modalPending: {},
      },
    },
  },
  [UserIdentifier.ADULT_LEARNER]: {
    app: lazy(() => import('src/views/AdultLearner')),
    mobileApp: lazy(() => import('src/views/AdultLearner/adult-learner-mobile-application')),
    reducers: undergraduateReducers,
    initialState: {
      collegeProfile: {
        userInfo: {
          user: EducationPhase.ADULT_LEARNER,
        },
        tab: 0,
        loading: true,
        modalPending: {},
      },
    },
  },
  [UserIdentifier.ADMIN]: {
    app: lazy(() => import('src/views/Admin')),
    reducers: adminReducers,
    initialState: {},
  },
  [UserIdentifier.PARENT]: {
    app: lazy(() => import('src/views/Parent/index')),
    mobileApp: lazy(() => import('src/views/Parent/parent-mobile-application')),
    reducers: parentReducers,
    initialState: {parent: {}},
  },
  [UserIdentifier.CP_USER]: {
    app: lazy(() => import('src/views/college-portal/index')),
    reducers: collegePortalReducers,
    initialState: collegePortalInitialState,
    modalConfigs: cpModals,
  },
  [UserIdentifier.DISTRICT_ADMIN]: {
    app: lazy(() => import('src/views/DistrictAdmin')),
    reducers: districtAdminReducers,
    initialState: {user: {type: 'DistrictAdmin'}},
  },
  [UserIdentifier.TEACHER]: educatorsApp,
  [UserIdentifier.COUNSELOR]: educatorsApp,
  [UserIdentifier.MENTOR]: educatorsApp,
  [UserIdentifier.CC_STAFF]: educatorsApp,
};

const getHsOnboardingApp = async () => {
  const isNewOnboarding = await FeatureFlagService.getFeatureFlagStatus('newOnboarding');
  return isNewOnboarding
    ? lazy(() => import('src/views/HighSchool/onboarding-2'))
    : lazy(() => import('src/views/HighSchool/Onboarding'));
};

const getCCOnboardingApp = async () => {
  const isNewOnboarding = await FeatureFlagService.getFeatureFlagStatus('newOnboarding');
  return isNewOnboarding
    ? lazy(() => import('src/views/Transfer/onboarding-2'))
    : lazy(() => import('src/views/Transfer/onboarding'));
};

const getCCMobileOnboardingApp = async () => {
  const isNewOnboarding = await FeatureFlagService.getFeatureFlagStatus('newOnboarding');
  return isNewOnboarding
    ? lazy(() => import('src/views/Transfer/onboarding/transfer-mobile-onboarding-2'))
    : lazy(() => import('src/views/Transfer/onboarding/transfer-mobile-onboarding'));
};

const getHsMobileOnboardingApp = async () => {
  const isNewOnboarding = await FeatureFlagService.getFeatureFlagStatus('newOnboarding');
  return isNewOnboarding
    ? lazy(() => import('src/views/HighSchool/Onboarding/high-school-mobile-onboarding-2'))
    : lazy(() => import('src/views/HighSchool/Onboarding/high-school-mobile-onboarding'));
};

export const onboardingApps: ObjectOf<AppDeclaration> = {
  [UserIdentifier.HS_STUDENT]: {
    app: await getHsOnboardingApp(),
    mobileApp: await getHsMobileOnboardingApp(),
    reducers: onboardingReducers,
    initialState: {},
  },
  [UserIdentifier.CC_STUDENT]: {
    app: await getCCOnboardingApp(),
    mobileApp: await getCCMobileOnboardingApp(),
    reducers: onboardingReducers,
    initialState: {},
  },
  [UserIdentifier.PARENT]: {
    app: lazy(() => import('src/views/Parent/onboarding')),
    mobileApp: lazy(() => import('src/views/Parent/onboarding/parent-mobile-onboarding')),
    reducers: parentReducers,
    initialState: {},
  },
  [UserIdentifier.ADULT_LEARNER]: {
    app: lazy(() => import('src/views/AdultLearner/Onboarding')),
    mobileApp: lazy(
      () => import('src/views/AdultLearner/Onboarding/adult-leaner-mobile-onboarding')
    ),
    reducers: onboardingReducers,
    initialState: {},
  },
  [UserIdentifier.TEACHER]: educatorsOnboardingApp,
  [UserIdentifier.COUNSELOR]: educatorsOnboardingApp,
  [UserIdentifier.MENTOR]: educatorsOnboardingApp,
  [UserIdentifier.CC_STAFF]: educatorsOnboardingApp,
  [UserIdentifier.CP_USER]: {
    app: lazy(() => import('src/views/college-portal/ResetPassword')),
    reducers: collegePortalReducers,
    initialState: collegePortalInitialState,
  },
};
