import React, {FC, SVGProps} from 'react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  SwipeableDrawer,
} from '@mui/material';
import {UseBooleanState} from 'src/hooks/useBoolean';
import {useHistory, useLocation} from 'react-router-dom';
import Icon from 'shared/components/icon';
import styles from './styles.module.scss';

type Href = string | ((pathname: string) => string);

export interface DrawerItem {
  Icon?: FC<SVGProps<SVGSVGElement>>;
  text: string;
  href?: Href;
  selected?: boolean;
  onClick?: () => void;
}

export interface BottomMobileDrawerProps {
  show: UseBooleanState;
  header?: string;
  drawerItems?: DrawerItem[];
}

const BottomMobileDrawer = (props: BottomMobileDrawerProps): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();

  const handleItemClick = (item: DrawerItem): void => {
    item.onClick?.();
    const href = typeof item.href === 'function' ? item.href(location.pathname) : item.href;
    href && history.push(href);
    props.show.setFalse();
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.show.value}
      onOpen={props.show.setTrue}
      onClose={props.show.setFalse}
      disableSwipeToOpen
      PaperProps={{sx: {borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}}
    >
      <Box>
        <List
          subheader={
            <ListSubheader>
              <span className={styles.header}>{props.header}</span>
            </ListSubheader>
          }
        >
          {props.drawerItems?.map((item) => (
            <React.Fragment key={item.text}>
              <ListItem button key={item.text} onClick={(): void => handleItemClick(item)}>
                {item.Icon && (
                  <ListItemIcon>
                    <item.Icon width={32} height={32} />
                  </ListItemIcon>
                )}
                {item.text}
              </ListItem>
              <Divider variant={item.Icon ? 'inset' : 'fullWidth'} />
            </React.Fragment>
          ))}
        </List>
        <Box display="flex" justifyContent="center">
          <Icon iconType="times-r" className={styles.closeIcon} onClick={props.show.setFalse} />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default BottomMobileDrawer;
