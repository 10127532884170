import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'shared/components/icon';
import 'stylesheets/components/shared-text-button.scss';

class TextButton extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    id: PropTypes.string,
    'aria-haspopup': PropTypes.string,
    'aria-expanded': PropTypes.bool,
    buttonName: PropTypes.string,
    color: PropTypes.oneOf(['blue', 'dark-gray', 'light-gray', 'red', 'white']),
    hoverColor: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    margin: PropTypes.bool,
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
    'aria-label': PropTypes.string,
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconStyle: PropTypes.object,
    cy: PropTypes.string,
    testid: PropTypes.string,
  };

  static defaultProps = {
    buttonName: '',
    color: 'blue',
    hoverColor: 'blue',
    disabled: false,
    onClick: null,
    selected: false,
    type: 'button',
    margin: true,
  };

  getClassName = () =>
    classnames(
      'shared-text-button',
      this.props.className,
      this.props.color,
      `hover-${this.props.hoverColor}`,
      {margin: this.props.margin},
      {'shared-text-button-selected': this.props.selected}
    );

  renderIcon = () => (
    <Icon
      iconType={this.props.icon}
      className={this.props.iconPosition}
      style={this.props.iconStyle}
    />
  );

  render() {
    const {onClick, type, disabled, buttonName, icon, iconPosition} = this.props;

    return (
      <button
        id={this.props.id}
        aria-haspopup={this.props['aria-haspopup']}
        aria-expanded={this.props['aria-expanded']}
        className={this.getClassName()}
        disabled={disabled}
        onClick={onClick}
        type={type}
        data-segment-track="button"
        data-button-name={buttonName}
        aria-label={this.props['aria-label']}
        data-cy={this.props.cy}
        data-testid={this.props.testid}
      >
        {icon && iconPosition === 'left' && this.renderIcon()}
        {this.props.children}
        {icon && iconPosition === 'right' && this.renderIcon()}
      </button>
    );
  }
}

export default TextButton;
