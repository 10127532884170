import React from 'react';
import PropTypes from 'prop-types';
import TagsTypeaheadFilter from 'src/components/CollegeSearch/Filters/TagsTypeaheadFilter';
import CheckboxGroupFilter from 'shared/components/filters/checkbox-group-filter';

class TagsFilters extends React.Component {
  static propTypes = {
    getColleges: PropTypes.func.isRequired,
  };

  render() {
    const {getColleges} = this.props;

    return (
      <div className="college-search-filters-section tags-filter">
        <fieldset role="group" aria-label="Filter by Tags">
          <legend className="college-search-filters-section-legend">
            <label
              className="college-search-filters-section-label"
              htmlFor="typeahead-tag"
              id="tag"
            >
              Tag
            </label>
          </legend>
          <div className="college-search-filters-section-typeahead">
            <TagsTypeaheadFilter onChange={getColleges} />
          </div>
          <div>
            <CheckboxGroupFilter
              name="top-college-tags"
              onChange={getColleges}
              options={{'College Town': 'College Town'}}
            />
            <CheckboxGroupFilter
              name="top-college-tags"
              onChange={getColleges}
              options={{'High Acceptance Rate': 'High Acceptance Rate'}}
            />
            <CheckboxGroupFilter
              name="top-college-tags"
              onChange={getColleges}
              options={{'Low Student-Faculty Ratio': 'Low Student-Faculty Ratio'}}
            />
            <CheckboxGroupFilter
              name="top-college-tags"
              onChange={getColleges}
              options={{Reputable: 'Reputable'}}
            />
            <CheckboxGroupFilter
              name="top-college-tags"
              onChange={getColleges}
              options={{Weather: 'Weather'}}
            />
          </div>
        </fieldset>
      </div>
    );
  }
}

export default TagsFilters;
