import {merge, omit} from 'lodash';
import {LoadState} from 'src/types/enums';

// ------- CONSTANTS -------

export const LOAD_RECOMMENDATIONS = 'LOAD_RECOMMENDATIONS';
export const FOLLOW_REC_COLLEGE = 'FOLLOW_REC_COLLEGE';
export const UNFOLLOW_REC_COLLEGE = 'UNFOLLOW_REC_COLLEGE';
export const CLEAR_RECOMMENDATIONS = 'CLEAR_RECOMMENDATIONS';
export const RECEIVE_BUCKETS = 'RECEIVE_BUCKETS';
export const DELETE_BUCKET = 'DELETE_BUCKET';
export const FETCH_RECOMMENDATIONS = 'FETCH_RECOMMENDATIONS';

// ------- ACTIONS -------

export function clearRecommendations() {
  return {type: CLEAR_RECOMMENDATIONS};
}

export function recommendationsShown(recommendationsShownEventId, buckets) {
  return {
    type: RECEIVE_BUCKETS,
    recommendationsShownEventId,
    buckets,
  };
}

// ------- REDUCER -------

const initialState = {
  recommendationsShownEventId: null,
  collegeInformation: {},
  buckets: {},
};

function parseCollegeInformation(state, recommendedColleges) {
  return recommendedColleges.reduce((info, college) => {
    info[college.id] = merge({}, info[college.id], college);
    return info;
  }, state.collegeInformation);
}

export const recommendationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RECOMMENDATIONS: {
      const bucketId = action.id;
      return {
        ...state,
        collegeInformation: parseCollegeInformation(state, action.recommendedColleges),
        buckets: {
          ...state.buckets,
          [bucketId]: {
            ...state.buckets[bucketId],
            bucketShownEventId: action.bucketShownEventId,
            loadState: LoadState.LOADED,
            collegeIds: action.recommendedColleges.map((college) => college.id),
          },
        },
      };
    }
    case FETCH_RECOMMENDATIONS: {
      const bucketId = action.id;
      return {
        ...state,
        buckets: {
          ...state.buckets,
          [bucketId]: {
            ...state.buckets[bucketId],
            loadState: LoadState.LOADING,
          },
        },
      };
    }
    case DELETE_BUCKET: {
      return {
        ...state,
        buckets: omit(state.buckets, action.id),
      };
    }
    case CLEAR_RECOMMENDATIONS:
      return {
        ...state,
        collegeInformation: {},
        buckets: {},
      };
    case FOLLOW_REC_COLLEGE: {
      const collegeId = action.id;
      return {
        ...state,
        collegeInformation: {
          ...state.collegeInformation,
          [collegeId]: {
            ...state.collegeInformation[collegeId],
            isFollowing: true,
          },
        },
      };
    }
    case UNFOLLOW_REC_COLLEGE: {
      const collegeId = action.id;
      return {
        ...state,
        collegeInformation: {
          ...state.collegeInformation,
          [collegeId]: {
            ...state.collegeInformation[collegeId],
            isFollowing: false,
          },
        },
      };
    }
    case RECEIVE_BUCKETS: {
      return {
        ...state,
        recommendationsShownEventId: action.recommendationsShownEventId,
        buckets: action.buckets.reduce((buckets, bucket, index) => {
          buckets[bucket.id] = {
            index,
            bucketShownEventId: '',
            description: bucket.definition,
            id: bucket.id,
            short: bucket.short,
            title: bucket.because,
            collegeIds: [],
            loadState: LoadState.NONE,
          };

          return buckets;
        }, state.buckets),
      };
    }
    default:
      return state;
  }
};
