import React, {useEffect, useState} from 'react';
import {Link, Route, Switch, useLocation} from 'react-router-dom';
import Layout from 'src/views/public/shared/layout';
import UserSelection from './user-selection';
import UserSignUp2 from './user-sign-up-2';
import UserSignUp from './user-sign-up';
import EducatorSelection from './educator-selection';
import NotAvailable from './not-available';
import CommunityCollegeSelection from './community-college-selection';
import Eligibility from 'src/views/public/eligibility';
import defaultProps from 'src/utils/defaultProps';
import FeatureFlagService from '../../../services/FeatureFlagService';
import {SignUpUserType} from 'src/types/enums';

const SIZE = 55;

interface Props {
  setNavigationAnnouncement: (announcement: string) => void;
}

const SignUp = ({setNavigationAnnouncement}: Props) => {
  const {pathname} = useLocation();

  const splitPath = pathname.split('/');
  const userSubType = (splitPath.length > 1 && splitPath[2]) || '';
  const [isNewOnboarding, setIsNewOnboarding] = useState(false);

  const getSignupPage = async () => await FeatureFlagService.getFeatureFlagStatus('newOnboarding');

  useEffect(() => {
    const fetchSignupPage = async () => {
      const result = await getSignupPage();
      setIsNewOnboarding(result);
    };
    fetchSignupPage();
  }, []);

  const UserSignUpWithDefaults = defaultProps({size: SIZE})(
    isNewOnboarding &&
      (userSubType === SignUpUserType.HIGH_SCHOOL ||
        userSubType === SignUpUserType.COMMUNITY_COLLEGE)
      ? UserSignUp2
      : UserSignUp
  );

  useEffect(() => {
    setNavigationAnnouncement('Sign Up');
  }, [setNavigationAnnouncement]);

  return (
    <Layout page="signup">
      <div className="auth-signup auth-shared-credentials-container">
        <Switch>
          <Route
            exact
            path="/signup"
            render={() => (
              <UserSelection
                setNavigationAnnouncement={setNavigationAnnouncement}
                size={SIZE}
                ccSelectionGroupUrl="/signup/cc-student"
              />
            )}
          />
          <Route path="/signup/check-eligibility" component={Eligibility} />
          <Route
            exact
            path="/signup/educator"
            render={() => (
              <EducatorSelection
                size={SIZE}
                setNavigationAnnouncement={setNavigationAnnouncement}
              />
            )}
          />
          <Route
            exact
            path="/signup/community-college-selection"
            render={() => (
              <CommunityCollegeSelection
                size={SIZE}
                setNavigationAnnouncement={setNavigationAnnouncement}
              />
            )}
          />
          <Route
            exact
            path="/signup/not-available"
            render={() => <NotAvailable setNavigationAnnouncement={setNavigationAnnouncement} />}
          />
          <Route
            path="/signup/:userType/program/:scholarshipProgramId"
            component={UserSignUpWithDefaults}
          />
          <Route path="/signup/:userType/:userSubtype" component={UserSignUpWithDefaults} />
          <Route path="/signup/:userType" component={UserSignUpWithDefaults} />
        </Switch>
        <p className="auth-signup-already-have-account">
          Already have an account?{' '}
          <Link to="/login" className="auth-shared-link">
            Log in
          </Link>
        </p>
        <p className="auth-signup-already-have-account">
          {userSubType === 'cc-student' &&
            'You can log into your high school account to convert it to a college transfer account'}
        </p>
      </div>
    </Layout>
  );
};

export default SignUp;
