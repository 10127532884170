import PropTypes from 'prop-types';
import React from 'react';
import {map} from 'lodash';

// about-testimonial
import Testimonial from 'shared/college-profile/containers/about-college/testimonial';
import Card from 'src/components/Cards/Card';
import CollapsibleTeaserSection from 'shared/components/collapsible-teaser-section';
import Text from 'shared/components/text';

class AboutTestimonials extends React.Component {
  static propTypes = {
    testimonials: PropTypes.array.isRequired,
  };

  renderTestimonials = (testimonials) =>
    map(testimonials, (testimonial, index) => (
      <Testimonial
        key={`testimonial-${index}`}
        showHorizontalRule={index !== testimonials.length - 1}
        testimonial={testimonial}
      />
    ));

  renderTestimonialsBySize = (testimonials) => {
    const renderedTestimonials = this.renderTestimonials(testimonials);
    const subtitle =
      testimonials.length > 1 ? `${testimonials.length} testimonials` : '1 testimonial';
    return testimonials.length > 2
      ? this.renderCollapsibleTestimonials(renderedTestimonials, subtitle)
      : this.renderShortTestimonials(renderedTestimonials, subtitle);
  };

  renderShortTestimonials = (renderedTestimonials, subtitle) => (
    <div className="about-testimonials-container">
      <Text header weight={2} emphasis>
        Student Testimonials
      </Text>
      <Text color="darkgrey" emphasis>
        {subtitle}
      </Text>
      {renderedTestimonials}
    </div>
  );

  renderCollapsibleTestimonials = (renderedTestimonials, subtitle) => (
    <CollapsibleTeaserSection
      title="Student Testimonials"
      subtitle={subtitle}
      trackIdentifier="college-listing-about-testimonials"
      className="college-profile-card-about-content teaser-testimonials-container"
    >
      <CollapsibleTeaserSection.Teaser>
        {renderedTestimonials.slice(0, 2)}
      </CollapsibleTeaserSection.Teaser>
      <CollapsibleTeaserSection.Body>{renderedTestimonials.slice(2)}</CollapsibleTeaserSection.Body>
    </CollapsibleTeaserSection>
  );

  render() {
    const {testimonials} = this.props;
    const renderedTestimonialsContainer = this.renderTestimonialsBySize(testimonials);
    return <Card className="zero-bottom-padding">{renderedTestimonialsContainer}</Card>;
  }
}

export default AboutTestimonials;
