import {reducer as formReducer} from 'redux-form';
import {modalReducer} from 'shared/reducers/modal';
import {notificationsReducer} from 'shared/reducers/notifications';
import requestInstitutionModalReducer from 'shared/reducers/request-institution-modal';
import {userConnectionsReducer} from 'shared/reducers/user-connections';
import achievementItemsReducer from 'shared/registration/reducers/achievement-items';
import collegesReducer from 'shared/registration/reducers/colleges';
import potentialPortfolioItemsReducer from 'shared/registration/reducers/potential-portfolio-items';
import recommendedCollegeIdsReducer from 'shared/registration/reducers/recommended-college-ids';
import checklistReducer from 'src/redux/checklist/reducer';
import checklistUserStatusReducer from 'src/redux/checklistUserStatus/reducer';
import toolbarNotificationReducer from '../../../../src/redux/toolbar-notification/reducer';

export const onboardingReducers = {
  achievementItems: achievementItemsReducer,
  checklist: checklistReducer,
  checklistUserStatus: checklistUserStatusReducer,
  colleges: collegesReducer,
  form: formReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  potentialPortfolioItems: potentialPortfolioItemsReducer,
  recommendedCollegeIds: recommendedCollegeIdsReducer,
  requestInstitutionModal: requestInstitutionModalReducer,
  toolbarNotification: toolbarNotificationReducer,
  userConnections: userConnectionsReducer,
};

export default onboardingReducers;
