import req from 'shared/req';
import {isEmpty} from 'lodash';

export default class FeatureInteractionService {
  static URL = '/v1/users/me/feature-interactions';

  private static createUrl(featureName: string): string {
    return `${FeatureInteractionService.URL}/${featureName}`;
  }

  static async get<T>(featureName: string, fields: string | string[]): Promise<T> {
    let response;

    if (Array.isArray(fields)) {
      fields = fields.join(',');
    }

    try {
      response = await req({
        url: FeatureInteractionService.createUrl(featureName),
        method: 'get',
        data: isEmpty(fields) ? {} : {fields},
      });
    } catch (error) {
      // means the feature doesn't exist
    }

    return response;
  }

  static async patch<T>(featureName: string, featureData: Partial<T>): Promise<T> {
    return req({
      url: FeatureInteractionService.createUrl(featureName),
      method: 'patch',
      data: {featureData},
    });
  }

  static async post<T>(featureName: string, featureData: T = {} as T): Promise<T> {
    return req({
      url: FeatureInteractionService.URL,
      method: 'post',
      data: {
        featureData: {
          featureName,
          ...featureData,
        },
      },
    });
  }
}
