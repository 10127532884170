import React, {createRef, Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {throttle} from 'lodash';

// images
import CarouselArrow from 'shared/components/carousel-arrow';

// Assumes all items in the carousel are the same width
class Carousel extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    arrowProps: PropTypes.object,
    carouselKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    fade: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.scrollBody = createRef();
    this.scrollContainer = createRef();
    this.state = {
      marginLeft: 0,
      marginRight: 0,
      end: null,
      overflow: true,
    };
    this.interval = null;
  }

  static defaultProps = {
    carouselKey: 1,
  };

  componentDidMount() {
    this.throttledCalculateOverflow = throttle(this.calculateOverflow, 500);
    window.addEventListener('resize', this.throttledCalculateOverflow);
    setTimeout(this.calculateOverflow);
    this.interval = setInterval(this.calculateOverflow, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledCalculateOverflow);
    clearInterval(this.interval);
  }

  calculateOverflow = () => {
    const {firstChild, childElementCount} = this.scrollBody.current || {};
    const {offsetWidth: containerWidth} = this.scrollContainer.current || {};

    if (!firstChild || !firstChild.offsetWidth || !containerWidth) {
      return;
    }

    // Set overflow to false if the number of items the carousel can display
    // is greater or equal to than the number of children passed in.
    const childStyles = window.getComputedStyle(firstChild);
    const marginWidth = parseInt(childStyles.marginLeft) + parseInt(childStyles.marginRight);
    const maxCanShow = Math.floor(containerWidth / (firstChild.offsetWidth + marginWidth));

    if (maxCanShow >= childElementCount) {
      this.setState({overflow: false});
    } else {
      this.setState({overflow: true});
    }
  };

  handlePrevClick = () => {
    const {
      firstChild,
      firstChild: {offsetWidth},
    } = this.scrollBody.current;
    const {offsetWidth: containerWidth} = this.scrollContainer.current;
    const childStyles = window.getComputedStyle(firstChild);
    const marginWidth = parseInt(childStyles.marginLeft) + parseInt(childStyles.marginRight);
    const displayWidth = Math.floor(containerWidth / offsetWidth) * (offsetWidth + marginWidth);

    this.setState(({marginLeft}) => {
      const leftOffset = Math.min(marginLeft + displayWidth, 0);

      return {
        marginLeft: leftOffset,
        marginRight: -leftOffset,
      };
    });
  };

  handleNextClick = () => {
    const {
      firstChild,
      firstChild: {offsetWidth},
    } = this.scrollBody.current;
    const {offsetWidth: containerWidth, scrollWidth} = this.scrollContainer.current;
    const childStyles = window.getComputedStyle(firstChild);
    const marginWidth = parseInt(childStyles.marginLeft) + parseInt(childStyles.marginRight);
    const displayWidth = Math.floor(containerWidth / offsetWidth) * (offsetWidth + marginWidth);

    // Calculate margin needed to get to the end on the first click.
    // The 4 adds space to allow shadows to show on the right edge.
    if (!this.state.end && this.state.marginLeft === 0) {
      this.setState(() => ({
        end: -scrollWidth + containerWidth - 4,
      }));
    }

    this.setState(({marginLeft, end}) => {
      const leftOffset = Math.max(marginLeft - displayWidth, end);

      return {
        marginLeft: leftOffset,
        marginRight: -leftOffset,
      };
    });
  };

  render() {
    const {children, carouselKey, arrowProps, className, fade} = this.props;

    const {marginLeft, marginRight, end, overflow} = this.state;

    const isLeftArrowVisible = overflow && marginLeft < 0;
    const isRightArrowVisible = overflow && marginLeft !== end;

    return (
      <div
        className={classnames('shared-carousel', className)}
        ref={this.scrollContainer}
        data-testid="shared-carousel"
      >
        <CarouselArrow
          className={classnames('shared-carousel-arrow', 'prev', {visible: isLeftArrowVisible})}
          direction="left"
          onClick={this.handlePrevClick}
          arrowKey={carouselKey}
          {...arrowProps}
        />
        <div
          className={classnames('shared-carousel-fade', 'fade-left', {
            visible: isLeftArrowVisible && fade,
          })}
        />
        <div
          className="shared-carousel-display"
          ref={this.scrollBody}
          style={{marginLeft, marginRight}}
        >
          {children}
        </div>
        <div
          className={classnames('shared-carousel-fade', 'fade-right', {
            visible: isRightArrowVisible && fade,
          })}
        />
        <CarouselArrow
          className={classnames('shared-carousel-arrow', 'next', {visible: isRightArrowVisible})}
          direction="right"
          onClick={this.handleNextClick}
          arrowKey={carouselKey}
          {...arrowProps}
        />
      </div>
    );
  }
}

export default Carousel;
