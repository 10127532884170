import React, {useState} from 'react';
import CollegeApplicationStatusMenu from 'shared/college-application-status/containers/college-application-status-menu';
import {earning} from 'shared/utils';
import Card from 'src/components/Cards/Card';
import FeatureFlagService from 'src/services/FeatureFlagService';
import {UserType} from 'src/types/enums';
import CollegeStatusText from './college-status-text';
import CommunityCollegeStatusText from './community-college-status-text';
import CtaButton from './cta-button';
import EarningsDisplay from './earnings-display';
import Header from './header';
import useAsyncEffect from 'src/hooks/use-async-effect';

interface Props {
  // TODO Tech Debt: this should be the same types as in src/store/store.ts
  maxEarnings: number;
  collegeProfile: {
    userInfo: {
      user: string;
      academicInterestIds: string[];
      highSchoolInfo: {
        senior: boolean;
      };
    };
    tab: number;
    loading: boolean;
    college: {
      _id: string;
      name: string;
      logo: string;
      bannerHero: string;
      city: string;
      state: string;
      amount: number;
      isFollowing: boolean;
      partnerApplicationDeadlineDay: string;
      partnerApplicationDeadlineMonth: string;
      partnerSubmissionDeadlineDay: string;
      partnerSubmissionDeadlineMonth: string;
      earningRelationship: {
        statusType: string;
        minGPA: number;
      };
      divisor: number;
      followable: boolean;
      maxEarnings: number;
    };
  };
  followCollege: () => void;
  unfollowCollege: () => void;
  missingCredits: number;
  openSubmitEarlyModal: () => void;
}

const CollegeStatusCard = ({
  collegeProfile,
  followCollege,
  unfollowCollege,
  missingCredits,
  openSubmitEarlyModal,
}: Props) => {
  const {
    college,
    college: {
      logo,
      name,
      amount,
      city,
      state,
      isFollowing,
      earningRelationship,
      divisor,
      followable,
      maxEarnings,
    },
    userInfo: {user},
  } = collegeProfile;

  const [showApplicationStatusMenu, setShowApplicationStatusMenu] = useState(false);

  useAsyncEffect(async () => {
    const flag = await FeatureFlagService.getFeatureFlagStatus('college_application_status');
    setShowApplicationStatusMenu(flag);
  }, []);

  const renderStatusText = () => {
    switch (user) {
      case UserType.HS_STUDENT:
        return (
          <CollegeStatusText collegeProfile={collegeProfile} missingCredits={missingCredits} />
        );
      case UserType.CC_STUDENT:
        return (
          <CommunityCollegeStatusText
            collegeProfile={collegeProfile}
            missingCredits={missingCredits}
          />
        );
      default:
        return null;
    }
  };

  const highSchoolOrTransferStudent = user === UserType.HS_STUDENT || user === UserType.CC_STUDENT;
  const canEarn = earning(earningRelationship.statusType);
  const maxStudentEarningsReached = !!maxEarnings && amount >= maxEarnings;

  return (
    <Card>
      <div className="college-profile-college-status-card">
        <Header {...{canEarn, city, logo, name, state}} />
        {highSchoolOrTransferStudent && showApplicationStatusMenu && (
          <CollegeApplicationStatusMenu
            college={college}
            className="college-app-status-section college-profile-app-status-dropdown"
            menuButtonColor="white"
            segmentEventLocation="college profile dropdown"
          />
        )}

        <EarningsDisplay
          amount={amount}
          statusType={earningRelationship.statusType}
          isPartner
          isFollowing={isFollowing}
          divisor={divisor}
          maxStudentEarningsReached={maxStudentEarningsReached}
        />
        {renderStatusText()}
        {followable && (
          <CtaButton
            followCollege={followCollege}
            unfollowCollege={unfollowCollege}
            collegeProfile={collegeProfile}
            missingCredits={missingCredits}
            openSubmitEarlyModal={openSubmitEarlyModal}
          />
        )}
      </div>
    </Card>
  );
};

export default CollegeStatusCard;
