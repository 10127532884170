import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import req from 'shared/req';
import AccessibleTypeahead from 'shared/components/accessible-typeahead';
import {expandFilter} from '../../actions/filters';

/**
 * Take a location object as returned by the locations endpoint and return
 * a new object that can be used as a filter value.
 */

interface Props {
  expandFilter: (filter: {name: string; value: any}) => void;
  removeCityFilter: (city: object | undefined) => void;
  onChange: (value: any) => void;
  noZip?: boolean;
  size?: string;
  filters: object[];
  adjustRadiusFilter: (radius: string) => void;
}

const LocationFilter = ({
  // eslint-disable-next-line @typescript-eslint/no-shadow
  expandFilter,
  onChange,
  noZip,
  size,
  filters,
  removeCityFilter,
  adjustRadiusFilter,
}: Props) => {
  const [existingRadius, setExistingRadius] = useState(false);
  const [existingCity, setExistingCity] = useState(false);
  const [locationInputValue, setLocationInputValue] = useState('');
  const locationFilter = filters['location'];

  useEffect(() => {
    setExistingRadius(!!filters?.['radius-location']);
    setExistingCity(!!filters?.['location']);
  }, [filters]);

  useEffect(() => {
    if (locationFilter && locationFilter.some((location) => location.hasOwnProperty('city'))) {
      setLocationInputValue(locationFilter[0]['city']);
    } else {
      setLocationInputValue('');
    }
  }, [filters]);

  const filterValueForLocation = (loc) => {
    switch (loc.type) {
      case 'ZipLocation':
        return {zip: loc.value};
      case 'City':
        return {city: loc.text, loc: loc.loc};
      case 'State':
        return {state: loc.value};
    }
  };

  const locationText = (loc) => {
    switch (loc.type) {
      case 'ZipLocation':
        return `${loc.name} ${loc.stateAbrv}`;
      case 'City':
        return `${loc.name}, ${loc.stateAbrv}`;
      case 'State':
        return loc.name;
      default:
        throw new Error(`Unknown location format: ${JSON.stringify(loc)}`);
    }
  };

  const locationAbbreviation = (loc) => ({
    value: loc.type === 'State' ? loc.stateAbrv : loc.name,
    type: loc.type,
    loc: loc.loc,
    text: locationText(loc),
  });

  const handleSearch = (query) =>
    req({
      url: '/v1/locations',
      method: 'GET',
      data: {query},
    }).then((results: any) => results.map(locationAbbreviation));

  const onSelect = (selection) => {
    const value = filterValueForLocation(selection);

    if (existingRadius && removeCityFilter && value) {
      const existingDistance = filters['radius-location'].split(',')[2];
      const newRadius = `${value['loc']}, ${existingDistance}`;

      removeCityFilter(value);
      adjustRadiusFilter(newRadius);
    }

    if (existingCity && removeCityFilter) {
      removeCityFilter(value);
    }

    if (existingRadius && selection.type === 'State') {
      // radius filters don't work with state, removing
      adjustRadiusFilter('Any');
    }

    expandFilter({name: 'location', value});
    setExistingRadius(true);
    onChange(value);
  };

  const filterBy = (option) => !(noZip && option.type === 'ZipLocation');

  const adjustInputValue = (typedValue) => {
    setLocationInputValue(typedValue);
  };

  return (
    <AccessibleTypeahead
      id="location-typeahead"
      positionFixed
      size={size}
      placeholder="Search for a location..."
      value={locationInputValue}
      filterBy={filterBy}
      inputProps={{id: 'typeahead-location'}}
      onSearch={handleSearch}
      onSelect={onSelect}
      onInputChange={adjustInputValue}
    />
  );
};

export default connect(null, {
  expandFilter,
})(LocationFilter);
