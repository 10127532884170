import {DISMISS_TOOLBAR_NOTIFICATION, SHOW_TOOLBAR_NOTIFICATION} from './actions';
import {RefObject} from 'react';

export interface ToolbarNotificationStore {
  show: boolean;
  ref: RefObject<HTMLDivElement> | null;
}

export default (state = {show: false, ref: null}, action): ToolbarNotificationStore => {
  switch (action.type) {
    case DISMISS_TOOLBAR_NOTIFICATION:
      return {
        ...state,
        show: false,
        ref: null,
      };
    case SHOW_TOOLBAR_NOTIFICATION:
      return {
        ...state,
        show: true,
        ref: action.payload.ref,
      };
    default:
      return state;
  }
};
