import React, {ReactElement} from 'react';
import {Box} from '@mui/material';
import Icon from 'shared/components/icon';
import styles from './styles.module.scss';

interface HeaderMessageProps {
  children: React.ReactNode | React.ReactNode[];
}

function HeaderMessage(props: HeaderMessageProps): ReactElement {
  return (
    <Box className={styles.notificationHeaderMessage}>
      <Icon iconType="check" className={styles.notificationHeaderCheckmark} />
      {props.children}
    </Box>
  );
}

interface HeaderProps {
  unsubscribedFrom?: string;
  unsubscribedAll: boolean;
}

export default function NotificationSettingsFormHeader(props: HeaderProps): ReactElement | null {
  if (props.unsubscribedAll) {
    return (
      <HeaderMessage key="all">
        You have successfully unsubscribed from all email communications. Note, you will still
        receive essential account-related emails (e.g. password reset).
      </HeaderMessage>
    );
  } else if (props.unsubscribedFrom) {
    return (
      <HeaderMessage key="unsubscribed-from">
        You have successfully unsubscribed from <strong>{props.unsubscribedFrom}</strong>.
      </HeaderMessage>
    );
  }

  return null;
}
