import {concat, clone} from 'lodash';
import {withoutIndex} from 'shared/utils';
import {
  SET_DISABILITY,
  REMOVE_DISABILITY,
  SET_DISABILITIES,
  ADD_DISABILITY,
} from 'src/redux/disabilities/actions';

export default function disabilitiesReducer(state = [], action) {
  switch (action.type) {
    case ADD_DISABILITY:
      return concat(state || [], {});
    case SET_DISABILITY: {
      if (state === null) {
        return [action.disability];
      } else if (action.index >= state.length) {
        return concat(state, action.disability);
      }
      const newState = clone(state);
      newState[action.index] = action.disability;
      return newState;
    }
    case REMOVE_DISABILITY: {
      const {index} = action;
      return withoutIndex(state, index);
    }
    case SET_DISABILITIES:
      return action.disabilities;
    default:
      return state;
  }
}
