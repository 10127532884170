import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SignUp from './sign-up';
import {setNavigationAnnouncement} from 'shared/actions/accessibility-announcement';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setNavigationAnnouncement,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SignUp);
