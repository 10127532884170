import {omit, times} from 'lodash';
import {getPeriodAbbreviation, portfolioUrl} from 'shared/portfolio/utils';
import req, {rejectCamelizedErrors, rejectGenericError} from 'shared/req';
import {RECEIVE_INSTITUTION, UPDATE_CURRENT_INSTITUTION} from 'portfolio/actions/institution';
import {objectId} from 'shared/utils';

export const RECEIVE_COURSES = 'RECEIVE_COURSES';

export const saveCourses = (courses) => (dispatch, getState) =>
  req({
    url: '/v1/portfolio/bulk_courses',
    method: 'PATCH',
    data: {
      courses: courses.map(({new: _, ...course}) => ({
        ...course,
        courseType: course.courseType === 'Dual Enrollment' ? 'College Level' : course.courseType,
        periodsPerYear: parseInt(course.periodType) || 0,
      })),
    },
  })
    .then((response) => {
      const {
        student: {currentInstitution},
      } = getState();

      const receivedCourses = response.courses.map((course) => {
        // Update institution in redux since activeYears or gradeSettings could change.
        // Also handles if course was added with a new institution which is possible via
        // dual enrollment for bulk entry
        dispatch({
          type: RECEIVE_INSTITUTION,
          institution: course.institution,
        });

        if (course.institution._id === currentInstitution._id) {
          dispatch({type: UPDATE_CURRENT_INSTITUTION, institution: course.institution});
        }

        return {
          ...course,
          courseType: course.courseType === 'College Level' ? 'Dual Enrollment' : course.courseType,
        };
      });

      dispatch({
        type: RECEIVE_COURSES,
        courses: receivedCourses,
      });

      return receivedCourses;
    })
    .catch(rejectCamelizedErrors)
    .catch(rejectGenericError);

export const saveCourse = (course) => (dispatch, getState) => {
  let url;
  let method;

  if (course.new) {
    url = '/graded_courses';
    method = 'POST';
  } else {
    url = `/graded_courses/${course._id}`;
    method = 'PATCH';
  }

  const {
    student: {_id, currentInstitution},
  } = getState();

  return req({
    url: portfolioUrl(url, _id),
    method: method,
    data: {
      ...omit(course, ['new']),
      courseType: course.courseType === 'Dual Enrollment' ? 'College Level' : course.courseType,
      periodsPerYear: parseInt(course.periodType) || 0,
    },
  })
    .then((response) => {
      const receivedCourse = {
        ...response.course,
        courseType:
          response.course.courseType === 'College Level'
            ? 'Dual Enrollment'
            : response.course.courseType,
      };

      // Update institution in redux since activeYears or gradeSettings could change.
      // Also handles if course was added with a new institution which is possible if
      // the user changes the school the course was taken at to a brand new school
      dispatch({
        type: RECEIVE_INSTITUTION,
        institution: receivedCourse.institution,
      });

      if (receivedCourse.institution._id === currentInstitution._id) {
        dispatch({type: UPDATE_CURRENT_INSTITUTION, institution: receivedCourse.institution});
      }

      dispatch({
        type: RECEIVE_COURSES,
        courses: [receivedCourse],
      });

      return receivedCourse;
    })
    .catch(rejectCamelizedErrors)
    .catch(rejectGenericError);
};

export const initCourseGrade = (period, gradeType) => ({
  gradeInput: null,
  gpaValue: null,
  _id: objectId(),
  period,
  gradeType,
});

// TODO: make this the only version of `initGradesForPeriod` after we use bulk
// entry in community college portfolio
export const initGradesForPeriod = (periodType, gradeType, hasFinalGrade) => {
  const periodsPerYear = parseInt(periodType) || 0;
  // i.e. periodType is 'W', 'S', or 'F'
  const initialGrades = times(periodsPerYear, (i) => {
    const period = `${getPeriodAbbreviation(periodType)}${i + 1}`;
    return initCourseGrade(period, gradeType);
  });
  if (periodsPerYear === 0 || hasFinalGrade) {
    // Final grade is combined with S, T, and Q.
    // Winter / Summer are not combined with other periods.
    const periodName = hasFinalGrade || periodType === '0' ? 'F' : periodType;
    initialGrades.push(initCourseGrade(periodName, gradeType));
  }
  return initialGrades;
};

export const receiveCourses = (courses) => ({
  type: RECEIVE_COURSES,
  courses,
});
