import React from 'react';
import classnames from 'classnames';
import Icon from 'shared/components/icon';
import 'stylesheets/components/shared-tooltip.scss';

interface Props {
  className?: string;
  size?: number;
  blue?: boolean;
}

const InfoIcon = ({className, size = 18, blue = false}: Props) => (
  <Icon
    iconType="info-circle-r"
    style={{fontSize: `${size}px`}}
    className={classnames('shared-tooltip-icon', className, {'shared-tooltip-icon-blue': blue})}
  />
);

export default InfoIcon;
