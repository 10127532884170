import StatusNotification from './StatusNotification';
import {connect} from 'react-redux';
import {dismissCitizenNotification} from 'src/redux/collegeSearch/actions';
import {Store} from 'src/types/store';

export default connect(
  (state: Store) => ({
    user: state.collegeSearch.user,
  }),
  {
    dismissCitizenNotification,
  }
)(StatusNotification);
