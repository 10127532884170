// award-notification
import {
  ADD_AWARD_NOTIFICATION,
  REMOVE_AWARD_NOTIFICATION,
  SET_ACTIVE_AWARD_NOTIFICATION,
  UNSET_ACTIVE_AWARD_NOTIFICATION,
} from 'shared/actions/award-notifications';

const defaultState = {
  activeAward: null,
  awards: [],
};

export const awardNotificationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_AWARD_NOTIFICATION:
      const newState = {
        ...state,
        awards: state.awards.concat([action.award]),
      };

      if (newState.awards.length === 1) {
        newState.activeAward = newState.awards[0];
      }

      return newState;
    case REMOVE_AWARD_NOTIFICATION:
      return {
        ...state,
        awards: state.awards.slice(1),
      };
    case SET_ACTIVE_AWARD_NOTIFICATION:
      return {
        ...state,
        activeAward: state.awards[0],
      };
    case UNSET_ACTIVE_AWARD_NOTIFICATION:
      return {
        ...state,
        activeAward: null,
      };
    default:
      return state;
  }
};
