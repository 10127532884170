import {forEach, omitBy, includes, reject} from 'lodash';

// student-search
import {
  DOWNLOAD_REQUESTED,
  DOWNLOAD_LISTINGS_RETRIEVED,
  DOWNLOAD_REMOVED,
  PENDING_DOWNLOAD_LISTINGS_RETRIEVED,
} from '../actions/download';

const initialState = {reports: [], reportsInProgress: {}};

export const downloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_REQUESTED: {
      const reportsInProgress = {...state.reportsInProgress, [action.report._id]: action.report};
      return {
        ...state,
        reportsInProgress,
      };
    }
    case DOWNLOAD_LISTINGS_RETRIEVED: {
      const {reportsInProgress} = state;
      const {reports} = action;
      const finishedReportIds = reports.map((report) => report._id);
      return {
        ...state,
        reports,
        reportsInProgress: omitBy(reportsInProgress, (report, _id) =>
          includes(finishedReportIds, _id)
        ),
      };
    }
    case PENDING_DOWNLOAD_LISTINGS_RETRIEVED: {
      const reportsInProgress = {};

      forEach(action.reports, (report) => (reportsInProgress[report._id] = report));

      return {
        ...state,
        reportsInProgress,
      };
    }
    case DOWNLOAD_REMOVED: {
      let reports = [];
      let deletingReports = [];
      if (action.reportId !== 'all') {
        reports = reject(state.reports, {_id: action.reportId});
        deletingReports = reject(state.deletingReports, {_id: action.reportId});
      }
      return {...state, deletingReports, reports};
    }
    default:
      return state;
  }
};
