import {some, omit} from 'lodash';
import {
  NEW_HONOR_AWARD,
  EDIT_HONOR_AWARD,
  CANCEL_HONOR_AWARD,
  RECEIVE_HONOR_AWARD,
  HONOR_AWARD_DELETED,
  SCROLLED_TO_HONOR_AWARD,
  RECEIVE_HONOR_AWARDS,
} from 'shared/portfolio/actions/honor-awards';

export function honorAwardsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_HONOR_AWARD:
      return {
        ...state,
        [action.id]: {
          ...action.honorAward,
        },
      };
    case NEW_HONOR_AWARD:
      if (some(state, 'new')) {
        return state;
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.honorAward,
          },
        };
      }
    case CANCEL_HONOR_AWARD:
      if (action.honorAward.new) {
        return omit(state, action.id);
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.honorAward,
            editing: false,
            scrollTo: true,
          },
        };
      }
    case EDIT_HONOR_AWARD:
      return {
        ...state,
        [action.id]: {
          ...action.honorAward,
          editing: true,
        },
      };
    case SCROLLED_TO_HONOR_AWARD:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          scrollTo: false,
        },
      };
    case HONOR_AWARD_DELETED:
      return omit(state, action.id);
    case RECEIVE_HONOR_AWARDS:
      return {
        ...state,
        ...action.honorAwards,
      };
    default:
      return state;
  }
}
