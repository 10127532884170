import req from 'shared/req';
import {createAction} from 'redux-actions';

const createTestimonialHelpfulness = (collegeId, testimonialId) =>
  req({
    url: `/v1/colleges/${collegeId}/testimonials/${testimonialId}/toggle_helpful`,
    method: 'post',
  });

export const RECEIVE_COLLEGE_TESTIMONIAL = createAction('RECEIVE_COLLEGE_TESTIMONIAL');

export const toggleCollegeTestimonialHelpfulness = (testimonialId) => (dispatch, getState) => {
  const {
    collegeProfile: {
      college: {_id: collegeId},
    },
  } = getState();

  return createTestimonialHelpfulness(collegeId, testimonialId).then((response) =>
    dispatch(RECEIVE_COLLEGE_TESTIMONIAL(response))
  );
};
