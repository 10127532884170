import {merge} from 'lodash';
import {decamelizeKeys} from 'humps';
import {portfolioUrl} from 'shared/portfolio/utils';
import req, {rejectCamelizedErrors, rejectGenericError} from 'shared/req';
import {objectId} from 'shared/utils';

export const NEW_WORK_EXPERIENCE = 'NEW_WORK_EXPERIENCE';
export const EDIT_WORK_EXPERIENCE = 'EDIT_WORK_EXPERIENCE';
export const CANCEL_EDIT_WORK_EXPERIENCE = 'CANCEL_EDIT_WORK_EXPERIENCE';

export const RECEIVE_WORK_EXPERIENCE = 'RECEIVE_WORK_EXPERIENCE';
export const DELETE_WORK_EXPERIENCE = 'DELETE_WORK_EXPERIENCE';

export const SCROLLED_TO_EXPERIENCE = 'SCROLLED_TO_EXPERIENCE';
export const RECEIVE_WORK_EXPERIENCES = 'RECEIVE_WORK_EXPERIENCES';

export function receiveWorkExperience(workExperience) {
  return {
    type: RECEIVE_WORK_EXPERIENCE,
    id: `work-experience-${workExperience._id}`,
    workExperience,
  };
}

export function newWorkExperience(options = {}) {
  const workExperience = {
    _id: objectId(),
    role: '',
    place: '',
    responsibilities: '',
    startDate: '',
    endDate: '',
    currentWorkplace: false,
    paid: '',
    hoursPerWeek: '',
    familyService: '',
    editing: true,
    new: true,
    scrollTo: !!options.scrollTo,
  };

  return {
    type: NEW_WORK_EXPERIENCE,
    id: `work-experience-${workExperience._id}`,
    workExperience,
  };
}

export const saveWorkExperience = (workExperience) => (dispatch, getState) => {
  const path = workExperience.new ? '/work_experiences' : `/work_experiences/${workExperience._id}`;
  const method = workExperience.new ? 'POST' : 'PATCH';

  const {
    student: {_id},
  } = getState();
  return req({
    url: portfolioUrl(path, _id),
    method: method,
    data: decamelizeKeys(workExperience),
  })
    .then((response) => {
      dispatch(receiveWorkExperience(merge({}, response.workExperience, {scrollTo: true})));
    })
    .catch(rejectCamelizedErrors)
    .catch(rejectGenericError);
};

export function editWorkExperience(workExperience) {
  return {
    type: EDIT_WORK_EXPERIENCE,
    id: `work-experience-${workExperience._id}`,
    workExperience,
  };
}

export function cancelEditWorkExperience(workExperience) {
  return {
    type: CANCEL_EDIT_WORK_EXPERIENCE,
    id: `work-experience-${workExperience._id}`,
    workExperience,
  };
}

export function deleteWorkExperience(id) {
  return (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(`/work_experiences/${id}`, _id),
      method: 'DELETE',
    }).then(() => {
      dispatch({
        type: DELETE_WORK_EXPERIENCE,
        id: `work-experience-${id}`,
      });
    });
  };
}

export function scrolledToExperience(id) {
  return {
    type: SCROLLED_TO_EXPERIENCE,
    id: `work-experience-${id}`,
  };
}
