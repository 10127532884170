import {omit, merge} from 'lodash';
import {decamelizeKeys, camelizeKeys} from 'humps';
import {normalize} from 'normalizr';
import {communityServiceEvent as communityServiceEventSchema} from 'shared/portfolio/schemas/community-service';
import {portfolioUrl} from 'shared/portfolio/utils';
import req, {rejectGenericError} from 'shared/req';
import {objectId} from 'shared/utils';

export const DELETE_COMMUNITY_SERVICE_EVENT = 'DELETE_COMMUNITY_SERVICE_EVENT';
export const EDIT_COMMUNITY_SERVICE_EVENT = 'EDIT_COMMUNITY_SERVICE_EVENT';
export const RECEIVE_COMMUNITY_SERVICE_EVENT = 'RECEIVE_COMMUNITY_SERVICE_EVENT';
export const NEW_COMMUNITY_SERVICE_EVENT = 'NEW_COMMUNITY_SERVICE_EVENT';
export const CANCEL_EDIT_COMMUNITY_SERVICE_EVENT = 'CANCEL_EDIT_COMMUNITY_SERVICE_EVENT';
export const SCROLL_TO_SERVICE = 'SCROLL_TO_SERVICE';
export const SCROLLED_TO_SERVICE = 'SCROLLED_TO_SERVICE';
export const RECEIVE_COMMUNITY_SERVICE_EVENTS = 'RECEIVE_COMMUNITY_SERVICE_EVENTS';

const OMITTED_FIELDS = [
  'editing',
  'endMonth',
  'endYear',
  'scrollTo',
  'startMonth',
  'startYear',
  'dateRange',
];

export const newCommunityServiceObject = () => ({
  _id: objectId(),
  place: '',
  dateRange: false,
  startMonth: '',
  startYear: '',
  endMonth: '',
  endYear: '',
  description: '',
  hours: '',
  requiredByHs: false,
  createdAt: Math.floor(Date.now() / 1000),

  // FE only
  editing: true,
  new: true,
});

export function communityServiceFactory(options = {}) {
  return {
    ...newCommunityServiceObject(),
    scrollTo: !!options.scrollTo,
  };
}

export function addNewCommunityServiceEvent(options = {}) {
  const communityServiceEvent = communityServiceFactory(options);

  return {
    type: NEW_COMMUNITY_SERVICE_EVENT,
    id: `communityServiceEvent-${communityServiceEvent._id}`,
    communityServiceEvent,
  };
}

export function cancelCommunityServiceEvent(communityServiceEvent) {
  return {
    type: CANCEL_EDIT_COMMUNITY_SERVICE_EVENT,
    id: `communityServiceEvent-${communityServiceEvent._id}`,
    communityServiceEvent,
  };
}

export function deleteCommunityServiceEvent(id) {
  return (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(`/community_service_events/${id}`, _id),
      method: 'DELETE',
    }).then(() => {
      dispatch({
        type: DELETE_COMMUNITY_SERVICE_EVENT,
        id: `communityServiceEvent-${id}`,
      });
    });
  };
}

export function editCommunityServiceEvent(communityServiceEvent) {
  return {
    type: EDIT_COMMUNITY_SERVICE_EVENT,
    id: `communityServiceEvent-${communityServiceEvent._id}`,
    communityServiceEvent: {...communityServiceEvent, scrollTo: true},
  };
}

export function receiveCommunityServiceEvent(communityServiceEvent, id) {
  return {
    type: RECEIVE_COMMUNITY_SERVICE_EVENT,
    id,
    communityServiceEvent,
  };
}

export function scrolledToService(id) {
  return {
    type: SCROLLED_TO_SERVICE,
    id: `communityServiceEvent-${id}`,
  };
}

export const saveCommunityServiceEvent =
  (communityServiceEvent, shouldScroll = true) =>
  (dispatch, getState) => {
    communityServiceEvent = normalizeCommunityServiceEventDate(communityServiceEvent);

    let url;
    let method;

    if (communityServiceEvent.new) {
      url = '/community_service_events';
      method = 'POST';
    } else {
      url = `/community_service_events/${communityServiceEvent._id}`;
      method = 'PATCH';
    }

    communityServiceEvent.notRequiredByHs = !communityServiceEvent.requiredByHs;
    const isNewEvent = communityServiceEvent.new;
    communityServiceEvent = omit(communityServiceEvent, ['new', 'requiredByHs']);

    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(url, _id),
      method: method,
      data: {community_service_event: decamelizeKeys(communityServiceEvent)},
    })
      .then((resp) => {
        const normalizedCommService = normalize(resp, communityServiceEventSchema);
        const id = normalizedCommService.result;
        const commServiceEvent = omit(
          merge({}, normalizedCommService.entities.communityServiceEvents[id], {
            scrollTo: shouldScroll,
          }),
          ['endDateMonth', 'endDateYear', 'startDateMonth', 'startDateYear']
        );

        dispatch({
          type: RECEIVE_COMMUNITY_SERVICE_EVENT,
          id,
          communityServiceEvent: commServiceEvent,
        });

        communityServiceEvent.new = isNewEvent;
      })
      .catch((responseJSON) => {
        const errors = camelizeKeys(responseJSON);
        if (!errors) return rejectGenericError();
        errors.startMonth = errors.date || errors.startDate;
        errors.endMonth = errors.endDate;
        return Promise.reject(errors);
      });
  };

function normalizeCommunityServiceEventDate(communityServiceEvent) {
  const withoutDateFields = omit(communityServiceEvent, OMITTED_FIELDS);
  let transformedDateObj;
  const startDate = new Date(communityServiceEvent.startYear, communityServiceEvent.startMonth);

  if (communityServiceEvent.dateRange) {
    transformedDateObj = {
      startDate,
      endDate: new Date(communityServiceEvent.endYear, communityServiceEvent.endMonth),
    };
  } else {
    transformedDateObj = {date: startDate};
  }

  return merge({}, transformedDateObj, withoutDateFields);
}
