import req from 'shared/req';
import switchAccount from 'shared/actions/switch-account';
import {updateUser} from 'shared/actions/student';

export const RECEIVE_POTENTIAL_CONNECTED_USERS = 'RECEIVE_POTENTIAL_CONNECTED_USERS';
export const RECEIVE_POTENTIAL_CONNECTED_USER = 'RECEIVE_POTENTIAL_CONNECTED_USER';
export const REMOVE_POTENTIAL_CONNECTED_USER = 'REMOVE_POTENTIAL_CONNECTED_USER';

export const receivePotentialConnectedUsers = (potentialConnectedUsers) => ({
  type: RECEIVE_POTENTIAL_CONNECTED_USERS,
  potentialConnectedUsers,
});

export const receivePotentialConnectedUser = (potentialConnectedUser) => ({
  type: RECEIVE_POTENTIAL_CONNECTED_USER,
  potentialConnectedUser,
});

export const removePotentialConnectedUser = (potentialConnectedUser) => ({
  type: REMOVE_POTENTIAL_CONNECTED_USER,
  potentialConnectedUser,
});

export const fetchPotentialConnectedUsers = () => (dispatch) =>
  req({
    url: '/v1/potential-connected-users',
  }).then((potentialConnectedUsers) =>
    dispatch(receivePotentialConnectedUsers(potentialConnectedUsers))
  );

export const deletePotentialConnectedUser = (potentialConnectedUser) => (dispatch) =>
  req({
    url: `/v1/potential-connected-users/${potentialConnectedUser._id}`,
    method: 'delete',
  }).then(() => dispatch(removePotentialConnectedUser(potentialConnectedUser)));

export const convertToUser = (potentialConnectedUserId) => (dispatch, getState) =>
  req({
    url: `/v1/potential-connected-users/${potentialConnectedUserId}/convert-to-user`,
    method: 'post',
  }).then((user) => {
    const logInAsUser = () =>
      dispatch(switchAccount(user._id, 'Entering student mode from parent portal'));

    const {
      parent: {
        addressLine1,
        addressLine2,
        addressVerified,
        confirmedUnverifiedAddress,
        city,
        subdivision,
        postalCode,
      },
    } = getState();

    // if the parent has an address we are going to try and save that to the student
    if (addressLine1) {
      updateUser(
        {
          addressLine1,
          addressLine2,
          addressVerified,
          confirmedUnverifiedAddress,
          city,
          subdivision,
          postalCode,
          id: user._id,
        },
        '_id'
      )
        .then(logInAsUser)
        .catch(logInAsUser);
    } else {
      logInAsUser();
    }
  });
