import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MultipleReferralModal from './multiple-referral-modal';

// referrals
import {
  addReferral,
  sendReferrals,
  updateReferral,
  dismissReferralsCta,
  MULTIPLE_REFERRAL_MODAL_IDENTIFIER,
} from './actions';

const mapStateToProps = ({referrals, modal, transfer}) => {
  const show = modal[MULTIPLE_REFERRAL_MODAL_IDENTIFIER];

  return {referrals, show: !!show, amount: transfer ? 50 : 100};
};

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators(
    {
      addReferral,
      sendReferrals,
    },
    dispatch
  );

  return {
    ...actions,
    updateReferral:
      (i) =>
      (valueOrEvent, valid, active = false) => {
        const value = typeof valueOrEvent === 'string' ? valueOrEvent : valueOrEvent.target.value;
        dispatch(updateReferral(i, {value, invalid: !valid, touched: true, active}));
      },
    dismissReferralsCta() {
      dispatch(dismissReferralsCta());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultipleReferralModal);
