import {normalize} from 'normalizr';
import {flashMessage} from 'shared/actions/notifications';
import {getUser, updateUser, receiveStudent} from 'shared/actions/student';
import {RECEIVE_COURSE_PRESELECTS} from 'shared/portfolio/actions/courses';
import {RECEIVE_INSTITUTIONS} from 'shared/portfolio/actions/portfolio';
import {institutions as Schema} from 'shared/portfolio/schemas/shared';
import {UserType} from 'src/types/enums';

export const GET_USER_INFO = 'GET_USER_INFO';
export const UPDATE_HOUSEHOLD_INCOME_RANGE = 'UPDATE_HOUSEHOLD_INCOME_RANGE';
export const GET_COURSE_INFO = 'GET_COURSE_INFO';

const HS_USER_FIELDS = [
  'totalCredits',
  'state',
  'highSchoolInfo',
  'academicInterestIds',
  'satScore',
  'actScore',
  'householdIncomeRange',
  'highSchoolFrlPercentage',
  'image',
  'currentInstitution',
  'coursePreselects',
  'institutions',
  'fafsaSubmission',
  'isUsCitizenOrResident',
].join(',');

const CC_USER_FIELDS = ['totalCredits', 'state'].join(',');

export const updateAcademicInterests = (academicInterestIds) => (dispatch) => {
  // optimistically update redux
  dispatch(receiveStudent({academicInterestIds}));

  updateUser({academicInterestIds}, 'academicInterestIds').then((user) => {
    dispatch(receiveStudent(user));
    dispatch(flashMessage('Your interests have been updated in your Profile.'));
  });
};

export const getStudentInfo = (user) => {
  const fields = user === UserType.CC_STUDENT ? CC_USER_FIELDS : HS_USER_FIELDS;

  return (dispatch) =>
    getUser('me', fields).then((info) => {
      if (user === UserType.HS_STUDENT) {
        const {currentInstitution, coursePreselects, institutions} = info;

        const normalizedCoursePreselects = {
          ...coursePreselects,
          periodsPerYear: currentInstitution.periodsPerYear,
          institutionId: `inst-${coursePreselects.institutionId || currentInstitution._id}`,
        };

        const normalizedInstitutions = normalize(institutions, Schema).entities.institutions;

        // coursePreselects
        dispatch({
          type: RECEIVE_COURSE_PRESELECTS,
          coursePreselects: normalizedCoursePreselects,
        });

        // institutions
        dispatch({
          type: RECEIVE_INSTITUTIONS,
          institutions: normalizedInstitutions,
        });
      }

      // userInfo
      dispatch({
        type: GET_USER_INFO,
        info,
      });
    });
};

export const updateHouseholdIncomeRange = (householdIncomeRange) => (dispatch) => {
  dispatch({
    type: UPDATE_HOUSEHOLD_INCOME_RANGE,
    householdIncomeRange,
  });
  updateUser({householdIncomeRange}, 'householdIncomeRange');
};
