import req from 'shared/req';

export const updateDistrictAdmin = (data = {}, fields = '_id') => {
  const id = data.id || 'me';
  delete data.id;

  const url = `/v1/district-admins/${id}?fields=${fields}`;

  return req({
    url,
    method: 'patch',
    data,
  });
};

export const getDistrictAdmin = (id = 'me', fields = '_id') => {
  const url = `/v1/district-admins/${id}`;

  return req({
    url,
    method: 'get',
    data: {fields},
  });
};
