import React, {useEffect} from 'react';
import {Props as TextInputProps} from '../text-input';
import FormikTextInput from '../formik-text-input';
import {SpecificInputProps} from '../utils';
import {useField} from 'formik';
import errors from '../../../static/errors.json';
import styles from './styles.module.scss';
import {useBoolean} from '../../../hooks/useBoolean';

interface PasswordInputProps {
  signUp?: boolean;
  name?: string;
}

const FormikPasswordInput = ({
  signUp,
  name = 'password',
  ...props
}: TextInputProps & SpecificInputProps & PasswordInputProps) => {
  const showValidationRequirements = useBoolean(true);
  const [, meta] = useField({name: name, type: 'password'});

  useEffect(() => {
    // there's a race condition because of how the validation works, so we briefly have a value and no error even though it hasn't been touched
    // this makes sure we keep displaying the validation requirements in that case
    if (meta.error && !meta.touched) {
      showValidationRequirements.setTrue();
    }

    if (meta.touched || (!meta.error && meta.value)) {
      showValidationRequirements.setFalse();
    }
  }, [meta.error, meta.value, meta.touched]);

  return (
    <>
      {signUp ? (
        <>
          <FormikTextInput
            {...props}
            data-testid="password-input"
            showCheck={!meta.error}
            showErrorIcon
            name={name}
            type="password"
          />
          {showValidationRequirements.value && (
            <div
              className={styles.validationRequirements}
              role="alert"
              data-testid="shared-text-input-error"
            >
              {errors.validation.password.invalid}
            </div>
          )}
        </>
      ) : (
        <FormikTextInput {...props} data-testid="password-input" name={name} type="password" />
      )}
    </>
  );
};

export default FormikPasswordInput;
