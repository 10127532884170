import req from 'shared/req';
import {flow, mapKeys, mapValues, camelCase, startCase} from 'lodash/fp';
const mapValuesWithKey = mapValues.convert({cap: false});

export const MISSING_HIGH_SCHOOL_CHANGE_MODAL = 'MISSING_HIGH_SCHOOL_CHANGE_MODAL';
export function modalChanged(modal, callback = null) {
  if (callback) {
    callback(modal);
  }

  return {
    type: MISSING_HIGH_SCHOOL_CHANGE_MODAL,
    modal,
  };
}

export const MISSING_HIGH_SCHOOL_REQUESTED = 'MISSING_HIGH_SCHOOL_REQUESTED';
export function schoolRequested(email) {
  return {
    type: MISSING_HIGH_SCHOOL_REQUESTED,
    email,
  };
}

export function requestHighSchool({subdivision, city, schoolName}) {
  return (dispatch) =>
    req({
      method: 'post',
      url: '/request-add-high-school',
      data: {subdivision, city, school_name: schoolName},
    })
      .then(({email}) => {
        dispatch(schoolRequested(email));
      })
      .catch(({errors}) => {
        throw flow(
          mapKeys(camelCase),
          mapValuesWithKey((error, fieldName) => `${startCase(fieldName)} ${error}`)
        )(errors);
      });
}
