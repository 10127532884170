import {RoutedModalConfig} from 'src/components/ModalContext/types';
import {Modals} from 'src/types/enums';
import {lazy} from 'react';
import {ModalProps} from './VerifyLeadershipClaim';

export const verifyLeadershipClaimConfig: RoutedModalConfig<ModalProps> = {
  type: Modals.VERIFY_LEADERSHIP,
  routed: true,
  Component: lazy(
    () => import(/* webpackChunkName: "VerifyLeadershipClaim" */ './VerifyLeadershipClaim')
  ),
  validateProps: (props) => Boolean(props.activitySlug),
};
