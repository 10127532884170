import {useEffect} from 'react';

/**

Adding effect to the dep array should allow us to pass it from outside the component if we want
*/

function useAsyncEffect(effect: () => Promise<void>, dependencies: any[] = []) {
  useEffect(() => {
    effect();
  }, [...dependencies]);
}

export default useAsyncEffect;
