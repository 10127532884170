export const RECEIVE_NOTIFICATION_MESSAGE_INDEXES = 'RECEIVE_NOTIFICATION_MESSAGE_INDEXES';
export const REMOVE_NOTIFICATION_MESSAGE_INDEX = 'REMOVE_NOTIFICATION_MESSAGE_INDEX';
export const REMOVE_NOTIFICATION_MESSAGE_INDEXES = 'REMOVE_NOTIFICATION_MESSAGE_INDEXES';

export const removeNotificationMessageIndexes = () => ({
  type: REMOVE_NOTIFICATION_MESSAGE_INDEXES,
});

export const removeNotificationMessageIndex = (sequenceNumber) => ({
  type: REMOVE_NOTIFICATION_MESSAGE_INDEX,
  sequenceNumber,
});

export const receiveNotificationMessageIndexes = (sequenceNumbers) => ({
  type: RECEIVE_NOTIFICATION_MESSAGE_INDEXES,
  notificationMessageIndexes: sequenceNumbers,
});
