import {camelCase, isNil, uniq} from 'lodash';
import {createReducer, ReducerMap} from 'src/redux/type';
import req from 'shared/req';
import {Action, ActionType} from 'src/actions/utils';
import {AppThunk, ObjectOf, Store} from 'src/types/store';
import {KeyedOptionOf} from 'src/types/form';
import qs from 'qs';
import AcademicInterestsService, {AcademicInterest} from 'src/services/AcademicInterestsService';
import {EducationPhase} from 'src/types/enums';

export interface CommonTest {
  name: string;
  abbreviation: string;
  dropdown_label: string;
  searchable_label: string;
}

export interface CommonTestOption {
  id: number;
  value: CommonTest;
  text: string;
}

export interface Course {
  subject: string;
  text: string;
  name: string;
}

// HonorAward represents an object in the the response from /v1/lists/awards
export interface HonorAward {
  _id: string;
  name: string;
  issuer?: string;
  educationPhase: EducationPhase;
}

export interface ListReducerType {
  courses: {
    normalCourses: Course[];
    apCourses: Course[];
    ibCourses: Course[];
    certificationCourses: Course[];
    dualEnrollmentCourses: Course[];
  };
  commonTests: CommonTestOption[];
  loadedListNames: string[];
  academicInterests: AcademicInterest[];
  activityCategories: string[];
  awards: HonorAward[];
  reportColumns: string[];
}

export const initialState: ListReducerType = {
  courses: {
    normalCourses: [],
    apCourses: [],
    ibCourses: [],
    certificationCourses: [],
    dualEnrollmentCourses: [],
  },
  commonTests: [],
  loadedListNames: [],
  activityCategories: [],
  academicInterests: [],
  awards: [],
  reportColumns: [],
};

export const RECEIVE_LIST = 'RECEIVE_LIST';

export const subjectTypeMap = {
  Regular: 'normalCourses',
  Honors: 'normalCourses',
  Advanced: 'normalCourses',
  AP: 'apCourses',
  IB: 'ibCourses',
  Certification: 'certificationCourses',
  'Dual Enrollment': 'dualEnrollmentCourses',
};

const listResponseTransformer = {
  commonTests: (response): CommonTestOption[] =>
    response.map((item) => ({
      id: item._id,
      value: item,
      text: `${item.name} - ${item.abbreviation}`,
    })),
  courses: (response): ObjectOf<Course[]> =>
    Object.keys(response).reduce((allCourses, key) => {
      allCourses[key] = response[key].map((course) => ({
        subject: course.subject,
        text: course.name,
      }));

      return allCourses;
    }, {}),
  activityCategories: (response): KeyedOptionOf<string> =>
    response.map((category, index) => ({
      value: category,
      text: category,
      _id: category,
      key: index,
    })),
};

export function getAcademicInterestList(): AppThunk {
  const name = 'academicInterests';
  return (dispatch, getState): void => {
    if (getState().lists.loadedListNames.includes(name)) return;
    AcademicInterestsService.index().then((results) => {
      dispatch(Action(RECEIVE_LIST, {name, results}));
    });
  };
}

export const getListOf = <ReturnValue>(
  listName: string,
  options?: Record<string, string | number>
): Promise<ReturnValue> =>
  req({
    url: `/v1/lists/${listName}${!isNil(options) ? `?${qs.stringify(options)}` : ''}`,
    method: 'get',
    responseTransformer: listResponseTransformer[camelCase(listName)],
  });

export function fetchListOf(name, options?: Record<string, string | number>): AppThunk {
  return async (dispatch, getState): Promise<void> => {
    if (getState().lists.loadedListNames.includes(name)) return;
    return getListOf(name, options).then((results) => {
      dispatch(Action(RECEIVE_LIST, {name, results}));
    });
  };
}

export const getCommonTestsList = fetchListOf.bind(null, 'common-tests');
export const getCourseList = (educationPhase): AppThunk => fetchListOf('courses', {educationPhase});
export const getActivityCategories = fetchListOf.bind(null, 'activity-categories');
export const getHonorAwards = (educationPhase): AppThunk => fetchListOf('awards', {educationPhase});
export const getReportColumns = (): AppThunk => fetchListOf('report-columns');

const reducerMap: ReducerMap<ListReducerType> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [RECEIVE_LIST]: (state, action: ActionType<{name: string; results: any}>): ListReducerType => ({
    ...state,
    loadedListNames: uniq(state.loadedListNames.concat(action.payload.name)),
    [camelCase(action.payload.name)]: action.payload.results,
  }),
};

export const listReducer = createReducer<ListReducerType>(reducerMap, initialState);

export const getCommonTestOptionsSelector = (state: Store): CommonTestOption[] =>
  state.lists.commonTests;

export interface CourseOption {
  subject: string;
  text: string;
}

export const getCourseOptionsSelector = (state: Store, courseType: string): CourseOption[] => {
  const courseSubjectMap = state.lists.courses || {};
  return courseSubjectMap[subjectTypeMap[courseType]] || [];
};

export interface HonorTypeaheadOption {
  id: number;
  text: string;
}
export interface HonorAwardsAndIssuers {
  awardOptions: HonorTypeaheadOption[];
  issuerOptions: HonorTypeaheadOption[];
}
export const honorAwardsAndIssuersSelector = (state: Store): HonorAwardsAndIssuers => {
  const awardOptions: HonorTypeaheadOption[] = [];
  const issuerOptions: HonorTypeaheadOption[] = [];

  const honors = state.lists.awards;
  for (let id = 0; id < honors.length; id++) {
    const honor = honors[id];
    awardOptions.push({id, text: honor.name});
    if (honor.issuer) {
      issuerOptions.push({id, text: honor.issuer});
    }
  }

  return {awardOptions, issuerOptions};
};
