import {some, omit} from 'lodash';
import {
  NEW_MULTIMEDIA,
  EDIT_MULTIMEDIA,
  CANCEL_EDIT_MULTIMEDIA,
  RECEIVE_MULTIMEDIA,
  MULTIMEDIA_DELETED,
  SCROLLED_TO_MULTIMEDIA,
  RECEIVE_ALL_MULTIMEDIA,
} from 'shared/portfolio/actions/multimedia';

export function multimediaReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_MULTIMEDIA:
      return {
        ...state,
        [action.id]: {
          ...action.multimedia,
        },
      };
    case RECEIVE_ALL_MULTIMEDIA:
      return action.multimediaItems || {};
    case NEW_MULTIMEDIA: {
      if (some(state, 'new')) {
        return state;
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.multimedia,
          },
        };
      }
    }
    case CANCEL_EDIT_MULTIMEDIA: {
      if (action.multimedia.new) {
        return omit(state, action.id);
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.multimedia,
            editing: false,
            scrollTo: true,
          },
        };
      }
    }
    case EDIT_MULTIMEDIA:
      return {
        ...state,
        [action.id]: {
          ...action.multimedia,
          editing: true,
        },
      };
    case SCROLLED_TO_MULTIMEDIA: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          scrollTo: false,
        },
      };
    }
    case MULTIMEDIA_DELETED:
      return omit(state, action.id);
    default:
      return state;
  }
}
