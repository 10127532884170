import React, {useContext} from 'react';
import classnames from 'classnames';
import Icon from 'shared/components/icon';
import Button from 'src/components/Button';
import colors from 'shared/aptitude/colors';
import './header-content.scss';
import {PlatformContext} from '../../../src/components/PlatformContext';
import {IonButton, IonButtons, IonHeader, IonTitle, IonToolbar} from '@ionic/react';
import PlatformService from '../../../src/services/PlatformService';

interface Props {
  children?: React.ReactNode;
  subtitle?: React.ReactNode;
  color?: 'n900' | 'white';
  onClose?: () => void;
  onBack?: () => void;
  textAlign?: 'left' | 'center';
  ignorePlatform?: boolean;
}

const HeaderContent = ({
  children,
  subtitle,
  color = 'n900',
  onClose,
  onBack,
  textAlign = 'left',
  ignorePlatform = false,
}: Props) => {
  const platformContext = useContext(PlatformContext);

  if (!ignorePlatform && platformContext.isIonic && !PlatformService.isMobileWeb()) {
    return (
      <IonHeader>
        <IonToolbar
          className={classnames('shared-modal-header-content', {subtitle})}
          style={{color: colors[color]}}
        >
          {onBack && (
            <IonButtons slot="start">
              <IonButton
                className="shared-modal-header-content-link-button back-link"
                onClick={onBack}
              >
                <Icon iconType="chevron-left" />
              </IonButton>
            </IonButtons>
          )}
          <IonTitle className={classnames('shared-modal-header-content-title', textAlign)}>
            {children}
            {subtitle && <div className="shared-modal-header-content-subtitle">{subtitle}</div>}
          </IonTitle>
          {onClose && (
            <IonButtons slot="end">
              <IonButton onClick={onClose}>
                <Icon iconType="times-r" style={{color: colors[color]}} />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
    );
  }

  return (
    <div
      className={classnames('shared-modal-header-content', {subtitle})}
      style={{color: colors[color]}}
    >
      {onBack && (
        <Button
          className="shared-modal-header-content-link-button back-link"
          onClick={onBack}
          margin={false}
          aria-label="back"
          link
          buttonColor={color === 'white' ? 'white' : 'gray'}
        >
          <Icon iconType="chevron-left" />
        </Button>
      )}
      <div className={classnames('shared-modal-header-content-title', textAlign)}>
        {children}
        {subtitle && <div className="shared-modal-header-content-subtitle">{subtitle}</div>}
      </div>
      {onClose && (
        <Button
          className="shared-modal-header-content-link-button"
          onClick={onClose}
          margin={false}
          aria-label="close dialog"
          link
          buttonColor={color === 'white' ? 'white' : 'gray'}
          testId="close-modal-button"
        >
          <Icon iconType="times-r" />
        </Button>
      )}
    </div>
  );
};

export default HeaderContent;
