import PropTypes from 'prop-types';
import React from 'react';
import Carousel from 'shared/components/carousel';

class MiniInfoCardContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const {children} = this.props;

    return <Carousel className="mini-info-card-container">{children}</Carousel>;
  }
}

export default MiniInfoCardContainer;
