export const SET_ACCESSIBILITY_ANNOUNCEMENT = 'SET_ACCESSIBILITY_ANNOUNCEMENT';

const accessibilityAnnouncement = (announcement) => ({
  type: SET_ACCESSIBILITY_ANNOUNCEMENT,
  announcement,
});

// Delay dispatch of accessibilityAnnouncement so
// that the screen reader doesn't miss it.
export const setAccessibilityAnnouncement = (announcement) => (dispatch) => {
  setTimeout(() => {
    dispatch(accessibilityAnnouncement(announcement));
  }, 50);
};

export const setNavigationAnnouncement = (page) => (dispatch) => {
  document.title = `RaiseMe - ${page}`;
  dispatch(setAccessibilityAnnouncement(`Navigated to ${page}`));
};
