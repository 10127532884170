import * as Yup from 'yup';
import {
  validateContainsLatinCharacter,
  validatePassword,
  validatePhone,
  validatePhoneOrEmail,
} from 'src/utils/validations';
import {TestContext, ValidationError} from 'yup';

declare module 'yup' {
  interface StringSchema<T extends string | null | undefined = string> extends Schema<T> {
    phoneOrEmail: (message: string, onEmpty?: string | boolean) => StringSchema<T>;
    containsLatinCharacter: (message: string) => StringSchema<T>;
    phoneNumber: (message: string, onEmpty?: string | boolean) => StringSchema<T>;
    password: (message: string, onEmpty?: string | boolean) => StringSchema<T>;
  }

  interface ArraySchema<T> {
    unique: (message: string, getProperty?: (val: T) => T) => ArraySchema<T>;
  }
}

function handleEmptyValue(
  this: TestContext,
  onEmpty: boolean | string = true
): boolean | ValidationError {
  return typeof onEmpty === 'string' ? this.createError({path: this.path, message: onEmpty}) : true;
}

function handleValidationFunction<T extends string>(
  this: TestContext,
  value: T,
  message: string,
  fn: (value: T) => any & {valid: boolean}
): boolean | ValidationError {
  const result = fn(value);
  return result.valid ? true : this.createError({path: this.path, message});
}

Yup.addMethod(
  Yup.string,
  'phoneOrEmail',
  function checkPhoneOrEmail(message, onEmpty: boolean | string = true) {
    return this.test('phoneOrEmail', message, function (value: string) {
      return !value
        ? handleEmptyValue.bind(this)(onEmpty)
        : handleValidationFunction.bind(this)(value, message, validatePhoneOrEmail);
    });
  }
);

/**
 * Validates phone number
 */
Yup.addMethod(
  Yup.string,
  'phoneNumber',
  function checkPhone(message, onEmpty: boolean | string = true) {
    return this.test('phoneNumber', message, function (value: string) {
      return !value
        ? handleEmptyValue.bind(this)(onEmpty)
        : handleValidationFunction.bind(this)(value, message, validatePhone);
    });
  }
);

const defaultGetter = (x: unknown): unknown => x;

/**
 * Validates password
 */

Yup.addMethod(Yup.string, 'password', function (message, onEmpty = true) {
  return this.test('password', message, function (value: string) {
    return !value
      ? handleEmptyValue.bind(this)(onEmpty)
      : handleValidationFunction.bind(this)(value, message, validatePassword);
  });
});

/**
 * Allows checks on array uniqueness.
 * Can pass you're own get/transform method to pull properties with.
 * This will add an error to the first duplicated array item
 */
Yup.addMethod(Yup.array, 'unique', function checkUnique(message, getProperty = defaultGetter) {
  return this.test('unique', message, function (list: unknown[]) {
    const data = list.map(getProperty);
    const set = new Set(data);
    const isUnique = list.length === set.size;
    if (isUnique) return true;
    for (let i = 0; i < data.length; i++) {
      if (set.has(data[i])) {
        set.delete(data[i]);
      } else {
        return this.createError({path: `${this.path}.[${i}]`, message});
      }
    }

    // should never reach this point
    return false;
  });
});

Yup.addMethod(Yup.string, 'containsLatinCharacter', function containsLatinCharacter(message) {
  return this.test('containsLatinCharacter', message, function (value: string) {
    if (!value) return true;
    return validateContainsLatinCharacter(value)
      ? true
      : this.createError({path: this.path, message});
  });
});

export default Yup;
