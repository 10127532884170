import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'src/components/tooltip';
import {Link} from 'react-router-dom';

const propTypes = {
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
  portfolioUrl: PropTypes.string,
};

const CtaButton = ({category, className, portfolioUrl}) => {
  if (category === 'referral') {
    return (
      <Link
        aria-label="Open referral modal"
        className={className ? className : ''}
        to={`${location.pathname}/invite/create`}
      >
        Add
      </Link>
    );
  }

  return (
    <Tooltip
      title={
        <>
          Did you do this?
          <br />
          Add it to your portfolio now.
          <br />
          We’ll take you there!
        </>
      }
    >
      <Link
        aria-label="Did you do this? Add it to your portfolio now (we'll take you there)."
        className={className ? className : ''}
        to={portfolioUrl}
      >
        Add
      </Link>
    </Tooltip>
  );
};

CtaButton.propTypes = propTypes;

export default CtaButton;
