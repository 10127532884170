import {filter} from 'lodash';
import req from 'shared/req';

export const SHOW_TOOLBAR = 'SHOW_TOOLBAR';
export const HIDE_TOOLBAR = 'HIDE_TOOLBAR';

export const createDismissedToolbar = () => (dispatch) =>
  req({
    url: '/v1/users/me/feature-interactions',
    method: 'post',
    data: {
      featureData: {
        featureName: 'parent-access-toolbar',
        dismissed: true,
      },
    },
  })
    .then(() => {
      dispatch({type: HIDE_TOOLBAR});
    })
    .catch(() => {
      dispatch({type: HIDE_TOOLBAR});
    });

export const getShowHideToolbar = () => (dispatch, getState) =>
  req({
    url: '/v1/users/me/feature-interactions/parent-access-toolbar',
    method: 'get',
    data: {fields: 'dismissed'},
  })
    .then((feature) => {
      const {dismissed} = feature;

      if (dismissed) {
        dispatch({type: HIDE_TOOLBAR});
      } else {
        dispatch({type: SHOW_TOOLBAR});
      }
    })
    .catch(() => {
      const {userConnections} = getState();
      const acceptedConnections = filter(userConnections, {status: 'accepted'});

      if (acceptedConnections.length > 0) {
        dispatch({type: SHOW_TOOLBAR});
      } else {
        dispatch({type: HIDE_TOOLBAR});
      }
    });
