export const UNSAVED_CHANGES_MODAL_IDENTIFIER = 'UNSAVED_CHANGES_MODAL_IDENTIFIER';
export const CHECK_UNSAVED_CHANGES = 'CHECK_UNSAVED_CHANGES';
export const DISMISS_UNSAVED_CHANGES = 'DISMISS_UNSAVED_CHANGES';

export function checkUnsavedChanges(tab) {
  return {
    type: CHECK_UNSAVED_CHANGES,
    tab,
  };
}

export function dismissUnsavedChanges(tab) {
  return {
    type: DISMISS_UNSAVED_CHANGES,
    tab,
  };
}
