import req from 'shared/req';

export const getCollegePortalUser = (id = 'me', fields = '') => {
  const url = `/v1/college-portal-users/${id}`;

  return req({
    url,
    method: 'get',
    data: {fields},
  });
};

export const updateCollegePortalUser = (data, fields = '_id') => {
  const id = data.id || 'me';
  delete data.id;

  const url = `/v1/college-portal-users/${id}?fields=${fields}`;

  return req({
    url,
    method: 'patch',
    data,
  });
};
