import {forEach} from 'lodash';
import req from 'shared/req';
import {errorMessage} from 'shared/actions/notifications';

export const RECEIVE_USER_CONNECTIONS = 'RECEIVE_USER_CONNECTIONS';

export const RECEIVE_USER_CONNECTION = 'RECEIVE_USER_CONNECTION';

export const REMOVE_USER_CONNECTION = 'REMOVE_USER_CONNECTION';

export const receiveUserConnection = (userConnection) => ({
  type: RECEIVE_USER_CONNECTION,
  userConnection,
});

export const removeUserConnection = (id) => ({
  type: REMOVE_USER_CONNECTION,
  id,
});

export const fetchUserConnections =
  (fields = null) =>
  (dispatch) =>
    req({
      url: `/v1/user-connections?fields=${fields}`,
    }).then((userConnections) => {
      dispatch({type: RECEIVE_USER_CONNECTIONS, userConnections});
      return userConnections;
    });

export const switchRequestee =
  (id, fields = '_id,requestor,requestee') =>
  (dispatch) =>
    req({
      url: `/v1/user-connections/${id}/switch-requestee?fields=${fields}`,
      method: 'post',
    })
      .then((userConnection) => dispatch(receiveUserConnection(userConnection)))
      .catch((errors) => {
        forEach(errors, (error) => {
          if (error.field === 'existing_connection') {
            dispatch(errorMessage('You are already connected to this user'));
          }
        });
      });

export const rejectConnection =
  (id, fields = '_id,requestor,requestee') =>
  (dispatch) =>
    req({
      url: `/v1/user-connections/${id}/reject?fields=${fields}`,
      method: 'post',
    }).then((userConnection) => dispatch(receiveUserConnection(userConnection)));

export const acceptConnection = (id) => (dispatch) =>
  req({
    url: `/v1/user-connections/${id}/accept`,
    method: 'post',
  }).then((userConnection) => dispatch(receiveUserConnection(userConnection)));

export const destroyConnection = (id) => (dispatch) =>
  req({
    url: `/v1/user-connections/${id}`,
    method: 'delete',
  }).then(() => dispatch(removeUserConnection(id)));
