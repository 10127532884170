import React, {ComponentPropsWithoutRef} from 'react';
import classnames from 'classnames';
import 'stylesheets/components/shared-label.scss';

// a11y note: Because the form element is nested inside <label>, they are implicitly associated
// with each other and don't need to be explicitly labelled with for and id HTML properties.
interface Props extends ComponentPropsWithoutRef<'label'> {
  required?: boolean;
  noTopPadding?: boolean;
  children: [React.JSX.Element | string, React.JSX.Element];
  testId?: string;
}

const Label = ({required, className, noTopPadding, testId, children, ...labelProps}: Props) => {
  const [text, input] = children;

  return (
    <label
      className={classnames('shared-label', className, {
        'shared-label-no-top-padding': noTopPadding,
      })}
      data-testid={testId || 'shared-label'}
      {...labelProps}
    >
      <span className={classnames('shared-label-text', {required})} data-testid="shared-label-text">
        {text}
      </span>
      {input}
    </label>
  );
};

export default Label;
