import cardSortOrder from 'shared/micro-scholarships/cardSortOrder';

const cardTypeInfo = {
  oppTeacher101Event: {
    rangeEnding: ' per event',
    title: 'Attend',
    subtitle: 'Becoming a Teacher 101 Event',
    category: 'college',
    portfolioUrl: {
      pathname: '/micro-scholarships/college-success/college-event/college-reception/form',
      state: {skipScrolling: true},
    },
  },
  oppSatActScore: {
    rangeEnding: '',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Many colleges use the SAT and ACT as entrance exams to help determine admission. While standardized testing may not be the most exciting part of college admissions, a good score can go a long way in boosting your applications and helping you stand out from other applicants. Your SAT score may also determine your eligibility for outside scholarships or placement in college classes.
      </p>
      <p>
        <b>To earn: </b> To earn this micro-scholarships from colleges, you will report the date you took the exam, and your score.
      </p>
      `,
    ],
    title: 'SAT Score or ACT Score',
    category: 'score',
    portfolioUrl: {
      pathname: '/micro-scholarships/add-test-score',
      search: '?tests=sat2016,act',
      state: {skipScrolling: true},
    },
  },
  oppSport: {
    rangeEnding: ' per year',
    description: [
      `
      <p>
        <b>Why colleges care: </b> Even if you don't plan on continuing your sporting career after graduation, colleges still care that you play in high school. Colleges love the skills that sports teach! From time management to leadership development, your participation in sports can help grab a college's attention.
      </p>
      <p>
        <b>To earn:</b> To earn this micro-scholarship from colleges, you need to participate in a sport at least five hours per week or seventy hours per year at your school or in a league.
      </p>
      `,
    ],
    title: 'Participate in a Sport',
    subtitle: '5 hrs/wk or 70 hrs/year',
    category: 'sport',
    portfolioUrl: {search: 'view=activity', state: {skipScrolling: true}},
  },
  oppSatAct: {
    rangeEnding: '',
    description: [
      `
      <p>
        <b>Why colleges care:</b>  The Scholastic Aptitude Test, or the SAT for short, is a common entrance exam used to help determine college admission. You are able to take the SAT multiple times, as you are only required to send colleges your best score.
      </p>
      <p>
        <b>To earn:</b>  To earn this micro-scholarship, you will need to input the date that you took the exam (month and year).
      </p>
      `,
    ],
    title: 'Take the SAT',
    subtitle: 'Take the SAT',
    category: 'score',
    portfolioUrl: {
      pathname: '/micro-scholarships/add-test-score',
      search: 'tests=sat2016',
      state: {skipScrolling: true},
    },
  },
  oppReferStudent: {
    rangeEnding: '',
    description: [
      `
      <p>
        <b>Why colleges care:</b> As a RaiseMe student, you are showing colleges your commitment to getting to college. By referring friends to join RaiseMe, you are empowering your peers to also achieve their dreams in higher education. This micro-scholarship is reward for you fostering a collective dedication to education.
      </p>
      <p>
        <b>To earn: </b> To earn this micro-scholarship, you can either send out your custom join link or add the emails/phone numbers of your peers directly.
      </p>
      `,
    ],
    title: 'Refer-a-Friend',
    subtitle: 'Get $100 for each friend that you invite',
    category: 'referral',
    portfolioUrl: '/portfolio#invitation',
  },
  oppPsatPreActScore: {
    rangeEnding: '',
    description: [
      `
      <p>
        <b>Why colleges care:</b>  The Preliminary SAT, better known as the PSAT, is the practice test for the SAT college entrance exam. As most schools require you to take either the SAT or the ACT as part of the college admissions process, the PSAT provides students with an opportunity to get acquainted with the exam earlier on in high school, typically 10th and/or 11th grade. But preparation alone isn’t the only benefit to the exam. The PSAT also opens the door for money towards college through the National Merit Scholarship Program and this micro-scholarship on RaiseMe.
      </p>
      <p>
        <b>To earn:</b> To earn this micro-scholarships from colleges, you will report the date you took the exam, and your score.
      </p>
      `,
    ],
    title: 'Score Well on PSAT or PreACT',
    category: 'score',
    portfolioUrl: {
      pathname: '/micro-scholarships/add-test-score',
      search: '?tests=psat2015,preAct',
      state: {skipScrolling: true},
    },
  },
  oppPerfectAttendance: {
    rangeEnding: ' per year',
    description: [
      `
      <p>
        <b>Why colleges care:</b>  Perfect attendance for a year of school, meaning no absences or tardies, demonstrates a strong commitment to your education. Colleges admire that dedication and reliability!
      </p>
      <p>
        <b>To earn:</b> To earn this micro-scholarship, you will select the year where you were never absent.
      </p>
      `,
    ],
    title: 'Perfect Attendance for a Year',
    category: 'course',
    portfolioUrl: {
      pathname: '/micro-scholarships/college-success/perfect-attendance/form',
      state: {skipScrolling: true},
    },
  },
  oppNhsMember: {
    rangeEnding: '',
    description: [
      `
      <p>
        <b>Why colleges care:</b>  As a member of the National Honor Society, you are recognized for both your success in the classroom and your involvment in community service.
      </p>
      <p>
        <b>To earn: </b> To earn this micro-scholarship, you will need to report the year and month that you became a member of the NHS.
      </p>
      `,
    ],
    title: 'National Honor Society',
    category: 'honors-and-awards',
    portfolioUrl: {
      search: '?view=honor-awards',
      state: {skipScrolling: true},
    },
  },
  oppLeadershipSport: {
    rangeEnding: ' per year',
    description: [
      `
      <p>
        <b>Why colleges care:</b> How you spend your time outside of school speaks to who you are, what kind of student you’ll be, and how you’ll carry the torch as an alumnus of that school when you graduate. As a leader on a sports team, you show colleges the types of attributes you’ve honed over years of dedication to your sport, and therefore the skills and qualities you’ll bring to their community.
      </p>
      <p>
        <b>To earn:</b> To earn this micro-scholarship, you will need to report the title of your leadership position and the year you participated.
      </p>
      `,
    ],
    title: 'Leadership Role in a Sport',
    category: 'sport',
    portfolioUrl: {
      search: '?view=activity',
      state: {skipScrolling: true},
    },
  },
  oppIncreaseGPA: {
    rangeEnding: ' per .1pt per course',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Improving your Grade Point Average (GPA) shows colleges that you are able to work hard, study, and grow over time.
      </p>
      <p>
        <b>To earn:</b> Depending on the college, you will earn a certain amount for each 0.1 points above a specifc GPA, multiplied by the number of credits you have completed. Please note that your award will grow as you increase your GPA and as you continue to complete more courses.
      </p>
      `,
    ],
    title: 'Academic Progress',
    subtitle: 'Maintain a high GPA throughout high school',
    category: 'course',
    portfolioUrl: {pathname: '/micro-scholarships/course/new', state: {skipScrolling: true}},
  },
  oppGradeB: {
    rangeEnding: ' per course',
    description: [
      `
      <p>
        <b>Why colleges care:</b>  Receiving a B in a course demonstrates a strong understanding of a subject and your ability to work hard in a class to receive a good grade.
      </p>
      <p>
        <b>To earn: </b>  To earn this micro-scholarship, you will enter your grade when you add a course to your profile.
      </p>
      `,
    ],
    title: 'Get a B',
    category: 'course',
    portfolioUrl: {pathname: '/micro-scholarships/course/new', state: {skipScrolling: true}},
  },
  oppGoldAward: {
    rangeEnding: '',
    description: [
      `
      <p>
        <b>Why colleges care:</b> The Gold Award represents the highest acheivement in Girl Scouts. Recipients of this award successfully demonstrate that an initiative they led had a positive impact on their community and furthered a cause that is dear to them. This demonstration of leadership and commitment to service is highly regarded by colleges. To learn more about this award visit: <a href='https://www.girlscouts.org/en/our-program/highest-awards/gold-award.html'>https://www.girlscouts.org/en/our-program/highest-awards/gold-award.html</a>
      </p>
      <p>
        <b>To earn:</b> To earn this micro-scholarship, you will enter the month and year in which the Girl Scouts of America awarded you the Gold Award.
      </p>
      `,
    ],
    title: 'Gold Award',
    category: 'honors-and-awards',
    portfolioUrl: {
      search: '?view=honor-awards',
      state: {skipScrolling: true},
    },
  },
  oppCommunityService: {
    rangeEnding: '',
    description: [
      `
      <p>
        <b>Why colleges care:</b>  Colleges award students who do community service because it shows compassion, strong character and leadership. Participation in community service is also a great way to develop empathy for groups of people and to identify which causes resonate the most with you.
      </p>
      <p>
        <b>To earn:</b> To earn this micro-scholarship, you will enter the name of the organization where you volunteered, the time frame you were involved, and the number of hours you served. Each college partner requires a minimum number of service hours in order to earn their award. Be sure to view each college's micro-scholarship page for specific details.
      </p>
      `,
    ],
    title: 'Volunteer in Your Community',
    subtitle: 'Volunteer in Your Community',
    category: 'community',
    portfolioUrl: {
      pathname: '/micro-scholarships/community-service/new',
      state: {skipScrolling: true},
    },
  },
  oppCollegeLevel: {
    rangeEnding: ' per course',
    description: [
      `
      <p>
        <b>Why colleges care:</b>  Taking classes at a nearby college is a great way to demonstrate your mastery of college level material. This gives assurance to the college that you'll be prepared and ready for the coursework at their institution once you're enrolled. Moreover, your grades in dual enrollment classes may count for credit at a 4-year institution, saving you time and money on your path to a degree.
      </p>
      <p>
        <b>To earn:</b>  To earn this micro-scholarship, you will enter 'dual enrollment' in course type, the name of the college where you took the course, the course name, and your grade.
      </p>
      `,
    ],
    title: 'Take a Dual Enrollment Course',
    category: 'course',
    portfolioUrl: {pathname: '/micro-scholarships/course/new', state: {skipScrolling: true}},
  },
  oppCollege101Event: {
    rangeEnding: ' per visit',
    description: ['Attend a "College 101" event, hosted by one of our participating colleges.'],
    title: 'Attend a College 101 Event',
    category: 'college',
    portfolioUrl: {
      pathname: '/micro-scholarships/college-success/college-event/college-reception/form',
      state: {skipScrolling: true},
    },
  },
  oppCollegeFair: {
    rangeEnding: ' per fair',
    description: [
      `
      <p>
        <b>Why colleges care:</b> A college fair is a great opportunity to learn about a lot of colleges at once. You'll be able to ask questions of college representatives and learn from them what makes their insitution unique. Conversations at college fairs are a great way to assess what you are looking for in a best fit college.
      </p>
      <p>
        <b>To earn:</b> To earn this micro-scholarship, you will specifiy the RaiseMe college partner that you met at a college fair, as well as the month and year of that meeting.
      </p>
      `,
    ],
    title: 'Attend a College Fair',
    category: 'college',
    portfolioUrl: {
      pathname: '/micro-scholarships/college-success/college-event/college-fair/form',
      state: {skipScrolling: true},
    },
  },
  oppApScore: {
    rangeEnding: ' per exam',
    description: [
      `
      <p>
        <b>Why colleges care:</b>  Taking an AP exam is a good proxy for how difficult the material will be in college. Scoring well on these exams is challenging and as such, many colleges will award you course credit. If you are awarded credit, that means you'll test out of a college course, saving you both time and money.
      </p>
      <p>
        <b>To earn:</b> To earn this micro-scholarship, enter the AP test name, the month and year you took the exam, and the score you received.
      </p>
      `,
    ],
    title: 'Take an AP exam',
    category: 'score',
    portfolioUrl: {
      pathname: '/micro-scholarships/add-test-score',
      search: '?tests=ap',
      state: {skipScrolling: true},
    },
  },
  oppHonors: {
    rangeEnding: ' per course',
    description: [
      `
      <p>
        <b>Why colleges care:</b>  While colleges like to see high grades, they also respect seeing that you are taking the challenging classes offered at your school. Taking Honors or Advanced shows a desire to push yourself academically and can help you stand out when applying to college.
      </p>
      <p>
        <b>To earn:</b>  To earn this micro-scholarship, you'll specify the honors or advanced course you took, as well as the grade you received.
      </p>
      `,
    ],
    title: 'Take an Honors or Advanced Course',
    category: 'course',
    portfolioUrl: {pathname: '/micro-scholarships/course/new', state: {skipScrolling: true}},
  },
  oppLeadershipActivity: {
    rangeEnding: ' per year',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Holding a leadership position in an extracurricular is really impressive to colleges for several reasons:
      </p>
      <ol>
        <li>
          Holding a leadership position is a lot of responsiblity, and your ability to juggle that and your academics is a great sign for how you can handle the many facets of college student life.
        </li>
        <li>
          Being in a leadership role is a testament to the respect you've earned from your peers. Colleges want to mold future leaders, and stepping up to that in high school is a big feather in your cap.
        </li>
      </ol>
      <p>
        <b>To earn:</b> To earn this micro-scholarship, you'll add the leadership position you held and the duration of time to an existing extracurricular activity on your portfolio.
      </p>
      `,
    ],
    title: 'Leadership Role in an Extracurricular Activity',
    category: 'extracurricular',
    portfolioUrl: {
      search: '?view=activity',
      state: {skipScrolling: true},
    },
  },
  oppMultimedia: {
    rangeEnding: ' per project',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Art and media projects are a great way to demonstrate your creativity and personality. Colleges want to enroll students who have a wide range of interests and who will contribute to the community on campus. Art, in any form, is a means of expression and colleges aim to foster an environment where all students have outlets to question and establish their identity.
      </p>
      <p>
        <b>To earn:</b> To earn this micro-scholarship, you can add links to any performing and visual artwork you've produced, including photos, video, presentations, websites, documents, and more.
      </p>
      `,
    ],
    title: 'Add an Art or Media Project',
    category: 'multimedia',
    portfolioUrl: {pathname: '/micro-scholarships/multimedia/new', state: {skipScrolling: true}},
  },
  oppPsatPreAct: {
    rangeEnding: '',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Many colleges require that you take either the SAT or ACT exam as part of your application to their institution. You are encouraged to take the PSAT as it is one of the best ways to practice for the SAT or ACT. Your performance on the PSAT will help you determine the preparation you will need to do your best on the SAT or ACT. Moreover, if you score well on the PSAT you may be eligible for the National Merit Scholarship program.
      </p>
      <p>
        <b>To earn: </b> To earn this micro-scholarship, you'll enter the month and year that you took the PSAT as well as your score.
      </p>
      `,
    ],
    title: 'Take the PSAT',
    subtitle: 'or PreACT',
    category: 'score',
    portfolioUrl: {
      pathname: '/micro-scholarships/add-test-score',
      search: '?tests=psat2015,preAct',
      state: {skipScrolling: true},
    },
  },
  oppSummerProgram: {
    rangeEnding: ' per summer program',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Pre-College summer programs offer a glimpse into life on campus, and enrolling in one can help you explore different areas of study, make new friends, and earn college credit along the way. They're also a way to help you prepare for college life, and get a sense for what it would be like to attend a college you might be considering. Many colleges offer micro-scholarships to students for taking this huge stride towards becoming college-ready!
      </p>
      <p>
        <b>To earn:</b> After enrolling in and attending a Pre-College summer program, enter the dates of the program you attended on a college's micro-scholarship page. Visit a college's website to learn more about their summer programs, when to apply, and whether or not they offer financial aid in case of hardship.
      </p>
      `,
    ],
    title: 'Attend a Summer Program',
    category: 'college',
    portfolioUrl: {
      pathname: '/micro-scholarships/college-success/college-event/summer-program/form',
      state: {skipScrolling: true},
    },
  },
  oppWorkExperience: {
    rangeEnding: ' per available',
    description: [
      `
      <p>
      <b>Why colleges care:</b> Working an after-school job or helping out at home demonstrates that you are capable of hard work and determination, particularly if those obligations prevent you from participating in extracurricular activities or community service.
      </p>
      <p>
      <b>To earn:</b> To earn this micro-scholarship, enter the type of work you did, the time range, and the number of hours per week into your portfolio.
      </p>
      `,
    ],
    title: 'Work Experience',
    subtitle: 'Earn for work experience or work done in family service',
    category: 'work-experience',
    portfolioUrl: {
      search: '?view=work-experience',
      state: {skipScrolling: true},
    },
  },
  oppForeignLanguage: {
    rangeEnding: ' per course',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Mastering a foreign language demonstrates a commitment to learning, and is an important achievement that can help you learn more about foreign countries and cultures.
      </p>
      <p>
        <b>To earn:</b> Take two or more years of foreign language courses to demonstrate your commitment to furthering your knowledge on the subject. Note that each college specifies the number of years you must complete to earn their award, the required grade average for each of the courses.
      </p>
      `,
    ],
    title: 'Take 2+ Foreign Language Courses',
    category: 'course',
    portfolioUrl: {pathname: '/micro-scholarships/course/new', state: {skipScrolling: true}},
  },
  oppEagleScout: {
    rangeEnding: '',
    description: [
      `
      <p>
        <b>Why colleges care:</b> The Eagle Scout Award represents the highest acheivement in the Boy Scouts of America. Recipients of this award successfully demonstrate that an initiative they led had a positive impact on their community and furthered a cause that is dear to them. To learn more about this award, visit: http://www.scouting.org/About/FactSheets/EagleScouts.aspx
      </p>
      <p>
        <b>To earn:</b> To earn this micro-scholarship, you will enter the month and year in which your earned the Eagle Scout Award.
      </p>
      `,
    ],
    title: 'Eagle Scout',
    category: 'honors-and-awards',
    portfolioUrl: {
      search: '?view=honor-awards',
      state: {skipScrolling: true},
    },
  },
  oppApIb: {
    rangeEnding: ' per course',
    description: [
      `
      <p>
        <b>Why colleges care:</b> The Advanced Placement (AP) and International Bacclaureate (IB) programs offer advanced, college-level coursework. Taking challenging coursework like AP or IB classes demonstrates high academic achievement to colleges, and if you score high on the official AP or IB exams at the end of the course, you could earn college credit.
      </p>
      <p>
        <b>To earn:</b> Enter the subject and year for the AP or IB coursework that you took into your portfolio.
      </p>
      `,
    ],
    title: 'Take an AP or IB Course',
    category: 'course',
    portfolioUrl: {pathname: '/micro-scholarships/course/new', state: {skipScrolling: true}},
  },
  oppActivity: {
    rangeEnding: ' per year',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Colleges love to see students who are adventurous and passionate about an activity outside of the classroom, whether that's a student organization, hobby, or something else. The goal isn't to join as many activities as you can. What's important is to show commitment to whatever you do and develop your skills through participation and leadership.
      </p>
      <p>
        <b>To earn:</b> Add your extracurricular activity, including the years that you were involved. Don't forget a short description to provide some more detail about your involvement.
      </p>
      `,
    ],
    title: 'Participate in an Extracurricular Activity',
    subtitle: '5 hrs/wk or 70 hrs/year',
    category: 'extracurricular',
    portfolioUrl: {
      search: '?view=activity',
      state: {skipScrolling: true},
    },
  },
  oppCourseSubject: {
    rangeEnding: '',
    title: 'Take 2+ Courses in a Subject',
    category: 'course',
    portfolioUrl: {pathname: '/micro-scholarships/course/new', state: {skipScrolling: true}},
  },
  oppFafsa: {
    rangeEnding: ' for completion',
    description: [
      `
      <p>
        <b>Why colleges care:</b> The Free Application for Federal Student Aid (FAFSA) is a critical form required for college students in order to qualify for federal and state financial aid. Many colleges also require that you complete the FAFSA to qualify for both need-based and merit-based aid from the institution. Start your FAFSA at fafsa.gov.
      </p>
      <p>
        <b>To earn:</b> Complete your FAFSA at fafsa.gov, and then enter your FSA ID (a number provided to you upon completion of the FAFSA) into your portfolio achievement.
      </p>
      `,
    ],
    title: 'Completing the FAFSA',
    category: 'fafsa',
    portfolioUrl: {
      pathname: '/micro-scholarships/college-success/fafsa/form',
      state: {skipScrolling: true},
    },
  },
  oppGradeA: {
    rangeEnding: ' per course',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Earning an A in a course demonstrates your mastery of a subject through hard work, and signals to colleges that you are capable of hard work.</p>
      </p>
      <p>
        <b>To earn:</b> After you earn your final course grade at the end of your term, enter the course name, the grade you earned, and the semester or term during which you earned the grade into your portfolio.
      </p>
      `,
    ],
    title: 'Get an A',
    category: 'course',
    portfolioUrl: {pathname: '/micro-scholarships/course/new', state: {skipScrolling: true}},
  },
  oppRegents: {
    rangeEnding: '',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Students attending high school in New York state must pass a minimum of 5 Regents exams with a minimum score of 65 in order to receive a Regents diploma. If you receive a 95 or higher (out of 100) on a Regents exam, this demonstrates academic achievement and can help distinguish you among other students.
      </p>
      <p>
        <b>To earn:</b> You can earn a micro-scholarship from select colleges on RaiseMe for scoring above a 95 on any Regents exam. To populate your micro-scholarships, navigate to the "Test Scores" section of your portfolio and type in the Regents exam name, date that you took the exam, and your final exam score.
      </p>
      `,
    ],
    title: 'Regents Score 95+',
    subtitle: '(NY)',
    category: 'score',
    portfolioUrl: {
      pathname: '/micro-scholarships/add-test-score',
      search: '?tests=other',
      state: {skipScrolling: true},
    },
  },
  oppCollegeReception: {
    rangeEnding: ' per event',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Many colleges and universities host events off-campus, like college fairs or college receptions, or other college events  in your area to help prospective students learn more about their institution. Attending an event hosted by a college or university can demonstrate your interest in learning more about that institution, and is a fantastic place to speak with a campus representative and get any of your questions about the institution answered.
      </p>
      <p>
        <b>To earn:</b> Navigate to the "College Readiness" section of your portfolio, select the type of event you attended, then enter the college name and month and date of the event you attended.
      </p>
      `,
    ],
    title: 'Attend an Off-Campus Event',
    category: 'college',
    portfolioUrl: {
      pathname: '/micro-scholarships/college-success/college-event/other-college-event/form',
      state: {skipScrolling: true},
    },
  },
  oppCollegeVisit: {
    rangeEnding: ' per visit',
    description: [
      `
      <p>
      <b>Why colleges care:</b> Visiting campus gives colleges an opportunity to meet you and learn about the real person behind your application, and allows you to figure out if a college could be a good fit for you. You'll see that every college has a different culture, and you'll want to find one where you can be challenged, successful, and happy.
      </p>

      <p>
      <b>To earn:</b> In the "College Readiness" category of your RaiseMe Portfolio, select "College Visit" to enter the month and year of your college visit.
      </p>
      `,
    ],
    title: 'Visit a College',
    category: 'college',
    portfolioUrl: {
      pathname: '/micro-scholarships/college-success/college-event/campus-visit/form',
      state: {skipScrolling: true},
    },
  },
  oppAssociateDegreeProgress: {
    rangeEnding: ' for completion',
    description: [
      `
      <p>
        <b>Why colleges care:</b> Completing your Associate degree (60 credits) streamlines the transfer process and helps you minimize the time and cost needed to earn your Bachelor's degree.
      </p>
      <p>
        <b>To earn:</b> You can earn this scholarship for indicating that you plan to complete your Associate degree (in your Portfolio or Account Settings) and demonstrating progress by adding a certain amount of credit hours to your Portfolio.
      </p>
      `,
    ],
    title: 'Associate Degree Progress',
    category: 'degree-progress',
    portfolioUrl: '/portfolio',
  },
};

cardTypeInfo['oppPopularActivity'] = {...cardTypeInfo['oppActivity']};

// assign order and group to all cards
cardSortOrder.map((cardType, index) => {
  if (!cardTypeInfo[cardType]) return;
  cardTypeInfo[cardType].rank = index;
  cardTypeInfo[cardType].key = cardType;
  cardTypeInfo[cardType].group = cardTypeInfo[cardType].group
    ? cardTypeInfo[cardType].group
    : 'discovery'; // using ActivityGroupings.discovery.key causes circular import
});

export default cardTypeInfo;
