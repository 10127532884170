import req from 'shared/req';
import {forEach, map, sortBy, startsWith} from 'lodash';
import {ActiveExperiments} from './ExperimentService';
import {ActivityGroupings} from 'shared/micro-scholarships/cardGroupings';
import cardTypeInfo from 'shared/micro-scholarships/cardTypeInfo';
import {getEarningText} from 'shared/micro-scholarships/utils';
import {Activity, CollegesOffering} from 'src/types/activities';
import TimeSensitiveItemTypeService from './TimeSensitiveItemTypeService';
import {addCollegeToCollection} from 'src/redux/studentReferralModal/actions';

function setActivityOrderAndGroup(
  treatment: string
): (activity: any, sortedOrder: number) => Activity {
  return (activity: any, sortedOrder: number): Activity => ({
    ...activity,
    cardInfo: {
      ...activity.cardInfo,
      rank:
        treatment === ActiveExperiments.PopularActivities.control
          ? cardTypeInfo['oppPopularActivity']['rank']
          : sortedOrder,
      group:
        treatment === ActiveExperiments.PopularActivities.control
          ? ActivityGroupings.discovery.key
          : ActivityGroupings.popular.key,
    },
  });
}

interface OpportunityCard {
  rangeEnding: string;
  description: string[];
  title: string;
  subtitle: string;
  category: string;
  portfolioUrl: string;
  group: string;
  rank: number;
  key: string;
}

interface MicroScholarship {
  minAmount: number;
  maxAmount: number;
  collegesOffering: CollegesOffering;
}

class TimeSensitiveItemTypeOpportunityCards {
  opportunityCardInfo: Record<string, OpportunityCard>;

  constructor() {
    this.opportunityCardInfo = {};
  }

  addCardInfoFromTimeSensitiveItemTypes = (timeSensitiveItemTypes: any[]): void => {
    timeSensitiveItemTypes.forEach((timeSensitiveItemType) => {
      this.opportunityCardInfo[`oppTimeSensitiveItem${timeSensitiveItemType._id}`] =
        this.cardInfoFromTimeSensitiveItemType(timeSensitiveItemType);
    });
  };

  cardInfoFromTimeSensitiveItemType(timeSensitiveItemType): OpportunityCard {
    return {
      rangeEnding: '', // this is what we do for the covid one
      description: [timeSensitiveItemType.detailedDescription],
      title: timeSensitiveItemType.title,
      subtitle: '',
      category: 'college',
      portfolioUrl: `/portfolio/college-success/time-sensitive/${timeSensitiveItemType._id}/form`,
      group: 'discovery',
      rank: 0,
      key: `oppTimeSensitiveItem${timeSensitiveItemType._id}`,
    };
  }
}

const timeSensitiveItemTypeOpportunityCards = new TimeSensitiveItemTypeOpportunityCards();

function formatOpportunity(opportunity: any, opportunityType: any): Activity | null {
  const opportunityName = opportunityType.charAt(0).toLowerCase() + opportunityType.substr(1);
  let cardInfo = timeSensitiveItemTypeOpportunityCards.opportunityCardInfo[opportunityName];

  if (!cardInfo) {
    cardInfo = cardTypeInfo[opportunityName];
  }

  if (!cardInfo) {
    console.warn(`[${opportunityType}] does not have cardInfo...removing`, opportunity);
    return null;
  }

  const detailUrlSegment = opportunityType.charAt(0).toUpperCase() + opportunityType.substr(1);

  return {
    ...opportunity,
    key: opportunityType,
    type: opportunityType,
    cardInfo,
    detailPagePath: `/micro-scholarships/${detailUrlSegment}`,
  };
}

export function formatHighSchoolActivity(activity: any): Activity {
  const {slug} = activity;

  const cardInfo = {
    ...cardTypeInfo['oppPopularActivity'],
    popularity: activity.popularity,
    schoolName: activity.schoolName,
    title: `Join ${activity.title}`,
    subtitle: getEarningText(activity.schoolName, activity.popularity),
  };

  return {
    _id: activity._id,
    key: slug,
    type: 'oppPopularActivity',
    cardInfo,
    minAmount: activity.minAmount,
    maxAmount: activity.maxAmount,
    studentAvatars: activity.studentAvatars,
    collegesOffering: activity.collegesOffering,
    detailPagePath: `/micro-scholarships/popular-activities/${slug}`,
    categories: activity.categories,
    claimed: activity.claimed,
    name: activity.name,
    popularity: activity.popularity,
    schoolName: activity.schoolName,
    slug: activity.slug,
    title: activity.title,
    portfolioItem: activity.portfolioItem,
    portfolioItemsCount: activity.portfolioItemsCount,
  };
}

export function formatPopularActivities(activities: any[], treatment: string): Activity[] {
  const formatted = activities.map(formatHighSchoolActivity);
  const sorted = sortBy(formatted, (card) => -card.popularity);
  const mapFn = setActivityOrderAndGroup(treatment);
  return sorted.map(mapFn);
}

const TIME_SENSITIVE_ITEM_TYPE_FIELDS = ['_id', 'detailedDescription', 'title'].join(',');

const OPP_TIME_SENSITIVE_ITEM = 'oppTimeSensitiveItem';

const isTimeSensitiveOpportunity = (opportunityType): boolean => {
  const opportunityName = opportunityType.charAt(0).toLowerCase() + opportunityType.substr(1);

  return startsWith(opportunityName, OPP_TIME_SENSITIVE_ITEM);
};

/* eslint-enable @typescript-eslint/no-explicit-any */

export default class MicroScholarshipService {
  static async getOpportunityCards(): Promise<Activity[]> {
    const opportunities: OpportunityCard[] = await req({
      url: '/v1/micro-scholarships/opportunity-cards',
    });

    const timeSensitiveItemTypeIds: string[] = [];

    Object.keys(opportunities).forEach((opportunityType) => {
      if (isTimeSensitiveOpportunity(opportunityType)) {
        timeSensitiveItemTypeIds.push(opportunityType.replace('oppTimeSensitiveItem', ''));
      }
    });

    if (timeSensitiveItemTypeIds.length) {
      const timeSensitiveItemTypes = await TimeSensitiveItemTypeService.index({
        timeSensitiveItemTypeIds: timeSensitiveItemTypeIds.join(','),
        fields: TIME_SENSITIVE_ITEM_TYPE_FIELDS,
      });

      timeSensitiveItemTypeOpportunityCards.addCardInfoFromTimeSensitiveItemTypes(
        timeSensitiveItemTypes
      );
    }

    return map(opportunities, formatOpportunity).filter(Boolean) as Activity[];
  }

  static async getOpportunityCard(type: string): Promise<Activity | null> {
    const url = `/v1/micro-scholarships/opportunity-cards/${type}`;
    const response = await req({url, method: 'GET'});

    if (isTimeSensitiveOpportunity(type)) {
      const timeSensitiveItemType = await TimeSensitiveItemTypeService.show(
        type.replace('OppTimeSensitiveItem', ''),
        {fields: TIME_SENSITIVE_ITEM_TYPE_FIELDS}
      );

      timeSensitiveItemTypeOpportunityCards.addCardInfoFromTimeSensitiveItemTypes([
        timeSensitiveItemType,
      ]);
    }

    const opportunity = formatOpportunity(response, type);
    return opportunity;
  }

  static async getMicroScholarship(
    scholarshipType
  ): Promise<{participatingColleges: never[]; minAmount: number; maxAmount: number}> {
    const participatingColleges = [];
    const microScholarships = await req<MicroScholarship>({
      url: `/v1/micro-scholarships/${scholarshipType}`,
    });

    forEach(microScholarships.collegesOffering.followed, (college) => {
      addCollegeToCollection(participatingColleges, college, true);
    });

    forEach(microScholarships.collegesOffering.unfollowed, (college) => {
      addCollegeToCollection(participatingColleges, college);
    });

    return {
      participatingColleges,
      minAmount: microScholarships.minAmount,
      maxAmount: microScholarships.maxAmount,
    };
  }

  static async getPopularActivityBySlug(slug: string): Promise<Activity> {
    const url = `/v1/micro-scholarships/popular-activities/${slug}`;
    const response = await req({url});
    const activity = formatHighSchoolActivity(response);
    return activity;
  }

  static async getPopularActivities(experimentTreatment: string): Promise<Activity[]> {
    let activities: Activity[] = [];
    try {
      const response: string[] = await req({
        url: '/v1/micro-scholarships/popular-activities',
      });

      activities = formatPopularActivities(response, experimentTreatment);
    } catch (error) {
      console.error('Failed getting activities', error);
    }

    return activities;
  }
}
