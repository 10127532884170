import PropTypes from 'prop-types';
import React from 'react';
import Text from 'shared/components/text';
import {withCommas} from 'shared/utils';

class TotalEarningsText extends React.Component {
  static propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  render() {
    const {amount} = this.props;

    return (
      <div className="shared-college-card-status-text-earnings">
        <span className="shared-college-card-status-text-subtext">
          <Text color="darkgrey" weight={6} inline>
            Earnings upon admission
          </Text>
        </span>
        <Text weight={2} color="green" emphasis inline>
          ${withCommas(Math.round(amount))}
        </Text>
      </div>
    );
  }
}

export default TotalEarningsText;
