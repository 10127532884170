import React, {ReactElement} from 'react';
import {Formik, Form} from 'formik';
import FormButton from 'src/components/Button';
import FormikTextField from 'src/components/FormikTextField';
import Label from 'shared/components/label';
import {isEmpty} from 'lodash';
import {Link} from 'react-router-dom';
import {TextFieldProps} from './FormikTextField/FormikTextField';
import styles from './reset-password-base-form.module.scss';

interface ResetPasswordBaseFormProps {
  fieldName: string;
  handleSubmit: (values: object, actions: object) => Promise<void>;
  validate: (values) => object;
  handleChange?: TextFieldProps['onChange'];
  successMessage?: string | null;
  placeholder: string;
  label: string;
  fieldType?: string;
}

const ResetPasswordBaseForm = (props: ResetPasswordBaseFormProps): ReactElement => (
  <Formik
    initialValues={{[props.fieldName]: ''}}
    onSubmit={props.handleSubmit}
    validate={props.validate}
    validateOnBlur
  >
    {(formikProps): ReactElement => (
      <Form data-testid="reset-password-form">
        <div className="shared-font caption">* Required Field</div>
        <Label required>
          {props.label}
          <FormikTextField
            type={props.fieldType || 'text'}
            name={props.fieldName}
            placeholder={props.placeholder}
            aria-label={props.placeholder}
            onChange={props.handleChange}
          />
        </Label>
        {props.successMessage && isEmpty(formikProps.errors) && (
          <div className={styles.success}>{props.successMessage}</div>
        )}
        <FormButton
          testId="reset-password-button"
          size="large"
          type="submit"
          width="100%"
          disabled={formikProps.isSubmitting}
        >
          {formikProps.isSubmitting ? 'Submitting...' : 'Submit'}
        </FormButton>
        <div className="shared-font body">
          Don&#39;t have an account? <Link to="/signup">Sign Up here.</Link>
        </div>
      </Form>
    )}
  </Formik>
);

export default ResetPasswordBaseForm;
