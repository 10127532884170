import {connect} from 'react-redux';
import Filters from 'src/components/CollegeSearch/Filters/Filters';
import {resetPage, getColleges} from 'src/redux/collegeSearch/actions';

const mapDispatchToProps = (dispatch) => ({
  getColleges() {
    dispatch(resetPage());
    dispatch(getColleges());
  },
});

export default connect(null, mapDispatchToProps)(Filters);
