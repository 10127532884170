import React, {useLayoutEffect, useRef, useState} from 'react';
import classnames from 'classnames';

import {useResizeObserver} from 'src/hooks/useResizeObserver';
import {useMediaQuery} from 'src/hooks/useMediaQuery';
import {useModalContext} from '.';

import './body.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Body = ({children, className}: Props) => {
  const mq = useMediaQuery();
  const bodyRef = useRef<HTMLDivElement>(null);
  const {height} = useResizeObserver(bodyRef);
  const [maxBodyHeight, setMaxBodyHeight] = useState('auto');
  const [bodyHeight, setBodyHeight] = useState('auto');
  const {bodyOverflow, setBodyOverflow, headerHeight, footerHeight, hasTextHeader} =
    useModalContext();

  useLayoutEffect(() => {
    if (bodyRef.current) {
      if (mq.SM_AND_UP) {
        // 576px is the modal max height per Aptitude
        setMaxBodyHeight(`${576 - headerHeight - footerHeight}px`);
        setBodyHeight('auto');
      } else {
        const maxHeight = `calc(100dvh - ${headerHeight}px - ${footerHeight}px)`;

        setMaxBodyHeight(maxHeight);
        setBodyHeight(maxHeight);
      }

      setBodyOverflow(bodyRef.current.scrollHeight > height);
    }
  }, [
    footerHeight,
    headerHeight,
    setBodyOverflow,
    mq.SM_AND_UP,
    height,
    bodyRef.current?.scrollHeight,
  ]);

  return (
    <div
      ref={bodyRef}
      className={classnames('shared-modal-body', className, {
        'no-overflow': hasTextHeader && !bodyOverflow,
      })}
      style={{
        maxHeight: maxBodyHeight,
        height: bodyHeight,
      }}
    >
      {children}
    </div>
  );
};

export default Body;
