import {RECEIVE_FEATURE_FLAGS, GET_FEATURE_FLAGS} from 'shared/actions/feature-flags';

export default (state = {flags: {}, loaded: false, loading: false}, action) => {
  switch (action.type) {
    case GET_FEATURE_FLAGS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_FEATURE_FLAGS:
      return {
        ...state,
        flags: {
          ...state.flags,
          ...action.flags,
        },
        loaded: true,
        loading: false,
      };
    default:
      return state;
  }
};
