import PropTypes from 'prop-types';
import React from 'react';
import MajorsTypeaheadFilter from 'src/components/CollegeSearch/Filters/MajorsTypeaheadFilter';

class MajorsFilters extends React.Component {
  static propTypes = {
    getColleges: PropTypes.func.isRequired,
  };

  render() {
    const {getColleges} = this.props;

    return (
      <div className="college-search-filters-section">
        <fieldset role="group" aria-label="Filter by Majors">
          <legend className="college-search-filters-section-legend">
            <label className="college-search-filters-section-label" htmlFor="typeahead-major">
              Major
            </label>
          </legend>
          <div className="college-search-filters-section-typeahead">
            <MajorsTypeaheadFilter onChange={getColleges} />
          </div>
        </fieldset>
      </div>
    );
  }
}

export default MajorsFilters;
