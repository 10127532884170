import {startsWith} from 'lodash';

export const SET_DISABILITY = 'SET_DISABILITY';
export const ADD_DISABILITY = 'ADD_DISABILITY';
export const REMOVE_DISABILITY = 'REMOVE_DISABILITY';
export const SET_DISABILITIES = 'SET_DISABILITIES';

export const addDisability = () => ({type: ADD_DISABILITY});

export const setDisability = (disability, index) => ({type: SET_DISABILITY, disability, index});

export const removeDisability = (index) => ({type: REMOVE_DISABILITY, index});

export const setDisabilities = (disabilities) => ({type: SET_DISABILITIES, disabilities});

export const formatDisabilitiesToSave = (disabilities) => {
  const formatter = (disability) =>
    disability.type === 'Other' ? `Other: ${disability.display}` : disability.display;

  return Array.isArray(disabilities) ? disabilities.map(formatter) : null;
};

export const formatDisabilitiesFromStudent = (disabilities) => {
  const formatter = (disability) => {
    if (startsWith(disability, 'Other:')) {
      return {type: 'Other', display: disability.split('Other:')[1].trim()};
    } else {
      return {type: disability, display: disability};
    }
  };

  return Array.isArray(disabilities) ? disabilities.map(formatter) : null;
};
