import React from 'react';
import PropTypes from 'prop-types';
import {map} from 'lodash';
import AccessibleTypeahead from 'shared/components/accessible-typeahead';

class TagsTypeaheadFilter extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    fetchTags: PropTypes.func.isRequired,
    tags: PropTypes.array,
  };

  componentDidMount() {
    this.props.fetchTags();
  }

  render() {
    const {onSelect, tags} = this.props;
    const tagNames = map(tags, 'name');
    return (
      <div className="tags-typeahead">
        <AccessibleTypeahead
          id="tags-typeahead"
          options={tagNames}
          onSelect={onSelect}
          placeholder="Enter tag"
          inputProps={{id: 'typeahead-tag'}}
          minLength={0}
        />
      </div>
    );
  }
}

export default TagsTypeaheadFilter;
