import browserHistory from 'shared/history';
import {
  fetchFeatureInteraction,
  changeFeatureInteraction,
} from 'shared/actions/feature-interactions';
import {camelCase} from 'lodash';

// the featureInteractions reducer camelcases the ID, so also camelcasing the ID here to match
export const programEndedKey = (id) => `programEnded${id ? camelCase(id) : null}`;
export const MODAL = 'modal';
export const BANNER = 'banner';

export const showProgramEndedModal = () => () =>
  browserHistory.push(`${location.pathname}/program-ended`);

export const closeProgramEndedModal = () => (dispatch, getState) => {
  const {
    college: {programId},
  } = getState();
  dispatch(changeFeatureInteraction(programEndedKey(programId), {[MODAL]: true}));
  return browserHistory.push(location.pathname.split('/program-ended')[0]);
};

export const fetchProgramEndedFeatureInteraction = (id) => (dispatch) =>
  dispatch(fetchFeatureInteraction(programEndedKey(id), [MODAL, BANNER]));

export const closeProgramEndedBanner = () => (dispatch, getState) => {
  const {
    college: {programId},
  } = getState();
  return dispatch(changeFeatureInteraction(programEndedKey(programId), {[BANNER]: true}));
};
