import {RECEIVE_FOLLOWED_COLLEGES} from '../actions/college-application-status-modal';

function followedCollegesReducer(state = [], action) {
  switch (action.type) {
    case RECEIVE_FOLLOWED_COLLEGES:
      return action.payload;
    default:
      return state;
  }
}

export default followedCollegesReducer;
