import PropTypes from 'prop-types';
import React from 'react';

class Criterion extends React.Component {
  static propTypes = {
    criterionType: PropTypes.string.isRequired,
    displayString: PropTypes.string.isRequired,
  };

  render() {
    const {criterionType, displayString} = this.props;

    return (
      <div className="flex-row">
        <span>{criterionType}</span>
        <strong>{displayString}</strong>
      </div>
    );
  }
}

export default Criterion;
