import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'src/components/tooltip';

const propTypes = {
  children: PropTypes.element.isRequired,
};

const NewTabTooltip = ({children}) => <Tooltip title="Opens in new tab">{children}</Tooltip>;

NewTabTooltip.propTypes = propTypes;

export default NewTabTooltip;
