import {merge, omit} from 'lodash';
import {camelizeKeys} from 'humps';
import {normalize} from 'normalizr';
import {collegeCourse as courseSchema} from 'shared/portfolio/schemas/college-courses';
import {portfolioUrl} from 'shared/portfolio/utils';
import req, {rejectCamelizedErrors, rejectGenericError} from 'shared/req';
import {objectId} from 'shared/utils';

export const NEW_COLLEGE_COURSE = 'NEW_COLLEGE_COURSE';
export const SAVE_COLLEGE_COURSE = 'SAVE_COLLEGE_COURSE';
export const EDIT_COLLEGE_COURSE = 'EDIT_COLLEGE_COURSE';
export const CANCEL_EDIT_COLLEGE_COURSE = 'CANCEL_EDIT_COLLEGE_COURSE';
export const COLLEGE_COURSE_DELETED = 'COLLEGE_COURSE_DELETED';
export const SCROLL_TO_COLLEGE_COURSE = 'SCROLL_TO_COLLEGE_COURSE';
export const SCROLLED_TO_COLLEGE_COURSE = 'SCROLLED_TO_COLLEGE_COURSE';
export const RECEIVE_COLLEGE_COURSE_INSTITUTION_GRADES_PRESELECTS =
  'RECEIVE_COLLEGE_COURSE_INSTITUTION_GRADES_PRESELECTS';
export const RECEIVE_COLLEGE_COURSES = 'RECEIVE_COLLEGE_COURSES';

export function newCollegeCourse(preselects, options = {}) {
  const values = merge(preselects, options);
  const course = {
    _id: objectId(),
    year: values.year,
    semester: values.semester,
    courseCode: '',
    name: '',
    subject: '',
    creditHours: null,
    grade: {
      gradeInput: null,
      gradeType: values.gradeType,
      period: 'F',
      enabled: true,
      _id: objectId(),
    },
    institution: values.institutionId,
    editingInstitution: false,
    editing: true,
    new: true,
    scrollTo: !!values.scrollTo,
  };

  return {
    type: NEW_COLLEGE_COURSE,
    id: `college-course-${course._id}`,
    course,
  };
}

export function cancelCollegeCourse(course) {
  return {
    type: CANCEL_EDIT_COLLEGE_COURSE,
    id: `college-course-${course._id}`,
    course,
  };
}

export function deleteCollegeCourse(id) {
  return (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(`/college_courses/${id}`, _id),
      method: 'DELETE',
    }).then(() => {
      dispatch({
        type: COLLEGE_COURSE_DELETED,
        id: `college-course-${id}`,
      });
    });
  };
}

export function editCollegeCourse(course) {
  return {
    type: EDIT_COLLEGE_COURSE,
    id: `college-course-${course._id}`,
    course,
  };
}

export function scrolledToCollegeCourse(id) {
  return {
    type: SCROLLED_TO_COLLEGE_COURSE,
    id: `college-course-${id}`,
  };
}

export function scrollToCollegeCourse(id) {
  return {
    type: SCROLL_TO_COLLEGE_COURSE,
    id: `college-course-${id}`,
  };
}

export const saveCollegeCourse = (course) => (dispatch, getState) => {
  let url;
  let method;
  if (course.new) {
    url = '/college_courses';
    method = 'POST';
  } else {
    url = `/college_courses/${course._id}`;
    method = 'PATCH';
  }

  const gradeData = merge({}, course.grade, {
    gradeInput: course.grade.gradeInput === 'I' ? 'INC' : course.grade.gradeInput,
  });

  const omittedFields = ['editingInstitution', 'new', 'grade'];

  const courseData = merge({}, omit(course, omittedFields), {grade: gradeData});

  const {
    student: {_id},
  } = getState();
  return req({
    url: portfolioUrl(url, _id),
    method: method,
    data: courseData,
  })
    .then((resp) => {
      const {course: courseResponse} = resp;

      const normalizedCourse = normalize(courseResponse, courseSchema);
      const id = normalizedCourse.result;
      const preselects = camelizeKeys(resp.preselects);
      preselects.institutionId = `inst-${preselects.institutionId}`;
      const receivedCourse = normalizedCourse.entities.collegeCourses[id];

      dispatch({
        type: RECEIVE_COLLEGE_COURSE_INSTITUTION_GRADES_PRESELECTS,
        id,
        course: receivedCourse,
        institutions: normalizedCourse.entities.institutions || {},
        grades: normalizedCourse.entities.grades || {},
        preselects: {},
      });
    })
    .catch(rejectCamelizedErrors)
    .catch(rejectGenericError);
};
