import {fetchCollegesForSearch} from '../search';

export const RECEIVE_COLLEGE_SEARCH_DATA = 'RECEIVE_COLLEGE_SEARCH_DATA';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const RECEIVE_RESULTS = 'RECEIVE_RESULTS';
export const UPDATE_SELECTED = 'UPDATE_SELECTED';
export const RESET_SEARCH = 'RESET_SEARCH';

export const updateQuery = (query) => ({
  type: UPDATE_QUERY,
  query,
});

export const receiveResults = (results) => ({
  type: RECEIVE_RESULTS,
  results,
});

export const updateSelected = (selected) => ({
  type: UPDATE_SELECTED,
  selected,
});

export const resetSearch = () => ({
  type: RESET_SEARCH,
});

const receiveCollegeSearchData = (collegeSearchBarData) => ({
  type: RECEIVE_COLLEGE_SEARCH_DATA,
  collegeSearchBarData,
});

export const fetchCollegeSearchBarData =
  (collegeFormat = 'array') =>
  (dispatch) =>
    fetchCollegesForSearch(collegeFormat).then((collegeSearchData) => {
      dispatch(receiveCollegeSearchData(collegeSearchData));
    });
