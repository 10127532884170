import PropTypes from 'prop-types';
import React from 'react';
import CreditHoursStatusText from './credit-hours-status-text';
import ProgressCard from './progress-card';
import InfoToolTip from 'shared/components/info-tooltip';

const ctaLink = {
  attributes: {
    href: '/portfolio#courses',
    'aria-label': 'Go to your portfolio and add a course',
  },
  text: 'Add',
};

class CreditHoursCard extends React.Component {
  static propTypes = {
    collegeProfile: PropTypes.object.isRequired,
    totalCredits: PropTypes.number.isRequired,
    requiredCredits: PropTypes.number.isRequired,
  };

  render() {
    const {collegeProfile, totalCredits, requiredCredits} = this.props;
    const title = (
      <span>
        Credit Hours Completed
        <InfoToolTip className="college-profile-tooltip" margin>
          The number of community college credit hours or units you need to add to your RaiseMe
          Portfolio to lock in your earnings from this college.
        </InfoToolTip>
      </span>
    );
    const text = (
      <CreditHoursStatusText
        collegeProfile={collegeProfile}
        totalCredits={totalCredits}
        requiredCredits={requiredCredits}
      />
    );

    return (
      <ProgressCard
        title={title}
        completed={totalCredits}
        required={requiredCredits}
        text={text}
        ctaLink={ctaLink}
      />
    );
  }
}

export default CreditHoursCard;
