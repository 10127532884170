import React, {useEffect} from 'react';
import styles from './styles.module.scss';
import {useDispatch} from 'react-redux';
import {setNavigationAnnouncement} from '../../../shared/actions/accessibility-announcement';

const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationAnnouncement('Privacy Policy'));
  }, [dispatch]);

  return (
    <div className={styles.root} role="main" id="main" tabIndex={-1}>
      <section className="section">
        <div className="container">
          <p>
            <span>CampusLogic Privacy Policy</span>
          </p>
          <p>
            <span>Last Updated: March 23, 2022</span>
          </p>
          <p>
            <span>INTRODUCTION</span>
          </p>
          <p>
            CampusLogic, Inc. (“<span>CampusLogic</span>,” “<span>we</span>,” and “<span>our</span>
            ”) is committed to protecting the privacy of all individuals (“
            <span>user</span>” or “<span>you</span>”) who visit and use this website
            <a href="https://campuslogic.com/">www.campuslogic.com</a> or any other website that we
            own or control and which posts to this Privacy Policy (the “<span>Site</span>”) and/or
            the software applications (the “<span>Applications</span>”) provided by CampusLogic
            referred to collectively herein as the “<span>Services.</span>” This Privacy Policy (the
            “<span>Privacy Policy</span>”) describes how CampusLogic collects, uses, shares and
            safeguards personal information we collect when you use or interact with the Site or the
            Services. Please read the following information carefully to understand our views and
            practices regarding your personal information and how we will treat it. We also provide
            a&nbsp;
            <span className="underline">
              supplementary privacy notice for users of the RaiseMe Service
            </span>
            &nbsp;and&nbsp;
            <span className="underline">
              a supplementary privacy notice for California residents.
            </span>
          </p>
          <p>
            This Privacy Policy does not apply to our processing of personal information provided to
            CampusLogic by our customers or otherwise collected by CampusLogic at the direction of
            customers (including, but not limited to, when we are acting as a “school official” on
            behalf of an educational institution), and which we process on behalf of our customers
            in our capacity as a service provider / processor. Our processing of that information is
            governed by our agreement with our customers.
          </p>
          <p>
            If you registered to use the Services, you will have been asked to click to agree to
            provide this information in order to access our Services or otherwise view our content
            on the Site.&nbsp;
            <span>
              If you do not agree to our use of your personal information in line with this Privacy
              Policy, please do not use our Services. By accessing and using our Site or by clicking
              “I accept” prior to your registration to the Services, you consent to the collection,
              use, and disclosure of your personal information by CampusLogic as described in this
              Privacy Policy.
            </span>
          </p>
          <p>
            We reserve the right to make changes to this Privacy Policy at any time, so we encourage
            you to review it frequently. If we change this Privacy Policy, we will post such changes
            to the Site and other places in the Applications we deem appropriate. The “Last Updated”
            legend above indicates when this Privacy Policy was last changed. If we make any
            material changes to the Privacy Policy, we will post the updated Privacy Policy on our
            Site prior to such changes taking effect. If required by law, we will also provide
            notification of material changes in another way that we believe is reasonably likely to
            reach you, such as via email or another manner through the Applications.
          </p>
          <p>
            <span>1. COLLECTION OF INFORMATION</span>
          </p>
          <p>
            CampusLogic collects and retains personal information about you in the following ways:
          </p>
          <p>
            <span>Information You Give Us.</span> This includes the personal information you
            voluntarily submitted to CampusLogic through the Site and/or Services, such as when you
            register to use our Services, including your name, postal address, email address, and
            other personal and unique information provided to CampusLogic based on your submissions
            and responses to questions asked of you throughout the Services.
          </p>
          <p>
            You may also share personal information with us when you submit user generated content
            through our Services. Please note that any information you post or feedback you disclose
            to CampusLogic through the Site or on our Services will not be private or confidential
            once it is published on Site or through our Services. If you provide feedback to us, we
            may use and disclose such feedback in our Services or on our Site without any reference
            to you.
          </p>
          <p>
            <span>Information Automatically Collected.</span> We automatically log information about
            you and your computer or mobile device when you access our Site and/or Services. Please
            refer to the sections on Tracking Technologies below.
          </p>
          <p>
            <span>2. USE OF INFORMATION</span>
          </p>
          <p>
            Having accurate information about you permits us to provide you with a smooth,
            efficient, and customized experience. Specifically, we may use your personal information
            as follows:
          </p>
          <ul>
            <li>
              <span>Service delivery</span>, including:
              <ol>
                <li>To fulfill a specific request by you.</li>
                <li>To operate, maintain, and improve our Site and Services.</li>
                <li>
                  To manage your account, including to communicate with you regarding your account
                  with our Services.
                </li>
                <li>To respond to your comments and questions and to provide customer service.</li>
                <li>
                  To send information including technical notices, updates, security alerts, and
                  support and administrative messages.
                </li>
              </ol>
            </li>
            <li>
              <span>Marketing and advertising.</span> We may send you emails about upcoming events,
              promotions, and other news as permitted by law. You may opt out of our email marketing
              communications as described in the Opt-out of marketing communications section below.
              Please note that even if you opt out from receiving marketing emails, we may still
              send you non-marketing emails, which include emails about your account with us and in
              connection with your use of the Services.
            </li>
            <li>
              <span>Compliance and protection.</span> We may use your personal information as we
              believe necessary or appropriate (i) to comply with applicable laws; (ii) to comply
              with lawful requests and legal process, including to respond to requests from public
              and government authorities; (iii) to enforce our Privacy Policy; and (iv) to protect
              our rights, privacy, safety or property, and/or that of you or others.
            </li>
            <li>
              <span>Research, Development and Services improvement.</span> We may use your personal
              information for research and development purposes, including to analyze and improve
              the Services and our business. As part of these activities, we may create aggregated,
              de-identified or other anonymous data from personal information we collect. We may use
              this anonymous data and share it with third parties for our lawful business purposes,
              including to analyze and improve the Services.
            </li>
          </ul>
          <p>
            <span>3. DISCLOSURE OR SHARING OF INFORMATION</span>
          </p>
          <p>We may share your personal information as follows:</p>
          <ul>
            <li>
              <span>Third Parties Designated by You.</span> We may share your personal information
              with third parties where you have provided your consent to do so.
            </li>
            <li>
              <span>Our Third-Party Service Providers.</span> We may share your personal information
              with our third-party service providers who provide services such as information
              technology and related infrastructure, auditing, email delivery, and other similar
              services. These third parties are only permitted to use your personal information to
              the extent necessary to enable them to provide their services to CampusLogic.
            </li>
            <li>
              <span>Corporate Restructuring.</span> We may share personal information when we do a
              business deal, or negotiate a business deal, involving the sale or transfer of all or
              a part of our business or assets. These deals can include any merger, financing,
              acquisition, or bankruptcy transaction or proceeding.
            </li>
            <li>
              <span>Other Disclosures.</span> We may share personal information as we believe
              necessary or appropriate: (a) to comply with applicable laws; (b) to comply with
              lawful requests and legal process, including to respond to requests from public and
              government authorities to meet national security or law enforcement requirements; (c)
              to enforce our Policy; and (d) to protect our rights, privacy, safety or property,
              and/or that of you or others.
            </li>
          </ul>
          <p>
            <span>4. TRACKING TECHNOLOGIES</span>
          </p>
          <p>
            We may collect information using “cookies” and similar technologies. Cookies are small
            data files stored on the hard drive of your computer or mobile device by a website. We
            may use both session cookies (which expire once you close your web browser) and
            persistent cookies (which stay on your computer or mobile device until you delete them)
            to provide you with a more personal and interactive experience in the Services.
          </p>
          <p>
            <span>Cookies We Use.</span> Our Services use the following types of cookies for the
            purposes set out below:
          </p>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Type</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Essential Cookies</th>
                <td>
                  <p>
                    These cookies are essential to provide you with services available through our
                    Site and to enable you to use some of its features. For example, they allow you
                    to log in to secure areas of our Services. Without these cookies, the services
                    that you have asked for cannot be provided, and we only use these cookies to
                    provide you with those services. These cookies are only used for the duration of
                    your session accessing the Services and are removed following completion of the
                    session.
                  </p>
                </td>
              </tr>
              <tr>
                <th scope="row">Functionality Cookies</th>
                <td>
                  <p>
                    These cookies allow us to remember choices you make when you use our Services,
                    such as remembering your login details. The purpose of these cookies is to
                    provide you with a more personal experience and to avoid you having to re-enter
                    your preferences every time you visit our Services.
                  </p>
                </td>
              </tr>
              <tr>
                <th scope="row">Analytics and Performance Cookies</th>
                <td>
                  <p>
                    These cookies are used to collect information about traffic to our Site and how
                    users use our Services. The information gathered does not identify any
                    individual visitor. The information is aggregated. It includes the number of
                    visitors to the Site, the pages they visited on our Site, whether they have
                    visited our Site before, and other similar information. We use this information
                    to help operate our Site more efficiently, to gather broad demographic
                    information and to monitor the level of activity on our Site.
                  </p>
                  <p>
                    We use Google Analytics for this purpose. Google Analytics uses its own cookies.
                    It is only used to improve how our Site/Application(s)/Services works. You can
                    find out more information about Google Analytics cookies here:
                    <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">
                      https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
                    </a>
                    .
                  </p>
                  <p>
                    You can find out more about how Google protects your data here:
                    <a href="https://www.google.com/analytics/learn/privacy.html">
                      www.google.com/analytics/learn/privacy.html
                    </a>
                    .
                  </p>
                  <p>
                    You can prevent the use of Google Analytics relating to your use of [our
                    Site/Application(s)/Services] by downloading and installing the browser plugin
                    available via this link:
                    <a href="http://tools.google.com/dlpage/gaoptout?hl=en-GB">
                      http://tools.google.com/dlpage/gaoptout?hl=en-GB
                    </a>
                    .
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <span>Other Technologies.</span> We may also use other technologies, such as local
            storage technologies like HTML5, that provide cookie-equivalent functionality but can
            store larger amounts of data, including on your device outside of your browser in
            connection with specific applications, for analytics and marketing purposes.
          </p>
          <p>
            <span>Disabling Cookies.</span> You can typically remove or reject cookies via your
            browser settings. Further information about cookies, including how to see what cookies
            have been set on your computer or mobile device and how to manage and delete them, visit
            <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>. If you do not
            accept our cookies, you may experience some inconvenience in your use of the Site or our
            Services.
          </p>
          <p>
            <span>Do Not Track Signals.</span> Some browsers may be configured to send “Do Not
            Track” signals to the online services that you visit. We do not currently respond to “Do
            Not Track” or similar signals. To find out more about &quot;Do Not Track&quot;, please
            visit
            <a href="http://www.allaboutdnt.com/">http://www.allaboutdnt.com</a>.
          </p>
          <p>
            <span>5. THIRD PARTY WEBSITES</span>
          </p>
          <p>
            Our Site may contain links to third party websites and features. This Privacy Policy
            does not cover the privacy practices of such third parties. These third parties have
            their own privacy policies and we do not accept any responsibility or liability for
            their websites, features or policies. We recommend that you read their privacy policies
            before you submit any data to them.
          </p>
          <p>
            If you elect to use the SponsoredScholar services, you will enter into an agreement with
            our payments partner, WePay, Inc., for the payments service and WePay&apos;s use of your
            data. Please review the WePay Privacy Policy (
            <a href="https://go.wepay.com/privacy-policy-us">
              https://go.wepay.com/privacy-policy-us
            </a>
            ), which will apply to you.
          </p>
          <p>
            <span>6. SECURITY OF INFORMATION</span>
          </p>
          <p>
            We seek to use commercially reasonable organizational, technical and administrative
            measures to protect personal information within our organization. However, no Internet
            or email transmission is ever fully secure or error free. In particular, email sent to
            or from us may not be secure. Therefore, you should take special care in deciding what
            information you send to us via the Internet. Please keep this in mind when disclosing
            any personal information to us via the Internet. In addition, we are not responsible for
            circumvention of any privacy settings or security measures contained on the
            Applications, or third party websites. Additionally, you must keep your account password
            confidential and not disclose it publicly or to unauthorized individuals. If you have
            reason to believe that your interaction with us is no longer secure or your password was
            compromised or misused, please immediately notify us by contacting us at
            <a href="mailto:privacy@campuslogic.com">privacy@campuslogic.com</a>.
          </p>
          <p>
            We will only retain your personal information as long as reasonably required to provide
            you with the Services unless a longer retention period is required or permitted by
            applicable law.
          </p>
          <p>
            <span>7. PROCESSING OF PERSONAL INFORMATION IN THE UNITED STATES</span>
          </p>
          <p>
            CampusLogic is headquartered in the United States. Your personal information may be
            transferred, in accordance with this Privacy Policy, outside the country in which you
            reside, including to the United States, where data protection and privacy regulations
            may not offer the same level of protection as your home country or territory. We will
            take reasonable steps to protect your personal information in accordance with this
            Privacy Policy or as otherwise disclosed to you.
          </p>
          <p>
            <span>8. POLICY FOR CHILDREN</span>
          </p>
          <p>
            Neither our Site nor our Services are intended for use by children under 13 years of
            age. If a parent or guardian becomes aware that his or her child has provided us with
            information without their consent, he or she should contact us directly at{' '}
            <a href="mailto:privacy@campuslogic.com">privacy@campuslogic.com</a>.
          </p>
          <p>
            <span>9. OPTIONS REGARDING YOUR INFORMATION</span>
          </p>
          <p>
            You have several choices regarding our use of your information, including the following:
          </p>
          <ol>
            <li>
              Opt-out of marketing communications. You can opt out of receiving marketing-related
              communications by clicking on the unsubscribe link contained in the communications we
              send you or contacting us at
              <a href="mailto:privacy@campuslogic.com">privacy@campuslogic.com</a>.
            </li>
            <li>
              Opt Out of Push Notifications. If you opt-in to receive push notifications within the
              Applications, we may send push notifications or alerts to your mobile device from time
              to time. You can deactivate push notifications and alerts at any time by changing your
              device settings, changing the push notification settings within the Applications, or
              deleting the Applications.
            </li>
            <li>
              Access. You may access the personal information we hold about you at any time via your
              account, or by contacting us at{' '}
              <a href="mailto:privacy@campuslogic.com">privacy@campuslogic.com</a>.
            </li>
            <li>
              Amend. You can contact us at
              <a href="mailto:privacy@campuslogic.com">privacy@campuslogic.com</a> to update or
              correct any inaccuracies in the personal information that we hold about you.
            </li>
            <li>Move. You can download your content stored in the Services at any time.</li>
            <li>
              Erase. You can request that we erase the personal information that we hold about you.
              If you request that we erase your information, please note that your information may
              continue to reside on our backup systems.
            </li>
          </ol>
          <p>
            We are committed to resolving any complaints about our collection or use of your
            personal information. If you would like to make a complaint regarding this Privacy
            Policy or our practices in relation to your personal information, please contact us at
            <a href="mailto:privacy@campuslogic.com">privacy@campuslogic.com</a>.
          </p>
          <p>
            <span>10. CONTACT INFORMATION</span>
          </p>
          <p>
            You may contact us by email at
            <a href="mailto:privacy@campuslogic.com">privacy@campuslogic.com</a> or by sending a
            letter to the following mailing address:{' '}
          </p>
          <ul className="list-unstyled">
            <li>CampusLogic, Inc.</li>
            <li>1340 S. Spectrum Blvd., Suite 200</li>
            <li>Chandler, AZ 85256</li>
            <li>United States</li>
            <li>Attn: Legal Department</li>
          </ul>
          <p>
            <span>
              ADDITIONAL PRIVACY POLICY PROVISIONS APPLICABLE TO USERS OF THE RAISEME SERVICE
            </span>
          </p>
          <p>
            <span>1. ABOUT RAISEME</span>
          </p>
          <p>
            The RaiseMe service is a cloud platform and mobile application (collectively, “
            <span>RaiseMe Service</span>”) provided by CampusLogic that allows higher education
            institutions to offer micro-scholarships to student users that follow the relevant
            institution on the RaiseMe Service or to enrolled students for completing certain
            actions or desired behaviors.
          </p>
          <p>
            RaiseMe partners with universities to offer students who are 13 years of age or over
            with scholarships for their achievements during high school and college.
          </p>
          <p>
            These additional provisions are applicable to users of the RaiseMe Service and in
            addition to the CampusLogic Privacy Policy and all other privacy notices provided
            herein, and do not amend or replace the CampusLogic Privacy Policy and all other privacy
            notices provided herein.
          </p>
          <p>
            <span>2. COLLECTION OF INFORMATION YOU PROVIDE TO THE RAISEME SERVICE</span>
          </p>
          <p>
            <span>Information you provide.</span> Personal information you may provide to us through
            the RaiseMe Service or otherwise may include:
          </p>
          <ul>
            <li>
              <span>Contact details</span>, such as your name, email address, phone number.
            </li>
            <li>
              <span>Account information</span>, such as but not limited to a password or other
              information that helps us confirm that it is you accessing your account.
            </li>
            <li>
              <span>Demographic or other information</span> (such as your school, gender, age or
              birthday, and other information about your interests and preferences). You may also
              have the opportunity to create a profile, which may include personal information, such
              as photographs, information about your academic and work history, your interests and
              activities, your use of RaiseMe Service and other information. When you earn a
              Micro-Scholarship, you may be required to provide additional information directly to a
              college or university, such as proof of identity, proof of academic and work history
              (which may include high school transcripts, standardized test scores, or references
              from teachers or counselors), or proof of financial need (which may include completing
              a FAFSA or CSS profile, and providing other family income documentation), in order to
              claim the award.
            </li>
            <li>
              <span>Verified third-party account credentials</span> (for example, but not limited to
              your log-in credentials for Google Plus or other third party sites).
            </li>
          </ul>
          <p>
            <span>Third-party sources.</span> We may receive personal information about you from
            third-party sources, as follows:
          </p>
          <ul>
            <li>
              Colleges and universities and other academic institutions that have awarded you
              Micro-Scholarships may share your application, enrollment and graduation information
              with us.
            </li>
            <li>
              High schools, colleges, universities or other educational partners may provide
              information about you to us. For example, we may receive your email address if your
              high school counselor uses the service to send you an invitation to join the RaiseMe
              service. We use this information to send the invitation{' '}
              <em>[and reminder message]</em> via email on behalf of the sender and to facilitate
              your registration if you chose to join the RaiseMe Service.
            </li>
            <li>
              If you provide your third-party account credentials to us or otherwise sign in to
              RaiseMe Service through a third party site or service, you understand some content
              and/or information in those accounts (&quot;
              <span>Third Party Account Information</span>&quot;) may be transmitted into your
              account with us, and that Third Party Account Information transmitted to the RaiseMe
              Service is covered by this Privacy Policy; for example, if you log in to the RaiseMe
              Service through Google Plus, your Google Plus profile information will be populated
              into your profile on RaiseMe Service. The information we receive when you authenticate
              through a third-party service depends on the settings, permissions and privacy policy
              controlled by that third-party service. You should always check the privacy settings
              and notices in the relevant third-party services to understand what data may be
              disclosed to us or shared with the RaiseMe Service.
            </li>
          </ul>
          <p>
            <span>2. USE OF INFORMATION</span>
          </p>
          <p>
            We use your personal information to provide, maintain and support the RaiseMe Service
            and for the other purposes described in the CampusLogic Privacy Policy.
          </p>
          <p>
            <span>3. DISCLOSURE OR SHARING OF INFORMATION</span>
          </p>
          <p>
            <span className="underline">Colleges:</span> If you are a student, you may share your
            personal information with a College by taking certain actions on the RaiseMe Service,
            including, but not limited to, submitting your Micro-Scholarships for redemption,
            applying to specific programs and scholarships, or adding a College to your list of
            followed Colleges. By providing your email address or mobile phone number on the RaiseMe
            Service and following a College, you indicate interest in the College through RaiseMe,
            which authorizes the College to contact you directly via email or SMS (outside of the
            RaiseMe Service). You can always request directly to the College to opt out of any
            direct communication from them. Colleges are solely responsible for managing their
            messaging outside of the RaiseMe Service through email and/or SMS, as well as the opt-in
            or opt-out process for students to receive those messages.
          </p>
          <p>
            <span className="underline">Parents, Counselors, Guardians, and Advisors:</span> The
            RaiseMe Service may permit Students to share access to their accounts with parents,
            guardians, school counselors, or other trusted advisors. If a Student permits such
            access, the permitted individuals will have access to the Student&apos;s account and
            personal information contained in the account. These additional users accessing the
            account agree to our Terms of Use by accessing the account. Counselors, Advisors and
            other school officials will be able to view certain personal information of other school
            officials at their institution, such as the school official’s name, title and email.
          </p>
          <p>
            <span className="underline">Non-Profit & Corporate Scholarship Administrators:</span> If
            you apply for a Micro-Scholarship sponsored by a non-College third party, such as a
            non-profit or corporation, your profile information may be shared with a third-party
            scholarship administrator to select scholarship recipients. You authorize the
            third-party scholarship administrator to contact you directly to verify your profile
            information, including, but not necessarily limited to, obtaining copies of academic
            transcripts, proof of financial need if required, or any other documentation RaiseMe or
            the scholarship administrator requires to award the scholarship and disburse funds to
            you or your College.
          </p>
          <p>
            <span className="underline">Sponsors:</span> If you follow a Sponsor, that Sponsor will
            receive access to your individual profile, including the personal information contained
            therein.
          </p>
          <p>
            <span className="underline">Affiliated Businesses and Third-Party Services:</span> In
            certain situations, businesses or third-party websites we&apos;re affiliated with may
            sell or provide products or services to you through or in connection with the RaiseMe
            Service (either alone or jointly with us). We will share your personal information with
            that affiliated business only to the extent that it is related to such transaction or
            service. One such service may include the ability for you to automatically transmit
            Third Party Account Information to your RaiseMe profile or to automatically transmit
            information in your RaiseMe profile to your third-party account; for example, we may
            permit you to &quot;like&quot; content within the RaiseMe Service or &quot;share&quot;
            content to other web sites, such as Facebook or Twitter. If you choose to
            &quot;like&quot; or &quot;share&quot; content or to otherwise post information from or
            via the RaiseMe Service to a third-party website, feature, or application, that
            information may be publicly displayed, and the third party website may have access to
            information about you and your use of our RaiseMe Service. In addition, certain
            third-party partners may use cookies, web beacons, and other storage technologies to
            collect or receive information from you via our website and elsewhere on the Internet
            and may use that information to provide measurement services, to target ads, or
            generally to improve the nature or quality of the services that they provide. We have no
            control over the policies and practices of third-party websites or businesses as to
            privacy or anything else, so if you choose to take part in any transaction or service
            relating to or involving an affiliated website or business, please review all such
            businesses&apos; or websites&apos; policies.
          </p>
          <p>
            <span>Privacy Notice for California Residents</span>
          </p>
          <p>
            <span>Effective Date: July 1, 2020</span>
          </p>
          <p>
            This <span>Privacy Notice for California Residents</span> (“<span>Notice</span>”)
            supplements the information contained in the Privacy Policy of CampusLogic Inc. (“
            <span>CampusLogic</span>”) and applies to all visitors, users, and others who reside in
            the State of California (“<span>consumers</span>” or “<span>you</span>”). We adopt this
            Notice to comply with the California Consumer Privacy Act of 2018 (<span>CCPA</span>)
            and any terms defined in the CCPA have the same meaning when used in this Notice.
          </p>
          <p>
            This Notice does not apply to the personal information of individuals with whom
            CampusLogic engages in the individuals’ capacity as representatives of businesses that
            may provide services to CampusLogic, or to which CampusLogic may provide services.
          </p>
          <p>
            <span>1. Information We Collect</span>
          </p>
          <p>
            <span className="underline">From Consumers.</span> Our Services (as defined in the
            CampusLogic Privacy Policy) may collect personal information as defined by the CCPA (as
            information that identifies, relates to, describes, is reasonably capable of being
            associated with, or could reasonably be linked, directly or indirectly, with a
            particular consumer or household) (“<span>personal information</span>”). This personal
            information may be collected directly from you on the Site (as defined in the
            CampusLogic Privacy Policy) or through the Services, or indirectly from you, for example
            by observing your actions in the Services or on the Site.
          </p>
          <p>
            <span className="underline">From Covered Businesses.</span> Our Services may also
            collect such personal information from consumers indirectly from a for-profit
            educational institution, college, or university that may be a covered business under the
            CCPA. In such event, CampusLogic is deemed a service provider that processes personal
            information on behalf of such covered business for the purpose of providing access to
            the Services as ordered and subscribed to by the covered business. Such processing
            activities are governed by the contract between CampusLogic and such covered business
            and the privacy policies or notices of such covered business, and not by this Notice.
          </p>
          <p>
            We have collected the following categories of personal information from consumers within
            the last twelve (12) months:
          </p>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">A. Identifiers.</th>
                <td>
                  <p>
                    A real name, alias, postal address, unique personal identifier, online
                    identifier, Internet Protocol address, email address, account name, Social
                    Security number, driver’s license number, passport number, or other similar
                    identifiers.
                  </p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  B. Personal information categories listed in the California Customer Records
                  statute (Cal. Civ. Code § 1798.80(e)).
                </th>
                <td>
                  <p>
                    A name, signature, Social Security number, physical characteristics or
                    description, address, telephone number, passport number, driver’s license or
                    state identification card number, insurance policy number, education,
                    employment, employment history, bank account number, credit card number, debit
                    card number, or any other financial information, medical information, or health
                    insurance information.
                  </p>
                  <p>
                    Some personal information included in this category may overlap with other
                    categories.
                  </p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  C. Protected classification characteristics under California or federal law.
                </th>
                <td>
                  <p>
                    Age (40 years or older), race, color, ancestry, national origin, citizenship,
                    religion or creed, marital status, medical condition, physical or mental
                    disability, sex (including gender, gender identity, gender expression, pregnancy
                    or childbirth and related medical conditions), sexual orientation, veteran or
                    military status, genetic information (including familial genetic information).
                  </p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>
              <tr>
                <th scope="row">D. Commercial information.</th>
                <td>
                  <p>
                    Records of personal property, products or services purchased, obtained, or
                    considered, or other purchasing or consuming histories or tendencies.
                  </p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <th scope="row">E. Biometric information.</th>
                <td>
                  <p>
                    Genetic, physiological, behavioral, and biological characteristics, or activity
                    patterns used to extract a template or other identifier or identifying
                    information, such as, fingerprints, faceprints, and voiceprints, iris or retina
                    scans, keystroke, gait, or other physical patterns, and sleep, health, or
                    exercise data.
                  </p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <th scope="row">F. Internet or other similar network activity.</th>
                <td>
                  <p>
                    Browsing history, search history, information on a consumer’s interaction with a
                    website, application, or advertisement.
                  </p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <th scope="row">G. Geolocation data.</th>
                <td>
                  <p>Physical location or movements.</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <th scope="row">H. Sensory data.</th>
                <td>
                  <p>Audio, electronic, visual, thermal, olfactory, or similar information.</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <th scope="row">I. Professional or employment-related information.</th>
                <td>
                  <p>Current or past job history or performance evaluations.</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              <tr>
                <th scope="row">J. Inferences drawn from other personal information.</th>
                <td>
                  <p>
                    Profile reflecting a person’s preferences, characteristics, psychological
                    trends, predispositions, behavior, attitudes, intelligence, abilities, and
                    aptitudes.
                  </p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Personal information does <span className="underline">not</span> include:
          </p>
          <ol>
            <li>Publicly available information from government records.</li>
            <li>De-identified or aggregated consumer information.</li>
            <li>
              Information excluded from the scope of the CCPA, including: health or medical
              information covered by the Health Insurance Portability and Accountability Act of 1996
              (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or
              clinical trial data; personal information covered by certain sector-specific privacy
              laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act
              (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver’s
              Privacy Protection Act of 1994.
            </li>
          </ol>
          <p>
            <span>2. Use and Sharing of Personal Information </span>
          </p>
          <p>
            You can read about how we use personal information in the “Use of Information” section
            of the CampusLogic Privacy Policy. We may share personal information as described in the
            “Disclosure or Sharing of Information” section of the CampusLogic Privacy Policy.
          </p>
          <p>
            <span>3. Disclosures of Personal Information for a Business Purpose</span>
          </p>
          <p>
            In the preceding twelve (12) months, CampusLogic may have disclosed the following
            categories of personal information for a business purpose:
          </p>
          <ul className="list-unstyled">
            <li>Category A: Identifiers.</li>
            <li>Category B: California Customer Records personal information categories.</li>
            <li>
              Category C: Protected classification characteristics under California or federal law.
            </li>
            <li>Category J: Non-public education information.</li>
          </ul>
          <p>
            CampusLogic may disclose your personal information for a business purpose to the
            following categories of third parties:
          </p>
          <ol>
            <li>Third Parties.</li>
            <li>Service providers.</li>
            <li>Corporate Restructuring.</li>
            <li>Other disclosures (legal purposes).</li>
          </ol>
          <p>
            <span>4. Your Rights and Choices</span>
          </p>
          <p>The CCPA provides California residents the following rights.</p>
          <p>
            <span className="underline">Information.</span>
          </p>
          <p>
            This Notice describes how we use and share your personal information through the
            Services, including during the past 12 months. This Notice describes:
          </p>
          <ul>
            <li>
              The sources through which we collect California residents’ personal information and
              the types of personal information collected in the “Information We Collect” section
              above.
            </li>
            <li>
              The purposes for which we use and share this information in the “Use and Sharing of
              Personal Information” section above.
            </li>
          </ul>
          <p>
            <span className="underline">Access.</span>
          </p>
          <p>
            You can request a copy of the personal information that we maintain about you. The CCPA
            limits this right. For example, companies may not provide users in response to access
            requests certain sensitive information and other consumers’ personal information and may
            exclude information that would compromise the confidentiality of trade secrets.
          </p>
          <p>
            <span className="underline">Deletion.</span>
          </p>
          <p>
            You have the right to request that CampusLogic delete any of your personal information
            that we collected from you and retained, subject to certain exceptions. Once we receive
            and confirm your verifiable consumer request, we will delete your personal information
            from our records, unless an exception applies.
          </p>
          <p>
            We may deny your deletion request if retaining the information is necessary for us or
            our service provider(s) to:
          </p>
          <ol>
            <li>
              Complete the transaction for which we collected the personal information, provide a
              good or service that you requested, take actions reasonably anticipated within the
              context of our ongoing business relationship with you, or otherwise perform our
              contract with you.
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive, fraudulent, or
              illegal activity, or prosecute those responsible for such activities.
            </li>
            <li>
              Debug products to identify and repair errors that impair existing intended
              functionality.
            </li>
            <li>
              Exercise free speech, ensure the right of another consumer to exercise their free
              speech rights, or exercise another right provided for by law.
            </li>
            <li>
              Comply with the California Electronic Communications Privacy Act (Cal. Penal Code §
              1546 et. seq.).
            </li>
            <li>
              Engage in public or peer-reviewed scientific, historical, or statistical research in
              the public interest that adheres to all other applicable ethics and privacy laws, when
              the information’s deletion may likely render impossible or seriously impair the
              research’s achievement, if you previously provided informed consent.
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with consumer expectations
              based on your relationship with us.
            </li>
            <li>Comply with a legal obligation.</li>
            <li>
              Make other internal and lawful uses of that information that are compatible with the
              context in which you provided it.
            </li>
          </ol>
          <p>
            <span className="underline">Exercising Your Rights.</span>
          </p>
          <p>
            To exercise your rights described above, please submit a verifiable consumer request to
            us by either: Calling us at 602.643.1300, or emailing
            <a href="mailto:privacy@campuslogic.com">privacy@campuslogic.com</a>.
          </p>
          <p>
            Only you, or a person registered with the California Secretary of State that you
            authorize to act on your behalf, may make a verifiable consumer request related to your
            personal information. You may also make a verifiable consumer request on behalf of your
            minor child.
          </p>
          <p>The verifiable consumer request must:</p>
          <ol>
            <li>
              Provide sufficient information that allows us to reasonably verify you are the person
              about whom we collected personal information or an authorized representative.
            </li>
            <li>
              Describe your request with sufficient detail that allows us to properly understand,
              evaluate, and respond to it.
            </li>
          </ol>
          <p>
            We cannot respond to your request or provide you with personal information if we cannot
            verify your identity or authority to make the request and confirm the personal
            information relates to you. Making a verifiable consumer request does not require you to
            create an account with us. We will only use personal information provided in a
            verifiable consumer request to verify the requestor’s identity or authority to make the
            request.
          </p>
          <p>
            <span className="underline">Response Timing and Format</span>
          </p>
          <p>
            We endeavor to respond to a verifiable consumer request within forty-five (45) days of
            its receipt. If we require more time, we will inform you of the reason and extension
            period in writing. If you have an account with us, we will deliver our written response
            to that account. If you do not have an account with us, we will deliver our written
            response electronically.
          </p>
          <p>
            Any disclosures we provide will only cover the 12-month period preceding the verifiable
            consumer request’s receipt. The response we provide will also explain the reasons we
            cannot comply with a request, if applicable.
          </p>
          <p>
            <span className="underline">Personal Information Sales Opt-Out and Opt-In Rights</span>
          </p>
          <p>CampusLogic does not sell personal information of consumers.</p>
          <p>
            <span className="underline">Non-Discrimination.</span>
          </p>
          <p>We will not discriminate against you for exercising any of your CCPA rights.</p>
          <p>
            <span className="underline">Other California Privacy Rights.</span>
          </p>
          <p>
            California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our
            Services that are California residents to request certain information regarding our
            disclosure of personal information to third parties for their direct marketing purposes.
            CampusLogic does not disclose personal information to third parties for their direct
            marketing purposes. For more information, please send an email to
            <a href="mailto:privacy@campuslogic.com">privacy@campuslogic.com</a>.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Index;
