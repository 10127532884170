export const formatFormValues = (formValues) => ({
  currentInstitution: {
    parentId: formValues.institution.parentId,
    type: formValues.institution.type,
    campus: formValues.institution.campus,
  },
  communityCollegeInfo: {
    transferYear: formValues.transferYear,
    transferSemester: formValues.transferTerm,
  },
  educationPhase: formValues.educationPhase,
  followedCollegeIds: formValues.followedCollegeIds,
  replaceFollowedColleges: true,
});
