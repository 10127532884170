import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {includes} from 'lodash';
import {EARNING_STATUSES} from '../utils';
import Icon from 'shared/components/icon';
import {formatEarnings} from 'src/utils/formatters';

const propTypes = {
  amount: PropTypes.number.isRequired,
  statusType: PropTypes.string,
  divisor: PropTypes.number,
};

const InstitutionalGrantsAndScholarshipsAidGraphic = ({amount, divisor, statusType}) => {
  const earningAndSubmitted = statusType === 'submitted';
  const isEarning = includes(EARNING_STATUSES, statusType);

  if (!isEarning || !amount) {
    return null;
  }

  const earnings = formatEarnings(amount, divisor);
  return (
    <div
      className={classnames('institutional-grants-and-scholarships-aid', {
        submitted: earningAndSubmitted,
      })}
    >
      <span className="institutional-grants-and-scholarships-aid-subheading">
        Institutional Grants & Scholarships Aid
      </span>
      <span className="institutional-aid-amount">{`$${earnings.termAmount()}`}</span>{' '}
      <span className="per-year">{earnings.divisorSuffix()}</span>
      <div className="institutional-aid-text-with-lock">
        <Icon
          iconType={earningAndSubmitted ? 'lock-alt' : 'lock-open-alt'}
          className="institutional-grants-unlocked"
        />
        <span>secured {`${earningAndSubmitted ? '' : 'so far'}`} in micro-scholarships</span>
      </div>
    </div>
  );
};

InstitutionalGrantsAndScholarshipsAidGraphic.propTypes = propTypes;

export default InstitutionalGrantsAndScholarshipsAidGraphic;
