import PropTypes from 'prop-types';
import React from 'react';
import {isNil, map, upperFirst} from 'lodash';
import SectionHeader from './section-header';
import AboutYourEarnings from './about-your-earnings';
import ReceivingMicroScholarships from './receiving-micro-scholarships';
import Card from 'src/components/Cards/Card';
import CollapsibleSection from 'src/components/collapsible-section';
import MiniInfoCardContainer from 'shared/components/college-profile/mini-info-card-container';
import MiniInfoCard from 'shared/components/college-profile/mini-info-card';
import {gpaFormat} from 'shared/utils';
import linkify from '../../linkify';

class RequirementsCard extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
  };

  render() {
    const {
      college: {
        aboutEarnings,
        amount,
        partnerMinGPA,
        partnerMinCredits,
        earningRelationship: {statusType},
        unsanitizedPartnerOngoingRequirements,
        unsanitizedPartnerRequirements,
        name,
        partnerApplicationDeadlineDay,
        partnerApplicationDeadlineMonth,
        partnerSubmissionDeadlineDay,
        partnerSubmissionDeadlineMonth,
        partnerSubmissionDeadlineYear,
        partnerSubmissionDeadlineTerm,
        receivingMicroScholarships,
      },
    } = this.props;

    return (
      <Card>
        <CollapsibleSection
          className="college-profile-requirements-card"
          title="Scholarship Requirements"
          subtitle="Requirements needed to earn scholarships on RaiseMe"
          isOpen={statusType === 'submitted'}
          trackIdentifier="college-listing-requirements-cc-scholarship-requirements"
        >
          <div className="college-profile-card-content">
            <MiniInfoCardContainer>
              <MiniInfoCard
                iconType="book"
                label={
                  <span>
                    Minimum
                    <br />
                    GPA
                  </span>
                }
                value={partnerMinGPA ? gpaFormat(partnerMinGPA) : null}
              />
              <MiniInfoCard
                iconType="book"
                label="Minimum Credit Hours"
                value={partnerMinCredits}
              />
              <MiniInfoCard
                iconType="clock"
                label="Application Due Date"
                value={
                  !isNil(partnerApplicationDeadlineMonth) && !isNil(partnerApplicationDeadlineDay)
                    ? `${partnerApplicationDeadlineMonth + 1}/${partnerApplicationDeadlineDay}`
                    : null
                }
              />
              <MiniInfoCard
                iconType="calendar-alt"
                label="Earning Deadline"
                value={
                  !isNil(partnerSubmissionDeadlineMonth) &&
                  !isNil(partnerSubmissionDeadlineDay) &&
                  !isNil(partnerSubmissionDeadlineYear)
                    ? `${partnerSubmissionDeadlineMonth + 1}/` +
                      `${partnerSubmissionDeadlineDay}/` +
                      `${partnerSubmissionDeadlineYear.toString().slice(2)}`
                    : null
                }
              />
              <MiniInfoCard
                iconType="calendar-alt"
                label="Next Eligible Transfer Term"
                value={
                  !isNil(partnerSubmissionDeadlineTerm)
                    ? upperFirst(partnerSubmissionDeadlineTerm)
                    : null
                }
              />
            </MiniInfoCardContainer>

            {unsanitizedPartnerRequirements &&
              unsanitizedPartnerRequirements.length > 0 &&
              map(unsanitizedPartnerRequirements, (requirement, index) => (
                <p
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: linkify(`${requirement.replace(/\\.$/g, '')}.`, {newTab: true}),
                  }}
                />
              ))}

            <SectionHeader title="Enrollment Requirements" subtitle="Once enrolled in College" />
            {unsanitizedPartnerOngoingRequirements &&
              unsanitizedPartnerOngoingRequirements.length > 0 &&
              map(unsanitizedPartnerOngoingRequirements, (requirement, index) => (
                <p
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: linkify(`${requirement.replace(/\\.$/g, '')}.`, {newTab: true}),
                  }}
                />
              ))}

            <SectionHeader title="About Your Earnings" />
            <AboutYourEarnings amount={amount} name={name} aboutEarningsText={aboutEarnings} />

            <SectionHeader title="Receiving Your Micro-Scholarships" />
            <ReceivingMicroScholarships
              name={name}
              receivingMicroScholarshipsText={receivingMicroScholarships}
            />
          </div>
        </CollapsibleSection>
      </Card>
    );
  }
}

export default RequirementsCard;
