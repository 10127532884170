import {keys} from 'lodash';
import req from 'shared/req';
import {errorMessage} from 'shared/actions/notifications';
import {verifyPhoneNumber} from 'shared/actions/verify-mobile';
import {verifyAddress} from 'shared/account-settings/address-verification';

export const RECEIVE_STUDENT = 'RECEIVE_STUDENT';
export const UPDATE_DISABILITIES = 'UPDATE_DISABILITIES';
export const SHOW_ADDRESS_MODAL = 'SHOW_ADDRESS_MODAL';

export function receiveStudent(student) {
  return {
    type: RECEIVE_STUDENT,
    student,
  };
}

export function submitForm(
  user,
  baseURL,
  mobile,
  dispatch,
  shouldValidateAddress = false,
  shouldValidateMobile = false,
  triggerNotification = false
) {
  if (user.mobilePartOne && user.mobilePartTwo && user.mobilePartThree) {
    user.mobile = `${user.mobilePartOne}${user.mobilePartTwo}${user.mobilePartThree}`;
  }

  if (user.newEmail && user.newEmailRepeat && user.newEmail === user.newEmailRepeat) {
    user.email = user.newEmail;
  }

  const fields = keys(user).join(',');
  const url = `${baseURL}/me?fields=${fields}`;

  return new Promise((resolve, reject) =>
    req({
      url,
      method: 'PATCH',
      data: user,
    })
      .then((modifiedUser) => {
        if (shouldValidateAddress && user.country === 'US' && user.addressLine1) {
          verifyAddress(modifiedUser, dispatch);
        } else if (triggerNotification) {
          if (shouldValidateMobile && modifiedUser.mobile) {
            dispatch(verifyPhoneNumber(modifiedUser.mobile, 'sms'));
          }
        }
        dispatch(receiveStudent(modifiedUser));
        resolve(modifiedUser);
      })
      .catch((errs) => {
        const errors = {};
        errs.forEach((err) => {
          if (err.field === 'mobile') {
            errors.mobilePartOne = err.message;
          } else if (err.field === 'address') {
            dispatch(errorMessage('Please provide a valid address.'));
          } else if (['disabilities', 'custom_gender_identity'].includes(err.field)) {
            dispatch(errorMessage(err.message));
          }
          errors[err.field] = err.message;
        });
        reject(errors);
      })
  );
}
