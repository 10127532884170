import PropTypes from 'prop-types';
import React from 'react';
import {capitalize, find} from 'lodash';
import Card from 'src/components/Cards/Card';
import FormButton from 'src/components/Button';

class EarningDeadlinesCard extends React.Component {
  static propTypes = {
    collegeProfile: PropTypes.shape({
      college: PropTypes.shape({
        partnerSubmissionDeadlineDay: PropTypes.number.isRequired,
        partnerSubmissionDeadlineMonth: PropTypes.number.isRequired,
        partnerSubmissionDeadlineYear: PropTypes.number.isRequired,
        partnerSubmissionDeadlineTerm: PropTypes.string.isRequired,
        partnerUpcomingSubmissionPeriods: PropTypes.object,
      }),
    }),
    openCollegeEarningDeadlineModal: PropTypes.func.isRequired,
  };

  render() {
    const {
      collegeProfile: {
        college: {
          partnerSubmissionDeadlineDay,
          partnerSubmissionDeadlineMonth,
          partnerSubmissionDeadlineYear,
          partnerSubmissionDeadlineTerm,
          partnerUpcomingSubmissionPeriods,
        },
      },
      openCollegeEarningDeadlineModal,
    } = this.props;

    const date = new Date(
      Date.UTC(
        partnerSubmissionDeadlineYear,
        partnerSubmissionDeadlineMonth,
        partnerSubmissionDeadlineDay
      )
    );
    const options = {
      timeZone: 'UTC',
    };
    const partnerSubmissionTermYear = find(
      partnerUpcomingSubmissionPeriods,
      (period) => period[2]
    )[1];
    const deadline = new Intl.DateTimeFormat('en-US', options).format(date);
    const term = `${capitalize(partnerSubmissionDeadlineTerm)} ${partnerSubmissionTermYear}`;

    return (
      <Card>
        <div className="college-profile-earning-deadline-card">
          <div>
            <p className="college-profile-earning-deadline-card-header">
              {`${term} Deadline to Earn`}
            </p>
            <span>{deadline}</span>
          </div>
          <FormButton
            outline
            margin={false}
            size="small"
            color="gray"
            className="college-profile-earning-deadline-card-add-button-desktop"
            onClick={openCollegeEarningDeadlineModal}
          >
            View All
          </FormButton>
        </div>
      </Card>
    );
  }
}

export default EarningDeadlinesCard;
