import PropTypes from 'prop-types';
import React from 'react';
import {isNil, map} from 'lodash';
import SectionHeader from './section-header';
import AboutYourEarningsCard from './about-your-earnings-card';
import ReceivingMicroScholarshipsCard from './receiving-micro-scholarships-card';
import InstitutionalGrantsAndScholarshipsAidGraphic from './institutional-grants-and-scholarships-aid-graphic';
import MiniInfoCardContainer from 'shared/components/college-profile/mini-info-card-container';
import MiniInfoCard from 'shared/components/college-profile/mini-info-card';
import Card from 'src/components/Cards/Card';
import CollapsibleSection from 'src/components/collapsible-section';
import {gpaFormat} from 'shared/utils';
import linkify from '../../linkify';

class Requirements extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
  };

  formatRequirement = (text) => {
    // if text does not end with ., ?, or !, add a period
    const endPunc = text.match(/[.?!]$/) ? '' : '.';
    return `${text}${endPunc}`;
  };

  render() {
    const {
      college: {
        aboutEarnings,
        amount,
        partnerMinGPA,
        partnerMinCredits,
        unsanitizedPartnerOngoingRequirements,
        unsanitizedPartnerRequirements,
        name,
        partnerApplicationDeadlineDay,
        partnerApplicationDeadlineMonth,
        partnerSubmissionDeadlineMonth,
        partnerSubmissionDeadlineDay,
        receivingMicroScholarships,
        divisor,
        earningRelationship: {statusType},
      },
    } = this.props;

    return (
      <ul
        className="college-profile-panel-body college-profile-requirements"
        aria-label={`Requirements for ${name}`}
      >
        <SectionHeader
          title="Scholarship Requirements"
          subtitle="Requirements Needed to Earn Scholarships on RaiseMe During 9th - 12th Grade"
        />

        <section className="college-profile-requirements-quick-stats">
          <MiniInfoCardContainer>
            <MiniInfoCard
              iconType="calendar-alt"
              label={
                <span>
                  Minimum
                  <br />
                  GPA
                </span>
              }
              value={partnerMinGPA ? gpaFormat(partnerMinGPA) : null}
            />
            <MiniInfoCard
              iconType="calendar-alt"
              label="Minimum Credits"
              value={partnerMinCredits}
            />
            <MiniInfoCard
              iconType="clock"
              label="Application Due Date"
              value={
                !isNil(partnerApplicationDeadlineMonth) && !isNil(partnerApplicationDeadlineDay)
                  ? `${partnerApplicationDeadlineMonth + 1}/${partnerApplicationDeadlineDay}`
                  : null
              }
            />
            <MiniInfoCard
              iconType="calendar-alt"
              label="Earning Deadline"
              value={
                !isNil(partnerSubmissionDeadlineMonth) && !isNil(partnerSubmissionDeadlineDay)
                  ? `${partnerSubmissionDeadlineMonth + 1}/${partnerSubmissionDeadlineDay}`
                  : null
              }
            />
          </MiniInfoCardContainer>
        </section>
        <InstitutionalGrantsAndScholarshipsAidGraphic {...{amount, divisor, statusType}} />
        {unsanitizedPartnerRequirements && unsanitizedPartnerRequirements.length > 0 && (
          <Card>
            <CollapsibleSection
              title="Eligibility Requirements"
              subtitle="During 9th - 12th Grade"
              trackIdentifier="college-listing-requirements-hs-eligibility-requirements"
            >
              <div className="college-profile-card-content">
                {map(unsanitizedPartnerRequirements, (requirement, index) => (
                  <p
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: linkify(this.formatRequirement(requirement), {newTab: true}),
                    }}
                  />
                ))}
              </div>
            </CollapsibleSection>
          </Card>
        )}

        {unsanitizedPartnerOngoingRequirements &&
          unsanitizedPartnerOngoingRequirements.length > 0 && (
            <Card>
              <CollapsibleSection
                title="Ongoing Requirements"
                subtitle="Once Enrolled in College"
                trackIdentifier="college-listing-requirements-hs-ongoing-requirements"
              >
                <div className="college-profile-card-content">
                  {map(unsanitizedPartnerOngoingRequirements, (requirement, index) => (
                    <p key={index}>{this.formatRequirement(requirement)}</p>
                  ))}
                </div>
              </CollapsibleSection>
            </Card>
          )}

        <AboutYourEarningsCard amount={amount} name={name} aboutEarningsText={aboutEarnings} />

        <ReceivingMicroScholarshipsCard
          name={name}
          receivingMicroScholarshipsText={receivingMicroScholarships}
        />
      </ul>
    );
  }
}

export default Requirements;
