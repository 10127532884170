import React, {ReactElement} from 'react';
import Button from 'src/components/Button';
import {Grid} from '@mui/material';

export interface FooterProps {
  unsubscribeAll: () => () => void;
}

export default function NotificationSettingsFormFooter(props: FooterProps): ReactElement {
  return (
    <Grid container justifyContent="space-between">
      <Grid item sm={4} xs={12}>
        <Button
          color="blue"
          outline
          onClick={props.unsubscribeAll}
          width="100%"
          testId="notification-settings-unsubscribe-all"
        >
          Unsubscribe from all
        </Button>
      </Grid>
    </Grid>
  );
}
