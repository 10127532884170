import React from 'react';
import PropTypes from 'prop-types';
import CheckboxGroupFilter from 'shared/components/filters/checkbox-group-filter';
import Text from 'shared/components/text';

export const SIZES = {
  S: 'Small (<5K)',
  M: 'Medium (5K-15K)',
  L: 'Large (15K+)',
};

class SizeFilters extends React.Component {
  static propTypes = {
    getColleges: PropTypes.func.isRequired,
  };

  render() {
    const {getColleges} = this.props;

    return (
      <div className="college-search-filters-section">
        <fieldset role="group" aria-label="Filter by Undergraduate Population">
          <legend className="college-search-filters-section-legend">
            <Text weight={5} emphasis>
              Undergrad Population
            </Text>
          </legend>
          <CheckboxGroupFilter name="size" options={SIZES} onChange={getColleges} />
        </fieldset>
      </div>
    );
  }
}

export default SizeFilters;
