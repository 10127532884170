import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {buttonColors} from 'shared/constants';
import NewTabTooltip from 'shared/components/new-tab-tooltip';
import 'stylesheets/components/shared-button.scss';

class FormButton extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    // Color of the button.
    // Deprecated: primary, alternate, and negative
    // use blue, white, and red respectively.
    color: PropTypes.oneOf(buttonColors),
    buttonName: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    margin: PropTypes.bool,
    horizontalMargin: PropTypes.bool,
    onClick: PropTypes.func,
    outline: PropTypes.bool,
    link: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fixedWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
    buttonRef: PropTypes.func,
    role: PropTypes.string,
    'aria-label': PropTypes.string,
    'aria-checked': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    cy: PropTypes.string,
    testId: PropTypes.string,
    newTabTooltip: PropTypes.bool,
  };

  static defaultProps = {
    color: 'blue',
    disabled: false,
    className: '',
    margin: true,
    horizontalMargin: false,
    onClick: null,
    type: 'button',
    width: 0,
    size: 'medium',
    outline: false,
    link: false,
  };

  getClassName() {
    return classnames(
      'shared-button',
      this.props.className,
      this.props.size,
      {margin: this.props.margin},
      {'horizontal-margin': this.props.horizontalMargin},
      `${this.props.color}${this.props.outline ? '-outline' : ''}${this.props.link ? '-link' : ''}`
    );
  }

  renderButton() {
    let styleWidth = {minWidth: this.props.width};
    if (this.props.fixedWidth) {
      styleWidth = {width: this.props.fixedWidth};
    }

    return (
      <button
        ref={this.props.buttonRef}
        id={this.props.id}
        className={this.getClassName()}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        style={styleWidth}
        type={this.props.type}
        name={this.props.name}
        value={this.props.value}
        data-segment-track={this.props.buttonName && 'button'}
        data-button-name={this.props.buttonName}
        role={this.props.role}
        aria-label={this.props['aria-label']}
        aria-checked={this.props['aria-checked']}
        aria-labelledby={this.props['aria-labelledby']}
        data-cy={this.props.cy}
        data-testid={this.props.testId}
      >
        {this.props.children}
      </button>
    );
  }

  render() {
    if (this.props.newTabTooltip) {
      return <NewTabTooltip>{this.renderButton()}</NewTabTooltip>;
    } else {
      return this.renderButton();
    }
  }
}

export default FormButton;
