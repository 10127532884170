import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  type: PropTypes.string,
  data: PropTypes.shape({
    icon: PropTypes.node,
    title: PropTypes.string,
  }),
};

const Title = ({type, data}) =>
  data && (
    <div className={`college-profile-scholarship-type college-profile-scholarship-type-${type}`}>
      {data.icon}
      {data.title}
    </div>
  );

Title.propTypes = propTypes;

export default Title;
