import PropTypes from 'prop-types';
import React from 'react';
import RangeFilter from 'shared/components/filters/range-filter';
import Text from 'shared/components/text';
import ExpandableFilter from 'src/components/CollegeSearch/Filters/expandable-filter';
import {percentFormat} from 'src/utils/collegeSearch';

class AdmissionFilters extends React.Component {
  static propTypes = {
    getColleges: PropTypes.func.isRequired,
  };

  render() {
    const {getColleges} = this.props;

    return (
      <div className="college-search-filters-section">
        <fieldset role="group" aria-label="Filter by Admission Data">
          <legend className="college-search-filters-section-legend">
            <Text weight={5} emphasis>
              Admission
            </Text>
          </legend>
          <Text id="college-search-acceptance-rate" weight={5} emphasis>
            Acceptance Rate
          </Text>
          <RangeFilter
            name="acceptance-rate"
            step={1}
            minValue={0}
            maxValue={100}
            labelFunc={percentFormat}
            onChange={getColleges}
            labelledby="college-search-acceptance-rate"
          />
          <ExpandableFilter filterType="Admission">
            <div className="college-search-subdivider"></div>
            <Text id="college-search-yield-percent" weight={5} emphasis>
              Yield
            </Text>
            <RangeFilter
              name="percent-yield"
              step={1}
              minValue={0}
              maxValue={100}
              labelFunc={percentFormat}
              onChange={getColleges}
              labelledby="college-search-yield-percent"
            />
            <div className="college-search-subdivider"></div>
            <Text id="college-search-avg-sat" weight={5} emphasis>
              Average SAT
            </Text>
            <RangeFilter
              name="average-sat"
              step={30}
              minValue={530}
              maxValue={1600}
              onChange={getColleges}
              labelledby="college-search-avg-sat"
            />
            <div className="college-search-subdivider"></div>
            <Text id="college-search-avg-act" weight={5} emphasis>
              Average ACT
            </Text>
            <RangeFilter
              name="average-act"
              step={1}
              minValue={11}
              maxValue={36}
              onChange={getColleges}
              labelledby="college-search-avg-act"
            />
          </ExpandableFilter>
        </fieldset>
      </div>
    );
  }
}

export default AdmissionFilters;
