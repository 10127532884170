import React from 'react';
import PropTypes from 'prop-types';
import {omit} from 'lodash';
import colors from 'shared/aptitude/colors';

function CarouselArrow(props) {
  const arrowProps = omit(props, [
    'currentSlide',
    'slideCount',
    'arrowKey',
    'chevronColor',
    'showOutline',
  ]);
  const arrowKey = `${props.arrowKey}-${props.direction}`;
  const {direction, chevronColor, showOutline} = props;
  const displayIf = (shouldDisplay) => (shouldDisplay ? 'inline' : 'none');

  return (
    <svg
      {...arrowProps}
      width="54px"
      height="54px"
      viewBox="0 0 54 54"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <circle id={`${arrowKey}-path-1`} cx="445" cy="283" r="22"></circle>
        <filter
          x="-18.2%"
          y="-15.9%"
          width="136.4%"
          height="136.4%"
          filterUnits="objectBoundingBox"
          id={`${arrowKey}-filter-2`}
        >
          <feMorphology
            radius="0.5"
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          ></feMorphology>
          <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.31372549   0 0 0 0 0.352941176   0 0 0 0 0.396078431  0 0 0 0.3 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        display={displayIf(showOutline)}
      >
        <g transform="translate(-418.000000, -257.000000)">
          <g id="Oval-Copy-2">
            <use
              fill="black"
              fillOpacity="1"
              filter={`url(#${arrowKey}-filter-2)`}
              xlinkHref={`#${arrowKey}-path-1`}
            ></use>
            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref={`#${arrowKey}-path-1`}></use>
          </g>
        </g>
      </g>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform={`scale(${showOutline ? 1 : 2})`}
      >
        <g
          display={displayIf(direction === 'left')}
          transform="translate(-418.000000, -258.000000)"
          fill={chevronColor}
          className="chevron"
        >
          <path
            d="M440.078125,282.46875 L446.15625,276.390625 C446.302084,276.244791 446.479166,276.171875 446.6875,276.171875 C446.895834,276.171875 447.072916,276.244791 447.21875,276.390625 L447.921875,277.109375 C448.067709,277.255209 448.140625,277.429686 448.140625,277.632812 C448.140625,277.835939 448.067709,278.015624 447.921875,278.171875 L443.109375,283 L447.921875,287.828125 C448.067709,287.984376 448.140625,288.164061 448.140625,288.367188 C448.140625,288.570314 448.067709,288.744791 447.921875,288.890625 L447.21875,289.609375 C447.072916,289.755209 446.895834,289.828125 446.6875,289.828125 C446.479166,289.828125 446.302084,289.755209 446.15625,289.609375 L440.078125,283.53125 C439.932291,283.385416 439.859375,283.208334 439.859375,283 C439.859375,282.791666 439.932291,282.614584 440.078125,282.46875 Z"
            id="chevron-left"
          ></path>
        </g>

        <g
          display={displayIf(direction === 'right')}
          transform="translate(-1113.000000, -258.000000)"
          fill={chevronColor}
          className="chevron"
        >
          <path
            d="M1144.92188,283.53125 L1138.84375,289.609375 C1138.69792,289.755209 1138.52083,289.828125 1138.3125,289.828125 C1138.10417,289.828125 1137.92708,289.755209 1137.78125,289.609375 L1137.07812,288.890625 C1136.93229,288.744791 1136.85938,288.570314 1136.85938,288.367188 C1136.85938,288.164061 1136.93229,287.984376 1137.07812,287.828125 L1141.89062,283 L1137.07812,278.171875 C1136.93229,278.015624 1136.85938,277.835939 1136.85938,277.632812 C1136.85938,277.429686 1136.93229,277.255209 1137.07812,277.109375 L1137.78125,276.390625 C1137.92708,276.244791 1138.10417,276.171875 1138.3125,276.171875 C1138.52083,276.171875 1138.69792,276.244791 1138.84375,276.390625 L1144.92188,282.46875 C1145.06771,282.614584 1145.14062,282.791666 1145.14062,283 C1145.14062,283.208334 1145.06771,283.385416 1144.92188,283.53125 Z"
            id="chevron-right"
          ></path>
        </g>
      </g>
    </svg>
  );
}

CarouselArrow.propTypes = {
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  arrowKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  chevronColor: PropTypes.string,
  showOutline: PropTypes.bool,
  scale: PropTypes.number,
};

CarouselArrow.defaultProps = {
  chevronColor: colors.n900,
  showOutline: true,
  scale: 1.0,
};

export default CarouselArrow;
