import {forEach, merge} from 'lodash';
import {portfolioUrl} from 'shared/portfolio/utils';
import req, {rejectCamelizedErrors, rejectGenericError} from 'shared/req';
import {objectId} from 'shared/utils';

export const NEW_EVENT = 'NEW_EVENT';
export const SAVE_EVENT = 'SAVE_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const CANCEL_EDIT_EVENT = 'CANCEL_EDIT_EVENT';
export const RECEIVE_COLLEGE_EVENT = 'RECEIVE_COLLEGE_EVENT';
export const EVENT_DELETED = 'EVENT_DELETED';
export const SCROLLED_TO_EVENT = 'SCROLLED_TO_EVENT';
export const RECEIVE_COLLEGE_EVENTS = 'RECEIVE_COLLEGE_EVENTS';
export const RECEIVE_COLLEGE_INTERACTION_OPTIONS = 'RECEIVE_COLLEGE_INTERACTION_OPTIONS';

export function newEvent(options = {}) {
  const collegeEvent = {
    _id: objectId(),
    collegeName: '',
    collegeId: '',
    month: null,
    year: null,
    editing: true,
    new: true,
    scrollTo: !!options.scrollTo,
  };

  return {
    type: NEW_EVENT,
    id: `college-event-${collegeEvent._id}`,
    collegeEvent,
  };
}

export function cancelEvent(collegeEvent) {
  return {
    type: CANCEL_EDIT_EVENT,
    id: `college-event-${collegeEvent._id}`,
    collegeEvent,
  };
}

export function deleteEvent(id) {
  return (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(`/college_events/${id}`, _id),
      method: 'DELETE',
    }).then(() => {
      dispatch({
        type: EVENT_DELETED,
        id: `college-event-${id}`,
      });
    });
  };
}

export function editEvent(collegeEvent) {
  return {
    type: EDIT_EVENT,
    id: `college-event-${collegeEvent._id}`,
    collegeEvent,
  };
}

export function receiveCollegeEvent(collegeEvent) {
  return {
    type: RECEIVE_COLLEGE_EVENT,
    id: `college-event-${collegeEvent._id}`,
    collegeEvent,
  };
}

export function scrolledToEvent(id) {
  return {
    type: SCROLLED_TO_EVENT,
    id: `college-event-${id}`,
  };
}

export const saveEvent = (collegeEvent) => (dispatch, getState) => {
  let url;
  let method;

  if (collegeEvent.new) {
    url = '/college_events';
    method = 'POST';
  } else {
    url = `/college_events/${collegeEvent._id}`;
    method = 'PATCH';
  }

  const {
    student: {_id},
  } = getState();
  return req({
    url: portfolioUrl(url, _id),
    method: method,
    data: collegeEvent,
  })
    .then((collegeEventResponse) => {
      dispatch(receiveCollegeEvent(merge({}, collegeEventResponse, {scrollTo: true})));
    })
    .catch(rejectCamelizedErrors)
    .catch(rejectGenericError);
};

export const getCollegeEventOptions =
  (onlyAwardableEvents = false) =>
  (dispatch) =>
    req({
      url: `/v1/lists/college-events?onlyAwardableEvents=${onlyAwardableEvents}`,
      method: 'get',
    }).then((colleges) => {
      const collegeInteractionOptions = {};
      forEach(colleges, (college) => (collegeInteractionOptions[college._id] = college.events));

      dispatch({
        type: RECEIVE_COLLEGE_INTERACTION_OPTIONS,
        collegeInteractionOptions,
      });
    });
