import React from 'react';
import Text from 'shared/components/text';
import Icon from 'shared/components/icon';

class UsOnlyText extends React.Component {
  render() {
    return (
      <div className="shared-college-card-status-text-us-only">
        <Icon className="shared-college-card-status-text-warning-flag" iconType="flag" />
        <Text weight={6} inline>
          U.S. citizenship required to earn
        </Text>
      </div>
    );
  }
}

export default UsOnlyText;
