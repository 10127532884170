import React from 'react';
import PropTypes from 'prop-types';
import GraphAttachedTooltip from 'shared/college-profile/components/graph-attached-tooltip';

const propTypes = {
  classification: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  studentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  xValue: PropTypes.number.isRequired,
};

const AcceptanceClassificationTooltip = ({label, studentValue, classification, xValue}) => (
  <GraphAttachedTooltip xValue={xValue} style={{left: `calc(${xValue}%)`}}>
    <div className="horizontal-box-plot-tooltip-label-text-container">
      <div>
        <span className="horizontal-box-plot-tooltip-text">{label}: </span>
        <span className="horizontal-box-plot-tooltip-text horizontal-box-plot-tooltip-emphasized">
          {studentValue}
        </span>
      </div>
      <div>
        <span className="horizontal-box-plot-tooltip-text">{classification}</span>
      </div>
    </div>
  </GraphAttachedTooltip>
);

AcceptanceClassificationTooltip.propTypes = propTypes;

export default AcceptanceClassificationTooltip;
