export const DISPLAY_INVALID_EMAILS_MODAL = 'DISPLAY_INVALID_EMAILS_MODAL';
export const CLOSE_INVALID_EMAILS_MODAL = 'CLOSE_INVALID_EMAILS_MODAL';

export const displayInvalidEmailsModal = (invalidEmails) => ({
  type: DISPLAY_INVALID_EMAILS_MODAL,
  invalidEmails,
});

export const closeInvalidEmailsModal = () => ({
  type: CLOSE_INVALID_EMAILS_MODAL,
});
