import {Preferences} from '@capacitor/preferences';
import OneSignal from 'onesignal-cordova-plugin';
import PlatformService from './PlatformService';

export const promptForPushNotifications = async () => {
  if (!PlatformService.isNativeApp()) {
    return;
  }

  // This triggers the Push notifications permission pop up if they are not already enabled.
  // If we've never prompted the user it will prompt pop up asking for permission to send push notifications.
  // If we have prompted the user before it will prompt them to go to the app settings and enable push notifications.
  return OneSignal.Notifications.requestPermission(true);
};

export const onPermissionChange = (callback: (permission: boolean) => void) => {
  if (!PlatformService.isNativeApp()) {
    return;
  }

  OneSignal.Notifications.addEventListener('permissionChange', async (permission) => {
    callback(permission);
  });
};

export const arePushNotificationsEnabled = async (): Promise<boolean> => {
  if (!PlatformService.isNativeApp()) {
    return false;
  }

  return OneSignal.Notifications.getPermissionAsync();
};

// This function is called when we want to only ever prompt once under certain conditions,
// such as when the user first hits full credits, or when they get their first earnings.
// The trackingKey is used to store whether we have prompted the user before.
export const idempotentPromptForPushNotifications = async (trackingKey: string) => {
  if (!PlatformService.isNativeApp()) {
    return;
  }

  if ((await Preferences.get({key: trackingKey})).value) {
    console.log(`Already prompted for push notifications with the key ${trackingKey}`);
    return;
  }

  await Preferences.set({key: trackingKey, value: 'true'});
  await promptForPushNotifications();
};
