import StudentReferrals, {StudentReferralProps} from './StudentReferrals';
import {connect} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function defaultAddReferrals(referrals: any): () => void {
  return (): void => {
    if (referrals && referrals.addField) {
      referrals.addField({contactInfo: ''});
    } else {
      console.error('Missing addField in referrals object');
    }
  };
}

export default connect(null, (dispatch, ownProps: StudentReferralProps) => ({
  addReferral: ownProps.addReferral || defaultAddReferrals(ownProps.referrals),
}))(StudentReferrals);
