import {without} from 'lodash';
import {
  RECEIVE_POTENTIAL_CONNECTED_USERS,
  RECEIVE_POTENTIAL_CONNECTED_USER,
  REMOVE_POTENTIAL_CONNECTED_USER,
} from './actions';

export default function (state = [], action) {
  switch (action.type) {
    case RECEIVE_POTENTIAL_CONNECTED_USERS:
      return action.potentialConnectedUsers;
    case RECEIVE_POTENTIAL_CONNECTED_USER:
      return [...state, action.potentialConnectedUser];
    case REMOVE_POTENTIAL_CONNECTED_USER:
      return without(state, action.potentialConnectedUser);
    default:
      return state;
  }
}
