import {
  RECEIVE_EDUCATOR,
  EDUCATOR_VERIFIED,
  EDUCATOR_EMAIL_CONFIRMED,
} from 'educator/action-types/educator';

const initialState = {
  dashboardStats: null,
};

const educatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_EDUCATOR: {
      return {...state, ...action.payload};
    }
    case EDUCATOR_EMAIL_CONFIRMED: {
      return {
        ...state,
        eligibleForVerificationAt: new Date().getTime(),
        emailVerifiedAt: new Date().getTime(),
      };
    }
    case EDUCATOR_VERIFIED: {
      return {...state, verifiedAt: new Date().getTime()};
    }
    default:
      return state;
  }
};

export default educatorReducer;
