import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {openModal} from 'shared/actions/modal';
import RightRail from 'transfer-student/college-profile/components/right-rail';
import {
  getSimilarPartnerColleges,
  changeTab,
  getOtherCampus,
  toggleFollowCollege,
  EARNING_DEADLINES_MODAL_IDENTIFIER,
  openSubmitEarlyModal,
} from '../actions/college-profile';
import {setApplicationStatus} from 'shared/college-earnings/actions/college-earnings';

const mapStateToProps = ({collegeProfile}) => ({collegeProfile});

const mapDispatchToProps = (dispatch) => {
  const basicActions = bindActionCreators(
    {
      openSubmitEarlyModal,
      getSimilarPartnerColleges,
      changeTab,
      getOtherCampus,
      toggleFollowCollege,
    },
    dispatch
  );

  const otherActions = {
    followCollege(id) {
      dispatch(toggleFollowCollege(id, 'follow'));
    },
    unfollowCollege(id) {
      dispatch(toggleFollowCollege(id, 'unfollow'));
    },
    followSimilarCollege(id, bucketShownEventId) {
      dispatch(toggleFollowCollege(id, 'follow', 'similar', bucketShownEventId));
    },
    unfollowSimilarCollege(id, bucketShownEventId) {
      dispatch(toggleFollowCollege(id, 'unfollow', 'similar', bucketShownEventId));
    },
    followOtherCampus(id) {
      dispatch(toggleFollowCollege(id, 'follow', 'campus'));
    },
    unfollowOtherCampus(id) {
      dispatch(toggleFollowCollege(id, 'unfollow', 'campus'));
    },
    setApplicationStatus(collegeId, status) {
      dispatch(setApplicationStatus(collegeId, status));
    },
    openCollegeEarningDeadlineModal() {
      dispatch(openModal(EARNING_DEADLINES_MODAL_IDENTIFIER));
    },
  };

  return {
    ...basicActions,
    ...otherActions,
  };
};

export const connectComponent = (component) =>
  withRouter(connect(mapStateToProps, mapDispatchToProps)(component));

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RightRail));
