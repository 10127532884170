import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'shared/components/modal';

class SubmitEarlyModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    universityId: PropTypes.string,
    isFollowing: PropTypes.bool,
    earningRelationship: PropTypes.object,
    highSchoolInfo: PropTypes.object,
    submissionDeadline: PropTypes.string,
    collegeName: PropTypes.string,
  };

  render() {
    const {
      show,
      universityId,
      isFollowing,
      earningRelationship,
      highSchoolInfo,
      submissionDeadline,
      collegeName,
      closeModal,
      onSubmit,
    } = this.props;

    const hasSubmitEarlyModal =
      earningRelationship &&
      earningRelationship.statusType !== 'submitted' &&
      highSchoolInfo &&
      highSchoolInfo.senior &&
      isFollowing;

    if (!hasSubmitEarlyModal) {
      return null;
    }

    return (
      <Modal show={show} onClose={closeModal}>
        <Modal.TextHeader onClose={closeModal}>Confirm Early Submission?</Modal.TextHeader>
        <Modal.Body>
          <p className="shared-font body">
            By submitting early, you are locking in your micro-scholarship earnings to
            {collegeName} now. You will no longer earn new micro-scholarships for items added to
            your Portfolio moving forward. Once submitted, you cannot unsubmit your Portfolio.
          </p>

          <p className="shared-font body">
            If you choose not to submit early, your Portfolio and micro-scholarships will be
            automatically submitted on {submissionDeadline}, as long as you continue to have at
            least 15 credits on your Portfolio and meet all other eligibility requirements.
          </p>
        </Modal.Body>
        <Modal.Footer
          primaryButton={{
            text: 'Confirm Submission',
            onClick: () => onSubmit(universityId),
          }}
        />
      </Modal>
    );
  }
}

export default SubmitEarlyModal;
