import req from 'shared/req';

export const DISMISS_MESSAGE = 'DISMISS_MESSAGE';

export function dismissMessageFeatureInteraction(studentId) {
  return () => {
    const url = `/v1/users/${studentId}/feature-interactions/transfer-education-deadline-message`;
    const date = new Date();
    req({
      url,
      method: 'put',
      data: {featureData: {dismissed: true, timestamp: date}},
    });
  };
}

export function dismissMessage() {
  return {
    type: DISMISS_MESSAGE,
  };
}
