import $ from 'jquery';
import {isEmpty} from 'lodash';
import {startLoading, stopLoading} from 'shared/actions/loading';
import {initializeFilters} from 'shared/actions/filters';
import req from 'shared/req';
import {formatEarnings} from 'src/utils/formatters';
import {collegePortalErrorMessage} from 'student-search/error-helpers';

export const UPDATE_RESULTS = 'UPDATE_RESULTS';
export const APPEND_RESULTS = 'APPEND_RESULTS';
export const PAGINATE_RESULTS = 'PAGINATE_RESULTS';
export const FETCHING_RESULTS = 'FETCHING_RESULTS';
export const SET_STUDENT_SEARCH_TYPE = 'SET_STUDENT_SEARCH_TYPE';
export const RECEIVE_TOTAL_STUDENTS_COUNT = 'RECEIVE_TOTAL_STUDENTS_COUNT';

const fetchingResults = () => ({
  type: FETCHING_RESULTS,
});

export const LOADING_IDENTIFIERS = {
  results: 'studentSearchResults',
  count: 'studentSearchTotalResults',
};

export const refreshSearch = () => (dispatch) => {
  dispatch(fetchSearchResults(1));
};

export const fetchSearchResults = (page = 1) => {
  if (typeof page !== 'number') {
    console.warn('Invalid page', page);
    page = 1;
  }

  const newSearch = page === 1;

  return (dispatch, getState) => {
    if (newSearch) {
      dispatch(startLoading(LOADING_IDENTIFIERS.results));
      dispatch(startLoading(LOADING_IDENTIFIERS.count));
    }
    const resultsAction = newSearch ? updateResults : appendResults;
    const filters = {
      ...getState().filters,
    };
    const studentType = getState().studentResults.studentSearchType;
    let searchUrl = '/college-portal/search';
    if (!isEmpty(filters)) {
      searchUrl += `?${$.param(filters)}`;
    }
    dispatch(fetchingResults());

    if (newSearch) {
      req({
        method: 'get',
        url: '/v1/student-search/count',
        data: {filters, studentType: studentType},
      })
        .then(({totalStudentsCount}) => {
          dispatch(receiveTotalStudentCount(totalStudentsCount));
          dispatch(stopLoading(LOADING_IDENTIFIERS.count));
        })
        .catch(() => dispatch(collegePortalErrorMessage()));
    }

    return req({
      method: 'get',
      url: '/v1/student-search',
      data: {filters, page, studentType: studentType},
    })
      .then((response) => {
        window.history.replaceState({}, '', searchUrl);
        const students = formatStudentResults(response);
        dispatch(resultsAction(students));
      })
      .then(() => {
        if (newSearch) {
          dispatch(stopLoading(LOADING_IDENTIFIERS.results));
        }
      })
      .catch(() => dispatch(collegePortalErrorMessage()));
  };
};

const formatStudentResults = (students) => {
  students.forEach((student) => {
    student.current_earnings_str = student.current_earnings
      ? formatEarnings(student.current_earnings).annualAmount()
      : null;
  });

  return students;
};

export const updateResults = (students) => ({
  type: UPDATE_RESULTS,
  students,
});

export const receiveTotalStudentCount = (totalStudentsCount) => ({
  type: RECEIVE_TOTAL_STUDENTS_COUNT,
  totalStudentsCount,
});

export const appendResults = (students) => ({
  type: APPEND_RESULTS,
  students,
});

export const setStudentSearchType = (studentType, columnSettings) => ({
  type: SET_STUDENT_SEARCH_TYPE,
  studentType,
  columnSettings,
});

export const paginateResults =
  (studentType = 'high-school') =>
  (dispatch, getState) => {
    if (!getState().studentResults.paging) {
      const page = getState().studentResults.page + 1;
      dispatch({
        type: PAGINATE_RESULTS,
        page,
      });
      dispatch(fetchSearchResults(page, studentType));
    }
  };

export const resetFilters =
  (newFilters = {}) =>
  (dispatch) => {
    dispatch(initializeFilters(newFilters));
    dispatch(fetchSearchResults());
  };
