import {Box} from '@mui/material';
import React, {lazy, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {Store} from 'redux';
import history from 'shared/history';
import {setUserAuthenticated} from 'shared/req';
import validateSession, {UserSession} from 'src/actions/validateSession';
import {getApp} from 'src/apps';
import {AppDeclaration} from 'src/apps/types';
import AccessibilityAnnouncement from 'src/components/AccessibilityAnnouncement';
import {ApplicationRootWithProvider} from 'src/components/ApplicationRoot';
import {PlatformContext} from 'src/components/PlatformContext';
import Suspense, {Loader} from 'src/components/Suspense';
import {useBoolean} from 'src/hooks/useBoolean';
import {publicReducers} from 'src/redux/auth/reducers/public-reducers';
import {LoadState, UserIdentifier} from 'src/types/enums';
import {configureStore} from 'src/utils/redux';
import {successfulLoginRedirectPath} from 'src/utils/storage';
import urls from 'src/utils/urls';
import vars from 'src/utils/vars';
import {AuthenticationWrapperProps} from './types';
import PublicRootApp from 'src/views/public';
import OneSignal from 'onesignal-cordova-plugin';
import PlatformService from '../../services/PlatformService';

const PublicMobileApplication = lazy(() => import('src/views/public/public-mobile-application'));

declare global {
  interface Window {
    initNotificationClient: (userId: string) => void;
  }
}

const getUnsupportedUserHomepage = (userIdentifier?: UserIdentifier): string => {
  const unsupportedUsers = {
    [UserIdentifier.CP_USER]: '/college-portal',
    [UserIdentifier.DISTRICT_ADMIN]: '/district-admins/schools',
  };

  return (userIdentifier && unsupportedUsers[userIdentifier]) || '/home';
};

function AuthenticationWrapper(props: AuthenticationWrapperProps): ReactElement {
  const {isIonic} = useContext(PlatformContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [store] = useState<Store<any, any>>(
    configureStore(publicReducers, {
      authenticated: false,
      collegeProfile: {userInfo: {user: 'public'}},
    })
  );

  useEffect(() => {
    document.body.classList.add('padding-0');
    return (): void => document.body.classList.remove('padding-0');
  }, []);

  return (
    <ApplicationRootWithProvider store={store}>
      <AccessibilityAnnouncement />
      {isIonic ? (
        <PublicMobileApplication setUserAuthenticated={props.setUserAuthenticated} store={store} />
      ) : (
        <PublicRootApp setUserAuthenticated={props.setUserAuthenticated} />
      )}
    </ApplicationRootWithProvider>
  );
}

function setNextPath(pathname: string): void {
  if (pathname !== '/') {
    history.push(pathname);
  } else if (successfulLoginRedirectPath.value()) {
    history.push(successfulLoginRedirectPath.pop() as string);
  }
}

export default function ApplicationLoader(): ReactElement | null {
  const [loadState, setLoadState] = useState<LoadState>(LoadState.NONE);
  const userAuthenticated = useBoolean(false);
  const {isIonic} = useContext(PlatformContext);

  const [next, setNext] = useState<string>();
  const appRef = useRef<
    Partial<{
      user: UserSession['user'];
      userIdentifier: UserIdentifier;
      appDeclaration: AppDeclaration;
      store: Store<any, any>;
    }>
  >({});

  function refreshApplicationToPath(nextPathname = '/'): void {
    const app = getApp(appRef.current.userIdentifier as UserIdentifier, nextPathname);
    if (app) {
      appRef.current.store = configureStore(app.reducers, app.initialState);
      appRef.current.appDeclaration = app;
      setNext(nextPathname);
    } else {
      console.error('No user application');
    }
  }

  useEffect(() => {
    validateSession([], false)
      .then((session) => {
        // If this is a native app, we need to log the user in to OneSignal to receive push notifications
        if (PlatformService.isNativeApp()) {
          OneSignal.login(session.user.id);
          // our application was set up to target the user using the userId tag
          OneSignal.User.addTags({userId: session.user.id});
        }
        const identifier = session.userIdentifier;
        appRef.current.userIdentifier = identifier;

        const app = getApp(identifier, session.next);
        if (app) {
          // handles main apps
          appRef.current.appDeclaration = app;
          appRef.current.store = configureStore(app.reducers, app.initialState);
        } else if (location.origin === vars.ORIGIN) {
          // handles non-main apps
          const homepage = getUnsupportedUserHomepage(identifier);
          location.assign(homepage);
          return null;
        } else {
          console.warn(`Missing portal for user identifier ${identifier}`);
        }

        setNext(session.next);
        setLoadState(LoadState.LOADED);

        try {
          typeof window.initNotificationClient === 'function' &&
            window.initNotificationClient(session.user.id);
        } catch (error) {
          // don't let errors in here force the app to log out
          console.error(error);
        }

        setUserAuthenticated();
      })
      .catch(() => {
        setLoadState(LoadState.FAILED);
      });
  }, [userAuthenticated.value]);

  useEffect(() => {
    next && setNextPath(next);
  }, [next]);

  if (loadState <= LoadState.LOADING) {
    return <Loader />;
  }

  if (loadState === LoadState.FAILED) {
    return <AuthenticationWrapper setUserAuthenticated={userAuthenticated.setTrue} />;
  }

  const {app: RootApp = null, modalConfigs = []} = appRef.current.appDeclaration || {};

  if (!RootApp || !appRef.current.store) {
    const homepage = getUnsupportedUserHomepage(appRef.current.userIdentifier);
    return (
      <Box
        display="flex"
        height="100vh"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        Unsupported user identifier: {appRef.current.userIdentifier}
        <br />
        <a href={homepage}>Homepage {homepage}</a>
        <br />
        <a href={urls.logout}>Sign Out</a>
      </Box>
    );
  }

  if (isIonic && appRef?.current?.appDeclaration?.mobileApp) {
    return <appRef.current.appDeclaration.mobileApp store={appRef.current.store} />;
  }

  return (
    <ApplicationRootWithProvider
      store={appRef.current.store}
      modalConfigs={modalConfigs}
      scrollOnNavigation
    >
      <>
        <AccessibilityAnnouncement />
        <Suspense>
          <RootApp refreshApplicationToPath={refreshApplicationToPath} />
        </Suspense>
      </>
    </ApplicationRootWithProvider>
  );
}
