import req from 'shared/req';
import {errorMessage, flashMessage} from 'shared/actions/notifications';

export const RECEIVE_COLLEAGUES = 'RECEIVE_COLLEAGUES';
export const RECEIVE_VERIFIED_COLLEAGUE = 'RECEIVE_VERIFIED_COLLEAGUE';

const FIELDS = [
  '_id',
  'firstName',
  'lastName',
  'image',
  'email',
  'isVerified',
  'emailVerifiedAt',
  'eligibleForVerificationAt',
].join(',');

export const fetchColleagues = () => (dispatch) =>
  req({
    url: '/v1/educators',
    method: 'get',
    data: {
      fields: FIELDS,
    },
  })
    .then((colleagues) => {
      dispatch({
        type: RECEIVE_COLLEAGUES,
        colleagues,
      });
    })
    .catch(() => {
      dispatch(errorMessage());
    });

export const receiveVerifiedColleague = (id) => ({
  type: RECEIVE_VERIFIED_COLLEAGUE,
  id,
});

export const verifyColleague =
  ({id}) =>
  (dispatch) =>
    req({
      url: '/v1/educator-verification/verify',
      method: 'post',
      data: {
        _id: id,
      },
    })
      .then((verifiedColleague) => {
        dispatch(receiveVerifiedColleague(verifiedColleague._id));
        dispatch(flashMessage('Thank you, you have verified your colleague!'));
      })
      .catch(() => {
        dispatch(errorMessage());
      });
