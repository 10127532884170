import React from 'react';
import Icon from 'shared/components/icon';
import Card from 'src/components/Cards/Card';
import styles from './styles.module.scss';
import BottomMobileDrawer, {
  DrawerItem,
} from 'src/apps/mobile/components/BottomMobileDrawer/BottomMobileDrawer';
import {useBoolean} from 'src/hooks/useBoolean';
import {useDispatch, useSelector} from 'react-redux';
import useAsyncEffect from 'src/hooks/use-async-effect/use-async-effect';
import {Store} from 'src/types/store';
import {LoadingWrapper} from 'shared/components/loading';
import classnames from 'classnames';
import {
  getCollegeAppStatus,
  setCollegeAppStatus,
} from 'shared/college-application-status/actions/college-application-status';

interface Props {
  college: {
    _id: string;
    applicationWebsite: string;
    financialAidWebsite: string;
    admissionsWebsite: string;
    website: string;
  }; // TODO Tech Debt: correct and use src/types/college
}

const MobileCollegeStatusCard = ({college}: Props) => {
  const show = useBoolean(false);
  const loading = useBoolean(true);
  const dispatch = useDispatch();
  const {applicationWebsite, financialAidWebsite, admissionsWebsite, website} = college;

  const statusMap = {
    undefined: {text: 'Select Application Status'},
    'planning-to-apply': {
      text: 'Planning to Apply',
      messageElement: <a href={applicationWebsite}>Learn about admission</a>,
    },
    applied: {
      text: 'Applied',
      messageElement: <a href={financialAidWebsite}>View Scholarships</a>,
    },
    accepted: {
      text: 'Accepted',
      messageElement: (
        <p>
          Need more info? <a href={admissionsWebsite}>Learn more</a>
        </p>
      ),
    },
    'planning-to-enroll': {
      text: 'Planning to Enroll',
      messageElement: <p>Remember to enroll soon.</p>,
    },
    enrolled: {
      text: 'Enrolled',
      messageElement: <a href={website}>Learn about student life</a>,
    },
    'not-interested': {text: 'Not Interested'},
    undecided: {text: 'Undecided'},
  };

  const drawerItems: DrawerItem[] = Object.entries(statusMap)
    .slice(1)
    .map(([status, value]) => ({
      ...value,
      onClick: () => {
        setCollegeAppStatus(college._id, status);
        dispatch(setCollegeAppStatus(college._id, status));
      },
    }));

  const savedStatus = useSelector((state: Store) => state.collegeApplicationStatus.status);

  useAsyncEffect(async () => {
    await dispatch(getCollegeAppStatus(college._id));
    loading.setFalse();
  }, [college._id, dispatch]);

  return (
    <Card className={classnames(styles.statusCard)}>
      <div
        onClick={show.setTrue}
        className={classnames(styles.cardContent, {[styles.centerContent]: loading.value})}
      >
        <header className="college-profile-progress-card-header">Application Status</header>
        <LoadingWrapper loading={loading.value} className={styles.loading}>
          <div>
            <p className={styles.statusText}>{statusMap[savedStatus]?.text}</p>
            {statusMap[savedStatus]?.messageElement && (
              <p onClick={(e) => e.stopPropagation()}>{statusMap[savedStatus]?.messageElement}</p>
            )}
          </div>
        </LoadingWrapper>
      </div>
      <Icon iconType="chevron-right" onClick={show.setTrue} />
      <BottomMobileDrawer
        header="Select Application Status"
        drawerItems={drawerItems.map((item, index) => ({
          ...item,
          selected: index === Object.keys(statusMap).indexOf(savedStatus),
        }))}
        show={show}
      />
    </Card>
  );
};

export default MobileCollegeStatusCard;
