import {recommendationsReducer} from 'recommendations/redux';
import {reducer as formReducer} from 'redux-form';
import {collegeSearchReducer} from 'src/redux/collegeSearch/reducer';
import {collegeProfileReducer} from 'shared/college-profile/reducers/college-profile';
import collegeApplicationStatusesReducer from 'shared/college-application-status/reducers/college-application-statuses-reducer';
import {filtersReducer} from 'shared/reducers/filters';
import {modalReducer} from 'shared/reducers/modal';
import referralModalReducer from 'educator/referral-modal/reducers';
import {genericReducer} from 'shared/reducers/generic-reducer';
import {booleanReducer} from 'shared/reducers/boolean-reducer';
import {notificationsReducer} from 'shared/reducers/notifications';
import {collegeSearchBarReducer} from 'shared/college-search-bar/reducers';
import notificationSettingsReducer from 'shared/account-settings/reducers/notification-settings';
import tabReducer from 'shared/account-settings/reducers/tab';
import unsavedChangesReducer from 'shared/account-settings/reducers/unsaved-changes';
import officialIdUploadingReducer from 'educator/account-settings/reducers/official-id-uploading';
import invalidEmailsModalReducer from './invalid-emails-modal';
import opportunityCardsReducer from 'shared/micro-scholarships/reducers/opportunity-cards';
import detailedOpportunityCardsReducer from 'shared/micro-scholarships/reducers/detailed-opportunity-cards';
import {loadingReducer} from 'shared/reducers/loading';
import {objectReducer} from 'shared/reducers/object-reducer';
import studentReducer from 'shared/reducers/student';
import {RECEIVE_PHONE_NUMBER} from 'shared/actions/verify-mobile';
import {INITIALIZE_FILTERS} from 'shared/actions/filters';
import educatorReducer from './educator';
import recentStudentsReducer from './recent-students';
import studentsReducer from './students';
import activitiesReducer from './activities';
import emailConfirmationModalReducer from './email-confirmation-modal';
import {RECEIVE_COLLEGES} from 'educator/actions/colleges';
import colleagueReducer from './colleagues';
import {RECEIVE_INSTITUTION_EARNINGS} from 'educator/action-types/institution-earnings';
import {
  RESET_STUDENT_SEARCH_OPTIONS,
  UPDATE_STUDENT_COUNTS,
  UPDATE_STUDENT_SEARCH_OPTIONS,
} from 'educator/action-types/students';
import {DEFAULT_STUDENT_SEARCH_OPTIONS} from 'educator/actions/students';
import {RECEIVE_CASELOAD} from 'educator/actions/caseload';
import notificationCenterReducers from 'shared/notification-center/reducers';
import {
  NO_REMAINING_MESSAGES,
  REMAINING_MESSAGES,
} from 'shared/notification-center/actions/notification-messages';
import toolbarNotificationReducer from 'src/redux/toolbar-notification/reducer';
import checklistReducer from '../../../src/redux/checklist/reducer';
import checklistUserStatusReducer from '../../../src/redux/checklistUserStatus/reducer';

export const educatorReducers = {
  activeTab: tabReducer,
  activities: activitiesReducer,
  caseloadStudentIds: genericReducer({
    actionProperty: 'studentIds',
    defaultState: [],
    setAction: RECEIVE_CASELOAD,
  }),
  checklist: checklistReducer,
  checklistUserStatus: checklistUserStatusReducer,
  colleagues: colleagueReducer,
  collegeApplicationStatuses: collegeApplicationStatusesReducer,
  collegeProfile: collegeProfileReducer,
  collegeSearch: collegeSearchReducer,
  collegeSearchBar: collegeSearchBarReducer,
  colleges: genericReducer({
    actionProperty: 'colleges',
    defaultState: [],
    setAction: RECEIVE_COLLEGES,
  }),
  detailedOpportunityCards: detailedOpportunityCardsReducer,
  educator: educatorReducer,
  emailConfirmationModal: emailConfirmationModalReducer,
  filters: filtersReducer,
  filtersInitialized: booleanReducer({defaultState: false, trueType: INITIALIZE_FILTERS}),
  form: formReducer,
  institutionEarnings: genericReducer({
    actionProperty: 'earnings',
    defaultState: [],
    setAction: RECEIVE_INSTITUTION_EARNINGS,
  }),
  invalidEmails: invalidEmailsModalReducer,
  loadMoreNotificationMessages: booleanReducer({
    defaultState: true,
    falseType: NO_REMAINING_MESSAGES,
    trueType: REMAINING_MESSAGES,
  }),
  loading: loadingReducer,
  microscholarshipsLoading: loadingReducer,
  modal: modalReducer,
  notificationSettings: notificationSettingsReducer,
  notifications: notificationsReducer,
  officialIdUploading: officialIdUploadingReducer,
  opportunityCards: opportunityCardsReducer,
  recentStudents: recentStudentsReducer,
  recommendations: recommendationsReducer,
  referralModal: referralModalReducer,
  student: studentReducer,
  studentCounts: objectReducer({
    actionProperty: 'payload',
    mergeObjectAction: UPDATE_STUDENT_COUNTS,
  }),
  studentSearchOptions: objectReducer({
    actionProperty: 'options',
    defaultState: DEFAULT_STUDENT_SEARCH_OPTIONS,
    mergeObjectAction: UPDATE_STUDENT_SEARCH_OPTIONS,
    replaceObjectAction: RESET_STUDENT_SEARCH_OPTIONS,
  }),
  students: studentsReducer,
  toolbarNotification: toolbarNotificationReducer,
  unsavedChanges: unsavedChangesReducer,
  user: educatorReducer,
  verifyMobile: genericReducer({
    actionProperty: 'payload',
    defaultState: '',
    setAction: RECEIVE_PHONE_NUMBER.toString(),
  }),
  ...notificationCenterReducers,
};
