import req from 'shared/req';
import {errorMessage} from 'shared/actions/notifications';

export const RECEIVE_COLLEGES = 'RECEIVE_COLLEGES';

const FIELDS = [
  'banner',
  'city',
  'earningsPossible',
  'logo',
  'name',
  'partnerSubmissionDeadlineDay',
  'partnerSubmissionDeadlineMonth',
  'partnerSubmissionDeadlineTerm',
  'partnerSubmissionDeadlineYear',
  'slug',
  'state',
].join(',');

export const getColleges = () => (dispatch) =>
  req({
    url: '/v1/colleges',
    method: 'get',
    data: {
      'offering-microscholarships': 1,
      limit: 10,
      fields: FIELDS,
      sort: 'distance',
      direction: 'asc',
    },
  })
    .then((colleges) =>
      dispatch({
        type: RECEIVE_COLLEGES,
        colleges,
      })
    )
    .catch(() => {
      dispatch(errorMessage());
    });
