import {
  DISPLAY_INVALID_EMAILS_MODAL,
  CLOSE_INVALID_EMAILS_MODAL,
} from 'educator/actions/invalid-emails-modal';

const defaultState = {
  invalidEmails: [],
  show: false,
};

export default function referralModalReducer(state = defaultState, action) {
  switch (action.type) {
    case DISPLAY_INVALID_EMAILS_MODAL:
      return {
        invalidEmails: action.invalidEmails,
        show: true,
      };
    case CLOSE_INVALID_EMAILS_MODAL:
      return defaultState;
    default:
      return state;
  }
}
