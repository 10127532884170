import {merge} from 'lodash';
import {decamelizeKeys} from 'humps';
import {normalize} from 'normalizr';
import {activity as activitySchema} from 'shared/portfolio/schemas/activities';
import {portfolioUrl} from 'shared/portfolio/utils';
import req, {rejectCamelizedErrors, rejectGenericError} from 'shared/req';
import {objectId} from 'shared/utils';

export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const EDIT_ACTIVITY = 'EDIT_ACTIVITY';
export const SCROLLED_TO_ACTIVITY = 'SCROLLED_TO_ACTIVITY';
export const RECEIVE_ACTIVITY = 'RECEIVE_ACTIVITY';
export const NEW_ACTIVITY = 'NEW_ACTIVITY';
export const CANCEL_EDIT_ACTIVITY = 'CANCEL_EDIT_ACTIVITY';
export const RECEIVE_ACTIVITY_INSTITUTION_LEADERSHIP_POSITIONS =
  'RECEIVE_ACTIVITY_INSTITUTION_LEADERSHIP_POSITIONS';
export const RECEIVE_POPULAR_EXTRACURRICULAR = 'RECEIVE_POPULAR_EXTRACURRICULAR';
export const SHOW_ACTIVITY_DESCRIPTION = 'SHOW_ACTIVITY_DESCRIPTION';
export const HIDE_ACTIVITY_DESCRIPTION = 'HIDE_ACTIVITY_DESCRIPTION';
export const RECEIVE_ACTIVITIES = 'RECEIVE_ACTIVITIES';
export const RECEIVE_LEADERSHIP_POSITIONS = 'RECEIVE_LEADERSHIP_POSITIONS';

export function deleteActivity(activity) {
  return (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(`/activities/${activity._id}`, _id),
      data: {activity_type: activity.activityType},
      method: 'DELETE',
    }).then(() =>
      dispatch({
        type: DELETE_ACTIVITY,
        id: `activity-${activity._id}`,
      })
    );
  };
}

export function receiveActivity(activity) {
  return {
    type: RECEIVE_ACTIVITY,
    id: `activity-${activity._id}`,
    activity,
  };
}

export function cancelActivity(activity) {
  return {
    type: CANCEL_EDIT_ACTIVITY,
    id: `activity-${activity._id}`,
    activity,
  };
}

export function scrolledToActivity(id) {
  return {
    type: SCROLLED_TO_ACTIVITY,
    id: `activity-${id}`,
  };
}

export function editActivity(activity) {
  return {
    type: EDIT_ACTIVITY,
    id: `activity-${activity._id}`,
    activity,
  };
}

export const saveActivity =
  (activity, scrollToActivity = true) =>
  (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    let url = portfolioUrl('/activities', _id);
    let method;

    if (activity.new) {
      method = 'POST';
    } else {
      url = `${url}/${activity._id}`;
      method = 'PATCH';
    }

    return req({
      url: url,
      method: method,
      data: {activity: decamelizeKeys(activity)},
    })
      .then((receivedActivity) => {
        const normalizedActivity = normalize(receivedActivity, activitySchema);
        const id = normalizedActivity.result;

        if (activity.activitySlug) {
          dispatch({
            type: RECEIVE_POPULAR_EXTRACURRICULAR,
            id,
            extracurricular: activity,
            activitySlug: activity.activitySlug,
          });
        }

        dispatch({
          type: RECEIVE_ACTIVITY_INSTITUTION_LEADERSHIP_POSITIONS,
          id,
          activity: merge({}, normalizedActivity.entities.activities[id], {
            scrollTo: scrollToActivity,
          }),
          institutions: normalizedActivity.entities.institutions || {},
          leadershipPositions: normalizedActivity.entities.leadershipPositions || {},
        });
      })
      .catch(rejectCamelizedErrors)
      .catch(rejectGenericError);
  };

export function newActivity(options = {}) {
  const activity = activityFactory(options);

  return {
    type: NEW_ACTIVITY,
    id: `activity-${activity._id}`,
    activity,
  };
}

export function activityFactory(options = {}) {
  return {
    _id: objectId(),
    activityType: options.activityType || '',
    description: '',
    where: 'school',
    leadershipPositions: [],
    name: '',
    category: '',
    organization: null,
    participation: '',
    schoolYears: [],
    createdAt: Math.floor(Date.now() / 1000),
    editing: true,
    new: true,
    scrollTo: !!options.scrollTo,
  };
}

export const toggleOnActivityDescription = () => ({
  type: SHOW_ACTIVITY_DESCRIPTION,
});

export const toggleOffActivityDescription = () => ({
  type: HIDE_ACTIVITY_DESCRIPTION,
});
