import PropTypes from 'prop-types';
import React from 'react';
import HorizontalBoxPlot from './horizontal-box-plot';
import AcceptanceClassificationTooltip from './acceptance-likelihood/acceptance-classification-tooltip';
import {acceptanceLikelihoodClassification} from 'shared/college-profile/utils';

class SATSection extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    showAdmissability: PropTypes.bool,
  };

  acceptanceLikelihoodClassification() {
    const {
      college: {satCriticalReadingRange, satMathematicsRange},
      userInfo: {satScore},
    } = this.props;

    if (!satCriticalReadingRange || !satMathematicsRange) {
      return '';
    }

    const twentyFifthPercentile = satCriticalReadingRange[0] + satMathematicsRange[0];
    const seventyFifthPercentile = satCriticalReadingRange[1] + satMathematicsRange[1];

    return acceptanceLikelihoodClassification(
      satScore.score,
      twentyFifthPercentile,
      seventyFifthPercentile
    );
  }

  render() {
    const {
      college: {avgSatScore, name},
      userInfo: {satScore},
      showAdmissability,
    } = this.props;

    if (!avgSatScore) {
      return null;
    }

    const points = [
      {
        value: avgSatScore,
        color: showAdmissability ? 'gray' : 'blue',
        label: `Avg Score: ${avgSatScore}`,
      },
    ];

    if (showAdmissability && satScore) {
      points.push({
        value: satScore.score,
        color: 'blue',
      });
    }

    return (
      <div className="college-profile-sat-act">
        <header className="college-profile-sat-act-header">Average SAT Score</header>
        {showAdmissability && (
          <div className="college-profile-sat-act-subheader">
            {satScore ? (
              <span>{`Compare your SAT score to ${name}’s averages.`}</span>
            ) : (
              <a href="/portfolio#scores" className="">
                Enter your SAT Score to compare to SAT averages.
              </a>
            )}
          </div>
        )}
        <div className="college-profile-sat-act-range">
          <div className="college-profile-sat-act-plot">
            {showAdmissability && satScore && (
              <AcceptanceClassificationTooltip
                label="Your SAT"
                studentValue={satScore.score}
                classification={this.acceptanceLikelihoodClassification()}
                xValue={(satScore.score / 1600) * 100}
              />
            )}
            <HorizontalBoxPlot
              max={1600}
              min={0}
              points={points}
              tooltip={showAdmissability && !!satScore}
            />
          </div>
          <div className="college-score-range-label">
            <div className="college-score-range-label-content">{avgSatScore}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default SATSection;
