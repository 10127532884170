import {groupBy, intersection, map} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import SimilarCollegesCard from 'shared/college-profile/containers/similar-colleges-card';
import Card from 'src/components/Cards/Card';
import ResourceCategoriesService from 'src/services/ResourceCategoriesService';
import {UserType} from 'src/types/enums';
import RequirementsCard from '../requirements-card';
import SectionHeader from '../section-header';
import ScholarshipType from './scholarship-type';
import SpotOrReplacementAward from './spot-or-replacement-award';

const SCHOLARSHIP_ORDER = [
  'academic',
  'activity',
  'sport',
  'volunteer',
  'work',
  'scores',
  'honor',
  'visits',
  'multimedia',
  'referral',
  'collegeSuccess',
  'fafsa',
  'degreeProgress',
];

class StudentScholarships extends React.Component {
  static propTypes = {
    collegeProfile: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    showScholarshipCta: PropTypes.bool,
    showScholarshipResources: PropTypes.bool,
    showSpotAwardExplanation: PropTypes.bool,
    linkToScholarshipDetails: PropTypes.bool,
    collapseCardsOnMobile: PropTypes.bool,
  };

  static defaultProps = {
    showScholarshipCta: true,
    showScholarshipResources: true,
    showSpotAwardExplanation: true,
    collapseCardsOnMobile: true,
  };

  state = {resources: {}};

  componentDidMount = () => {
    ResourceCategoriesService.index({
      resourceSection: 'college-profile',
      userType: 'Student',
    }).then((categories) => {
      const resourcesByType = {};
      categories.forEach(({title, resources}) => (resourcesByType[title] = resources));
      this.setState({resources: resourcesByType});
    });
  };
  getModalPendingStatus = (type) => {
    const {modalPending} = this.props.collegeProfile;

    return modalPending && modalPending[type];
  };

  render() {
    const {
      collegeProfile: {
        college,
        college: {
          partnerScholarships,
          amount,
          name,
          financialAidWebsite,
          email,
          _id,
          earningRelationship: {statusType, hasEarnings, churnedAt, spotAward, replacementAward},
        },
        userInfo: {user},
        userInfo,
      },
      openModal,
      showScholarshipCta,
      showScholarshipResources,
      showSpotAwardExplanation,
      linkToScholarshipDetails,
      collapseCardsOnMobile,
    } = this.props;

    const groupedScholarships = groupBy(partnerScholarships, (scholarship) => scholarship.type);
    const awardsReplaced = !!(spotAward || replacementAward);
    let awardName;

    if (awardsReplaced) {
      if (spotAward) {
        awardName = spotAward.name;
      } else if (replacementAward) {
        awardName = replacementAward.displayData.title;
      }
    }

    let display = (
      <div data-testid="student-scholarships">
        {user === UserType.CC_STUDENT && <RequirementsCard college={college} />}
        {showSpotAwardExplanation && awardsReplaced && (
          <>
            <SpotOrReplacementAward name={awardName} amount={amount} sponsorName={name} />
            <div className="college-profile-scholarships-inactive-awards">
              <SectionHeader title="Inactive Awards" />
              <p>
                Your micro-scholarships will become active if the total amount is greater than the{' '}
                <span className="college-profile-scholarships-bold">{awardName}</span>. You will
                receive either amount and not both, whichever is greater.
                <a href="/portfolio"> Add achievements to keep earning micro-scholarships.</a>
              </p>
            </div>
          </>
        )}
        <div
          className={`college-profile-scholarship-breakdown ${
            awardsReplaced ? 'awards-replaced' : ''
          }`}
        >
          {map(intersection(SCHOLARSHIP_ORDER, Object.keys(groupedScholarships)), (type) => (
            <Card key={type}>
              <ScholarshipType
                userInfo={userInfo}
                awardsReplaced={awardsReplaced}
                scholarships={groupedScholarships[type]}
                type={type}
                submitted={statusType === 'submitted'}
                openModal={openModal}
                pendingModal={this.getModalPendingStatus(type)}
                showScholarshipCta={showScholarshipCta}
                showScholarshipResources={showScholarshipResources}
                linkToScholarshipDetails={linkToScholarshipDetails}
                collegeName={name}
                collegeId={_id}
                collapseCardsOnMobile={collapseCardsOnMobile}
                resources={this.state.resources}
              />
            </Card>
          ))}
        </div>
      </div>
    );

    if (statusType === 'churned' && hasEarnings) {
      display = (
        <>
          <Card>
            <p className="college-profile-scholarships-churned-title">
              {name} is No Longer Awarding Scholarships on RaiseMe
            </p>
            <p>
              Do not worry! Your scholarship dollars are not gone! {name} is no longer awarding
              scholarships on RaiseMe, but as of {churnedAt}, you are still on track to earn
              scholarships from {name}.
            </p>
            <p>
              {financialAidWebsite &&
                `Check out the scholarships you are on track
              to earn from ${name} here: ${financialAidWebsite}.`}{' '}
              {email &&
                `Reach out to
              ${email} with any additional questions.`}{' '}
              Keep up your good work!
            </p>
          </Card>
          <SimilarCollegesCard />
        </>
      );
    }

    return display;
  }
}

export default StudentScholarships;
