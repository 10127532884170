import {camelizeKeys} from 'humps';
import {map} from 'lodash';
import {createAction} from 'redux-actions';
import req from 'shared/req';
import {patchFeatureInteraction} from 'shared/actions/feature-interactions';

export const RECEIVE_EARNINGS = 'RECEIVE_EARNINGS';
export const RECEIVED_EARNINGS = 'RECEIVED_EARNINGS';
export const SHOW_NEW_EARNINGS = 'SHOW_NEW_EARNINGS';
export const INCREASE_EARNINGS = 'INCREASE_EARNINGS';
export const DONE_ANIMATING = 'DONE_ANIMATING';

export const SET_APPLICATION_STATUS = 'SET_APPLICATION_STATUS';
export const SETTING_APPLICATION_STATUS = 'SETTING_APPLICATION_STATUS';

export const SET_LOADING = 'SET_LOADING';

export const setEarningFilter = createAction('SET_EARNING_FILTER');

export const RECEIVE_UNDERGRADUATE_PROGRAM_INFORMATION =
  'RECEIVE_UNDERGRADUATE_PROGRAM_INFORMATION';

export function receiveInitialEarnings(initialCount = 10) {
  return (dispatch, getState) =>
    req({
      method: 'get',
      url: `/v1/college-earnings?limit=${initialCount}&offset=${0}`,
    })
      .then((earnings) => {
        dispatch({
          type: RECEIVED_EARNINGS,
          earnings: earnings,
        });

        showNewEarnings(dispatch);
        return earnings;
      })
      .then((initialEarnings) =>
        req({
          method: 'get',
          url: `/v1/college-earnings?limit=${0}&offset=${initialCount}`,
        }).then((secondaryEarnings) => initialEarnings.concat(secondaryEarnings))
      )
      .then((earnings) => {
        dispatch({
          type: RECEIVED_EARNINGS,
          earnings,
        });

        showNewEarnings(dispatch);
      })
      .catch(() => {
        const {earnings} = getState();
        dispatch({
          type: RECEIVED_EARNINGS,
          earnings,
        });
      });
}

export function receiveEarnings(collegeListingIds = [], limit = 0, offset = 0) {
  return (dispatch, getState) =>
    req({
      method: 'get',
      url: `/v1/college-earnings?limit=${limit}&offset=${offset}`,
      data: {collegeListingIds},
    })
      .then((earnings) => {
        dispatch({
          type: RECEIVED_EARNINGS,
          animating: true,
          earnings,
        });

        showNewEarnings(dispatch);
      })
      .catch(() => {
        const {earnings} = getState();
        dispatch({
          type: RECEIVED_EARNINGS,
          earnings,
        });
      });
}

const setAppStatusAction = (appStatus) => ({
  type: SET_APPLICATION_STATUS,
  appStatus: camelizeKeys(appStatus),
});

export function setApplicationStatus(collegeId, appStatus) {
  const indicatorStateAction = (indicatorState) => ({
    type: SETTING_APPLICATION_STATUS,
    collegeId: collegeId,
    indicatorState,
  });

  const timeoutIndicator = (dispatch) =>
    setTimeout(() => dispatch(indicatorStateAction(null)), 2000);

  return (dispatch) => {
    dispatch(indicatorStateAction('loading'));
    return req({
      url: `/v1/colleges/${collegeId}/status`,
      method: 'put',
      data: {status: appStatus},
    })
      .then((newAppStatus) => {
        dispatch(indicatorStateAction('success'));
        dispatch(setAppStatusAction(newAppStatus));
        timeoutIndicator(dispatch);
      })
      .catch(() => {
        dispatch(indicatorStateAction('failure'));
        timeoutIndicator(dispatch);
      });
  };
}

// See college-earnings-normal-text.scss for corresponding css animation slide-up
export function showNewEarnings(dispatch) {
  setTimeout(() => {
    increaseEarningsAnimation(dispatch);
  }, 2500);

  dispatch({type: SHOW_NEW_EARNINGS});
}

// See college-earnings-normal-text.scss for corresponding css animation green-black
export function increaseEarningsAnimation(dispatch) {
  setTimeout(() => {
    dispatch({type: DONE_ANIMATING});
  }, 6000);

  dispatch({type: INCREASE_EARNINGS});
}

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    loading,
  };
}

export function receiveEarningsOrder(earnings) {
  return {
    type: RECEIVE_EARNINGS,
    earnings,
  };
}

export function updateEarningsOrder(earnings) {
  return () => {
    const earningsIds = map(earnings, (earning) => earning._id);

    return patchFeatureInteraction('earnings-sidebar-order', {order: earningsIds});
  };
}
