import {omit, omitBy} from 'lodash';
import {
  RECEIVE_COLLEGE_SUCCESS_ITEMS,
  EDIT_COLLEGE_SUCCESS_ITEM,
  REMOVE_COLLEGE_SUCCESS_ITEM,
} from 'shared/portfolio/actions/college-success-items';
import {REMOVE_FAFSA_SUBMISSION} from 'shared/college-success/actions/fafsa';

export const collegeSuccessItemsReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_COLLEGE_SUCCESS_ITEMS:
      return action.items;
    case EDIT_COLLEGE_SUCCESS_ITEM:
      return {
        ...state,
        [action.item._id]: action.item,
      };
    case REMOVE_COLLEGE_SUCCESS_ITEM:
      return omit(state, action._id);
    case REMOVE_FAFSA_SUBMISSION:
      return omitBy(state, (item) => item._type === 'FafsaSubmissionItem');
    default:
      return state;
  }
};
