import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import HighSchoolStudent from 'shared/img/hs-student.svg';
import CommunityCollegeStudent from 'shared/img/cc-student.svg';
import Educator from 'shared/img/educator.svg';
import ParentOrGuardian from 'shared/img/parent-guardian.svg';

export default class UserSelection extends React.Component {
  static propTypes = {
    setNavigationAnnouncement: PropTypes.func.isRequired,
    ccSelectionGroupUrl: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.props.setNavigationAnnouncement('Sign Up - User Selection');
  }

  render() {
    const {ccSelectionGroupUrl, size} = this.props;
    return (
      <>
        <h1 className="auth-signup-header">Tell us about yourself.</h1>
        <Link to="/signup/hs-student" className="auth-signup-user-link">
          <HighSchoolStudent width={size} height={size} /> I&#39;m a high school student
        </Link>
        <Link to={ccSelectionGroupUrl} className="auth-signup-user-link">
          <CommunityCollegeStudent width={size} height={size} /> I&#39;m a college transfer student
        </Link>
        <Link to="/signup/educator" className="auth-signup-user-link">
          <Educator width={size} height={size} /> I&#39;m an educator
        </Link>
        <Link to="/signup/parent" className="auth-signup-user-link">
          <ParentOrGuardian width={size} height={size} /> I&#39;m a parent or guardian
        </Link>
        <a
          href="https://help.raise.me/hc/en-us/articles/115001992367-Is-RaiseMe-only-for-high-school-students"
          className="auth-shared-link center"
        >
          I&#39;m none of these
        </a>
      </>
    );
  }
}
