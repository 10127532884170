import PropTypes from 'prop-types';
import React from 'react';
import {withCommas} from 'shared/utils';

class Award extends React.Component {
  static propTypes = {
    award: PropTypes.object.isRequired,
  };

  reason(awardFor) {
    return awardFor.charAt(0).toUpperCase() + awardFor.slice(1);
  }

  render() {
    const {
      award,
      award: {amount},
    } = this.props;

    return (
      <div className="college-profile-scholarship-award">
        <div className="college-profile-scholarship-award-amount">
          ${withCommas(Math.round(amount))}
        </div>
        {award.for && (
          <div className="college-profile-scholarship-award-reason">{this.reason(award.for)}.</div>
        )}
      </div>
    );
  }
}

export default Award;
