import PropTypes from 'prop-types';
import React from 'react';
import {map} from 'lodash';
import StatisticsTable from './statistics-table';

class GraduationByEthnicity extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
  };

  static isEmptyWithProps = (props) => !props.college.sixYearGraduationRateByEthnicity;

  getRows() {
    const {
      college: {sixYearGraduationRateByEthnicity},
    } = this.props;

    return map(sixYearGraduationRateByEthnicity, (group) => ({
      name: group.name,
      values: [`${group.percent}%`],
    }));
  }

  render() {
    if (GraduationByEthnicity.isEmptyWithProps(this.props)) {
      return null;
    }

    return <StatisticsTable caption="6-Year Graduation Rate by Ethnicity" rows={this.getRows()} />;
  }
}

export default GraduationByEthnicity;
