import {forEach} from 'lodash';
import {
  RECEIVE_USER_CONNECTIONS,
  REMOVE_USER_CONNECTION,
  RECEIVE_USER_CONNECTION,
} from '../actions/user-connections';

export const userConnectionsReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_USER_CONNECTIONS:
      return action.userConnections;
    case RECEIVE_USER_CONNECTION: {
      const newState = [];

      forEach(state, (userConnection) => {
        if (userConnection._id === action.userConnection._id) {
          newState.push({...action.userConnection});
        } else {
          newState.push({...userConnection});
        }
      });

      return newState;
    }
    case REMOVE_USER_CONNECTION: {
      const newState = [];

      forEach(state, (userConnection) => {
        if (userConnection._id !== action.id) {
          newState.push({...userConnection});
        }
      });

      return newState;
    }
    default:
      return state;
  }
};
