import {DISMISS_MESSAGE} from 'portfolio/components/terms-banner-message/actions';

export const termsBannerMessageReducer = (state = {dismissed: false}, action) => {
  switch (action.type) {
    case DISMISS_MESSAGE:
      return {
        ...state,
        dismissed: true,
      };
    default:
      return state;
  }
};
