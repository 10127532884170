import classnames from 'classnames';
import {kebabCase} from 'lodash';
import PropTypes from 'prop-types';
import React, {cloneElement, Children, Component} from 'react';
import Fade from 'src/components/fade';
import TextButton from 'src/components/TextButton';
import {track} from 'src/utils/analytics';
import 'stylesheets/components/shared-collapsible-teaser-section.scss';

class Teaser extends Component {
  static displayName = 'Teaser';
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return <div className="shared-collapsible-teaser-section-teaser">{this.props.children}</div>;
  }
}

class Body extends Component {
  static displayName = 'Body';
  static propTypes = {
    children: PropTypes.node,
    isExpanded: PropTypes.bool,
    collapsedHeight: PropTypes.number,
  };

  static defaultProps = {
    collapsedHeight: 64,
  };

  render() {
    const {children, collapsedHeight, isExpanded} = this.props;
    const body = <div className="shared-collapsible-teaser-section-body">{children}</div>;

    return isExpanded ? body : <Fade height={collapsedHeight}>{body}</Fade>;
  }
}

export default class CollapsibleTeaserSection extends Component {
  static propTypes = {
    children: PropTypes.node,
    subtitle: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    className: PropTypes.string,
    trackIdentifier: PropTypes.string,
    isCollapsingDisabled: PropTypes.bool,
  };

  static Teaser = Teaser;
  static Body = Body;

  state = {
    isExpanded: false, // Default sections to closed.
  };

  handleTriggerClick = (e) => {
    const {trackIdentifier} = this.props;

    e.preventDefault();

    if (trackIdentifier) {
      track(`${kebabCase(trackIdentifier)}-${this.state.isExpanded ? 'close' : 'open'}`);
    }

    this.setState(({isExpanded}) => ({isExpanded: !isExpanded}));
  };

  renderTitle = () => (
    <div className="shared-collapsible-teaser-section-title">{this.props.title}</div>
  );

  renderSubtitle = () => (
    <div className="shared-collapsible-teaser-section-subtitle">{this.props.subtitle}</div>
  );

  render() {
    const {children, subtitle, className, isCollapsingDisabled} = this.props;
    const {isExpanded} = isCollapsingDisabled ? {isExpanded: true} : this.state;

    return (
      <>
        <section
          className={classnames('shared-collapsible-teaser-section', className, {
            opened: isExpanded,
          })}
          data-testid="collapsible-teaser-section"
        >
          <div className="shared-collapsible-teaser-section-header">
            {this.renderTitle()}
            {subtitle && this.renderSubtitle()}
          </div>

          {Children.map(children, (child) => {
            if (child.type.displayName === 'Teaser') {
              return cloneElement(child);
            }
            if (child.type.displayName === 'Body') {
              return cloneElement(child, {isExpanded});
            }
            return child;
          })}
        </section>
        {isCollapsingDisabled || (
          <TextButton onClick={this.handleTriggerClick} testid="collapsible-teaser-text-button">
            {'Show '}
            {isExpanded ? 'Less' : 'More'}
          </TextButton>
        )}
      </>
    );
  }
}
