import {filter, forEach, includes} from 'lodash';
import req from 'shared/req';
import {flashMessage} from 'shared/actions/notifications';
import {receivePotentialConnectedUser} from 'src/redux/potentialConnectedUsers/actions';

export const RECEIVE_USER_CONNECTIONS = 'RECEIVE_USER_CONNECTIONS';
export const RECEIVE_USER_CONNECTION = 'RECEIVE_USER_CONNECTION';
export const REMOVE_USER_CONNECTION = 'REMOVE_USER_CONNECTION';

export const receiveUserConnections = (userConnections) => ({
  type: RECEIVE_USER_CONNECTIONS,
  userConnections,
});

export const receiveUserConnection = (userConnection) => ({
  type: RECEIVE_USER_CONNECTION,
  userConnection,
});

export const removeUserConnection = (userConnection) => ({
  type: REMOVE_USER_CONNECTION,
  userConnection,
});

const USER_CONNECTION_FIELDS = [
  'status',
  'statusUpdatedAt',
  'userConnectionInfo',
  'type',
  'connectedUser',
].join(',');

export const fetchUserConnections = () => (dispatch) =>
  req({
    url: `/v1/user-connections?fields=${USER_CONNECTION_FIELDS}`,
  }).then((userConnections) => dispatch(receiveUserConnections(userConnections)));

export const deleteUserConnection = (userConnection) => (dispatch) =>
  req({
    url: `/v1/user-connections/${userConnection._id}`,
    method: 'delete',
  }).then(() => dispatch(removeUserConnection(userConnection)));

export const createUserConnection =
  ({
    email = '',
    mobile = '',
    firstName = '',
    lastName = '',
    userType = 'Student',
    permissions = 'full-access',
  }) =>
  (dispatch) =>
    req({
      url: `/v1/user-connections?fields=${USER_CONNECTION_FIELDS}`,
      method: 'post',
      data: {
        email,
        mobile,
        firstName,
        lastName,
        userType,
        permissions,
      },
    }).then((responseData) => {
      if (responseData.type === 'UserConnection') {
        dispatch(receiveUserConnection(responseData));
      } else {
        dispatch(receivePotentialConnectedUser(responseData));
      }
    });

export const resendUserConnection = (userConnectionId) => (dispatch) =>
  req({
    url: `/v1/user-connections/${userConnectionId}/resend`,
    method: 'post',
  }).then(() => dispatch(flashMessage('Invitation resent!')));

export const notifyNewAcceptedUserConnections = () => (dispatch, getState) => {
  const handleAcceptedUserConnectionsResponse = (response = {}) => {
    const {userConnectionIds} = response;
    const {userConnections} = getState();

    const connectionsToNotify = filter(
      userConnections,
      (connection) =>
        connection.status === 'accepted' && !includes(userConnectionIds || [], connection._id)
    );

    const newAcceptedConnectionIds = [];

    forEach(connectionsToNotify, (connection) => {
      const {
        connectedUser: {firstName, lastName},
      } = connection;
      newAcceptedConnectionIds.push(connection._id);
      dispatch(flashMessage(`${firstName} ${lastName} has accepted your invitation to connect.`));
    });

    // if we have any new connections we notified we need to store that on the feature interaction
    // so we won't notify about them in the future
    if (newAcceptedConnectionIds.length) {
      // if we already have userConnectionIds that means the feature interaction exists so we need to update
      // if there were not userConnectionIds then we can create a new feature interaction with the data
      if (userConnectionIds) {
        req({
          url: '/v1/users/me/feature-interactions/accepted-user-connections',
          method: 'patch',
          data: {
            featureData: {
              user_connection_ids: userConnectionIds.concat(newAcceptedConnectionIds),
            },
          },
        });
      } else {
        req({
          url: '/v1/users/me/feature-interactions',
          method: 'post',
          data: {
            featureData: {
              featureName: 'accepted-user-connections',
              user_connection_ids: newAcceptedConnectionIds,
            },
          },
        });
      }
    }
  };

  req({
    url: '/v1/users/me/feature-interactions/accepted-user-connections?fields=userConnectionIds',
  })
    .then(handleAcceptedUserConnectionsResponse)
    .catch(handleAcceptedUserConnectionsResponse);
};

export const mapStudentConnectionErrors = (responseErrors) => {
  const errors = {};

  forEach(responseErrors, ({field, type}) => {
    if (field === 'existing_connection') {
      errors.connectedStudentEmail = 'You already have an existing connection with this student.';
    } else {
      switch (type) {
        case 'EmailInvalidError':
          errors.connectedStudentEmail = 'Email address is invalid';
          break;
        case 'EmailBlankError':
          errors.connectedStudentEmail = 'Must provide a valid email address';
          break;
        case 'DuplicateEmailError':
          errors.connectedStudentEmail = 'Each account needs unique information';
          break;
        case 'DuplicateMobileError':
          errors.connectedStudentMobile = 'Each account needs unique information';
          break;
        case 'MobileInvalidError':
          errors.connectedStudentMobile = 'Mobile number is invalid';
      }
    }
  });

  return errors;
};
