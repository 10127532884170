// modal
import {
  RECEIVE_COLLEGE_SEARCH_DATA,
  RECEIVE_RESULTS,
  UPDATE_QUERY,
  UPDATE_SELECTED,
  RESET_SEARCH,
} from '../actions';

const initialState = {
  collegeSearchBarData: {},
  query: '',
  results: [],
  selected: 0,
};

export const collegeSearchBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case RECEIVE_RESULTS:
      return {
        ...state,
        results: action.results,
      };
    case UPDATE_SELECTED:
      return {
        ...state,
        selected: action.selected,
      };
    case RESET_SEARCH:
      return {
        ...state,
        query: '',
        results: [],
        selected: 0,
      };
    case RECEIVE_COLLEGE_SEARCH_DATA:
      return {
        ...state,
        collegeSearchBarData: action.collegeSearchBarData,
      };
    default:
      return state;
  }
};
