import {get, truncate} from 'lodash';
import {
  compact,
  first,
  flow,
  map,
  maxBy,
  minBy,
  property,
  propertyOf,
  split,
  trim,
} from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'src/components/tooltip';
import Text from 'shared/components/text';
import {withCommas} from 'shared/utils';

const METERS_PER_MILE = 1609.34;
const max = Number.MAX_SAFE_INTEGER;

const undergraduateStudents = ({totalUndergraduates}) =>
  totalUndergraduates && `${withCommas(totalUndergraduates)} undergraduate students`;

const usNewsOrForbesRanking = ({usNewsRank, forbesRank}) =>
  flow(
    minBy('value'),
    property('label')
  )([
    {value: usNewsRank || max, label: `#${usNewsRank} U.S. News Rank`},
    {value: forbesRank || max, label: `#${forbesRank} Forbes Rank`},
    {value: max - 1, label: ''},
  ]);

const mostPopularMajor = (props) => {
  const mostPopularMajorName = get(props, ['majorsParticipation', 0, 'name'], false);
  return (
    mostPopularMajorName && (
      <Tooltip title={`Top major: ${mostPopularMajorName}`} placement="top">
        <span>
          {/* Actually truncate so the tooltip appears over the center of the truncated, not full text */}
          {truncate(`Top major: ${mostPopularMajorName}`, {length: 42, omission: '…'})}
        </span>
      </Tooltip>
    )
  );
};

const distanceFromYou = ({distance}) =>
  distance && `${withCommas(Math.round(distance / METERS_PER_MILE))} miles from you`;

const facultyRatio = ({studentFacultyRatio}) =>
  studentFacultyRatio && `${studentFacultyRatio}:1 student to faculty ratio`;

const highSchoolFacts = ({
  highSchoolFollowerCount,
  highSchoolApplicationStatusBreakdown: {planningToApply, applied, accepted},
}) =>
  flow(
    maxBy('value'),
    property('label')
  )([
    {value: planningToApply, label: `${planningToApply} classmates planning to apply`},
    {value: applied, label: `${applied} classmates applied`},
    {value: accepted, label: `${accepted} classmates accepted`},
    {
      value: 2,
      label:
        highSchoolFollowerCount < 5
          ? 'Be the first to follow'
          : `${withCommas(highSchoolFollowerCount)} classmates following`,
    },
  ]);

export const FIELDS_REQUIRED_BY_BUCKET = {
  small_inregion: ['totalUndergraduates'],
  large_inregion: ['totalUndergraduates'],
  small_nearby: ['totalUndergraduates'],
  large_nearby: ['totalUndergraduates'],
  small: ['totalUndergraduates'],
  large: ['totalUndergraduates'],
  top200: ['usNewsRank', 'forbesRank'],
  top100: ['usNewsRank', 'forbesRank'],
  top50: ['usNewsRank', 'forbesRank'],
  liberal_arts: ['majorsParticipation'],
  research: ['majorsParticipation'],
  northeast: ['distance'],
  midwest: ['distance'],
  south: ['distance'],
  west: ['distance'],
  outside_us: ['distance'],
  territory: ['distance'],
  nearby: ['distance'],
  high_school: ['highSchoolFollowerCount', 'highSchoolApplicationStatusBreakdown'],
  small_classes: ['studentFacultyRatio'],
};

const CONTEXTUAL_DATA_BY_BUCKET = {
  small_inregion: undergraduateStudents,
  large_inregion: undergraduateStudents,
  small_nearby: undergraduateStudents,
  large_nearby: undergraduateStudents,
  small: undergraduateStudents,
  large: undergraduateStudents,
  top200: usNewsOrForbesRanking,
  top100: usNewsOrForbesRanking,
  top50: usNewsOrForbesRanking,
  liberal_arts: mostPopularMajor,
  research: mostPopularMajor,
  northeast: distanceFromYou,
  midwest: distanceFromYou,
  south: distanceFromYou,
  west: distanceFromYou,
  outside_us: distanceFromYou,
  territory: distanceFromYou,
  nearby: distanceFromYou,
  high_school: highSchoolFacts,
  small_classes: facultyRatio,
  // TODO (KARL-1092): implement contextual text for sponsored recommendations
};

export default class ContextualDataText extends React.PureComponent {
  static propTypes = {
    college: PropTypes.shape({
      distance: PropTypes.number,
    }).isRequired,
    bucketID: PropTypes.string,
  };

  render() {
    const {bucketID, college} = this.props;

    const contextualDataFunction = flow(
      split(';'), // Combined buckets ID format: "bucket1; bucket2"
      map(trim),
      map(propertyOf(CONTEXTUAL_DATA_BY_BUCKET)),
      compact,
      first
    )(bucketID);

    let contextValue;

    try {
      contextValue =
        typeof contextualDataFunction === 'function' ? contextualDataFunction(college) : null;
    } catch (error) {
      console.error(
        `Failed to get context value fn[${contextualDataFunction.name}]`,
        error.toString()
      );
      return null;
    }

    if (!contextValue) return null;

    return (
      <div className="shared-college-card-contextual-data-text">
        <Text color="blue" weight={6}>
          {contextValue}&nbsp;
        </Text>
      </div>
    );
  }
}
