import React, {ReactElement, useEffect} from 'react';
import {connect} from 'react-redux';
import {setAccessibilityAnnouncement} from 'shared/actions/accessibility-announcement';
import {Store} from 'src/types/store';

interface AccessibilityAnnouncementProps {
  accessibilityAnnouncement: string;
  setAccessibilityAnnouncement: (announcement: string) => void;
}

function AccessibilityAnnouncement(props: AccessibilityAnnouncementProps): ReactElement {
  useEffect(() => {
    // Delay clearing of the announcement so the screen reader
    // doesn't skip the actual announcement.
    if (props.accessibilityAnnouncement) {
      setTimeout(() => props.setAccessibilityAnnouncement(''), 500);
    }
  }, [props.accessibilityAnnouncement]);

  return (
    <div className="sr-only" role="status" aria-live="polite" aria-atomic="true">
      {props.accessibilityAnnouncement && <span>{props.accessibilityAnnouncement}</span>}
    </div>
  );
}

export default connect(
  (state: Store) => ({accessibilityAnnouncement: state.accessibilityAnnouncement}),
  {
    setAccessibilityAnnouncement,
  }
)(AccessibilityAnnouncement);
