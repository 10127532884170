import req from 'shared/req';

export const RECEIVE_SCHOLARSHIP = 'RECEIVE_SCHOLARSHIP';
export const CLEAR_SCHOLARSHIP = 'CLEAR_SCHOLARSHIP';

export const getScholarship = (scholarshipId) => (dispatch) =>
  req({
    url: `/v1/scholarships/${scholarshipId}`,
  }).then((scholarship) => {
    dispatch(receiveScholarship(scholarship));
  });

export const receiveScholarship = (scholarship) => ({
  type: RECEIVE_SCHOLARSHIP,
  scholarship,
});

export const clearScholarship = () => ({
  type: CLEAR_SCHOLARSHIP,
});
