import PropTypes from 'prop-types';
import React from 'react';
import {map} from 'lodash';
import classnames from 'classnames';
import Icon from 'shared/components/icon';
import List from 'shared/components/menu-item-list';
import MenuButton from 'shared/components/menu-button';
import MenuItem from 'shared/components/menu-item';
import ShowHideButton from 'shared/components/show-hide-button';
import {getMedia, watchMedia} from 'shared/mq';
import {STATUS_LABELS} from 'shared/college-application-status/utils';

class CollegeApplicationStatusMenu extends React.Component {
  static propTypes = {
    collegeApplicationStatus: PropTypes.object,
    className: PropTypes.string,
    menuButtonColor: PropTypes.string,
    college: PropTypes.object,
    getCollegeAppStatus: PropTypes.func,
    setCollegeAppStatus: PropTypes.func.isRequired,
    showStatusContent: PropTypes.bool.isRequired,
    showKebabOnMobile: PropTypes.bool.isRequired,
    forceCompactView: PropTypes.bool,
    segmentEventLocation: PropTypes.string,
  };

  static defaultProps = {
    showStatusContent: true,
    showKebabOnMobile: true,
  };

  componentWillUnmount() {
    this.mq.remove();
  }

  componentDidMount() {
    const {getCollegeAppStatus} = this.props;

    if (getCollegeAppStatus) {
      getCollegeAppStatus(this.collegeId());
    }

    this.mq = watchMedia(() => this.forceUpdate());
  }

  statusContent = (status) => {
    const {
      college: {website, admissionsWebsite, applicationWebsite, financialAidWebsite},
    } = this.props;

    switch (status) {
      case 'planning-to-apply':
        return <a href={applicationWebsite}>Learn about admission</a>;
      case 'applied':
        return <a href={financialAidWebsite}>View Scholarships</a>;
      case 'accepted':
        return (
          <p>
            Need more info? <a href={admissionsWebsite}>Learn more</a>
          </p>
        );
      case 'planning-to-enroll':
        return <p>Remember to enroll soon.</p>;
      case 'enrolled':
        return <a href={website}>Learn about student life</a>;
      case 'undecided':
        return (
          <p>
            Need more info? <a href={admissionsWebsite}>Learn more</a>
          </p>
        );
      default:
        '';
    }
  };

  collegeId = () => {
    const {college} = this.props;
    return college.id || college._id;
  };

  menuItems = () => {
    const {setCollegeAppStatus, collegeApplicationStatus, segmentEventLocation} = this.props;

    return map(STATUS_LABELS, (itemName, key) => (
      <MenuItem
        className="college-application-status-menu-item"
        name={itemName}
        key={key}
        checked={collegeApplicationStatus && key === collegeApplicationStatus.status}
        onClick={() => setCollegeAppStatus(this.collegeId(), key, segmentEventLocation)}
      />
    ));
  };

  getClassNames = () => {
    const {className} = this.props;
    return classnames(className);
  };

  render() {
    const {
      collegeApplicationStatus,
      showStatusContent,
      setCollegeAppStatus,
      menuButtonColor,
      showKebabOnMobile,
      forceCompactView,
    } = this.props;

    const mq = getMedia();

    const isMobile = mq.SM_AND_DOWN && showKebabOnMobile;
    const isCompactView = isMobile || forceCompactView;

    const ButtonListMenu = ShowHideButton('ButtonListMenu', MenuButton, List);
    const IconListMenu = ShowHideButton('IconListMenu', Icon, List);

    const ListMenu = isCompactView ? IconListMenu : ButtonListMenu;

    const buttonMenuParentProps = {
      children:
        (collegeApplicationStatus && STATUS_LABELS[collegeApplicationStatus.status]) ||
        'Select Application Status',
      onClick: setCollegeAppStatus,
      color: (collegeApplicationStatus && menuButtonColor) || 'dark',
    };

    const extraClickAreaPixels = 5;
    const iconListParentProps = {
      iconType: 'ellipsis-v',
      className: 'default white-icon',
      style: {
        cursor: 'pointer',
        margin: `-${extraClickAreaPixels}px`,
        padding: `${extraClickAreaPixels}px`,
      },
    };

    return (
      <div
        className={this.getClassNames()}
        data-testid={`application-status-menu-${this.props.college._id}`}
      >
        <div
          className="college-application-status-menu-content"
          data-testid={collegeApplicationStatus?.status}
        >
          {showStatusContent &&
            collegeApplicationStatus &&
            this.statusContent(collegeApplicationStatus.status)}
        </div>
        <ListMenu
          parentProps={isCompactView ? iconListParentProps : buttonMenuParentProps}
          childProps={{children: this.menuItems()}}
          className="college-app-status-menu-container"
        />
      </div>
    );
  }
}

export default CollegeApplicationStatusMenu;
