import {RECEIVE_RECOMMENDED_COLLEGE_IDS} from 'shared/registration/actions/recommended-college-ids';

const recommendedCollegeIdsReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_RECOMMENDED_COLLEGE_IDS:
      return action.ids;
    default:
      return state;
  }
};

export default recommendedCollegeIdsReducer;
