import React, {useRef, useState, useEffect} from 'react';
import classnames from 'classnames';
import usePlatformContext from 'src/hooks/usePlatform';

interface Props {
  children: React.ReactNode;
  top?: number;
  innerZ?: number;
  enabled?: boolean;
}

const Sticky = ({children, top = 0, innerZ, enabled = true}: Props) => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const {isIonic} = usePlatformContext();

  useEffect(() => {
    const stickyRefElement = stickyRef.current;

    if (!stickyRefElement) return;

    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(enabled && e.intersectionRatio < 1),
      {
        rootMargin: `${-(top + 1)}px 0px 0px 0px`,
        threshold: 1,
        ...(isIonic && {root: document.querySelector('ion-content')}),
      }
    );

    observer.observe(stickyRefElement);

    return (): void => {
      observer.unobserve(stickyRefElement);
    };
  }, [top, enabled, isIonic]);

  return (
    <div
      style={{position: enabled ? 'sticky' : 'static', top, zIndex: innerZ}}
      className={classnames('sticky-outer-wrapper', {active: isSticky})}
      ref={stickyRef}
    >
      {children}
    </div>
  );
};

export default Sticky;
