import {Formik, Form, FormikHelpers} from 'formik';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import Label from 'shared/components/label';
import GoogleOAuth from 'shared/modules/google-oauth';
import FormButton from 'src/components/Button';
import FormikCredentialsInput, {
  loginCredentialsInputFormSchema,
} from 'src/components/inputs/formik-credentials-input/formik-credentials-input';
import Layout from 'src/views/public/shared/layout';
import {getReferralDataFromUrl} from 'src/views/public/utils';
import Yup from 'src/utils/yup';
import errors from 'src/static/errors.json';
import {setNavigationAnnouncement} from 'shared/actions/accessibility-announcement';
import req from 'shared/req';
import {parseQueryString} from 'shared/utils';
import {removeParams} from 'src/redux/auth/actions/url-parameters';
import {errorMessage} from 'shared/actions/notifications';
import FormikPasswordInput from '../inputs/formik-password-input';

const handleRedirect = (response, currentState?: {urlParameters}): void => {
  const urlParameters = currentState?.urlParameters;

  /**
   * if the user tried to access login protected page while they were logged out they would
   * have been redirected to the login page with a "next" parameter in the url indicated where
   * they were trying to go, this handles redirecting there if applicable
   */
  const {next} = parseQueryString((urlParameters || window.location.search).slice(1));
  removeParams();

  window.location = next || response.url;
};

const updateUserPassword = async (dispatch, values, formikHelpers: FormikHelpers<any>) => {
  let response;
  try {
    response = await req({
      url: '/v1/basic/password?cookie=true&webapp=true&fields=_id',
      method: 'post',
      data: {
        ...getReferralDataFromUrl(),
        ...values,
      },
    });

    handleRedirect(response);
  } catch (caughtErrors) {
    (caughtErrors as {type: string}[]).forEach((error) => {
      if (error.type === 'UserInvalid') {
        formikHelpers.setErrors({
          identifier:
            'There is an issue with your account. Please email support@raise.me so they can assist you in resolving it. Thank you!',
        });
      } else {
        dispatch(errorMessage('Oops! Something went wrong with logging you in'));
      }
      console.error(error);
    });
  }
};

const setPasswordValidationSchema = Yup.object()
  .shape({
    newPassword: Yup.string()
      .required(errors.validation.password.required)
      .password(errors.validation.password.invalid),
    newPasswordConfirmation: Yup.string()
      .required(errors.validation.password.required)
      .password(errors.validation.password.invalid)
      .oneOf([Yup.ref('newPassword'), null], errors.validation.password.mismatch),
  })
  .concat(loginCredentialsInputFormSchema);

const SetPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation<{hideCredentials?: boolean}>();
  const hasHiddenCredentials = location.state?.hideCredentials;
  const hiddenCredentials = location.state?.hiddenCredentials;

  useEffect(() => {
    dispatch(setNavigationAnnouncement('Set Password'));
    GoogleOAuth.init([]);
  }, [dispatch]);

  return (
    <Layout page="login">
      <div className="auth-login auth-shared-credentials-container">
        <h1 className="auth-login-welcome-header">
          To increase the security of your account, we are requiring all users to update their
          passwords.
        </h1>
        <Formik
          initialValues={{
            identifier: hasHiddenCredentials ? hiddenCredentials.identifier : '',
            password: hasHiddenCredentials ? hiddenCredentials.password : '',
            newPassword: '',
            newPasswordConfirmation: '',
          }}
          onSubmit={async (values, formikHelpers) =>
            updateUserPassword(dispatch, values, formikHelpers)
          }
          validationSchema={setPasswordValidationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              {!hasHiddenCredentials && (
                <FormikCredentialsInput passwordHeader="Current Password" />
              )}
              <div className="oauth-credentials-input">
                <Label required>
                  New Password
                  <FormikPasswordInput signUp name="newPassword" />
                </Label>
                <Label required>
                  Confirm New Password
                  <FormikPasswordInput
                    useFormik
                    signUp
                    name="newPasswordConfirmation"
                    cy="desktop-new-password-confirmation-input"
                  />
                </Label>
              </div>
              <FormButton
                size="large"
                type="submit"
                className="auth-shared-submit-button"
                width="100%"
                disabled={formik.isSubmitting || !formik.isValid}
                cy="desktop-login-submit-button"
                id="desktop-login-submit-button"
              >
                {formik.isSubmitting ? 'Updating Password...' : 'Update Password'}
              </FormButton>
            </Form>
          )}
        </Formik>
        <Link to="/forgot-password" className="auth-shared-link auth-login-forgot-password">
          Forgot your password?
        </Link>
        <p className="auth-login-no-account">
          Don&#39;t have an account?{' '}
          <Link to="/signup" className="auth-shared-link">
            Sign up here
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default SetPassword;
