import React from 'react';
import {Props as TextInputProps} from './text-input';
import FormikTextInput from 'src/components/inputs/formik-text-input';
import {SpecificInputProps} from './utils';

/* 
Unlike some other input types the react <input type="email"> element has a pattern by default
*/

const FormikEmailInput = ({name = 'identifier', ...props}: TextInputProps & SpecificInputProps) => (
  <FormikTextInput {...props} name={name} type="email" autoComplete="email" />
);

export default FormikEmailInput;
