import {ObjectOf} from 'src/types/store';
import {ISODateFormat} from 'src/types/onboarding';
import {format} from 'date-fns';
import {parseISOLocal} from 'src/utils/helpers';
import {ApiError} from 'src/utils/errors/ApiErrorHandler';
import {withCommas} from 'shared/utils';

const finiteAndGreaterThanZero = (x: number): boolean => Number.isFinite(x) && x > 0;

export function formatEarnings(
  amount: number,
  divisor = 4
): {
  earningsPresent: boolean;
  annualAmount: () => string;
  termAmount: () => string;
  termDescription: () => string;
  divisorSuffix: () => string;
} {
  const earningsPresent = finiteAndGreaterThanZero(amount);
  const divisorPresent = finiteAndGreaterThanZero(divisor);
  if (!divisorPresent) {
    console.error(`[${divisor}] Divisor should be finite and greater than zero`);
  }

  const annualAmount = (): string => (earningsPresent ? withCommas(Math.round(amount)) : '0');

  const termAmount = (): string =>
    earningsPresent && divisorPresent ? withCommas(Math.round(amount / divisor)) : '0';

  const divisorSuffix = (): string => (divisor === 1 ? 'total award' : 'per year');

  const termDescription = (): string => `${termAmount()} ${divisorSuffix()}`;

  return {
    earningsPresent,
    annualAmount,
    termAmount,
    termDescription,
    divisorSuffix,
  };
}

export const formatMobile = (mobile): string =>
  mobile ? `${mobile.slice(0, 3)}-${mobile.slice(3, 6)}-${mobile.slice(6, 10)}` : '';
export const roundToTenth = (value): string => value.toFixed(1);
export const roundToHundredth = (value): string =>
  Number.isInteger(value)
    ? Number(value).toLocaleString(undefined)
    : Number.isFinite(value)
    ? Number(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    : '0';

export function truncatedDecimals(num: number): string {
  // @ts-expect-error
  return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
}

// https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
export function toSIFormat(num, digits = 1): string {
  const si = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

/**
 * If the number is greater than max it will format using JS locale
 * @param count Count of numbers
 * @param shorthandMax Max count before using SI units
 */
export const formatShortCount = (count: number, shorthandMax = 1e6): string => {
  if (count > shorthandMax) return toSIFormat(count, 1);
  return count.toLocaleString();
};

/**
 * @deprecated Use FormFieldError.getFieldErrors()
 */
export function getFieldErrors(validationErrors: ApiError[]): ObjectOf<string> {
  return validationErrors.reduce((fieldErrors, fieldError) => {
    if (fieldError.field) {
      fieldErrors[fieldError.field] = fieldError.message;
    } else {
      console.warn('Missing field property', fieldError);
    }
    return fieldErrors;
  }, {});
}

export function createDateFormatter(
  dateFormat = 'MMM, d yyyy'
): (isoDate: ISODateFormat) => string {
  return function formatInstance(isoDate: ISODateFormat): string {
    try {
      return format(parseISOLocal(isoDate), dateFormat);
    } catch (error) {
      console.log('Failed to parse', {dateFormat, isoDate, error});
      return '';
    }
  };
}

export const formatShortMonthAndYear = (date: Date): string => format(date, "MMM ''yy");
