import classnames from 'classnames';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AboutCollege from 'shared/college-profile/components/about-college';
import LoadingBody from 'shared/college-profile/components/loading-body';
import LoadingCard from 'shared/college-profile/components/loading-card';
import Requirements from 'shared/college-profile/components/requirements';
import Scholarships from 'shared/college-profile/components/scholarships';
import {HIDE_STATUSES} from 'shared/college-profile/components/scholarships/constants';
// Render ModalSection here so it can be displayed from all tabs
import ModalSection from 'shared/college-profile/components/scholarships/modal-section';
import RoutedTabs from 'src/components/routed-tabs';
import {getMedia, watchMedia} from 'shared/mq';
import Sticky from 'src/components/Sticky';
import {AppLayoutContext} from 'src/components/app-layout';
import {track} from 'src/utils/analytics';
import CollegeDetailNav from './college-detail-nav';
import {withPlatformContext} from '../../../src/hooks/usePlatform';

class CollegeTabNav extends React.Component {
  static propTypes = {
    showAllScholarshipPrograms: PropTypes.bool,
    // allow passing in either a boolean or a function that accepts the college object to determine if we show the requirements tab
    hideRequirements: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    hideScholarships: PropTypes.bool,
    customRequirementsTitle: PropTypes.string,
    showCollegeDetailNav: PropTypes.bool,
    nonStudent: PropTypes.bool,
    RightRail: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    showGPASection: PropTypes.bool,
    showTestsSection: PropTypes.bool,
    showCostsAndFinancialAid: PropTypes.bool,
    showTestScores: PropTypes.bool,
    recommendationsShownEventId: PropTypes.string,
    showAbout: PropTypes.bool,
    linkToScholarshipDetails: PropTypes.bool,
    Requirements: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    showRightRailOnScholarships: PropTypes.bool,
    collapseCardsOnMobile: PropTypes.bool,
    followCollege: PropTypes.func.isRequired,
    unfollowCollege: PropTypes.func.isRequired,
    platformContext: {
      isIonic: PropTypes.bool,
    },
    collegeProfile: PropTypes.shape({
      college: PropTypes.shape({
        scholarshipPrograms: PropTypes.object,
        earningRelationship: PropTypes.object,
        state: PropTypes.bool,
        name: PropTypes.string,
        amount: PropTypes.number,
        _id: PropTypes.string,
        isFollowing: PropTypes.bool,
        isPartner: PropTypes.bool,
      }),
      loadingScholarships: PropTypes.bool,
      loading: PropTypes.bool,
      userInfo: PropTypes.object,
      testimonials: PropTypes.array,
      activeScholarshipProgram: PropTypes.object,
    }).isRequired,
  };

  static defaultProps = {
    showAbout: true,
    showCollegeDetailNav: true,
  };

  static contextType = AppLayoutContext;

  componentDidMount() {
    this.mq = watchMedia(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.mq.remove();
  }

  handleTabClick = (tab) => {
    const mq = getMedia();
    let y = window.scrollY;

    if (mq.XS) {
      y = Math.min(160, y);
    } else if (mq.SM || mq.MS) {
      y = Math.min(250, y);
    } else if (mq.MD) {
      y = Math.min(300, y);
    } else if (mq.LG) {
      y = Math.min(350, y);
    }

    window.scrollTo(0, y);
    track(`college-listing-${tab}-tab`);
  };

  AboutCollege = () => {
    const {
      collegeProfile: {college, userInfo, testimonials},
      recommendationsShownEventId,
      RightRail,
      showGPASection,
      showTestsSection,
      showCostsAndFinancialAid,
      showTestScores,
    } = this.props;

    const mq = getMedia();

    let state;

    if (userInfo) {
      state = userInfo.state || college.state;
    }

    return (
      <>
        {mq.SM_AND_DOWN && <RightRail recommendationsShownEventId={recommendationsShownEventId} />}
        <AboutCollege
          userInfo={userInfo}
          testimonials={testimonials}
          college={college}
          userState={state}
          showGPASection={showGPASection}
          showTestsSection={showTestsSection}
          showCostsAndFinancialAid={showCostsAndFinancialAid}
          showTestScores={showTestScores}
        />
      </>
    );
  };

  Requirements = () => {
    const {Requirements: CustomRequirements} = this.props;

    if (CustomRequirements) {
      return <CustomRequirements />;
    } else {
      return <Requirements college={this.props.collegeProfile.college} />;
    }
  };

  Scholarships = () => {
    const {
      RightRail,
      recommendationsShownEventId,
      showRightRailOnScholarships,
      collapseCardsOnMobile,
    } = this.props;

    const mq = getMedia();

    return (
      <>
        {mq.SM_AND_DOWN && showRightRailOnScholarships && (
          <RightRail recommendationsShownEventId={recommendationsShownEventId} />
        )}
        <Scholarships
          name={name}
          nonStudent={this.props.nonStudent}
          linkToScholarshipDetails={this.props.linkToScholarshipDetails}
          loading={this.props.collegeProfile.loadingScholarships}
          collapseCardsOnMobile={collapseCardsOnMobile}
        />
      </>
    );
  };

  render() {
    const {
      collegeProfile: {college, loading},
      showAllScholarshipPrograms,
      hideScholarships,
      hideRequirements,
      nonStudent,
      showAbout,
      customRequirementsTitle,
      platformContext: {isIonic},
      showCollegeDetailNav,
    } = this.props;

    const topToolbarHeight = isIonic ? 0 : this.context.topToolbarHeight;

    const mq = getMedia();

    if (loading) {
      return (
        <div className="college-tab-nav">
          <div className="college-tab-nav-tab-container college-tab-nav-single-page">
            <div className="college-tab-nav-tab-body">
              {mq.SM_AND_DOWN && <LoadingCard college={college} />}
              <LoadingBody />
            </div>
          </div>
        </div>
      );
    }

    const {earningRelationship, scholarshipPrograms} = college;
    // Only show scholarships tab if the user is a student (HS or CC) and does not have
    // an earning relationship in HIDE_STATUSES, or is not a student and the college has
    // one or more valid scholarship programs.
    let showScholarships;
    if (hideScholarships) {
      showScholarships = false;
    } else {
      showScholarships = showAllScholarshipPrograms
        ? !isEmpty(scholarshipPrograms)
        : HIDE_STATUSES.indexOf(earningRelationship.statusType) < 0;
    }

    // Only show requirements tab if the user is a HS student, can see the scholarships tab,
    // and the college is not churned.
    let showRequirements;
    if (hideRequirements) {
      if (typeof hideRequirements === 'function') {
        showRequirements = !hideRequirements(college);
      } else {
        showRequirements = false;
      }
    } else {
      showRequirements =
        showScholarships && earningRelationship && earningRelationship.statusType !== 'churned';
    }

    const tabs = [];

    if (showAbout) {
      tabs.push({
        label: 'About',
        onClick: () => this.handleTabClick('about'),
        path: '',
        skipScrolling: true,
      });
    }

    if (showScholarships) {
      tabs.push({
        label: 'Scholarships',
        onClick: () => this.handleTabClick('scholarships'),
        path: '/scholarships',
        skipScrolling: true,
      });
    }

    if (showRequirements) {
      tabs.push({
        label: customRequirementsTitle || 'Requirements',
        onClick: () => this.handleTabClick('requirements'),
        path: '/requirements',
        skipScrolling: true,
      });
    }

    const hasTabs = tabs.length > 1;

    const detailNavBarHeight = showCollegeDetailNav ? 64 : 0;

    return (
      <>
        {mq.MS_AND_DOWN && showCollegeDetailNav && (
          <Sticky top={topToolbarHeight} innerZ={4}>
            <div className="college-detail-nav-container">
              <CollegeDetailNav
                schoolName={college.name}
                amount={college.amount}
                isPartner={college.isPartner}
                _id={college._id}
                following={college.isFollowing}
                followCollege={this.props.followCollege}
                unfollowCollege={this.props.unfollowCollege}
                userType={this.props.collegeProfile.userInfo.user}
              />
            </div>
          </Sticky>
        )}
        <Sticky
          top={mq.MS_AND_DOWN ? detailNavBarHeight + topToolbarHeight : topToolbarHeight}
          innerZ={3}
        >
          {hasTabs && (
            <div className="college-tab-nav-tab-list">
              <div className="college-tab-nav-tab-container">
                <RoutedTabs tabs={tabs} />
              </div>
            </div>
          )}
        </Sticky>
        <div className="college-tab-nav">
          {!nonStudent && <ModalSection />}
          <div
            className={classnames({
              'college-tab-nav-tab-body-container': hasTabs,
              'college-tab-nav-tab-container': !hasTabs,
              'college-tab-nav-single-page': !hasTabs,
            })}
          >
            <div className="college-tab-nav-tab-body">
              <Switch>
                {showScholarships && <Route path="*/scholarships" component={this.Scholarships} />}
                {showRequirements && <Route path="*/requirements" component={this.Requirements} />}
                {showAbout && <Route path="*" component={this.AboutCollege} />}
              </Switch>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withPlatformContext(CollegeTabNav);
