import 'src/utils/polyfill';
import 'jquery-ujs';
import 'shared/snippets/raise';
import 'shared/cookie-preference';
import 'static/shared/analytics';
import 'shared/snippets/breaking-change-notification';
import 'shared/snippets/new-tab-tooltip';
import 'shared/application/socket-connection';
import 'stylesheets/application.scss';
import {ready} from 'shared/utils';
import {createRoot} from 'react-dom/client';
import React from 'react';
import ApplicationLoader from './components/ApplicationLoader';
import {PlatformContextProvider} from 'src/components/PlatformContext';
import PlatformService, {AppPlatform} from './services/PlatformService';
import {setupLibraries} from 'src/apps/mobile/setupLibraries';

const App = ({isIonicBuild}: {isIonicBuild: boolean}) => {
  const forcePlatform = isIonicBuild ? AppPlatform.IONIC : undefined;

  return (
    <PlatformContextProvider forcePlatform={forcePlatform}>
      <ApplicationLoader />
    </PlatformContextProvider>
  );
};

ready(() => {
  const isIonicBuild = PlatformService.isNativeApp();

  if (isIonicBuild) {
    document.addEventListener('deviceready', setupLibraries, {capture: true});
  }
  const rootElement = document.getElementById('root-app');
  if (!rootElement) {
    console.error('Missing root element');
    return;
  }

  const root = createRoot(rootElement);
  root.render(<App isIonicBuild={isIonicBuild} />);
});
