import {EducationPhase} from 'src/types/enums';
import {isEmpty} from 'lodash';

export const selectDefaultPhase = (educationPhasesDownloadable, initialPhase = null) => {
  const educationPhasePresent = new Set(educationPhasesDownloadable);

  switch (true) {
    case !isEmpty(initialPhase) && educationPhasePresent.has(initialPhase):
      return initialPhase;
    case educationPhasePresent.has(EducationPhase.HIGH_SCHOOL):
      return EducationPhase.HIGH_SCHOOL;
    case educationPhasePresent.has(EducationPhase.COMMUNITY_COLLEGE):
      return EducationPhase.COMMUNITY_COLLEGE;
    case educationPhasePresent.has(EducationPhase.UNDERGRADUATE):
      return EducationPhase.UNDERGRADUATE;
    case educationPhasePresent.has(EducationPhase.ADULT_LEARNER):
      return EducationPhase.ADULT_LEARNER;
    default:
      return EducationPhase.HIGH_SCHOOL;
  }
};
