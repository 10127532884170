import {merge} from 'lodash';
import {
  RECEIVE_ATTENDANCE,
  RECEIVE_COURSE_PRESELECTS,
  RECEIVE_GRADES,
} from 'shared/portfolio/actions/courses';
import {RECEIVE_COLLEGE_COURSE_INSTITUTION_GRADES_PRESELECTS} from 'shared/portfolio/actions/college-courses';

export const attendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_ATTENDANCE:
      return {
        ...state,
        ...action.attendance,
      };
    default:
      return state;
  }
};

export const gradesReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_COLLEGE_COURSE_INSTITUTION_GRADES_PRESELECTS:
      return merge({}, state, action.grades);
    case RECEIVE_GRADES:
      return {
        ...state,
        ...action.grades,
      };
    default:
      return state;
  }
};

export const preselectsReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_COURSE_PRESELECTS:
      return {
        ...state,
        ...action.coursePreselects,
      };
    default:
      return state;
  }
};
