export const booleanReducer = (args) => {
  const {trueType, falseType, defaultState = false} = args;
  return (state = defaultState, action) => {
    if (trueType && action.type === trueType) {
      return true;
    }

    if (falseType && action.type === falseType) {
      return false;
    }

    return state;
  };
};
