import {connect} from 'react-redux';
import {concat, without} from 'lodash';
import LocationCheckboxFilter from 'src/components/CollegeSearch/Filters/LocationCheckboxFilter/LocationCheckboxFilter';
import {expandFilter, dismissFilter, clearFilter} from 'shared/actions/filters';

const mapStateToProps = (state) => ({
  selected: state.filters['location'] || [],
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {dispatch} = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onClick(key, checked) {
      const onChange = ownProps.onChange;
      const valueGen = checked ? concat : without;
      const filter = {[key]: '1'};
      const value = valueGen(stateProps.selected, filter);

      if (checked) {
        dispatch(clearFilter({name: 'radius-location'}));
        dispatch(clearFilter({name: 'location'}));

        dispatch(expandFilter({name: 'location', value: filter}));
      } else {
        if (value.length > 1) {
          dispatch(dismissFilter({name: 'location', value: filter}));
        } else {
          dispatch(clearFilter({name: 'location'}));
        }
      }
      onChange(value);
    },
  };
};

export default connect(mapStateToProps, null, mergeProps)(LocationCheckboxFilter);
