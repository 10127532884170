import req from 'shared/req';

export const RECEIVE_COLLEGE = 'RECEIVE_COLLEGE';
export const CLEAR_COLLEGE = 'CLEAR_COLLEGE';
export const RECEIVE_COLLEGES = 'RECEIVE_COLLEGES';
export const CLEAR_COLLEGES = 'CLEAR_COLLEGES';
export const UPDATE_COLLEGE = 'UPDATE_COLLEGE';

export const getCollege = (id, fields = '_id', data = {}) =>
  req({
    url: `/v1/colleges/${id}/`,
    method: 'get',
    data: {fields, ...data},
  });

export const getColleges = (ids, fields = '_id') =>
  req({
    url: '/v1/colleges',
    method: 'get',
    data: {ids, fields},
  });

export const clearCollege = () => ({
  type: CLEAR_COLLEGE,
});

export const clearColleges = () => ({
  type: CLEAR_COLLEGES,
});

export const followCollege = (id, callback = () => {}) =>
  req({
    url: `/v1/colleges/${id}/follow`,
    method: 'post',
    data: {},
  }).then(callback);

export const unfollowCollege = (id, callback = () => {}) =>
  req({
    url: `/v1/colleges/${id}/unfollow`,
    method: 'post',
    data: {},
  }).then(callback);
