import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'src/components/tooltip';

function ScholarshipBadge(props) {
  return (
    <Tooltip title="Awards scholarships on RaiseMe" placement="bottom">
      <button
        className={classNames('college-search-scholarship-badge', props.className)}
        aria-label="Scholarship badge tooltip"
      >
        <svg
          width="14px"
          height="14px"
          viewBox="0 0 14 14"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Exploration-2017-08" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-880.000000, -678.000000)">
              <g id="Group" transform="translate(391.000000, 528.000000)">
                <g id="Group-4-Copy-24">
                  <g id="Group-17-Copy-2">
                    <g id="Group-26" transform="translate(18.000000, 146.000000)">
                      <g id="Group-27">
                        <g id="Group-18" transform="translate(51.000000, 4.000000)">
                          <g id="Group-9-Copy-3" transform="translate(420.000000, 0.000000)">
                            <rect
                              id="Rectangle-8-Copy-6"
                              fill="#366aff"
                              x="0"
                              y="0"
                              width="14"
                              height="14"
                              rx="7"
                            />
                            <path
                              d="M7.23122239,6.38888889 C5.89396171,6.06111111 5.46391753,5.725 5.46391753,5.19722222 C5.46391753,4.59166667 6.05596465,4.16666667 7.0544919,4.16666667 C8.10309278,4.16666667 8.49189985,4.63888889 8.52724595,5.33333333 L9.82916053,5.33333333 C9.79086892,4.375 9.16936672,3.50277778 7.93814433,3.21666667 L7.93814433,2 L6.17083947,2 L6.17083947,3.2 C5.02798233,3.43611111 4.1089838,4.13055556 4.1089838,5.20555556 C4.1089838,6.48888889 5.2371134,7.12777778 6.87776141,7.5 C8.35346097,7.83333333 8.64506627,8.31944444 8.64506627,8.84166667 C8.64506627,9.22222222 8.35935199,9.83333333 7.0544919,9.83333333 C5.84094256,9.83333333 5.36082474,9.31944444 5.29896907,8.66666667 L4,8.66666667 C4.0736377,9.88333333 5.03681885,10.5638889 6.17083947,10.7944444 L6.17083947,12 L7.93814433,12 L7.93814433,10.8055556 C9.08394698,10.5972222 10,9.97222222 10,8.83055556 C10,7.25833333 8.56848306,6.71944444 7.23122239,6.38888889 Z"
                              id="Shape"
                              fill="#fff"
                              fillRule="nonzero"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </Tooltip>
  );
}

ScholarshipBadge.propTypes = {
  className: PropTypes.string,
};

export default ScholarshipBadge;
