import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array]).isRequired,
  style: PropTypes.object,
};

const GraphAttachedTooltip = ({children, style}) => (
  <div className="graph-attached-tooltip" style={style}>
    {children}
  </div>
);

GraphAttachedTooltip.propTypes = propTypes;

export default GraphAttachedTooltip;
