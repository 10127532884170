import {connect} from 'react-redux';
import {find} from 'lodash';
import TermNotOfferedModal from 'src/components/CollegeSearch/TermNotOfferedModal/TermNotOfferedModal';
import {closeModal} from 'shared/actions/modal';
import {unfollowCollege} from 'src/redux/collegeSearch/actions';

export const TERM_NOT_OFFERED_MODAL = 'term-not-offered-modal';

const mapStateToProps = (state) => {
  const show = state.modal[TERM_NOT_OFFERED_MODAL] === true;
  const {collegeId} = state.termNotOfferedModal;

  const modalCollege = find(
    state.collegeSearch.filteredColleges,
    (college) => college.id === collegeId
  );

  return {
    show,
    college: modalCollege || {},
    communityCollegeInfo: state.collegeSearch.user.communityCollegeInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal() {
    dispatch(closeModal(TERM_NOT_OFFERED_MODAL));
  },
  unfollowCollege(id) {
    dispatch(unfollowCollege(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TermNotOfferedModal);
