import {sortBy, uniqBy, filter, every, each, forEach, zipWith, values, keys} from 'lodash';
import req from 'shared/req';

// Search Utils
const partnersFirst = (colleges) => sortBy(colleges, (college) => (college.partner ? 0 : 1));

const concatColleges = (limit, ...colleges) => {
  const allColleges = colleges.reduce((c1, c2) => c1.concat(c2));
  // Ensure partners are always included if possible.
  return uniqBy(partnersFirst(allColleges), (college) => college.name).slice(0, limit);
};

const findCollegesByName = (normalized, colleges) =>
  filter(colleges, (college) => {
    const name = college.name.toLowerCase();

    return name.indexOf(normalized) === 0;
  });

const findCollegesByWords = (words, colleges) =>
  filter(colleges, (college) => {
    const name = college.name.toLowerCase();

    return every(words, (word) => name.indexOf(word.toLowerCase()) !== -1);
  });

export function filterColleges(query, abbreviations, collegesList, limit = 8) {
  if (!query.trim().length) {
    return [];
  }

  const NO_RESULTS = [{name: 'No results'}];

  const normalized = query.trim().toLowerCase();
  let colleges = [];

  each(abbreviations, (abbr) => {
    const l = abbr.text.length;

    if (
      colleges.length < limit &&
      normalized.indexOf(abbr.text.toLowerCase()) === 0 &&
      (typeof normalized[l] === 'undefined' || normalized[l] === ' ')
    ) {
      const intended = (abbr.shortFor + ' ' + normalized.substring(l + 1)).trim().toLowerCase();

      colleges = concatColleges(
        limit,
        colleges,
        findCollegesByName(intended, collegesList),
        findCollegesByWords(intended.split(' '), collegesList)
      );
    }
  });

  colleges = concatColleges(
    limit,
    colleges,
    findCollegesByName(normalized, collegesList),
    findCollegesByWords(normalized.split(' '), collegesList)
  );

  each(abbreviations, (abbr) => {
    const l = abbr.text.length;

    if (
      colleges.length < limit &&
      abbr.text.toLowerCase().indexOf(normalized) === 0 &&
      (typeof normalized[l] === 'undefined' || normalized[l] === ' ')
    ) {
      const intended = (abbr.shortFor + ' ' + normalized.substring(l + 1)).trim().toLowerCase();

      colleges = concatColleges(limit, colleges, findCollegesByName(intended, collegesList));
    }
  });

  return colleges.length ? partnersFirst(colleges) : NO_RESULTS;
}

const addToAbbreviations = (nameText, abbrev, abbreviations) => {
  abbreviations.push({text: abbrev, shortFor: nameText});
};

const combineAbbreviations = (nicknames, otherNicknames) => {
  const abbreviations = [];
  forEach(nicknames, (nameText, abbrev) => {
    addToAbbreviations(nameText, abbrev, abbreviations);
  });
  forEach(otherNicknames, (nameText, abbrev) => {
    addToAbbreviations(nameText, abbrev, abbreviations);
  });
  return abbreviations;
};

const formatColleges = (colleges) =>
  zipWith(keys(colleges), values(colleges), (name, college) => ({
    name: name,
    slug: college.url,
    _id: college.id,
    partner: college.u,
  }));

export const fetchCollegesForSearch = () =>
  req({
    url: '/v1/colleges-for-search',
  }).then((response) => {
    const {colleges, nicknames, other_nicknames} = response;

    return {
      abbreviations: combineAbbreviations(nicknames, other_nicknames),
      colleges,
      formattedColleges: formatColleges(colleges),
    };
  });
