// shared
import {getDistrictAdmin, updateDistrictAdmin} from 'shared/actions/district-admin';
import {changesSaved, errorMessage} from 'shared/actions/notifications';
import {startLoading, stopLoading} from 'shared/actions/loading';

export const RECEIVE_DISTRICT_ADMIN = 'RECEIVE_DISTRICT_ADMIN';

export const DISTRICT_ADMIN_FIELDS = [
  'firstName',
  'lastName',
  'title',
  'email',
  'districtName',
  'type',
  'districtId',
].join(',');

export const getDistrictAdminInfo = () => (dispatch) => {
  dispatch(startLoading('user'));
  getDistrictAdmin('me', DISTRICT_ADMIN_FIELDS)
    .then((districtAdmin) => {
      dispatch(receiveDistrictAdmin(districtAdmin));
      dispatch(stopLoading());
      dispatch(stopLoading('user'));
    })
    .catch(() => {
      dispatch(errorMessage());
    });
};

const receiveDistrictAdmin = (districtAdmin) => ({
  type: RECEIVE_DISTRICT_ADMIN,
  districtAdmin,
});

export const updateDistrictAdminInfo = (districtAdmin) => (dispatch) =>
  updateDistrictAdmin(districtAdmin, DISTRICT_ADMIN_FIELDS)
    .then((updatedDistrictAdmin) => {
      dispatch(receiveDistrictAdmin(updatedDistrictAdmin));
      dispatch(stopLoading());
      dispatch(changesSaved());
    })
    .catch(() => {
      dispatch(errorMessage());
    });
