import PropTypes from 'prop-types';
import React from 'react';
import {map} from 'lodash';
import StackedBarChart from '../stacked-bar-chart';
import Card from 'src/components/Cards/Card';
import CollapsibleTeaserSection from 'shared/components/collapsible-teaser-section';
import MiniInfoCardContainer from 'shared/components/college-profile/mini-info-card-container';
import MiniInfoCard from 'shared/components/college-profile/mini-info-card';
import {withCommas} from 'shared/utils';

class AboutStudents extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
  };

  formatValue = (value) => `${value}%`;

  formatGroups = (groups) => map(groups, (group) => ({name: group.name, value: group.percent}));

  render() {
    const {
      college: {
        totalUndergraduates,
        studentFacultyRatio,
        percentLivingOnCampus,
        genderDiversity,
        ethnicDiversity,
      },
    } = this.props;

    const bodyComponentProps = {
      caption: 'Student Diversity',
      groups: this.formatGroups(ethnicDiversity),
      formatValue: this.formatValue,
    };
    const isCollapsingDisabled = StackedBarChart.isEmptyWithProps(bodyComponentProps);
    const modifierClass = isCollapsingDisabled ? '' : 'zero-bottom-padding';

    return (
      <Card className={modifierClass}>
        <CollapsibleTeaserSection
          title="Students"
          trackIdentifier="college-listing-about-students"
          className="college-profile-card-about-content"
          isCollapsingDisabled={isCollapsingDisabled}
        >
          <CollapsibleTeaserSection.Teaser>
            <MiniInfoCardContainer>
              <MiniInfoCard
                iconType="users"
                label="Total Undergrads"
                value={totalUndergraduates ? withCommas(totalUndergraduates) : null}
              />
              <MiniInfoCard
                iconType="user"
                label="Student to Faculty Ratio"
                value={studentFacultyRatio ? `${studentFacultyRatio}:1` : null}
              />
              <MiniInfoCard
                iconType="university"
                label="Living On Campus"
                value={percentLivingOnCampus ? `${percentLivingOnCampus}%` : null}
              />
            </MiniInfoCardContainer>
            <StackedBarChart
              caption="Gender Balance"
              groups={this.formatGroups(genderDiversity)}
              formatValue={this.formatValue}
            />
          </CollapsibleTeaserSection.Teaser>
          <CollapsibleTeaserSection.Body>
            <StackedBarChart {...bodyComponentProps} />
          </CollapsibleTeaserSection.Body>
        </CollapsibleTeaserSection>
      </Card>
    );
  }
}

export default AboutStudents;
