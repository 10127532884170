import {ApiErrorHandler} from './ApiErrorHandler';

interface BasicLoginErrors {
  identifier?: string;
  password?: string;
  hasWeakPassword?: boolean;
}

export class BasicLoginError extends ApiErrorHandler<BasicLoginErrors> {
  getFieldErrors(): BasicLoginErrors {
    if (!this.hasErrors()) return {};
    const fieldErrors: BasicLoginErrors = {};
    const error = this.apiErrors[0];
    switch (error.type) {
      case 'BasicLoginInvalidEmail':
        fieldErrors.identifier = error.message;
        fieldErrors.password = error.message;
        break;
      case 'BasicLoginWeakPassword':
        fieldErrors.hasWeakPassword = true;
        break;
      case 'BasicSignupInvalidEmail':
      case 'BasicSignupTakenEmail':
      case 'BasicSignupTooYoung':
      case 'BasicSignupInvalidMobile':
      case 'BasicSignupTakenMobile':
      case 'BasicSignupEmailHostInvalid':
      case 'BasicLoginAccountInactive':
        fieldErrors.identifier = error.message;
        break;
      case 'BasicSignupInvalidPassword':
        fieldErrors.password = error.message;
        break;
      case 'IonicLoginInvalidUserType':
        fieldErrors.identifier =
          'The RaiseMe app currently only support student/parent users. Please visit www.raise.me to log in!';
        break;
      case 'BasicLoginInvalidUserType':
        fieldErrors.identifier = 'This feature is only available to students. Sorry!';
        break;
      case 'BasicSignupInvalidScholarshipProgramId':
        fieldErrors.identifier = 'This scholarship program id is invalid.';
      default:
        this.logUnknownApiError(error);
    }

    return fieldErrors;
  }
}
