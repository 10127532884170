import {clone} from 'lodash';
import Backbone from 'backbone';

window.Raise = window.Raise || {};

window.Raise.Views = window.Raise.View || {};
window.Raise.Models = window.Raise.Models || {};
window.Raise.Collections = window.Raise.Collections || {};

window.Raise.vent = clone(Backbone.Events);
