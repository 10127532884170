import {includes} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import CollegeApplicationStatusMenu from 'shared/college-application-status/components/college-application-status-menu';
import CollegeCard from 'shared/components/college-card/college-card';
import Icon from 'shared/components/icon';
import Text from 'shared/components/text';
import EmptyCollegeCard from 'src/components/CollegeSearch/EmptyCollegeCard';
import LoadTrigger from 'src/components/LoadTrigger';
import {UserType} from 'src/types/enums';
import 'stylesheets/components/college-search/college-search-filtered-colleges.scss';

class FilteredColleges extends React.Component {
  static propTypes = {
    collegeSearch: PropTypes.shape({
      sortBy: PropTypes.string,
      filteredColleges: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          bannerUrl: PropTypes.string,
          logoUrl: PropTypes.string,
          name: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
          following: PropTypes.bool,
          slug: PropTypes.string,
        })
      ),
      page: PropTypes.number,
      hasMore: PropTypes.bool,
      loading: PropTypes.bool,
      totalColleges: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      user: PropTypes.shape({
        communityCollegeInfo: PropTypes.object,
        highSchoolInfo: PropTypes.shape({
          senior: PropTypes.bool,
        }),
      }),
    }).isRequired,
    followCollege: PropTypes.func.isRequired,
    unfollowCollege: PropTypes.func.isRequired,
    getColleges: PropTypes.func.isRequired,
    setCollegeAppStatus: PropTypes.func.isRequired,
    openTermNotOfferedModal: PropTypes.func.isRequired,
    collegeApplicationStatuses: PropTypes.object.isRequired,
    userType: PropTypes.string.isRequired,
    showApplicationStatusMenu: PropTypes.bool,
  };

  showDeadline = (college) => {
    const statusType = college.earningRelationship && college.earningRelationship.statusType;
    const {
      collegeSearch: {
        user: {highSchoolInfo},
      },
      userType,
    } = this.props;
    const isEducator = userType === UserType.EDUCATOR;
    const isHsSenior = userType === UserType.HS_STUDENT && highSchoolInfo.senior;
    const showToCCStudent =
      userType === UserType.CC_STUDENT &&
      includes(['earning_deadline_soon', 'submission_period_deferred'], statusType);

    return isEducator || isHsSenior || showToCCStudent;
  };

  loadMoreColleges = () => this.props.getColleges({append: true});

  render() {
    const {
      followCollege,
      unfollowCollege,
      setCollegeAppStatus,
      openTermNotOfferedModal,
      collegeApplicationStatuses,
      showApplicationStatusMenu,
      collegeSearch: {
        filteredColleges,
        hasMore,
        sortBy,
        loading,
        user: {communityCollegeInfo},
      },
      userType,
    } = this.props;

    let display = (
      <div className="college-search-filtered-colleges-no-results">
        <div className="college-search-filtered-colleges-no-results-text">
          <Text weight={4} emphasis showOverflow>
            We couldn&#39;t find any matches.
          </Text>
          <Text weight={5} color="darkgrey" showOverflow>
            Try de-selecting some filters to see more results.
          </Text>
        </div>
        <EmptyCollegeCard />
      </div>
    );

    if (filteredColleges === null) {
      display = (
        <div className="college-search-spinner-container">
          <Icon iconType="spinner" className="rm-spin rm-2x" />
        </div>
      );
    } else if (filteredColleges.length > 0) {
      display = filteredColleges.map((college) => {
        const collegeApplicationStatus = collegeApplicationStatuses[college.id];
        const isHsOrTransferStudent =
          userType === UserType.HS_STUDENT || userType === UserType.CC_STUDENT;

        return (
          <span
            className="college-search-filtered-colleges-card"
            key={college.id}
            data-testid="college-search-result"
          >
            {isHsOrTransferStudent && showApplicationStatusMenu && (
              <CollegeApplicationStatusMenu
                collegeApplicationStatus={collegeApplicationStatus}
                setCollegeAppStatus={setCollegeAppStatus}
                college={college}
                className="college-search-app-status-section"
                menuButtonColor="primary"
                showStatusContent={false}
                segmentEventLocation="college search dropdown"
              />
            )}
            <Link to={`/edu/${college.slug}`}>
              <CollegeCard
                college={college}
                followCollege={followCollege}
                unfollowCollege={unfollowCollege}
                shortCard={userType === UserType.EDUCATOR}
                showDeadline={this.showDeadline(college)}
                showDeadlineYear={userType === UserType.CC_STUDENT}
                showStatus={college.earningRelationship !== null}
                showFollow={userType !== UserType.EDUCATOR}
                showBadge
                showSortInfo
                sortBy={sortBy}
                communityCollegeInfo={communityCollegeInfo}
                openTermNotOfferedModal={openTermNotOfferedModal}
                simplified
                userType={userType}
              />
            </Link>
          </span>
        );
      });
    }

    return (
      <div className="college-search-filtered-colleges">
        <div className="college-search-filtered-colleges-cards">{display}</div>
        <LoadTrigger
          onLoadMore={this.loadMoreColleges}
          buttonText="Load more colleges"
          isLoading={loading}
          hasMore={hasMore}
        />
      </div>
    );
  }
}

export default connect((state) => ({
  userType: state.collegeProfile.userInfo.user,
}))(FilteredColleges);
