import PropTypes from 'prop-types';
import React from 'react';
import {compact, isEmpty, valuesIn} from 'lodash';
import Dropdown from 'src/components/dropdown';
import Label from 'shared/components/label';
import HighlightedText from 'shared/college-profile/components/highlighted-text';
import {withCommas} from 'shared/utils';

const incomes = [
  {value: 'under30k', text: '<$30k'},
  {value: '30kTo48k', text: '$30-48k'},
  {value: '49kTo75k', text: '$49-75k'},
  {value: '76kTo110k', text: '$76-110k'},
  {value: 'above110k', text: '>$110k'},
].map((incomeRange) => ({
  _id: incomeRange.text,
  value: incomeRange.value,
  text: incomeRange.text,
}));

class AverageNetPricePerHouseholdIncome extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    userState: PropTypes.string.isRequired,
    updateHouseholdIncomeRange: PropTypes.func.isRequired,
  };

  render() {
    const {
      userInfo: {householdIncomeRange},
      userState,
      college: {netPriceByHouseholdIncome, state, control},
      updateHouseholdIncomeRange,
    } = this.props;

    const netPricePerHouseholdIncomeRangeData = !isEmpty(
      compact(valuesIn(netPriceByHouseholdIncome))
    );

    // Hide graphic for out-of-state public schools but show it for the rest
    const hideGraphicForSchool = userState !== state && control === 'Public';

    if (!netPricePerHouseholdIncomeRangeData || hideGraphicForSchool) {
      return null;
    }

    return (
      <div>
        <div className="average-net-price-per-household-income">
          <p className="average-net-price-per-income-subheading">
            Average Net Price Based on Household Income
          </p>
          <p className="average-net-price-per-income-text">
            To get a more accurate estimate of your net price at this college, tell us more about
            your household income.
          </p>
          <Label>
            <div className="household-income-range-dropdown-label">
              Select your household income per year
            </div>
            <Dropdown
              className="household-income-range-dropdown"
              size="small"
              onChange={(e) => updateHouseholdIncomeRange(e.target.value)}
              options={incomes}
              value={householdIncomeRange || ''}
            />
          </Label>
          {householdIncomeRange && netPriceByHouseholdIncome[householdIncomeRange] && (
            <div>
              <HighlightedText
                padding={false}
                iconType="dollar-sign"
                color="grey"
                text={[
                  <span key="avg-net-">Avg. Net Price by Income</span>,
                  <span key="avg-net-price-by-income" className="average-net-price-by-income">
                    <div>{`$${withCommas(netPriceByHouseholdIncome[householdIncomeRange])}*`}</div>
                    <div key="caption" className="average-net-price-disclaimer-text">
                      *Based on household income
                    </div>
                  </span>,
                ]}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AverageNetPricePerHouseholdIncome;
