import {MISSING_INSTITUTION_REQUESTED} from 'src/components/RequestInstitutionModal/actions';

const requestInstitutionModalReducer = (state = {}, action) => {
  switch (action.type) {
    case MISSING_INSTITUTION_REQUESTED:
      return {
        ...state,
        email: action.email,
        requested: true,
      };
    default:
      return state;
  }
};

export default requestInstitutionModalReducer;
