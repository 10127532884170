import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {flatten} from 'lodash';
import classNames from 'classnames';
import {useMediaQuery} from 'src/hooks/useMediaQuery';
import {openModal} from 'shared/actions/modal';
import {Store} from 'src/types/store';
import Icon from 'shared/components/icon';
import CollegeSearchBar from 'shared/college-search-bar/containers';
import UnstyledButton from 'src/components/unstyled-button';

const InlineCollegeSearchBar = (): React.ReactElement | null => {
  const mq = useMediaQuery();
  const dispatch = useDispatch();
  const filterLength = useSelector(({filters}: Store) => flatten(Object.values(filters)).length);

  if (mq.MS_AND_DOWN) {
    return (
      <div className={classNames('college-search-bar --ionic')}>
        <div className={classNames('college-search-bar-field clearfix --ionic')}>
          <Icon iconType="search" className="college-search-bar-search-icon" />
          <CollegeSearchBar />
        </div>
        <UnstyledButton
          onClick={() => dispatch(openModal('college-search-filters'))}
          className="college-search-bar-filter-button"
        >
          <Icon iconType="sliders-h" /> {filterLength ? `Filtered (${filterLength})` : 'Filter'}
        </UnstyledButton>
      </div>
    );
  }
  return null;
};

export default InlineCollegeSearchBar;
