import PropTypes from 'prop-types';
import React from 'react';
import {getMedia, watchMedia} from 'shared/mq';
import FilterTagsMobile from 'src/components/CollegeSearch/FilterTags/FilterTagsMobile';
import AdmissionFilters from 'src/components/CollegeSearch/Filters/AdmissionFilters';
import AttributeFilters from 'src/components/CollegeSearch/Filters/AttributeFilters';
import CostFilters from 'src/components/CollegeSearch/Filters/CostFilters';
import DiversityFilters from 'src/components/CollegeSearch/Filters/DiversityFilters';
import GraduationFilters from 'src/components/CollegeSearch/Filters/GraduationFilters';
import LocationFilters from 'src/components/CollegeSearch/Filters/LocationFilters';
import MajorsFilters from 'src/components/CollegeSearch/Filters/MajorsFilters';
import NcaaFilters from 'src/components/CollegeSearch/Filters/NcaaFilters';
import SizeFilters from 'src/components/CollegeSearch/Filters/SizeFilters';
import TagsFilters from 'src/components/CollegeSearch/Filters/TagsFilters';
import TypeFilters from 'src/components/CollegeSearch/Filters/TypeFilters';
import CollegeNameFilter from 'src/components/CollegeSearch/Filters/college-name-filter';

class Filters extends React.Component {
  static propTypes = {
    getColleges: PropTypes.func.isRequired,
    transfer: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.mq = watchMedia(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.mq.remove();
  }

  render() {
    const mq = getMedia();
    const {getColleges, transfer} = this.props;

    return (
      <div className="college-search-filters-sidebar" role="group" aria-label="Filter Colleges">
        {mq.MS_AND_DOWN && <FilterTagsMobile />}
        <CollegeNameFilter getColleges={getColleges} />
        <LocationFilters transfer={transfer} />
        <AttributeFilters getColleges={getColleges} />
        <MajorsFilters getColleges={getColleges} />
        <TypeFilters getColleges={getColleges} transfer={transfer} />
        <CostFilters getColleges={getColleges} transfer={transfer} />
        {!transfer && <AdmissionFilters getColleges={getColleges} />}
        <SizeFilters getColleges={getColleges} />
        <NcaaFilters getColleges={getColleges} />
        <DiversityFilters getColleges={getColleges} />
        <GraduationFilters getColleges={getColleges} />
        <TagsFilters getColleges={getColleges} />
      </div>
    );
  }
}

export default Filters;
