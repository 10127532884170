import {merge} from 'lodash';
import {camelizeKeys} from 'humps';
import {portfolioUrl} from 'shared/portfolio/utils';
import req, {rejectGenericError} from 'shared/req';
import {objectId} from 'shared/utils';

export const NEW_MULTIMEDIA = 'NEW_MULTIMEDIA';
export const SAVE_MULTIMEDIA = 'SAVE_MULTIMEDIA';
export const EDIT_MULTIMEDIA = 'EDIT_MULTIMEDIA';
export const CANCEL_EDIT_MULTIMEDIA = 'CANCEL_EDIT_MULTIMEDIA';
export const RECEIVE_MULTIMEDIA = 'RECEIVE_MULTIMEDIA';
export const RECEIVE_ALL_MULTIMEDIA = 'RECEIVE_ALL_MULTIMEDIA';
export const MULTIMEDIA_DELETED = 'MULTIMEDIA_DELETED';
export const SCROLLED_TO_MULTIMEDIA = 'SCROLLED_TO_MULTIMEDIA';

export const newMultimediaObject = () => ({
  _id: objectId(),
  title: '',
  category: '',
  description: '',
  links: [''],
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
  parentCategory: '',
  previewUrls: [],
  editing: true,
  new: true,
});

export function newMultimedia(options = {}) {
  const multimedia = {
    ...newMultimediaObject(),
    scrollTo: !!options.scrollTo,
  };

  return {
    type: NEW_MULTIMEDIA,
    id: `multimedia-${multimedia._id}`,
    multimedia,
  };
}

export function cancelMultimedia(multimedia) {
  return {
    type: CANCEL_EDIT_MULTIMEDIA,
    id: `multimedia-${multimedia._id}`,
    multimedia,
  };
}

export function deleteMultimedia(id) {
  return (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(`/multimedia_items/${id}`, _id),
      method: 'DELETE',
    }).then(() => {
      dispatch({
        type: MULTIMEDIA_DELETED,
        id: `multimedia-${id}`,
      });
    });
  };
}

export function editMultimedia(multimedia) {
  return {
    type: EDIT_MULTIMEDIA,
    id: `multimedia-${multimedia._id}`,
    multimedia: {...multimedia, scrollTo: true},
  };
}

export function receiveMultimedia(multimedia) {
  return {
    type: RECEIVE_MULTIMEDIA,
    id: `multimedia-${multimedia._id}`,
    multimedia,
  };
}

export function scrolledToMultimedia(id) {
  return {
    type: SCROLLED_TO_MULTIMEDIA,
    id: `multimedia-${id}`,
  };
}

export const saveMultimedia = (multimedia) => (dispatch, getState) => {
  let url;
  let method;

  if (multimedia.new) {
    url = '/multimedia_items';
    method = 'POST';
  } else {
    url = `/multimedia_items/${multimedia._id}`;
    method = 'PATCH';
  }

  const {
    student: {_id},
  } = getState();
  return req({
    url: portfolioUrl(url, _id),
    method: method,
    data: multimedia,
  })
    .then((multimediaResponse) => {
      dispatch(receiveMultimedia(merge({}, multimediaResponse, {scrollTo: true})));
    })
    .catch((responseJSON) => {
      const errors = camelizeKeys(responseJSON);
      if (!errors) return rejectGenericError();

      if (errors.links) {
        const badLink = errors.links.split(' ')[0];
        const badLinkIndex = multimedia.links.findIndex((link) => link === badLink);
        errors.badLinkIndex = badLinkIndex;
      }

      if (errors.date) {
        errors.year = errors.date;
      }

      return Promise.reject(errors);
    });
};
