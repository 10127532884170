import {honorAwardsModalConfig} from 'src/components/HonorAwardsModal';
import {workExperienceModalConfig} from 'src/components/WorkExperienceModal';
import {activityModalConfig} from 'src/components/ActivityModal';
import {collegeCourseModalConfig} from 'src/components/CollegeCourseModal';

const transferModals = [
  honorAwardsModalConfig,
  workExperienceModalConfig,
  activityModalConfig,
  collegeCourseModalConfig,
];

export default transferModals;
