import PropTypes from 'prop-types';
import React from 'react';
import {find, groupBy, map} from 'lodash';
import ScholarshipType from './scholarship-type';
import EligibilityCriteriaCard from '../right-rail/eligibility-criteria-card';
import EnrollmentCriteriaCard from '../right-rail/enrollment-criteria-card';
import Card from 'src/components/Cards/Card';
import Dropdown from 'src/components/dropdown';
import SectionHeader from 'shared/college-profile/components/section-header';
import {getMedia, watchMedia} from 'shared/mq';
import {withCommas} from 'shared/utils';
import {SCHOLARSHIP_TYPE_MAP} from './constants';

class NonStudentScholarships extends React.Component {
  static propTypes = {
    collegeProfile: PropTypes.object.isRequired,
    changeScholarshipProgram: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.mq = watchMedia(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.mq.remove();
  }

  handleProgramSelect = (e) => {
    this.props.changeScholarshipProgram(e.target.value);
  };

  displayMax = (max) => `- $${withCommas(max)} Maximum`;

  render() {
    const mq = getMedia();
    const {
      collegeProfile: {
        activeScholarshipProgram,
        userInfo,
        college: {scholarshipPrograms},
      },
    } = this.props;

    const scholarshipProgram = activeScholarshipProgram.name
      ? find(scholarshipPrograms, {name: activeScholarshipProgram.name})
      : scholarshipPrograms[0];

    const groupedScholarships = groupBy(
      scholarshipProgram.scholarships,
      (scholarship) => SCHOLARSHIP_TYPE_MAP[scholarship._type]
    );

    return (
      <div className="college-profile-scholarship-breakdown">
        <div className="college-profile-educator-scholarship-header">
          {scholarshipPrograms.length > 1 ? (
            <Dropdown
              options={map(scholarshipPrograms, (program) => ({
                _id: program.name,
                value: program.name,
                text: `${program.name} ${this.displayMax(program.maxPerStudent)}`,
              }))}
              value={activeScholarshipProgram.name || scholarshipProgram.name}
              onChange={this.handleProgramSelect}
            />
          ) : (
            <SectionHeader
              title={`${scholarshipProgram.name} ${this.displayMax(
                scholarshipProgram.maxPerStudent
              )}`}
            />
          )}
        </div>
        {mq.SM_AND_DOWN && (
          <>
            <EligibilityCriteriaCard scholarshipProgram={scholarshipProgram} />
            <EnrollmentCriteriaCard ongoingRequirements={scholarshipProgram.ongoingRequirements} />
          </>
        )}
        {map(Object.keys(groupedScholarships), (type) => {
          if (type === 'hidden') {
            return null;
          }

          return (
            <Card key={type}>
              <ScholarshipType
                awardsReplaced={false}
                scholarships={groupedScholarships[type]}
                type={type}
                submitted={false}
                educator
                userInfo={userInfo}
              />
            </Card>
          );
        })}
      </div>
    );
  }
}

export default NonStudentScholarships;
