import React from 'react';
import PropTypes from 'prop-types';
import Pill from 'src/components/Pill';

class TagGroup extends React.Component {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
      PropTypes.string.isRequired,
    ]).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const {data, onClose} = this.props;

    return (
      <div className="shared-tag-group" data-testid="shared-tag-group">
        {data.length > 0 &&
          data.map(({name, _id}) => (
            <Pill key={_id} onClick={onClose(_id)} iconRight="times-r">
              {name}
            </Pill>
          ))}
      </div>
    );
  }
}

export default TagGroup;
