import React, {PropsWithChildren} from 'react';
import Icon from './icon';
import classnames from 'classnames';

export const LoadingIcon = () => (
  <Icon iconType="spinner" className="rm-spin shared-loading-icon" testid="loading-icon" />
);

const Loading = (props: {className?: string}) => (
  <div className={classnames('shared-loading', props.className)} data-testid="loading">
    <LoadingIcon />
  </div>
);

export default Loading;

type LoadingWrapperProps = PropsWithChildren<{
  className?: string;
  loading: boolean;
}>;

export const LoadingWrapper = ({className, children, loading}: LoadingWrapperProps) => {
  if (loading) {
    return <Loading className={className} />;
  } else {
    return <div>{children}</div>;
  }
};
