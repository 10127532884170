import React from 'react';
import PropTypes from 'prop-types';
import styles from 'stylesheets/micro-scholarships.module.scss';
import {CardContainer} from 'shared/micro-scholarships/components/opportunity-card';

const settings = {
  title: (institutionName) => {
    if (institutionName && institutionName.length <= 28) {
      return `Join clubs at ${institutionName}`;
    }

    return 'Join clubs at your high school today';
  },
  subtitle:
    'View the most popular clubs at your school, earn Micro-Scholarships and sign up as a club leader.',
};

export default function JoinClubsCTACard(props) {
  const {institutionName} = props;

  return (
    <CardContainer className={styles.JoinClubsCardContainer}>
      <div className={styles.content}>
        <h2>{settings.title(institutionName)}</h2>
        <p>{settings.subtitle}</p>
      </div>
    </CardContainer>
  );
}

JoinClubsCTACard.propTypes = {
  institutionName: PropTypes.string.isRequired,
};
