import {RECEIVE_PARENT} from './actions';
import {RECEIVE_IMAGE} from 'shared/actions/avatar';

const parentReducer = (state = {type: 'Parent'}, action) => {
  switch (action.type) {
    case RECEIVE_PARENT:
      return {
        ...state,
        ...action.parent,
      };
    case RECEIVE_IMAGE:
      return {
        ...state,
        image: action.image,
      };
    default:
      return state;
  }
};

export default parentReducer;
