import React from 'react';
import PropTypes from 'prop-types';
import ScholarshipBadge from 'src/components/CollegeSearch/ScholarshipBadge';

const propTypes = {
  canEarn: PropTypes.bool,
  city: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

const Header = ({canEarn, city, logo, name, state}) => (
  <header className="college-profile-college-status-card-header">
    <img
      src={logo}
      aria-hidden="true"
      alt=""
      className="college-profile-college-status-card-logo"
    />
    <div className="college-profile-college-status-card-college-info">
      <p id="college-profile-college-name" className="college-profile-college-status-card-name">
        {name}
      </p>
      <p className="college-profile-college-status-card-location">
        {canEarn && (
          <span>
            <ScholarshipBadge /> &#8226;
          </span>
        )}{' '}
        {city}, {state}
      </p>
    </div>
  </header>
);

Header.propTypes = propTypes;

export default Header;
