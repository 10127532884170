import React from 'react';
import PropTypes from 'prop-types';
import {percentFormat} from 'src/utils/collegeSearch';
import RangeFilter from 'shared/components/filters/range-filter';
import Text from 'shared/components/text';

class DiversityFilters extends React.Component {
  static propTypes = {
    getColleges: PropTypes.func.isRequired,
  };

  render() {
    const {getColleges} = this.props;

    return (
      <div className="college-search-filters-section">
        <fieldset role="group" aria-label="Filter by Diversity Data">
          <legend className="college-search-filters-section-legend">
            <Text id="college-search-diversity" weight={5} emphasis>
              Diversity
            </Text>
          </legend>
          <RangeFilter
            name="diversity"
            step={1}
            minValue={0}
            maxValue={100}
            labelFunc={percentFormat}
            onChange={getColleges}
            labelledby="college-search-diversity"
          />
        </fieldset>
      </div>
    );
  }
}

export default DiversityFilters;
