import PropTypes from 'prop-types';
import React from 'react';
import {sumBy, map} from 'lodash';
import classnames from 'classnames';
import {watchMedia, getMedia} from 'shared/mq';
import Award from './award';
import Icon from 'shared/components/icon';
import CollapsibleSection from 'src/components/collapsible-section';
import {withCommas} from 'shared/utils';

class Awards extends React.Component {
  static propTypes = {
    awards: PropTypes.array.isRequired,
    pending: PropTypes.bool,
  };

  componentDidMount() {
    this.mq = watchMedia(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.mq.remove();
  }

  getTitle(hasAwards, totalAmount) {
    const formattedAmount = `$${withCommas(Math.round(totalAmount))}`;

    return (
      <div className={`college-profile-award-amount ${hasAwards ? 'has-awards' : 'no-awards'}`}>
        <Icon iconType="check-circle" className="college-profile-award-amount-check" />
        {formattedAmount}
      </div>
    );
  }

  render() {
    const {awards, pending} = this.props;
    const totalAmount = sumBy(awards, 'amount');
    const numAwards = awards.length;
    const hasAwards = numAwards > 0;
    const mq = getMedia();

    return (
      <CollapsibleSection
        className={classnames(
          'college-profile-scholarship-awards',
          {'has-awards': hasAwards},
          {pending: pending && mq.MS_AND_DOWN}
        )}
        disableCollapse={!hasAwards || pending}
        title={this.getTitle(hasAwards, totalAmount)}
        collapseLabel={
          pending
            ? 'New awards may take a few minutes to calculate'
            : hasAwards
            ? `${numAwards} Award${numAwards > 1 ? 's' : ''}`
            : 'No Awards Yet'
        }
        isOpen={false}
        trackIdentifier="college-listing-scholarships-awards"
      >
        {map(awards, (award, i) => (
          <Award key={i} award={award} />
        ))}
      </CollapsibleSection>
    );
  }
}

export default Awards;
