import {errorMessage} from 'shared/actions/notifications';
import GoogleOAuth from 'shared/modules/google-oauth';
import req from 'shared/req';
import {removeParams} from 'src/redux/auth/actions/url-parameters';
import {BasicLoginError} from 'src/utils/errors/BasicLoginError';
import {getReferralDataFromUrl, invalidEducatorEmail, TOO_YOUNG} from 'src/views/public/utils';
import {mobileAllowedUserTypes} from '../../../utils/users';
import PlatformService from '../../../services/PlatformService';
import Errors from 'src/static/errors.json';

const tooYoungError = Errors.validation.birthday.tooYoung;

export const onSubmit =
  (values, followCollegeId, shouldRedirect = true) =>
  (_dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (userTooYoung()) {
        reject({identifier: tooYoungError});
        return;
      }

      const state = getState();
      return req({
        url: '/v1/basic/signup?fields=_id',
        method: 'post',
        data: {
          ...getReferralDataFromUrl(state),
          ...parseDataFromUrl(),
          followCollegeId,
          ...values,
          cookie: true,
          lastVisitedPublicRoute: sessionStorage.getItem('lastVisitedPublicRoute'),
        },
      })
        .then((res) => {
          removeParams();
          if (shouldRedirect) {
            window.location = res.url;
          }
          resolve();
        })
        .catch((data) => {
          const loginErrors = new BasicLoginError(data);

          if (loginErrors.getFieldErrors().identifier === tooYoungError) {
            window.sessionStorage.setItem(TOO_YOUNG, 'true');
          }
          reject(loginErrors.getFieldErrors());
        });
    });

const userTooYoung = () => window.sessionStorage.getItem(TOO_YOUNG) === 'true';

export const googleSignUp = () => (dispatch, getState) => {
  const state = getState();
  const data = getReferralDataFromUrl(state);

  GoogleOAuth.authorize().then((googleUser) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('googleUser', JSON.stringify(googleUser, null, 2));
    }
    const googleEmail = googleUser.email;

    if (data.user_type === 'educator' && invalidEducatorEmail(googleEmail)) {
      dispatch(errorMessage('Please use your official work email address.'));
      return;
    }

    return req({
      url: `/v1/google/signup?runtime=${PlatformService.runtime()}&allowedUserTypes=${mobileAllowedUserTypes()}`,
      method: 'post',
      data: {
        token: googleUser.authentication.idToken,
        ...data,
        ...parseDataFromUrl(),
      },
    })
      .then((res) => {
        removeParams();

        window.location = res.url;
      })
      .catch((errors) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('data', JSON.stringify(errors, null, 2));
        }
        dispatch(errorMessage());
      });
  });
};

const USER_SUBTYPES = new Set(['teacher', 'counselor', 'mentor', 'communityCollegeStaff']);

export const parseDataFromUrl = () => {
  const data = {};
  const urlParams = window.location.pathname.slice(1).split('/');

  data.user_type = urlParams[1];

  if (urlParams[2] && USER_SUBTYPES.has(urlParams[2])) {
    data.user_subtype = urlParams[2];
  } else if (urlParams[2] === 'program' && urlParams[3]) {
    data.scholarshipProgramId = urlParams[3];
  }

  return data;
};
