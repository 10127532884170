import React, {useEffect, useState} from 'react';
import PlatformService, {AppPlatform} from 'src/services/PlatformService';
import basicContext from 'src/components/PlatformContext/basicContext';
import ionicContext from 'src/components/PlatformContext/ionicContext';
import {PlatformContext} from './types';
import {setupIonicReact} from '@ionic/react';
import {useMediaQuery} from '../../hooks/useMediaQuery';

interface Props {
  children: React.ReactNode;
  forcePlatform?: AppPlatform;
}

export function PlatformContextProvider(props: Props): React.ReactElement {
  const platformService = new PlatformService();
  const [platform, setPlatform] = useState<AppPlatform>(props.forcePlatform || AppPlatform.BASIC);
  const mq = useMediaQuery();

  useEffect(() => {
    !props.forcePlatform && platformService.getPlatform().then(setPlatform);
    // this needs called whether the platform is ionic, otherwise Ionic components won't work properly
    setupIonicReact({mode: 'ios', swipeBackEnabled: true});
  }, []);

  useEffect(() => {
    if (mq.SM_AND_DOWN && platform === AppPlatform.BASIC) {
      setPlatform(AppPlatform.IONIC);
    } else if (!mq.SM_AND_DOWN && platform === AppPlatform.IONIC) {
      setPlatform(AppPlatform.BASIC);

      // When we switch back to basic, we need to reset the body styles that Ionic sets
      document.body.style.position = 'static';
      document.body.style.overflow = 'auto';
      document.body.style.transform = 'none';
    }
  }, [mq.SM_AND_DOWN]);

  return (
    <PlatformContext.Provider
      key={platform}
      value={{
        isBasic: platform === AppPlatform.BASIC,
        isIonic: platform === AppPlatform.IONIC,
        platform,
        components: platform === AppPlatform.BASIC ? basicContext : ionicContext,
      }}
    >
      {props.children}
    </PlatformContext.Provider>
  );
}
