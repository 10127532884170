import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from './icon';

class MenuButton extends React.Component {
  static propTypes = {
    children: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'white', 'dark']),
  };

  static defaultProps = {
    color: '',
    className: '',
  };

  getClassName() {
    const {color, className} = this.props;
    return classnames(`menu-button-${color}`, className);
  }

  render() {
    const {onClick, children} = this.props;
    return (
      <button className={this.getClassName()} onClick={onClick}>
        {children}
        <Icon iconType="chevron-down" className="shared-dropdown-arrow" />
      </button>
    );
  }
}

export default MenuButton;
