import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {flashMessage} from 'shared/actions/notifications';

class MessageAndRedirect extends React.Component {
  static propTypes = {
    redirectTo: PropTypes.string.isRequired,
    showMessage: PropTypes.func.isRequired,
    // Used in mapDispatchToProps
    /* eslint-disable react/no-unused-prop-types */
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['error', 'success']),
    /* eslint-enable react/no-unused-prop-types */
  };

  static defaultProps = {
    type: 'error',
  };

  constructor(props) {
    super(props);
    this.props.showMessage();
  }

  render() {
    return <Redirect path="*" to={this.props.redirectTo} />;
  }
}

const mapDispatchToProps = (dispatch, {message, type}) => ({
  showMessage: () => dispatch(flashMessage(message, type, {}, 5000)),
});

export default connect(null, mapDispatchToProps)(MessageAndRedirect);
