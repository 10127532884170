import {
  RECEIVE_VERIFIED_COLLEAGUE,
  RECEIVE_COLLEAGUES,
} from 'educator/account-settings/actions/colleagues';

const colleagueReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_VERIFIED_COLLEAGUE:
      return state.map((colleague) => {
        if (action.id === colleague._id) {
          return {...colleague, isVerified: true};
        } else {
          return colleague;
        }
      });
    case RECEIVE_COLLEAGUES: {
      return action.colleagues;
    }
    default:
      return state;
  }
};

export default colleagueReducer;
