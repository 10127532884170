import req from 'shared/req';
import {portfolioUrl} from 'shared/portfolio/utils';
import {REMOVE_INSTITUTION} from 'shared/portfolio/actions/portfolio';

export const UPDATE_CURRENT_INSTITUTION = 'UPDATE_CURRENT_INSTITUTION';
export const RECEIVE_INSTITUTION = 'RECEIVE_INSTITUTION';

export const updateInstitution = (values) => (dispatch, getState) => {
  const {
    student: {_id},
  } = getState();

  return req({
    url: portfolioUrl(`/institutions/${values._id}`, _id),
    method: 'PATCH',
    data: values,
  }).then((institution) => {
    dispatch({
      type: RECEIVE_INSTITUTION,
      institution,
    });
  });
};

export const addInstitution = (values) => (dispatch, getState) => {
  const {
    student: {_id},
  } = getState();

  return req({
    url: portfolioUrl('/institutions', _id),
    method: 'POST',
    data: values,
  }).then((institution) => {
    dispatch({type: RECEIVE_INSTITUTION, institution});

    if (values.currentlyAttend) {
      dispatch({type: UPDATE_CURRENT_INSTITUTION, institution});
    }
  });
};

export const clearInstitution = (institutionId) => ({
  type: REMOVE_INSTITUTION,
  institutionId,
});

export const removeInstitution = (institutionId) => (dispatch, getState) =>
  req({
    url: portfolioUrl(`/institutions/${institutionId}`, getState().student._id),
    method: 'delete',
  }).then(() => {
    dispatch(clearInstitution(institutionId));
  });
