import classnames from 'classnames';
import React, {forwardRef} from 'react';
import {Booleanish} from 'src/types/util';

interface Props {
  iconType: string;
  className?: string;
  onClick?: () => void;
  rotate?: number;
  style?: object;
  buttonName?: string;
  role?: string;
  'aria-hidden'?: Booleanish;
  'aria-label'?: string;
  color?: string;
  testid?: string;
}

export const Icon = (
  {
    iconType,
    className = '',
    onClick,
    rotate,
    style = {},
    buttonName,
    role,
    'aria-hidden': ariaHidden = 'true',
    'aria-label': ariaLabel,
    color = '',
    testid,
  }: Props,
  contentRef
) => (
  // NOTE: Icons are hidden from screen readers by default
  <i
    ref={contentRef}
    className={classnames(
      'icon',
      'rm',
      `rm-${iconType}`,
      className,
      {[`rm-rotate-${rotate}`]: rotate},
      color
    )}
    role={role}
    aria-hidden={ariaHidden}
    aria-label={ariaLabel}
    style={style}
    onClick={onClick}
    data-segment-track={buttonName && 'button'}
    data-button-name={buttonName}
    data-testid={testid}
  />
);
export default forwardRef(Icon);
