import {reducer as formReducer} from 'redux-form';
import {RECEIVE_DISTRICT_ADMIN} from 'district-admin/actions/district-admin';
import {CLEAR_SCHOOL, RECEIVE_SCHOOL, RECEIVE_SCHOOLS} from 'district-admin/actions/schools';
import {CLEAR_STUDENTS, RECEIVE_STUDENTS} from 'district-admin/actions/students';
import educatorReducer from './educators';
import {genericReducer} from 'shared/reducers/generic-reducer';
import {objectReducer} from 'shared/reducers/object-reducer';
import tabReducer from 'shared/account-settings/reducers/tab';
import {modalReducer} from 'shared/reducers/modal';
import {notificationsReducer} from 'shared/reducers/notifications';
import {loadingReducer} from 'shared/reducers/loading';
import toolbarNotificationReducer from 'src/redux/toolbar-notification/reducer';
import checklistReducer from '../../src/redux/checklist/reducer';
import checklistUserStatusReducer from '../../src/redux/checklistUserStatus/reducer';

export const districtAdminReducers = {
  activeTab: tabReducer,
  checklist: checklistReducer,
  checklistUserStatus: checklistUserStatusReducer,
  educators: educatorReducer,
  form: formReducer,
  loading: loadingReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  school: genericReducer({
    actionProperty: 'school',
    defaultState: {},
    resetAction: CLEAR_SCHOOL,
    setAction: RECEIVE_SCHOOL,
  }),
  schools: genericReducer({
    actionProperty: 'schools',
    defaultState: [],
    setAction: RECEIVE_SCHOOLS,
  }),
  students: genericReducer({
    actionProperty: 'students',
    defaultState: [],
    resetAction: CLEAR_STUDENTS,
    setAction: RECEIVE_STUDENTS,
  }),
  toolbarNotification: toolbarNotificationReducer,
  user: objectReducer({
    actionProperty: 'districtAdmin',
    mergeObjectAction: RECEIVE_DISTRICT_ADMIN,
  }),
};
