import {HIDE_TOOLBAR, SHOW_TOOLBAR} from 'src/redux/parentAccessToolbar/actions';
import {HIDE_OPT_OUT_MODAL, SHOW_OPT_OUT_MODAL} from 'src/redux/parentOptOutModal/actions';
import potentialConnectedUsersReducer from 'src/redux/potentialConnectedUsers/reducer';
import userConnectionsReducer from 'src/redux/userConnections/reducer';
import {reducer as formReducer} from 'redux-form';
import notificationSettingsReducer from 'shared/account-settings/reducers/notification-settings';
import {START_LOADING, STOP_LOADING} from 'shared/actions/loading';
import {RECEIVE_PHONE_NUMBER} from 'shared/actions/verify-mobile';
import {accessibilityAnnouncementReducer} from 'shared/reducers/accessibility-announcement';
import {avatarReducer} from 'shared/reducers/avatar';
import {booleanReducer} from 'shared/reducers/boolean-reducer';
import {genericReducer} from 'shared/reducers/generic-reducer';
import {modalReducer} from 'shared/reducers/modal';
import {notificationsReducer} from 'shared/reducers/notifications';
import parentReducer from 'src/redux/parent/reducer';
import toolbarNotificationReducer from 'src/redux/toolbar-notification/reducer';
import checklistReducer from '../../redux/checklist/reducer';
import checklistUserStatusReducer from '../../redux/checklistUserStatus/reducer';

export const parentReducers = {
  accessibilityAnnouncement: accessibilityAnnouncementReducer,
  avatar: avatarReducer,
  checklist: checklistReducer,
  checklistUserStatus: checklistUserStatusReducer,
  form: formReducer,
  loading: booleanReducer({
    defaultState: true,
    falseType: STOP_LOADING,
    trueType: START_LOADING,
  }),
  modal: modalReducer,
  notificationSettings: notificationSettingsReducer,
  notifications: notificationsReducer,
  parent: parentReducer,
  potentialConnectedUsers: potentialConnectedUsersReducer,
  showAccessToolbar: booleanReducer({falseType: HIDE_TOOLBAR, trueType: SHOW_TOOLBAR}),
  showOptOutModal: booleanReducer({falseType: HIDE_OPT_OUT_MODAL, trueType: SHOW_OPT_OUT_MODAL}),
  toolbarNotification: toolbarNotificationReducer,
  user: parentReducer,
  userConnections: userConnectionsReducer,
  verifyMobile: genericReducer({
    actionProperty: 'payload',
    defaultState: '',
    setAction: RECEIVE_PHONE_NUMBER.toString(),
  }),
};
