import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from 'shared/components/icon';
import 'stylesheets/components/shared-avatar.scss';

export const AvatarImageShape = {
  userInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    image: PropTypes.string,
  }),
  size: PropTypes.oneOf(['small', 'small-medium', 'medium', 'large', 'medium-large']),
  className: PropTypes.string,
  id: PropTypes.string,
};

const AvatarImage = (props) => {
  const {
    userInfo: {image, firstName, lastName},
    size,
    className,
    id,
  } = props;
  let fallback = <Icon iconType="user" testid="avatar-icon" />;
  if (firstName || lastName) {
    fallback = (firstName ? firstName[0] : '') + (lastName ? lastName[0] : '');
  }

  return (
    <span
      className={classnames('shared-avatar', size, className)}
      id={id}
      data-testid="avatar-image"
    >
      {image ? (
        <img
          src={image}
          aria-hidden="true"
          alt="avatar image"
          className="shared-avatar-image"
          data-testid="shared-avatar-image"
        />
      ) : (
        <span className="shared-avatar-initials" aria-hidden="true">
          {fallback}
        </span>
      )}
    </span>
  );
};

AvatarImage.propTypes = AvatarImageShape;
AvatarImage.defaultProps = {
  className: '',
};

export default AvatarImage;
