import React from 'react';
import Toast from 'src/components/Toast';
import Icon from 'shared/components/icon';
import TextButton from 'src/components/TextButton';

interface ActionNotificationProps {
  action: object;
  actionText: string;
  dismiss: () => void;
  dispatch: (action) => void;
  strongText?: string;
  text: string;
  type: string;
}

const ActionNotification = ({
  dismiss,
  dispatch,
  text,
  type,
  strongText,
  actionText,
  action,
}: ActionNotificationProps): React.ReactElement => (
  <Toast type={type} className="shared-notification-toast">
    <span className="shared-notifications-message-text">
      {strongText && (
        <strong className="shared-notifications-message-text-strong">{`${strongText} `}</strong>
      )}
      {`${text} `}
      <TextButton
        onClick={(): void => {
          dismiss();
          dispatch(action);
        }}
        color="white"
        className="shared-notifications-message-text-action-button"
      >
        {actionText}
      </TextButton>
    </span>
    <Icon
      iconType="times-r"
      className="shared-notifications-dismiss-notification-button"
      onClick={dismiss}
    />
  </Toast>
);

export default ActionNotification;
