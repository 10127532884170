import PropTypes from 'prop-types';
import React from 'react';

class Text extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    header: PropTypes.bool,
    float: PropTypes.string,
    weight: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    italic: PropTypes.bool,
    emphasis: PropTypes.bool,
    inline: PropTypes.bool,
    hover: PropTypes.bool,
    showOverflow: PropTypes.bool,
    color: PropTypes.oneOf([
      'blue',
      'darkergrey',
      'darkgrey',
      'grey',
      'lightgrey',
      'mediumgrey',
      'green',
      'orange',
      'red',
      'white',
    ]),
    onClick: PropTypes.func,
    letterSpacing: PropTypes.bool,
  };

  static defaultProps = {
    header: false,
    weight: 4,
    italic: false,
    emphases: false,
    inline: false,
    letterSpacing: false,
  };

  getClassName = () => {
    const {
      weight,
      italic,
      emphasis,
      color,
      float,
      hover,
      showOverflow,
      inline,
      letterSpacing,
      className,
    } = this.props;

    let allClassNames = 'shared-text';

    allClassNames += ` shared-text-${weight}`;

    [
      [italic, ' shared-text-italic'],
      [emphasis, ' shared-text-emphasis'],
      [color && !hover, ` shared-text-${color}`],
      [hover, ' shared-text-white'],
      [float, ` shared-text-${float}`],
      [inline, ' shared-text-inline'],
      [showOverflow, ' shared-text-show-overflow'],
      [letterSpacing, ' shared-text-letter-spacing'],
      [className, ` ${className}`],
    ].forEach((addClass) => {
      if (addClass[0]) {
        allClassNames += addClass[1];
      }
    });

    return allClassNames;
  };

  render() {
    const {id, header, children, inline, weight, onClick} = this.props;

    if (header) {
      const CustomTag = `h${weight}`;

      return (
        <CustomTag id={id} className={this.getClassName()} onClick={onClick}>
          {children}
        </CustomTag>
      );
    } else if (inline) {
      return (
        <span id={id} className={this.getClassName()} onClick={onClick}>
          {children}
        </span>
      );
    } else {
      return (
        <p id={id} className={this.getClassName()} onClick={onClick}>
          {children}
        </p>
      );
    }
  }
}

export default Text;
