import React, {ReactNode} from 'react';
import fp, {flow} from 'lodash/fp';
import Toast from 'src/components/Toast';
import Icon from 'shared/components/icon';
import {getMedia, watchMedia} from 'shared/mq';
import {MQ} from 'src/utils/mq';

interface SimpleNotificationProps {
  dismiss: () => void;
  iconType?: string;
  text: React.ReactNode;
  type: string;
}

class SimpleNotification extends React.Component<SimpleNotificationProps> {
  private mq;

  componentDidMount(): void {
    this.mq = watchMedia(() => {
      this.forceUpdate();
    });
  }

  firstOf = ({content, matching: mq}: {content: ReactNode; matching: MQ}): ReactNode => {
    const activeMediaQueries = flow(fp.mapKeys(fp.snakeCase), fp.pickBy(fp.identity), fp.keys);

    const firstActiveMediaQueryContent = flow(fp.pick(activeMediaQueries(mq)), fp.values, fp.first);

    return firstActiveMediaQueryContent(content);
  };

  formatContent = (content: ReactNode): ReactNode => {
    switch (typeof content) {
      case 'string':
        return (
          <span
            className="shared-notifications-message-text"
            data-testid="notification-message-text"
          >
            {content}
          </span>
        );
      case 'object':
        return (
          <span className="shared-notifications-message-text">
            {this.firstOf({content, matching: getMedia()})}
          </span>
        );
      default:
        return content;
    }
  };

  render(): JSX.Element {
    const {dismiss, iconType, text, type} = this.props;
    return (
      <Toast type={type} className="shared-notification-toast">
        {iconType && <Icon iconType={iconType} />}
        {this.formatContent(text)}
        <Icon
          iconType="times-r"
          className="shared-notifications-dismiss-notification-button"
          onClick={dismiss}
        />
      </Toast>
    );
  }
}

export default SimpleNotification;
