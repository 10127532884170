import React, {useRef, useEffect} from 'react';

import RoutedTabs from 'src/components/routed-tabs';
import HeaderContent from './header-content';
import {useModalContext} from '.';

import './tabbed-header.scss';

interface Props {
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  onClose: () => void;
  onBack?: () => void;
  textAlign?: 'left' | 'center';
  tabs: {
    label: string;
    onClick?: () => void;
    path: string;
    skipScrolling?: boolean;
  }[];
}

const TabbedHeader = ({children, subtitle, onBack, onClose, textAlign, tabs}: Props) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const {setHeaderHeight} = useModalContext();

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [setHeaderHeight]);

  return (
    <div
      id="shared-modal-header"
      ref={headerRef}
      className="shared-tabbed-modal-header"
      data-testid="shared-tabbed-modal-header"
    >
      <HeaderContent subtitle={subtitle} onClose={onClose} onBack={onBack} textAlign={textAlign}>
        {children}
      </HeaderContent>
      <RoutedTabs tabs={tabs} />
    </div>
  );
};

export default TabbedHeader;
