import PropTypes from 'prop-types';

export const CardGroupingsShape = PropTypes.shape({
  key: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  Container: PropTypes.elementType,
});

export const CardGroupingOrder = PropTypes.arrayOf(PropTypes.string);

export const OpportunityCardPropTypes = {
  loading: PropTypes.bool,
  cards: PropTypes.shape({}),
  cardCount: PropTypes.number.isRequired,
  cardGroupings: CardGroupingsShape.isRequired,
  cardGroupingOrder: CardGroupingOrder.isRequired,
};
