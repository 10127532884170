import {updateUser as _updateUser} from 'shared/actions/student';

export const RECEIVE_STUDENT = 'RECEIVE_STUDENT';
export const CLEAR_STUDENT = 'CLEAR_STUDENT';

// We need these fields for the portfolio checklist.
const STUDENT_FIELDS = [
  'email',
  'skippedChecklistItems',
  'studentInvitations',
  'firstName',
  'isUsCitizenOrResident',
  'followedCollegeIds',
  'currentInstitution',
].join(',');

export const updateUser = (data) => (dispatch) =>
  _updateUser(data, STUDENT_FIELDS).then((student) => dispatch({type: RECEIVE_STUDENT, student}));

export const clearStudent = () => ({
  type: CLEAR_STUDENT,
});
