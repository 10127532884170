import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from 'shared/components/icon';

const propTypes = {
  iconType: PropTypes.string,
  padding: PropTypes.bool,
  color: PropTypes.oneOf(['blue', 'grey']),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
};

const HighlightedText = ({iconType, padding = true, color = 'blue', text}) => (
  <div className={classnames(`college-profile-highlighted-text ${color}`, {padding})}>
    <Icon
      iconType={iconType}
      className={classnames('college-profile-highlighted-text-icon', {padding})}
    />
    <span className="college-profile-highlighted-text-content">{text}</span>
  </div>
);

HighlightedText.propTypes = propTypes;

export default HighlightedText;
