import PropTypes from 'prop-types';
import React from 'react';
import {filter, sum} from 'lodash';
import StackedBarChart from 'shared/college-profile/components/stacked-bar-chart';
import HighlightedText from 'shared/college-profile/components/highlighted-text';
import InfoTooltip from 'shared/components/info-tooltip';
import {withCommas} from 'shared/utils';

const formatValue = (value) => `$${withCommas(value)} /year`;

const roomAndBoard = (
  <span>
    On-Campus Food &&nbsp;
    <div className="connected-words">
      Housing
      <InfoTooltip className="college-profile-tooltip reduced-margin">
        The estimated annual cost to live on campus in a typical room with a typical meal plan
        on-campus.
      </InfoTooltip>
    </div>
  </span>
);

const indirectCosts = (
  <span>
    Indirect&nbsp;
    <div className="connected-words">
      Costs
      <InfoTooltip className="college-profile-tooltip">
        The estimated annual costs for educational expenses&mdash;like books and supplies, travel,
        and personal expenses such as laundry, snacks, and entertainment.
      </InfoTooltip>
    </div>
  </span>
);

const propTypes = {
  college: PropTypes.shape({
    tuition: PropTypes.number,
    onCampusHousingCost: PropTypes.number,
    onCampusOtherCosts: PropTypes.number,
    booksAndSuppliesCost: PropTypes.number,
    state: PropTypes.string.isRequired,
    control: PropTypes.string.isRequired,
  }),
  userState: PropTypes.string.isRequired,
};

const EstimatedCostOfAttendance = ({college, userState}) => {
  const {tuition, onCampusHousingCost, onCampusOtherCosts, booksAndSuppliesCost, state, control} =
    college;

  const totalCost = sum([tuition, onCampusHousingCost, onCampusOtherCosts, booksAndSuppliesCost]);

  if (totalCost <= 0) return null;

  const inState = userState === state;
  const tuitionDisplay = (
    <span>
      {control === 'Private' ? 'Tuition' : `${inState ? 'In' : 'Out-of'}-State Tuition`}
      &nbsp;&&nbsp;
      <div className="connected-words">
        Fees
        <InfoTooltip className="college-profile-tooltip">
          The estimated annual cost of the classes you will take and other mandatory costs of campus
          life.
        </InfoTooltip>
      </div>
    </span>
  );

  const groups = [
    {name: tuitionDisplay, value: tuition},
    {name: roomAndBoard, value: onCampusHousingCost},
    {name: indirectCosts, value: onCampusOtherCosts + booksAndSuppliesCost},
  ];

  return (
    <StackedBarChart
      caption="Estimated Cost of Attendance"
      groups={filter(groups, (group) => group.value)}
      colorScheme="green"
      formatValue={formatValue}
      sort={false}
      footer={
        <HighlightedText
          iconType="tag"
          text={[
            <span key="est-cost">Total Cost of Attendance</span>,
            <span key="est-cost-amount">{formatValue(totalCost)}</span>,
          ]}
        />
      }
    />
  );
};

EstimatedCostOfAttendance.propTypes = propTypes;

export default EstimatedCostOfAttendance;
