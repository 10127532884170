import {sortBy} from 'lodash';
import {
  SET_RECIPIENT,
  SET_ACTIVE_THREAD,
  RECEIVE_THREADS,
  RECEIVE_THREAD_MESSAGES,
  UPDATE_CURRENT_THREAD,
} from '../actions/messaging';

export const messagingReducer = (
  state = {fakeSubmittedMessages: {}, threadId: null, threadIndex: 0},
  action
) => {
  const newThreads = state.threads;
  const newMessages = state.currentThreadMessages;

  switch (action.type) {
    case SET_RECIPIENT:
      return {
        ...state,
        recipient: action.recipient,
      };
    case SET_ACTIVE_THREAD:
      return {
        ...state,
        currentThreadMessages: [],
        threadId: state.threads[action.threadIndex].id,
        threadIndex: action.threadIndex,
      };
    case RECEIVE_THREADS:
      if (action.threads.length === 0) {
        action.threads = [
          {
            id: 'no-threads',
            lastActivity: new Date(),
            recipient: {
              firstName: 'Pending First Message',
              lastName: '',
            },
            recipientsCount: 0,
            sender: {
              firstName: 'No Messages Found',
              lastName: '',
            },
          },
        ];
      }

      return {
        ...state,
        threads: action.threads,
      };
    case RECEIVE_THREAD_MESSAGES:
      if (action.threadMessages.length === 0) {
        action.threadMessages = [
          {
            id: 'no-message-id',
          },
        ];
      }

      return {
        ...state,
        currentThreadMessages: action.threadMessages,
      };
    case UPDATE_CURRENT_THREAD:
      newThreads[state.threadIndex] = {
        ...newThreads[state.threadIndex],
        lastActivity: action.message.timeSent,
      };
      newMessages.push(action.message);

      return {
        ...state,
        currentThreadMessages: newMessages.reverse(),
        threadIndex: 0,
        threads: sortBy(newThreads, 'lastActivity').reverse(),
      };
    default:
      return state;
  }
};
