import {lazy} from 'react';
import {RoutedModalConfig} from '../ModalContext/types';
import {PortfolioItemModalParams} from '../PortfolioItemModal';

export const workExperienceModalConfig: RoutedModalConfig<PortfolioItemModalParams> = {
  type: 'work-experience',
  routed: true,
  Component: lazy(
    () => import(/* webpackChunkName: "WorkExperienceModal" */ './WorkExperienceModal')
  ),
};
