import {forEach, map} from 'lodash';
import GoogleApiContacts from 'shared/modules/google-api-contacts';
import req from 'shared/req';
import ReferralService from 'src/services/ReferralService';
import browserHistory from 'shared/history';

const Raise = window.Raise;

export const FOLLOW_COLLEGE = 'REFERRAL_MODAL/FOLLOW_COLLEGE';
export const UNFOLLOW_COLLEGE = 'REFERRAL_MODAL/UNFOLLOW_COLLEGE';
export const SET_PAGE = 'REFERRAL_MODAL/SET_PAGE';
export const PREPEND_INVITATIONS = 'REFERRAL_MODAL/PREPEND_INVITATIONS';
export const APPEND_INVITATIONS = 'REFERRAL_MODAL/APPEND_INVITATIONS';
export const UPDATE_IDENTIFIER = 'REFERRAL_MODAL/UPDATE_IDENTIFIER';
export const SENT_INVITE = 'REFERRAL_MODAL/SENT_INVITE';
export const CLEAR_INPUT = 'REFERRAL_MODAL/CLEAR_INPUT';
export const RECEIVE_CONTACTS = 'REFERRAL_MODAL/RECEIVE_CONTACTS';
export const SELECT_CONTACT = 'REFERRAL_MODAL/SELECT_CONTACT';
export const SELECT_ALL_CONTACTS = 'REFERRAL_MODAL/SELECT_ALL_CONTACTS';
export const UPDATE_FILTER = 'REFERRAL_MODAL/UPDATE_FILTER';
export const OPEN_REFERRAL_MODAL = 'REFERRAL_MODAL/OPEN_REFERRAL_MODAL';
export const CLOSE_REFERRAL_MODAL = 'REFERRAL_MODAL/CLOSE_REFERRAL_MODAL';
export const RECEIVE_PARTICIPATING_COLLEGES = 'RECEIVE_PARTICIPATING_COLLEGES';

export const followCollege = (collegeId) => (dispatch) => {
  const url = `/v1/colleges/${collegeId}/follow`;

  req({
    url,
    method: 'post',
  }).then(() => {
    dispatch({type: FOLLOW_COLLEGE, collegeId});
  });
};

export const unfollowCollege = (collegeId) => (dispatch) => {
  const url = `/v1/colleges/${collegeId}/unfollow`;

  req({
    url,
    method: 'post',
  }).then(() => {
    dispatch({type: UNFOLLOW_COLLEGE, collegeId});
  });
};

export const setPage = (page) => ({type: SET_PAGE, page});

export const updateIdentifier = (identifier) => ({type: UPDATE_IDENTIFIER, identifier});

const prependInvitations = (invitations) => {
  Raise && Raise.vent.trigger(PREPEND_INVITATIONS, {invitations});
  return {type: PREPEND_INVITATIONS, invitations};
};

const appendInvitations = (invitations) => ({type: APPEND_INVITATIONS, invitations});

export const fetchInvitations = () => (dispatch) => {
  req({
    method: 'get',
    url: '/students/get-referral-status-hash',
  }).then((invitations) => {
    dispatch(prependInvitations(invitations));
  });
};

export const sendInvite =
  (identifier, referralSource = 'referral-popover', silent, append = false) =>
  (dispatch) => {
    dispatch({type: SENT_INVITE});

    ReferralService.sendReferral(identifier, referralSource, silent).then((invite) => {
      const invitation = {
        display: invite.identifier,
        status: invite.status === 'joined' ? 'Signed up with Another Link' : 'Invited',
        image: 'https://resources.raise.me/images/avatar.png',
        timestamp: Date.now(),
      };

      if (append) {
        dispatch(appendInvitations([invitation]));
      } else {
        dispatch(prependInvitations([invitation]));
      }

      setTimeout(() => {
        dispatch({type: CLEAR_INPUT});
      }, 1000);
    });
  };

export const getContacts = () => (dispatch) => {
  GoogleApiContacts.getContacts().done((contacts) => {
    const pathParts = window.location.pathname.split('/invite/');
    pathParts.pop(); // remove the last part of the path
    const newPath = `${pathParts.join('/')}/invite/contacts`;
    dispatch({type: RECEIVE_CONTACTS, contacts});
    browserHistory.push({pathname: newPath});
  });
};

export const selectContact = (email) => ({type: SELECT_CONTACT, email});

export const selectAllContacts = () => ({type: SELECT_ALL_CONTACTS});

export const updateFilter = (filter) => ({type: UPDATE_FILTER, filter});

export const sendInvites = (emails) => (dispatch) => {
  req({
    url: '/students/create-referrals',
    method: 'post',
    data: {referrals: emails, referralType: 'google-contact-import'},
  }).then((res) => {
    const now = Date.now();
    const invitations = map(res.referrals, (invite) => ({
      display: invite,
      status: 'Invited',
      image: 'https://resources.raise.me/images/avatar.png',
      timestamp: now,
    }));

    dispatch(prependInvitations(invitations));
    dispatch({type: SET_PAGE, page: 0});
  });
};

export const openReferralModal = () => ({type: OPEN_REFERRAL_MODAL});

export const closeReferralModal = () => ({type: CLOSE_REFERRAL_MODAL});

export const receiveParticipatingColleges = (colleges) => ({
  type: RECEIVE_PARTICIPATING_COLLEGES,
  colleges,
});

export const addCollegeToCollection = (
  collection,
  {id, slug, name, city, state},
  followed = false
) => {
  collection.push({
    address: `${city}, ${state}`,
    id,
    slug,
    name,
    followed,
  });
};

export const fetchParticipatingColleges = () => (dispatch) =>
  req({
    url: '/v1/micro-scholarships/ReferralScholarship',
  }).then((data) => {
    const participatingColleges = [];

    forEach(data.collegesOffering.followed, (college) => {
      addCollegeToCollection(participatingColleges, college, true);
    });

    forEach(data.collegesOffering.unfollowed, (college) => {
      addCollegeToCollection(participatingColleges, college);
    });

    return dispatch(receiveParticipatingColleges(participatingColleges));
  });
