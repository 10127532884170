import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import NonStudentScholarships from '../components/scholarships/non-student-scholarships';
import {changeScholarshipProgram} from '../actions/college-profile';

const mapStateToProps = (state) => ({
  collegeProfile: state.collegeProfile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeScholarshipProgram,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NonStudentScholarships);
