import React from 'react';
import PropTypes from 'prop-types';
import {percentFormat} from 'src/utils/collegeSearch';
import RangeFilter from 'shared/components/filters/range-filter';
import Text from 'shared/components/text';

class GraduationFilters extends React.Component {
  static propTypes = {
    getColleges: PropTypes.func.isRequired,
  };

  render() {
    const {getColleges} = this.props;

    return (
      <div className="college-search-filters-section">
        <fieldset role="group" aria-label="Filter by Graduation Rate">
          <legend className="college-search-filters-section-legend">
            <Text id="college-search-grad-rate" weight={5} emphasis>
              Graduation Rate
            </Text>
          </legend>
          <RangeFilter
            name="six-year-grad-rate"
            step={1}
            minValue={0}
            maxValue={100}
            labelFunc={percentFormat}
            onChange={getColleges}
            labelledby="college-search-grad-rate"
          />
        </fieldset>
      </div>
    );
  }
}

export default GraduationFilters;
