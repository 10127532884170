import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'shared/components/icon';
import CarouselArrow from 'shared/components/carousel-arrow';
import MQ from 'shared/media-query';
import 'stylesheets/components/college-profile/college-images-lightbox.scss';

class CollegeImagesLightbox extends React.Component {
  static propTypes = {
    image: PropTypes.object,
    changeLightboxPage: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    imageCount: PropTypes.number,
    name: PropTypes.string.isRequired,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.keyHandler, {capture: true});
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyHandler, {capture: true});
  }

  keyHandler = (e) => {
    const {changeLightboxPage, currentPage, imageCount} = this.props;

    if (e.key === 'ArrowRight' && parseInt(currentPage) !== imageCount - 1) {
      changeLightboxPage(1)();
    } else if (e.key === 'ArrowLeft' && parseInt(currentPage) !== 0) {
      changeLightboxPage(-1)();
    }
  };

  render() {
    const {changeLightboxPage, image, currentPage, imageCount, handleClose, name} = this.props;

    return (
      <div className="college-photos-lightbox" data-testid="images-lightbox">
        <div className="college-photos-lightbox-exit">
          <Icon className="lightbox-icon" onClick={handleClose} iconType="times-r" />
        </div>
        <div className="college-photos-lightbox-content-container">
          <div className="college-photos-lightbox-photo">{image}</div>
          <div className="lightbox-navigation">
            <div className="lightbox-info">
              <span className="lightbox-info-title">
                <Icon iconType="instagram" /> {name}
              </span>
              <span className="lightbox-info-pageCount">{`${
                currentPage + 1
              } of ${imageCount}`}</span>
            </div>
            <MQ.MS_AND_DOWN>
              <hr />
            </MQ.MS_AND_DOWN>
            <div className="college-photos-lightbox-controls">
              {currentPage !== 0 ? (
                <>
                  <MQ.MS_AND_DOWN>
                    <Icon
                      className="lightbox-icon"
                      iconType="chevron-left"
                      onClick={changeLightboxPage(-1)}
                    />
                  </MQ.MS_AND_DOWN>
                  <MQ.SM_AND_UP>
                    <CarouselArrow
                      className="college-photos-lightbox-controls-arrow"
                      direction="left"
                      onClick={changeLightboxPage(-1)}
                    />
                  </MQ.SM_AND_UP>
                </>
              ) : (
                <span>&nbsp;</span>
              )}
              {currentPage !== imageCount - 1 && (
                <>
                  <MQ.MS_AND_DOWN>
                    <Icon
                      className="lightbox-icon"
                      iconType="chevron-right"
                      onClick={changeLightboxPage(1)}
                    />
                  </MQ.MS_AND_DOWN>
                  <MQ.SM_AND_UP>
                    <CarouselArrow
                      className="college-photos-lightbox-controls-arrow"
                      direction="right"
                      onClick={changeLightboxPage(1)}
                    />
                  </MQ.SM_AND_UP>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CollegeImagesLightbox;
