import classNames from 'classnames';
import {includes} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {getMedia, watchMedia} from 'shared/mq';
import FilterTags from 'src/components/CollegeSearch/FilterTags';
import FilteredColleges from 'src/components/CollegeSearch/FilteredColleges';
import Filters from 'src/components/CollegeSearch/Filters';
import FiltersMobile from 'src/components/CollegeSearch/FiltersMobile';
import Header from 'src/components/CollegeSearch/Header';
import LoadingSearch from 'src/components/CollegeSearch/LoadingSearch';
import SortByMobile from 'src/components/CollegeSearch/SortByModalMobile';
import StatusNotification from 'src/components/CollegeSearch/StatusNotification';
import TermNotOfferedModal from 'src/components/CollegeSearch/TermNotOfferedModal';
import FeatureFlagService from 'src/services/FeatureFlagService';
import {PlatformContext} from 'src/components/PlatformContext';
import {UserType} from 'src/types/enums';
import InlineCollegeSearchBar from './InlineCollegeSearchBar';

class CollegeSearch extends React.Component {
  static propTypes = {
    collegeSearch: PropTypes.object.isRequired,
    getColleges: PropTypes.func.isRequired,
    followCollege: PropTypes.func.isRequired,
    unfollowCollege: PropTypes.func.isRequired,
    resetPage: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    transfer: PropTypes.bool.isRequired,
    getCollegeAppStatuses: PropTypes.func.isRequired,
    setCollegeAppStatus: PropTypes.func.isRequired,
    collegeApplicationStatuses: PropTypes.object.isRequired,
    setNavigationAnnouncement: PropTypes.func,
    showWelcomeModal: PropTypes.func.isRequired,
    openTermNotOfferedModal: PropTypes.func.isRequired,
    initializeCollegeSearch: PropTypes.func.isRequired,
    filtersInitialized: PropTypes.bool.isRequired,
    userType: PropTypes.string.isRequired,
  };

  static contextType = PlatformContext;

  state = {
    initializedColleges: false,
    showApplicationStatusMenu: false,
  };

  componentDidMount() {
    const {props} = this;
    const isHsOrTransferStudent =
      props.userType === UserType.HS_STUDENT || props.userType === UserType.CC_STUDENT;

    this.mq = watchMedia(() => {
      this.forceUpdate();
    });

    FeatureFlagService.getFeatureFlagStatuses(['college_application_status']).then(
      ({collegeApplicationStatus}) =>
        this.setState({showApplicationStatusMenu: collegeApplicationStatus})
    );

    props.resetPage();

    if (includes([UserType.HS_STUDENT], props.userType)) {
      props.showWelcomeModal();
    }

    props.setNavigationAnnouncement('Colleges');

    if (props.userType !== UserType.HS_STUDENT) {
      props.initializeCollegeSearch(props.userType);
    }

    if (isHsOrTransferStudent && this.state.showApplicationStatusMenu) {
      props.getCollegeAppStatuses();
    }
  }

  componentDidUpdate() {
    const {state, props} = this;
    if (props.filtersInitialized && !state.initializedColleges) {
      this.setState({initializedColleges: true});
      props.getColleges();
    }
  }

  componentWillUnmount() {
    this.mq.remove();
  }

  render() {
    const mq = getMedia();
    const {
      getColleges,
      followCollege,
      unfollowCollege,
      collegeSearch,
      collegeSearch: {loading},
      filters,
      transfer,
      setCollegeAppStatus,
      collegeApplicationStatuses,
      openTermNotOfferedModal,
      userType,
    } = this.props;
    const {notification} = this.state;
    const {isIonic} = this.context;

    return (
      <div
        className={classNames('college-search-container', {
          '--no-tabs':
            userType === UserType.EDUCATOR || (mq.MS_AND_DOWN && userType === UserType.CC_STUDENT),
          '--ionic': isIonic,
        })}
      >
        <InlineCollegeSearchBar />
        <Header transfer={transfer} toolbar={notification} />
        <div
          className="college-search"
          aria-label={`This page includes a list of colleges that may add more colleges to
            the list when the user scrolls to the bottom of the page. If automatic
            loading does not occur, a "Load more colleges" button may appear at
            the bottom of the list to fetch more colleges. There is no other
            content below the list of colleges.`}
        >
          {loading ? (
            <LoadingSearch />
          ) : (
            <>
              <div className="college-search-sidebar-desktop">
                <Filters transfer={transfer} />
              </div>
              <div className="college-search-body">
                <div className="college-search-filter-tags">
                  <FilterTags />
                </div>
                {userType === UserType.CC_STUDENT && <TermNotOfferedModal />}
                <FilteredColleges
                  collegeSearch={collegeSearch}
                  followCollege={followCollege}
                  unfollowCollege={unfollowCollege}
                  getColleges={getColleges}
                  setCollegeAppStatus={setCollegeAppStatus}
                  collegeApplicationStatuses={collegeApplicationStatuses}
                  openTermNotOfferedModal={openTermNotOfferedModal}
                  showApplicationStatusMenu={this.state.showApplicationStatusMenu}
                />
              </div>
              <FiltersMobile filters={filters} transfer={transfer} />
              <SortByMobile />
              {userType === UserType.HS_STUDENT && <StatusNotification />}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default CollegeSearch;
