// accessibility-announcement
import {SET_ACCESSIBILITY_ANNOUNCEMENT} from 'shared/actions/accessibility-announcement';

export const accessibilityAnnouncementReducer = (state = '', action) => {
  switch (action.type) {
    case SET_ACCESSIBILITY_ANNOUNCEMENT:
      return action.announcement;
    default:
      return state;
  }
};
