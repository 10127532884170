import {forEach, isArray, isObject, kebabCase, map} from 'lodash';

export const formatColleges = (colleges) => colleges.map(formatCollege);

export function formatCollege(college) {
  return {
    ...college,
    id: college._id,
    bannerUrl: college.banner,
    logoUrl: college.logo,
    // @deprecated use isFollowing
    following: college.isFollowing,
    deadlineMonth: college.partnerSubmissionDeadlineMonth,
    deadlineDay: college.partnerSubmissionDeadlineDay,
    deadlineYear: college.partnerSubmissionDeadlineYear,
    deadlineTerm: college.partnerSubmissionDeadlineTerm,
    avgNetPrice: college.averageNetPrice,
    graduationRate: college.sixYearGraduationRate,
    studentSubmissionYear: college.studentSubmissionYear,
    studentSubmissionTerm: college.studentSubmissionTerm,
  };
}

export function formatInitialFilters(filters) {
  const formatted = isArray(filters) ? [] : {};

  forEach(Object.keys(filters), (key) => {
    const newKey = kebabCase(key);
    const value = filters[key];

    if (isObject(value)) {
      formatted[newKey] = formatInitialFilters(value);
    } else {
      formatted[newKey] = value;
    }
  });

  return formatted;
}

export function formatFilters(filters) {
  const formattedFilters = {};

  forEach(filters, (value, name) => {
    if (name === 'location') {
      const locations = map(value, (val) => {
        if ('city' in val) {
          const commaIndex = val.city.lastIndexOf(', ');
          return {
            city: val.city.substring(0, commaIndex),
            state: val.city.substring(commaIndex + 2),
            loc: val.loc,
          };
        } else {
          return val;
        }
      });

      formattedFilters[name] = JSON.stringify(locations);
    } else if (isArray(value)) {
      // Majors can have commas in their names so they are pipe separated.
      const separator = name === 'majors' || name === 'name' ? '|' : ',';
      formattedFilters[name] = value.join(separator);
    } else if (isObject(value) && 'min' in value && 'max' in value) {
      formattedFilters[name] = `${value.min},${value.max}`;
    } else {
      formattedFilters[name] = value;
    }
  });

  return formattedFilters;
}

// Takes a number < 1,000,000 and returns it in shortened
// currency format. E.g. currentFormat(50000) -> '$50K'
export function currencyFormat(number) {
  let result = number.toString();

  if (result.length >= 4) {
    result = result.substring(0, result.length - 3).concat('K');
  }

  return `$${result}`;
}

export function percentFormat(string) {
  return `${string}%`;
}
