import React from 'react';

import {Modal as MuiModal} from '@mui/material';
import CollegeImagesLightbox from 'shared/college-profile/components/about-college/college-images-lightbox';

import './image-modal.scss';

interface Props {
  show: boolean;
  onClose: () => void;
  image: React.ReactNode;
  onPageChange: (pageDirection: number) => () => void;
  currentPage: number;
  imageCount?: number;
  name?: string;
}

const ImageModal = ({show, onClose, image, onPageChange, currentPage, imageCount, name}: Props) => (
  <MuiModal
    open={show}
    onClose={onClose}
    slotProps={{backdrop: {className: 'shared-image-modal-backdrop'}}}
  >
    <div className="shared-image-modal">
      <CollegeImagesLightbox
        handleClose={onClose}
        changeLightboxPage={onPageChange}
        image={image}
        name={name}
        currentPage={currentPage}
        imageCount={imageCount}
      />
    </div>
  </MuiModal>
);

export default ImageModal;
