import {find} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useState, ReactElement, createContext, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import UserConnectionsService from 'src/services/UserConnectionsService';
import {Store} from 'src/types/store';
import {UserConnection} from 'src/types/userConnections';

interface ImpostorModeContextType {
  isImpostor: boolean;
  currentConnection: UserConnection | null;
  userConnections: UserConnection[];
}

interface Props {
  children: ReactNode;
}

export const ImpostorModeContext = createContext<ImpostorModeContextType>(
  {} as ImpostorModeContextType
);
ImpostorModeContext.displayName = 'ImpostorModeContext';

export function ImpostorModeProvider(props: Props): ReactElement {
  const [isImpostor, setIsImpostor] = useState<boolean>(false);
  const [currentConnection, setCurrentConnection] = useState<UserConnection | null>(null);
  const [userConnections, setUserConnections] = useState<UserConnection[]>([]);
  const authenticated = useSelector((state: Store) => state.authenticated);

  useEffect(() => {
    if (!authenticated) return;
    UserConnectionsService.index({
      fields: 'status,userConnectionInfo,type,connectedUser,impostorUser',
    })
      .then((response) => {
        setUserConnections(response);
        if (response.length) {
          const targetConnection = find<UserConnection>(
            response,
            (connection: UserConnection) => connection.connectedUser.impersonatedUser
          );
          if (targetConnection) {
            setCurrentConnection(targetConnection);
          }
          setIsImpostor(!!targetConnection);
        }
      })
      .catch(() => {
        console.error('Failed to load user connections');
      });
  }, []);

  return (
    <ImpostorModeContext.Provider value={{isImpostor, userConnections, currentConnection}}>
      {props.children}
    </ImpostorModeContext.Provider>
  );
}

ImpostorModeProvider.propTypes = {
  children: PropTypes.node,
};
