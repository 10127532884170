import React, {useEffect, useState} from 'react';
import {
  AnyModalConfig,
  ModalContext,
  ModalContextType,
  ModalProps,
  ModalProviderProps,
} from './types';
import {
  getMatchingConfig,
  getQueryParams,
  launchRoutedModal,
  previousSearchParams,
  validateProps,
} from 'src/components/ModalContext/utils';
import Suspense from 'src/components/Suspense';
import {withRouter} from 'react-router-dom';

interface Modal {
  config: AnyModalConfig | null;
  props: ModalProps;
}
const NULL_MODAL: Modal = {config: null, props: null};

/**
 * This component renders controlled and routed modals based on incoming configurations and by listening for
 * changes to the query parameters in the url.
 *
 * The context provides show and hide methods allowing the modal and context consumers to control the active modal state.
 * Using the following pattern for routed modals saves the current query parameters and restores them when the modal is closed.
 *
 * Launch a routed modal by calling launchRoutedModal() providing the modal name and props.
 * Launch a controlled modal by using the ModalContext and invoking show() providing the modal name and props
 *
 * Example url - raise.me/portfolio ? view=claim-club & activityId = 1234
 *
 * Example configuration
 * {
 *     // Name of the modal
 *     type: claim-club
 *
 *     // Lazily loaded modal
 *     Component: lazy(() => import(/** webpackChunkName: "ClaimClubModal" ** / './ClaimClubModal')),
 *
 *     // returns boolean if all required params are present
 *     validateProps: (params) => Boolean(params.activityId),
 *
 *     // only required for routed modals
 *     routed: true
 * }
 *
 * The above url and configuration would render ClaimClubModal providing router props and parsed params.
 */
function ModalProvider(
  props: ModalProviderProps & {children?: React.ReactNode}
): React.ReactElement {
  const [modal, setModal] = useState<Modal>(NULL_MODAL);

  function resetQueryParams(): void {
    const search = previousSearchParams.pop() || '';
    props.history.push({
      search,
      pathname: props.location.pathname,
      state: {skipScrolling: true},
    });
  }

  const show: ModalContextType['show'] = (modalType, modalProps) => {
    const modalConfig = props.configs.find((config) => config.type === modalType);
    if (!modalConfig) {
      console.error(`Missing view configuration ${modalType}`);
      return;
    }

    const validProps = validateProps(modalConfig, modalProps);
    if (!validProps) {
      return;
    }

    if (modalConfig.routed) {
      launchRoutedModal(modalType, modalProps);
    } else {
      setModal({config: modalConfig, props: modalProps});
    }
  };

  const hide: ModalContextType['hide'] = () => {
    if (modal.config && modal.config.routed) {
      resetQueryParams();
    }

    setModal(NULL_MODAL);
  };

  useEffect(() => {
    const params = getQueryParams(props.location);
    const config = getMatchingConfig(params, props.configs);

    if (params && config) {
      if (!validateProps(config, params)) {
        resetQueryParams();
        return;
      }

      setModal({
        config: config,
        props: {
          history: props.history,
          match: props.match,
          location: props.location,
          data: params,
        },
      });
    } else if (modal.config && modal.config.routed) {
      hide();
    }
  }, [props.configs, props.location]);

  return (
    <ModalContext.Provider
      value={{show: show, hide, activeModal: modal.config && modal.config.type}}
    >
      {modal.config && (
        <Suspense>
          <modal.config.Component onClose={hide} {...modal.props} />
        </Suspense>
      )}
      {props.children}
    </ModalContext.Provider>
  );
}

export default withRouter(ModalProvider);
