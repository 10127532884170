import qs from 'qs';
import {cleanCollegeSearchFilters} from 'src/components/CollegeSearch/FilterTags/FilterTags';

const LOCAL_STORAGE_KEY = 'savedCollegeSearch';

type CollegeSearchFilters = Record<string, CollegeSearchFilter>;
type CollegeSearchFilter = [] | object;
type SortOrder = 'asc' | 'desc';

interface CollegeSearch {
  filters: CollegeSearchFilters;
  order: SortOrder;
  sort: string;
}
type SaveableCollegeSearch = CollegeSearch & {schemaVersion: 1};

const DEFAULT_SAVED_SEARCH: CollegeSearch = {
  filters: {},
  order: 'asc',
  sort: 'distance',
};

export const getSearchFromUrl = (): CollegeSearch | null => {
  const urlParameters = qs.parse(location.search.split('?').pop() || '');

  const filters = cleanCollegeSearchFilters(urlParameters.filters || {});
  if (Object.keys(filters).length < 1 && !urlParameters.sortBy && !urlParameters.sortOrder) {
    return null;
  }

  const sort = urlParameters.sortBy || DEFAULT_SAVED_SEARCH.sort;
  const order = urlParameters.sortOrder || DEFAULT_SAVED_SEARCH.order;

  return {filters, sort, order};
};

export const getSavedCollegeSearch = (): CollegeSearch | null => {
  try {
    const savedSearch = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!savedSearch) {
      return null;
    }

    const parsedSearch: SaveableCollegeSearch = JSON.parse(savedSearch);
    const filters = cleanCollegeSearchFilters(parsedSearch.filters || {});
    if (Object.keys(filters).length < 1 && !parsedSearch.sort && !parsedSearch.sort) {
      return null;
    }

    return {
      filters,
      sort: parsedSearch.sort,
      order: parsedSearch.order,
    };
  } catch (e) {
    console.error('Error reading saved college search from localStorage', e);
  }

  return null;
};

const saveCollegeSearch = (collegeSearch: CollegeSearch) => {
  const searchToStore: SaveableCollegeSearch = {
    ...collegeSearch,
    schemaVersion: 1,
  };
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(searchToStore));
};

const updateUrlToMatchSearch = (collegeSearch: CollegeSearch) => {
  window.history.replaceState(
    {},
    '',
    `/colleges/search?${qs.stringify(
      {
        filters: collegeSearch.filters,
        sortBy: collegeSearch.sort,
        sortOrder: collegeSearch.order,
      },
      {encode: false}
    )}`
  );
};

const getCollegeSearch = (): CollegeSearch =>
  getSearchFromUrl() || getSavedCollegeSearch() || DEFAULT_SAVED_SEARCH;

const updateSavedSearchAndURLParameters = (collegeSearch: CollegeSearch) => {
  try {
    collegeSearch.filters = cleanCollegeSearchFilters(collegeSearch.filters);
    saveCollegeSearch(collegeSearch);
    updateUrlToMatchSearch(collegeSearch);
  } catch (e) {
    console.error('Error saving college search to localStorage', e);
  }
};

export {getCollegeSearch, updateSavedSearchAndURLParameters};
export type {CollegeSearch};
