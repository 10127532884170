import PropTypes from 'prop-types';
import React from 'react';
import StatisticsTable from './statistics-table';
import {acceptanceLikelihoodClassification} from 'shared/college-profile/utils';
import AcceptanceLikelihoodExplanation from './acceptance-likelihood/acceptance-likelihood-explanation';
import Icon from 'shared/components/icon';

class SATTable extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    showAdmissability: PropTypes.bool,
  };

  addDefaultScores(values) {
    values.push(<a href="/portfolio#scores">Enter Score to See Match</a>);
    values.push(<Icon iconType="lock-alt" className="admissability-locked-icon" />);
  }

  getRows() {
    const {
      college: {satCriticalReadingRange, satMathematicsRange},
      showAdmissability,
      userInfo: {satScore},
    } = this.props;
    const rows = [];

    if (satCriticalReadingRange) {
      const values = [`${satCriticalReadingRange[0]}-${satCriticalReadingRange[1]}`];

      if (showAdmissability) {
        if (satScore) {
          values.push(satScore.scoreReadingAndWriting);
          values.push(
            acceptanceLikelihoodClassification(
              satScore.scoreReadingAndWriting,
              satCriticalReadingRange[0],
              satCriticalReadingRange[1]
            )
          );
        } else {
          this.addDefaultScores(values);
        }
      }

      rows.push({
        name: 'SAT English',
        values,
      });
    }

    if (satMathematicsRange) {
      const values = [`${satMathematicsRange[0]}-${satMathematicsRange[1]}`];

      if (showAdmissability) {
        if (satScore) {
          values.push(satScore.scoreMathematics);
          values.push(
            acceptanceLikelihoodClassification(
              satScore.scoreMathematics,
              satMathematicsRange[0],
              satMathematicsRange[1]
            )
          );
        } else {
          this.addDefaultScores(values);
        }
      }

      rows.push({
        name: 'SAT Math',
        values,
      });
    }

    return rows;
  }

  render() {
    const {
      showAdmissability,
      userInfo: {satScore},
    } = this.props;

    const rows = this.getRows();
    const cols = ['Subject', 'Avg. Range'];

    if (showAdmissability) {
      cols.push('Your Score');
      cols.push(
        <span>
          Match
          <AcceptanceLikelihoodExplanation />
        </span>
      );
    }

    let className = '';
    if (showAdmissability) {
      if (satScore) {
        className = 'wide-row-headers';
      } else {
        className = 'with-score-cta';
      }
    }

    return (
      <StatisticsTable className={className} aria-label="SAT Breakdown" header={cols} rows={rows} />
    );
  }
}

export default SATTable;
