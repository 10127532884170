import React from 'react';
import AccessibleTypeahead from 'shared/components/accessible-typeahead';
import TagGroup from 'shared/components/tag-group';
import {compact} from 'lodash';

export interface MultiselectProps {
  id: string;
  selected: string[];
  maximumSelections?: number;
  options: (string | {_id: string; name: string})[];
  onSelect: (selected: string, typeahead: HTMLSelectElement) => void;
  onDelete: (deleted: string) => void;
  placeholder?: string;
}

export default function Multiselect({
  id,
  maximumSelections,
  selected,
  options,
  onDelete,
  onSelect,
  ...accessibleTypeaheadInfo
}: MultiselectProps): React.ReactElement {
  const mappedOptions = options.map((option) => {
    if (typeof option === 'string') {
      return {_id: option, name: option};
    }

    return option;
  });

  const renderedOptions = mappedOptions.filter((option) => !selected.includes(option._id));

  const optionsById = mappedOptions.reduce((acc, option) => {
    acc[option._id] = option;

    return acc;
  }, {});

  const data = compact(selected.map((selectedId) => optionsById[selectedId]));

  const showTypeahead = !maximumSelections || selected.length < maximumSelections;

  return (
    <div>
      {showTypeahead && (
        <AccessibleTypeahead
          id={`${id}-multiselect-typeahead`}
          cy={`${id}-multiselect-typeahead`}
          {...accessibleTypeaheadInfo}
          onSelect={({_id}, typeahead): void => onSelect(_id, typeahead)}
          options={renderedOptions}
          minLength={0}
          labelKey="name"
          filterBy={['name']}
        />
      )}
      <TagGroup
        data={data}
        onClose={(_id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          onDelete(_id);
        }}
      />
    </div>
  );
}
