import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
  fetchInitialCollegeInfo,
  fetchCollege,
  fetchPartnerScholarships,
  getCollegeTestimonials,
  getSimilarColleges,
  toggleLoading,
  toggleLoadingScholarships,
  clearCollegeInfo,
} from 'shared/college-profile/actions/college-profile';
import Layout from '../components/layout';
import {resetSearch} from 'shared/college-search-bar/actions';
import {setNavigationAnnouncement} from 'shared/actions/accessibility-announcement';

const mapStateToProps = ({collegeProfile}) => ({collegeProfile});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchInitialCollegeInfo,
      fetchCollege,
      fetchPartnerScholarships,
      getCollegeTestimonials,
      getSimilarColleges,
      toggleLoading,
      toggleLoadingScholarships,
      clearCollegeInfo,
      setNavigationAnnouncement,
    },
    dispatch
  ),
  resetNavBarSearch: () => dispatch(resetSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
