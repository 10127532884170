import {filter} from 'lodash';
import {pathWithoutModal} from 'shared/utils';

export const closeActivityModalPath = () => ({
  pathname: pathWithoutModal('/college-success'),
  state: {skipScrolling: true},
});

const COLLEGE_SUCCESS_ITEM_TYPE_TO_LINK = {
  FafsaSubmissionItem: () => `${window.location.pathname}/college-success/fafsa/form`,
  TimeSensitiveItem: (_id) =>
    `${window.location.pathname}/college-success/time-sensitive/${_id}/form`,
  PerfectAttendance: () => `${window.location.pathname}/college-success/perfect-attendance/form`,
  CollegeInteraction: (_id) =>
    `${window.location.pathname}/college-success/college-event/${_id}/form`,
  Survey: (_id) => `/survey/${_id}`,
};

export const linkForCollegeSuccessItem = ({type, _id, itemId = ''}) => {
  const itemTypeToLink = COLLEGE_SUCCESS_ITEM_TYPE_TO_LINK[type];
  return itemTypeToLink && itemTypeToLink(_id) + (itemId ? `/${itemId}` : '');
};

export const itemFromScholarship = (name) => name && name.match(/(\w+)Scholarship/)[1];

export const startedScholarships = (scholarships) => {
  const now = new Date();
  const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  return filter(scholarships, (item) => {
    if ((!item.startMonth && item.startMonth !== 0) || !item.startDay || !item.startYear) {
      return true; // don't filter if start date hasn't been set
    }

    const startDate = new Date(item.startYear, item.startMonth, item.startDay);

    return startDate <= currentDate;
  });
};
