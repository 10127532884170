import React, {useEffect, useState} from 'react';
import {forEach, size} from 'lodash';
import classnames from 'classnames';
import CollegeStatusCard from 'shared/college-profile/components/right-rail/college-status-card';
import FixedSimilarCollegesCard from 'shared/college-profile/components/right-rail/fixed-similar-colleges-card';
import CreditHoursCard from 'shared/college-profile/components/right-rail/credit-hours-card';
import EarningDeadlinesCard from 'shared/college-profile/components/right-rail/earning-deadlines-card';
import CampusesCard from 'shared/college-profile/components/right-rail/campuses-card';
import MobileCollegeStatusCard from 'shared/college-profile/components/right-rail/mobile-college-status-card/mobile-college-status-card';
import usePlatformContext from 'src/hooks/usePlatform';

const RECOMMENDATION_STATUSES = [
  'churned',
  'scholarships_not_yet_available',
  'limited_scholarships',
];

interface CollegeProfile {
  college: {
    _id: string;
    isPartner: boolean;
    partnerMinCredits: number;
    otherCampusListingsIds: string[];
    campusesGroupName: string;
    otherCampuses: any[];
    earningRelationship: {
      statusType: string;
      minGPA: number;
    };
    maxEarnings: number;
    applicationWebsite: string;
    financialAidWebsite: string;
    admissionsWebsite: string;
    website: string;
    partnerUpcomingSubmissionPeriods?: any;
    partnerSubmissionDeadlineTerm?: any;
    name: string;
    logo: string;
    bannerHero: string;
    city: string;
    state: string;
    amount: number;
    isFollowing: boolean;
    partnerApplicationDeadlineDay: string;
    partnerApplicationDeadlineMonth: string;
    partnerApplicationDeadlineYear: string;
    partnerSubmissionDeadlineDay: string;
    partnerSubmissionDeadlineMonth: string;
    divisor: number;
    followable: boolean;
  };
  similarPartnerColleges: any[];
  userInfo: {
    user: string;
    academicInterestIds: string[];
    highSchoolInfo: {
      senior: boolean;
    };
    totalCredits: number;
  };
  tab: number;
  loading: boolean;
  maxEarnings: number;
}

interface Props {
  collegeProfile: CollegeProfile;
  followCollege: () => void;
  unfollowCollege: () => void;
  followSimilarCollege: () => void;
  unfollowSimilarCollege: () => void;
  followOtherCampus: () => void;
  unfollowOtherCampus: () => void;
  getSimilarPartnerColleges: (id: string, eventId?: string) => void;
  getOtherCampus: (campusId: string) => void;
  hideSecondaryCards?: boolean;
  recommendationsShownEventId?: string;
  openCollegeEarningDeadlineModal?: () => void;
  openSubmitEarlyModal: () => void;
}

const RightRail = ({
  collegeProfile,
  followCollege,
  unfollowCollege,
  followSimilarCollege,
  unfollowSimilarCollege,
  followOtherCampus,
  unfollowOtherCampus,
  getSimilarPartnerColleges,
  getOtherCampus,
  hideSecondaryCards,
  recommendationsShownEventId,
  openCollegeEarningDeadlineModal,
  openSubmitEarlyModal,
}: Props) => {
  const [showRecommendations, setShowRecommendations] = useState(false);

  useEffect(() => {
    const {
      college: {_id, earningRelationship, otherCampusListingsIds, otherCampuses},
    } = collegeProfile;

    const shouldShowRecommendations = RECOMMENDATION_STATUSES.includes(
      earningRelationship.statusType
    );
    setShowRecommendations(shouldShowRecommendations);

    if (shouldShowRecommendations) {
      getSimilarPartnerColleges(_id, recommendationsShownEventId);
    }

    if (otherCampusListingsIds && otherCampusListingsIds.length !== size(otherCampuses)) {
      forEach(otherCampusListingsIds, (campusId) => {
        getOtherCampus(campusId);
      });
    }
  }, [collegeProfile, getSimilarPartnerColleges, getOtherCampus, recommendationsShownEventId]);

  const {
    college: {
      isPartner,
      partnerMinCredits,
      otherCampusListingsIds,
      campusesGroupName,
      otherCampuses,
      earningRelationship,
      maxEarnings,
    },
    similarPartnerColleges,
    userInfo: {totalCredits},
  } = collegeProfile;

  const roundedTotalCredits = Math.floor(totalCredits);
  const missingCredits = Math.max(partnerMinCredits - roundedTotalCredits, 0);
  const {isIonic} = usePlatformContext();

  const hasSubmissionPeriods = [
    'partnerUpcomingSubmissionPeriods',
    'partnerSubmissionDeadlineTerm',
  ].reduce(
    (p, c) =>
      p &&
      c in collegeProfile.college &&
      collegeProfile.college[c] !== undefined &&
      collegeProfile.college[c] !== null,
    true
  );

  return (
    <aside className="college-profile-right-rail" aria-labelledby="college-profile-college-name">
      <CollegeStatusCard
        collegeProfile={collegeProfile}
        followCollege={followCollege}
        unfollowCollege={unfollowCollege}
        missingCredits={missingCredits}
        openSubmitEarlyModal={openSubmitEarlyModal}
        maxEarnings={maxEarnings}
      />
      <div className={classnames({'hide-cards': hideSecondaryCards})}>
        {isIonic && <MobileCollegeStatusCard college={collegeProfile.college} />}
        {showRecommendations && (
          <FixedSimilarCollegesCard
            similarPartnerColleges={similarPartnerColleges}
            followCollege={followSimilarCollege}
            unfollowCollege={unfollowSimilarCollege}
            collegeProfile={collegeProfile}
          />
        )}
        {isPartner && (
          <CreditHoursCard
            collegeProfile={collegeProfile}
            totalCredits={roundedTotalCredits}
            requiredCredits={partnerMinCredits}
          />
        )}
        {hasSubmissionPeriods && earningRelationship.statusType !== 'deadline_passed' && (
          <EarningDeadlinesCard
            collegeProfile={collegeProfile}
            openCollegeEarningDeadlineModal={openCollegeEarningDeadlineModal}
          />
        )}
        {otherCampusListingsIds && (
          <CampusesCard
            otherCampuses={otherCampuses}
            campusesGroupName={campusesGroupName}
            followCollege={followOtherCampus}
            unfollowCollege={unfollowOtherCampus}
          />
        )}
      </div>
    </aside>
  );
};

export default RightRail;
