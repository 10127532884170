import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'shared/components/icon';
import './remove-button.scss';

class RemoveButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
  };

  render() {
    const {onRemove, className} = this.props;

    return (
      <button
        className={classNames('shared-remove-button', className)}
        data-testid="shared-remove-button"
      >
        <Icon
          onClick={onRemove}
          iconType="times-r"
          className="shared-remove-button--icon"
          testid="remove-button"
        />
      </button>
    );
  }
}

export default RemoveButton;
