import React, {ReactNode} from 'react';
import 'stylesheets/components/shared-fade.scss';

interface FadeProps {
  children: ReactNode;
  height: number;
}
const Fade = ({children, height}: FadeProps): React.ReactElement => (
  <div className="shared-fade" style={{height: `${height}px`}}>
    <div style={{marginTop: `-${height}px`}}>{children}</div>
  </div>
);

export default Fade;
