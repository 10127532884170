import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Notifications from 'shared/components/notifications';
import Sticky from 'src/components/Sticky';
import {AppLayoutContext} from 'src/components/app-layout';
import RoutedTabs from 'src/components/routed-tabs';
import 'stylesheets/components/college-search/college-search-bar.scss';
import CollegeSearch from './public-college-search';
import 'stylesheets/components/college-search/college-search-tab.scss';
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import usePlatformContext from '../../../hooks/usePlatform';

const RECOMMENDATIONS_TAB = {
  label: 'RECOMMENDATIONS',
  path: '/recommendations/sign-up-first',
};

const SEARCH_COLLEGES_TAB = {
  label: 'SEARCH COLLEGES',
  path: '/search',
};

const MY_LIST_TAB = {
  label: 'MY LIST',
  path: '/my-list/sign-up-first',
};

const PublicTabbedCollegeSearch = () => {
  const {topToolbarHeight} = useContext(AppLayoutContext);
  const mq = useMediaQuery();
  const {isIonic} = usePlatformContext();

  const mobileTabs = [RECOMMENDATIONS_TAB, SEARCH_COLLEGES_TAB];
  const desktopTabs = [...mobileTabs, MY_LIST_TAB];

  return (
    <div className="college-search-tab">
      <Notifications />
      <Sticky top={isIonic ? 0 : topToolbarHeight} innerZ={3}>
        <RoutedTabs
          replaceOnNavigation={false}
          tabs={mq.MS_AND_DOWN ? mobileTabs : desktopTabs}
          className={isIonic && mq.MS_AND_DOWN ? 'college-search-mobile --invert' : ''}
        />
      </Sticky>
      <Switch>
        <Route
          path={['/colleges/my-list', '/colleges/search', '/colleges/recommendations']}
          render={() => <CollegeSearch transfer={false} />}
        />
        <Redirect exact from="/colleges" to="/colleges/search" />
      </Switch>
    </div>
  );
};

export default PublicTabbedCollegeSearch;
