import {MQ, watchMedia} from 'src/utils/mq';
import {useEffect, useState} from 'react';

export function useMediaQuery(): MQ {
  const [mq, setMQ] = useState<MQ>({} as MQ);
  useEffect(() => {
    const {remove} = watchMedia(setMQ, true);
    return remove;
  }, []);

  return mq || {};
}
