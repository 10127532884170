import gapi from 'gapi';
import $ from 'jquery';
import vars from 'src/utils/vars';
import {GoogleAuth} from '@codetrix-studio/capacitor-google-auth';

const GoogleOAuth = {};
const clientId = vars.googleClientId || false;

// Authenticates a user with Google's API if the user has not already done so.
// If a user has already authorized RaiseMe to use Google's API, the user won't
// won't see Google's OAuth window.
GoogleOAuth.connect = (scope, discoveryDocs) => {
  const promise = $.Deferred();

  GoogleOAuth.signInAlreadyAuthorizedUser(scope, (signInResponse) => {
    if (signInResponse.access_token) {
      promise.resolve(signInResponse);
    } else {
      GoogleOAuth.authorize(scope, discoveryDocs).then(
        (authorizeResponse) => {
          promise.resolve(authorizeResponse);
        },
        (err) => {
          promise.reject(err);
        }
      );
    }
  });

  return promise;
};

GoogleOAuth.init = (scope, discoveryDocs = [], cb = () => {}) => {
  GoogleAuth.initialize({
    clientId: clientId,
    forceCodeForRefreshToken: true,
    scopes: scope,
    discoveryDocs,
  });

  cb();
};

// Accepts a scope and discoveryDocs arguments and prompts user to connect gmail account
// and authorize the requested permissions (scope)
//
// scope can be a string or an array of Google API scopes
// discoveryDocs is an array of Google API discovery docs urls, it is optional
GoogleOAuth.authorize = async () => {
  try {
    return await GoogleAuth.signIn();
  } catch (errors) {
    throw errors;
  }
};

// Accepts a scope and a callback to call with the results of authorizing the user
// Will authorize the user if they are logged into a gmail account that they have previously
// authorized all of the scope permissions with, otherwise the result will not have an access_token
//
// scope can be a string or an array of Google API scopes
// cb is a callback function that will be passed the results of authorizing with GoogleOAuth
GoogleOAuth.signInAlreadyAuthorizedUser = (scope, cb = () => {}) => {
  if (Array.isArray(scope)) {
    scope = scope.join(' ');
  }

  gapi.load('client:auth2', () => {
    gapi.auth2.authorize(
      {
        client_id: clientId,
        scope,
        prompt: 'none',
      },
      (result) => {
        cb(result);
      }
    );
  });
};

GoogleOAuth.userConnected = () => {
  const promise = $.Deferred();

  gapi.load(
    'client:auth2',
    () => {
      gapi.auth2.init({clientId});
      promise.resolve(gapi.auth2.getAuthInstance().isSignedIn.get());
    },
    () => {
      promise.reject();
    }
  );

  return promise;
};

export default GoogleOAuth;
