import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {setNavigationAnnouncement} from 'shared/actions/accessibility-announcement';
import Label from 'shared/components/label';
import Dropdown, {DropdownOption} from 'src/components/dropdown';
import RadioButton from 'src/components/radio-button';
import CityService, {CityRequest} from 'src/services/city-service';
import CountryService, {Country} from 'src/services/country-service';
import {EligibleSchool, eligibleSchoolByCountryAndCity} from 'src/services/school-service';

const formattedCountryOptions = (countries: Country[]): DropdownOption[] =>
  countries.map(({name, code}) => ({
    _id: name,
    value: code,
    text: name,
  }));

const formatCityOptions = (cityRequest: CityRequest): DropdownOption[] => {
  if (!cityRequest.cities) return [];

  return cityRequest.cities.map((cityName) => ({
    _id: cityName,
    value: cityName,
    text: cityName,
  }));
};

const formatSchoolOptions = (schools: EligibleSchool[]): DropdownOption[] =>
  schools.map(({id, schoolName}) => ({
    _id: id,
    value: schoolName,
    text: schoolName,
  }));

const capitalizeCountryCode = (countryCode: string): string => {
  new URLSearchParams(window.location.search).set('country', countryCode.toUpperCase());
  return countryCode.toUpperCase();
};

const Eligibility = () => {
  const [countryOptions, setCountryOptions] = useState<DropdownOption[]>([]);
  const [cityOptions, setCityOptions] = useState<DropdownOption[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<DropdownOption[]>([]);
  const [country, setCountry] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [school, setSchool] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCountries = async () => {
      const countries = await CountryService.index();
      setCountryOptions(formattedCountryOptions(countries));
    };

    const setCountryFromUrl = async () => {
      let countryUrlParam = new URLSearchParams(window.location.search).get('country');
      if (countryUrlParam) {
        countryUrlParam = capitalizeCountryCode(countryUrlParam);
        setCountry(countryUrlParam);
        setCityOptions(formatCityOptions(await CityService.index({country: countryUrlParam})));
      }
    };

    fetchCountries();
    setCountryFromUrl();

    dispatch(setNavigationAnnouncement('Check Eligibility'));
  }, [dispatch]);

  useEffect(() => {
    const fetchCities = async () => {
      setCityOptions(formatCityOptions(await CityService.index({country})));
    };

    fetchCities();
    setCity('');
  }, [country]);

  useEffect(() => {
    const fetchSchools = async () => {
      setSchoolOptions(formatSchoolOptions(await eligibleSchoolByCountryAndCity(country, city)));
    };

    fetchSchools();
    setSchool('');
  }, [country, city]);

  return (
    <div className="auth-eligibility">
      <h1 className="auth-eligibility-header">Check High School Eligibility</h1>
      <p className="auth-eligibility-text">
        Select a country and city to view
        <br /> eligible schools.
      </p>
      <legend className="shared-required-legend"></legend>
      <Label required>
        Eligible Countries
        <Dropdown
          testid="eligibility-country-select"
          options={countryOptions}
          onChange={(e) => setCountry(e.target.value)}
          value={country}
        />
      </Label>
      <Label required>
        Eligible Cities
        <Dropdown
          testid="eligibility-city-select"
          options={cityOptions}
          onChange={(e) => setCity(e.target.value)}
          disabled={!country || country === 'US'}
          value={city}
        />
      </Label>
      {city && !(country === 'US') && (
        <ul className="auth-school-list">
          {schoolOptions.map(({value, _id}, i) => (
            <li key={i} className="auth-school-list-item">
              <RadioButton
                name="eligible-school"
                label={value}
                onChange={() => setSchool(String(_id))}
                checked={school === _id}
              />
            </li>
          ))}
        </ul>
      )}
      {(country === 'US' || school) && (
        <Link to="/signup" className="auth-eligibility-eligible-button">
          You&#39;re Eligible! Continue
        </Link>
      )}
      <span>
        <a
          className="auth-shared-link center"
          href="https://help.raise.me/hc/en-us/articles/360003494493"
        >
          Can&#39;t find your high school?
        </a>
        <Link to="/signup" className="auth-shared-link center">
          Not in high school?
        </Link>
      </span>
    </div>
  );
};

export default Eligibility;
