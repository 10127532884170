import PropTypes from 'prop-types';
import React from 'react';
import Text from 'shared/components/text';
import Icon from 'shared/components/icon';

class BoostGpaText extends React.Component {
  static propTypes = {
    relationship: PropTypes.shape({
      minGPA: PropTypes.number,
    }).isRequired,
  };

  render() {
    const {
      relationship: {minGPA},
    } = this.props;

    return (
      <div className="shared-college-card-status-text-gpa">
        <Icon className="shared-college-card-status-text-warning-flag" iconType="flag" />
        <Text weight={6} inline>
          Boost GPA to {minGPA} to earn
        </Text>
      </div>
    );
  }
}

export default BoostGpaText;
