import {ComponentType, createContext} from 'react';
import {AppPlatform} from 'src/services/PlatformService';

export interface PlatformContextType {
  isBasic: boolean;
  isIonic: boolean;
  platform: AppPlatform;
  /**
   * Map of components that are dependant on the application platform
   */
  components: PlatformComponentContext;
}

export const PlatformContext = createContext<PlatformContextType>({} as PlatformContextType);
PlatformContext.displayName = 'PlatformContext';

export interface PlatformComponentContext {
  LaunchScreen: ComponentType;
}
