import {map} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {camelizeKeys} from 'humps';
import CriterionFactory from 'src/components/ScholarshipPrograms/CriterionFactory';

class Criteria extends React.Component {
  static propTypes = {
    criteria: PropTypes.array.isRequired,
    onHighSchoolListClick: PropTypes.func,
  };

  render() {
    if (this.props.criteria.length < 1) {
      return null;
    }

    return (
      <div className="eligibility-criteria">
        {map(this.props.criteria, (criterion) => (
          <CriterionFactory
            key={criterion._id}
            criterion={camelizeKeys(criterion)}
            onHighSchoolListClick={this.props.onHighSchoolListClick}
          />
        ))}
      </div>
    );
  }
}

export default Criteria;
