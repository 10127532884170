import {pick} from 'lodash';
import moment from 'moment';
import req from 'shared/req';
import {stopLoading} from 'shared/actions/loading';
import {collegePortalErrorMessage} from 'student-search/error-helpers';

export const DOWNLOAD_REQUESTED = 'DOWNLOAD_REQUESTED';
export const DOWNLOAD_LISTINGS_RETRIEVED = 'DOWNLOAD_LISTINGS_RETRIEVED';
export const PENDING_DOWNLOAD_LISTINGS_RETRIEVED = 'PENDING_DOWNLOAD_LISTINGS_RETRIEVED';
export const DOWNLOAD_REMOVED = 'DOWNLOAD_REMOVED';

export const DOWNLOAD_MODAL_IDENTIFIER = 'downloadModal';
export const LOADING_IDENTIFIER = 'studentReports';
export const DOWNLOAD_STUDENTS_ALL = 'all';
export const DOWNLOAD_STUDENTS_AFTER_DATE = 'follow';

export const reportsUrl = '/rest/college_portal_users/me/reports';

/**
 * Turn a date into a filename for download.
 * Example:
 *   formatFileName(new Date("July 25, 2017 12:34 PM")) === "2017-07-25-1234_RaiseMe"
 */
export const formatFileName = (date, collegeListingSlug) => {
  const listingSlug = collegeListingSlug ? collegeListingSlug : 'all-access';
  return `${moment(date).format('YYYY-MM-DD-HHmm')}_${listingSlug}_RaiseMe`;
};

export const fetchAvailableDownloads = () => (dispatch) =>
  req({
    method: 'get',
    url: reportsUrl,
  }).then((reports) =>
    dispatch({
      type: DOWNLOAD_LISTINGS_RETRIEVED,
      reports: reports.map((report) =>
        pick(report, [
          '_id',
          'filename',
          'records_requested',
          'filesize',
          'created_at',
          'time_received',
          'time_completed',
        ])
      ),
    })
  );

const fetchPendingDownloads = () => (dispatch) =>
  req({
    method: 'get',
    url: `${reportsUrl}?pending=true`,
  }).then((reports) =>
    dispatch({
      type: PENDING_DOWNLOAD_LISTINGS_RETRIEVED,
      reports: reports.map((report) =>
        pick(report, [
          '_id',
          'filename',
          'records_requested',
          'filesize',
          'created_at',
          'time_received',
          'time_completed',
          'failed',
        ])
      ),
    })
  );

export const getAllDownloads = () => (dispatch) => {
  Promise.all([dispatch(fetchAvailableDownloads()), dispatch(fetchPendingDownloads())]).then(() =>
    dispatch(stopLoading(LOADING_IDENTIFIER))
  );
};

export const requestDownload = ({report}) => ({type: DOWNLOAD_REQUESTED, report});

export const removeDownload =
  ({reportId}) =>
  (dispatch) =>
    req({
      method: 'delete',
      url: `${reportsUrl}/${reportId}`,
    })
      .then(() => {
        dispatch({
          type: DOWNLOAD_REMOVED,
          reportId,
        });
      })
      .catch((responseJSON) => {
        if (responseJSON && responseJSON.error === 'not found') {
          dispatch({
            type: DOWNLOAD_REMOVED,
            reportId,
          });
        } else {
          dispatch(collegePortalErrorMessage());
        }
      });
