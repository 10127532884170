import React from 'react';
import PropTypes from 'prop-types';

// stylesheet
import 'stylesheets/components/shared-resource-card.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  linkName: PropTypes.string.isRequired,
};

const Resource = ({title, link, thumbnail, caption, linkName}) => (
  <div className="shared-resource-card">
    <a
      href={link}
      aria-label={`${title} (Opens in new tab)`}
      target="_blank"
      rel="noopener noreferrer"
      data-segment-track="link"
      data-link-name={linkName}
      data-testid="shared-resource-card"
    >
      <img className="shared-resource-card-image" alt={title} src={thumbnail} />
      <div className="shared-resource-card-text-section">
        <p className="shared-resource-card-title">{title}</p>
        <span className="shared-resource-card-caption">{caption}</span>
      </div>
    </a>
  </div>
);

Resource.propTypes = propTypes;

export default Resource;
