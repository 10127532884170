import React, {useContext, useEffect, useRef} from 'react';
import classNames from 'classnames';

import colors from 'shared/aptitude/colors';
import HeaderContent from './header-content';
import {useModalContext} from '.';

import './image-header.scss';
import {useResizeObserver} from '../../../src/hooks/useResizeObserver';
import {PlatformContext} from '../../../src/components/PlatformContext';
import {IonButton, IonButtons, IonHeader, IonToolbar} from '@ionic/react';
import PlatformService from '../../../src/services/PlatformService';
import Icon from 'shared/components/icon';

const HEIGHTS = {
  tall: 220,
  short: 160,
};

interface Props {
  children: React.ReactNode;
  backgroundColor?: keyof typeof colors;
  backgroundImage: React.ElementType;
  color?: 'n900' | 'white';
  onClose: () => void;
  onBack?: () => void;
  textAlign?: 'left' | 'center';
  height?: keyof typeof HEIGHTS;
}

const ImageHeader = ({
  children,
  onBack,
  onClose,
  backgroundColor = 'white',
  backgroundImage,
  color,
  textAlign,
  height = 'tall',
}: Props) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const {height: headerHeight} = useResizeObserver(headerRef);
  const {setHeaderHeight} = useModalContext();
  const platformContext = useContext(PlatformContext);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerHeight);
    }
  }, [setHeaderHeight, headerHeight]);

  const imageStyles = {
    backgroundColor: colors[backgroundColor],
    height: HEIGHTS[height],
  };
  const svgStyles = {
    height: HEIGHTS[height] * 0.8,
  };
  const SVG = backgroundImage;

  return (
    <div id="shared-modal-header" ref={headerRef} className="shared-image-modal-header">
      {platformContext.isIonic && !PlatformService.isMobileWeb() && (
        <IonHeader>
          <IonToolbar
            style={{
              '--ion-toolbar-background': imageStyles.backgroundColor,
              '--ion-toolbar-border-color': imageStyles.backgroundColor,
            }}
          >
            <IonButtons slot="end">
              <IonButton onClick={onClose}>
                <Icon iconType="times-r" style={{color: colors[color]}} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <div className="shared-image-modal-header-image" style={imageStyles}>
        <SVG className="modal-svg" style={svgStyles} />
      </div>
      <HeaderContent onClose={onClose} onBack={onBack} color={color} ignorePlatform />
      <div className={classNames('shared-image-modal-header-title', textAlign)}>{children}</div>
    </div>
  );
};

export default ImageHeader;
