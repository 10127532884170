import React from 'react';
import PropTypes from 'prop-types';
import {map, capitalize} from 'lodash';
import {MONTH_ABBRVS} from 'shared/utils';
import Criteria from './Criteria';

const propTypes = {
  requirements: PropTypes.array.isRequired,
  criteria: PropTypes.array.isRequired,
  deadline: PropTypes.string,
  applicationDeadline: PropTypes.string,
  // Provide if link to show all high schools
  // affected by a criteria should be shown.
  onHighSchoolListClick: PropTypes.func,
  eligiblePhase: PropTypes.string,
  submissionPeriods: PropTypes.shape({
    id: PropTypes.string,
    term: PropTypes.string,
    eligiblePhase: PropTypes.string,
  }),
  minCreditHours: PropTypes.number,
};

const formattedDate = (time) => {
  const date = new Date(time);
  const day = date.getUTCDate();
  const month = date.getUTCMonth();

  return `${MONTH_ABBRVS[month]} ${day}`;
};

const submissionPeriodsList = (submissionPeriods) =>
  submissionPeriods.map(({id, term, applicationDeadline, submissionDeadline}) => (
    <div className="eligibility-criteria" key={id}>
      <p>
        <strong>{capitalize(term)} Term</strong>
      </p>
      <div className="flex-row">
        <span>Application for Admission Due Date: </span>
        <strong>{formattedDate(applicationDeadline)}</strong>
      </div>
      <div className="flex-row">
        <span>RaiseMe Earning Deadline:</span>
        <strong>{formattedDate(submissionDeadline)}</strong>
      </div>
    </div>
  ));

const Requirements = ({
  requirements,
  criteria,
  onHighSchoolListClick,
  deadline,
  applicationDeadline,
  submissionPeriods,
  eligiblePhase,
  minCreditHours,
}) => (
  <div className="requirements">
    <div className="ongoing-requirements">
      {map(requirements, (req, i) => (
        <p key={i} dangerouslySetInnerHTML={{__html: req}}></p>
      ))}
    </div>
    <h4 className="requirements-section-header">To be Eligible to Earn</h4>
    <p className="explaining-text">
      Students who meet these criteria are eligible to earn from this micro-scholarship program:
    </p>

    {eligiblePhase === 'high-school' && !!deadline && (
      <div className="eligibility-criteria">
        <div className="flex-row">
          <span>Deadline to Earn</span>
          <strong>{formattedDate(deadline)}</strong>
        </div>
      </div>
    )}
    {eligiblePhase === 'community-college' && !!minCreditHours && (
      <div className="eligibility-criteria">
        <div className="flex-row">
          <span>Minimum Credit-Hours required to earn</span>
          <strong>{minCreditHours}</strong>
        </div>
      </div>
    )}
    {eligiblePhase === 'high-school' && !!applicationDeadline && (
      <div className="eligibility-criteria">
        <div className="flex-row">
          <span>Deadline to Apply</span>
          <strong>{formattedDate(applicationDeadline)}</strong>
        </div>
      </div>
    )}
    {eligiblePhase === 'community-college' && submissionPeriodsList(submissionPeriods)}
    <Criteria criteria={criteria} onHighSchoolListClick={onHighSchoolListClick} />
  </div>
);

Requirements.propTypes = propTypes;

export default Requirements;
