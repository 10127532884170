import React from 'react';
import PropTypes from 'prop-types';
import PrimaryLogo from 'shared/img/primary-logo-blue.svg';
import AuthImage from 'shared/img/auth.svg';

const propTypes = {
  children: PropTypes.node,
  page: PropTypes.oneOf(['login', 'signup']).isRequired,
};

const Layout = ({children, page = 'login'}) => (
  <div className="shared-auth-layout">
    <section className="shared-auth-layout-left">
      <a href="/" className="shared-auth-layout-logo-link">
        <PrimaryLogo width={118} height={23} />
      </a>
      {children}
    </section>
    <section className={`shared-auth-layout-right ${page}`}>
      <AuthImage />
    </section>
  </div>
);

Layout.propTypes = propTypes;

export default Layout;
