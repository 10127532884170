import {map} from 'lodash';
import {withCommas} from 'shared/utils';

const formatAsCurrency = (number) => `$${withCommas(Math.round(number * 100) / 100)}`;

export const rangeString = (minAmount, maxAmount) => {
  const amounts = [minAmount];

  if (minAmount !== maxAmount) {
    amounts.push(maxAmount);
  }

  return map(amounts, formatAsCurrency).join('-');
};

const categoriesMap = {
  college: {category: 'College readiness', color: 'violet'},
  fafsa: {category: 'College readiness', color: 'violet'},
  course: {category: 'Academics', color: 'blue'},
  extracurricular: {category: 'Extracurriculars', color: 'orange'},
  sport: {category: 'Extracurriculars', color: 'orange'},
  ['honors-and-awards']: {category: 'Honors and Awards', color: 'yellow'},
  multimedia: {category: 'Art and Media', color: 'pink'},
  referral: {category: 'Referral', color: 'green'},
  score: {category: 'Test scores', color: 'cyan'},
  ['work-experience']: {category: 'Work Experience', color: 'brown'},
  ['community']: {category: 'Community service', color: 'purple'},
};

export const getCategoryObject = (category) => {
  let categoryObject = categoriesMap[category];

  // this is for a graceful fail.  We should never end up here
  if (!categoryObject) {
    categoryObject = {category: 'Other', color: 'gray'};
  }

  return categoryObject;
};

export const getEarningText = (school, popularity) =>
  `${popularity} student${popularity === 1 ? '' : 's'} ` +
  `at ${school} ${popularity === 1 ? 'is' : 'are'} ` +
  'earning for this';
