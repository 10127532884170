import req from 'shared/req';
import {track} from 'src/utils/analytics';

export const GET_COLLEGE_APP_STATUS = 'GET_COLLEGE_APP_STATUS';
export const RECEIVE_COLLEGE_APP_STATUS = 'RECEIVE_COLLEGE_APP_STATUS';
export const RECEIVE_COLLEGE_APP_STATUSES = 'RECEIVE_COLLEGE_APP_STATUSES';
export const SET_COLLEGE_APP_STATUS = 'SET_COLLEGE_APP_STATUS';

export function getCollegeAppStatuses() {
  return (dispatch) =>
    req({
      url: '/v1/college_application_statuses',
      method: 'get',
    }).then((receivedCollegeAppStatuses) => {
      const formattedCollegeAppStatuses = receivedCollegeAppStatuses.reduce(
        (formattedApps, collegeApp) => {
          formattedApps[collegeApp.collegeId] = collegeApp;
          return formattedApps;
        },
        {}
      );

      dispatch(receiveCollegeAppStatuses(formattedCollegeAppStatuses));
      return receiveCollegeAppStatuses(formattedCollegeAppStatuses);
    });
}

export function getCollegeAppStatus(collegeId) {
  return (dispatch) =>
    req({
      url: `/v1/college_application_statuses/${collegeId}`,
      method: 'get',
    }).then((receivedCollegeAppStatus) => {
      dispatch(receiveCollegeAppStatus(receivedCollegeAppStatus || {}));
      return receiveCollegeAppStatus(receivedCollegeAppStatus || {});
    });
}

export function receiveCollegeAppStatuses(collegeApps) {
  return {
    type: RECEIVE_COLLEGE_APP_STATUSES,
    payload: collegeApps,
  };
}

export function receiveCollegeAppStatus(collegeApp) {
  return {
    type: RECEIVE_COLLEGE_APP_STATUS,
    payload: collegeApp,
  };
}

export function setCollegeAppStatus(collegeId, appStatus, segmentEventLocation = '') {
  return (dispatch) => {
    track('ApplicationStatusUpdateEvent', {collegeId, appStatus, segmentEventLocation});

    return req({
      url: `/v1/college_application_statuses/${collegeId}`,
      method: 'PATCH',
      data: {
        college_application_status: {
          status: appStatus,
        },
      },
    }).then((receivedCollegeAppStatus) => {
      dispatch(receiveCollegeAppStatus(receivedCollegeAppStatus));
      return receiveCollegeAppStatus(receivedCollegeAppStatus);
    });
  };
}
