import React, {ReactElement, Suspense as ReactSuspense, SuspenseProps} from 'react';
import {Box} from '@mui/material';
import {LoadingIcon} from 'shared/components/loading';
import {PartialBy} from 'src/types/util';

export function Loader(): ReactElement {
  return (
    <Box position="absolute" top="30%" left="50%" fontSize="36px">
      <LoadingIcon />
    </Box>
  );
}

export default function Suspense(props: PartialBy<SuspenseProps, 'fallback'>): ReactElement {
  return <ReactSuspense fallback={props.fallback || <Loader />}>{props.children}</ReactSuspense>;
}
