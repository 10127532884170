import {FormOption} from 'src/types/form';
import AssetService, {CountryOption} from 'src/services/AssetService';
import {ReactElement} from 'react';
import {ObjectOf} from 'src/types/store';
import {FormikProps} from 'formik';

export function includesOption(options: FormOption[], value: string): boolean {
  return options.some((option) => option.value === value);
}

export function getOption<OptionType extends FormOption>(
  options: OptionType[],
  value: string
): OptionType | undefined {
  return options.find((option) => option.value === value);
}

export function shiftOptionToFront<T extends FormOption>(options: T[], value: string): T[] {
  const shifted = [...options];
  const index = shifted.findIndex((option) => option.value === value);
  shifted.splice(0, 0, shifted.splice(index, 1)[0]);
  return shifted;
}

export const formatCoordinates = (lat?: number, long?: number): [number, number] | null =>
  !!lat && !!long ? [lat, long] : null;

/**
 * Helper to get country options with the US at the front
 */
export const getCountryOptions = async (): Promise<CountryOption[]> => {
  const options = (await AssetService.getCountryOptions({includeMeta: true})) as CountryOption[];
  return shiftOptionToFront<CountryOption>(options, 'US');
};

export const isDomestic = (country: string): boolean => country === 'US';

export const isInternational = (country: string): boolean => !isDomestic(country);

export const renderIf = (Component, ...conditions: unknown[]): ReactElement | null =>
  conditions.every(Boolean) ? Component : null;

export interface Dimensions {
  width: number;
  height: number;
}
export const initialDimensions: Dimensions = {width: 0, height: 0};

export function refDimensions(element: HTMLElement | null): Dimensions {
  if (element) {
    return {
      width: element.offsetWidth,
      height: element.offsetHeight,
    };
  }

  return initialDimensions;
}

export function sumRefDimensions(...refs: (HTMLElement | null)[]): Dimensions {
  return refs.reduce(
    (acc, c) => {
      const dimensions = refDimensions(c);
      acc.width += dimensions.width;
      acc.height += dimensions.height;
      return acc;
    },
    {width: 0, height: 0}
  );
}

export function addOrRemoveFromCollection<Type>(
  value: Type,
  collection: Type[],
  shouldAdd: boolean
): Type[] {
  return shouldAdd ? collection.concat(value) : collection.filter((element) => element !== value);
}

export function filterTrueKeys(selection: ObjectOf<boolean>): string[] {
  return Object.keys(selection).filter((key) => Boolean(selection[key]));
}

/**
 * Parses an ISO date string ignoring the timezone
 */
export function parseISOLocal(time): Date {
  const digits = time.split(/\D/).map(Number);
  return new Date(digits[0], digits[1] - 1, digits[2], digits[3], digits[4], digits[5]);
}

export function formikToReactFormsShim<Values>(
  name: keyof Values,
  formikProps: FormikProps<Values>
): any {
  return {
    value: formikProps.values[name],
    name,
    onChange: formikProps.handleChange,
    onBlur: formikProps.handleBlur,
    error: formikProps.errors[name],
    forceError: Boolean(formikProps.errors[name]) && formikProps.touched[name],
  };
}
