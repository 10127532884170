// overlay
import {CLOSE_OVERLAY, OPEN_OVERLAY} from 'shared/actions/overlay';

const defaultState = {
  hidden: false,
};

export function overlayReducer(state = defaultState, action) {
  switch (action.type) {
    case CLOSE_OVERLAY:
      return {
        ...state,
        hidden: true,
      };
    case OPEN_OVERLAY:
      return {
        ...state,
        hidden: false,
      };
    default:
      return state;
  }
}
