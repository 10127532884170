import {some, omit} from 'lodash';
import {
  NEW_EVENT,
  EDIT_EVENT,
  CANCEL_EDIT_EVENT,
  RECEIVE_COLLEGE_EVENT,
  EVENT_DELETED,
  SCROLLED_TO_EVENT,
  RECEIVE_COLLEGE_EVENTS,
} from 'shared/portfolio/actions/college-events';

export function collegeEventsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_COLLEGE_EVENT:
      return {
        ...state,
        [action.id]: {
          ...action.collegeEvent,
        },
      };
    case NEW_EVENT:
      if (some(state, 'new')) {
        return state;
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.collegeEvent,
          },
        };
      }
    case CANCEL_EDIT_EVENT:
      if (action.collegeEvent.new) {
        return omit(state, action.id);
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.collegeEvent,
            editing: false,
            scrollTo: true,
          },
        };
      }
    case EDIT_EVENT:
      return {
        ...state,
        [action.id]: {
          ...action.collegeEvent,
          editing: true,
        },
      };
    case SCROLLED_TO_EVENT:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          scrollTo: false,
        },
      };
    case EVENT_DELETED:
      return omit(state, action.id);
    case RECEIVE_COLLEGE_EVENTS:
      return {
        ...state,
        ...action.collegeEvents,
      };
    default:
      return state;
  }
}
