import React from 'react';
import PropTypes from 'prop-types';
import 'stylesheets/components/shared-separator.scss';

const Separator = ({children}) => (
  <div className="shared-separator">
    <span className="shared-separator-text">{children}</span>
    <hr className="shared-separator-line" aria-hidden="true" />
  </div>
);

// if we have a use case where we won't provide the child string styles
// will need adjusted, so making children a required prop for now
Separator.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Separator;
