import React from 'react';
import classnames from 'classnames';
import TextButton from 'src/components/TextButton';
import Button from 'src/components/Button';

export interface FooterButtonType {
  text: string;
  onClick?: () => void;
  'aria-label'?: string;
  disabled?: boolean;
  type?: string;
  width?: number | string;
  color?: 'blue' | 'light-blue' | 'white' | 'gray' | 'red';
  buttonName?: string;
  cy?: string;
}

interface Props {
  buttonType: 'primary' | 'secondary' | 'tertiary';
  centered?: boolean;
}

const FooterButton = ({
  buttonType,
  type = 'button',
  text,
  color = 'blue',
  centered,
  ...props
}: Props & FooterButtonType) =>
  buttonType === 'tertiary' ? (
    <TextButton
      className={classnames('shared-modal-footer-button', buttonType)}
      color={color}
      type={type}
      margin={false}
      testid={`shared-modal-footer-button-${buttonType}`}
      {...props}
    >
      {text}
    </TextButton>
  ) : (
    <Button
      className={classnames('shared-modal-footer-button', buttonType, {centered})}
      color={color}
      outline={buttonType === 'secondary'}
      type={type}
      margin={false}
      testId={`shared-modal-footer-button-${buttonType}`}
      {...props}
    >
      {text}
    </Button>
  );

export default FooterButton;
