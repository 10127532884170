import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import ProgressBar from 'shared/components/progress-bar';
import Card from 'src/components/Cards/Card';

class ProgressCard extends React.Component {
  static propTypes = {
    // Text or node that will title the card
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    // Optional text or node beneath the title
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    // completed and required are used for the
    // progress bar values (e.g. 8/10 courses)
    completed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    required: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // The text or node that appears underneath
    // the progress bar
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    // An object of attributes and text you can apply
    // to <a>. In attributes, href and aria-label are
    // required while onClick is optional
    ctaLink: PropTypes.shape({
      attributes: PropTypes.shape({
        href: PropTypes.string.isRequired,
        'aria-label': PropTypes.string.isRequired,
        onClick: PropTypes.func,
        to: PropTypes.string,
      }).isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    }).isRequired,
  };

  renderButton = (className) => {
    const {attributes, text} = this.props.ctaLink;

    if (attributes.to) {
      return (
        <Link {...attributes} className={className}>
          {text}
        </Link>
      );
    }

    return (
      <a {...attributes} className={className}>
        {text}
      </a>
    );
  };

  render() {
    const {title, subtitle, completed, required, text} = this.props;
    const current = parseInt(completed);
    const total = parseInt(required);
    const fillAmount = (Math.min(current / total, 1) * 100).toFixed(0) + '%';

    return (
      <Card>
        <div className="college-profile-progress-card">
          <header className="college-profile-progress-card-header">
            {title}
            {this.renderButton('college-profile-progress-card-add-button-desktop')}
          </header>
          {subtitle && <span className="college-profile-progress-card-subheader">{subtitle}</span>}
          <div className="college-profile-progress-card-credit-progress">
            <p className="college-profile-progress-card-credits-taken">
              {current} / {total}
            </p>
            <div className="college-profile-progress-card-progress-bar">
              <ProgressBar color="blue" fillAmount={fillAmount} height={10} />
            </div>
          </div>
          {text}
          {this.renderButton('college-profile-progress-card-add-button-mobile')}
        </div>
      </Card>
    );
  }
}

export default ProgressCard;
