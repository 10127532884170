import React, {useState} from 'react';
import EmptyHeader from 'shared/components/empty-header';
import {FormikErrors} from 'formik';
import {Box} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {validateEmail, validatePhone, isMobile} from 'src/utils/validations';
import ResetPasswordService from 'src/services/ResetPasswordService';
import ResetPasswordBaseForm from 'src/components/ResetPasswordBaseForm';
import PublicFooter from 'src/components/PublicFooter/PublicFooter';
import placeholders from 'src/static/placeholders.json';
import usePlatformContext from 'src/hooks/usePlatform';

interface FormValues {
  emailOrMobile: string;
}

const validate = (values: FormValues): object => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.emailOrMobile) {
    errors.emailOrMobile = 'Email or phone is required.';
  } else if (!(validatePhone(values.emailOrMobile).valid || validateEmail(values.emailOrMobile))) {
    errors.emailOrMobile = 'Enter valid email or phone number format.';
  }

  return errors;
};

const ForgotPassword = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>();
  const history = useHistory();
  const {isBasic} = usePlatformContext();

  const handleChange = (value, setValue): void => {
    if (isMobile(value)) {
      setValue(validatePhone(value).formatted);
    } else {
      setValue(value);
    }
  };

  const handleSubmit = async (values, actions): Promise<void> => {
    actions.setSubmitting(true);
    setSuccessMessage('');
    await ResetPasswordService.forgotPassword(values.emailOrMobile)
      .then((res) => {
        res.redirect ? history.push('/mobile-verification-code') : setSuccessMessage(res.message);
      })
      .catch((res) => actions.setFieldError('emailOrMobile', res.error));
    actions.setSubmitting(false);
  };

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" alignItems="center">
      {isBasic && <EmptyHeader signOutButton={false} signInButton />}
      <Box mt={20} display="flex" justifyContent="center">
        <div className="shared-font display-1">Forgot password?</div>
      </Box>
      <Box display="flex" flexDirection="column" width={300} flexGrow={1}>
        <ResetPasswordBaseForm
          fieldName="emailOrMobile"
          handleSubmit={handleSubmit}
          validate={validate}
          handleChange={handleChange}
          successMessage={successMessage}
          placeholder={placeholders.exampleEmail}
          label="Email or Phone Number"
        />
      </Box>
      <PublicFooter />
    </Box>
  );
};

export default ForgotPassword;
