import PropTypes from 'prop-types';
import React from 'react';
import Card from 'src/components/Cards/Card';

class CollegeStatusCard extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
  };

  render() {
    const {
      college: {logo, name},
    } = this.props;

    return (
      <section className="college-profile-loading-card college-profile-loading-body">
        <Card>
          <header className="college-profile-loading-card-header">
            <img
              src={logo}
              aria-hidden="true"
              alt=""
              className="college-profile-loading-card-logo"
            />
            <div className="college-profile-loading-card-college-info">
              <p id="college-profile-college-name" className="college-profile-loading-card-name">
                {name}
              </p>
              <div className="college-profile-loading-body-text shimmer" />
            </div>
          </header>
          <div className="college-profile-loading-body-box shimmer" />
          <div className="college-profile-loading-body-text shimmer" />
        </Card>
        <Card>
          <div className="college-profile-loading-body-title shimmer" />
          <div className="college-profile-loading-body-text shimmer" />
          <div className="college-profile-loading-body-text shimmer" />
        </Card>
      </section>
    );
  }
}

export default CollegeStatusCard;
