import {uniqueId} from 'lodash';
import {errorMessage} from 'shared/actions/notifications';
import {queryParamsToString} from 'shared/utils/url-helpers';
import PresignedUploadURLService from 'src/services/PresignedUploadURLService';
import JWTService from 'src/services/JWTService';
import vars from 'src/utils/vars';

export const FINISH_UPLOADING = 'MINERVA/FINISH_UPLOADING';
export const START_UPLOADING = 'MINERVA/START_UPLOADING';

export const uploadImage =
  ([file]) =>
  (dispatch, getState) =>
    upload(file, '/v1/upload-image')(dispatch, getState).then((result) => result.publicUrl);

const uploadGroundTruthFile =
  (type, file, options = {}) =>
  async (dispatch) => {
    const {presignedURL} = await PresignedUploadURLService.index({
      ...options,
      type,
      filename: file.name,
    });

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      dispatch({type: START_UPLOADING});

      xhr.open('PUT', presignedURL, true);
      xhr.onload = () => {
        const {responseText} = xhr;

        if (xhr.status === 200) {
          resolve();
        } else {
          dispatch(errorMessage(responseText));
          reject(responseText);
        }

        dispatch({type: FINISH_UPLOADING});
      };

      xhr.send(file);
    });
  };

export const uploadFile =
  (file, type = 'default', options = {}) =>
  (dispatch, getState) => {
    const queryString = queryParamsToString(options);

    if (type === 'ground_truth') {
      return uploadGroundTruthFile(type, file, options)(dispatch);
    } else if (type === 'spot_award') {
      return upload(file, '/college-portal/award-spot-scholarships')(dispatch, getState);
    } else {
      return upload(
        file,
        `/v1/upload-file?type=${type}${queryString.length ? '&' : ''}${queryString}`
      )(dispatch, getState);
    }
  };

const upload = (file, path) => (dispatch) => {
  if (!file) {
    dispatch(errorMessage('File cannot be empty.'));
    return Promise.reject();
  }

  return JWTService.token().then(
    (token) =>
      new Promise((resolve, reject) => {
        const url = `${vars.API_GATEWAY}${path}`;
        const xhr = new XMLHttpRequest();
        const form = new FormData();

        dispatch({type: START_UPLOADING});

        xhr.open('POST', url, true);
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        xhr.onload = () => {
          const {responseText} = xhr;

          if (xhr.status === 200) {
            resolve(JSON.parse(responseText));
          } else {
            dispatch(errorMessage(responseText));
            reject(responseText);
          }

          dispatch({type: FINISH_UPLOADING});
        };

        form.append('file', file, file.name || `${uniqueId('image_')}.png`);
        xhr.send(form);
      })
  );
};
