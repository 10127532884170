import {some, omit} from 'lodash';
import {
  NEW_WORK_EXPERIENCE,
  EDIT_WORK_EXPERIENCE,
  CANCEL_EDIT_WORK_EXPERIENCE,
  DELETE_WORK_EXPERIENCE,
  RECEIVE_WORK_EXPERIENCE,
  SCROLLED_TO_EXPERIENCE,
  RECEIVE_WORK_EXPERIENCES,
} from 'shared/portfolio/actions/work-experiences';

export function experiencesReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_WORK_EXPERIENCE:
      return {
        ...state,
        [action.id]: {
          ...action.workExperience,
        },
      };
    case NEW_WORK_EXPERIENCE:
      if (some(state, 'new')) {
        return state;
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.workExperience,
          },
        };
      }
    case EDIT_WORK_EXPERIENCE:
      return {
        ...state,
        [action.id]: {
          ...action.workExperience,
          editing: true,
        },
      };
    case SCROLLED_TO_EXPERIENCE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          scrollTo: false,
        },
      };
    case CANCEL_EDIT_WORK_EXPERIENCE:
      if (action.workExperience.new) {
        return omit(state, action.id);
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.workExperience,
            editing: false,
            scrollTo: true,
          },
        };
      }
    case DELETE_WORK_EXPERIENCE:
      return omit(state, action.id);
    case RECEIVE_WORK_EXPERIENCES:
      return {
        ...state,
        ...action.workExperiences,
      };
    default:
      return state;
  }
}
