import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'shared/img/work-experience.svg';

class WorkExperienceIcon extends React.Component {
  static propTypes = {
    size: PropTypes.number,
    className: PropTypes.string,
  };

  static defaultProps = {
    size: 50,
  };

  render() {
    const {size, className} = this.props;
    return <Icon className={className} width={size} height={size} />;
  }
}

export default WorkExperienceIcon;
