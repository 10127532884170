import req from 'shared/req';

export interface EligibleSchool {
  id: string;
  address: string;
  city: string;
  country: string;
  countryCode: string;
  postalCode: string;
  schoolName: string;
  subdivision: string;
  subdivisionCode: number;
  totalEarnings: number;
  totalRaiseEducators?: number;
  totalRaiseStudents?: number;
  totalStudents?: number;
}

// TODO: Tech Debt - Flesh this service out as a CRUD service, extend with /v1/schools-by-country call,
// and use wherever reqs to /v1/schools or /v1/schools-by-country-and-city are used
export const eligibleSchoolByCountryAndCity = async (
  countryCode: string,
  city: string
): Promise<EligibleSchool[]> =>
  req({
    url: '/v1/schools-by-country-and-city',
    method: 'get',
    data: {
      country: countryCode,
      city,
    },
  });
