/*
 * Action types.
 */

export const SET_FILTER = 'SET_FILTER';
export const EXPAND_FILTER = 'EXPAND_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const DISMISS_TAG = 'DISMISS_TAG';
export const DISMISS_FILTER = 'DISMISS_FILTER';
export const INITIALIZE_FILTERS = 'INITIALIZE_FILTERS';

/*
 * Action creators.
 */

export const setFilter = ({name, value}) => ({type: SET_FILTER, name, value});

export const expandFilter = ({name, value}) => ({type: EXPAND_FILTER, name, value});

export const clearFilter = ({name}) => ({type: CLEAR_FILTER, name});

export const clearFilters = () => ({type: CLEAR_FILTERS});

export const dismissTag = ({name, valueId}) => ({type: DISMISS_TAG, name, valueId});

export const dismissFilter = ({name, value}) => ({type: DISMISS_FILTER, name, value});

export const initializeFilters = (initialFilters) => ({type: INITIALIZE_FILTERS, initialFilters});
