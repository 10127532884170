import React, {useState, ReactElement, createContext, ReactNode} from 'react';
import PropTypes from 'prop-types';

interface SkipToContentContextType {
  mainContentId: string;
  setMainContentId: (id: string) => void;
}

interface Props {
  children: ReactNode;
}

export const SkipToContentContext = createContext<SkipToContentContextType>(
  {} as SkipToContentContextType
);
SkipToContentContext.displayName = 'SkipToContentContext';

export function SkipToContentProvider(props: Props): ReactElement {
  const [mainContentId, setMainContentId] = useState<string>('main');

  return (
    <SkipToContentContext.Provider value={{mainContentId, setMainContentId}}>
      {props.children}
    </SkipToContentContext.Provider>
  );
}

SkipToContentProvider.propTypes = {
  children: PropTypes.node,
};
