import {find, without} from 'lodash';

// notifications
import {ADD_NOTIFICATION, REMOVE_NOTIFICATION} from 'shared/actions/notifications';

// We use the message as the key to make it easy to remove, and to make sure we don't display
// duplicate messages at the same time.
export const notificationsReducer = (state = [], action) => {
  const newState = [...state];
  let message;

  switch (action.type) {
    case ADD_NOTIFICATION:
      newState.push(action.message);
      break;
    case REMOVE_NOTIFICATION:
      message = find(newState, {id: action.id});
      return without(newState, message);
  }

  return newState;
};
