import React from 'react';
import SkipToContent, {SkipToContentProvider} from '../SkipToContent';
import {ImpostorModeProvider} from 'src/components/ImpostorModeContext';
import {AnyModalConfig} from 'src/components/ModalContext/types';
import {ModalProvider} from 'src/components/ModalContext';
import {RaiseEventListenerProvider} from 'src/components/raise-event-listener-context';

export interface ApplicationRootProps {
  children: React.ReactNode;
  modalConfigs?: AnyModalConfig[];
}

const ApplicationRoot = ({children, modalConfigs = []}: ApplicationRootProps) => (
  <SkipToContentProvider>
    <ImpostorModeProvider>
      <ModalProvider configs={modalConfigs}>
        <RaiseEventListenerProvider>
          <SkipToContent />
          {children}
        </RaiseEventListenerProvider>
      </ModalProvider>
    </ImpostorModeProvider>
  </SkipToContentProvider>
);

export default ApplicationRoot;
