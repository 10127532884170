import PropTypes from 'prop-types';
import React from 'react';
import StatisticsTable from './statistics-table';
import {acceptanceLikelihoodClassification} from 'shared/college-profile/utils';
import AcceptanceLikelihoodExplanation from './acceptance-likelihood/acceptance-likelihood-explanation';
import Icon from 'shared/components/icon';

class ACTTable extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    showAdmissability: PropTypes.bool,
  };

  addDefaultScores(values) {
    values.push(<a href="/portfolio#scores">Enter Score to See Match</a>);
    values.push(<Icon iconType="lock-alt" className="admissability-locked-icon" />);
  }

  getRows() {
    const {
      college: {actEnglish, actMath, actWriting},
      showAdmissability,
      userInfo: {actScore},
    } = this.props;
    const rows = [];

    if (actEnglish) {
      const values = [actEnglish.join('-')];

      if (showAdmissability) {
        if (actScore) {
          values.push(actScore.scoreEnglish);
          values.push(
            acceptanceLikelihoodClassification(actScore.scoreEnglish, actEnglish[0], actEnglish[1])
          );
        } else {
          this.addDefaultScores(values);
        }
      }

      rows.push({
        name: 'ACT English',
        values,
      });
    }

    if (actMath) {
      const values = [actMath.join('-')];

      if (showAdmissability) {
        if (actScore) {
          values.push(actScore.scoreMath);
          values.push(
            acceptanceLikelihoodClassification(actScore.scoreMath, actMath[0], actMath[1])
          );
        } else {
          this.addDefaultScores(values);
        }
      }

      rows.push({
        name: 'ACT Math',
        values,
      });
    }

    if (actWriting) {
      const values = [actWriting.join('-')];

      if (showAdmissability) {
        if (actScore) {
          values.push(actScore.scoreWriting);
          values.push(
            acceptanceLikelihoodClassification(actScore.scoreWriting, actWriting[0], actWriting[1])
          );
        } else {
          this.addDefaultScores(values);
        }
      }

      rows.push({
        name: 'ACT Writing',
        values,
      });
    }

    return rows;
  }

  render() {
    const {
      showAdmissability,
      userInfo: {actScore},
    } = this.props;

    const rows = this.getRows();
    const cols = ['Subject', 'Avg. Range'];

    if (showAdmissability) {
      cols.push('Your Score');

      cols.push(
        <span>
          Match
          <AcceptanceLikelihoodExplanation />
        </span>
      );
    }

    let className = '';
    if (showAdmissability) {
      if (actScore) {
        className = 'wide-row-headers';
      } else {
        className = 'with-score-cta';
      }
    }

    return (
      <StatisticsTable className={className} aria-label="ACT Breakdown" header={cols} rows={rows} />
    );
  }
}

export default ACTTable;
