import classnames from 'classnames';
import ScholarshipBadge from 'src/components/CollegeSearch/ScholarshipBadge';
import PropTypes from 'prop-types';
import React from 'react';
import {
  formatCollegeNameForCard,
  showTermNotOfferedModal,
} from 'shared/components/college-card/utils';
import FollowButton from 'shared/components/follow-button';
import {earning} from 'shared/utils';
import 'stylesheets/components/college-card-transfers.scss';
import ContextualDataText from './contextual-data-text';
import DeadlineText from './deadline-text';
import SortingInfo from './sorting-info';
import StatusText from './status-text';
import {Box} from '@mui/material';
import styles from './college-card.module.scss';
import InfoTooltip from '../info-tooltip';
import {connect} from 'react-redux';
import {successfulLoginRedirectPath} from '../../../src/utils/storage';
import {SessionStorage} from '../../../src/utils/session-storage';
import browserHistory from '../../history';

const CollegeCard = ({
  authenticated = true,
  college,
  followCollege,
  unfollowCollege,
  showDeadline,
  showDeadlineYear,
  showStatus,
  showFollow = true,
  showBadge = true,
  showSortInfo,
  bucketID,
  sortBy,
  condensed,
  shortCard = false,
  simplified,
  communityCollegeInfo,
  openTermNotOfferedModal,
  wideCard,
  userType,
}) => {
  const collegeName = () =>
    formatCollegeNameForCard(college.name, showDeadline, college.earningRelationship);

  const isFollowing = () => college.following || college.isFollowing;

  const handleFollowClick = (e) => {
    e.preventDefault();

    sessionStorage.setItem(SessionStorage.FOLLOW_COLLEGE_ID, college.id);
    successfulLoginRedirectPath.set(`edu/${college.slug}`);

    if (isFollowing()) {
      unfollowCollege(college.id);
    } else {
      userType === 'public'
        ? browserHistory.push('/colleges/search/sign-up-first', {from: window.location.pathname})
        : followCollege(college.id);

      if (openTermNotOfferedModal && showTermNotOfferedModal(college, communityCollegeInfo)) {
        openTermNotOfferedModal(college.id);
      }
    }
  };

  const deadline = showDeadline && college.deadlineMonth !== null && college.deadlineDay !== null;
  const earningsPossible =
    college.earningRelationship && college.earningRelationship.statusType
      ? earning(college.earningRelationship.statusType)
      : college.earningsPossible;
  const containerClassName = classnames('college-card-transfers', {
    condensed,
    'short-card': shortCard,
    [styles.wideCollegeCard]: wideCard,
    simplified,
  });

  const loginText =
    'Create an account or log in to view potential earnings from this college partner.';

  return (
    <div className={containerClassName}>
      {college.bannerUrl ? (
        <img
          src={college.bannerUrl}
          className="college-card-transfers-banner"
          style={{backgroundImage: college.bannerUrl}}
          alt={`${college.name} - Banner Image`}
          aria-hidden="true"
        />
      ) : (
        <div className="college-card-transfers-banner" />
      )}
      <img
        src={college.logoUrl}
        className="college-card-transfers-logo"
        alt=""
        aria-hidden="true"
      />
      <div
        className={classnames('college-card-transfers-content', {
          [styles.bottomContainer]: wideCard,
        })}
      >
        <Box flex="1 1 0">
          {showSortInfo && <SortingInfo college={college} sortBy={sortBy} />}
          <ContextualDataText college={college} bucketID={bucketID} />
          <div className="college-card-transfers-header-container">
            <div
              className={classnames('college-card-transfers-header', {
                'with-deadline': deadline,
              })}
            >
              <p
                className={classnames('college-card-transfers-name', {
                  condensed,
                })}
              >
                {collegeName()} {showBadge && earningsPossible && <ScholarshipBadge />}
              </p>
              <p className="college-card-transfers-location">
                {college.city}, {college.state}
                {!authenticated && college.isPartner && (
                  <>
                    <InfoTooltip className="college-card-signed-out-info-mobile">
                      {loginText}
                    </InfoTooltip>
                    <span className="college-card-signed-out-info">{loginText}</span>
                  </>
                )}
              </p>
            </div>
            {deadline && (
              <DeadlineText
                deadlineMonth={college.deadlineMonth}
                deadlineDay={college.deadlineDay}
                deadlineYear={college.deadlineYear}
                earningRelationship={college.earningRelationship}
                showDeadlineYear={showDeadlineYear}
                simplified={simplified}
              />
            )}
          </div>
        </Box>
        {wideCard && showFollow && (
          <Box pb={2} flex="1 1 0" alignSelf="flex-end">
            <FollowButton
              collegeName={college.name}
              following={college.following}
              outline
              onClick={handleFollowClick}
            />
          </Box>
        )}
      </div>
      {!wideCard && (
        <div className="college-card-bottom-container">
          {showStatus && (
            <StatusText
              following={isFollowing()}
              amount={college.amount}
              earningRelationship={college.earningRelationship}
              condensed={condensed}
              amountLoading={college.amountLoading}
              simplified={simplified}
            />
          )}
          <div
            className={classnames('college-card-transfers-follow', {
              condensed,
            })}
          >
            {showFollow && (
              <FollowButton
                collegeName={college.name}
                following={isFollowing()}
                outline
                onClick={handleFollowClick}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

CollegeCard.propTypes = {
  authenticated: PropTypes.bool,
  college: PropTypes.shape({
    earningsPossible: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    city: PropTypes.string,
    state: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    bannerUrl: PropTypes.string,
    following: PropTypes.bool,
    isFollowing: PropTypes.bool,
    deadlineMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deadlineDay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deadlineYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    earningRelationship: PropTypes.object,
    isPartner: PropTypes.bool,
    avgNetPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tuition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    acceptanceRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    graduationRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    forbesRank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    forbesRankYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    usNewsRank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    usNewsRankYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    usNewsListType: PropTypes.string,
    totalUndergraduates: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amountLoading: PropTypes.bool,
    studentSubmissionTerm: PropTypes.string,
  }).isRequired,
  userType: PropTypes.string,
  followCollege: PropTypes.func.isRequired,
  unfollowCollege: PropTypes.func.isRequired,
  showDeadline: PropTypes.bool,
  showDeadlineYear: PropTypes.bool,
  showStatus: PropTypes.bool,
  showFollow: PropTypes.bool,
  showBadge: PropTypes.bool,
  showSortInfo: PropTypes.bool,
  bucketID: PropTypes.string,
  sortBy: PropTypes.string,
  condensed: PropTypes.bool,
  shortCard: PropTypes.bool,
  simplified: PropTypes.bool,
  communityCollegeInfo: PropTypes.shape({
    transferSemester: PropTypes.string.isRequired,
  }),
  openTermNotOfferedModal: PropTypes.func,
  wideCard: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  authenticated: state.authenticated,
});

export default connect(mapStateToProps)(CollegeCard);
