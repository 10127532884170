import req from 'shared/req';
import {updateCollegePortalUser} from './college-portal-user';

export const RECEIVE_PARTNER_COLLEGES = 'COLLEGE_PORTAL_USER/RECEIVE_PARTNER_COLLEGES';

export const fetchPartnerColleges = () => (dispatch) =>
  req({
    url: '/v1/lists/partners',
  }).then((colleges) => dispatch(receivePartnerColleges(colleges)));

const receivePartnerColleges = (colleges) => ({
  type: RECEIVE_PARTNER_COLLEGES,
  colleges,
});

export const changeColleges = (universityId) => (dispatch) => {
  dispatch(updateCollegePortalUser({universityId})).then(() => location.reload());
};
