import {handleActions} from 'redux-actions';

// college success item types
import {RECEIVE_COLLEGE_SUCCESS_ITEM_TYPES} from '../actions/college-success-item-types';

const collegeSuccessItemTypesReducer = handleActions(
  {
    [RECEIVE_COLLEGE_SUCCESS_ITEM_TYPES]: (_state, {collegeSuccessItemTypes}) =>
      collegeSuccessItemTypes,
  },
  []
);

export default collegeSuccessItemTypesReducer;
