import CRUDService from './CRUDService';

export interface Resource {
  title: string;
  link: string;
  description: string;
  thumbnail: string;
  linkName: string;
}

export interface ResourceCategory {
  id?: number;
  priority?: number;
  title: string;
  resources: Resource[];
}

export default new CRUDService<ResourceCategory>('/v1/resource-categories');
