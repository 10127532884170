import req from 'shared/req';
import {updateUser} from 'shared/actions/student';
import {verifyPhoneNumber, RECEIVE_PHONE_NUMBER} from 'shared/actions/verify-mobile';
import UserService from 'src/services/UserService';

export const GET_USER_INFO = 'GET_USER_INFO';
export const SENDING_VERIFY_EMAIL = 'SENDING_VERIFY_EMAIL';
export const SENT_VERIFY_EMAIL = 'SENT_VERIFY_EMAIL';
export const HIDE_VERIFIED = 'HIDE_VERIFIED';

const USER_FIELDS = [
  'email',
  'mobile',
  'mobileVerified',
  'country',
  'isFirstGen',
  'ethnicity',
  'highSchoolInfo',
  'emailVerifiedAt',
  'parentEmail',
  'communityCollegeInfo',
  'contactInfoUpdatedAt',
  'createdAt',
  'undergraduateInfo',
];

export function getUserInfo() {
  return (dispatch) => {
    UserService.getData(USER_FIELDS).then((user) => {
      dispatch(RECEIVE_PHONE_NUMBER(user.mobile));
      dispatch({
        type: GET_USER_INFO,
        user,
        loading: false,
      });
    });
  };
}

export function updateContactInfoUpdatedAt() {
  return () => {
    updateUser({contactInfoUpdatedAt: new Date()}, 'contactInfoUpdatedAt');
  };
}

export function sendVerificationEmail() {
  return (dispatch) => {
    req({
      url: '/verify_email/send_verification_email',
      method: 'get',
    }).then(() => {
      dispatch({type: SENDING_VERIFY_EMAIL});

      setTimeout(() => {
        dispatch({type: SENT_VERIFY_EMAIL});
      }, 3000);
    });
  };
}

export function hideVerifiedConfirmation() {
  return {type: HIDE_VERIFIED};
}

export const sendVerificationSMS = (mobile) => verifyPhoneNumber(mobile, 'sms');
