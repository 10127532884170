import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'shared/components/icon';
import {gpaFormat} from 'shared/utils';
import {navigateToRequirements} from './college-status-text';

class CommunityCollegeStatusText extends React.Component {
  static propTypes = {
    collegeProfile: PropTypes.object.isRequired,
    missingCredits: PropTypes.number.isRequired,
  };

  render() {
    const {
      missingCredits,
      collegeProfile: {
        college: {
          partnerApplicationDeadlineDay,
          partnerApplicationDeadlineMonth,
          partnerSubmissionDeadlineDay,
          partnerSubmissionDeadlineMonth,
          earningRelationship,
          earningRelationship: {statusType},
          isFollowing,
        },
      },
    } = this.props;
    const applicationDeadlineMonth = partnerApplicationDeadlineMonth + 1;
    const submissionDeadlineMonth = partnerSubmissionDeadlineMonth + 1;

    switch (statusType) {
      case 'submission_period_deferred':
      case 'earning_deadline_soon': {
        {
          if (missingCredits > 0) {
            return (
              <div className="college-profile-status-text">
                <p className="college-profile-status-text-warning">
                  <Icon className="college-profile-status-text-icon" iconType="flag" />
                  Earnings Deadline
                </p>
                <p>
                  {`Add at least ${missingCredits} credit hours by
                    ${submissionDeadlineMonth}/${partnerSubmissionDeadlineDay}.`}
                </p>
              </div>
            );
          } else {
            return (
              <div className="college-profile-status-text">
                <p className="college-profile-status-text-positive">
                  <Icon className="college-profile-status-text-icon" iconType="flag" />
                  On-Track for Submission
                </p>
                <p>
                  {`Way to go! You're on track to have your earnings automatically submitted on
                    ${submissionDeadlineMonth}/${partnerSubmissionDeadlineDay}.`}
                </p>
              </div>
            );
          }
        }
      }
      case 'earning': {
        if (isFollowing) {
          if (missingCredits <= 0) {
            return (
              <div className="college-profile-status-text">
                <p className="college-profile-status-text-positive">
                  <Icon className="college-profile-status-text-icon" iconType="flag" />
                  On-Track for Submission
                </p>
                <p>
                  {`Way to go! You're on track to have your earnings automatically submitted on
                    ${submissionDeadlineMonth}/${partnerSubmissionDeadlineDay}. Also, be sure to apply
                    by ${applicationDeadlineMonth}/${partnerApplicationDeadlineDay}.`}
                </p>
              </div>
            );
          } else {
            return null;
          }
        } else {
          return (
            <div className="college-profile-status-text">
              <p className="college-profile-status-text-awards">Awards scholarships on RaiseMe</p>
              <p>Follow to earn scholarships from this school.</p>
            </div>
          );
        }
      }
      case 'submitted':
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-positive">
              <Icon className="college-profile-status-text-icon" iconType="check" />
              You’ve Been Submitted!
            </p>
            <p>
              {`Apply by ${applicationDeadlineMonth}/${partnerApplicationDeadlineDay}, enroll, and `}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigateToRequirements(true);
                }}
              >
                meet any additional requirements
              </a>
              {' set by the school (if needed) to be awarded.'}
            </p>
          </div>
        );
      case 'deadline_passed':
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-warning">
              <Icon className="college-profile-status-text-icon" iconType="flag" />
              Deadline to Earn Has Passed
            </p>
            <p>
              {`The deadline to earn from this school on RaiseMe
              has passed. Visit their website to see what aid opportunities
              may be available to you.`}
            </p>
          </div>
        );
      case 'us_citizenship_required':
      case 'complete_profile':
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-warning">
              <Icon className="college-profile-status-text-icon" iconType="flag" />
              Complete Your Profile
            </p>
            <p>Complete account settings to check eligibility.</p>
          </div>
        );
      case 'boost_gpa':
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-warning">
              <Icon className="college-profile-status-text-icon" iconType="flag" />
              Boost Your GPA
            </p>
            <p>
              {`Add classes to boost your GPA to ${gpaFormat(earningRelationship.minGPA)}
              and earn from this school.`}
            </p>
          </div>
        );
      case 'us_citizens_only':
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-neutral">
              <Icon className="college-profile-status-text-icon" iconType="flag" />
              Only Available to U.S. Citizens
            </p>
            <p>
              This college only offers scholarships on RaiseMe to U.S. citizens and permanent
              residents, but you can still follow this college.
            </p>
          </div>
        );
      case 'churned':
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-warning">
              <Icon className="college-profile-status-text-icon" iconType="flag" />
              No Longer Awarding New Micro-Scholarships
            </p>
            <p>Learn more on the scholarships tab.</p>
          </div>
        );
      case 'scholarships_not_yet_available':
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-neutral">
              <Icon className="college-profile-status-text-icon" iconType="flag" />
              Micro-Scholarships Not Currently Available
            </p>
            <p>
              This college does not currently offer micro-scholarships on RaiseMe. Follow this
              college to be alerted if and when micro-scholarships become available.
            </p>
          </div>
        );
      case 'limited_scholarships':
        return (
          <div className="college-profile-status-text">
            <p className="college-profile-status-text-neutral">
              <Icon className="college-profile-status-text-icon" iconType="flag" />
              Only available to select students
            </p>
            <p>
              {`This college either does not currently have a micro-
              scholarship program or does not have a program
              for which you are eligible, based on your Raiseme
              profile. You can still apply and may be eligible for
              other types of institutional aid.
              `}
            </p>
          </div>
        );
      default:
        return null;
    }
  }
}

export default CommunityCollegeStatusText;
