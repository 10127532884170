import React, {ReactElement, useCallback, useEffect, useRef} from 'react';
import FormButton from 'src/components/Button';
import {renderIf} from 'src/utils/helpers';
import classNames from 'classnames';

export interface LoadTriggerProps {
  hasMore: boolean;
  onLoadMore: () => void;
  isLoading: boolean;
  buttonText?: string;
  observerOptions?: IntersectionObserverInit;
  className?: string;
}

const defaultObserverOptions = {
  root: null, // window by default
  rootMargin: '0px',
  threshold: 0.1,
};

export default function LoadTrigger(props: LoadTriggerProps): ReactElement | null {
  const loader = useRef<HTMLDivElement>(null);
  const loadMore = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && props.hasMore) {
        !props.isLoading && props.onLoadMore();
      }
    },
    [props.hasMore, props.isLoading, props.onLoadMore]
  );

  useEffect(() => {
    const options = Object.assign({}, props.observerOptions || {}, defaultObserverOptions);
    const observer = new IntersectionObserver(loadMore, options);
    if (loader.current) {
      observer.observe(loader.current);
    }

    return (): void => observer.disconnect();
  }, [loader.current, loadMore]);

  return renderIf(
    <div className={classNames('college-search-load-more-button', props.className)} ref={loader}>
      <FormButton type="button" size="small" color="primary" onClick={props.onLoadMore}>
        {props.buttonText || 'Load more'}
      </FormButton>
    </div>,
    props.hasMore
  );
}
