import PropTypes from 'prop-types';
import React from 'react';
import {capitalize} from 'lodash';
import Modal from 'shared/components/modal';

class TermNotOfferedModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    college: PropTypes.object.isRequired,
    communityCollegeInfo: PropTypes.object.isRequired,
    unfollowCollege: PropTypes.func.isRequired,
  };

  render() {
    const {show, closeModal, college, communityCollegeInfo, unfollowCollege} = this.props;

    return (
      <Modal show={show} onClose={closeModal}>
        <Modal.TextHeader
          onClose={closeModal}
          subtitle={`${communityCollegeInfo?.transferSemester} transfer term not offered at this college. The earnings
                    deadline for the next available Transfer Term will apply`}
        >
          College not offering {communityCollegeInfo?.transferSemester} Transfer Term
        </Modal.TextHeader>
        <div className="colleges-not-offering-transfer-term-modal">
          <Modal.Body>
            <div className="college-transfer-header">
              <span>College</span>
              <span className="transfer-date">Next Transfer Term</span>
            </div>
            <div className="college-transfer-terms">
              <div className="deferred-college" key={college.collegeId}>
                <span className="college-name">
                  <img
                    className="college-earnings-earning-image"
                    alt={`${college.name} logo`}
                    src={`//colleges-static.raise.me/${college.slug}/logo-120x120.png`}
                  />
                  {college.name}
                </span>
                <span className="transfer-term">
                  {capitalize(college.studentSubmissionTerm)} {college.studentSubmissionYear}
                </span>
              </div>
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer
          primaryButton={{
            text: 'Got it',
            onClick: closeModal,
          }}
          secondaryButton={{
            text: 'Unfollow',
            rightAligned: true,
            onClick: () => {
              unfollowCollege(college.id);
              closeModal();
            },
          }}
        />
      </Modal>
    );
  }
}

export default TermNotOfferedModal;
