import {unionBy} from 'lodash';
import {RECEIVE_STUDENT, CLEAR_STUDENT} from 'shared/portfolio/actions/student';
import {UPDATE_CURRENT_INSTITUTION} from 'portfolio/actions/institution';
import {ADD_FAFSA_SUBMISSION, REMOVE_FAFSA_SUBMISSION} from 'shared/college-success/actions/fafsa';
import {RECEIVE_INSTITUTION} from 'portfolio/actions/institution';
import {UPDATE_DISABILITIES} from 'src/user/actions';
import {RECEIVE_IMAGE} from 'shared/actions/avatar';
import {
  UPDATE_SUGGESTED_ADDRESS,
  ADDRESS_VERIFIED,
} from 'shared/account-settings/address-verification';

export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_STUDENT:
      return {
        ...state,
        ...action.student,
      };
    case CLEAR_STUDENT:
      return {};
    case UPDATE_CURRENT_INSTITUTION:
      return {
        ...state,
        currentInstitution: action.institution,
        institutions: unionBy([action.institution], state.institutions, '_id'),
      };
    case RECEIVE_INSTITUTION:
      return {
        ...state,
        institutions: unionBy([action.institution], state.institutions, '_id'),
      };
    case REMOVE_FAFSA_SUBMISSION:
      return {
        ...state,
        fafsaSubmission: {},
      };
    case ADD_FAFSA_SUBMISSION:
      return {
        ...state,
        fafsaSubmission: action.fafsaSubmission,
      };
    case RECEIVE_IMAGE:
      return {
        ...state,
        image: action.image,
      };
    case UPDATE_DISABILITIES: {
      const {disabilities} = action;
      return {...state, disabilities, hasDisabilities: disabilities && disabilities.length};
    }
    case UPDATE_SUGGESTED_ADDRESS: {
      return {
        ...state,
        suggestedAddress: action.suggestedAddress,
      };
    }
    case ADDRESS_VERIFIED: {
      return {
        ...state,
        ...action.addressVerifiedData,
      };
    }
    default:
      return state;
  }
};
