import PropTypes from 'prop-types';
import React from 'react';
import {withCommas} from 'shared/utils';

class LabeledHorizontalPlotContainer extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    subtext: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
  };

  render() {
    const {children, value, subtext, label} = this.props;

    return (
      <div className="about-college-score-range" aria-hidden="true">
        <div className="about-college-score-plot">{children}</div>
        <div className="college-score-range-label">
          <div>{label || `$${withCommas(Math.round(value))}`}</div>
          <div className="college-score-range-label-subtext">{subtext}</div>
        </div>
      </div>
    );
  }
}

export default LabeledHorizontalPlotContainer;
