import React from 'react';
import classNames from 'classnames';
import {Tooltip as MuiTooltip, TooltipProps} from '@mui/material';

import styles from './tooltip.module.scss';

interface Props {
  className?: string;
}

const Tooltip = ({className, children, ...remainingProps}: Props & TooltipProps) => (
  <MuiTooltip
    classes={{arrow: styles.arrow, tooltip: classNames(styles.tooltip, className)}}
    arrow
    {...remainingProps}
    enterTouchDelay={100}
  >
    {children}
  </MuiTooltip>
);

export default Tooltip;
