import {arrayOf, Schema} from 'normalizr';
import {institution} from './shared';

const collegeCourse = new Schema('collegeCourses', {
  idAttribute: (course) => `college-course-${course._id}`,
});

collegeCourse.define({
  institution: institution,
});

const collegeCourses = arrayOf(collegeCourse);

export {collegeCourse, collegeCourses};
