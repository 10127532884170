export const TOP_ACTIVITIES = [
  {
    name: 'Soccer',
    count: 22000,
    emoji: '⚽️',
  },
  {
    name: 'Choir',
    count: 29191,
    emoji: '🎶',
  },
  {
    name: 'Football',
    count: 11000,
    emoji: '🏈',
  },
  {
    name: 'Science Club',
    count: 13432,
    emoji: '🔬',
  },
  {
    name: 'Yearbook',
    count: 14423,
    emoji: '📖',
  },
];

export const SAMPLE_DATA = {
  _id: 'sample',
  highSchoolInfo: {
    gpa: 3.85,
    graduationYear: new Date().getFullYear() + 1,
  },
  communityCollegeInfo: {
    gpa: 3.85,
    transferYear: new Date().getFullYear() + 1,
    transferSemester: 'Spring',
  },
  totalCredits: 16,
  followedCollegesEarnings: 9000,
  code: 'sample',
  updatedAt: Date.now() / 1000,
  currentInstitution: {},
};

export const SESSION_STORAGE_KEY = 'educator-student-query';
export const STUDENTS_PER_PAGE = 20;
