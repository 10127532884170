import {omit, merge, some} from 'lodash';
import {
  DELETE_ACTIVITY,
  RECEIVE_ACTIVITY,
  EDIT_ACTIVITY,
  CANCEL_EDIT_ACTIVITY,
  SCROLLED_TO_ACTIVITY,
  NEW_ACTIVITY,
  RECEIVE_ACTIVITY_INSTITUTION_LEADERSHIP_POSITIONS,
  SHOW_ACTIVITY_DESCRIPTION,
  HIDE_ACTIVITY_DESCRIPTION,
  RECEIVE_ACTIVITIES,
  RECEIVE_LEADERSHIP_POSITIONS,
} from 'shared/portfolio/actions/activities';

export function leadershipPositionsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_ACTIVITY_INSTITUTION_LEADERSHIP_POSITIONS:
    case RECEIVE_LEADERSHIP_POSITIONS:
      return merge({}, state, action.leadershipPositions);
    default:
      return state;
  }
}

export function activitiesReducer(state = {}, action) {
  switch (action.type) {
    case EDIT_ACTIVITY:
      return {
        ...state,
        [action.id]: {
          ...action.activity,
          editing: true,
        },
      };
    case DELETE_ACTIVITY:
      return omit(state, action.id);
    case CANCEL_EDIT_ACTIVITY:
      if (action.activity.new) {
        return omit(state, action.id);
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.activity,
            editing: false,
            scrollTo: true,
          },
        };
      }
    case SCROLLED_TO_ACTIVITY:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          scrollTo: false,
        },
      };
    case RECEIVE_ACTIVITY_INSTITUTION_LEADERSHIP_POSITIONS:
    case RECEIVE_ACTIVITY:
      return {
        ...state,
        [action.id]: {
          ...action.activity,
        },
      };
    case NEW_ACTIVITY:
      if (some(state, 'new')) {
        return state;
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.activity,
          },
        };
      }
    case RECEIVE_ACTIVITIES:
      return {
        ...state,
        ...action.activities,
      };
    default:
      return state;
  }
}

export const activityDescriptionReducer = (state = false, action) => {
  switch (action.type) {
    case SHOW_ACTIVITY_DESCRIPTION:
      return true;
    case HIDE_ACTIVITY_DESCRIPTION:
      return false;
    default:
      return state;
  }
};
