import {GET_COLLEGE} from 'shared/college-profile/actions/college-profile';

export const collegeReducer = (state = {activeScholarshipProgram: {}}, action) => {
  switch (action.type) {
    case GET_COLLEGE: {
      return {
        ...state,
        ...action.college,
      };
    }
    default:
      return state;
  }
};
