import req from '../../shared/req';
import {ipInfo} from '../../shared/utils';

const API_KEY = 'AIzaSyD4eBgMc7E7tNEBLTJqF4ul4DgwTBZh42o';

const extractCityState = (plusCode: string) => {
  const plusCodeArray = plusCode.split(',');
  const locationIndex = plusCodeArray.length === 3 ? 0 : 1;
  const city = plusCodeArray[locationIndex].split(' ').slice(1).join(' ');
  const state = plusCodeArray[locationIndex + 1].trim();
  return `${city}, ${state}`;
};

export const getCity = async (coordinates): Promise<string> => {
  const geocodingURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.latitude},${coordinates.longitude}&key=${API_KEY}`;

  const locationData: {plus_code: {compound_code: string}} = await req({
    url: geocodingURL,
    method: 'GET',
  });

  if (locationData.plus_code) {
    return extractCityState(locationData.plus_code.compound_code);
  } else {
    throw new Error('Failed to retrieve city data');
  }
};

interface Coordinates {
  longitude: number;
  latitude: number;
}

interface Location {
  name: string;
  coordinates: Coordinates;
}

const getBrowserCoordinates = (): Promise<Coordinates> =>
  new Promise((resolve, reject) => {
    navigator?.geolocation?.getCurrentPosition(
      ({coords: {latitude, longitude}}) => {
        resolve({latitude, longitude});
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getBrowserLocation = async (): Promise<Location> => {
  const coordinates = await getBrowserCoordinates();
  const name = await getCity(coordinates);

  return {name, coordinates};
};

export const getIpLocation = async (): Promise<Location> => {
  const {coordinates, cityName, subdivisionName} = (await ipInfo()) as {
    coordinates: Coordinates;
    cityName: string;
    subdivisionName: string;
  };

  const name =
    cityName && subdivisionName ? `${cityName}, ${subdivisionName}` : await getCity(coordinates);

  return {coordinates, name};
};
