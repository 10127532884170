export const CLOSE_OVERLAY = 'CLOSE_OVERLAY';
export const OPEN_OVERLAY = 'OPEN_OVERLAY';

export function closeOverlay() {
  return {type: CLOSE_OVERLAY};
}

export function openOverlay() {
  return {type: OPEN_OVERLAY};
}
