import {RECEIVE_COLLEGE} from 'src/redux/admin/actions/communityCollegeSearch';

const defaultState = {
  _id: '',
  campuses: [],
  city: '',
  name: '',
  state: '',
  type: '',
  unitId: '',
};

function currentInstitutionReducer(state = defaultState, action) {
  switch (action.type) {
    case RECEIVE_COLLEGE:
      return action.college;
    default:
      return state;
  }
}

export default currentInstitutionReducer;
