import React, {
  forwardRef,
  useId,
  useImperativeHandle,
  useRef,
  ComponentPropsWithRef,
  ReactNode,
} from 'react';
import classNames from 'classnames';

import 'stylesheets/components/radio-button';

export interface Props extends ComponentPropsWithRef<'input'> {
  value?: string;
  label?: ReactNode;
  error?: boolean;
  testId?: string;
  cy?: string;
}

const RadioButton = forwardRef<HTMLInputElement, Props>(function RadioButton(
  {label, className, error, testId, cy, ...inputProps},
  forwardedRef
) {
  const inputId = useId();
  const ref = useRef<HTMLInputElement>(null);

  // if forwarded a ref, expose existing input ref
  useImperativeHandle(forwardedRef, () => ref.current!, []);

  return (
    <div className={classNames('radio-button', {className})}>
      <input
        ref={ref}
        id={inputId}
        className={classNames('radio-button-input', {error})}
        data-testid={testId}
        data-cy={cy}
        {...inputProps}
        type="radio"
      />
      <label
        htmlFor={inputId}
        className={classNames('radio-button-label', {disabled: inputProps.disabled})}
      >
        {label}
      </label>
    </div>
  );
});

export default RadioButton;
