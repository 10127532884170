import PropTypes from 'prop-types';
import React from 'react';
import {compact} from 'lodash';
import GraduationByEthnicity from './graduation-by-ethnicity';
import Card from 'src/components/Cards/Card';
import CollapsibleTeaserSection from 'shared/components/collapsible-teaser-section';
import MiniInfoCardContainer from 'shared/components/college-profile/mini-info-card-container';
import MiniInfoCard from 'shared/components/college-profile/mini-info-card';

class AboutGraduationRates extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
  };

  render() {
    const {
      college,
      college: {
        firstYearRetention,
        fourYearGraduationRate,
        sixYearGraduationRate,
        sixYearGraduationRateByEthnicity,
      },
    } = this.props;

    // If none of the info is present, return null.
    if (
      compact([
        firstYearRetention,
        fourYearGraduationRate,
        sixYearGraduationRate,
        sixYearGraduationRateByEthnicity,
      ]).length < 1
    ) {
      return null;
    }

    const isCollapsingDisabled = GraduationByEthnicity.isEmptyWithProps({college});
    const modifierClass = isCollapsingDisabled ? '' : 'zero-bottom-padding';

    return (
      <Card className={modifierClass}>
        <CollapsibleTeaserSection
          title="Graduation Rates"
          trackIdentifier="college-listing-about-graduation-rates"
          className="college-profile-about-graduation college-profile-card-about-content"
          isCollapsingDisabled={isCollapsingDisabled}
        >
          <CollapsibleTeaserSection.Teaser>
            <MiniInfoCardContainer>
              <MiniInfoCard
                iconType="chart-area"
                label="1st Yr. Student Retention"
                value={firstYearRetention ? `${Math.round(firstYearRetention)}%` : null}
              />
              <MiniInfoCard
                iconType="graduation-cap"
                label="Four-Year Grad Rate"
                value={fourYearGraduationRate ? `${Math.round(fourYearGraduationRate)}%` : null}
              />
              <MiniInfoCard
                iconType="graduation-cap"
                label="Six-Year Grad Rate"
                value={sixYearGraduationRate ? `${Math.round(sixYearGraduationRate)}%` : null}
              />
            </MiniInfoCardContainer>
          </CollapsibleTeaserSection.Teaser>
          <CollapsibleTeaserSection.Body collapsedHeight={120}>
            <GraduationByEthnicity college={college} />
          </CollapsibleTeaserSection.Body>
        </CollapsibleTeaserSection>
      </Card>
    );
  }
}

export default AboutGraduationRates;
