import React, {ReactNode, ComponentPropsWithoutRef} from 'react';
import classNames from 'classnames';
import styles from './unstyled-button.module.scss';

interface Props extends ComponentPropsWithoutRef<'button'> {
  className?: string;
  children: ReactNode;
}

const UnstyledButton = ({className, children, ...buttonProps}: Props) => (
  <button className={classNames(styles.button, className)} {...buttonProps}>
    {children}
  </button>
);

export default UnstyledButton;
