import {
  RECEIVE_NOTIFICATION_MESSAGE_INDEXES,
  REMOVE_NOTIFICATION_MESSAGE_INDEX,
  REMOVE_NOTIFICATION_MESSAGE_INDEXES,
} from 'shared/notification-center/actions/notification-message-indexes';

const defaultState = new Set();

export default function notificationMessagesReducer(state = defaultState, action) {
  switch (action.type) {
    case RECEIVE_NOTIFICATION_MESSAGE_INDEXES: {
      return new Set([...Array.from(state), ...action.notificationMessageIndexes]);
    }
    case REMOVE_NOTIFICATION_MESSAGE_INDEX:
      state.delete(action.sequenceNumber);
      // we don't want to mutate state, so we return a new Set from the state
      return new Set([...state]);
    case REMOVE_NOTIFICATION_MESSAGE_INDEXES:
      return new Set();
    default:
      return state;
  }
}
