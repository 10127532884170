export const ADD_AWARD_NOTIFICATION = 'ADD_AWARD_NOTIFICATION';
export const REMOVE_AWARD_NOTIFICATION = 'REMOVE_AWARD_NOTIFICATION';
export const SET_ACTIVE_AWARD_NOTIFICATION = 'SET_ACTIVE_AWARD_NOTIFICATION';
export const UNSET_ACTIVE_AWARD_NOTIFICATION = 'UNSET_ACTIVE_AWARD_NOTIFICATION';

export const addAwardNotificationToQueue = (award) => ({
  type: ADD_AWARD_NOTIFICATION,
  award,
});

export const removeAwardNotificationFromQueue = () => ({
  type: REMOVE_AWARD_NOTIFICATION,
});

export const setActiveAwardNotification = () => ({
  type: SET_ACTIVE_AWARD_NOTIFICATION,
});

export const unsetActiveAwardNotification = () => ({
  type: UNSET_ACTIVE_AWARD_NOTIFICATION,
});
