import {RECEIVE_COLLEGE_APP_STATUS} from '../actions/college-application-status';

function collegeApplicationStatusReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_COLLEGE_APP_STATUS:
      return action.payload;
    default:
      return state;
  }
}

export default collegeApplicationStatusReducer;
