import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {removeNotification} from 'shared/actions/notifications';

// notifications
import SimpleNotification from '../../src/components/notifications/simple-notification';
import ActionNotification from '../../src/components/notifications/action-notification';
import 'stylesheets/components/shared-notifications.scss';

class Notifications extends React.Component {
  static propTypes = {
    dismiss: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    notifications: PropTypes.array,
  };

  iconForNotificationType({type}) {
    switch (type) {
      case 'success':
        return 'check-r';
      case 'error':
        return '';
    }
  }

  mapNotificationToComponent(notification) {
    const {dismiss, dispatch} = this.props;

    const sharedProps = {
      dismiss: () => dismiss(notification.id),
      key: notification.id,
      text: notification.text,
      type: notification.type,
    };

    switch (notification.template) {
      case 'action':
        return (
          <ActionNotification
            {...sharedProps}
            dispatch={dispatch}
            strongText={notification.strongText}
            actionText={notification.actionText}
            action={notification.action}
          />
        );
      default:
        return (
          <SimpleNotification
            {...sharedProps}
            iconType={this.iconForNotificationType(notification)}
          />
        );
    }
  }

  render() {
    const {notifications} = this.props;

    // TODO: make this care about the duration and dispatch it's own removal
    return (
      <div className="shared-notifications">
        {notifications.map((notification) => this.mapNotificationToComponent(notification))}
      </div>
    );
  }
}

const mapStateToProps = ({notifications}) => ({
  notifications: notifications || [],
});

const mapDispatchToProps = (dispatch) => ({
  dismiss: (id) => dispatch(removeNotification(id)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
