import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {changeTab} from 'shared/college-profile/actions/college-profile';
import CollegeTabNav from '../components/college-tab-nav';

const mapStateToProps = ({collegeProfile}) => ({
  collegeProfile,
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      changeTab,
    },
    dispatch
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollegeTabNav));
