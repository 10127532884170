import {
  MISSING_HIGH_SCHOOL_REQUESTED,
  MISSING_HIGH_SCHOOL_CHANGE_MODAL,
} from '../actions/missing-high-school-modal';

export function missingHighSchoolModalReducer(state = {}, action) {
  switch (action.type) {
    case MISSING_HIGH_SCHOOL_REQUESTED:
      return {
        ...state,
        email: action.email,
      };
    case MISSING_HIGH_SCHOOL_CHANGE_MODAL:
      return {
        ...state,
        modal: action.modal,
      };
    default:
      return state;
  }
}
