import {concat, includes, map, noop, without} from 'lodash';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {clearFilter, setFilter} from 'shared/actions/filters';
import Checkbox from 'shared/components/checkbox';

class CheckboxGroupFilter extends React.Component {
  static propTypes = {
    selected: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    /* eslint-disable react/no-unused-prop-types */
    // these two are used in mapStateToProps and mergeProps
    name: PropTypes.string,
    onChange: PropTypes.func,
    /* eslint-enable react/no-unused-prop-types */
    useContainer: PropTypes.bool,
  };

  static defaultProps = {
    useContainer: true,
  };

  render() {
    const {selected, options, onClick, useContainer} = this.props;
    const checkboxes = map(options, (label, key) => (
      <Checkbox
        key={key}
        checked={includes(selected, key)}
        label={label}
        onChange={(e) => onClick(key, e.target.checked)}
      />
    ));

    return useContainer ? (
      <div className="checkbox-group-filter">{checkboxes}</div>
    ) : (
      <>{checkboxes}</>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selected: state.filters[ownProps.name] || [],
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {dispatch} = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onClick(key, checked) {
      const onChange = ownProps.onChange || noop;
      const valueGen = checked ? concat : without;
      const value = valueGen(stateProps.selected, key);
      if (value.length) {
        dispatch(
          setFilter({
            name: ownProps.name,
            value,
          })
        );
      } else {
        dispatch(clearFilter({name: ownProps.name}));
      }
      onChange(value);
    },
  };
};

export {CheckboxGroupFilter};

export default connect(mapStateToProps, null, mergeProps)(CheckboxGroupFilter);
