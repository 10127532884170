import React from 'react';
import GlobalNotification, {NotificationFnResult} from 'src/components/GlobalNotification';
import {MQ} from 'shared/mq';
import {useMediaQuery} from 'src/hooks/useMediaQuery';
import {Link} from 'react-router-dom';
import {MiddlewareFn} from 'src/hooks/useFunctionChain';

interface StatusNotificationProps {
  // ...props is used below
  // eslint-disable-next-line react/no-unused-prop-types
  dismissCitizenNotification: () => void;
  user: {
    totalCredits: number;
    highSchoolInfo: {senior: boolean};
    dismissedCitizenNotification: boolean;
    isUsCitizenOrResident: boolean;
  };
}

type StatusNotificationMiddlewareProps = StatusNotificationProps & {
  mq: MQ;
  creditsLeft: number;
};

const notificationChain: MiddlewareFn<StatusNotificationMiddlewareProps, NotificationFnResult>[] = [
  (ctx): NotificationFnResult => {
    if (ctx.user?.highSchoolInfo?.senior && ctx.creditsLeft > 0) {
      return {
        canDismiss: ctx.mq.MS_AND_DOWN,
        notification: (
          <Link to="/portfolio#courses">
            <span className="college-search-status-notification-courses">
              <span className="college-search-status-notification-mobile-emphasis">
                Add {ctx.creditsLeft} more course credits
              </span>{' '}
              to qualify to receive your earnings.
            </span>
            <span className="college-search-status-notification-add-courses">Add course</span>
          </Link>
        ),
      };
    }
  },
  (ctx): NotificationFnResult => {
    if (!ctx.user?.dismissedCitizenNotification && ctx.user?.isUsCitizenOrResident === null) {
      return {
        canDismiss: true,
        onDismiss: ctx.dismissCitizenNotification,
        notification: (
          <Link to="/account-settings">
            <span className="college-search-status-notification-citizen">
              <span className="college-search-status-notification-mobile-emphasis">
                Edit your citizenship status
              </span>{' '}
              to earn additional scholarships.
            </span>
            <span className="college-search-status-notification-edit-citizen">Edit status</span>
          </Link>
        ),
      };
    }
  },
];

export default function StatusNotification(props: StatusNotificationProps): React.ReactElement {
  const mq = useMediaQuery();
  const creditsLeft = Math.max(Math.ceil(15 - props.user?.totalCredits), 0);
  return (
    <GlobalNotification<StatusNotificationMiddlewareProps>
      className="college-search-status-notification"
      notificationChain={notificationChain}
      context={{
        ...props,
        creditsLeft,
        mq,
      }}
    />
  );
}
