import React, {useRef, useEffect} from 'react';
import classNames from 'classnames';

import {useResizeObserver} from 'src/hooks/useResizeObserver';
import HeaderContent from './header-content';
import {useModalContext} from '.';

import './text-header.scss';

interface Props {
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  onClose?: () => void;
  onBack?: () => void;
  textAlign?: 'left' | 'center';
  className?: string;
}

const TextHeader = ({children, subtitle, onBack, onClose, textAlign, className}: Props) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const {height} = useResizeObserver(headerRef);
  const {setHasTextHeader, setHeaderHeight, bodyOverflow} = useModalContext();

  useEffect(() => {
    setHasTextHeader(true);

    return () => {
      setHasTextHeader(false);
    };
  }, [setHasTextHeader]);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(height);
    }
  }, [setHeaderHeight, height]);

  return (
    <div
      id="shared-modal-header"
      ref={headerRef}
      className={classNames('shared-text-modal-header', className, {overflow: bodyOverflow})}
    >
      <HeaderContent subtitle={subtitle} onClose={onClose} onBack={onBack} textAlign={textAlign}>
        {children}
      </HeaderContent>
    </div>
  );
};

export default TextHeader;
