import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import Requirements from 'src/components/ScholarshipPrograms/Requirements';
import Card from 'src/components/Cards/Card';
import CollapsibleSection from 'src/components/collapsible-section';
import {getMedia, watchMedia} from 'shared/mq';
import {Modals} from 'src/types/enums';
import {withRouter} from 'react-router-dom';
import {ModalContext} from 'src/components/ModalContext/types';

class EligibilityCriteriaCard extends React.Component {
  static propTypes = {
    scholarshipProgram: PropTypes.object.isRequired,
    showsHighSchoolList: PropTypes.bool,
  };

  static contextType = ModalContext;

  componentDidMount() {
    this.mq = watchMedia(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.mq.remove();
  }

  onHighSchoolListClick = (criterionId) => {
    const {props, context: modalContext} = this;
    modalContext.show(Modals.SCHOLARSHIP_PROGRAM_HIGH_SCHOOL, {
      programId: props.scholarshipProgram._id,
      criterionId,
    });
  };

  render() {
    const {scholarshipProgram, showsHighSchoolList} = this.props;
    const mq = getMedia();
    const criteria = [...scholarshipProgram.criteria];

    if (!isEmpty(scholarshipProgram.criterionOr)) {
      criteria.push(scholarshipProgram.criterionOr);
    }

    return (
      <Card className="college-profile-eligibility-criteria scholarship-program-info">
        <CollapsibleSection
          title="Eligibility Criteria"
          subtitle="To Earn RaiseMe Micro-Scholarships"
          disableCollapse={mq.MD_AND_UP}
        >
          <div className="college-profile-header-spacing">
            <Requirements
              requirements={scholarshipProgram.eligibilityRequirements}
              deadline={scholarshipProgram.submissionDeadline}
              onHighSchoolListClick={showsHighSchoolList && this.onHighSchoolListClick}
              applicationDeadline={scholarshipProgram.applicationDeadline}
              minCreditHours={scholarshipProgram.minCreditHours}
              criteria={criteria}
              submissionPeriods={scholarshipProgram.submissionPeriods}
              eligiblePhase={scholarshipProgram.eligiblePhase}
            />
          </div>
        </CollapsibleSection>
      </Card>
    );
  }
}

export default withRouter(EligibilityCriteriaCard);
