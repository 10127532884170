import React from 'react';
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {Formik} from 'formik';

import AcademicInterests from 'shared/components/academic-interests';
import {Store} from 'src/types/store';
import {setMajors} from 'shared/college-profile/actions/college-profile';
import {updateAcademicInterests} from 'shared/college-profile/actions/student';

const emojis = [
  '\uD83D\uDC69\uD83C\uDFFB\u200D\uD83D\uDD2C',
  '\uD83D\uDC68\uD83C\uDFFD\u200D\uD83C\uDFEB',
  '\uD83D\uDC69\uD83C\uDFFC\u200D\uD83D\uDE80',
  '\uD83D\uDC68\uD83C\uDFFE\u200D\u2696\uFE0F',
  '\uD83D\uDC69\uD83C\uDFFD\u200D\u2695\uFE0F',
];

const AcademicInterestsSection = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: Store) => state.collegeProfile.userInfo);

  return (
    <Formik
      initialValues={{
        academicInterestIds: userInfo.academicInterestIds,
      }}
      onSubmit={({academicInterestIds}) => {
        dispatch(updateAcademicInterests(academicInterestIds));
        dispatch(setMajors(academicInterestIds));
      }}
    >
      {({initialValues}) => {
        const hasNoInitialInterests = initialValues.academicInterestIds.length === 0;

        return (
          <div
            data-testid="academic-interests-section"
            className={classnames('college-profile-academic-interests-section', {
              'no-interests': hasNoInitialInterests,
            })}
          >
            {hasNoInitialInterests && (
              <div className="college-profile-academic-interests-section-emojis-container">
                {emojis.map((emoji) => (
                  <span key={emoji} className="college-profile-academic-interests-section-emoji">
                    {emoji}
                  </span>
                ))}
              </div>
            )}

            <AcademicInterests
              submitOnChange
              label={
                hasNoInitialInterests
                  ? 'What are you interested in studying?'
                  : 'Your academic interests'
              }
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default AcademicInterestsSection;
