import React from 'react';
import {useField} from 'formik';
import TextArea from 'src/components/text-area';
import TextInput from 'src/components/inputs/text-input';
import {Testable} from 'src/types/util';

export interface TextFieldProps {
  showCheck?: boolean;
  autoComplete?: string;
  'aria-label'?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  selectValueOnFocus?: boolean;
  autoFocus?: boolean;
  onChange?: (value: string, setValue: (value: string) => void) => void;
  className?: string;
  ['data-testid']?: string;
}

type FormikTextFieldProps = Testable &
  TextFieldProps & {
    name: string;
    type: string;
  };

export const setValue =
  (setFieldValue, name) =>
  (value): void =>
    setFieldValue(name, typeof value === 'object' ? value.target.value : value);

/**
 * @deprecated Use FormikTextArea or FormikTextInput instead.
 */
export default function FormikTextField(props: FormikTextFieldProps): React.ReactElement {
  const InputComponent = props.type === 'textarea' ? TextArea : TextInput;
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const stringValue = typeof value === 'object' ? value.target.value : value;
    if (props.onChange) {
      props.onChange(stringValue, helpers.setValue);
    } else {
      helpers.setValue(stringValue);
    }
  };

  return (
    <InputComponent
      {...props}
      value={field.value}
      error={meta.touched || field.value ? meta.error : undefined}
      onBlur={field.onBlur}
      onChange={handleChange}
      required={props.required}
      className={props.className}
      showCheck={props.showCheck && field.value}
    />
  );
}

FormikTextField.defaultProps = {
  type: 'text',
};
