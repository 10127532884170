import PropTypes from 'prop-types';
import React from 'react';
import Text from 'shared/components/text';
import Icon from 'shared/components/icon';

class DeadlinePassedText extends React.Component {
  static propTypes = {
    deadline: PropTypes.string,
  };

  render() {
    const {deadline} = this.props;

    return (
      <div className="shared-college-card-status-text-deadline-passed">
        <Icon className="shared-college-card-status-text-default-flag" iconType="flag" />
        <Text weight={6} inline>
          {deadline ? deadline : 'The'} deadline to earn has passed
        </Text>
      </div>
    );
  }
}

export default DeadlinePassedText;
