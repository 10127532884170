import {map, findIndex} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'shared/components/checkbox';

class LocationCheckboxFilter extends React.Component {
  static propTypes = {
    selected: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const {selected, options, onClick} = this.props;
    const checkboxes = map(options, (label, key) => (
      <Checkbox
        key={key}
        checked={findIndex(selected, key) !== -1}
        label={label}
        onChange={(e) => onClick(key, e.target.checked)}
      />
    ));
    return <div className="checkbox-group-filter">{checkboxes}</div>;
  }
}

export default LocationCheckboxFilter;
