import React from 'react';
import CollegeTabNav from 'shared/college-profile/containers/college-tab-nav';
import Layout from 'shared/college-profile/containers/layout';
import {connectComponent} from 'shared/college-profile/containers/right-rail';
import RightRail from './right-rail';
import {RouteComponentProps} from 'react-router-dom';

const ConnectedRightRail = connectComponent(RightRail) as unknown as React.ReactElement;

const CollegeProfile = ({match}: RouteComponentProps) => (
  <Layout match={match} RightRail={ConnectedRightRail}>
    {/* @ts-expect-error */}
    <CollegeTabNav hideRequirements nonStudent RightRail={ConnectedRightRail} hideScholarships />
  </Layout>
);

export default CollegeProfile;
