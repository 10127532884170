import PropTypes from 'prop-types';
import React, {Component} from 'react';

class TestimonialAvatar extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
  };

  render() {
    const {url} = this.props;

    return (
      <div className="about-testimonial-avatar-container">
        <img className="about-testimonial-avatar-picture" src={url} alt="user avatar" />
      </div>
    );
  }
}

export default TestimonialAvatar;
