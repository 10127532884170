import React from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';

import Icon from 'shared/components/icon';
import Button from 'src/components/Button';
import Header from 'shared/college-profile/components/right-rail/header';
import Card from 'src/components/Cards/Card';
import {successfulLoginRedirectPath} from '../../../utils/storage';
import {SessionStorage} from '../../../utils/session-storage';

interface College {
  name: string;
  state: string;
  city: string;
  logo: string;
  earningsPossible: boolean;
  _id: string;
  slug: string;
  isPartner: boolean;
}

interface CollegeProfile {
  college: College;
}

interface RightRailProps {
  collegeProfile: CollegeProfile;
}

const RightRail = ({
  collegeProfile: {
    college: {city, logo, name, state, earningsPossible, isPartner, _id, slug},
  },
}: RightRailProps) => {
  const {push} = useHistory();
  const {pathname} = useLocation();

  const handleFollow = () => {
    if (earningsPossible) {
      sessionStorage.setItem(SessionStorage.FOLLOW_COLLEGE_ID, _id);
      successfulLoginRedirectPath.set(`edu/${slug}`);
    }

    push(`${pathname}/sign-up-first`);
  };

  return (
    <aside className="college-profile-right-rail" aria-labelledby="college-profile-college-name">
      <Card>
        <div className="college-profile-college-status-card">
          <Header {...{city, logo, name, state}} canEarn={earningsPossible} />
          {earningsPossible ? (
            <div className="college-profile-status-text" data-testid="college-profile-status-text">
              <p className="college-profile-status-text-awards">Awards scholarships on RaiseMe</p>
              <p>
                Create an account or log in to see potential earnings from this college partner.
              </p>
            </div>
          ) : (
            <div className="college-profile-status-text" data-testid="college-profile-status-text">
              <p className="college-profile-status-text-neutral">
                <Icon className="college-profile-status-text-icon" iconType="flag" />
                Micro-Scholarships Not Currently Available
              </p>
              <p>
                Though {name} does not offer Micro-Scholarships on RaiseMe, other colleges do. Sign
                up for a free RaiseMe profile to see potential earnings from our college partners!
              </p>
              {!isPartner && (
                <div className="shared-font caption employee-text">
                  <p className="inline">{`${name} employee? `}</p>
                  <Link to={`${location.pathname}/college-employee`}>Click here.</Link>
                </div>
              )}
            </div>
          )}
          <Button margin width="100%" onClick={() => handleFollow()}>
            {earningsPossible ? 'Follow' : 'Create Account'}
          </Button>
        </div>
      </Card>
    </aside>
  );
};

export default RightRail;
