import PropTypes from 'prop-types';
import React from 'react';

class CollegeMap extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
  };

  render() {
    const {
      college: {loc},
    } = this.props;

    if (!loc) {
      return null;
    }

    return (
      <div className="college-profile-map">
        <iframe
          title="Google Maps"
          width="100%"
          height="242"
          frameBorder="0"
          style={{border: '0'}}
          src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyC5W5zNGvxuJ8jVWpFSLQxTBbfW9l47WI0&zoom=12&center=${loc[1]},${loc[0]}`}
          allowFullScreen
        ></iframe>
      </div>
    );
  }
}

export default CollegeMap;
