import React, {memo} from 'react';

const LoadingCard = () => (
  <div className="college-search-loading-card">
    <div className="college-search-loading-banner shimmer"></div>
    <div className="college-search-loading-text-group">
      <div className="college-search-loading-text small shimmer"></div>
      <div className="college-search-loading-text x-small shimmer"></div>
    </div>
  </div>
);

export default memo(LoadingCard);
