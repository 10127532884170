import {clone, isEmpty, map, unionBy} from 'lodash';
import {
  CHANGE_SCHOLARSHIP_PROGRAM,
  CHANGE_TAB,
  CLEAR_COLLEGE_INFO,
  FOLLOW_COLLEGE,
  FOLLOW_OTHER_CAMPUS,
  FOLLOW_SIMILAR_COLLEGE,
  GET_COLLEGE,
  GET_COLLEGE_TESTIMONIALS,
  GET_OTHER_CAMPUS,
  GET_SIMILAR_COLLEGES,
  GET_SIMILAR_PARTNER_COLLEGES,
  MODAL_SAVED,
  SET_EARLY_SUBMISSION_SUBMITTING,
  TOGGLE_LOADING,
  TOGGLE_LOADING_SCHOLARSHIPS,
  UNFOLLOW_COLLEGE,
  UNFOLLOW_OTHER_CAMPUS,
  UNFOLLOW_SIMILAR_COLLEGE,
  UPDATE_EARNING_STATUS,
} from '../actions/college-profile';
import {GET_USER_INFO, UPDATE_HOUSEHOLD_INCOME_RANGE} from '../actions/student';
import {
  RECEIVE_STUDENT_COLLEGE_TAGS,
  REQUESTED_UPDATE_STUDENT_COLLEGE_TAGS,
} from '../actions/student-college-tags';
import {RECEIVE_COLLEGE_TESTIMONIAL} from 'shared/college-profile/actions/testimonials';
import {eligiblePrograms} from '../utils';
import {RECEIVE_COLLEGE_PORTAL_USER} from 'src/redux/college-portal/actions/college-portal-user';

const initialState = {
  activeScholarshipProgram: {},
};

export const collegeProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLLEGE: {
      const {scholarshipPrograms} = action.college;
      const {userInfo} = state;
      const eligibleScholarshipPrograms = scholarshipPrograms
        ? {scholarshipPrograms: eligiblePrograms(scholarshipPrograms, userInfo)}
        : {};

      return {
        ...state,
        college: {
          ...state.college,
          ...action.college,
          ...eligibleScholarshipPrograms,
        },
      };
    }
    case GET_SIMILAR_PARTNER_COLLEGES:
      if (isEmpty(action.colleges)) {
        return state;
      }
      return {
        ...state,
        similarPartnerColleges: action.colleges.map((college) => ({
          ...college,
          bucketShownEventId: action.bucketShownEventId,
        })),
      };
    case GET_SIMILAR_COLLEGES:
      if (isEmpty(action.colleges)) {
        return state;
      }
      return {
        ...state,
        similarColleges: action.colleges.map((college) => ({
          ...college,
          bucketShownEventId: action.bucketShownEventId,
        })),
      };
    case GET_COLLEGE_TESTIMONIALS:
      return {
        ...state,
        testimonials: action.testimonials,
      };
    case `${RECEIVE_COLLEGE_TESTIMONIAL().type}`: {
      const testimonial = action.payload;

      return {
        ...state,
        testimonials: unionBy([testimonial], state.testimonials, 'testimonialId'),
      };
    }
    case MODAL_SAVED: {
      const modalPendingCopy = clone(state.modalPending);
      modalPendingCopy[action.category] = true;
      return {
        ...state,
        modalPending: modalPendingCopy,
      };
    }
    case GET_USER_INFO: {
      const {info} = action;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...info,
        },
      };
    }
    case FOLLOW_COLLEGE:
      return {
        ...state,
        college: {
          ...state.college,
          isFollowing: true,
          relationship: action.relationship,
        },
      };
    case UNFOLLOW_COLLEGE:
      return {
        ...state,
        college: {
          ...state.college,
          isFollowing: false,
          relationship: action.relationship,
        },
      };
    case FOLLOW_SIMILAR_COLLEGE: {
      const {similarColleges, similarPartnerColleges} = state;
      const {id} = action;
      const colleges = updateFollowing(similarColleges, id, true);
      const partnerColleges = updateFollowing(similarPartnerColleges, id, true);

      return {
        ...state,
        similarColleges: colleges,
        similarPartnerColleges: partnerColleges,
      };
    }
    case UNFOLLOW_SIMILAR_COLLEGE: {
      const {similarColleges, similarPartnerColleges} = state;
      const {id} = action;
      const colleges = updateFollowing(similarColleges, id, false);
      const partnerColleges = updateFollowing(similarPartnerColleges, id, false);

      return {
        ...state,
        similarColleges: colleges,
        similarPartnerColleges: partnerColleges,
      };
    }
    case CHANGE_TAB:
      return {
        ...state,
        tab: action.index,
      };
    case GET_OTHER_CAMPUS:
      return {
        ...state,
        college: {
          ...state.college,
          otherCampuses: unionBy(state.college.otherCampuses, [action.campus], '_id'),
        },
      };
    case UNFOLLOW_OTHER_CAMPUS:
      return {
        ...state,
        college: {
          ...state.college,
          otherCampuses: updateFollowing(state.college.otherCampuses, action.id, false),
        },
      };
    case FOLLOW_OTHER_CAMPUS:
      return {
        ...state,
        college: {
          ...state.college,
          otherCampuses: updateFollowing(state.college.otherCampuses, action.id, true),
        },
      };
    case UPDATE_HOUSEHOLD_INCOME_RANGE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          householdIncomeRange: action.householdIncomeRange,
        },
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case TOGGLE_LOADING_SCHOLARSHIPS:
      return {
        ...state,
        loadingScholarships: action.loading,
      };
    case CLEAR_COLLEGE_INFO:
      return {
        ...state,
        activeScholarshipProgram: {
          name: '',
        },
        college: {
          ...state.college,
          bannerHero: null,
          logo: null,
          name: null,
          otherCampusListingIds: null,
          otherCampuses: null,
        },
        eventIds: {
          recsShownEventId: action.recomendationShownEventId,
        },
      };
    case CHANGE_SCHOLARSHIP_PROGRAM:
      return {
        ...state,
        activeScholarshipProgram: {
          ...state.activeScholarshipProgram,
          name: action.name,
        },
      };
    case UPDATE_EARNING_STATUS:
      return {
        ...state,
        college: {
          ...state.college,
          earningRelationship: {
            statusType: action.statusType,
          },
        },
      };
    case SET_EARLY_SUBMISSION_SUBMITTING:
      return {
        ...state,
        earlySubmissionSubmitting: action.submitting,
      };
    case RECEIVE_COLLEGE_PORTAL_USER:
      if (!action.collegePortalUser.listingId) {
        return state;
      }

      return {
        ...state,
        id: action.collegePortalUser.listingId,
        userInfo: {
          type: 'CollegePortalUser',
          user: 'CollegePortalUser',
        },
      };
    case `${RECEIVE_STUDENT_COLLEGE_TAGS().type}`: {
      const studentCollegeTags = action.payload;

      return {
        ...state,
        college: {
          ...state.college,
          studentCollegeTags,
        },
      };
    }
    case `${REQUESTED_UPDATE_STUDENT_COLLEGE_TAGS().type}`: {
      const studentCollegeTags = action.payload;

      return {
        ...state,
        college: {
          ...state.college,
          studentCollegeTags,
        },
      };
    }
    default:
      return state;
  }
};

// Helper function
function updateFollowing(colleges, id, isFollowing) {
  return map(colleges, (college) => {
    if (college._id === id) {
      return {
        ...college,
        isFollowing,
      };
    }
    return college;
  });
}
