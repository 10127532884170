import {RECEIVE_OPPORTUNITY_CARD} from '../actions/opportunity-cards';

export default function detailedOpportunityCardsReducer(state = {}, action) {
  const payload = action.payload;
  switch (action.type) {
    case RECEIVE_OPPORTUNITY_CARD:
      return {
        ...state,
        [payload.opportunityCard.type]: payload.opportunityCard,
      };
    default:
      return state;
  }
}
