import React from 'react';
import PropTypes from 'prop-types';
import ReceivingMicroScholarships from './receiving-micro-scholarships';
import Card from 'src/components/Cards/Card';
import CollapsibleSection from 'src/components/collapsible-section';

const propTypes = {
  name: PropTypes.string.isRequired,
  receivingMicroScholarshipsText: PropTypes.string.isRequired,
};

const ReceivingMicroScholarshipsCard = ({name, receivingMicroScholarshipsText}) => (
  <Card>
    <CollapsibleSection
      title="Receiving Your Micro-Scholarships"
      className="college-profile-requirements-receiving"
      trackIdentifier="college-listing-requirements-receiving-your-micro-scholarships"
    >
      <div className="college-profile-card-content">
        <ReceivingMicroScholarships
          name={name}
          receivingMicroScholarshipsText={receivingMicroScholarshipsText}
        />
      </div>
    </CollapsibleSection>
  </Card>
);

ReceivingMicroScholarshipsCard.propTypes = propTypes;

export default ReceivingMicroScholarshipsCard;
