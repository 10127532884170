import React from 'react';
import classnames from 'classnames';
import {TooltipProps} from '@mui/material';

import Tooltip from 'src/components/tooltip';
import InfoIcon from 'shared/components/info-icon';

import 'stylesheets/components/shared-tooltip.scss';

interface Props {
  // Callback fired on button click.
  onClick?: () => void;
  // Size of info icon. Defaults to 16.
  iconSize?: 14 | 16;
  // An extra class name for the tooltip.
  className?: string;
  // The contents of the tooltip,
  // e.g. "<div>Includes <em>all</em> of your earnings.</div>"
  children: React.ReactNode;
  // Placement of the tooltip relative to the trigger. Defaults to bottom.
  placement?: TooltipProps['placement'];
  // Apply margin-left style. Defaults to false.
  margin?: boolean;
  blue?: boolean;
}

const InfoTooltip = ({
  onClick,
  iconSize = 16,
  className,
  children,
  placement,
  blue,
  margin = false,
}: Props) => (
  <Tooltip title={children} placement={placement}>
    <button
      onClick={onClick}
      className={classnames('shared-tooltip-button', className, {margin})}
      type="button"
      aria-label="Additional information"
      data-testid="info-tooltip"
    >
      <InfoIcon size={iconSize} blue={blue} />
    </button>
  </Tooltip>
);

export default InfoTooltip;
