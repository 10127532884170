import React, {useState, useEffect} from 'react';
import AccessibleTypeahead from 'shared/components/accessible-typeahead';
import CollegeMajorsService from 'src/services/CollegeMajorsService';

function MajorsTypeaheadFilter(props: {onSelect: (value: string) => void}): React.ReactElement {
  const [collegeMajors, setCollegeMajors] = useState<string[]>([]);

  useEffect(() => {
    CollegeMajorsService.index().then((majors) =>
      setCollegeMajors(majors.map((major) => major.name))
    );
  }, []);

  return (
    <AccessibleTypeahead
      id="majors-typeahead"
      options={collegeMajors}
      onSelect={props.onSelect}
      placeholder="Enter major"
      inputProps={{id: 'typeahead-major'}}
    />
  );
}

export default MajorsTypeaheadFilter;
