export const VERIFY_PHONE_MODAL = 'VERIFY_PHONE_MODAL';
import {openModal} from 'shared/actions/modal';
import req from 'shared/req';
import {createAction} from 'redux-actions';

export const RECEIVE_PHONE_NUMBER = createAction('RECEIVE_PHONE_NUMBER');
export const PHONE_NUMBER_VERIFIED = createAction('PHONE_NUMBER_VERIFIED');

// Returns status of verification SMS or call
export const verifyPhoneNumber = (phone_number, via_method) => (dispatch) =>
  req({
    url: '/v1/verify-phone-number',
    data: {phone_number, via_method},
    method: 'POST',
  }).then(({success}) => {
    if (success) {
      // set phone number in state
      dispatch(RECEIVE_PHONE_NUMBER(phone_number));
      return dispatch(openModal(VERIFY_PHONE_MODAL));
    }
  });
