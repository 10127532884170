import {connect} from 'react-redux';
import {noop} from 'lodash';
import TagsTypeaheadFilter from 'src/components/CollegeSearch/Filters/TagsTypeaheadFilter/TagsTypeaheadFilter';

//
import {fetchTags} from 'src/redux/collegeSearch/actions';
import {expandFilter} from 'shared/actions/filters';

const mapStateToProps = (state) => ({
  tags: state.collegeSearch.tags,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelect(tag, typeahead) {
    typeahead.clear();
    const onChange = ownProps.onChange || noop;
    dispatch(expandFilter({name: 'top-college-tags', value: tag}));
    onChange(tag);
  },
  fetchTags() {
    dispatch(fetchTags());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsTypeaheadFilter);
