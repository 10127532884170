import {EDUCATOR_CONFIRMATION_CODE_RESEND} from 'educator/action-types/email-confirmation';

const emailConfirmationModalReducer = (state = {}, action) => {
  switch (action.type) {
    case EDUCATOR_CONFIRMATION_CODE_RESEND: {
      return {...state, confirmationCodeResendSuccess: action.success};
    }
    default:
      return state;
  }
};

export default emailConfirmationModalReducer;
