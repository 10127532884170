import {camelCase} from 'lodash';
import {handleActions} from 'redux-actions';

// experiment actions
import {RECEIVE_EXPERIMENT_TREATMENT} from 'shared/actions/experiments';

export const experimentsReducer = handleActions(
  {
    [RECEIVE_EXPERIMENT_TREATMENT]: (
      {experiments},
      {payload: {experimentName, treatmentName}}
    ) => ({...experiments, [camelCase(experimentName)]: treatmentName}),
  },
  {}
);
