import PropTypes from 'prop-types';
import React from 'react';
import QuickStats from './quick-stats';
import AboutStudents from './about-students';
import AboutAdmissions from './about-admissions';
import AboutFutureSalary from './about-future-salary';
import AboutGraduationRates from './about-graduation-rates';
import AboutOverview from './AboutOverview';
import SimilarCollegesCard from 'shared/college-profile/containers/similar-colleges-card';
import AboutCostAndFinancialAid from './cost-and-financial-aid';
import AboutTestimonials from './about-testimonials/about-testimonials';

class AboutCollege extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    testimonials: PropTypes.array.isRequired,
    userInfo: PropTypes.object.isRequired,
    userState: PropTypes.string.isRequired,
    showGPASection: PropTypes.bool,
    showTestsSection: PropTypes.bool,
    showCostsAndFinancialAid: PropTypes.bool,
    showTestScores: PropTypes.bool,
  };

  state = {
    collegeHasGraduates: [
      this.props.college.fourYearGraduationRate,
      this.props.college.sixYearGraduationRate,
    ].some((rate) => rate && rate > 0),
  };

  render() {
    const {
      userInfo,
      userState,
      college,
      testimonials,
      college: {nationalCollegeData, name},
      showGPASection,
      showTestsSection,
      showCostsAndFinancialAid,
      showTestScores,
    } = this.props;

    return (
      <ul className="college-profile-panel-body" aria-label={`About ${name}`}>
        <QuickStats college={college} userInfo={userInfo} />
        <AboutOverview college={college} />
        {showCostsAndFinancialAid && (
          <AboutCostAndFinancialAid
            college={college}
            nationalCollegeData={nationalCollegeData}
            userState={userState}
            userInfo={userInfo}
          />
        )}
        <AboutStudents college={college} />
        {testimonials && testimonials.length !== 0 && (
          <AboutTestimonials testimonials={testimonials} />
        )}
        <AboutAdmissions
          college={college}
          userInfo={userInfo}
          showTestsSection={showTestsSection}
          showGPASection={showGPASection}
          showTestScores={showTestScores}
        />
        {this.state.collegeHasGraduates && (
          <div>
            <AboutGraduationRates college={college} />
            <AboutFutureSalary college={college} nationalCollegeData={nationalCollegeData} />
          </div>
        )}
        <SimilarCollegesCard />
      </ul>
    );
  }
}

export default AboutCollege;
