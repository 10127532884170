import Apptentive from 'apptentive-cordova/www/Apptentive';
import {BranchDeepLinks, BranchInitEvent} from 'capacitor-branch-deep-links';
import OneSignal from 'onesignal-cordova-plugin';
import vars from 'src/utils/vars';

export function setupLibraries(): void {
  console.log('Setting up libraries');
  Apptentive.deviceReady(
    (...args) => {
      console.log('APPTENTIVE DEVICE READY', ...args);
    },
    (...args) => {
      console.error('APPTENTIVE ERROR STARTING DEVICE', ...args);
    }
  );

  BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
    // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
    // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
    console.log(event.referringParams);
  });

  if (vars.ONESIGNAL_APP_ID) {
    OneSignal.initialize(vars.ONESIGNAL_APP_ID);

    OneSignal.Notifications.addEventListener('click', async (e) => {
      const clickData = await e.notification;
      console.log('Notification Clicked : ' + clickData);
    });
  }
}
