import {Box} from '@mui/material';
import {FormikErrors} from 'formik';
import {get} from 'lodash';
import React, {ReactElement} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import EmptyHeader from 'shared/components/empty-header';
import PublicFooter from 'src/components/PublicFooter/PublicFooter';
import ResetPasswordBaseForm from 'src/components/ResetPasswordBaseForm';
import usePlatformContext from 'src/hooks/usePlatform';
import ResetPasswordService from 'src/services/ResetPasswordService';

interface FormValues {
  password: string;
}

type ResetPasswordProps = RouteComponentProps<{resetToken: string}>;

const validate = (values: FormValues): object => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.password) {
    errors.password = 'New password is required.';
  }

  return errors;
};

const ResetPassword = (props: ResetPasswordProps): ReactElement => {
  const history = useHistory();
  const {isBasic} = usePlatformContext();

  const getResetToken = (): string => get(props, 'match.params.resetToken');

  const handleSubmit = async (values, actions): Promise<void> => {
    actions.setSubmitting(true);
    await ResetPasswordService.resetPassword({
      password: values.password,
      resetToken: getResetToken(),
    })
      .then(() => history.push('/login'))
      .catch((res) => {
        actions.setFieldError('password', res.error);
        actions.setSubmitting(false);
      });
  };

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" alignItems="center">
      {isBasic && <EmptyHeader signOutButton={false} signInButton />}
      <Box mt={20} display="flex" justifyContent="center">
        <div className="shared-font display-1">Reset your password</div>
      </Box>
      <Box display="flex" flexDirection="column" width={300} flexGrow={1}>
        <ResetPasswordBaseForm
          fieldName="password"
          handleSubmit={handleSubmit}
          validate={validate}
          placeholder="New Password"
          label="New Password"
          fieldType="password"
        />
      </Box>
      <PublicFooter />
    </Box>
  );
};

export default ResetPassword;
