import {getFeatureInteraction, postFeatureInteraction} from 'shared/actions/feature-interactions';

export const SHOW_OPT_OUT_MODAL = 'SHOW_OPT_OUT_MODAL';
export const HIDE_OPT_OUT_MODAL = 'HIDE_OPT_OUT_MODAL';

export const getOptOutModalInteraction = () => (dispatch) =>
  getFeatureInteraction('opt-out-modal')
    .then(() => {
      dispatch({type: HIDE_OPT_OUT_MODAL});
    })
    .catch(() => {
      dispatch({type: SHOW_OPT_OUT_MODAL});
    });

export const createOptOutModalInteraction = () => (dispatch) =>
  postFeatureInteraction('opt-out-modal')
    .then(() => {
      dispatch({type: HIDE_OPT_OUT_MODAL});
    })
    .catch(() => {});
