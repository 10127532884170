import classnames from 'classnames';
import {isPlainObject, pick} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {AsyncTypeahead, Highlighter, Typeahead} from 'react-bootstrap-typeahead';
import Icon from 'shared/components/icon';
import 'stylesheets/components/react-bootstrap-typeahead.scss';
import 'stylesheets/components/shared-error-icon.scss';

export default class AccessibleTypeahead extends React.Component {
  static propTypes = {
    // Unique string id used by react-bootstrap-typeahead for a11y
    id: PropTypes.string.isRequired,
    // function(selection) that handles the user selecting an option.
    onSelect: PropTypes.func.isRequired,
    // function(text) that handles the input value changing.
    onInputChange: PropTypes.func,
    // Array of options to use for the typeahead.
    // Use this if the typeahead results are available locally on the page.
    options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
    // function(query) that returns search results in a Promise (such as returned by `req` in `shared/req`).
    // Use this if the typeahead results must be requested asynchronously.
    onSearch: PropTypes.func,
    // onBlur handler for the underlying field.
    onBlur: PropTypes.func,
    // onFocus handler for the underlying field.
    onFocus: PropTypes.func,
    // placeholder text
    placeholder: PropTypes.string,
    // function(option) that returns true/false to include a result in the dropdown.
    // Alternatively, an array that specifies what fields in an options object to search.
    // No filtering is done by default.
    filterBy: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
    // The key in the option object to use in the dropdown option labels.
    labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    // The minimum number of characters required before onSearch() is invoked.
    minLength: PropTypes.number,
    // Props that should be passed directly to the `<input>` element.
    inputProps: PropTypes.object,
    // Whether to allow free text entry.
    allowNew: PropTypes.bool,
    // Whether to display a checkmark next to this field.
    showCheck: PropTypes.bool,
    // The string or object that should be currently selected.
    // Allows direct control over selection.
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    // String error message.
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    // Whether this field is required.
    required: PropTypes.bool,
    // Support passing aria-labelledby from shared Label component.
    'aria-labelledby': PropTypes.string,
    // function(option, menuProps) to allow customizing the rendering of a menu item.
    renderMenuItemChildren: PropTypes.func,
    // Other nodes to render inside the outtermost typeahead div.
    children: PropTypes.element,
    // Whether or not multiple selections are allowed.
    multiple: PropTypes.bool,
    // Whether this input is disabled
    disabled: PropTypes.bool,
    // How to render when there are no results
    renderEmptyResult: PropTypes.func,
    // function(selection) that handles the user selecting the empty result
    onSelectEmptyResult: PropTypes.func,
    // How to render while loading asynchronous results
    renderLoading: PropTypes.func,
    // Autofocus the input when the component initially mounts.
    focusOnMount: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    // Size of the typeahead. Defaults to medium
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    // (optional) Name of icon to put at the right side of the input
    postFixIcon: PropTypes.string,
    positionFixed: PropTypes.bool,
    cy: PropTypes.string,
    debounceDelay: PropTypes.number,
    className: PropTypes.string,
  };

  static defaultProps = {
    placeholder: '',
    positionFixed: false,
    labelKey: 'text',
    minLength: 2,
    focusOnMount: false,
    size: 'medium',
    renderMenuItemChildren: (option, props) => (
      <Highlighter search={props.text}>{getOptionLabel(option, props.labelKey)}</Highlighter>
    ),
  };

  constructor(props) {
    super(props);
    this.state = this.props.onSearch ? {isLoading: false, options: []} : {};
  }

  handleSearch = (query) => {
    const loadingOptions = this.props.renderLoading
      ? [{text: query, disabled: true, loading: true}]
      : [];
    this.setState({isLoading: true, options: loadingOptions});

    this.props.onSearch(query).then((options) => {
      if (this.props.renderEmptyResult && !options.length) {
        this.setState({isLoading: false, options: [{text: query, emptyResult: true}]});
      } else {
        this.setState({isLoading: false, options});
      }
    });
  };

  renderResult = (option, props, index) => {
    const {renderEmptyResult, renderLoading, renderMenuItemChildren} = this.props;

    if (option.emptyResult) {
      return renderEmptyResult({props});
    } else if (option.loading) {
      return renderLoading({props});
    } else {
      return renderMenuItemChildren(option, props, index);
    }
  };

  onSelectEmptyResult = (...args) => {
    this.props.onSelectEmptyResult(...args);
    this.setState({options: []});
    this.typeahead.blur();
  };

  handleChange = (selections) => {
    const {multiple} = this.props;

    if (multiple) {
      this.props.onSelect(selections, this.typeahead);
      this.typeahead.setState({showMenu: true});
    } else if (selections.length) {
      const selection = selections[0];
      const onSelect = selection.emptyResult ? this.onSelectEmptyResult : this.props.onSelect;
      return onSelect(selection, this.typeahead);
    }
  };

  handleBlur = (e) => {
    const {onBlur} = this.props;
    onBlur && onBlur(e);
  };

  handleFocus = (e) => {
    const {onFocus} = this.props;
    onFocus && onFocus(e);
  };

  render() {
    const {
      id,
      placeholder,
      labelKey,
      minLength,
      allowNew,
      options,
      value,
      error,
      required,
      children,
      onInputChange,
      multiple,
      disabled,
      focusOnMount,
      size,
      postFixIcon,
      className,
    } = this.props;

    const {showCheck} = this.state;

    const inputProps = this.props.inputProps || {};
    if (this.props['aria-labelledby']) {
      inputProps['aria-labelledby'] = this.props['aria-labelledby'];
    }
    if (required) {
      inputProps['aria-required'] = true;
      inputProps['required'] = true;
    }

    if (this.props.cy) {
      inputProps['data-cy'] = this.props.cy;
      inputProps['data-testid'] = this.props.cy;
    }

    let filterBy = this.props.filterBy;
    if (this.props.onSearch) {
      // Don't filter search results by default.
      filterBy = filterBy || (() => true);
    }

    const TypeaheadVariety = this.props.onSearch ? AsyncTypeahead : Typeahead;

    let selected;
    if (!value) {
      selected = [];
    } else {
      selected = multiple ? value : [value];
    }

    const additionalOptions = {};

    if (this.props.onSearch && this.props.debounceDelay) {
      additionalOptions.delay = this.props.debounceDelay;
    }

    return (
      <div className={classnames('bootstrap-typeahead-wrap', size, className, {error})}>
        <TypeaheadVariety
          id={id}
          ref={(typeahead) => (this.typeahead = typeahead)}
          autoFocus={focusOnMount}
          disabled={disabled}
          options={options}
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onInputChange={onInputChange}
          inputProps={inputProps}
          selected={selected}
          placeholder={placeholder}
          filterBy={filterBy}
          labelKey={labelKey}
          minLength={minLength}
          allowNew={allowNew}
          renderMenuItemChildren={this.renderResult}
          multiple={multiple}
          positionFixed={this.props.positionFixed}
          {...pick(this.state, ['options', 'isLoading'])}
          {...additionalOptions}
        />
        {showCheck && (
          <Icon
            className={classnames('shared-text-input-checkmark', size)}
            iconType="check"
            testid="shared-text-input-checkmark"
          />
        )}
        {postFixIcon && (
          <Icon
            className={classnames('shared-typeahead-postfix-icon', size)}
            iconType={postFixIcon}
          />
        )}
        {children}
        {error && (
          <>
            <Icon
              className={classnames('shared-error-icon', size)}
              iconType="times-r"
              testid="shared-error-icon"
            />
            <div id="text-input-error" className="shared-error-text" role="alert">
              {error}
            </div>
          </>
        )}
      </div>
    );
  }
}

// Convenience typeahead item component capable of displaying two lines of text.
export class TypeaheadItem extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    subtext: PropTypes.string.isRequired,
    cy: PropTypes.string,
  };

  render() {
    const {text, subtext} = this.props;
    return (
      <div
        className="accessible-typeahead-item"
        data-cy={this.props.cy}
        data-testid={this.props.cy}
      >
        <div className="accessible-typeahead-item-text">{text}</div>
        <div className="accessible-typeahead-item-subtext">{subtext}</div>
      </div>
    );
  }
}

// Modified version of https://github.com/ericgio/react-bootstrap-typeahead/blob/master/src/utils/getOptionLabel.js
const getOptionLabel = (option, labelKey) => {
  if (option.paginationOption || option.customOption) {
    return option[labelKey === 'string' ? labelKey : 'label'];
  }

  let optionLabel;

  if (typeof option === 'string') {
    optionLabel = option;
  }

  if (typeof labelKey === 'function') {
    // This overwrites string options, but we assume the consumer wants to do
    // something custom if `labelKey` is a function.
    optionLabel = labelKey(option);
  } else if (typeof labelKey === 'string' && isPlainObject(option)) {
    optionLabel = option[labelKey];
  }

  return optionLabel;
};
