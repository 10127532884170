import PropTypes from 'prop-types';
import React from 'react';
import linkify from 'shared/linkify';

const propTypes = {
  name: PropTypes.string.isRequired,
  receivingMicroScholarshipsText: PropTypes.string,
};

const ReceivingMicroScholarships = ({name, receivingMicroScholarshipsText}) => {
  if (receivingMicroScholarshipsText) {
    return receivingMicroScholarshipsText
      .split('\n')
      .map((value, i) => (
        <p key={i} dangerouslySetInnerHTML={{__html: linkify(value, {newTab: true})}} />
      ));
  }
  return (
    <p className="shared-college-profile-requirements">
      If you are accepted and choose to attend {name} - they will automatically include the
      Micro-Scholarships you earned from them in your financial aid package.
    </p>
  );
};

ReceivingMicroScholarships.propTypes = propTypes;

export default ReceivingMicroScholarships;
