import {forEach, isEqual} from 'lodash';
import {
  RECEIVE_POTENTIAL_PORTFOLIO_ITEMS,
  RECEIVE_POTENTIAL_PORTFOLIO_ITEM,
  REMOVE_POTENTIAL_PORTFOLIO_ITEM,
} from 'shared/registration/actions/potential-portfolio-items';

const potentialPortfolioItemsReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_POTENTIAL_PORTFOLIO_ITEMS:
      return action.potentialPortfolioItems;
    case RECEIVE_POTENTIAL_PORTFOLIO_ITEM:
      // in our use cases we only ever add an entire item, we don't update
      // so there is no need to find an existing item and merge
      return [...state, action.potentialPortfolioItem];
    case REMOVE_POTENTIAL_PORTFOLIO_ITEM: {
      const potentialPortfolioItems = [];
      const itemToRemove = action.potentialPortfolioItem;

      forEach(state, (potentialPortfolioItem) => {
        if (!isEqual(potentialPortfolioItem, itemToRemove)) {
          potentialPortfolioItems.push(potentialPortfolioItem);
        }
      });

      return potentialPortfolioItems;
    }
    default:
      return state;
  }
};

export default potentialPortfolioItemsReducer;
