import {clone} from 'lodash';
import {
  FETCH_COURSES,
  RECEIVE_COURSES,
  TOGGLE_COURSE,
  SENDING_INVITES,
  SENT_INVITES,
  OPEN_REFERRAL_MODAL,
  CLOSE_REFERRAL_MODAL,
} from '../actions';

export const READY_STATUS = 'READY_STATUS';
export const FETCHING_STATUS = 'FETCHING_STATUS';
export const DONE_STATUS = 'DONE_STATUS';
export const SENDING_STATUS = 'SENDING_STATUS';

const defaultState = {
  connectedCourses: [],
  courses: [],
  show: false,
  status: READY_STATUS,
};

export default function referralModalReducer(state = defaultState, action) {
  switch (action.type) {
    case OPEN_REFERRAL_MODAL:
      return {
        ...state,
        show: true,
      };
    case CLOSE_REFERRAL_MODAL:
      return {
        ...state,
        show: false,
      };
    case FETCH_COURSES:
      return {
        ...state,
        status: FETCHING_STATUS,
      };
    case RECEIVE_COURSES:
      return {
        ...state,
        courses: action.courses,
        status: DONE_STATUS,
      };
    case SENDING_INVITES:
      return {
        ...state,
        status: SENDING_STATUS,
      };
    case SENT_INVITES:
      return {
        ...state,
        connectedCourses: action.connectedCourses,
        courses: [],
        status: READY_STATUS,
      };
    case TOGGLE_COURSE: {
      const {index} = action;
      const courses = clone(state.courses);
      const course = courses[index];
      course.selected = !course.selected;

      return {
        ...state,
        courses,
      };
    }
    default:
      return state;
  }
}
