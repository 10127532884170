import PropTypes from 'prop-types';
import React from 'react';
import LoadingBody from '../loading-body';
import NonStudentScholarships from 'shared/college-profile/containers/non-student-scholarships';
import StudentScholarships from 'shared/college-profile/containers/student-scholarships';

class Scholarships extends React.Component {
  static propTypes = {
    nonStudent: PropTypes.bool,
    name: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    linkToScholarshipDetails: PropTypes.bool,
    collapseCardsOnMobile: PropTypes.bool,
  };

  render() {
    const {nonStudent, name, linkToScholarshipDetails, loading, collapseCardsOnMobile} = this.props;

    if (loading) {
      return <LoadingBody />;
    }

    return (
      <ul
        className="college-profile-panel-body college-profile-scholarships"
        aria-label={`Scholarships for ${name}`}
        data-testid="college-profile-scholarships"
      >
        {nonStudent ? (
          <NonStudentScholarships />
        ) : (
          <StudentScholarships
            linkToScholarshipDetails={linkToScholarshipDetails}
            collapseCardsOnMobile={collapseCardsOnMobile}
          />
        )}
      </ul>
    );
  }
}

export default Scholarships;
