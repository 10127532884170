import React from 'react';
import FilterTags from 'src/components/CollegeSearch/FilterTags';

const FilterTagsMobile = () => (
  <div className="college-search-filters-section">
    <fieldset role="group" aria-label="Applied filters">
      <legend className="college-search-filters-section-legend">
        <label className="college-search-filters-section-label" htmlFor="filter-tags">
          Filtered by:
        </label>
      </legend>
      <FilterTags />
    </fieldset>
  </div>
);

export default FilterTagsMobile;
