import {START_LOADING, STOP_LOADING, DEFAULT_IDENTIFIER} from '../actions/loading';
import {booleanReducer} from './boolean-reducer';

export function loadingReducer(state = {[DEFAULT_IDENTIFIER]: true}, action) {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        [action.identifier || DEFAULT_IDENTIFIER]: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        [action.identifier || DEFAULT_IDENTIFIER]: false,
      };
    default:
      return state;
  }
}

export default booleanReducer({
  defaultState: true,
  falseType: STOP_LOADING,
  trueType: START_LOADING,
});
