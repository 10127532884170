import React from 'react';
import Modal from 'shared/components/modal';
import InfoIcon from 'shared/components/info-icon';
import WasThisHelpfulButtons from 'shared/components/gpa-modal/was-this-helpful-buttons';

export default class AcceptanceLikelihoodExplanation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {show: false};
  }

  closeModal = () => {
    this.setState({show: false});
  };

  render() {
    const {show} = this.state;

    return (
      <span className="acceptance-likelihood-explanation">
        <button className="info-icon-button" onClick={() => this.setState({show: true})}>
          <InfoIcon size={14} />
        </button>
        <Modal
          className="acceptance-likelihood-explanation-modal"
          show={show}
          onClose={this.closeModal}
        >
          <Modal.TextHeader onClose={this.closeModal}>Understanding Your Match</Modal.TextHeader>
          <Modal.Body>
            <p className="acceptance-likelihood-explanation-modal-intro-text">
              Match means that your academic &quot;numbers&quot; (e.g., GPA, SAT/ACT) are close to
              the numbers of an average student that is accepted to a college. Keep in mind that
              colleges will look at many other facets of a student profile. The most important thing
              is to have a balanced list of reaches, likelies, and safeties.
            </p>

            <div className="acceptance-likelihood-explanation-modal-categories-section">
              <div className="acceptance-likelihood-explanation-modal-category-row clearfix">
                <div className="acceptance-likelihood-explanation-modal-category-title">Safety</div>
                <div className="acceptance-likelihood-explanation-modal-category-text">
                  You have a good chance of getting in, based on your numbers. Your numbers are at
                  or higher than the 75th percentile for students accepted by that college.
                </div>
              </div>
              <div className="acceptance-likelihood-explanation-modal-category-row clearfix">
                <div className="acceptance-likelihood-explanation-modal-category-title">Likely</div>
                <div className="acceptance-likelihood-explanation-modal-category-text">
                  You have an okay chance of getting accepted - within the 25th to 75th percentile.
                </div>
              </div>
              <div className="acceptance-likelihood-explanation-modal-category-row clearfix">
                <div className="acceptance-likelihood-explanation-modal-category-title">Reach</div>
                <div className="acceptance-likelihood-explanation-modal-category-text">
                  You have less of a chance of getting accepted - just at or slightly below the 25th
                  percentile.
                </div>
              </div>
            </div>

            <p className="acceptance-likelihood-explanation-modal-text">
              These tags are not guarantees. Just as there are students who are not accepted to
              safety schools, there are also students who are accepted to reach schools. It&apos;s
              always a good idea to work with your college counselor to consider the bigger picture.
            </p>

            <strong>
              <WasThisHelpfulButtons section="hs-match-explainer" />
            </strong>
          </Modal.Body>
        </Modal>
      </span>
    );
  }
}
