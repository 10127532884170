import PropTypes from 'prop-types';
import React from 'react';
import {sortBy, sumBy, map} from 'lodash';
import classnames from 'classnames';

class StackedBarChart extends React.Component {
  static propTypes = {
    caption: PropTypes.string.isRequired,
    // Data to populate the chart
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        value: PropTypes.number.isRequired,
        subText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      })
    ).isRequired,
    // Text that will go to the right of the
    // chart. Optional. Defaults to none;
    chartText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
    ]),
    // Number to divide by to get a percentage for the chart.
    // Optional. Defaults to sum of values from groups.
    divisor: PropTypes.number,
    // Function that formats value from groups
    // (e.g. 1000 -> $1,000). Optional.
    formatValue: PropTypes.func,
    // Defines the colors that the bart chart will
    // show. Optional. Defaults to rainbow.
    colorScheme: PropTypes.oneOf(['rainbow', 'green', 'blue']),
    // Whether or not the table values underneath the
    // chart should be sorted from highest value to lowest.
    // Optional. Defaults to true.
    sort: PropTypes.bool,
    // Footer. Optional. Defaults to none.
    footer: PropTypes.node,
    // whether or not the table has a tooltip attached to it
    // Optional. Defaults to false
    withTooltip: PropTypes.bool,
  };

  static defaultProps = {
    colorScheme: 'rainbow',
    sort: true,
  };

  static isEmptyWithProps = (props) => props.groups.length === 0;

  render() {
    const {
      caption,
      groups,
      chartText,
      formatValue,
      colorScheme,
      divisor,
      footer,
      sort,
      withTooltip,
    } = this.props;

    if (StackedBarChart.isEmptyWithProps(this.props)) return null;

    const sortedGroups = sort ? sortBy(groups, 'value').reverse() : groups;
    const divider = 100 - sumBy(groups, (group) => (group.value > 0 ? 1 : 0));
    let xShift = 0;
    return (
      <table className={classnames('stacked-bar-chart', {'with-tooltip': withTooltip})}>
        <caption className="stacked-bar-chart-caption">
          <div className="stacked-bar-chart-caption-text">{caption}</div>
          <div className="stacked-bar-chart-bar-wrapper">
            <svg className="stacked-bar-chart-bar" xmlns="http://www.w3.org/2000/svg">
              {map(sortedGroups, (group, index) => {
                const x = xShift;
                let value = (group.value / (divisor || sumBy(groups, 'value'))) * divider;

                if (value > 0) {
                  value++;
                }

                xShift += value;

                return (
                  <rect
                    key={index}
                    className={`stacked-bar-chart-color ${colorScheme}-${index}`}
                    width={`${value}%`}
                    height="100%"
                    x={`${x}%`}
                  />
                );
              })}
            </svg>
            {chartText && <span className="stacked-bar-chart-bar-text">{chartText}</span>}
          </div>
        </caption>
        <tbody>
          {map(sortedGroups, (group, index) => (
            <React.Fragment key={index}>
              <tr className={classnames('stacked-bar-chart-row', {subtext: group.subText})}>
                <td>
                  <div className={classnames('stacked-bar-chart-name', {footer})}>
                    <svg className="stacked-bar-chart-color-reference">
                      <circle
                        cx="8"
                        cy="8"
                        r="8"
                        className={`stacked-bar-chart-color ${colorScheme}-${index}`}
                      />
                    </svg>
                    <span className="stacked-bar-chart-group-name">{group.name}</span>
                  </div>
                </td>
                <td className={classnames('stacked-bar-chart-group-value', {footer})}>
                  {formatValue ? formatValue(group.value) : group.value}
                </td>
              </tr>
              {group.subText && (
                <tr>
                  <td colSpan="2" className="stacked-bar-chart-subtext">
                    {group.subText}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
        {footer && (
          <tfoot>
            <tr className="stacked-bar-chart-footer">
              <td colSpan="2" className="stacked-bar-chart-footer-data">
                {footer}
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    );
  }
}

export default StackedBarChart;
