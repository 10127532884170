import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {identity, noop} from 'lodash';
import {Slider} from '@mui/material';

import {setFilter} from 'shared/actions/filters';
import {Store} from 'src/types/store';

interface Props {
  // minValue is the minimum possible value for the range.
  minValue: number;
  // maxValue is the maximum possible value for the range.
  maxValue: number;
  // onChange is a callback({min, max}) executed when the slider's state is changed.
  onChange?: ({min, max}: {min: number; max: number}) => void;
  // step is finest amount by which the slider can increment or decrement.
  step: number;
  // if float === true, then values are converted to floating-point values instead
  // of integers.
  float?: boolean;
  // labelFunc is a function(numericValue) that returns a string to display on a label.
  labelFunc?: (arg: number) => string;
  // labelledby is the id of a label that fulfills aria-labelledby
  labelledby?: string;
  // name of field
  name: string;
}

const RangeFilter = ({
  minValue,
  maxValue,
  onChange = noop,
  step,
  float,
  labelFunc = identity,
  labelledby,
  name,
}: Props) => {
  const dispatch = useDispatch();
  const savedFilterValues = useSelector(({filters}: Store) => filters[name] || {});
  const parseFunc = float ? parseFloat : parseInt;
  const savedMin = parseFunc(savedFilterValues.min || minValue);
  const savedMax = parseFunc(savedFilterValues.max || maxValue);
  const [currentMin, setCurrentMin] = useState(savedMin);
  const [currentMax, setCurrentMax] = useState(savedMax);

  // Make sure current values update if user dismisses filter
  useEffect(() => {
    setCurrentMin(savedMin);
    setCurrentMax(savedMax);
  }, [savedMin, savedMax]);

  const updateLabels = (_e: $TSFixMe, newValue: $TSFixMe) => {
    const [min, max] = newValue;
    setCurrentMin(min);
    setCurrentMax(max);
  };

  const handleChange = (_e: $TSFixMe, newValue: $TSFixMe) => {
    const [min, max] = newValue;
    const value = {min, max};

    setCurrentMin(min);
    setCurrentMax(max);

    dispatch(setFilter({name, value}));
    onChange(value);
  };

  return (
    <div className="slider-wrap">
      {/* aria-hidden on the labels because <Slider /> handles annoucing the range */}
      <span className="slider-label-min" aria-hidden>
        {labelFunc(currentMin)}
      </span>
      <Slider
        name={name}
        value={[currentMin, currentMax]}
        onChange={updateLabels}
        onChangeCommitted={handleChange}
        step={step}
        min={minValue}
        max={maxValue}
        aria-labelledby={labelledby}
        getAriaValueText={labelFunc}
      />
      <span className="slider-label-max" aria-hidden>
        {labelFunc(currentMax)}
      </span>
    </div>
  );
};

export default RangeFilter;
