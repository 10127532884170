import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setNavigationAnnouncement} from 'shared/actions/accessibility-announcement';
import {googleSignUp, onSubmit as userSignUp} from '../../../../redux/auth/actions/user-sign-up';
import SignUp from './user-sign-up';
import {SessionStorage} from '../../../../utils/session-storage';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSubmit: (values) =>
        userSignUp(values, window.sessionStorage.getItem(SessionStorage.FOLLOW_COLLEGE_ID)),
      googleSignUp,
      setNavigationAnnouncement,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SignUp);
