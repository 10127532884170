import {connect} from 'react-redux';

// college-images
import CollegeImages from '../components/about-college/college-images';

const mapStateToProps = ({collegeProfile: {userInfo, college}}) => ({
  collegeId: college._id,
  slug: college.slug,
  userId: userInfo._id,
  universityId: college.universityId,
});

export default connect(mapStateToProps)(CollegeImages);
