import PropTypes from 'prop-types';
import React, {Component} from 'react';
import moment from 'moment';
import Icon from 'shared/components/icon';
import TextButton from 'src/components/TextButton';
import colors from 'shared/aptitude/colors';

// about-testimonial
import TestimonialAvatar from './testimonial-avatar';
import 'stylesheets/components/college-testimonial.scss';

class Testimonial extends Component {
  static propTypes = {
    testimonial: PropTypes.object.isRequired,
    showHorizontalRule: PropTypes.bool.isRequired,
    toggleCollegeTestimonialHelpfulness: PropTypes.func.isRequired,
  };

  blueIconStyle = {color: colors.b500};
  grayIconStyle = {color: colors.n600};

  toggleHelpfulness = () =>
    this.props.toggleCollegeTestimonialHelpfulness(this.props.testimonial.testimonialId);

  render() {
    const {
      testimonial: {
        firstName,
        lastName,
        major,
        year,
        graduationYear,
        submittedAt,
        body,
        avatarUrl,
        helpfulnessCount,
        userMarkedHelpful,
      },
      showHorizontalRule,
    } = this.props;

    const helpfulnessCounter = helpfulnessCount > 0 ? ` (${helpfulnessCount})` : '';

    return (
      <div className="about-testimonial-container" data-testid="about-testimonial">
        <div className="about-testimonial-student">
          <div className="about-testimonial-student-avatar">
            {avatarUrl ? <TestimonialAvatar url={avatarUrl} /> : <Icon iconType="user" />}
          </div>
          <div className="about-testimonial-student-info">
            <p className="about-testimonial-student-name">
              <strong>
                {firstName} {lastName ? lastName.charAt(0) : null}
              </strong>
            </p>
            <p>
              {(year || graduationYear) && (
                <span>
                  {year ? `${year} ` : `${yearToShorthandFormat(graduationYear)}`}
                  <strong>·</strong>
                </span>
              )}
              {` ${major}`}
            </p>
            <p>{submittedAt && moment(new Date(submittedAt)).fromNow()}</p>
          </div>
        </div>
        <div className="about-testimonial-body">
          <p>&quot;{body}&quot;</p>
        </div>
        <TextButton
          color="dark-grey"
          hoverColor="blue"
          icon={userMarkedHelpful ? 'check-circle' : 'check-circle-r'}
          iconPosition="left"
          iconStyle={userMarkedHelpful ? this.blueIconStyle : this.grayIconStyle}
          onClick={this.toggleHelpfulness}
        >
          {`Helpful ${helpfulnessCounter}`}
        </TextButton>
        {showHorizontalRule && <hr data-testid="testimonial-hr" />}
      </div>
    );
  }
}

const yearToShorthandFormat = (integerYear) => `'${integerYear.toString().substr(-2)}`;

export default Testimonial;
