import React from 'react';
import PropTypes from 'prop-types';
import 'stylesheets/public/terms-of-use.scss';

export default class Terms extends React.Component {
  static propTypes = {
    setNavigationAnnouncement: PropTypes.func,
  };

  componentDidMount() {
    const {setNavigationAnnouncement} = this.props;

    if (setNavigationAnnouncement) {
      setNavigationAnnouncement('Terms of Use');
    }
  }

  render() {
    const supportEmail = 'support@raiseme.zendesk.com';

    return (
      <div className="col-sm-8 col-sm-offset-2 policy terms-of-use">
        <p
          className="MsoNormal"
          style={{
            marginTop: '75.0pt',
            marginRight: '0in',
            marginBottom: '12.75pt',
            marginLeft: '0in',
          }}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              RAISEME TERMS OF USE
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              1.Introduction; Your Agreement to These Terms of Use
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            Welcome to RaiseMe, an online service (“RaiseMe”) owned and operated by CampusLogic,
            Inc., a Delaware corporation (“CampusLogic,” “we,” and/or “us”). RaiseMe is an online
            platform that allows prospective or current college or university students (each, a
            “Student”) to create profiles, learn about colleges or universities (each, a “College”),
            and earn micro-scholarships (“Micro-Scholarships”) from Colleges, corporations,
            non-profit organizations, and foundations (each, a “Sponsor”) based on their individual
            achievements inside and outside of the classroom. RaiseMe also allows Students to
            connect with teachers and counselors from their schools and with outside college access
            organizations with whom they have a relationship outside of the RaiseMe Services (each,
            an “Educator”), and allows Educators to connect with Colleges. For purposes of these
            Terms of Use, Students, Sponsors, Educators and other visitors to the website together
            will be referred to as “Users” and the purposes described in this paragraph will be
            referred to as the “Intended Purpose.”
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            These Terms of Use (the “Terms”) govern your use of our websites (which consist of the
            domain www.raise.me and all subdomains thereof (collectively, the “Site”), products,
            services, and software applications (the “Services”). These Terms are a binding contract
            between you and RaiseMe. You must agree to and accept all of the Terms, or you may not
            access or use the RaiseMe Site or the Services. Use of the RaiseMe website or any
            related Services shall be deemed to constitute your agreement to and acceptance of these
            Terms, and these Terms will remain in effect while you use the RaiseMe Site and the
            Services. These Terms include the terms and conditions set forth below, as well as those
            in the&nbsp;
          </span>
          <a href="https://www.raise.me/privacy-policy?webview=false">
            <span style={{fontFamily: '"Times New Roman",serif', color: '#366AFF'}}>
              Privacy Policy
            </span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            &nbsp;and&nbsp;
          </span>
          <a href="https://www.raise.me/copyright_policy?webview=false">
            <span style={{fontFamily: '"Times New Roman",serif', color: '#366AFF'}}>
              Copyright Dispute Policy
            </span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>.</span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            Please note: Section 13 of these Terms contains an arbitration clause and class action
            waiver that applies to all Users (as defined below). If you reside in the United States,
            this provision applies to all disputes with RaiseMe. If you reside outside of the United
            States, this provision applies to any action you bring against RaiseMe in the United
            States. It affects how disputes with RaiseMe are resolved. By accepting these Terms, you
            agree to be bound by this arbitration clause and class action waiver. Please read it
            carefully.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            If you have any questions, comments, or concerns regarding these Terms or the Services,
            please contact us at&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>{supportEmail}.</span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            Last Updated Date: 11/18/2020{' '}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              2.Scope of Services
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            2.1 If you choose to use the Services as a User, your relationship with CampusLogic is
            limited to being an independent user, and not an employee, agent, joint venturer or
            partner of CampusLogic for any reason, and you act exclusively on your own behalf and
            for your own benefit, and not on behalf, or for the benefit, of CampusLogic.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            2.2 The Site may contain links to third-party websites or resources (“Third-Party
            Services”). Such Third-Party Services may be subject to different terms and conditions
            and privacy practices. CampusLogic is not responsible or liable for the availability or
            accuracy of such Third-Party Services, or the content, products, or services available
            from such Third-Party Services. Links to such Third-Party Services are not an
            endorsement by CampusLogic of such Third-Party Services. Your interactions with
            third-party organizations and/or individuals found on or through the Services, including
            payment and delivery of goods or services, and any other terms, conditions, warranties,
            or representations associated with such dealings, are solely between you and such
            third-party organizations and/or individuals. You should make whatever investigation you
            feel necessary or appropriate before proceeding with any online or offline transaction
            with any of these third parties. You agree that CampusLogic shall not be responsible or
            liable for any loss or damage of any sort incurred as the result of any such dealings.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            2.3 Due to the nature of the Internet, CampusLogic cannot guarantee the continuous and
            uninterrupted availability and accessibility of the Site or the related Services.
            CampusLogic may restrict the availability of the Services or certain areas or features
            thereof, if this is necessary in view of capacity limits, the security or integrity of
            our servers, or to carry out maintenance measures that ensure the proper or improved
            functioning of the Site or the Services. CampusLogic may improve, enhance and modify the
            Services and introduce new Services from time to time.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              3.Eligibility, Using the Site, User Verification
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            3.1 In most states, you must be at least 18 years old and able to enter into legally
            binding contracts to accept and agree to these Terms. If you are not of legal age to
            form a binding contract, then you must have your parent or guardian read these terms and
            agree to them for you before you use the Services or provide any information to us.
            Please review this agreement with your parent or guardian so that you both understand
            what restrictions apply to your use of our websites and services.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            3.2 If you are agreeing to these Terms on behalf of an organization or entity, you
            represent and warrant that you are authorized to agree to these Terms on that
            organization or entity’s behalf and bind them to these Terms (in which case, the
            references to “you” and “your” in these Terms, except for in this sentence, refer to
            that organization or entity).
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            3.3 CampusLogic may make the access to and use of the Site and Services, or certain
            areas or features of the Site and Services, subject to certain conditions or
            requirements, such as completing a registration process or meeting specific quality or
            eligibility criteria. You promise to provide us with accurate, complete, and updated
            registration information about yourself. You may not register with an email address or
            phone number that you don’t have the right to use, or another person’s email address or
            phone number with the intent to impersonate that person. You may not transfer your
            account to anyone else without our prior written permission.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              4. How it Works
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            4.1&nbsp;<i>Connecting with Users</i>: Educators may only connect with Students from the
            schools or organizations that such Educators serve, and may connect with an individual
            Student only with that Student’s permission. Educators may also connect with Colleges
            and Sponsors via the Services. Students may only connect with Educators from their own
            schools and organizations, and may connect with an individual Educator only with that
            Educator’s permission. Students may elect to “follow” Sponsors via the Services by
            selecting Sponsors and clicking to “follow” those Sponsors. By clicking to “follow”
            Sponsors, Students consent and authorize the transfer of their information, profile, and
            personal data provided via RaiseMe to such Sponsors for the purpose of allowing Sponsors
            to view their profiles and provide information related to their programs and
            institution. Sponsors may only view the profiles of Students who elect to follow them.
            Permissions to connect may be modified through the “My Connections” and “Account
            Settings” sections of your account.
            <br />
            <br />
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            4.2&nbsp;<i>Qualifying for Micro-Scholarships</i>: Students can earn Micro-Scholarships
            based on the courses and achievements they add to their profiles while they are in high
            school. Students may only be awarded Micro-Scholarships from Colleges and Sponsors that
            they follow before the applicable College’s and/or Sponsor’s “Follow Deadline” for their
            class year, which is a date set by each College and Sponsor and stated on such College’s
            or Sponsor’s page on the Services. Once a College’s or Sponsor’s Follow Deadline has
            passed, Students who are seniors in high school may not continue to earn
            Micro-Scholarships from such College or Sponsor. Micro-Scholarships can be awarded
            retroactively for Students’ previous accomplishments, if expressly stated by a College
            or Sponsor. Retroactive cases could include, by way of example, when a Student adds an
            achievement accomplished in the past. Select Micro-Scholarships may only be awarded to a
            finite number of Students, as stated in the College’s or Sponsor’s award criteria.
            Certain Micro-Scholarships may have eligibility requirements that are based on a
            Student’s grade point average (“GPA”). RaiseMe calculates a Student’s GPA as displayed
            on the following page: help.raise.me/article/34-how-is-my-gpa-calculated-on-raise-me.
            RaiseMe reserves the right to verify any Student’s accomplishments. Any information
            found to be false or misleading may disqualify that Student from all Micro-Scholarships.
            In addition, the Colleges and/or Sponsors that have awarded Micro-Scholarships to that
            Student may be notified of that Student’s disqualification.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            4.3&nbsp;<i>Applying to College</i>: Students’ RaiseMe profiles do not take the place of
            any college entrance application, nor do they guarantee Students admittance to any
            college. RaiseMe does not make any guarantees, representations, or promises that the
            Services or any Micro-Scholarships will assist you in getting admitted into college,
            except that the use of the RaiseMe platform shall not reduce the likelihood of
            acceptance through a College Partner’s admissions process.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            4.4&nbsp;<i>Redeeming Micro-Scholarships</i>:
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            Unless otherwise stated by any College or Sponsor, Students have one year from the date
            of high school graduation to begin redemption of their Micro-Scholarships, unless
            otherwise specified by the College as part of their Eligibility Criteria. To be eligible
            to redeem your Micro-Scholarships with the College you ultimately attend, you must
            follow that College before its Follow Deadline for your class year (as set by the
            College and stated on the College’s page on the Services) and ensure your profile is
            complete (meaning your personal information is correct and you have submitted at least
            fifteen (15) course credits worth of grades, unless otherwise stated by the applicable
            College). If you have indicated that you are a Senior in high school, RaiseMe will
            automatically submit your profile to each College that you are following by the
            College’s Follow Deadline if your profile is complete as defined by the College’s
            minimum course credit requirement. Each College has a different Follow Deadline for your
            class year. If you do not follow a College before such Follow Deadline, the
            Micro-Scholarships earned on RaiseMe will not be included in your financial aid package
            from that College.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            If you follow a College before its Follow Deadline, meet all of the College’s
            eligibility requirements, including having a complete profile, apply before the
            College’s required deadline and are accepted to such College, your applicable
            Micro-Scholarship earnings will be included in your financial aid package from such
            College. The Micro-Scholarships you earn on RaiseMe for each particular College
            represent a minimum amount of scholarship or grant aid that the College is willing to
            include in your financial aid package if you are admitted to and attend that College. If
            you receive an even larger scholarship or grant upon admission (based on your college
            application or FAFSA), then that scholarship or grant will include or replace the
            Micro-Scholarships you have earned on RaiseMe, at the College’s sole discretion.
            Colleges or Sponsors may disburse Micro-Scholarships as part of, or separate from, any
            financial aid package.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            Each College or Sponsor controls the method through which it disburses
            Micro-Scholarships and RaiseMe is not responsible for any disputes between a Sponsor
            and/or College, and a Student regarding disbursement. Colleges and/or Sponsors may
            suspend a Student’s redemption of Micro-Scholarships if the Student is not enrolled
            full-time or does not maintain compliance with other institutional criteria (found on
            the College’s profile page). Micro-Scholarships from Colleges or Sponsors may be
            contingent on the Student’s maintaining a minimum grade point average (“GPA”). A Student
            will lose such Micro-Scholarships for the period during which his or her GPA falls below
            the minimum requirement. Such Student’s Micro-Scholarships may be restored when his or
            her GPA rises back above the minimum; however, the Student will not be reimbursed for
            the portion of Micro-Scholarships lost while the Student’s GPA was below the minimum
            requirement.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            Micro-Scholarships are split evenly across the Student’s first four years of attendance
            at the redeeming College, unless the College or Sponsor expressly states otherwise. If
            the Student completes undergraduate education in increments other than four years, it is
            at the College’s or Sponsor’s sole discretion to determine how the tuition reduction
            will be applied per year.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            The fulfillment of each Micro-Scholarship is the sole responsibility of the College or
            Sponsor awarding such Micro-Scholarship(s). RaiseMe is not and will not be responsible
            for the fulfillment of any Micro-Scholarship(s). Students can only redeem
            Micro-Scholarships from a College if they are admitted to, enroll at, and attend that
            particular school. Micro-Scholarships are remitted as a reduction to that Student’s full
            tuition bill. Colleges and Sponsors must honor all Micro-Scholarship commitments made to
            Students on RaiseMe, provided such Students are admitted to, enroll at, and attend the
            applicable College.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            4.5&nbsp;<i>Transfer Students</i>: Micro-Scholarships can be redeemed by transfer
            students unless a Sponsor expressly states otherwise on the Services. If a Student
            transfers to a College where he or she has received Micro-Scholarships, the awarding
            College shall honor the corresponding proportion of Micro-Scholarships based on a
            four-year undergraduate program, unless otherwise expressly stated by the College on the
            RaiseMe platform.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              5.Modification of These Terms
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            5.1 CampusLogic reserves the right to modify these Terms at any time in accordance with
            this provision. If we make changes to these Terms, we will post the revised Terms on the
            Site. If the changes are substantial, we will place a notice on the Site, send you an
            email, or communicate the updated Terms in some other manner. If you disagree with the
            revised Terms, you may terminate this Agreement with immediate effect and discontinue
            your use of the Site and the Services. If you do not terminate your Agreement before the
            date the revised Terms become effective, your continued access to or use of the Site
            will constitute acceptance of the revised Terms.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            5.2 Except for changes by us as described here, no other amendment or modification of
            these Terms will be effective unless in writing and signed by both you and us.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              6. Privacy
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            6.1 CampusLogic takes the privacy of its Users very seriously. For the current Privacy
            Policy, please click&nbsp;
          </span>
          <a href="https://www.raise.me/privacy-policy?webview=false">
            <span style={{fontFamily: '"Times New Roman",serif', color: '#366AFF'}}>here</span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>.</span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            6.2 If you are a Sponsor or an Educator, you may receive certain student user data in
            connection with using the Services for the Intended Purpose. By continuing to use the
            Site and Services, you represent and warrant that you will comply with all applicable
            laws and regulations, including without limitation the Family Educational Rights &amp;
            Privacy Act (FERPA), and the General Data Protection Regulation (GDPR) as an independent
            data controller with respect to European Economic Area data subjects and the related
            personal information that you may receive via use of the Services.{' '}
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            6.3 If you are an Educator or a Sponsor and you elect to provide certain data or
            personal information, including student names or email addresses, to CampusLogic for the
            purpose of facilitating Student User awareness and registration to earn
            Micro-Scholarships, then you represent and warrant to CampusLogic that you have all
            rights, authority, and consents required under applicable laws, including without
            limitation FERPA, to provide such data or personal information to CampusLogic for the
            purposes set forth in these Terms. You further acknowledge and agree that CampusLogic
            may use such data and information to contact the students regarding the Services and the
            registration process for a RaiseMe account. You are responsible for any breach of these
            representations and warranties and agree that CampusLogic shall not be held liable for
            your breach of this agreement or violation of applicable law.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            6.4 The Children’s Online Privacy Protection Act (“COPPA”) requires that online service
            providers obtain parental consent before they knowingly collect personally identifiable
            information online from children who are under 13. We do not knowingly collect or
            solicit personally identifiable information from children under 13; if you are a child
            under 13, please do not attempt to register for the RaiseMe Services or send any
            personal information about yourself to us. If we learn we have collected personal
            information from a child under 13, we will delete that information as quickly as
            possible. If you believe that a child under 13 may have provided us personal
            information, please contact us at&nbsp;
          </span>
          <a href={`mailto:${supportEmail}`}>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#366AFF'}}>
              {supportEmail}
            </span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>.</span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              7. Content
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            7.1 CampusLogic may, at its sole discretion, enable Users to (i) create, upload, post,
            send, receive and store content, such as text, photos, audio, video, or other materials
            and information on or through the Site (“User Content”); and (ii) access and view
            content that CampusLogic itself makes available on or through the Site, including
            proprietary Content and any content licensed or authorized for use by or through
            CampusLogic from a third party (“Content” and together with User Content, “Collective
            Content”).
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            7.2 The Site, Content, and User Content may in its entirety or in part be protected by
            copyright, trademark, and/or other laws of the United States and other countries. You
            acknowledge and agree that the Site and Content, including all associated intellectual
            property rights, are the exclusive property of CampusLogic and/or its licensors or
            authorized third-parties. You will not remove, alter or obscure any copyright,
            trademark, service mark or other proprietary rights notices incorporated in or
            accompanying the Site, Content or User Content. All trademarks, service marks, logos,
            trade names, and any other source identifiers of CampusLogic used on or in connection
            with the Site and Content are trademarks or registered trademarks of CampusLogic in the
            United States and abroad. Trademarks, service marks, logos, trade names and any other
            proprietary designations of third parties used on or in connection with the Site,
            Content, and/or Collective Content are used for identification purposes only and may be
            the property of their respective owners.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            7.3 You will not use, copy, adapt, modify, prepare derivative works of, distribute,
            license, sell, transfer, publicly display, publicly perform, transmit, broadcast or
            otherwise exploit the Site or Collective Content, except to the extent you are the legal
            owner of certain User Content or as expressly permitted in these Terms or in any other
            agreement entered into between you and CampusLogic. No licenses or rights are granted to
            you by implication or otherwise under any intellectual property rights owned or
            controlled by CampusLogic or its licensors, except for the licenses and rights expressly
            granted in these Terms.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            7.4 Subject to your compliance with these Terms, CampusLogic grants you a limited,
            non-exclusive, non-sublicensable, revocable, non-transferable license to (i) download
            and use the Site or any Services relating thereto on your personal device(s); and (ii)
            access and view any Collective Content made available on or through the Site and
            accessible to you, solely for your personal and non-commercial use.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            7.5 By creating, uploading, posting, sending, receiving, storing, or otherwise making
            available any User Content on or through the Site, you grant to CampusLogic a
            non-exclusive, worldwide, royalty-free, irrevocable, perpetual (or for the term of the
            protection), sub-licensable and transferable license to such User Content to access,
            use, store, copy, modify, prepare derivative works of, distribute, publish, transmit,
            stream, broadcast, and otherwise make full use of in any manner such User Content to
            provide and/or promote the Services and the Site, in any media or platform. Unless you
            provide specific consent, CampusLogic does not claim any ownership rights in any User
            Content and nothing in these Terms will be deemed to restrict any rights that you may
            have to use or exploit your User Content.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            7.6 You are solely responsible for all User Content that you make available on or
            through the Site. Accordingly, you represent and warrant that: (i) you either are the
            sole and exclusive owner of all User Content that you make available on or through the
            Site or you have all rights, licenses, consents and releases that are necessary to grant
            to CampusLogic the rights in and to such User Content, as contemplated under these
            Terms; and (ii) neither the User Content nor your posting, uploading, publication,
            submission or transmittal of the User Content or CampusLogic’s use of the User Content
            (or any portion thereof) will infringe, misappropriate or violate a third party’s
            patent, copyright, trademark, trade secret, moral rights or other proprietary or
            intellectual property rights, or rights of publicity or privacy, or result in the
            violation of any applicable law or regulation.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            7.7 You will not post, upload, publish, submit or transmit any User Content that, in
            CampusLogic’s sole and absolute discretion: (i) is fraudulent, false, misleading
            (directly or by omission or failure to update information) or deceptive; (ii) is
            defamatory, libelous, obscene, pornographic, vulgar or offensive; (iii) promotes
            discrimination, bigotry, racism, hatred, harassment or harm against any individual or
            group; (iv) is violent or threatening or promotes violence or actions that are
            threatening to any other person; (v) promotes illegal or harmful activities or
            substances; or (vi) violates any other RaiseMe or CampusLogic policy. CampusLogic may,
            without prior notice, remove or disable access to any User Content that CampusLogic
            finds to be in violation of these Terms, or otherwise may be harmful or objectionable to
            CampusLogic, its Users, third parties, or property.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            7.8 CampusLogic respects copyright law and expects its Users to do the same. If you
            believe that any content on the Site infringes copyrights you own, please notify us at{' '}
          </span>
          <a href={`mailto:${supportEmail}`}>
            <span style={{fontFamily: '"Times New Roman",serif'}}>{supportEmail}</span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>. &nbsp;</span>
          <span style={{fontFamily: '"Times New Roman",serif'}}> </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              8. Prohibited Activities
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            8.1 You are solely responsible for compliance with any and all laws, rules, regulations,
            and tax obligations that may apply to your use of the Site and the Services. In
            connection with your use of the Site and the Services, you will not and will not assist
            or enable others to:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            breach or circumvent any applicable laws or regulations, agreements with third-parties,
            third-party rights, or our Terms;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            use the Services or the Site for any purpose other than the Intended Purpose;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            use the Services, the Site or Collective Content for any commercial or other purposes
            that are not expressly permitted by these Terms or in a manner that falsely implies
            CampusLogic endorsement, partnership or otherwise misleads others as to your affiliation
            with CampusLogic;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            copy, store or otherwise access or use any information, including personally
            identifiable information about any other User, contained on the Site in any way that is
            inconsistent with these Terms or that otherwise violates the privacy rights of Users or
            third parties;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            use the Services or the Site in connection with the distribution of unsolicited
            commercial messages (“spam”);
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            discriminate against or harass anyone on the basis of race, national origin, religion,
            gender, gender identity, physical or mental disability, medical condition, marital
            status, age or sexual orientation, or otherwise engage in any abusive or disruptive
            behavior;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            use, display, mirror or frame the Site or Collective Content, or any individual element
            within the Site, CampusLogic or RaiseMe’s name, any CampusLogic or RaiseMe trademark,
            logo or other proprietary information, or the layout and design of any page or form
            contained on a page in the Site, without RaiseMe’s express written consent;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            dilute, tarnish or otherwise harm the CampusLogic or RaiseMe brand in any way, including
            through unauthorized use of Collective Content, registering and/or using RaiseMe or
            derivative terms in domain names, trade names, trademarks or other source identifiers,
            or registering and/or using domains names, trade names, trademarks or other source
            identifiers that closely imitate or are confusingly similar to RaiseMe domains,
            trademarks, taglines, promotional campaigns or Collective Content;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            use any robots, spider, crawler, scraper or other automated means or processes to
            access, collect data or other content from or otherwise interact with the Site for any
            purpose;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to
            circumvent any technological measure implemented by CampusLogic or any of CampusLogic’s
            providers or any other third party to protect the Services or the Site;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            run Maillist, Listserv, any form of auto-responder or “spam” on the Site or the
            Services, or any processes that run or are activated while you are not logged into the
            Site or the Services, or that otherwise interfere with the proper working of the
            Services (including by placing an unreasonable load on the Site’s infrastructure);
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            copy or store any significant portion of the Content;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            use the Site or the Services in a manner unrelated or disruptive to the Services;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            attempt to decipher, decompile, disassemble or reverse engineer any of the software used
            to provide the Services or the Site;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            take any action that damages or adversely affects, or could damage or adversely affect
            the performance or proper functioning of the Services or the Site;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            violate or infringe anyone else’s rights or otherwise cause harm to anyone.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            8.2 You acknowledge that CampusLogic has no obligation to monitor the access to or use
            of the Site by any User or to review, disable access to, or edit any User Content, but
            has the right to do so to (i) operate, secure and improve the Site (including without
            limitation for fraud prevention, risk assessment, investigation and customer support
            purposes); (ii) ensure Users’ compliance with these Terms; (iii) comply with applicable
            law or the order or requirement of a court, law enforcement or other administrative
            agency or governmental body; (iv) respond to User Content that it determines is harmful
            or objectionable; or (v) as otherwise set forth in these Terms. Users agree to cooperate
            with and assist CampusLogic in good faith, and to provide CampusLogic with such
            information and take such actions as may be reasonably requested by CampusLogic with
            respect to any investigation undertaken by CampusLogic or a representative of
            CampusLogic regarding the use or abuse of the Site.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            8.3 If you feel that any User you interact with, whether online or in person, is acting
            or has acted inappropriately, including but not limited to anyone who (i) engages in
            offensive, violent or sexually inappropriate behavior, (ii) you suspect of stealing from
            you, or (iii) engages in any other disturbing conduct, you should immediately report
            such person to the appropriate authorities and then to CampusLogic by contacting us with
            your police station and report number (if available); provided that your report will not
            obligate us to take any action beyond that required by law (if any) or cause us to incur
            any liability to you.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              9.Term and Termination, Suspension and other Measures
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            9.1 Your RaiseMe account and access to the Services is subject at all times to this
            Agreement, which shall remain in effect for the duration of your use of the Services. By
            accessing the Services, you agree to be bound by this Agreement.{' '}
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            9.2 You may terminate this Agreement at any time by contacting us and providing notice
            of your intent to terminate. Termination of this Agreement will result in immediate
            termination of your RaiseMe account as further described below.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            9.3 CampusLogic may terminate this Agreement for any reason at any time, with or without
            cause, by giving you notice via email to your registered email address.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            9.4 CampusLogic may also, immediately and without notice terminate this Agreement if (i)
            you have materially breached your obligations under these Terms, (ii) you have violated
            applicable laws, regulations or third party rights, or (iii) CampusLogic believes in
            good faith that such action is reasonably necessary to protect the personal safety or
            property of CampusLogic, its Users, or third parties (for example in the case of
            fraudulent behavior of a User).
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            9.5 In addition, CampusLogic may take any of the following measures (i) to comply with
            applicable law, or the order or request of a court, law enforcement or other
            administrative agency or governmental body; (ii) if you have breached these Terms,
            applicable laws, regulations, or third party rights; (iii) if you have provided
            inaccurate, fraudulent, outdated or incomplete information during the CampusLogic
            account registration or thereafter; (iv) if CampusLogic believes in good faith that such
            action is reasonably necessary to protect the personal safety or property of
            CampusLogic, its Users, or third parties, or to prevent fraud or other illegal activity;
            (v) for any other&nbsp;<i>bona fide</i>&nbsp;reason determined by CampusLogic in a good
            faith exercise of its reasonable business judgment:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            limit your access to or use of the Site;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            temporarily or permanently revoke any special status associated with your RaiseMe
            account; or
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            temporarily or in case of severe or repeated offenses permanently suspend your RaiseMe
            account.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            9.6 When this Agreement has been terminated, you are not entitled to a restoration of
            your RaiseMe account or any of your User Content. If your access to or use of the Site
            has been limited or your RaiseMe account has been suspended or this Agreement has been
            terminated by us, you may not register a new RaiseMe account or access and use the Site
            through a RaiseMe account of another User.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            9.7 If you or we terminate this Agreement, the clauses of these Terms that reasonably
            should survive termination of the Agreement will remain in effect.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              10.Disclaimer of Warranties
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            10.1&nbsp;If you choose to use the Services, Site or Collective Content, you do so
            voluntarily and at your sole risk. THE SERVICES AND THE SITE ARE PROVIDED ON AN “AS IS”
            AND “AS AVAILABLE” BASIS. CAMPUSLOGIC EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PUPROSE AND NONINFRINGEMENT. CAMPUSLOGIC MAKES
            NO REPRESENTATION OR WARRANTY THAT THE SITE OR SERVICES WILL MEET YOUR EXPECTATIONS OR
            REQUIREMENTS OR BE UNINTERRUPTED OR ERROR-FREE OR THAT ANY RESULTS OR INFORMATION
            OBTAINED WILL BE ACCURATE, TIMELY, OR COMPLETE. CAMPUSLOGIC IS NOT RESPONSIBLE FOR THE
            FAILURE OF THE INTERNET OR ANY DATA OR NETWORK USED IN CONNECTION WITH THE SITE OR THE
            SERVICES.{' '}
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            10.2&nbsp;You agree that you have had whatever opportunity you deem necessary to
            investigate the Services, laws, rules, or regulations that may be applicable to your use
            of the Services and the Site and that you are not relying upon any statement of law or
            fact made by CampusLogic relating to such use.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            10.3 All Content and Collective Content posted on or available through the Site is
            provided for informational purposes only. It is not intended to be, and should not be
            relied upon as, legal, financial or investment advice. Users of the site seeking legal,
            financial or investment advice should consult with a licensed attorney, financial
            planner or investment advisor, respectively.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            10.4The foregoing disclaimers apply to the maximum extent permitted by law. You may have
            other statutory rights. However, the duration of statutorily required warranties, if
            any, shall be limited to the maximum extent permitted by law.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              11.Limitation of Liability
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            11.1&nbsp;You acknowledge and agree that, to the maximum extent permitted by law, the
            entire risk arising out of your access to and use of the Services, Site and Collective
            Content, your publishing or viewing of any content via the Site or any other interaction
            you have with other Users whether in person or online remains with you. NEITHER
            CAMPUSLOGIC NOR ANY OF ITS AFFILIATES, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS SHALL
            BE LIABLE FOR ANY INCIDENTAL, SPECIAL, INDIRECT, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL
            DAMAGES, OR FOR LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION,
            COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR
            ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN
            CONNECTION WITH (I) THESE TERMS, (II) FROM THE USE OF OR INABILITY TO USE THE SERVICES,
            SITE OR COLLECTIVE CONTENT, OR (III) FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS
            WITH OTHER USERS OR OTHER PERSONS WITH WHOM YOU COMMUNICATE, INTERACT OR MEET WITH AS A
            RESULT OF YOUR USE OF THE SERVICES OR THE SITE, WHETHER BASED ON WARRANTY, CONTRACT,
            TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR
            NOT CAMPUSLOGIC HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED
            REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. IN NO EVENT
            WILL CAMPUSLOGIC’S AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS
            AND YOUR USE OF THE SERVICES OR THE SITE, OR FROM THE USE OF OR INABILITY TO USE THE
            SERVICES, SITE OR COLLECTIVE CONTENT AND IN CONNECTION THEREWITH, EXCEED ONE THOUSAND
            U.S. DOLLARS (US$1,000) THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
            ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN CAMPUSLOGIC AND YOU. SOME JURISDICTIONS DO
            NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
            DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            11.2 CampusLogic shall not be responsible for any higher education decisions, for
            whatever reason made, made by Users of the Services or by third parties by virtue of any
            User’s use of the Services. RaiseMe cannot guarantee and does not promise any specific
            results from use of the Services. No advice or information, whether oral or written,
            obtained by a User from RaiseMe or through or from the Services shall create any
            warranty not expressly stated herein.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            11.3 If there is a dispute between participants on the Site, or between Users and any
            third party, you agree that CampusLogic is under no obligation to become involved. In
            the event that you have a dispute with one or more other Users, you release RaiseMe, its
            officers, employees, agents, and successors from claims, demands, and damages of every
            kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed,
            arising out of or in any way related to such disputes and/or our Services.{' '}
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              If you are a California resident, you shall and hereby do waive California Civil Code
              Section 1542, which says: “A general release does not extend to claims which the
              creditor or releasing party does not know or suspect to exist in his or her favor at
              the time of executing the release and that, if known by him or her would have
              materially affected his or her settlement with the debtor or released party.”
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              12. Indemnification
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            12.1 You agree to release, defend (at CampusLogic’s option), indemnify, and hold
            CampusLogic and its affiliates and subsidiaries, and their officers, directors,
            employees and agents, harmless from and against any claims, liabilities, damages,
            losses, and expenses, including, without limitation, reasonable legal and accounting
            fees, arising out of or in any way connected with (i) your breach of these Terms, (ii)
            your improper use of the Site or any Services, (iii) your interaction with any User,
            including without limitation any injuries, losses or damages (whether compensatory,
            direct, incidental, consequential or otherwise) of any kind arising in connection with
            or as a result of such interaction or use, (iv) your breach of any laws, regulations or
            third party rights, including privacy laws and rights; or (v) your breach of your
            representations and warranties to CampusLogic in these Terms.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              13. Dispute Resolution and Arbitration Agreement
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.1 This Dispute Resolution and Arbitration Agreement shall apply if you (i) reside in
            the United States; or (ii) do not reside in the United States, but bring any claim
            against CampusLogic in the United States (to the extent not in conflict with Sections 11
            or 12).
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.2&nbsp;<i>Overview of Dispute Resolution Process</i>. CampusLogic is committed to
            participating in a consumer-friendly dispute resolution process. To that end, these
            Terms provide for a two-part process for individuals to whom Section 13.1 applies: (1)
            an informal negotiation directly with CampusLogic’s customer service team, and (2) a
            binding arbitration administered by the American Arbitration Association (“AAA”) using
            its specially designed Consumer Arbitration Rules (as modified by this Section 13).
            Specifically, the process provides:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            Claims can be filed with AAA online (
          </span>
          <a href="http://www.adr.org/cs/idcplg?IdcService=GET_FILE&dDocName=ADRSTAGE2034889&RevisionSelectionMethod=LatestReleased">
            <span style={{fontFamily: '"Times New Roman",serif', color: '#366AFF'}}>
              www.adr.org
            </span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>);</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            Arbitrators must be neutral and no party may unilaterally select an arbitrator;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            Arbitrators must disclose any bias, interest in the result of the arbitration, or
            relationship with any party;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            Parties retain the right to seek relief in small claims court for certain claims, at
            their option;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            The initial filing fee for the consumer is capped at $200;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            The consumer gets to elect the hearing location and can elect to participate live, by
            phone, video conference, or, for claims under $25,000, by the submission of documents;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '7.5pt',
            marginLeft: '132.5pt',
            textIndent: '-.25in',
          }}
        >
          <span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#313A42'}}>
            ·
            <span style={{font: '7.0pt "Times New Roman"'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            The arbitrator can grant any remedy that the parties could have received in court to
            resolve the party’s individual claim.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.3&nbsp;<i>Pre-Arbitration Dispute Resolution and Notification</i>. Prior to
            initiating an arbitration, you and CampusLogic each agree to notify the other party of
            the dispute and attempt to negotiate an informal resolution to it first. We will contact
            you at the email address you have provided to us; you can contact CampusLogic’s customer
            service team by emailing us at the contact addresses provided on the Site. If after a
            good faith effort to negotiate one of us feels the dispute has not and cannot be
            resolved informally, the party intending to pursue arbitration agrees to notify the
            other party via email prior to initiating the arbitration. In order to initiate
            arbitration, a claim must be filed with the AAA and the written Demand for Arbitration
            (available at&nbsp;
          </span>
          <a href="http://www.adr.org/cs/idcplg?IdcService=GET_FILE&dDocName=ADRSTAGE2034889&RevisionSelectionMethod=LatestReleased">
            <span style={{fontFamily: '"Times New Roman",serif', color: '#366AFF'}}>
              www.adr.org
            </span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            ) provided to the other party, as specified in the AAA Rules.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.4&nbsp;<i>AGREEMENT TO ARBITRATE</i>. YOU AND CAMPUSLOGIC MUTUALLY AGREE THAT ANY
            DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO THESE TERMS OR THE BREACH,
            TERMINATION, ENFORCEMENT OR INTERPRETATION THEREOF, OR TO THE USE OF THE SITE, ANY
            RAISEME SERVICE OR THE COLLECTIVE CONTENT (COLLECTIVELY, “DISPUTES”) WILL BE SETTLED BY
            BINDING ARBITRATION (THE “ARBITRATION AGREEMENT”). IF THERE IS A DISPUTE ABOUT WHETHER
            THIS ARBITRATION AGREEMENT CAN BE ENFORCED OR APPLIES TO OUR DISPUTE, YOU AND
            CAMPUSLOGIC AGREE THAT THE ARBITRATOR WILL DECIDE THAT ISSUE.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.5&nbsp;<i>Exceptions to Arbitration Agreement</i>. You and CampusLogic each agree
            that the following claims are exceptions to the Arbitration Agreement and will be
            brought in a judicial proceeding in a court of competent jurisdiction: (i) Any claim
            related to actual or threatened infringement, misappropriation or violation of a party’s
            copyrights, trademarks, trade secrets, patents, or other intellectual property rights;
            (ii) Any claim seeking emergency injunctive relief based on exigent circumstances (e.g.,
            imminent danger or commission of a crime, hacking, cyber-attack).
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.6&nbsp;<i>Arbitration Rules and Governing Law</i>. This Arbitration Agreement
            evidences a transaction in interstate commerce and thus the Federal Arbitration Act
            governs the interpretation and enforcement of this provision. The arbitration will be
            administered by AAA in accordance with the Consumer Arbitration Rules (the “AAA Rules“)
            then in effect, except as modified here. The AAA Rules are available at&nbsp;
          </span>
          <a href="http://www.adr.org/">
            <span style={{fontFamily: '"Times New Roman",serif', color: '#366AFF'}}>
              www.adr.org
            </span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            &nbsp;or by calling the AAA at 1–800–778–7879.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.7&nbsp;<i>Modification to AAA Rules - Arbitration Hearing/Location</i>. In order to
            make the arbitration most convenient to you, CampusLogic agrees that any required
            arbitration hearing may be conducted , at your option, (a) in the county where you
            reside; (b) in San Francisco County; (c) in any other location to which you and
            CampusLogic both agree; (d) via phone or video conference; or (e) for any claim or
            counterclaim under $25,000, by solely the submission of documents to the arbitrator.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.8&nbsp;<i>Arbitrator’s Decision</i>. The arbitrator’s decision will include the
            essential findings and conclusions upon which the arbitrator based the award. Judgment
            on the arbitration award may be entered in any court with proper jurisdiction. The
            arbitrator may award declaratory or injunctive relief only on an individual basis and
            only to the extent necessary to provide relief warranted by the claimant’s individual
            claim.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.9&nbsp;<i>JURY TRIAL WAIVER</i>. YOU AND CAMPUSLOGIC ACKNOWLEDGE AND AGREE THAT WE
            ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY AS TO ALL ARBITRABLE DISPUTES.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.10&nbsp;<i>NO CLASS ACTIONS OR REPRESENTATIVE PROCEEDINGS</i>. YOU AND CAMPUSLOGIC
            ACKNOWLEDGE AND AGREE THAT WE ARE EACH WAIVING THE RIGHT TO PARTICIPATE AS A PLAINTIFF
            OR CLASS USER IN ANY PURPORTED CLASS ACTION LAWSUIT, CLASS-WIDE ARBITRATION, PRIVATE
            ATTORNEY-GENERAL ACTION, OR ANY OTHER REPRESENTATIVE PROCEEDING AS TO ALL DISPUTES.
            FURTHER, UNLESS YOU AND CAMPUSLOGIC BOTH OTHERWISE AGREE IN WRITING, THE ARBITRATOR MAY
            NOT CONSOLIDATE MORE THAN ONE PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM
            OF ANY CLASS OR REPRESENTATIVE PROCEEDING. IF THIS PARAGRAPH IS HELD UNENFORCEABLE WITH
            RESPECT TO ANY DISPUTE, THEN THE ENTIRETY OF THE ARBITRATION AGREEMENT WILL BE DEEMED
            VOID WITH RESPECT TO SUCH DISPUTE.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.11&nbsp;<i>Severability</i>. Except as provided in Section 13.10, in the event that
            any portion of this Arbitration Agreement is deemed illegal or unenforceable, such
            provision shall be severed and the remainder of the Arbitration Agreement shall be given
            full force and effect.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.12&nbsp;<i>Changes</i>. Notwithstanding the provisions of Section 5 (“Modification of
            These Terms”), if CampusLogic changes this Section 13 (“Dispute Resolution and
            Arbitration Agreement”) after the date you last accepted these Terms (or accepted any
            subsequent changes to these Terms), you may reject any such change by sending us written
            notice (including by email) within thirty (30) days of the date such change became
            effective. By rejecting any change, you are agreeing that you will arbitrate any Dispute
            between you and CampusLogic in accordance with the provisions of the “Dispute Resolution
            and Arbitration Agreement” section as of the date you last accepted these Terms (or
            accepted any subsequent changes to these Terms).
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            13.13&nbsp;<i>Survival</i>. Except as provided in Section 13.10, this Section 13 will
            survive any termination of these Terms and will continue to apply even if you stop using
            the Site or terminate your RaiseMe account.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              14. Feedback
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            14.1 We welcome and encourage you to provide feedback, comments and suggestions for
            improvements to the Site or the Services (“Feedback”). You may submit Feedback by
            emailing us, through the&nbsp;
          </span>
          <a href="https://help.raise.me/hc/en-us/requests/new">
            <span style={{fontFamily: '"Times New Roman",serif', color: '#366AFF'}}>
              “Contact” section
            </span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            &nbsp;of the Site, or by other means of communication. Any Feedback you submit to us
            will be considered non-confidential and non-proprietary to you. By submitting Feedback
            to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable,
            sub-licensable, perpetual license to use and publish those ideas and materials for any
            purpose, without compensation to you.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              15. SMS Text Messaging Terms
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            15.1 In connection with using the Site and the Services, you may have the opportunity to
            consent to receive SMS text messages from CampusLogic, Sponsors, Educators and/or other
            Students and Users. If and when you opt-in to receive SMS messages from CampusLogic, we
            will send you an SMS message to confirm your signup.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            15.2 You can cancel SMS text messaging service at any time. Just text “STOP” to
            833-334-9005. After you send the message “STOP” to us, we will send you a reply message
            to confirm that you have been unsubscribed. After this, you will no longer receive
            messages from us. If you want to join again, text “UNSTOP” and we will start sending
            messages to you again.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            15.3 If at any time you have questions about the SMS text messaging service, just text
            “HELP” to 833-334-9005 or email us at&nbsp;
            {supportEmail}.
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}> </span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            After you send the message “HELP” to us, we will respond with instructions on how to use
            our SMS text messaging service as well as how to unsubscribe.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            15.4 We are able to deliver messages to the following mobile phone carriers: [AT&amp;T,
            Verizon Wireless, Sprint, T-Mobile, U.S. Cellular, Boost Mobile, MetroPCS, Virgin
            Mobile, Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass
            Cellular, Cellular One of East Central, IL (ECIT), Cellular One of Northeast
            Pennsylvania, Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat
            Wireless), Epic Touch (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility),
            Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa
            Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC
            Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas
            RSA 3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union Wireless,
            United Wireless, Viaero Wireless, and West Central (WCC or 5 Star Wireless)].
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            15.5 As always, your mobile phone carrier’s messaging and data rates may apply for any
            messages sent to you from us and to us from you. If you have any questions about your
            text plan or data plan, it is best to contact your wireless provider.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            15.6 For any questions about the SMS text messaging services available through RaiseMe
            and described here, please send an email to&nbsp;
          </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}>{supportEmail}.</span>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            {' '}
            For questions or concerns regarding privacy, please also review the current RaiseMe
            Privacy Policy, please click&nbsp;
          </span>
          <a href="https://www.raise.me/privacy-policy?webview=false">
            <span style={{fontFamily: '"Times New Roman",serif', color: '#366AFF'}}>here</span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>.</span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              16. Applicable Law and Jurisdiction
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            16.1 If you reside in the United States, these Terms will be interpreted in accordance
            with the laws of the State of Delaware and the United States of America, without regard
            to conflict-of-law provisions.{' '}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              17. General Provisions
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            17.1 Except as they may be supplemented by additional terms and conditions, policies,
            guidelines or standards, these Terms constitute the entire Agreement between CampusLogic
            and you pertaining to the subject matter hereof, and supersede any and all prior oral or
            written understandings or agreements between CampusLogic and you in relation to the
            access to and use of the Site.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            17.2 No joint venture, partnership, employment, or agency relationship exists between
            you and CampusLogic as a result of this Agreement or your use of the Site.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            17.3 These Terms do not and are not intended to confer any rights or remedies upon any
            person other than the parties.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            17.4 If any provision of these Terms is held to be invalid or unenforceable, such
            provision will be struck and will not affect the validity and enforceability of the
            remaining provisions.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            17.5 CampusLogic’s failure to enforce any right or provision in these Terms will not
            constitute a waiver of such right or provision unless acknowledged and agreed to by us
            in writing. Except as expressly set forth in these Terms, the exercise by either party
            of any of its remedies under these Terms will be without prejudice to its other remedies
            under these Terms or otherwise permitted under law.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            17.6 You may not assign, transfer or delegate this Agreement and your rights and
            obligations hereunder without CampusLogic’s prior written consent. CampusLogic may
            without restriction assign, transfer or delegate this Agreement and any rights and
            obligations hereunder, at its sole discretion, with 30 days’ prior notice. Your right to
            terminate this Agreement at any time remains unaffected.
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            17.7 Unless specified otherwise, any notices or other communications to Users permitted
            or required under this Agreement, will be in writing and given by CampusLogic via email,
            Site notification, or messaging service (including SMS).
          </span>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            17.8 If you have any questions about these Terms please email us at the contact
            information provided in the&nbsp;
          </span>
          <a href="https://help.raise.me/hc/en-us/requests/new">
            <span style={{fontFamily: '"Times New Roman",serif', color: '#366AFF'}}>
              “Contact” section
            </span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            &nbsp;of the site or at
          </span>
          <a href={`mailto:${supportEmail}`}>
            <span style={{fontFamily: '"Times New Roman",serif'}}>{supportEmail}</span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>. </span>
        </p>
        <p
          className="MsoNormal"
          style={{marginTop: '7.5pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in'}}
        >
          <b>
            <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
              18. Contact Us
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{marginBottom: '12.75pt'}}>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>
            18.1 If you have any questions or concerns regarding the RaiseMe Site or Services, or
            these Terms, please write to us at{' '}
          </span>
          <a href={`mailto:${supportEmail}`}>
            <span style={{fontFamily: '"Times New Roman",serif'}}>{supportEmail}</span>
          </a>
          <span style={{fontFamily: '"Times New Roman",serif', color: '#313A42'}}>. </span>
          <span style={{fontFamily: '"Times New Roman",serif'}}> </span>
        </p>
        <p className="MsoNormal">
          <span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span>
        </p>
      </div>
    );
  }
}
