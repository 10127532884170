import {connect} from 'react-redux';
import {dismissTag, expandFilter, setFilter} from 'shared/actions/filters';
import CollegeNameFilter from './college-name-filter';

const mapStateToProps = (state) => ({
  filters: state.filters,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {dispatch} = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    adjustCollegeNameFilter(collegeName) {
      if (collegeName && collegeName.length > 1) {
        dispatch(setFilter({name: 'college-name', value: collegeName}));
        dispatch(expandFilter({name: 'college-name', value: collegeName}));
      } else if (collegeName.length < 1) {
        dispatch(dismissTag({name: 'college-name', valueId: collegeName}));
      }
    },
  };
};

export default connect(mapStateToProps, null, mergeProps)(CollegeNameFilter);
