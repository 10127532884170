import classnames from 'classnames';
import {find} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Text from 'shared/components/text';
import {getMedia, watchMedia} from 'shared/mq';
import {
  EDUCATOR_SORT_OPTIONS,
  HS_SORT_OPTIONS,
  pluralize,
  PUBLIC_SORT_OPTIONS,
  TRANSFER_SORT_OPTIONS,
} from 'shared/utils';
import SortIcon from 'src/components/CollegeSearch/SortIcon';
import Dropdown from 'src/components/dropdown';
import TextButton from 'src/components/TextButton';
import {UserType} from 'src/types/enums';
import {PlatformContext} from 'src/components/PlatformContext';
import 'stylesheets/components/college-search/college-search-header.scss';
import classNames from 'classnames';

const sortOptions = (options) =>
  options.map((sort) => {
    const {value, type, order} = sort;

    return {value: `${type}|||${order}`, text: value, _id: `${type}|||${order}`};
  });

class Header extends React.Component {
  static propTypes = {
    collegeSearch: PropTypes.object.isRequired,
    clearFilters: PropTypes.func.isRequired,
    updateSortBy: PropTypes.func.isRequired,
    resetPage: PropTypes.func.isRequired,
    getColleges: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    updateSortOrder: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired,
  };

  static contextType = PlatformContext;

  componentDidMount() {
    this.header.addEventListener('touchmove', (e) => {
      e.preventDefault();
    });
    this.mq = watchMedia(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.mq.remove();
  }

  reloadColleges() {
    this.props.resetPage();
    this.props.getColleges();
  }

  render() {
    const {
      clearFilters,
      updateSortBy,
      openModal,
      updateSortOrder,
      userType,
      collegeSearch: {totalColleges, sortBy, sortOrder},
    } = this.props;
    const {isIonic} = this.context;
    const mq = getMedia();

    let options;
    switch (userType) {
      case UserType.HS_STUDENT:
        options = sortOptions(HS_SORT_OPTIONS);
        break;
      case UserType.CC_STUDENT:
        options = sortOptions(TRANSFER_SORT_OPTIONS);
        break;
      case UserType.EDUCATOR:
        options = sortOptions(EDUCATOR_SORT_OPTIONS);
        break;
      case UserType.PUBLIC:
        options = sortOptions(PUBLIC_SORT_OPTIONS);
        break;
      default:
        throw new Error('This user type should not be here');
    }

    const sort = find(options, {value: `${sortBy}|||${sortOrder}`});

    return (
      <div
        className={classnames('college-search-header', {
          educator: userType === UserType.EDUCATOR,
          '--ionic': isIonic,
        })}
        ref={(header) => {
          this.header = header;
        }}
      >
        <span className="college-search-header-filters">
          <Text weight={3} inline>
            Filter
          </Text>
          <TextButton
            buttonName="college-search-clear-filters"
            color="red"
            onClick={clearFilters}
            aria-label="Clear All Filters"
          >
            Clear All
          </TextButton>
        </span>
        <h1
          className={classNames('college-search-header-title', {'--ionic': isIonic})}
          aria-live="polite"
          data-testid="college-search-total-count"
        >
          <span className="shared-font bold">{totalColleges}</span>{' '}
          {pluralize(totalColleges, 'College')}
        </h1>
        <span className="college-search-header-sort">
          <span className="college-search-header-sort-by">
            {mq.SM_AND_UP && (
              <Text weight={5} inline emphasis={mq.MS_AND_DOWN}>
                Sort By
              </Text>
            )}
          </span>
          <span className={classnames('college-search-header-sort-dropdown', {mobile: isIonic})}>
            {mq.MS_AND_DOWN ? (
              <div
                className="college-search-header-mobile-sort-container"
                id="open-sort-by-ionic-picker"
                onClick={() => {
                  openModal('college-search-sort-by');
                }}
              >
                <span
                  className="college-search-header-mobile-sort"
                  aria-label={`Results sorted by ${sort?.text} click here to change sorting order`}
                >
                  {sort?.text}
                </span>
                <SortIcon className="college-search-header-mobile-sort-icon" size={20} />
              </div>
            ) : (
              <Dropdown
                onChange={(e) => {
                  const [type, order] = e.target.value.split('|||');
                  updateSortBy(type);
                  updateSortOrder(order);
                  this.reloadColleges();
                }}
                options={options}
                value={`${sortBy}|||${sortOrder}`}
              />
            )}
          </span>
        </span>
      </div>
    );
  }
}

export default Header;
