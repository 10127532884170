import req from 'shared/req';
import {errorMessage} from 'shared/actions/notifications';

export const RECEIVE_PHOTO_LISTINGS = 'RECEIVE_PHOTO_LISTINGS';
export const INCREMENT_PHOTO_LISTING_COUNTER = 'INCREMENT_PHOTO_LISTING_COUNTER';
export const RESET_PHOTO_LISTING_COUNTER = 'RESET_PHOTO_LISTING_COUNTER';
export const UPDATE_USE_RANKED_STATE = 'UPDATE_USE_RANKED_STATE';

export const fetchPhotoListings = (universityId) => (dispatch) =>
  req({
    url: `/admin/university/${universityId}/college_images`,
    method: 'get',
  })
    .then((photoListings) => {
      dispatch(() => dispatch(receivePhotoListings(photoListings)));
    })
    .catch(() => {
      dispatch(errorMessage('Oops! Something went wrong when fetching the list of photos!'));
    });

export const submitUseRanked = (universityId, useRanked) => (dispatch) =>
  req({
    url: `/admin/university/${universityId}/college_images/`,
    method: 'patch',
    data: {use_ranked: String(useRanked)},
  })
    .then((photoListings) => {
      dispatch(() => dispatch(receivePhotoListings(photoListings)));
    })
    .catch(() => {
      dispatch(errorMessage('Oops! Something went wrong when fetching the list of photos!'));
    });

export const uploadPhotoListings = (universityId, data) => (dispatch) =>
  req({
    url: `/admin/university/${universityId}/college_images`,
    method: 'patch',
    data: data,
  }).then(() => {
    dispatch(incrementPhotoListingCounter());
  });

export const deletePhotoListings = (universityId, data) => (dispatch) =>
  req({
    url: `/admin/university/${universityId}/college_images`,
    method: 'patch',
    data: {delete_image_listings: data ? [...data].map(encodeURIComponent).join(',') : ''},
  })
    .then((photoListings) => {
      dispatch(() => dispatch(receivePhotoListings(photoListings)));
    })
    .catch(() => {
      dispatch(errorMessage('Oops! Something went wrong when fetching the list of photos!'));
    });

export const incrementPhotoListingCounter = () => ({
  type: INCREMENT_PHOTO_LISTING_COUNTER,
});

export const receivePhotoListings = (photoListings) => ({
  type: RECEIVE_PHOTO_LISTINGS,
  photoListings,
});

export const resetPhotoListingCounter = () => ({
  type: RESET_PHOTO_LISTING_COUNTER,
});

export const updateUseRankedState = (useRanked) => ({
  type: UPDATE_USE_RANKED_STATE,
  useRanked,
});
