import {at, fromPairs} from 'lodash';
import {ADMISSION_LIKELIHOOD_SCORES_UPDATED} from 'high-school-student/college-earnings/actions/admission-likelihood';

export default (state = {}, action) => {
  switch (action.type) {
    case ADMISSION_LIKELIHOOD_SCORES_UPDATED:
      return {
        ...state,
        ...fromPairs(action.scores.map((score) => at(score, 'collegeListingId', 'scoreCategory'))),
      };
    default:
      return state;
  }
};
