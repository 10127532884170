import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'shared/components/icon';
import 'stylesheets/components/shared-copyable-textbox.scss';

class CopyableTextbox extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    buttonName: PropTypes.string,
    textAria: PropTypes.string,
    buttonAria: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {copied: false};
  }

  select = () => {
    this.textbox.setSelectionRange(0, this.props.text.length);
  };

  copy = () => {
    if (this.state.copied) {
      return;
    }

    this.textbox.focus();
    this.select();

    try {
      const success = document.execCommand('copy');

      if (success) {
        this.setState({copied: true});

        setTimeout(() => {
          this.setState({copied: false});
        }, 1000);
      }
    } catch (e) {
      alert('Could not copy! Please select the text and press CTRL+C');
    } finally {
      window.getSelection().removeAllRanges();
      this.copyButton.focus();
    }
  };

  render() {
    return (
      <div className="copyable-textbox">
        <input
          className="copyable-textbox-input"
          data-testid="copyable-textbox-input"
          type="text"
          readOnly="readOnly"
          aria-label={`${this.props.textAria ? this.props.textAria : 'text box'}`}
          onClick={this.select}
          ref={(c) => {
            this.textbox = c;
          }}
          value={this.props.text}
        />
        <button
          data-button-name={this.props.buttonName}
          data-segment-track={this.props.buttonName && 'button'}
          className="copyable-textbox-button"
          type="button"
          aria-label={`${this.props.buttonAria ? this.props.buttonAria : 'copy text box'}`}
          onClick={this.copy}
          ref={(c) => {
            this.copyButton = c;
          }}
        >
          {this.state.copied ? <Icon className="copyable-textbox-icon" iconType="check" /> : 'Copy'}
        </button>
      </div>
    );
  }
}

export default CopyableTextbox;
