import classnames from 'classnames';
import React, {useEffect, useRef} from 'react';

import {useModalContext} from '.';
import FooterButton, {FooterButtonType} from './footer-button';

import './footer.scss';
import {useResizeObserver} from '../../../src/hooks/useResizeObserver';

interface Props {
  // Primary call to action button, solid color style
  primaryButton: {
    centered?: boolean;
    rightAligned?: boolean;
    newTabTooltip?: boolean;
  } & FooterButtonType;
  // Secondary action button, outline style
  secondaryButton?: {
    // Setting this to true will make the secondary button rightAligned,
    // placing it immediately to the right of the primary button
    rightAligned?: boolean;
  } & FooterButtonType;
  // Tertiary action (commonly delete or cancel), link/text style
  tertiaryButton?: FooterButtonType;
  className?: string;
}

const Footer = ({primaryButton, secondaryButton, tertiaryButton, className}: Props) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const {height: footerHeight} = useResizeObserver(footerRef);
  const {setFooterHeight, bodyOverflow} = useModalContext();

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerHeight);
    }
  }, [setFooterHeight, footerHeight]);

  return (
    <div
      id="shared-modal-footer"
      ref={footerRef}
      className={classnames('shared-modal-footer', className, {overflow: bodyOverflow})}
      data-testid="shared-modal-footer"
    >
      {primaryButton && <FooterButton buttonType="primary" {...primaryButton} />}
      {secondaryButton && <FooterButton buttonType="secondary" {...secondaryButton} />}
      {((!!primaryButton && !!secondaryButton && !!tertiaryButton) ||
        secondaryButton?.rightAligned) && <div className="shared-modal-footer-spacer" />}
      {tertiaryButton && <FooterButton buttonType="tertiary" {...tertiaryButton} />}
    </div>
  );
};

export default Footer;
