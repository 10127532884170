export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const DEFAULT_IDENTIFIER = 'default';

export const startLoading = (identifier) => ({
  type: START_LOADING,
  identifier,
});

export const stopLoading = (identifier) => ({
  type: STOP_LOADING,
  identifier,
});
