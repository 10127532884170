import {keys} from 'lodash';
import {portfolioUrl} from 'shared/portfolio/utils';
import req from 'shared/req';

export const NEW_COURSE = 'NEW_COURSE';
export const RECEIVE_ATTENDANCE = 'RECEIVE_ATTENDANCE';
export const COURSE_DELETED = 'COURSE_DELETED';
export const RECEIVE_GRADES = 'RECEIVE_GRADES';
export const RECEIVE_COURSE_PRESELECTS = 'RECEIVE_COURSE_PRESELECTS';

export function deleteCourse(id) {
  return (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl(`/graded_courses/${id}`, _id),
      method: 'DELETE',
    }).then(() => {
      dispatch({
        type: COURSE_DELETED,
        id,
      });
    });
  };
}

export function saveAttendance(schoolYear, absences) {
  return (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();
    return req({
      url: portfolioUrl('/attendance', _id),
      method: 'PATCH',
      data: {
        patch: [{op: 'replace', value: absences, path: `/${schoolYear}`}],
      },
    }).then(() => {
      const attendance = {
        [schoolYear]: absences,
      };

      return dispatch(receiveAttendance(attendance));
    });
  };
}

/**
 * This function updates the attendance for a student and returns a promise
 *
 * @param attendance {object} object with keys as school years and values as number of absences (0 or 1 for 1 or more)
 * @returns {Promise} resolves with the response from the request to update the user's attendance.
 */
export const saveAttendanceForSchoolYears =
  (attendance = {}) =>
  (dispatch, getState) => {
    const {
      student: {_id},
    } = getState();

    return req({
      url: portfolioUrl('/attendance', _id),
      method: 'PATCH',
      data: {
        patch: keys(attendance).map((schoolYear) => ({
          op: 'replace',
          value: attendance[schoolYear],
          path: `/${schoolYear}`,
        })),
      },
    }).then(() => dispatch(receiveAttendance(attendance)));
  };

export function receiveAttendance(attendance) {
  return {
    type: RECEIVE_ATTENDANCE,
    attendance,
  };
}
