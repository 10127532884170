import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Separator from 'shared/components/separator';
import CommunityCollegeStudent from 'shared/img/cc-student.svg';

export default class CommunityCollegeSelection extends React.Component {
  static propTypes = {
    setNavigationAnnouncement: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.props.setNavigationAnnouncement('Sign Up - Community College Selection');
  }

  render() {
    return (
      <>
        <h1 className="auth-signup-header">
          Let&#39;s make sure we get you into the right experience.
        </h1>
        <Link to="/signup/cc-student" className="auth-signup-cc-link">
          <CommunityCollegeStudent width={this.props.size} height={this.props.size} />
          <span>
            I attend a U.S. community college, and want to transfer to a four-year college or
            university
          </span>
        </Link>
        <Separator>Or</Separator>
        <Link to="/signup/hs-student" className="auth-signup-cc-other-link">
          I&#39;m in high school and taking community college classes
        </Link>
        <Link to="/signup/not-available" className="auth-signup-cc-other-link">
          I attend a 4-year university in or outside the U.S.
        </Link>
        <a
          href="https://help.raise.me/hc/en-us/articles/115001992367-Is-RaiseMe-only-for-high-school-students"
          className="auth-shared-link center"
        >
          I&#39;m none of these
        </a>
      </>
    );
  }
}
