import PropTypes from 'prop-types';
import React from 'react';
import Filters from 'src/components/CollegeSearch/Filters';
import Modal from 'shared/components/modal';

class FiltersMobile extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    clearFilters: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    collegeSearch: PropTypes.object.isRequired,
    transfer: PropTypes.bool.isRequired,
    // Props for recommendation experiment group A
    filters: PropTypes.object,
  };

  handleExit = () => {
    const {closeModal} = this.props;
    closeModal('college-search-filters');
  };

  render() {
    const {
      show,
      clearFilters,
      collegeSearch: {totalColleges},
      filters,
      transfer,
    } = this.props;

    return (
      <Modal show={show} onClose={this.handleExit} className="college-search-mobile-filters">
        <Modal.TextHeader onClose={this.handleExit}>Filter</Modal.TextHeader>
        <Modal.Body className="college-search-mobile-filters-body">
          <Filters filters={filters} transfer={transfer} />
        </Modal.Body>
        <Modal.Footer
          primaryButton={{
            onClick: this.handleExit,
            text: `View ${totalColleges} Results`,
          }}
          tertiaryButton={{
            onClick: clearFilters,
            text: 'Clear All',
            color: 'red',
          }}
        />
      </Modal>
    );
  }
}

export default FiltersMobile;
