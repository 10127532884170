// modal
import {CLOSE_MODAL, OPEN_MODAL} from 'shared/actions/modal';

export const modalReducer = (state = {}, action) => {
  switch (action.type) {
    case CLOSE_MODAL:
      return {
        ...state,
        [action.identifier]: false,
      };
    case OPEN_MODAL:
      return {
        ...state,
        [action.identifier]: true,
      };
    default:
      return state;
  }
};
