import PropTypes from 'prop-types';
import React from 'react';
import styles from 'stylesheets/micro-scholarships.module.scss';
import OpportunityCard from '../opportunity-card';
import {CardGroupingsShape} from '../prop-types';
import JoinClubsCTACard from './join-clubs-cta-card';

export default function PopularActivities(props) {
  const {settings, cards, institutionName} = props;
  const subtitle = settings.subtitle(institutionName);
  return (
    <section className={styles.OpportunityCardSection}>
      <h4>{subtitle}</h4>
      <JoinClubsCTACard institutionName={institutionName} />
      {cards.map((card) => (
        <OpportunityCard showCTAs key={card.key} {...card} />
      ))}
    </section>
  );
}

PopularActivities.propTypes = {
  institutionName: PropTypes.string,
  settings: CardGroupingsShape.isRequired,
  cards: PropTypes.array.isRequired,
};
