import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import 'stylesheets/components/college-search/college-search-bar.scss';

class Results extends React.Component {
  static propTypes = {
    results: PropTypes.array.isRequired,
    selected: PropTypes.number.isRequired,
    handleMouseEnter: PropTypes.func.isRequired,
  };

  handleResultMouseEnter = (i) => {
    this.props.handleMouseEnter(i);
  };

  preventBlur = (e) => {
    e.preventDefault();
  };

  render() {
    const {results} = this.props;

    const resultNodes = results.map((result, i) => (
      <li key={result.slug} onMouseEnter={this.handleResultMouseEnter.bind(this, i)}>
        <Link
          to={`/edu/${result.slug}`}
          onMouseDown={this.preventBlur}
          className={classnames('nav-dropdown-item nav-search-result border ', {
            'nav-dropdown-item-selected': i === this.props.selected,
          })}
        >
          {result.name}
        </Link>
      </li>
    ));

    return <ul className="nav-dropdown nav-search-results">{resultNodes}</ul>;
  }
}

export default Results;
