import UserService from 'src/services/UserService';

let userId = null;

export const getUserId = async () => {
  if (userId) {
    return userId;
  }

  userId = (await UserService.getData(['_id']))._id;

  return userId;
};
