import {truncate} from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {onClickCard} from 'shared/micro-scholarships/actions/popular-activity';
import AvatarList from 'shared/micro-scholarships/components/avatar-list';
import {OpportunityCardShape, StudentAvatarShape} from 'shared/micro-scholarships/prop-types';
import {rangeString} from 'shared/micro-scholarships/utils';
import CtaButton from './cta-button';

export const CardContainer = (props) => (
  <div className={classnames('opportunity-card', props.className)} onClick={props.onClick}>
    {props.children}
  </div>
);

CardContainer.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export const CardHeader = (props) => {
  const {title, subtitle, studentAvatars} = props;
  const displayTitle = truncate(title, {length: 40, separator: ' ', omission: '…'});

  return (
    <div className="panel-heading hoverable">
      <h2 className="opp-card-title" title={title}>
        {displayTitle}
      </h2>
      {subtitle && <p className="opp-card-subtitle">{subtitle}</p>}
      {studentAvatars && (
        <div className="avatar-list">
          <AvatarList max={5} users={studentAvatars} />
        </div>
      )}
    </div>
  );
};

CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  studentAvatars: PropTypes.arrayOf(StudentAvatarShape),
};

const OpportunityCard = (props) => {
  const {cardInfo, minAmount, maxAmount, showCTAs, detailPagePath} = props;

  if (!cardInfo) {
    return null;
  }

  const collegesOffering = props.collegesOffering.total;

  const collegeSuffix = collegesOffering === 1 ? '' : 's';
  const containerClassName = `${cardInfo.category}-card hoverable`;
  const ariaLabel = `View ${collegesOffering} college${collegeSuffix} offering scholarships for ${rangeString(
    minAmount,
    maxAmount
  )}${cardInfo.rangeEnding} for ${cardInfo.title} (opens dialog)`;
  const onClick = () => props.onClickCard && props.onClickCard(cardInfo);

  return (
    <CardContainer className={containerClassName} onClick={onClick}>
      <Link
        aria-label={ariaLabel}
        className="panel panel-opportunity-card"
        to={{
          pathname: detailPagePath,
          state: {skipScrolling: true},
        }}
      >
        <CardHeader
          title={cardInfo.title}
          subtitle={cardInfo.subtitle}
          studentAvatars={props.studentAvatars}
        />
        <div className="panel-body hoverable course-card-lower-bar">
          <span className="range-statement">
            {rangeString(minAmount, maxAmount)}
            {cardInfo.rangeEnding}
          </span>
        </div>
        <div className="panel-footer">
          {collegesOffering}
          &nbsp;College{collegeSuffix} Offering
        </div>
      </Link>
      {showCTAs && (
        <CtaButton
          category={cardInfo.category}
          className="edit-link"
          portfolioUrl={cardInfo.portfolioUrl}
        />
      )}
    </CardContainer>
  );
};

OpportunityCard.propTypes = {
  ...OpportunityCardShape,
  onClickCard: PropTypes.func,
};

OpportunityCard.defaultProps = {
  onClickCard,
};

export default OpportunityCard;
