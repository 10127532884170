import React, {ReactElement, RefObject, useEffect, useRef} from 'react';
import PopularMajorsTable from '../popular-majors-table';
import ScholarshipCTA from 'shared/college-profile/containers/about-college/scholarship-cta';
import CollegeMap from '../college-map';
import AcademicInterestsSection from '../academic-interests-section';
import CollegeImages from 'shared/college-profile/containers/college-images';
import styles from './AboutOverview.module.scss';
import Card from 'src/components/Cards/Card';
import CollapsibleSection from 'src/components/collapsible-section';
import linkify from 'shared/linkify';
import {EducationPhase} from 'src/types/enums';
import NewTabTooltip from 'shared/components/new-tab-tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Colors from '../../../../aptitude/colors';
import {
  faXTwitter,
  faTiktok,
  faSquareFacebook,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-solid-svg-icons';

interface College {
  missionStatement: string;
  transferMissionStatement: string;
  partnerScholarships: object[];
  website: string;
  facebook: string;
  twitter: string;
  tiktok: string;
  instagram: string;
  youtube: string;
  collegeImages: object;
  name: string;
  slug: string;
  enableCampusReelVideos: boolean;
}

interface AboutOverviewProps {
  college: College;
  userType: EducationPhase;
}

export const helpers = {
  loadCampusReelScript: (slug: string): void => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://d1tzssi22em3se.cloudfront.net/campusreel_api_carousel_bloomsburg.js';
    script.async = true;
    script.onload = function (): void {
      // @ts-expect-error
      new CampusReelCarousel('campusreel', slug, 'cc1cf8c1fd43e7a4bad52d4215f01d2d');
    };
    document.body.appendChild(script);
  },
};

const AboutOverview = (props: AboutOverviewProps): ReactElement => {
  const {
    userType,
    college,
    college: {
      missionStatement,
      transferMissionStatement,
      partnerScholarships,
      website,
      facebook,
      twitter,
      tiktok,
      instagram,
      youtube,
      collegeImages,
      name,
      slug,
      enableCampusReelVideos,
    },
  } = props;

  const campusReelRef = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;

  useEffect(() => {
    if (enableCampusReelVideos && slug) {
      helpers.loadCampusReelScript(slug);
    }

    return () => {
      if (campusReelRef.current) {
        campusReelRef.current.innerHTML = '';
      }
    };
  }, [slug, enableCampusReelVideos]);

  // transferMissionStatement is only requested when the user is a transfer student
  const missionStatementText = transferMissionStatement
    ? transferMissionStatement
    : missionStatement;

  return (
    <Card>
      <CollapsibleSection title="About" trackIdentifier="college-listing-about">
        <div className="college-profile-card-about-content">
          {missionStatementText && (
            <p
              className="college-profile-card-about-mission"
              data-testid="college-profile-about-text"
              dangerouslySetInnerHTML={{__html: linkify(missionStatementText, {newTab: true})}}
            />
          )}
          <div id="campusreel" ref={campusReelRef} className={styles.campusReelCarousel} />
          <CollegeMap college={college} />
          <CollegeImages name={name} images={collegeImages} />
          {userType === EducationPhase.HIGH_SCHOOL ? <AcademicInterestsSection /> : null}
          <PopularMajorsTable college={college} />
          <ScholarshipCTA scholarships={partnerScholarships} type="visits" />
          <div className="college-profile-section-link-menu">
            {website && (
              <a
                href={website}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="College Website (opens in new tab)"
              >
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  color={Colors.b500}
                  className="icon"
                />
                College Website
              </a>
            )}
            {facebook && (
              <NewTabTooltip>
                <a
                  href={`https://www.facebook.com/${facebook}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook (opens in new tab)"
                >
                  <FontAwesomeIcon icon={faSquareFacebook} color={Colors.b500} className="icon" />
                  <span className="college-profile-social-media-name">Facebook</span>
                </a>
              </NewTabTooltip>
            )}
            {twitter && (
              <NewTabTooltip>
                <a
                  href={`https://twitter.com/${twitter}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Twitter (opens in new tab)"
                >
                  <FontAwesomeIcon icon={faXTwitter} color={Colors.b500} className="icon" />
                  <span className="college-profile-social-media-name">Twitter</span>
                </a>
              </NewTabTooltip>
            )}
            {tiktok && (
              <NewTabTooltip>
                <a
                  href={`https://tiktok.com/@${tiktok}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="TikTok (opens in new tab)"
                >
                  <FontAwesomeIcon icon={faTiktok} color={Colors.b500} className="icon" />
                  <span className="college-profile-social-media-name">TikTok</span>
                </a>
              </NewTabTooltip>
            )}
            {instagram && (
              <NewTabTooltip>
                <a
                  href={`https://www.instagram.com/${instagram}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram (opens in new tab)"
                >
                  <FontAwesomeIcon icon={faInstagram} color={Colors.b500} className="icon" />
                  <span className="college-profile-social-media-name">Instagram</span>
                </a>
              </NewTabTooltip>
            )}
            {youtube && (
              <NewTabTooltip>
                <a
                  href={youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="YouTube (opens in new tab)"
                >
                  <FontAwesomeIcon icon={faYoutube} color={Colors.b500} className="icon" />
                  <span className="college-profile-social-media-name">YouTube</span>
                </a>
              </NewTabTooltip>
            )}
          </div>
        </div>
      </CollapsibleSection>
    </Card>
  );
};

export default AboutOverview;
