import {
  find as _find,
  concat,
  every,
  filter,
  includes,
  isArray,
  isObject,
  omit,
  pickBy,
  without,
} from 'lodash';
import {
  CLEAR_FILTER,
  CLEAR_FILTERS,
  DISMISS_FILTER,
  DISMISS_TAG,
  EXPAND_FILTER,
  INITIALIZE_FILTERS,
  SET_FILTER,
} from 'shared/actions/filters';
import {withoutIndex} from 'shared/utils';

export const filtersReducer = (filters = {}, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...omit(filters, action.name),
        [action.name]: action.value,
      };
    case EXPAND_FILTER: {
      const {name, value} = action;
      const find = isObject(value) ? _find : includes;

      // If the filter already exists, return existing state.
      if (find(filters[name], value)) {
        return {...filters};
      }
      // Otherwise, add the filter to filters.
      const filterValues = concat(filters[name] || [], value);
      return {...filters, [name]: filterValues};
    }
    case CLEAR_FILTER:
      return omit(filters, action.name);
    case CLEAR_FILTERS:
      return {};
    case DISMISS_TAG: {
      const {name, valueId} = action;
      // If the filter is an array with more than one item, then just remove
      // the item within the filter corresponding to the tag.
      if (isArray(filters[name]) && filters[name].length > 1) {
        return {
          ...filters,
          [name]: withoutIndex(filters[name], valueId),
        };
      }
      // Otherwise remove the filter entirely.
      return omit(filters, name);
    }
    case DISMISS_FILTER: {
      const {name, value} = action;
      let payload;

      if (every(filters[name], isObject)) {
        payload = filter(
          filters[name],
          (filterObject) => Object.keys(filterObject)[0] !== Object.keys(value)[0]
        );
      } else {
        payload = without(filters[name], value);
      }
      if (payload.length === 0) {
        const otherFilters = pickBy(filters, (_, key) => key !== name);
        return {
          ...otherFilters,
        };
      } else {
        return {
          ...filters,
          [name]: payload,
        };
      }
    }
    case INITIALIZE_FILTERS:
      return {
        ...action.initialFilters,
      };
    default:
      return filters;
  }
};
