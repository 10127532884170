import PropTypes from 'prop-types';
import React from 'react';
import {some} from 'lodash';
import CompareSalaryAfterGraduation from './comparison-charts/compare-salary-after-graduation';
import CompareEarningAboveHighSchool from './comparison-charts/compare-earning-above-high-school';
import CompareLoanPayment from './comparison-charts/compare-loan-payment';
import Card from 'src/components/Cards/Card';
import CollapsibleSection from 'src/components/collapsible-section';

class AboutFutureSalary extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    nationalCollegeData: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
  };

  render() {
    const {college, nationalCollegeData, isOpen} = this.props;

    const isSalaryInfoAvailable = some([
      college.typicalMonthlyStudentLoanPayment,
      college.studentsEarningAboveHighSchoolGrads,
      college.salaryAfterGraduation,
    ]);

    if (!isSalaryInfoAvailable) {
      return null;
    }

    return (
      <Card>
        <CollapsibleSection
          title="Future Salary and Outcomes"
          trackIdentifier="college-listing-about-future-salary-and-outcomes"
          isOpen={isOpen}
        >
          <div className="college-profile-card-about-content">
            <CompareSalaryAfterGraduation
              college={college}
              nationalCollegeData={nationalCollegeData}
            />
            <CompareEarningAboveHighSchool
              college={college}
              nationalCollegeData={nationalCollegeData}
            />
            <CompareLoanPayment college={college} nationalCollegeData={nationalCollegeData} />
          </div>
        </CollapsibleSection>
      </Card>
    );
  }
}

export default AboutFutureSalary;
