import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import ScholarshipBadge from 'src/components/CollegeSearch/ScholarshipBadge';
import FollowButton from 'shared/components/follow-button';
import {earning} from 'shared/utils';

class CollegeListItem extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    college: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      logoUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      nickname: PropTypes.string,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      earningRelationship: PropTypes.object.isRequired,
      following: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
      bucketShownEventId: PropTypes.string,
    }).isRequired,
    followCollege: PropTypes.func.isRequired,
    unfollowCollege: PropTypes.func.isRequired,
    recsShownEventId: PropTypes.string,
    // Uses nickname over name if it exists
    useNickname: PropTypes.bool,
    showFollowButton: PropTypes.bool,
  };

  static defaultProps = {
    showFollowButton: true,
  };

  handleFollowClick = () => {
    const {
      followCollege,
      unfollowCollege,
      college: {id, following, bucketShownEventId},
    } = this.props;

    if (following) {
      unfollowCollege(id, bucketShownEventId);
    } else {
      followCollege(id, bucketShownEventId);
    }
  };

  render() {
    const {college, recsShownEventId, useNickname, children} = this.props;

    return (
      <li className="shared-college-list-item">
        <img
          src={college.logoUrl}
          className="shared-college-list-item-logo"
          alt=""
          aria-hidden="true"
        />
        <div className="shared-college-list-item-info-container">
          <Link
            to={`/edu/${college.slug}?bucketEventId=${college.bucketShownEventId}&recsShownEventId=${recsShownEventId}`}
            className="shared-college-list-item-info"
            aria-label={`${college.name} (view profile)`}
          >
            <p className="shared-college-list-item-name">
              {useNickname && college.nickname ? college.nickname : college.name}
            </p>
            <p className="shared-college-list-item-location">
              {earning(college.earningRelationship.statusType) && (
                <span>
                  <ScholarshipBadge /> &#8226;{' '}
                </span>
              )}
              {college.city}, {college.state}
            </p>
          </Link>
          {children}
          {this.props.showFollowButton && (
            <div className="shared-college-list-item-follow-button">
              <FollowButton
                collegeName={college.name}
                following={college.following}
                compact
                outline
                onClick={this.handleFollowClick}
              />
            </div>
          )}
        </div>
      </li>
    );
  }
}

export default CollegeListItem;
