// shared
import req from 'shared/req';
import {START_LOADING, STOP_LOADING, startLoading, stopLoading} from 'shared/actions/loading';

export const RECEIVE_SCHOOLS = 'DISTRICT_ADMIN/RECEIVE_SCHOOLS';
export const RECEIVE_SCHOOL = 'DISTRICT_ADMIN/RECEIVE_SCHOOL';
export const CLEAR_SCHOOL = 'DISTRICT_ADMIN/CLEAR_SCHOOL';

export const fetchSchool = (id) => (dispatch) => {
  dispatch(startLoading('school'));
  return req({
    url: `/v1/schools/${id}`,
    method: 'get',
  }).then((school) => {
    dispatch({
      type: RECEIVE_SCHOOL,
      school,
    });
    dispatch(stopLoading('school'));
  });
};

export const clearSchool = () => (dispatch) => dispatch({type: CLEAR_SCHOOL});

export const fetchSchools = () => (dispatch) => {
  const identifier = 'table';

  dispatch({
    type: START_LOADING,
    identifier,
  });

  req({
    url: '/v1/district-admins/me/schools',
    method: 'get',
  }).then((schools) => {
    dispatch({
      type: RECEIVE_SCHOOLS,
      schools,
    });

    return dispatch({
      type: STOP_LOADING,
      identifier,
    });
  });
};
