import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class HeaderContainer extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node])),
    ]).isRequired,
    logoLink: PropTypes.string,
  };

  render() {
    const {children, logoLink} = this.props;
    let LogoWrapper = 'span';
    const logoAttributes = {};

    if (logoLink) {
      logoAttributes['aria-label'] = 'RaiseMe Home';
      logoAttributes.to = logoLink;
      LogoWrapper = Link;
    }

    return (
      <div className="header-wrapper blue">
        <nav>
          <div className="nav-bar">
            <LogoWrapper className="nav-logo" {...logoAttributes}>
              <div className="logo-box"></div>
            </LogoWrapper>
            {children}
          </div>
        </nav>
      </div>
    );
  }
}

export default HeaderContainer;
