import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import browserHistory from 'shared/history';
import React, {useEffect} from 'react';
import ApplicationRoot, {ApplicationRootProps} from './ApplicationRoot';
import {AnyAction, Store} from 'redux';
import pageView from 'shared/page-view';
import {get} from 'lodash';
import {trackPage} from 'src/utils/analytics';

type ApplicationRootWithProviderProps<S, A extends AnyAction> = Partial<
  Pick<ApplicationRootProps, 'modalConfigs'>
> & {
  store: Store<S, A>;
  children: React.ReactNode;
  disableLogPageView?: boolean;
  scrollOnNavigation?: boolean;
};

const logPageView = (pathname: string): void => {
  pageView(pathname);
  trackPage(pathname);
};

export default function ApplicationRootWithProvider<S, A extends AnyAction>(
  props: ApplicationRootWithProviderProps<S, A>
): React.ReactElement {
  useEffect(() => {
    const {disableLogPageView = false, scrollOnNavigation = false} = props;

    // Trigger on first landing
    if (!disableLogPageView) {
      logPageView(location.pathname);
    }

    return browserHistory.listen(({pathname, state}) => {
      if (!disableLogPageView) {
        logPageView(pathname);
      }

      if (scrollOnNavigation && !get(state, 'skipScrolling', false)) {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }
    });
  }, []);

  return (
    <Provider store={props.store}>
      {/* @ts-expect-error: router needs to explicitly define children */}
      <Router history={browserHistory}>
        <ApplicationRoot modalConfigs={props.modalConfigs}>{props.children}</ApplicationRoot>
      </Router>
    </Provider>
  );
}
