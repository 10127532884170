import req from 'shared/req';
import {createAction} from 'redux-actions';

const requestStudentCollegeTags = (collegeID) =>
  req({
    url: `/v1/colleges/${collegeID}/tags`,
    method: 'get',
  });

const requestUpdateStudentCollegeTags = (collegeID, tagIDs) =>
  req({
    url: `/v1/colleges/${collegeID}/tags`,
    method: 'put',
    data: {tags: tagIDs},
  });

export const RECEIVE_STUDENT_COLLEGE_TAGS = createAction('RECEIVE_STUDENT_COLLEGE_TAGS');
export const REQUESTED_UPDATE_STUDENT_COLLEGE_TAGS = createAction(
  'REQUESTED_UPDATE_STUDENT_COLLEGE_TAGS'
);

export const getStudentCollegeTags = () => (dispatch, getState) => {
  const {
    collegeProfile: {
      college: {_id: collegeID},
    },
  } = getState();

  requestStudentCollegeTags(collegeID).then((studentCollegeTags) => {
    dispatch(RECEIVE_STUDENT_COLLEGE_TAGS(studentCollegeTags));
  });
};

export const updateStudentCollegeTags = (updatedTagIDs) => (dispatch, getState) => {
  const {
    collegeProfile: {
      college: {_id: collegeID},
    },
  } = getState();

  return requestUpdateStudentCollegeTags(collegeID, updatedTagIDs).then((response) =>
    dispatch(REQUESTED_UPDATE_STUDENT_COLLEGE_TAGS(response))
  );
};
