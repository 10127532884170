import {unionBy} from 'lodash';
import {
  FOLLOW_COLLEGE,
  UNFOLLOW_COLLEGE,
  LOAD_MORE_COLLEGES,
  LOAD_COLLEGES,
  RESET_PAGE,
  UPDATE_SORT_BY,
  UPDATE_SORT_ORDER,
  GET_SAVED_SORT,
  GET_USER_INFO,
  GET_CITIZEN_NOTIFICATION,
  FINISHED_LOADING,
  UPDATE_COLLEGE_RELATIONSHIP,
  RECEIVE_TAGS,
} from 'src/redux/collegeSearch/actions';

const defaultState = {
  amountLoading: false,
  filteredColleges: null,
  loading: true,
  page: 0,
  tags: [],
  totalColleges: 0,
  user: {},
};

export const collegeSearchReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FOLLOW_COLLEGE: {
      const colleges = state.filteredColleges.map((college) => {
        const {id} = action;

        if (college.id === id) {
          return {
            ...college,
            amountLoading: true,
            following: true,
          };
        }
        return college;
      });

      return {
        ...state,
        filteredColleges: colleges,
      };
    }
    case UNFOLLOW_COLLEGE: {
      const colleges = state.filteredColleges.map((college) => {
        if (college.id === action.id) {
          return {
            ...college,
            following: false,
          };
        }
        return college;
      });

      return {
        ...state,
        filteredColleges: colleges,
      };
    }
    case UPDATE_COLLEGE_RELATIONSHIP: {
      const colleges = state.filteredColleges.map((college) => {
        const {
          id,
          college: {amount, earningRelationship},
        } = action;
        if (college.id === id) {
          return {
            ...college,
            amount: amount,
            amountLoading: false,
            earningRelationship,
          };
        }
        return college;
      });

      return {
        ...state,
        filteredColleges: colleges,
      };
    }
    case LOAD_MORE_COLLEGES:
      return {
        ...state,
        filteredColleges: unionBy(state.filteredColleges, action.loadedColleges, 'id'),
        hasMore: action.hasMore,
        page: state.page + 1,
        totalColleges: action.totalColleges,
      };
    case LOAD_COLLEGES:
      return {
        ...state,
        filteredColleges: action.loadedColleges,
        hasMore: action.hasMore,
        page: 1,
        totalColleges: action.totalColleges,
      };
    case RESET_PAGE:
      return {
        ...state,
        filteredColleges: null,
        page: 0,
      };
    case UPDATE_SORT_BY:
      return {
        ...state,
        sortBy: action.sort,
      };
    case UPDATE_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.order,
      };
    case GET_SAVED_SORT: {
      const {sort, order} = action.search;
      return {
        ...state,
        sortBy: sort,
        sortOrder: order,
      };
    }
    case GET_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.info,
        },
      };
    case GET_CITIZEN_NOTIFICATION:
      return {
        ...state,
        user: {
          ...state.user,
          dismissedCitizenNotification: action.dismissed.dismissed,
        },
      };
    case FINISHED_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case RECEIVE_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    default:
      return state;
  }
};
