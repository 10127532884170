import React from 'react';
import PropTypes from 'prop-types';
import {filterColleges} from '../search';
import Results from './results';
import {UP, DOWN, ENTER, ESC, TAB} from 'shared/keys';
import 'stylesheets/components/college-search/college-search-bar.scss';
import {withRouter} from 'react-router-dom';

class Search extends React.Component {
  static propTypes = {
    setSearchBox: PropTypes.func,
    setSearching: PropTypes.func,
    fetchCollegeSearchBarData: PropTypes.func.isRequired,
    abbreviations: PropTypes.array,
    colleges: PropTypes.array,
    query: PropTypes.string.isRequired,
    results: PropTypes.array.isRequired,
    selected: PropTypes.number.isRequired,
    updateQuery: PropTypes.func.isRequired,
    receiveResults: PropTypes.func.isRequired,
    updateSelected: PropTypes.func.isRequired,
    resetSearch: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  handleMouseEnter = (i) => {
    this.props.updateSelected(i);
  };

  startSearching = () => {
    const {setSearching, colleges, query, fetchCollegeSearchBarData} = this.props;

    if (!colleges) {
      fetchCollegeSearchBarData().then(() => {
        if (query) {
          this.performQuery(query);
        }
      });
    }

    if (!setSearching) {
      return;
    }

    setSearching(true);
  };

  stopSearching = () => {
    const {setSearching, resetSearch} = this.props;

    resetSearch();

    if (!setSearching) {
      return;
    }

    setSearching(false);
  };

  handleQueryChange = (e) => {
    const query = e.target.value;
    this.performQuery(query);
  };

  performQuery = (query) => {
    const {colleges, abbreviations, updateQuery, receiveResults, updateSelected} = this.props;
    let results;

    if (colleges) {
      results = filterColleges(query, abbreviations, colleges);
    } else {
      results = [{slug: '#', name: 'Colleges loading...'}];
    }

    updateQuery(query);
    receiveResults(results);
    updateSelected(0);
  };

  moveSelectedDown = () => {
    const {selected, results, updateSelected} = this.props;

    updateSelected(Math.min(results.length - 1, selected + 1));
  };

  moveSelectedUp = () => {
    const {selected, results, updateSelected} = this.props;

    if (results.length === 0) {
      return;
    }

    updateSelected(Math.max(0, selected - 1));
  };

  handleKeyDown = (e) => {
    const key = e.which || e.keyCode;
    const shiftKey = e.shiftKey;
    const {history, selected, results} = this.props;

    switch (key) {
      case UP:
        e.preventDefault();
        this.moveSelectedUp();
        break;
      case DOWN:
        e.preventDefault();
        this.moveSelectedDown();
        break;
      case ENTER: {
        e.preventDefault();
        const current = results[selected];

        current && current.slug && history.push(`/edu/${current.slug}`);
        break;
      }
      case ESC:
        e.preventDefault();
        this.stopSearching();
        break;
      case TAB:
        if (shiftKey) {
          if (this.props.selected > 0) {
            e.preventDefault();
            this.moveSelectedUp();
          }
        } else {
          if (this.props.selected < results.length - 1) {
            e.preventDefault();
            this.moveSelectedDown();
          }
        }
        break;
    }
  };

  render() {
    const {query, results, selected, setSearchBox} = this.props;

    return (
      <>
        <input
          ref={setSearchBox}
          onChange={this.handleQueryChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.stopSearching}
          onFocus={this.startSearching}
          value={query}
          className="nav-search-input"
          type="text"
          placeholder="Search for a college..."
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          aria-label="Search for a college"
        />
        <Results results={results} selected={selected} handleMouseEnter={this.handleMouseEnter} />
      </>
    );
  }
}

export default withRouter(Search);
