import {RECEIVE_ACTIVITIES} from 'educator/action-types/activities';

const activitiesReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_ACTIVITIES: {
      return action.activities;
    }
    default:
      return state;
  }
};

export default activitiesReducer;
