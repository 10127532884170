import PropTypes from 'prop-types';
import React from 'react';
import {formatEarnings} from 'src/utils/formatters';
import InfoTooltip from 'shared/components/info-tooltip';

class EarningsDisplay extends React.Component {
  static propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    statusType: PropTypes.string.isRequired,
    isFollowing: PropTypes.bool.isRequired,
    divisor: PropTypes.number.isRequired,
    maxStudentEarningsReached: PropTypes.bool,
  };

  render() {
    const {amount, statusType, isFollowing, divisor, maxStudentEarningsReached} = this.props;

    if (amount === null) {
      return null;
    }

    const earnings = formatEarnings(amount, divisor);

    switch (statusType) {
      case 'earning':
      case 'submission_period_deferred':
      case 'earning_deadline_soon':
        if (isFollowing) {
          return (
            <div className="college-profile-earnings-display">
              <p className="college-profile-earnings-display-title">Your Earnings</p>
              <p className="college-profile-earnings-display-amount">${earnings.annualAmount()}</p>
              <p className="college-profile-earnings-display-breakdown">
                ${earnings.termDescription()}
              </p>
              {maxStudentEarningsReached && (
                <p className="college-profile-earnings-display-max-has-met">
                  Max earnings for this school met
                  <InfoTooltip>
                    Just because you’ve hit the maximum doesn’t mean you won’t potentially earn more
                    from this college if you are accepted! It just means that this college is only
                    willing to guarantee a certain amount ahead of time. Once you are accepted, the
                    college will award you at least that amount, and can always add more if they see
                    fit.
                  </InfoTooltip>
                </p>
              )}
            </div>
          );
        } else {
          return null;
        }
      case 'submitted':
        return (
          <div className="college-profile-earnings-display">
            <p className="college-profile-earnings-display-title">Your Earnings</p>
            <p className="college-profile-earnings-display-amount-submitted">
              ${earnings.annualAmount()}
            </p>
            <p className="college-profile-earnings-display-breakdown">
              ${earnings.termDescription()}
            </p>
          </div>
        );
      default:
        return null;
    }
  }
}

export default EarningsDisplay;
