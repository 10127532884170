import {RECEIVE_RECENT_STUDENTS} from 'educator/action-types/students';

const recentStudentsReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_RECENT_STUDENTS: {
      return action.recentStudents;
    }
    default:
      return state;
  }
};

export default recentStudentsReducer;
