import React, {ReactNode} from 'react';
import classnames from 'classnames';
import Icon from 'shared/components/icon';
import Tooltip from 'src/components/tooltip';
import 'stylesheets/components/shared-pill.scss';
import 'stylesheets/components/shared-tooltip.scss';

interface PillProps {
  children: ReactNode;
  color?:
    | 'gray'
    | 'dark-gray'
    | 'yellow'
    | 'orange'
    | 'brown'
    | 'blue'
    | 'cyan'
    | 'pink'
    | 'violet'
    | 'purple'
    | 'green'
    | 'white'
    | 'red';

  iconLeft?: string;
  iconRight?: string;
  onClick?: () => void;
  tooltipText?: string;
}

const Pill = ({
  children,
  color = 'gray',
  iconLeft,
  iconRight,
  onClick,
  tooltipText,
}: PillProps): React.ReactElement => {
  const PillElement = onClick ? 'button' : 'span';
  const pill = (
    <PillElement
      onClick={onClick}
      data-testid="pill-element"
      className={classnames('shared-pill', color)}
    >
      {iconLeft && <Icon className="left" iconType={iconLeft} />}
      <span className="shared-pill-text" data-testid="shared-pill-text">
        {children}
      </span>
      {iconRight && <Icon className="right" iconType={iconRight} />}
    </PillElement>
  );

  if (tooltipText) {
    return <Tooltip title={tooltipText}>{pill}</Tooltip>;
  }

  return pill;
};

export default Pill;
