import {RECIEVE_NOT_OFFERED_COLLEGE_TERMS} from 'portfolio/components/colleges-not-offering-transfer-term-modal/actions';

export const followedCollegesNextTransferTermsReducer = (state = {colleges: {}}, action) => {
  switch (action.type) {
    case RECIEVE_NOT_OFFERED_COLLEGE_TERMS:
      return {
        ...state,
        colleges: action.payload.colleges,
      };
    default:
      return state;
  }
};
