import React, {ReactElement} from 'react';
import classNames from 'classnames';
import TextInput from 'src/components/inputs/text-input';
import RemoveButton from '../RemoveButton';
import {validateMobileOrEmail} from 'shared/utils';
import {ReduxFormField} from 'src/types/form';
import styles from './student-referral-item.module.scss';

interface StudentReferralItemProps {
  contactInfo: ReduxFormField;
  placeholderText: string;
  remove: () => void;
  showRemove: boolean;
  className?: string;
}

export default function StudentReferralItem(props: StudentReferralItemProps): ReactElement {
  const validate = (e, active): void => {
    validateMobileOrEmail(e.target.value, (valid, value) => {
      props.contactInfo.onChange(value, valid, active);
    });
  };

  const onInputChange = (e): void => validate(e, true);
  const handleBlur = (e): void => validate(e, false);

  return (
    <div className={classNames(styles.item, props.className)} data-testid="student-referral-item">
      {/* @ts-expect-error: will no longer error after being moved off redux-form */}
      <TextInput
        {...props.contactInfo}
        onChange={onInputChange}
        onBlur={handleBlur}
        placeholder={props.placeholderText}
        aria-label="email or mobile"
        error="Invalid email or mobile"
      />
      {props.showRemove && <RemoveButton onRemove={props.remove} />}
    </div>
  );
}

StudentReferralItem.defaultProps = {
  placeholderText: 'Enter an email address or phone number',
};
