import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setNavigationAnnouncement} from 'shared/actions/accessibility-announcement';
import {googleSignUp, onSubmit as userSignUp} from '../../../../redux/auth/actions/user-sign-up';
import SignUp2 from './user-sign-up';
import {SessionStorage} from '../../../../utils/session-storage';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSubmit: (values) => {
        const date = new Date(values.birthday);
        values.birthdayMonth = date.getUTCMonth() + 1;
        values.birthdayDay = date.getUTCDate();
        values.birthdayYear = date.getUTCFullYear();
        delete values.birthday;

        return userSignUp(values, window.sessionStorage.getItem(SessionStorage.FOLLOW_COLLEGE_ID));
      },
      googleSignUp,
      setNavigationAnnouncement,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SignUp2);
