import React from 'react';
import classnames from 'classnames';

interface CardProps {
  height?: string | number;
  width?: string | number;
  children?: React.ReactNode | React.ReactNodeArray;
  className?: string;
  elementId?: string;
  size?: 'small' | 'medium' | 'large';
  testId?: string;
}

const Card: React.FC<CardProps> = ({
  height,
  width,
  children,
  className,
  size = 'medium',
  elementId,
  testId = 'shared-card',
}) => (
  <section
    id={elementId}
    className={classnames('shared-card', size, className)}
    style={{width, height}}
    data-testid={testId}
  >
    {children}
  </section>
);

export default Card;
