import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import {map} from 'lodash';
import Icon from 'shared/components/icon';

class StatisticsTable extends React.Component {
  static propTypes = {
    // Title of the table, pass node if tooltip/icon included
    caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    subCaption: PropTypes.string,
    // An array of objects, such that each object has name, values,
    // and an optional className to apply styling.
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        values: PropTypes.array,
        className: PropTypes.string,
      })
    ),
    // The table header row
    header: PropTypes.array,
    'aria-label': PropTypes.string,
  };

  renderHeader(header) {
    if (!header) {
      return null;
    }

    return (
      <thead className="statistics-table-header">
        <tr>
          {map(header, (title, index) => (
            <th key={index} className="statistics-table-header-title">
              {title}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  render() {
    const {rows, header, caption, className, subCaption} = this.props;

    if (rows.length === 0) {
      return null;
    }

    return (
      <table
        className={classnames('college-profile-statistics-table', className)}
        aria-label={this.props['aria-label']}
      >
        {caption && (
          <caption
            className={classnames('college-profile-statistics-table-caption', {only: !subCaption})}
          >
            {caption}
            {subCaption && (
              <div className="college-profile-statistics-table-sub-caption">{subCaption}</div>
            )}
          </caption>
        )}
        {this.renderHeader(header)}
        <tbody>
          {map(rows, (row, index) => {
            const {highlighted, name, values, className: rowClassName} = row;
            return (
              <tr key={index} className={classnames('statistics-table-row', {highlighted})}>
                <th
                  scope="row"
                  className={`statistics-table-name ${rowClassName ? rowClassName : ''}`}
                >
                  {highlighted && <Icon iconType="star" className="statistics-table-row-icon" />}
                  {name}
                </th>
                {map(values, (value, i) => (
                  <td
                    key={i}
                    className={`statistics-table-value ${rowClassName ? rowClassName : ''}`}
                  >
                    {value}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default StatisticsTable;
