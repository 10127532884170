import PropTypes from 'prop-types';
import React from 'react';
import EstimatedNetPrice from './estimated-net-price';
import EstimatedCostOfAttendance from './estimated-cost-of-attendance';
import EstimatedFinancialAid from './estimated-financial-aid';
import AverageNetPricePerHouseholdIncome from 'shared/college-profile/containers/average-net-price-per-household-income';
import Card from 'src/components/Cards/Card';
import CollapsibleTeaserSection from 'shared/components/collapsible-teaser-section';
import Icon from 'shared/components/icon';
import NewTabTooltip from '../../../../components/new-tab-tooltip';

class AboutCostAndFinancialAid extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    userState: PropTypes.string.isRequired,
    userInfo: PropTypes.object.isRequired,
  };

  render() {
    const {
      college,
      college: {financialAidWebsite, state, slug, netPriceCalculator},
      userState,
      userInfo,
    } = this.props;

    const ASU_SLUG = 'arizona-state-university-tempe';

    const inState = userState === state;

    return (
      <Card className="zero-bottom-padding">
        <CollapsibleTeaserSection
          title="Cost and Financial Aid"
          subtitle="Per Year"
          trackIdentifier="college-listing-about-cost-and-financial-aid"
          className="college-profile-card-about-content"
        >
          <CollapsibleTeaserSection.Teaser>
            <EstimatedNetPrice college={college} userInfo={userInfo} userState={userState} />
          </CollapsibleTeaserSection.Teaser>
          <CollapsibleTeaserSection.Body collapsedHeight={80}>
            <EstimatedNetPrice.Formula />
            <EstimatedCostOfAttendance college={college} userState={userState} />
            <EstimatedFinancialAid college={college} userInfo={userInfo} userState={userState} />
            <AverageNetPricePerHouseholdIncome
              college={college}
              userInfo={userInfo}
              userState={userState}
            />
            {netPriceCalculator && (
              <div className="college-profile-section-link-menu net-price-calculator">
                To calculate a more exact net price based on you and your family&#39;s personal
                information, use a{' '}
                <NewTabTooltip>
                  <a
                    href={netPriceCalculator}
                    target="_blank"
                    aria-label="Net Price Calculator (opens in new tab)"
                    rel="noreferrer"
                  >
                    Net Price Calculator <Icon iconType="external-link-alt" />.
                  </a>
                </NewTabTooltip>
              </div>
            )}
            <div className="college-profile-section-link-menu bottom-padding">
              {financialAidWebsite && (
                <a
                  href={financialAidWebsite}
                  target="_blank"
                  aria-label="Financial Aid Info (opens in new tab)"
                  rel="noreferrer"
                >
                  <Icon iconType="external-link-alt" />
                  Financial Aid
                </a>
              )}
              {slug === ASU_SLUG && inState && (
                <a
                  href={`/edu/${ASU_SLUG}/attendance_cost_estimator`}
                  aria-label="Estimated Tuition Calculator"
                >
                  <Icon iconType="tag" />
                  Est. Tuition Calculator
                </a>
              )}
            </div>
          </CollapsibleTeaserSection.Body>
        </CollapsibleTeaserSection>
      </Card>
    );
  }
}

export default AboutCostAndFinancialAid;
