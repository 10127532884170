import {some, omit} from 'lodash';
import {
  NEW_TEST_SCORE,
  EDIT_TEST_SCORE,
  CANCEL_EDIT_TEST_SCORE,
  DELETE_TEST_SCORE,
  RECEIVE_TEST_SCORE,
  SCROLLED_TO_SCORE,
  RECEIVE_TEST_SCORES,
} from 'shared/portfolio/actions/test-scores';

export function testScoresReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_TEST_SCORE:
      return {
        ...state,
        [action.id]: {
          ...action.testScore,
        },
      };
    case NEW_TEST_SCORE:
      if (some(state, 'new')) {
        return state;
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.testScore,
          },
        };
      }
    case EDIT_TEST_SCORE:
      return {
        ...state,
        [action.id]: {
          ...action.testScore,
          editing: true,
        },
      };
    case SCROLLED_TO_SCORE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          scrollTo: false,
        },
      };
    case CANCEL_EDIT_TEST_SCORE:
      if (action.testScore.new) {
        return omit(state, action.id);
      } else {
        return {
          ...state,
          [action.id]: {
            ...action.testScore,
            editing: false,
            scrollTo: true,
          },
        };
      }
    case DELETE_TEST_SCORE:
      return omit(state, action.id);
    case RECEIVE_TEST_SCORES:
      return {
        ...state,
        ...action.testScores,
      };
    default:
      return state;
  }
}
