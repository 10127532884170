/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Action,
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import {immutableBoolReducer} from '../../shared/reducers/immutable-reducers';

// @ts-expect-error
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore<State = any, A extends Action = AnyAction>(
  reducers: any,
  initialState: any = {},
  middleware: any[] = []
): Store<State, A> {
  if (initialState instanceof Function) {
    initialState = initialState();
  }

  return createStore(
    combineReducers({
      ...reducers,
      authenticated: immutableBoolReducer,
    }),
    {
      authenticated: true,
      ...initialState,
    },
    composeEnhancers(applyMiddleware(thunk, ...middleware))
  );
}
