import {Schema, arrayOf} from 'normalizr';
import {institution} from './shared';

const course = new Schema('courses', {
  idAttribute: (data) => `course-${data._id}`,
});

const grade = new Schema('grades', {
  idAttribute: (data) => `grade-${data._id}`,
});

course.define({
  grades: arrayOf(grade),
  institution: institution,
});

const courses = arrayOf(course);

export {course, courses, grade};
