import PropTypes from 'prop-types';
import React from 'react';
import 'stylesheets/components/shared-toast.scss';

class Toast extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    type: PropTypes.oneOf(['success', 'warning', 'error']),
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  getClassName = () => {
    const {type, className} = this.props;
    return `shared-toast toast-${type} ${className}`;
  };

  render() {
    return (
      <div className={this.getClassName()} role="alert">
        {this.props.children}
      </div>
    );
  }
}

export default Toast;
