// account-conversion
import {STEPS} from './constants';

// Ordered steps for high school to community college conversion
export const highSchoolSteps = [STEPS.email, STEPS.institution, STEPS.transferInfo, STEPS.follow];

export const getNextStep = (currentStep) => {
  const nextStepIndex = highSchoolSteps.indexOf(currentStep) + 1;
  if (nextStepIndex >= highSchoolSteps.length) {
    return STEPS.submit;
  }

  return highSchoolSteps[nextStepIndex];
};

export const getPreviousStep = (currentStep) => {
  const previousStepIndex = highSchoolSteps.indexOf(currentStep) - 1;
  if (previousStepIndex < 0) {
    return STEPS.redirect;
  }

  return highSchoolSteps[previousStepIndex];
};
