import PropTypes from 'prop-types';
import React from 'react';
import InfoTooltip from 'shared/components/info-tooltip';
import CostComparisonWithAverage from './cost-comparison-with-average';

class CompareSalaryAfterGraduation extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    nationalCollegeData: PropTypes.object.isRequired,
  };

  render() {
    const {college, nationalCollegeData} = this.props;

    if (!college.salaryAfterGraduation) {
      return null;
    }

    const salaryTooltip = (
      <InfoTooltip className="college-profile-tooltip">
        The median earnings of former students who received federal financial aid, at 10 years after
        entering the school.
      </InfoTooltip>
    );

    return (
      <CostComparisonWithAverage
        label="Salary After Attending"
        tooltip={salaryTooltip}
        dataSource={nationalCollegeData}
        attributeInSource="avgSalaryAfterGraduation"
        attributeOnCollege="salaryAfterGraduation"
        college={college}
      />
    );
  }
}

export default CompareSalaryAfterGraduation;
