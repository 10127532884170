import {merge} from 'lodash';
import {
  RECEIVE_COLLEGES,
  FOLLOW_COLLEGE,
  UNFOLLOW_COLLEGE,
} from 'shared/registration/actions/schools';

const initialState = {
  colleges: [],
  loaded: false,
};

function collegesReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_COLLEGES:
      return merge({}, state, {
        colleges: action.colleges,
        loaded: true,
      });
    case FOLLOW_COLLEGE: {
      const followColleges = state.colleges.map((college) => {
        if (college.id === action.id) {
          return merge({}, college, {following: true});
        }
        return college;
      });

      return merge({}, state, {
        colleges: followColleges,
      });
    }
    case UNFOLLOW_COLLEGE: {
      const unfollowColleges = state.colleges.map((college) => {
        if (college.id === action.id) {
          return merge({}, college, {following: false});
        }
        return college;
      });

      return merge({}, state, {
        colleges: unfollowColleges,
      });
    }
    default:
      return state;
  }
}

export default collegesReducer;
