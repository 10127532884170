import React from 'react';
import Label from 'shared/components/label';
import Yup from 'src/utils/yup';
import {passwordRegex} from 'src/utils/validations';
import EmailOrPhoneInput from 'src/components/inputs/formik-email-or-phone-input';
import EmailInput from 'src/components/inputs/formik-email-input';
import errors from 'src/static/errors.json';
import FormikPasswordInput from '../formik-password-input';

interface Props {
  identifierHeader?: string;
  identifierDisabled?: boolean;
  passwordHeader?: string;
  signUp?: boolean;
  useFormik?: boolean;
  showRequiredLegend?: boolean;
}

export const loginCredentialsInputFormSchema = Yup.object().shape({
  identifier: Yup.string()
    .required(errors.validation.phoneOrEmail.required)
    .phoneOrEmail(errors.validation.phoneOrEmail.invalid),
  password: Yup.string().required(errors.validation.password.required),
});

const underTwentyOneError = 'Whoops! You must be under 21 to use RaiseMe as a high school student.';
const underFifteenError =
  'Whoops! You must be at least 15 to use RaiseMe as a community college student.';

export const genericSignupFormSchema = Yup.object().shape({
  identifier: Yup.string()
    .required(errors.validation.email.required)
    .email(errors.validation.email.invalid),
  password: Yup.string()
    .required(errors.validation.password.invalid)
    .password(errors.validation.password.invalid),
});

export const hsSignupFormSchema = Yup.object().shape({
  identifier: Yup.string()
    .required(errors.validation.email.required)
    .email(errors.validation.email.invalid),
  password: Yup.string()
    .required(errors.validation.password.invalid)
    .password(errors.validation.password.invalid),
  firstName: Yup.string().required(errors.validation.firstName.required),
  lastName: Yup.string().required(errors.validation.lastName.required),
  birthday: Yup.date()
    .required(errors.validation.birthday.required)
    .test('is-21-or-younger', underTwentyOneError, function (value) {
      return ageValidator(value, 'underTwentyOneError');
    }),
});

export const transferSignupFormSchema = Yup.object().shape({
  identifier: Yup.string()
    .required(errors.validation.email.required)
    .email(errors.validation.email.invalid),
  password: Yup.string()
    .required(errors.validation.password.invalid)
    .password(errors.validation.password.invalid),
  firstName: Yup.string().required(errors.validation.firstName.required),
  lastName: Yup.string().required(errors.validation.lastName.required),
  birthday: Yup.date()
    .required(errors.validation.birthday.required)
    .required(errors.validation.birthday.required)
    .test('is-over-15', underFifteenError, function (value) {
      return ageValidator(value, 'underFifteenError');
    }),
});

const ageValidator = (dob, errorName) => {
  const birthDate = dob;
  const todaysDate = new Date(Date.now());

  let age = todaysDate.getFullYear() - birthDate.getFullYear();

  const hasBirthdayPassedThisYear =
    todaysDate.getMonth() > birthDate.getMonth() ||
    (todaysDate.getMonth() === birthDate.getMonth() && todaysDate.getDate() >= birthDate.getDate());

  if (!hasBirthdayPassedThisYear) age--;

  if (errorName === 'underFifteenError') {
    if (age === 13 || age === 14) return false;
  } else if (errorName === 'underTwentyOneError') {
    if (age >= 21) return false;
  }
  return true;
};

const FormikCredentialsInput = ({
  identifierHeader = 'Email or Phone Number',
  passwordHeader = 'Password',
  identifierDisabled = false,
  signUp,
  showRequiredLegend = true,
}: Props) => {
  const IdentifierInput = signUp ? EmailInput : EmailOrPhoneInput;
  return (
    <div className="oauth-credentials-input" data-testid="credentials-input">
      {showRequiredLegend ? <legend className="shared-required-legend"></legend> : null}
      <Label required>
        {identifierHeader}
        <IdentifierInput
          name="identifier"
          active={false}
          disabled={identifierDisabled}
          cy="desktop-login-input"
          required
        />
      </Label>
      <Label required>
        {passwordHeader}
        <FormikPasswordInput
          signUp={signUp}
          name="password"
          {...(signUp && {pattern: passwordRegex.source})}
          cy="desktop-password-input"
          required
        />
      </Label>
    </div>
  );
};

export default FormikCredentialsInput;
