import PropTypes from 'prop-types';
import React from 'react';
import {flatten, isNil, map} from 'lodash';
import classnames from 'classnames';

const MAX_X_VALUE_FOR_SHOWING_MAX_LABEL = 85;
const MIN_X_VALUE_FOR_SHOWING_MIN_LABEL = 15;

class HorizontalBoxPlot extends React.Component {
  static propTypes = {
    lower: PropTypes.number,
    max: PropTypes.number.isRequired,
    maxLabel: PropTypes.string,
    min: PropTypes.number.isRequired,
    minLabel: PropTypes.string,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        color: PropTypes.oneOf(['blue', 'gray']),
        label: PropTypes.string,
        tooltip: PropTypes.node,
      })
    ),
    tooltip: PropTypes.bool,
    upper: PropTypes.number,
  };

  xValue(value) {
    this.xValues = this.xValues || {};

    if (!this.xValues[value]) {
      const {min, max} = this.props;

      this.xValues[value] = ((value - min) / (max - min)) * 100;
    }

    return this.xValues[value];
  }

  showMaxLabel() {
    const {points} = this.props;
    if (!points) {
      return true;
    }

    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      if (point.label && this.xValue(point.value) > MAX_X_VALUE_FOR_SHOWING_MAX_LABEL) {
        return false;
      }
    }

    return true;
  }

  showMinLabel() {
    const {points} = this.props;
    if (!points) {
      return true;
    }

    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      if (point.label && this.xValue(point.value) < MIN_X_VALUE_FOR_SHOWING_MIN_LABEL) {
        return false;
      }
    }

    return true;
  }

  renderPoints() {
    const {points} = this.props;

    if (!points || !points.length) {
      return null;
    }

    return flatten(
      map(points, ({value, label, tooltip, color}, i) => [
        <circle
          key={`circle-${i}`}
          className={classnames('horizontal-box-plot-point', {gray: color === 'gray'})}
          cx={`${this.xValue(value)}%`}
          cy="18%"
          filter="url(#dropshadow)"
          r="10"
        />,
        label && (
          <text
            key={`label-${i}`}
            className="horizontal-box-plot-label"
            textAnchor="middle"
            x={`${this.xValue(value)}%`}
            dy="1em"
            y="20px"
          >
            {label}
          </text>
        ),
        tooltip && this.renderTooltip(tooltip, value, i),
      ])
    );
  }

  render() {
    const {max, maxLabel, min, minLabel, lower, upper, tooltip} = this.props;

    let scaledLower, scaledUpper, boxStart, boxWidth, upperPosition;

    if (!isNil(lower)) {
      scaledLower = lower - min;
    }

    if (!isNil(upper)) {
      scaledUpper = upper - min;
    }

    const scaledMax = max - min;

    if (!isNil(scaledLower)) {
      boxStart = 100 - ((scaledMax - scaledLower) * 100) / scaledMax;
    }

    if (!isNil(scaledUpper)) {
      boxWidth = 100 - ((scaledMax - scaledUpper) * 100) / scaledMax - boxStart;
    }

    if (!isNil(boxStart) && !isNil(boxWidth)) {
      upperPosition = boxStart + boxWidth;
    }

    const boxHeight = '10px';
    const lineLength = '14px';
    const labelOffset = '15px';

    // Decorative graph so aria-hidden is set to true.
    return (
      <svg
        width="100%"
        height="30px"
        className={classnames('horizontal-box-plot', {'with-tooltip': tooltip})}
        aria-hidden="true"
        alt=""
      >
        <defs>
          <filter id="dropshadow" height="200%" width="200%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="4" />
            <feOffset dx="2" dy="2" result="offsetblur" />
            <feFlood floodColor="#000" floodOpacity="0.1" />
            <feComposite in2="offsetblur" operator="in" />
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <rect
          rx="4"
          ry="4"
          height={boxHeight}
          width="100%"
          className="horizontal-box-plot-background"
        />
        {!isNil(boxStart) && !isNil(boxWidth) && (
          <rect
            className="horizontal-box-plot-box"
            height={boxHeight}
            x={`${boxStart}%`}
            width={`${boxWidth}%`}
          />
        )}
        <line className="horizontal-box-plot-line" x1="0.5%" x2="0.5%" y1="0" y2={lineLength} />
        {!isNil(boxStart) && (
          <line
            className="horizontal-box-plot-line"
            x1={`${boxStart}%`}
            x2={`${boxStart}%`}
            y1="0"
            y2={lineLength}
          />
        )}
        {!isNil(upperPosition) && (
          <line
            className="horizontal-box-plot-line"
            x1={`${upperPosition}%`}
            x2={`${upperPosition}%`}
            y1="0"
            y2={lineLength}
          />
        )}
        <line className="horizontal-box-plot-line" x1="99.5%" x2="99.5%" y1="0" y2={lineLength} />
        {!isNil(boxStart) && (
          <text
            className="horizontal-box-plot-label"
            textAnchor="middle"
            x={`${boxStart}%`}
            dy="1em"
            y={labelOffset}
          >
            {lower}
          </text>
        )}
        {this.showMinLabel() && (
          <text
            className="horizontal-box-plot-label"
            textAnchor="middle"
            x="0.5%"
            dy="1em"
            y={labelOffset}
          >{`${minLabel || min}`}</text>
        )}
        {!isNil(upperPosition) && (
          <text
            textAnchor="middle"
            className="horizontal-box-plot-label"
            x={`${upperPosition}%`}
            dy="1em"
            y={labelOffset}
          >
            {upper}
          </text>
        )}
        {this.showMaxLabel() && (
          <text
            textAnchor="middle"
            className="horizontal-box-plot-label"
            x="99.5%"
            dy="1em"
            y={labelOffset}
          >
            {maxLabel || max}
          </text>
        )}
        {this.renderPoints()}
      </svg>
    );
  }
}

export default HorizontalBoxPlot;
