import PropTypes from 'prop-types';
import React from 'react';
import {sum} from 'lodash';
import HighlightedText from 'shared/college-profile/components/highlighted-text';
import {withCommas} from 'shared/utils';
import Text from 'shared/components/text';

const formatValue = (value) => `$${withCommas(value)} /year`;

const propTypes = {
  college: PropTypes.shape({
    tuition: PropTypes.number,
    onCampusHousingCost: PropTypes.number,
    onCampusOtherCosts: PropTypes.number,
    booksAndSuppliesCost: PropTypes.number,
    state: PropTypes.string.isRequired,
    control: PropTypes.string.isRequired,
    financialAidBreakdown: PropTypes.shape({
      federalGrants: PropTypes.shape({averageAmount: PropTypes.number}),
      stateOrLocalGovtGrants: PropTypes.shape({averageAmount: PropTypes.number}),
      institutionalAid: PropTypes.shape({averageAmount: PropTypes.number}),
    }),
  }),
  userInfo: PropTypes.object.isRequired,
  userState: PropTypes.string.isRequired,
};

const EstimatedNetPrice = ({college, userInfo, userState}) => {
  const {
    booksAndSuppliesCost,
    onCampusHousingCost,
    onCampusOtherCosts,
    state,
    tuition,
    financialAidBreakdown: {federalGrants, stateOrLocalGovtGrants, institutionalAid},
  } = college;

  const {highSchoolFrlPercentage, highSchoolInfo} = userInfo;

  const totalCost = sum([tuition, onCampusHousingCost, onCampusOtherCosts, booksAndSuppliesCost]);

  let aidAmount = 0;

  if (institutionalAid.averageAmount) {
    aidAmount += institutionalAid.averageAmount;
  }

  const inState = userState === state;

  if (inState && stateOrLocalGovtGrants.averageAmount) {
    aidAmount += stateOrLocalGovtGrants.averageAmount;
  }

  const studentIndicatedFrl = highSchoolInfo && highSchoolInfo.lunchPlan === 'FRL';
  const highschoolFrlPercentageHigh = highSchoolFrlPercentage && highSchoolFrlPercentage > 50;

  const lowIncome = studentIndicatedFrl || highschoolFrlPercentageHigh;

  if (lowIncome && federalGrants.averageAmount) {
    aidAmount += federalGrants.averageAmount;
  }

  const netCost = totalCost - aidAmount;

  if (netCost <= 0) return null;

  return (
    <div className="estimated-net-cost">
      <HighlightedText
        iconType="dollar-sign"
        text={[
          <span key="est-cost">Total Est. Net&nbsp;Price</span>,
          <span key="est-cost-amount">{formatValue(netCost)}</span>,
        ]}
      />
    </div>
  );
};

const Formula = () => (
  <div className="estimated-net-cost">
    <div className="estimated-net-cost-formula-container">
      <div className="estimated-net-cost-text">How to calculate your Estimated Net Price</div>
      <div className="estimated-net-cost-formula">
        <div className="estimated-net-cost-formula-item">
          <HighlightedText padding={false} iconType="tag" text="Cost of Attendance" />
        </div>
        <div className="estimated-net-cost-formula-item hide-mobile">
          <Text weight={3}>&mdash;</Text>
        </div>
        <div className="estimated-net-cost-formula-item">
          <Text weight={3} className="estimated-net-cost-formula-mobile-symbol">
            &mdash;
          </Text>
          <HighlightedText padding={false} iconType="gift" text="Financial Aid" />
        </div>
        <div className="estimated-net-cost-formula-item hide-mobile">
          <Text weight={3}>=</Text>
        </div>
        <div className="estimated-net-cost-formula-item">
          <Text weight={3} className="estimated-net-cost-formula-mobile-symbol">
            =
          </Text>
          <HighlightedText padding={false} iconType="dollar-sign" text="Net Price" />
        </div>
      </div>
    </div>
  </div>
);

EstimatedNetPrice.propTypes = propTypes;
EstimatedNetPrice.Formula = Formula;

export default EstimatedNetPrice;
