import {keyBy, omit} from 'lodash';
import {COURSE_DELETED} from 'shared/portfolio/actions/courses';
import {RECEIVE_COURSES} from 'portfolio/actions/courses';

export const bulkEntryCoursesReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_COURSES: {
      return {...state, ...keyBy(action.courses, '_id')};
    }
    case COURSE_DELETED:
      return omit(state, action.id);
    default:
      return state;
  }
};
