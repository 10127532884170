import React, {useContext} from 'react';

import {PlatformContext} from 'src/components/PlatformContext';

import styles from './title.module.scss';

interface Props {
  children: React.ReactNode;
}

const Title = ({children}: Props): React.ReactElement | null => {
  const platformContext = useContext(PlatformContext);

  if (platformContext.isIonic) return null;

  return <h2 className={styles.title}>{children}</h2>;
};

export default Title;
