import PropTypes from 'prop-types';
import React from 'react';
import CheckboxGroupFilter from 'shared/components/filters/checkbox-group-filter';
import Text from 'shared/components/text';
import ExpandableFilter from 'src/components/CollegeSearch/Filters/expandable-filter';

export const TYPES = {
  'Liberal Arts': 'Liberal Arts Colleges',
  Research: 'Research Universities',
  'Non Research Universities': 'Non Research Universities',
};

class TypeFilters extends React.Component {
  static propTypes = {
    getColleges: PropTypes.func.isRequired,
    transfer: PropTypes.bool.isRequired,
  };

  render() {
    const {getColleges, transfer} = this.props;

    return (
      <div className="college-search-filters-section">
        <fieldset role="group" aria-label="Filter by College Type">
          <legend className="college-search-filters-section-legend">
            <Text weight={5} emphasis>
              College Type
            </Text>
          </legend>
          <CheckboxGroupFilter
            name="control"
            options={{Public: 'Public', Private: 'Private'}}
            onChange={getColleges}
          />
          <div className="college-search-subdivider"></div>
          {!transfer && (
            <CheckboxGroupFilter
              options={{1: 'Community College'}}
              name="community-college"
              onChange={getColleges}
            />
          )}
          <CheckboxGroupFilter
            options={{1: 'Trade School'}}
            name="trade-school"
            onChange={getColleges}
          />
          <div className="college-search-subdivider"></div>
          <CheckboxGroupFilter name="type" options={TYPES} onChange={getColleges} />
          <ExpandableFilter filterType="College">
            <div className="college-search-subdivider"></div>
            <CheckboxGroupFilter
              options={{1: 'Historically Black College'}}
              name="historically-black"
              onChange={getColleges}
            />
            <CheckboxGroupFilter
              options={{1: 'Hispanic-Serving Institution'}}
              name="hispanic-serving"
              onChange={getColleges}
            />
            <CheckboxGroupFilter
              options={{1: 'Tribal College / University'}}
              name="tribal"
              onChange={getColleges}
            />
            <div className="college-search-subdivider"></div>
            <CheckboxGroupFilter
              options={{Womens: "Women's College", Mens: "Men's College"}}
              name="single-sex"
              onChange={getColleges}
            />
          </ExpandableFilter>
        </fieldset>
      </div>
    );
  }
}

export default TypeFilters;
