import React, {ComponentType, ReactElement, useContext} from 'react';
import {PlatformContext} from '../components/PlatformContext';
import {PlatformContextType} from '../components/PlatformContext/types';

export default function usePlatformContext(): PlatformContextType {
  return useContext(PlatformContext);
}

export interface WithPlatformContextProps {
  platformContext: PlatformContextType;
}

export function withPlatformContext<PassedPropsAndPlatformContext extends WithPlatformContextProps>(
  Component: ComponentType<PassedPropsAndPlatformContext>
) {
  return function WithPlatformContext(
    props: Omit<PassedPropsAndPlatformContext, 'platformContext'>
  ): ReactElement | null {
    const platformContext = usePlatformContext();

    return (
      <Component {...(props as PassedPropsAndPlatformContext)} platformContext={platformContext} />
    );
  };
}
