import React, {
  useEffect,
  useRef,
  ReactNode,
  ComponentPropsWithRef,
  forwardRef,
  useImperativeHandle,
  useId,
} from 'react';
import classNames from 'classnames';

import 'stylesheets/components/shared-checkbox.scss';

export interface Props extends ComponentPropsWithRef<'input'> {
  // remove once all instances are off redux-form
  invalid?: boolean;

  value?: string;
  label?: ReactNode;
  error?: boolean;
  indeterminate?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, Props>(function Checkbox(
  {
    // redux-form
    invalid,

    label,
    error,
    className,
    indeterminate,
    ...inputProps
  },
  forwardedRef
) {
  const inputId = useId();
  const ref = useRef<HTMLInputElement>(null);

  // if forwarded a ref, expose existing input ref
  useImperativeHandle(forwardedRef, () => ref.current!, []);

  useEffect(() => {
    if (ref.current && indeterminate !== undefined) {
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <div className={classNames('shared-aptitude-checkbox', className)}>
      <input
        ref={ref}
        id={inputId}
        className={classNames('shared-checkbox-input', {invalid, error})}
        data-testid="shared-checkbox"
        {...inputProps}
        type="checkbox"
      />
      <label
        htmlFor={inputId}
        className={classNames('shared-checkbox-input-label', {disabled: inputProps.disabled})}
      >
        {label}
      </label>
    </div>
  );
});

export default Checkbox;
