import React from 'react';
import {useField} from 'formik';
import TextInput, {Props as TextInputProps} from './text-input';

/**
  For use in Formik forms.

  Usage example:
  <FormikTextInput name="email" placeholder="example@email.com" />
*/

const FormikTextInput = (props: {name: string} & TextInputProps) => {
  const [field, meta] = useField({name: props.name, type: props.type || 'text'});

  return (
    <TextInput
      {...props}
      {...field}
      error={meta.touched ? meta.error : ''}
      {...(props.onChange && {
        onChange: (e) => {
          field.onChange(e);
          props.onChange?.(e);
        },
      })}
    />
  );
};

export default FormikTextInput;
