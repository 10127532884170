import {includes} from 'lodash';
import {MULTIPLE_REFERRAL_MODAL_IDENTIFIER} from 'portfolio/components/multiple-referral-modal/actions';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {track} from 'src/utils/analytics';

const SCHOLARSHIP_TYPE_MAP = {
  CampusVisitScholarship: 'campus-visit',
  SummerProgramScholarship: 'summer-program',
  CollegeReceptionScholarship: 'college-reception',
  CollegeFairScholarship: 'college-fair',
};

const LABEL_MAP = {
  academic: 'a course',
  visits: 'a college event',
  scores: 'a test score',
  volunteer: 'a community service',
  activity: 'an activity',
  sport: 'an activity',
  honor: 'an honor or award',
  referral: 'a referral',
  work: 'work experience',
  multimedia: 'a multimedia item',
  fafsa: 'a FAFSA submission',
  degreeProgress: 'your Associate degree plans',
  collegeSuccess: 'a college success item',
};

const TRACK_MAP = {
  academic: 'course',
  visits: 'college-event',
  scores: 'test-score',
  volunteer: 'community-service',
  activity: 'activity',
  sport: 'sport',
  honor: 'honors-and-awards',
  referral: 'referral',
  work: 'work-experience',
  multimedia: 'multimedia',
  fafsa: 'fafsa',
  degreeProgress: 'degree-progress',
  collegeSuccess: 'college-success',
};

// map to modal identifier
const MODAL_MAP = {
  referral: MULTIPLE_REFERRAL_MODAL_IDENTIFIER,
};

export const isCcCourseGradeScholarship = (props = this.props) => {
  const {userInfo, scholarship} = props;
  return (
    userInfo.user === 'community-college' &&
    scholarship.scholarshipType === 'CourseGradeScholarship'
  );
};

class ScholarshipCTALink extends React.Component {
  static propTypes = {
    scholarship: PropTypes.shape({
      // mapped broad category like 'academic'
      type: PropTypes.string.isRequired,
      // specific scholarship class like 'CourseGradeScholarship'
      scholarshipType: PropTypes.string.isRequired,
      timeSensitiveItemTypeId: PropTypes.string,
      typeDisplay: PropTypes.string,
    }).isRequired,
    // Tab that the button is on. Used for event tracking.
    source: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    // Modal type is pending evaluation
    pending: PropTypes.bool,
    userInfo: PropTypes.object,
    showScholarshipCta: PropTypes.bool,
    collegeName: PropTypes.string,
    collegeId: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
  };

  deepLinkMap = () => {
    const {
      scholarship: {scholarshipType, timeSensitiveItemTypeId, typeDisplay},
      collegeName,
      collegeId,
      location: {pathname},
    } = this.props;
    const type = SCHOLARSHIP_TYPE_MAP[scholarshipType];

    return {
      academic: {pathname: `${pathname}/course/new`, state: {skipScrolling: true}},
      visits: {
        pathname: `${pathname}/college-success/college-event/${type || 'other-college-event'}/form`,
        search: `?collegeName=${encodeURI(
          collegeName
        )}&collegeId=${collegeId}&event=${typeDisplay}&onlyAwardableEvents=true`,
        state: {skipScrolling: true},
      },
      scores: {pathname: `${pathname}/add-test-score`, state: {skipScrolling: true}},
      volunteer: {pathname: `${pathname}/community-service/new`, state: {skipScrolling: true}},
      activity: {pathname: `${pathname}/add-extracurricular`, state: {skipScrolling: true}},
      sport: {pathname: `${pathname}/add-extracurricular`, state: {skipScrolling: true}},
      honor: '/portfolio#honors-and-awards',
      referral: '/portfolio',
      work: '/portfolio#work-experience',
      multimedia: {pathname: `${pathname}/multimedia/new`, state: {skipScrolling: true}},
      fafsa: {pathname: `${pathname}/college-success/fafsa/form`, state: {skipScrolling: true}},
      degreeProgress: '/transfers/settings',
      collegeSuccess: {
        pathname: `${pathname}/college-success/time-sensitive/${timeSensitiveItemTypeId}/form`,
        state: {skipScrolling: true},
      },
    };
  };

  showCtaAddButton = () => {
    const {
      scholarship: {scholarshipType, type},
      userInfo,
    } = this.props;

    // There is no modal for this
    if (!MODAL_MAP[type]) return false;

    if (
      includes(
        ['CourseGradeScholarship', 'ChallengingClassScholarship', 'FullCourseLoadScholarship'],
        scholarshipType
      ) &&
      userInfo.user === 'community-college'
    ) {
      return false;
    }
    // Cannot add more than one fafsa submission
    const submittedFafsa = !!(userInfo || {}).fafsaSubmission;
    if (submittedFafsa && type === 'fafsa') return false;

    return true;
  };

  navigateToAddCourseModal = () => {
    this.props.history.push(this.props.location.pathname.concat('/courses'));
  };

  render() {
    if (!this.props.showScholarshipCta) return null;

    const {
      userInfo,
      scholarship: {type, scholarshipType},
      source,
      openModal,
      pending,
    } = this.props;

    if (scholarshipType === 'ClaimedAccountScholarship') {
      return null;
    }
    let link = this.deepLinkMap()[type];

    const label = LABEL_MAP[type];
    const category = TRACK_MAP[type];
    const modal = MODAL_MAP[type];

    if (type === 'fafsa' && (pending || !(userInfo.highSchoolInfo || {}).senior)) {
      return null;
    }

    // Show pending status
    // or open relevant modal
    // or redirect to relevant section on Student Portfolio
    if (this.showCtaAddButton()) {
      return (
        <button
          className="college-profile-scholarship-cta-link"
          onClick={() => {
            openModal(modal);
          }}
        >
          Add
        </button>
      );
    }

    if (isCcCourseGradeScholarship(this.props)) {
      return (
        <button
          className="college-profile-scholarship-cta-link"
          onClick={this.navigateToAddCourseModal}
        >
          Add Course
        </button>
      );
    }

    if (link) {
      let linkText = 'Add in Portfolio';
      let ariaLabelText = `Go to your portfolio and add ${label}`;

      if (type === 'degreeProgress') {
        linkText = 'Edit in Account Settings';
      } else if (
        [
          'fafsa',
          'visits',
          'collegeSuccess',
          'scores',
          'sport',
          'activity',
          'multimedia',
          'volunteer',
          'academic',
        ].includes(type)
      ) {
        linkText = 'Add';
      }

      if (type === 'fafsa') {
        ariaLabelText = 'Opens the FAFSA modal';
      }

      if (includes(['PerfectAttendanceScholarship', 'OverallGpaScholarship'], scholarshipType)) {
        link = '/portfolio#courses';
        linkText = 'Add in Portfolio';
      }

      return (
        <Link
          className="college-profile-scholarship-cta-link"
          to={link}
          aria-label={ariaLabelText}
          onClick={() => {
            track(`college-listing ${source}-add-${category}`);
          }}
        >
          {linkText}
        </Link>
      );
    }

    return null;
  }
}

export default withRouter(ScholarshipCTALink);
