import React, {createContext, ReactNode, useContext, useRef, useState} from 'react';
import {Modal as MuiModal} from '@mui/material';
import classNames from 'classnames';
import {IonModal} from '@ionic/react';

import TextHeader from './text-header';
import ImageHeader from './image-header';
import TabbedHeader from './tabbed-header';
import NavigationFooter from './navigation-footer';
import Body from './body';
import Footer from './footer';

import './modal.scss';
import usePlatformContext from '../../../src/hooks/usePlatform';

interface ModalContext {
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
  bodyOverflow: boolean;
  setBodyOverflow: (overflow: boolean) => void;
  footerHeight: number;
  setFooterHeight: (height: number) => void;
  hasTextHeader: boolean;
  setHasTextHeader: (header: boolean) => void;
}

const ModalContext = createContext<ModalContext | undefined>(undefined);

export const useModalContext = () => {
  const modalContext = useContext(ModalContext);

  if (!modalContext) {
    throw new Error('useModalContext should only be used by children of shared Modal');
  }

  return modalContext;
};

interface Props {
  children: ReactNode;
  show: boolean;
  // onClose is used here for dismissing the modal by clicking outside of it
  onClose?: () => void;
  className?: string;
  // TODO: Remove once we have ionic apps for the college and educator portals
  forceNotIonic?: boolean;
  testId?: string;
}

const Modal = ({onClose, show, children, className, forceNotIonic, testId}: Props) => {
  const {isIonic} = usePlatformContext();
  const modal = useRef<HTMLIonModalElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [bodyOverflow, setBodyOverflow] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const [hasTextHeader, setHasTextHeader] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        bodyOverflow,
        setBodyOverflow,
        footerHeight,
        setFooterHeight,
        hasTextHeader,
        setHasTextHeader,
      }}
    >
      {!forceNotIonic && isIonic ? (
        <IonModal
          ref={modal}
          isOpen={show}
          onWillDismiss={modal.current?.dismiss}
          onDidDismiss={onClose}
          canDismiss
          className="shared-modal-ionic"
        >
          <div className={classNames('shared-modal', className)}>{children}</div>
        </IonModal>
      ) : (
        <MuiModal
          onClose={onClose}
          open={show}
          slotProps={{backdrop: {className: 'shared-modal-backdrop'}}}
        >
          <div
            className={classNames('shared-modal', className)}
            data-testid={testId ? testId : 'shared-modal'}
          >
            {children}
          </div>
        </MuiModal>
      )}
    </ModalContext.Provider>
  );
};

Modal.TextHeader = TextHeader;
Modal.ImageHeader = ImageHeader;
Modal.TabbedHeader = TabbedHeader;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.NavigationFooter = NavigationFooter;

export default Modal;
