import disabilitiesReducer from 'src/redux/disabilities/reducer';
import {recommendationsReducer} from 'recommendations/redux';
import {reducer as formReducer} from 'redux-form';
import {referralModalReducer} from 'src/redux/studentReferralModal/reducer';
import {collegeSearchReducer} from 'src/redux/collegeSearch/reducer';
import {termNotOfferedModalReducer} from 'src/redux/termNotOfferedModal/reducer';
import {followedCollegesNextTransferTermsReducer} from 'portfolio/reducers/followed-colleges-next-transfer-terms';
import {collegeProfileReducer} from 'shared/college-profile/reducers/college-profile';
import {startsWithAcademicInterestsReducer} from 'shared/college-profile/reducers/starts-with-academic-interests-reducer';
import {
  LOADING_FALSE,
  LOADING_TRUE,
  RECEIVE_PORTFOLIO,
  RESET_PORTFOLIO,
} from 'shared/portfolio/actions/portfolio';
import {termsBannerMessageReducer} from 'portfolio/reducers/terms-banner-message';
import {collegeEarningsReducer} from 'shared/college-earnings/reducers/college-earnings';
import collegeApplicationStatusReducer from 'shared/college-application-status/reducers/college-application-status-reducer';
import collegeApplicationStatusesReducer from 'shared/college-application-status/reducers/college-application-statuses-reducer';
import {collegeCoursesReducer} from 'shared/portfolio/reducers/college-courses';
import {institutionsReducer} from 'shared/portfolio/reducers/institutions';
import {collegeEventsReducer} from 'shared/portfolio/reducers/college-events';
import {honorAwardsReducer} from 'shared/portfolio/reducers/honor-awards';
import {communityServiceReducer} from 'shared/portfolio/reducers/community-service';
import {experiencesReducer} from 'shared/portfolio/reducers/work-experiences';
import {testScoresReducer} from 'shared/portfolio/reducers/test-scores';
import {referralsReducer} from 'portfolio/reducers/referrals';
import {multimediaReducer} from 'shared/portfolio/reducers/multimedia';
import studentReducer from 'shared/portfolio/reducers/student';
import {studentNotificationBanner} from 'src/redux/studentNotificationBanner/reducer';
import {missingHighSchoolModalReducer} from 'shared/missing-high-school-modal/reducers/missing-high-school-modal';
import {collegeSearchBarReducer} from 'shared/college-search-bar/reducers';
import followedCollegesReducer from 'shared/college-application-status/reducers/followed-colleges-reducer';
import {popoversReducer} from 'shared/reducers/popovers';
import {modalReducer} from 'shared/reducers/modal';
import {overlayReducer} from 'shared/reducers/overlay';
import {notificationsReducer} from 'shared/reducers/notifications';
import {avatarReducer} from 'shared/reducers/avatar';
import {filtersReducer} from 'shared/reducers/filters';
import {immutableBoolReducer} from 'shared/reducers/immutable-reducers';
import {booleanReducer} from 'shared/reducers/boolean-reducer';
import {genericReducer} from 'shared/reducers/generic-reducer';
import featureFlagsReducer from 'shared/reducers/feature-flags';
import {START_LOADING, STOP_LOADING} from 'shared/actions/loading';
import {INITIALIZE_FILTERS} from 'shared/actions/filters';
import {accessibilityAnnouncementReducer} from 'shared/reducers/accessibility-announcement';
import {userConnectionsReducer} from 'shared/reducers/user-connections';
import notificationSettingsReducer from 'shared/account-settings/reducers/notification-settings';
import tabReducer from 'shared/account-settings/reducers/tab';
import unsavedChangesReducer from 'shared/account-settings/reducers/unsaved-changes';
import {awardNotificationsReducer} from 'shared/reducers/award-notifications';
import {RECEIVE_PHONE_NUMBER} from 'shared/actions/verify-mobile';
import collegeSuccessItemTypesReducer from 'shared/college-success/reducers/college-success-item-types';
import {RECEIVE_COLLEGE_INTERACTION_OPTIONS} from 'shared/portfolio/actions/college-events';
import {objectReducer} from 'shared/reducers/object-reducer';
import {CLEAR_COLLEGES, RECEIVE_COLLEGES, UPDATE_COLLEGE} from 'shared/actions/college';
import notificationCenterReducers from 'shared/notification-center/reducers';
import {loadingReducer} from 'shared/reducers/loading';
import {messagingReducer} from 'student-search/reducers/messaging';
import {
  NO_REMAINING_MESSAGES,
  REMAINING_MESSAGES,
} from 'shared/notification-center/actions/notification-messages';
import {experimentsReducer} from 'shared/reducers/experiments';
import {
  activitiesReducer,
  activityDescriptionReducer,
  leadershipPositionsReducer,
} from 'shared/portfolio/reducers/activities';
import {attendanceReducer, preselectsReducer} from 'shared/portfolio/reducers/courses';
import {collegeSuccessItemsReducer} from 'shared/portfolio/reducers/college-success-items';
import {listReducer} from 'src/redux/lists';
import toolbarNotificationReducer from 'src/redux/toolbar-notification/reducer';
import checklistReducer from '../../redux/checklist/reducer';
import checklistUserStatusReducer from '../../redux/checklistUserStatus/reducer';
import educatorConnectionsReducer from '../../redux/educatorConnections/reducer';

export const transferReducers = {
  accessibilityAnnouncement: accessibilityAnnouncementReducer,
  accountSettingsLoading: booleanReducer({
    defaultState: true,
    falseType: STOP_LOADING,
    trueType: START_LOADING,
  }),
  activeTab: tabReducer,
  activities: activitiesReducer,
  activityDescription: activityDescriptionReducer,
  activityModalLoading: booleanReducer({
    defaultState: true,
    falseType: STOP_LOADING,
    trueType: START_LOADING,
  }),
  attendance: attendanceReducer,
  avatar: avatarReducer,
  awardNotifications: awardNotificationsReducer,
  banners: studentNotificationBanner,
  checklist: checklistReducer,
  checklistUserStatus: checklistUserStatusReducer,
  collegeApplicationStatus: collegeApplicationStatusReducer,
  collegeApplicationStatuses: collegeApplicationStatusesReducer,
  collegeCourses: collegeCoursesReducer,
  collegeEarnings: collegeEarningsReducer,
  collegeEvents: collegeEventsReducer,
  collegeInteractionOptions: genericReducer({
    actionProperty: 'collegeInteractionOptions',
    defaultState: {},
    setAction: RECEIVE_COLLEGE_INTERACTION_OPTIONS,
  }),
  collegeProfile: collegeProfileReducer,
  collegeSearch: collegeSearchReducer,
  collegeSearchBar: collegeSearchBarReducer,
  collegeSuccessItemTypes: collegeSuccessItemTypesReducer,
  collegeSuccessItems: collegeSuccessItemsReducer,
  colleges: objectReducer({
    actionProperty: 'colleges',
    emptyObjectAction: CLEAR_COLLEGES,
    mergeObjectAction: UPDATE_COLLEGE,
    replaceObjectAction: RECEIVE_COLLEGES,
  }),
  communityServiceEvents: communityServiceReducer,
  coursePreselects: preselectsReducer,
  disabilities: disabilitiesReducer,
  educatorConnections: educatorConnectionsReducer,
  experiments: experimentsReducer,
  featureFlags: featureFlagsReducer,
  filters: filtersReducer,
  filtersInitialized: booleanReducer({defaultState: false, trueType: INITIALIZE_FILTERS}),
  followedColleges: followedCollegesReducer,
  followedCollegesNextTransferTerms: followedCollegesNextTransferTermsReducer,
  form: formReducer,
  honorAwards: honorAwardsReducer,
  institutions: institutionsReducer,
  leadershipPositions: leadershipPositionsReducer,
  lists: listReducer,
  loadMoreNotificationMessages: booleanReducer({
    defaultState: true,
    falseType: NO_REMAINING_MESSAGES,
    trueType: REMAINING_MESSAGES,
  }),
  loading: booleanReducer({defaultState: true, falseType: LOADING_FALSE, trueType: LOADING_TRUE}),
  messaging: messagingReducer,
  microscholarshipsLoading: loadingReducer,
  missingHighSchoolModal: missingHighSchoolModalReducer,
  modal: modalReducer,
  multimedia: multimediaReducer,
  notificationSettings: notificationSettingsReducer,
  notifications: notificationsReducer,
  overlay: overlayReducer,
  popovers: popoversReducer,
  portfolioEmpty: genericReducer({
    actionProperty: 'portfolioEmpty',
    defaultState: false,
    setAction: RECEIVE_PORTFOLIO,
  }),
  portfolioInitiated: booleanReducer({
    defaultState: false,
    falseType: RESET_PORTFOLIO,
    trueType: RECEIVE_PORTFOLIO,
  }),
  recommendations: recommendationsReducer,
  referralModal: referralModalReducer,
  referrals: referralsReducer,
  showTransferEducationDeadlineMessage: genericReducer({
    actionProperty: 'showTransferEducationDeadlineMessage',
    defaultState: false,
    setAction: RECEIVE_PORTFOLIO,
  }),
  showTransferEducationDeadlineModal: genericReducer({
    actionProperty: 'showTransferEducationDeadlineModal',
    defaultState: false,
    setAction: RECEIVE_PORTFOLIO,
  }),
  startsWithAcademicInterests: startsWithAcademicInterestsReducer,
  student: studentReducer,
  termNotOfferedModal: termNotOfferedModalReducer,
  termsBannerMessages: termsBannerMessageReducer,
  testScores: testScoresReducer,
  toolbarNotification: toolbarNotificationReducer,
  transfer: immutableBoolReducer,
  unsavedChanges: unsavedChangesReducer,
  userConnections: userConnectionsReducer,
  userType: genericReducer({
    actionProperty: 'userType',
    defaultState: '',
    setAction: RECEIVE_PORTFOLIO,
  }),
  verifyMobile: genericReducer({
    actionProperty: 'payload',
    defaultState: '',
    setAction: RECEIVE_PHONE_NUMBER.toString(),
  }),
  workExperiences: experiencesReducer,
  ...notificationCenterReducers,
};
