import req from 'shared/req';
import {errorMessage} from 'shared/actions/notifications';

// this will attempt to switch accounts but will fail if the current user does not have permissions on the account
// they are attempting to switch to
// userId is the id of the user to switch to
const switchAccount = (userId, reason) => (dispatch) =>
  req({
    url: '/v1/basic/switch-account',
    method: 'post',
    data: {user_id: userId, reason},
  })
    .then(() => (location.href = '/'))
    .catch(() => {
      dispatch(errorMessage());
    });

export default switchAccount;
