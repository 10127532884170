import PropTypes from 'prop-types';
import React from 'react';
import {includes} from 'lodash';
import classnames from 'classnames';
import Text from 'shared/components/text';
import {MONTH_ABBRVS} from 'shared/utils';

class DeadlineText extends React.Component {
  static propTypes = {
    earningRelationship: PropTypes.shape({
      statusType: PropTypes.string.isRequired,
    }),
    deadlineDay: PropTypes.number.isRequired,
    deadlineMonth: PropTypes.number.isRequired,
    deadlineYear: PropTypes.number,
    showDeadlineYear: PropTypes.bool,
    simplified: PropTypes.bool,
  };

  deadlineSoon = () => {
    const {earningRelationship, deadlineDay, deadlineMonth, deadlineYear} = this.props;
    const currentDate = new Date();
    const offsetDate = new Date(deadlineYear, deadlineMonth, deadlineDay);
    // set the offset to be 30 days before the deadline
    offsetDate.setDate(offsetDate.getDate() - 30);

    if (earningRelationship === null) {
      return offsetDate <= currentDate;
    } else {
      return (
        includes(
          ['earning_deadline_soon', 'submission_period_deferred'],
          earningRelationship.statusType
        ) || offsetDate <= currentDate
      );
    }
  };

  render() {
    const {deadlineDay, deadlineMonth, deadlineYear, showDeadlineYear, simplified} = this.props;
    const color = this.deadlineSoon() ? 'red' : 'darkgrey';

    return (
      <div className={classnames('shared-college-card-deadline-text', {simplified})}>
        <Text color={color} weight={3} inline>
          {MONTH_ABBRVS[deadlineMonth].toUpperCase()} {deadlineDay}
          {showDeadlineYear && ` ${deadlineYear}`}
        </Text>
        <Text weight={6} letterSpacing>
          DEADLINE
        </Text>
      </div>
    );
  }
}

export default DeadlineText;
