import {concat} from 'lodash';
import {
  APPEND_RESULTS,
  FETCHING_RESULTS,
  PAGINATE_RESULTS,
  RECEIVE_TOTAL_STUDENTS_COUNT,
  SET_STUDENT_SEARCH_TYPE,
  UPDATE_RESULTS,
} from '../actions/results';
import {RECEIVE_DATA_FOR_STUDENT_SEARCH} from 'student-search/actions/student-search';
import {selectDefaultPhase} from 'student-search/utils';

export const resultsReducer = (results = {}, action) => {
  switch (action.type) {
    case UPDATE_RESULTS: {
      const {students} = action;
      return {...results, page: 1, paging: false, students};
    }
    case PAGINATE_RESULTS: {
      const {page} = action;
      return {...results, page, paging: true};
    }
    case APPEND_RESULTS: {
      const students = concat(results.students, action.students);
      return {...results, paging: false, students};
    }
    case RECEIVE_TOTAL_STUDENTS_COUNT: {
      const {totalStudentsCount} = action;
      return {...results, totalStudentsCount};
    }
    case FETCHING_RESULTS:
      return {...results, paging: true};
    case SET_STUDENT_SEARCH_TYPE:
      return {...results, page: 1, studentSearchType: action.studentType};
    case RECEIVE_DATA_FOR_STUDENT_SEARCH:
      return {
        page: 1,
        studentSearchType: selectDefaultPhase(
          action.data.educationPhasesDownloadable,
          results.studentSearchType
        ),
      };
    default:
      return results;
  }
};
