import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {filtersReducer as _filtersReducer} from 'shared/reducers/filters';
import {notificationsReducer} from 'shared/reducers/notifications';
import {modalReducer} from 'shared/reducers/modal';
import {accessibilityAnnouncementReducer} from 'shared/reducers/accessibility-announcement';
import notificationSettingsReducer from 'shared/account-settings/reducers/notification-settings';
import {genericReducer} from 'shared/reducers/generic-reducer';
import {objectReducer} from 'shared/reducers/object-reducer';
import {loadingReducer} from 'shared/reducers/loading';
import {userConnectionsReducer} from 'shared/reducers/user-connections';
import studentReducer from 'shared/reducers/student';
import {popoversReducer} from 'shared/reducers/popovers';
import {RECEIVE_PHONE_NUMBER} from 'shared/actions/verify-mobile';
import {RECEIVE_COLLEGE_DATA_FOR_STUDENT} from 'shared/actions/college-data-for-student';

// student-search
import {messagingReducer} from 'student-search/reducers/messaging';
import {savedGroupsReducer} from 'student-search/reducers/saved-groups';
import {resultsReducer} from 'student-search/reducers/results';
import {downloadReducer} from 'student-search/reducers/download';
import {RETRIEVE_GROUP} from 'student-search/actions/saved-groups';
import {RECEIVE_DATA_FOR_STUDENT_SEARCH} from 'student-search/actions/student-search';
import {RECEIVE_COLLEGE_PORTAL_USER} from 'src/redux/college-portal/actions/college-portal-user';
import {RECEIVE_PARTNER_COLLEGES} from 'src/redux/college-portal/actions/colleges';
import {collegeProfileReducer} from 'shared/college-profile/reducers/college-profile';
import collegeApplicationStatusReducer from 'shared/college-application-status/reducers/college-application-status-reducer';
import toolbarNotificationReducer from 'src/redux/toolbar-notification/reducer';
import checklistReducer from '../../checklist/reducer';
import checklistUserStatusReducer from '../../checklistUserStatus/reducer';

const filtersReducer = (filters = {}, action) => {
  switch (action.type) {
    case RETRIEVE_GROUP:
      return action.filters;
    default:
      return _filtersReducer(filters, action);
  }
};

export const reducers = {
  accessibilityAnnouncement: accessibilityAnnouncementReducer,
  checklist: checklistReducer,
  checklistUserStatus: checklistUserStatusReducer,
  collegeApplicationStatus: collegeApplicationStatusReducer,
  collegeDataForStudent: objectReducer({
    actionProperty: 'collegeDataForStudent',
    mergeObjectAction: RECEIVE_COLLEGE_DATA_FOR_STUDENT,
  }),
  collegeProfile: collegeProfileReducer,
  downloads: downloadReducer,
  filters: filtersReducer,
  form: formReducer,
  loading: loadingReducer,
  messaging: messagingReducer,
  modal: modalReducer,
  notificationSettings: notificationSettingsReducer,
  notifications: notificationsReducer,
  partnerColleges: genericReducer({
    actionProperty: 'colleges',
    defaultState: [],
    setAction: RECEIVE_PARTNER_COLLEGES,
  }),
  popovers: popoversReducer,
  savedGroups: savedGroupsReducer,
  student: studentReducer,
  studentResults: resultsReducer,
  studentSearch: objectReducer({
    actionProperty: 'data',
    mergeObjectAction: RECEIVE_DATA_FOR_STUDENT_SEARCH,
  }),
  toolbarNotification: toolbarNotificationReducer,
  user: objectReducer({
    actionProperty: 'collegePortalUser',
    defaultState: {type: 'CollegePortalUser'},
    mergeObjectAction: RECEIVE_COLLEGE_PORTAL_USER,
  }),
  userConnections: userConnectionsReducer,
  verifyMobile: genericReducer({
    actionProperty: 'payload',
    defaultState: '',
    setAction: RECEIVE_PHONE_NUMBER.toString(),
  }),
};

export default combineReducers(reducers);
