import req from 'shared/req';

import {startLoading, stopLoading} from 'shared/actions/loading';
import JWTService from 'src/services/JWTService';

import {
  receiveNotificationMessageIndexes,
  removeNotificationMessageIndex,
  removeNotificationMessageIndexes,
} from './notification-message-indexes';

import {
  RECEIVE_COLLEGE_NAMES,
  RECEIVE_PROGRAM_COLLEGE,
} from '../reducers/scholarship-program-subjects';

import {getUserId} from 'shared/notification-center/actions/user';
import {Action} from 'src/actions/utils';
import {inBatches} from '../../../src/utils/inBatches';

export const RECEIVE_NOTIFICATION_MESSAGES = 'RECEIVE_NOTIFICATION_MESSAGES';
export const REMOVE_NOTIFICATION_MESSAGE = 'REMOVE_NOTIFICATION_MESSAGE';
export const REMOVE_NOTIFICATION_MESSAGES = 'REMOVE_NOTIFICATION_MESSAGES';
export const NO_REMAINING_MESSAGES = 'NO_REMAINING_MESSAGES';
export const REMAINING_MESSAGES = 'REMAINING_MESSAGES';
export const RESET_LOAD_MORE_NOTIFICATION_MESSAGES = 'RESET_LOAD_MORE_NOTIFICATION_MESSAGES';
export const RECEIVE_SCHOLARSHIP_PROGRAM_SUBJECTS = 'RECEIVE_SCHOLARSHIP_PROGRAM_SUBJECTS';

export const receiveScholarshipProgramSubjects = (scholarshipPrograms) => ({
  type: RECEIVE_SCHOLARSHIP_PROGRAM_SUBJECTS,
  scholarshipPrograms,
});

export const removeNotificationMessage = (sequenceNumber) => ({
  type: REMOVE_NOTIFICATION_MESSAGE,
  sequenceNumber,
});

export const removeNotificationMessages = () => ({
  type: REMOVE_NOTIFICATION_MESSAGES,
});

export const receiveNotificationMessages = (notificationMessages) => ({
  type: RECEIVE_NOTIFICATION_MESSAGES,
  notificationMessages,
});

export async function requestPrograms(programIdsToFetch) {
  if (!programIdsToFetch.length) return;

  const url = '/v1/scholarship-programs';
  const programColleges = {programs: {}, colleges: {}};
  let scholarshipPrograms = [];

  try {
    scholarshipPrograms = await req({
      url,
      data: {
        programIds: programIdsToFetch.join(','),
        fields: ['_id', 'collegeName', 'collegeSlug', 'collegeId'].join(','),
      },
    });
  } catch (error) {
    console.trace('error fetching programs', error);
  }

  return scholarshipPrograms.reduce((acc, program) => {
    acc.colleges[program.collegeId] = {
      id: program.collegeId,
      name: program.collegeName,
      slug: program.collegeSlug,
    };

    acc.programs[program._id] = program.collegeId;

    return acc;
  }, programColleges);
}

export const MAX_PROGRAM_IDS = 100;

export function getProgramIdsToFetch(notifications, fetchedPrograms) {
  const programIdsToFetch = new Set();

  notifications.forEach(function (notification) {
    const subjects = notification.message.subjects;

    subjects.forEach(function (subject) {
      const programId = subject.programId;
      if (programId && !fetchedPrograms[programId]) {
        programIdsToFetch.add(programId);
      }
    });
  });

  return Array.from(programIdsToFetch);
}

function fetchMissingProgramSubjects(notifications, batchSize = MAX_PROGRAM_IDS) {
  return async function (dispatch, getState) {
    const {scholarshipProgramSubjects} = getState();

    const programIdsToFetch = getProgramIdsToFetch(
      notifications,
      scholarshipProgramSubjects.programs
    );

    if (programIdsToFetch.length) {
      // Fetch programs in batches to avoid hitting the max URL length
      inBatches(programIdsToFetch, batchSize, async (programIds) => {
        const {colleges, programs} = await requestPrograms(programIds);

        dispatch(Action(RECEIVE_COLLEGE_NAMES, {colleges}));
        dispatch(Action(RECEIVE_PROGRAM_COLLEGE, {programs}));
      });
    }
  };
}

export const getNotificationMessagePage =
  (limit = 10, lastSequenceNumber = null) =>
  async (dispatch, getState) => {
    const {loadMoreNotificationMessages} = getState();
    if (!loadMoreNotificationMessages) {
      return;
    }

    JWTService.token().then((token) => {
      dispatch(startLoading('notificationMessages'));
      getUserId().then((userId) => {
        const requestUrl =
          `/v1/notification-messages/${userId}?limit=${limit}` +
          (lastSequenceNumber ? `&last=${lastSequenceNumber}` : '');
        return req({
          url: requestUrl,
          method: 'GET',
          headers: {Authorization: `Bearer ${token}`},
          data: {},
        }).then(async ({notifications}) => {
          const messageCount = Object.keys(notifications).length;
          if (!messageCount || messageCount < limit) {
            dispatch({type: NO_REMAINING_MESSAGES});
          }

          const {notificationMessages, sequenceNumbers} = formatNotificationMessages(notifications);

          await dispatch(fetchMissingProgramSubjects(notifications));

          dispatch(receiveNotificationMessages(notificationMessages));
          dispatch(receiveNotificationMessageIndexes(sequenceNumbers));
          dispatch(stopLoading('notificationMessages'));
          return markAsRead(userId, notifications);
        });
      });
    });
  };

export const formatNotificationMessages = (notifications) => {
  const notificationMessages = {};
  const sequenceNumbers = [];

  notifications.forEach(
    ({
      sequenceNumber,
      read,
      deleted,
      message: {
        category,
        prefix,
        multipleSubjectsPrefix,
        subjectIndex,
        suffix,
        subjects,
        timestamp,
      },
    }) => {
      sequenceNumbers.push(sequenceNumber);
      notificationMessages[sequenceNumber] = {
        deleted,
        read,
        timestamp,
        category,
        suffix,
        subjects: normalizeSubjects(subjects),
        prefix,
        multipleSubjectsPrefix,
        subjectIndex,
      };
    }
  );

  return {notificationMessages, sequenceNumbers};
};

export const normalizeSubjects = (subjects) => {
  if (!subjects) {
    return null;
  }

  return subjects.map(normalizeSubject);
};

export const normalizeSubject = (subject) => {
  const normalizedSubject = {...subject};

  if (subject.id && !subject._id) {
    normalizedSubject._id = subject.id;
  }

  return normalizedSubject;
};

export const markAsRead = (userId, notifications) => {
  const sequenceNumbers = [];

  notifications.forEach(({sequenceNumber, read}) => {
    if (!read) {
      sequenceNumbers.push(sequenceNumber);
    }
  });

  if (!sequenceNumbers.length) {
    return;
  }

  JWTService.token().then((token) =>
    req({
      url: `/v1/notification-messages/${userId}`,
      method: 'PATCH',
      headers: {Authorization: `Bearer ${token}`},
      data: {
        attributes: {read: true},
        sequenceNumbers,
      },
    })
  );
};

export const markAsDeleted = (sequenceNumber) => async (dispatch) => {
  const userId = await getUserId();

  JWTService.token().then((token) => {
    dispatch(removeNotificationMessageIndex(sequenceNumber));
    dispatch(removeNotificationMessage(sequenceNumber));
    return req({
      url: `/v1/notification-messages/${userId}`,
      method: 'PATCH',
      headers: {Authorization: `Bearer ${token}`},
      data: {
        attributes: {deleted: true},
        sequenceNumbers: [sequenceNumber],
      },
    });
  });
};

export const clearMessageData = () => (dispatch) => {
  dispatch(removeNotificationMessages());
  dispatch(removeNotificationMessageIndexes());
};

export const resetLoadMoreNotificationMessages = () => (dispatch) =>
  dispatch({type: REMAINING_MESSAGES});
