import PropTypes from 'prop-types';
import React from 'react';
import LabeledHorizontalPlotContainer from '../labeled-horizontal-plot-container';
import LabeledHorizontalPlot from '../labeled-horizontal-plot';
import {aboveBelowAverage} from 'shared/college-profile/utils';
import {withCommas} from 'shared/utils';

class CostComparisonWithAverage extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.node,
    // E.g. NationalCollegeData
    dataSource: PropTypes.object.isRequired,
    // E.g. averageNetPrice
    attributeOnCollege: PropTypes.string.isRequired,
    // E.g. averageNetPricePrivate
    attributeInSource: PropTypes.string.isRequired,
    // E.g. CollegeListing
    college: PropTypes.object.isRequired,
  };

  render() {
    const {label, tooltip, dataSource, attributeOnCollege, attributeInSource, college} = this.props;
    const value = college[attributeOnCollege];

    if (!value) {
      return null;
    }

    const average = dataSource[attributeInSource]['median'];
    const averageCost = `$${withCommas(average)}`;
    const min = dataSource[attributeInSource]['min'];
    const max = dataSource[attributeInSource]['max'];

    return (
      <div className="college-profile-cost-comparison">
        <p className="college-profile-cost-comparison-label-text">{label}</p>
        {tooltip && tooltip}
        <div
          aria-label={`${label} $${withCommas(
            value
          )} as compared to the national average ${averageCost}`}
        >
          <LabeledHorizontalPlotContainer value={value} subtext={aboveBelowAverage(value, average)}>
            <LabeledHorizontalPlot
              labelText={`${averageCost} Nat'l Avg`}
              labelValue={average}
              max={max}
              min={min}
              value={value}
            />
          </LabeledHorizontalPlotContainer>
        </div>
      </div>
    );
  }
}

export default CostComparisonWithAverage;
