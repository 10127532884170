import PropTypes from 'prop-types';
import React from 'react';
import {filter, map} from 'lodash';
import ScholarshipCTALink from './scholarship-cta-link';

class ScholarshipCTA extends React.Component {
  static propTypes = {
    scholarships: PropTypes.array,
    type: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    collegeProfile: PropTypes.object.isRequired,
  };

  render() {
    const {scholarships, type, openModal, collegeProfile} = this.props;

    let candidates = filter(scholarships, (scholarship) => scholarship.type === type);

    if (candidates.length === 0) {
      return null;
    }

    // return first two visit types.
    candidates = candidates.slice(0, 2);

    return (
      <div className="college-profile-scholarship-cta">
        {map(candidates, (scholarship, index) => (
          <div key={index} className="college-profile-scholarship-cta-item">
            <div className="college-profile-scholarship-cta-text">
              <p className="college-profile-scholarship-cta-title">{scholarship.title}</p>
              <p
                className="college-profile-scholarship-cta-description"
                dangerouslySetInnerHTML={{__html: scholarship.description}}
              />
            </div>
            <ScholarshipCTALink
              type={type}
              source="about"
              openModal={openModal}
              classType={scholarship.scholarshipType}
              pending={this.props.collegeProfile.modalPending[type]}
              showScholarshipCta
              scholarship={scholarship}
              collegeName={collegeProfile.college.name}
              collegeId={collegeProfile.college._id}
              userInfo={collegeProfile.userInfo}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default ScholarshipCTA;
