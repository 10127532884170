import req from 'shared/req';

export const RECEIVE_COLLEGE_SUCCESS_ITEM_TYPES = 'RECEIVE_COLLEGE_SUCCESS_ITEM_TYPES';

export const receiveCollegeSuccessItemTypes = (collegeSuccessItemTypes) => ({
  type: RECEIVE_COLLEGE_SUCCESS_ITEM_TYPES,
  collegeSuccessItemTypes,
});

export const COLLEGE_SUCCESS_ITEM_TYPE_FIELDS = [
  'emoji',
  'title',
  'description',
  'details',
  'collegeSuccessItemType',
  'completedAtText',
  'allowMany',
  'endDate',
  'startDay',
  'startMonth',
  'startYear',
].join(',');

export const getCollegeSuccessItemTypes = () => (dispatch) =>
  req({
    url: `/v1/college-success-items?fields=${COLLEGE_SUCCESS_ITEM_TYPE_FIELDS}`,
  }).then((collegeSuccessItemTypes) =>
    dispatch(receiveCollegeSuccessItemTypes(collegeSuccessItemTypes))
  );
