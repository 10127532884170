import React from 'react';
import {Link, LinkProps} from 'react-router-dom';

export interface MobileLinkProps {
  to: LinkProps['to'];
  children: React.ReactNode;
  className?: string;
  role?: string;
}
/**
 * This component is a wrapper around the IonRouterLink and the Link component from react-router-dom.
 *
 * It lets us use the same component in both Ionic and React, and the correct one will render depending on the platform.
 */

const MobileLink = ({to, children, className, role}: MobileLinkProps): React.ReactElement => (
  <Link to={to} role={role} className={className}>
    {children}
  </Link>
);

export default MobileLink;
