import {StampColors} from '../../src/components/Stamp/Stamp';

export const PLANNING_TO_APPLY = 'planning-to-apply';
export const APPLIED = 'applied';
export const ACCEPTED = 'accepted';
export const PLANNING_TO_ENROLL = 'planning-to-enroll';
export const ENROLLED = 'enrolled';
export const NOT_INTERESTED = 'not-interested';
export const UNDECIDED = 'undecided';

export const STATUS_LABELS = {
  [PLANNING_TO_APPLY]: 'Planning to apply',
  [APPLIED]: 'Applied',
  [ACCEPTED]: 'Accepted',
  [PLANNING_TO_ENROLL]: 'Planning to enroll',
  [ENROLLED]: 'Enrolled',
  [NOT_INTERESTED]: 'Not interested',
  [UNDECIDED]: 'Undecided',
};

export const STATUS_COLORS: Record<string, StampColors> = {
  [PLANNING_TO_APPLY]: 'violet',
  [APPLIED]: 'blue',
  [ACCEPTED]: 'cyan',
  [PLANNING_TO_ENROLL]: 'green',
  [ENROLLED]: 'dark-green',
  [NOT_INTERESTED]: 'black',
  [UNDECIDED]: 'gray',
};

// Record<[key type here], [value type here]>
