import req from 'shared/req';

export interface ReferralResponse {
  status: string;
  identifier: string;
  type: 'mobile' | 'email' | string;
}

export default class ReferralService {
  static async sendReferrals(
    contacts: string[],
    referralSource: string
  ): Promise<(ReferralResponse | null)[]> {
    return Promise.all(
      contacts.map((contact) => ReferralService.sendReferral(contact, referralSource))
    );
  }

  static async sendReferral(
    contactInfo: string,
    referralSource: string,
    silent?: false
  ): Promise<ReferralResponse | null> {
    let response;
    try {
      const url = '/v1/users/me/referrals';
      response = await req({
        url,
        method: 'POST',
        data: {
          identifier: contactInfo,
          referralType: referralSource,
          silent: silent,
        },
      });
    } catch (error) {
      console.log(`Failed inviting ${contactInfo}`, error);
      return null;
    }

    return response;
  }
}
