import {merge, omit, keyBy} from 'lodash';
import {RECEIVE_ACTIVITY_INSTITUTION_LEADERSHIP_POSITIONS} from 'shared/portfolio/actions/activities';
import {RECEIVE_COLLEGE_COURSE_INSTITUTION_GRADES_PRESELECTS} from 'shared/portfolio/actions/college-courses';
import {RECEIVE_INSTITUTIONS, REMOVE_INSTITUTION} from '../actions/portfolio';
import {UPDATE_CURRENT_INSTITUTION, RECEIVE_INSTITUTION} from 'portfolio/actions/institution';

// Can delete after transfers is moved to institutionsReducer2, primary difference is there is not inst- prefix
export function institutionsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_COLLEGE_COURSE_INSTITUTION_GRADES_PRESELECTS:
    case RECEIVE_ACTIVITY_INSTITUTION_LEADERSHIP_POSITIONS:
    case RECEIVE_INSTITUTIONS:
      return merge({}, state, action.institutions);
    case REMOVE_INSTITUTION:
      return omit(state, `inst-${action.institutionId}`);
    case RECEIVE_INSTITUTION:
    case UPDATE_CURRENT_INSTITUTION:
    default:
      return state;
  }
}

export function institutionsReducer2(state = {}, action) {
  switch (action.type) {
    case RECEIVE_INSTITUTIONS:
      return {...state, ...keyBy(action.institutions, '_id')};
    case RECEIVE_INSTITUTION:
    case UPDATE_CURRENT_INSTITUTION:
      return {...state, [action.institution._id]: action.institution};
    case REMOVE_INSTITUTION:
      return omit(state, action.institutionId);
    default:
      return state;
  }
}
