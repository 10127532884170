import React from 'react';
import {useSelector} from 'react-redux';
import CheckboxGroupFilter from 'shared/components/filters/checkbox-group-filter';
import Text from 'shared/components/text';
import ScholarshipBadge from 'src/components/CollegeSearch/ScholarshipBadge';
import {UserType} from 'src/types/enums';
import {Store} from 'src/types/store';

interface PropyProps {
  getColleges: () => void;
}

const AttributeFilters = ({getColleges}: PropyProps) => {
  const userType = useSelector((state: Store) => state.collegeProfile.userInfo.user);

  return (
    <div className="college-search-filters-section">
      <fieldset role="group" aria-label="Filter by Attribute">
        <legend className="college-search-filters-section-legend">
          <Text weight={5} emphasis>
            Show Only
          </Text>
        </legend>
        <CheckboxGroupFilter
          name="offering-microscholarships"
          options={{
            1: (
              <span className="college-search-awards-scholarships-filter">
                Awards scholarships on RaiseMe <ScholarshipBadge />
              </span>
            ),
          }}
          onChange={getColleges}
        />
        {userType !== UserType.PUBLIC && (
          <CheckboxGroupFilter
            name="following"
            options={{
              1: userType === UserType.EDUCATOR ? 'Followed by my students' : 'Currently Following',
            }}
            onChange={getColleges}
          />
        )}
      </fieldset>
    </div>
  );
};

export default AttributeFilters;
