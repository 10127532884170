// Institution types
export interface InstitutionType {
  type: string;
  singular: string;
  plural: string;
  example: string;
}

export const institutionTypes: Record<string, InstitutionType> = {
  'high-school': {
    type: 'high-school',
    singular: 'High School',
    plural: 'High Schools',
    example: 'Lincoln High, Stockton, CA',
  },
  'community-college': {
    type: 'community-college',
    singular: 'Community College',
    plural: 'Community Colleges',
    example: 'Dean College, Franklin, MA',
  },
  'dual-enrollment': {
    type: 'dual-enrollment',
    singular: 'College',
    plural: 'Colleges',
    example: 'Dean College, Franklin, MA',
  },
};

// Button colors
export const buttonColors = [
  'primary',
  'blue',
  'light-blue',
  'alternate',
  'white',
  'gray',
  'negative',
  'red',
  'google',
  'gray-link',
];
