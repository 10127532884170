import {connect} from 'react-redux';
import FilterTags from 'src/components/CollegeSearch/FilterTags/FilterTags';
import {getColleges, resetPage} from 'src/redux/collegeSearch/actions';
import {dismissTag} from 'shared/actions/filters';

const mapStateToProps = (state) => ({
  tags: state.filters,
  userType: state.collegeProfile.userInfo.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleClose(name, valueId) {
    dispatch(dismissTag({name, valueId}));
    dispatch(resetPage());
    dispatch(getColleges());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterTags);
