import req from 'shared/req';
// Not a typical rest resource, can't use CRUDService
export interface CityRequest {
  cities: string[];
  inEu: boolean;
}

export default class CityService {
  static async index(data: object): Promise<CityRequest> {
    return await req({url: '/v1/cities', data: data});
  }
}
