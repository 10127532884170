import {CREATE_POPOVER, DESTROY_POPOVER} from 'shared/actions/popovers';

export function popoversReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_POPOVER:
      return {
        ...state,
        [action.id]: true,
      };
    case DESTROY_POPOVER:
      return {
        ...state,
        [action.id]: false,
      };
    default:
      return state;
  }
}
