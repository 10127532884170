import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import InfoIcon from 'shared/components/info-icon';
import 'stylesheets/components/shared-tooltip.scss';

const GpaTooltip = () => {
  const {pathname} = useLocation();

  return (
    <span className="shared-gpa-modal-tooltip">
      <Link
        className="shared-gpa-modal-tooltip-info-button info-icon-button"
        to={`${pathname}/gpa`}
        aria-label="GPA tooltip (opens dialog)"
      >
        <InfoIcon />
      </Link>
    </span>
  );
};

export default GpaTooltip;
