import {differenceBy} from 'lodash';

// action-types
export const RECEIVE_SATISFACTION = 'EDUCATION_ONBOARDING/RECEIVE_SATISFACTION';
export const RECEIVE_GOALS = 'EDUCATION_ONBOARDING/RECEIVE_GOALS';
import {GET_COLLEGE} from 'shared/college-profile/actions/college-profile';
import {getStudent, updateUser} from 'shared/actions/student';
import {getCollege} from 'shared/actions/college';

export const updateSatisfaction = (satisfaction) => ({
  type: RECEIVE_SATISFACTION,
  satisfaction,
});

export const addGoal = (goal) => (dispatch, getState) =>
  dispatch({
    type: RECEIVE_GOALS,
    goals: getState().goals.concat(goal),
  });

export const removeGoal = (goal) => (dispatch, getState) =>
  dispatch({
    type: RECEIVE_GOALS,
    goals: differenceBy(getState().goals, [goal], 'name'),
  });

export const getStudentInfo = () => getStudent('me', 'firstName,currentInstitution');

export const getCollegeInfo = () => (dispatch, getState) => {
  const {
    student: {
      currentInstitution: {schoolId},
    },
  } = getState();

  getCollege(schoolId, ['logo', 'redeemableTerm'].join(',')).then((college) =>
    dispatch({type: GET_COLLEGE, college})
  );
};

export const setOnboardingCompletedAt = () => () =>
  updateUser({
    onboardingCompletedAt: Date.now(),
  });
