import React from 'react';
import {navigateToRequirements} from './college-status-text';

interface Props {
  collegeProfile: {
    college: {
      earningRelationship: {
        statusType: string;
        deadlineDay: string;
        deadlineMonth: string;
      };
      partnerApplicationDeadlineMonth: string;
      partnerApplicationDeadlineDay: string;
      partnerSubmissionDeadlineMonth: string;
      partnerSubmissionDeadlineDay: string;
    };
  };
  totalCredits: number;
  requiredCredits: number;
}

const CreditHoursStatusText = ({
  collegeProfile: {
    college: {
      earningRelationship: {statusType},
      partnerApplicationDeadlineMonth,
      partnerApplicationDeadlineDay,
      partnerSubmissionDeadlineMonth,
      partnerSubmissionDeadlineDay,
    },
  },
  totalCredits,
  requiredCredits,
}: Props) => {
  const missingCredits = requiredCredits - totalCredits;
  const applicationDeadlineMonth = partnerApplicationDeadlineMonth + 1;
  const submissionDeadlineMonth = partnerSubmissionDeadlineMonth + 1;

  switch (statusType) {
    case 'submitted':
      return (
        <div>
          <p className="college-profile-progress-card-positive-status">
            {`Way to go! Your earnings from this college are almost locked in. Apply by
                ${applicationDeadlineMonth}/${partnerApplicationDeadlineDay}, enroll, and `}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigateToRequirements(true);
              }}
            >
              meet any additional requirements
            </a>
            {' set by the school (if needed) to be awarded.'}
          </p>
        </div>
      );
    case 'boost_gpa':
      return (
        <p className="college-profile-progress-card-no-status">
          {`Add classes as you go! At least ${missingCredits}
          more credit hours needed by the earnings deadline.`}
        </p>
      );
    case 'submission_period_deferred':
    case 'earning_deadline_soon': {
      if (missingCredits > 0) {
        return (
          <p className="college-profile-progress-card-warning-status">
            {`Add classes as you go! At least ${missingCredits} more
              credit hours needed by ${submissionDeadlineMonth}/${partnerSubmissionDeadlineDay}.`}
          </p>
        );
      } else {
        return (
          <p className="college-profile-progress-card-positive-status">
            {`You've met the minimum ${requiredCredits} credit hours - be sure
              to add all your credit hours by ${submissionDeadlineMonth}/${partnerSubmissionDeadlineDay}.`}
          </p>
        );
      }
    }
    case 'earning': {
      if (missingCredits > 0) {
        return (
          <p className="college-profile-progress-card-no-status">
            {`Add classes as you go! At least ${missingCredits}
              more credit hours needed by the earnings deadline.`}
          </p>
        );
      } else {
        return (
          <p className="college-profile-progress-card-no-status">
            {`You've met the minimum ${requiredCredits} credit hours - be
              sure to add all your credit hours by the earnings deadline.`}
          </p>
        );
      }
    }
    default:
      return null;
  }
};

export default CreditHoursStatusText;
