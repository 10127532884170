import React from 'react';
import {IonPickerLegacy} from '@ionic/react';
import {
  getColleges,
  resetPage,
  updateSortBy,
  updateSortOrder,
} from 'src/redux/collegeSearch/actions';

import {HS_SORT_OPTIONS, PUBLIC_SORT_OPTIONS, TRANSFER_SORT_OPTIONS} from 'shared/utils';
import {useDispatch, useSelector} from 'react-redux';
import {Store} from 'src/types/store';

const SortByModalMobile = () => {
  const authenticated = useSelector((state: Store) => state.authenticated);
  const transfer = useSelector((state: Store) => state.transfer);

  const {sortBy, sortOrder} = useSelector((state: Store) => state.collegeSearch);
  const dispatch = useDispatch();
  let sortOptions;

  if (!authenticated) {
    sortOptions = PUBLIC_SORT_OPTIONS;
  } else {
    sortOptions = transfer ? TRANSFER_SORT_OPTIONS : HS_SORT_OPTIONS;
  }

  const updateSort = (type, order) => {
    dispatch(updateSortBy(type));
    dispatch(updateSortOrder(order));
    dispatch(resetPage());
    dispatch(getColleges());
  };

  return (
    <IonPickerLegacy
      className="college-search-sort-by-ionic"
      trigger="open-sort-by-ionic-picker"
      columns={[
        {
          name: 'sort',
          selectedIndex: sortOptions.findIndex(
            ({type, order}) => sortBy === type && sortOrder === order
          ),
          options: sortOptions.map(({value, type, order}) => ({
            text: value,
            value: {type, order},
          })),
        },
      ]}
      buttons={[
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Done',
          handler: ({sort: {value}}): void => {
            updateSort(value.type, value.order);
          },
        },
      ]}
    />
  );
};

export default SortByModalMobile;
