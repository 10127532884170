// admin/university
import {
  RECEIVE_PHOTO_LISTINGS,
  INCREMENT_PHOTO_LISTING_COUNTER,
  RESET_PHOTO_LISTING_COUNTER,
  UPDATE_USE_RANKED_STATE,
} from '../actions/photoListing';
import {merge} from 'lodash';

const defaultState = {
  added: [],
  deleted: [],
  fileProgressCount: 0,
  previousCount: -1,
  ranked: [],
  useRanked: true,
};

let newState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_PHOTO_LISTINGS:
      newState = {
        fileProgressCount: 0,
        useRanked: action.photoListings.use_ranked,
      };
      return merge({}, state, action.photoListings, newState);
    case INCREMENT_PHOTO_LISTING_COUNTER:
      newState = {
        fileProgressCount: (state.fileProgressCount += 1),
        previousCount: state.fileProgressCount,
      };
      return merge({}, state, newState);
    case RESET_PHOTO_LISTING_COUNTER:
      newState = {
        fileProgressCount: 0,
        previousCount: -1,
      };
      return merge({}, state, newState);
    case UPDATE_USE_RANKED_STATE:
      newState = {
        useRanked: action.useRanked,
      };
      return merge({}, state, newState);
    default:
      return state;
  }
};
