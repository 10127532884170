import CRUDService from 'src/services/CRUDService';

export interface Campaign {
  id: number;
  sponsorId: string;
  filters: object;
  educationPhase: string;
  name: string;
  campaignFollowsCount?: number;
}

export default new CRUDService<Campaign>('/v1/campaigns', true);
