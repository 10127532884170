import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import EarningsText from './earnings-text';
import BoostGpaText from './boost-gpa-text';
import UsOnlyText from './us-only-text';
import DeadlinePassedText from './deadline-passed-text';
import TotalEarningsText from './total-earnings-text';
import ChurnedPartnerText from './churned-partner-text';
import Loading from 'shared/components/loading';

class StatusText extends React.PureComponent {
  static propTypes = {
    following: PropTypes.bool.isRequired,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    earningRelationship: PropTypes.shape({
      statusType: PropTypes.string.isRequired,
      minGPA: PropTypes.number,
      hasEarnings: PropTypes.bool,
    }).isRequired,
    condensed: PropTypes.bool,
    amountLoading: PropTypes.bool,
    simplified: PropTypes.bool,
  };

  getDisplay = (eligibility) => {
    const {
      amount,
      following,
      earningRelationship,
      earningRelationship: {hasEarnings},
    } = this.props;

    switch (eligibility) {
      case 'earning':
      case 'earning_deadline_soon':
      case 'submission_period_deferred':
        return following ? <EarningsText amount={amount} /> : null;
      case 'boost_gpa':
        return <BoostGpaText relationship={earningRelationship} />;
      case 'us_citizens_only':
        return <UsOnlyText />;
      case 'deadline_passed':
        return <DeadlinePassedText />;
      case 'submitted':
        return <TotalEarningsText amount={amount} />;
      case 'churned':
        return hasEarnings ? <ChurnedPartnerText /> : null;
      default:
        return null;
    }
  };

  render() {
    const {
      earningRelationship: {statusType},
      condensed,
      amountLoading,
      simplified,
    } = this.props;

    return (
      <div
        className={classnames('shared-college-card-status-text', {
          condensed: condensed || simplified,
        })}
      >
        {amountLoading ? <Loading /> : this.getDisplay(statusType)}
      </div>
    );
  }
}

export default StatusText;
