import PropTypes from 'prop-types';

export const CardInfoShape = PropTypes.shape({
  category: PropTypes.string.isRequired,
  rangeEnding: PropTypes.string.isRequired,
  portfolioUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  popularity: PropTypes.number,
  schoolName: PropTypes.string,
});

export const StudentAvatarShape = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  photo: PropTypes.string,
});

export const OpportunityCardShape = {
  cardInfo: CardInfoShape.isRequired,
  type: PropTypes.string.isRequired,
  minAmount: PropTypes.number.isRequired,
  maxAmount: PropTypes.number.isRequired,
  collegesOffering: PropTypes.object.isRequired,
  showCTAs: PropTypes.bool.isRequired,
  detailPagePath: PropTypes.string.isRequired,
  studentAvatars: PropTypes.arrayOf(StudentAvatarShape),
};
