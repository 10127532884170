// shared
import req from 'shared/req';
import {START_LOADING, STOP_LOADING} from 'shared/actions/loading';

export const RECEIVE_STUDENTS = 'DISTRICT_ADMIN/RECEIVE_STUDENTS';
export const CLEAR_STUDENTS = 'DISTRICT_ADMIN/CLEAR_STUDENTS';

export const STUDENTS_PER_PAGE = 20;

export const fetchStudents =
  (schoolId, options = {}) =>
  (dispatch) => {
    const identifier = 'students';

    dispatch({
      type: START_LOADING,
      identifier,
    });

    return req({
      url: '/v1/students',
      method: 'get',
      data: {schoolId, ...options},
    }).then((students) => {
      dispatch({
        type: RECEIVE_STUDENTS,
        students,
      });

      return dispatch({
        type: STOP_LOADING,
        identifier,
      });
    });
  };

export const fetchSortedStudents =
  ({sortField, sortDirection, page, limit}) =>
  (dispatch, getState) => {
    const {
      school: {_id},
    } = getState();

    dispatch(fetchStudents(_id, {sortDirection, sortField, limit, skip: page * limit}));
  };

export const clearStudents = () => (dispatch) => dispatch({type: CLEAR_STUDENTS});
