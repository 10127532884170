import {decamelize} from 'humps';
import {forEach, isNil, map, merge} from 'lodash';
import {normalize} from 'normalizr';
import {RECEIVE_COURSES} from 'portfolio/actions/courses';
import {showSuccessfulCleverLogin} from 'shared/actions/clever';
import {getUser} from 'shared/actions/student';
import req from 'shared/req';
import {UserType} from 'src/types/enums';
import schema from '../schemas';
import {RECEIVE_ACTIVITIES, RECEIVE_LEADERSHIP_POSITIONS} from './activities';
import {RECEIVE_COLLEGE_COURSES} from './college-courses';
import {RECEIVE_COLLEGE_EVENTS} from './college-events';
import {RECEIVE_COLLEGE_SUCCESS_ITEMS} from './college-success-items';
import {RECEIVE_COMMUNITY_SERVICE_EVENTS} from './community-service';
import {RECEIVE_ATTENDANCE, RECEIVE_COURSE_PRESELECTS, RECEIVE_GRADES} from './courses';
import {RECEIVE_HONOR_AWARDS} from './honor-awards';
import {RECEIVE_ALL_MULTIMEDIA} from './multimedia';
import {RECEIVE_STUDENT} from './student';
import {RECEIVE_TEST_SCORES} from './test-scores';
import {RECEIVE_WORK_EXPERIENCES} from './work-experiences';
import UserService from '../../../src/services/UserService';
import {errorMessage} from '../../actions/notifications';

export const RECEIVE_INSTITUTIONS = 'RECEIVE_INSTITUTIONS';
export const REMOVE_INSTITUTION = 'REMOVE_INSTITUTION';
export const LOADING_TRUE = 'LOADING_TRUE';
export const LOADING_FALSE = 'LOADING_FALSE';
export const RECEIVE_PORTFOLIO = 'RECEIVE_PORTFOLIO';
export const RESET_PORTFOLIO = 'RESET_PORTFOLIO';

const INITIAL_STUDENT_FIELDS = ['firstName', 'lastName', 'bannerHero', 'image', 'cleverId'].join(
  ','
);

export const STUDENT_FIELDS = [
  'bio',
  'followedCollegeIds',
  'portfolio',
  'city',
  'state',
  'subdivision',
  'country',
  'institutions',
  'currentInstitution',
  'createdAt',
  'educationPhase',
  'email',
  'skippedChecklistItems',
  'studentInvitations',
  'isUsCitizenOrResident',
  'activityClaimVerifications',
  'loc',
].join(',');

export const CC_STUDENT_FIELDS = [
  'communityCollegeInfo',
  'followedCollegesTransferTerms',
  'followedCollegesNextTransferTerms',
].join(',');

export const HS_STUDENT_FIELDS = [
  'highSchoolInfo',
  'parentEmail',
  'fafsaSubmission',
  'academicInterestIds',
  'coursePreselects',
  'academicInterests',
].join(',');

export const UNDERGRADUATE_STUDENT_FIELD = 'undergraduateInfo';

// Get just enough data to show on the loading state.
export const getInitialUserInfo = () => (dispatch) =>
  getUser('me', INITIAL_STUDENT_FIELDS).then((student) => {
    if (student.cleverId) {
      dispatch(showSuccessfulCleverLogin());
    }
    dispatch({type: RECEIVE_STUDENT, student});
  });

export const getFieldsForStudent = (educationPhase) => {
  const FIELDS_SPECIFIC_TO_EDUCATION_PHASE = {
    [UserType.HS_STUDENT]: HS_STUDENT_FIELDS,
    [UserType.UNDERGRADUATE_STUDENT]: UNDERGRADUATE_STUDENT_FIELD,
    [UserType.CC_STUDENT]: CC_STUDENT_FIELDS,
  };

  return `${STUDENT_FIELDS},${FIELDS_SPECIFIC_TO_EDUCATION_PHASE[educationPhase]}`;
};

// temporarily added here for tracking the API performance.
export const getFullUser = (id = 'me', fields = '_id') => {
  const url = `/v1/users-full/${id}`;

  return req({
    url,
    method: 'get',
    data: {fields},
  });
};

// Get the remaining information needed for the portfolio.
export const getUserInfo =
  (educationPhase = UserType.HS_STUDENT) =>
  (dispatch, getState) =>
    getFullUser('me', getFieldsForStudent(educationPhase))
      .then((user) => {
        const {student: initialUserData} = getState();
        const student = merge(user, initialUserData);
        const {
          portfolio,
          currentInstitution,
          coursePreselects,
          institutions,
          portfolio: {
            attendance,
            testScores,
            communityServiceEvents,
            awards,
            courses,
            collegeSuccessItems,
          },
        } = student;

        if (student.currentInstitution.campus) {
          student.currentInstitution.campus = JSON.parse(student.currentInstitution.campus);
        }

        const normalizedCoursePreselects = formatCoursePreselects({
          coursePreselects,
          currentInstitution,
        });

        const normalizedPortfolio = normalize(
          {
            institutions,
            coursePreselects: normalizedCoursePreselects,
            ...portfolio,
            testScores: formatTestScores(testScores),
            communityServiceEvents: formatCommunityServiceEvents(communityServiceEvents),
            honorAwards: awards,
          },
          schema
        ).entities;

        // aboutMe
        dispatch({
          type: RECEIVE_STUDENT,
          student,
        });

        // activities
        dispatch({
          type: RECEIVE_ACTIVITIES,
          activities: normalizedPortfolio.activities,
        });

        // attendance
        dispatch({type: RECEIVE_ATTENDANCE, attendance});

        // collegeEvents
        dispatch({
          type: RECEIVE_COLLEGE_EVENTS,
          collegeEvents: normalizedPortfolio.collegeEvents,
        });

        // collegeSuccess
        dispatch({
          type: RECEIVE_COLLEGE_SUCCESS_ITEMS,
          items: formatCollegeSuccess(collegeSuccessItems),
        });

        // communityServiceEvents
        dispatch({
          type: RECEIVE_COMMUNITY_SERVICE_EVENTS,
          communityServiceEvents: normalizedPortfolio.communityServiceEvents,
        });

        // courses or collegeCourses
        if (educationPhase === 'high-school') {
          dispatch({
            type: RECEIVE_COURSES,
            courses: courses.map((course) => {
              // Display 'Dual Enrollment' on frontend instead of 'College Level'
              course.courseType =
                course.courseType === 'College Level' ? 'Dual Enrollment' : course.courseType;

              return course;
            }),
          });
        } else {
          // TODO: move transfer students to bulk entry and use denormalized
          // CollegeCourses.
          dispatch({
            type: RECEIVE_COLLEGE_COURSES,
            collegeCourses: normalizedPortfolio.collegeCourses,
          });
        }

        // coursePreselects
        dispatch({
          type: RECEIVE_COURSE_PRESELECTS,
          coursePreselects: normalizedCoursePreselects,
        });

        // grades
        dispatch({
          type: RECEIVE_GRADES,
          grades: normalizedPortfolio.grades,
        });

        // honorAwards
        dispatch({
          type: RECEIVE_HONOR_AWARDS,
          honorAwards: normalizedPortfolio.honorAwards,
        });

        // institutions
        dispatch({
          type: RECEIVE_INSTITUTIONS,
          institutions: normalizedPortfolio.institutions,
        });

        // leadershipPositions
        dispatch({
          type: RECEIVE_LEADERSHIP_POSITIONS,
          leadershipPositions: normalizedPortfolio.leadershipPositions,
        });

        // student
        dispatch({type: RECEIVE_STUDENT, student});

        // testScores
        dispatch({
          type: RECEIVE_TEST_SCORES,
          testScores: normalizedPortfolio.testScores,
        });

        // workExperiences
        dispatch({
          type: RECEIVE_WORK_EXPERIENCES,
          workExperiences: normalizedPortfolio.workExperiences,
        });

        // multimediaItems
        dispatch({
          type: RECEIVE_ALL_MULTIMEDIA,
          multimediaItems: normalizedPortfolio.multimedia,
        });
      })
      .then(() => dispatch({type: LOADING_FALSE}));

export const getCurrentInstitution = () => async (dispatch, getState) => {
  try {
    const user = await UserService.getData(['currentInstitution']);
    const {student: initialUserData} = getState();
    const student = merge(user, initialUserData);

    if (student.currentInstitution?.campus) {
      student.currentInstitution.campus = JSON.parse(student.currentInstitution.campus);
    }

    dispatch({
      type: RECEIVE_STUDENT,
      student,
    });
  } catch (error) {
    console.error('Error fetching current institution:', error);
  }
};

export const getPerfectAttendanceInfo = () => async (dispatch, getState) => {
  try {
    const user = await UserService.getData(['highSchoolInfo', 'portfolio']);
    const {student: initialUserData} = getState();

    const student = merge(user, initialUserData);

    const attendance = student?.portfolio?.attendance || [];

    dispatch({
      type: RECEIVE_STUDENT,
      student,
    });

    dispatch({type: RECEIVE_ATTENDANCE, attendance});
  } catch (error) {
    dispatch(errorMessage(error));
  }
};

const formatCollegeSuccess = (items) => {
  const formattedItems = {};

  forEach(items, (item) => {
    formattedItems[item._id] = item;
  });

  return formattedItems;
};

// Migrate test score shape from API shape to the old portfolio style.
// TODO: rewrite portfolio item components to accept API shape as-is.
const formatTestScores = (testScores = []) =>
  testScores.map((testScore) => ({
    ...testScore,
    scores: map(testScore.scores, (value, key) => ({
      field: decamelize(key.substring(testScore.type.length)),
      value: String(value),
    })),
  }));

// Migrate community service shape from API shape to the old portfolio style.
// TODO: rewrite portfolio item components to accept API shape as-is.
const formatCommunityServiceEvents = (events) =>
  events.map((evt) => {
    const {endMonth, endYear} = evt;
    return {
      ...evt,
      dateRange: !isNil(endMonth) && !isNil(endYear),
    };
  });

export const formatCoursePreselects = ({coursePreselects = {}, currentInstitution}) => ({
  ...coursePreselects,
  periodsPerYear: currentInstitution.gradeSettings.periodType,
  institutionId: `inst-${coursePreselects.institutionId || currentInstitution._id}`,
});

export const fetchPortfolioInfo = () =>
  new Promise((resolve, reject) => {
    req({url: '/students/portfolio/info'})
      .then((portfolioInfo) => {
        const {
          userType,
          portfolioEmpty,
          showApplicationStatusMenu,
          showHSAccountConversionModal,
          showTransferEducationDeadlineModal,
          showClubClaimVerificationModal,
          ...portfolioState
        } = portfolioInfo;

        const initialState = {
          userType,
          portfolioEmpty,
          showApplicationStatusMenu,
          showHSAccountConversionModal,
          showTransferEducationDeadlineModal,
          showClubClaimVerificationModal,
          ...portfolioState,
        };

        return resolve(initialState);
      })
      .catch(reject);
  });

export const receivePortfolioInfo = (portfolioInfo) => ({
  type: RECEIVE_PORTFOLIO,
  ...portfolioInfo,
});
