import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SubmitEarlyModal from '../components/submit-early-modal';
import {submitToUniversity, closeSubmitEarlyModal} from '../actions/college-profile';

const mapStateToProps = ({modal, collegeProfile}) => {
  const {
    userInfo: {highSchoolInfo},
    college: {
      universityId,
      isFollowing,
      earningRelationship,
      partnerSubmissionDeadlineDay,
      partnerSubmissionDeadlineMonth,
      name,
    },
  } = collegeProfile;

  return {
    show: !!modal.submitEarlyModal,
    universityId,
    isFollowing,
    earningRelationship,
    highSchoolInfo,
    collegeName: name,
    submissionDeadline: `${partnerSubmissionDeadlineMonth + 1}/${partnerSubmissionDeadlineDay}`,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal: closeSubmitEarlyModal,
      onSubmit: submitToUniversity,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SubmitEarlyModal);
