import req from 'shared/req';
import {get} from 'lodash';

export const ActiveExperiments = {
  PopularActivities: {
    name: 'clubOpportunityCardTest',
    control: 'control',
    treatment: 'treatment',
  },
};

export default class ExperimentService {
  static async get(experiment: string): Promise<{groupName: string} | null> {
    const url = `/v1/experiments/${experiment}`;
    let response = null;

    try {
      response = await req({url, method: 'GET'});
    } catch (error) {
      console.error(`Error getting experiment ${experiment}`, error);
    }

    return response;
  }

  static async getTreatment(experiment: string): Promise<string | null> {
    const response = await ExperimentService.get(experiment);
    return get(response, 'groupName', null);
  }
}
