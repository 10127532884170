import {isEmpty, map} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Carousel from 'shared/components/carousel';
import ImageModal from 'shared/components/modal/image-modal';
import {track} from 'src/utils/analytics';
import 'stylesheets/components/college-profile/college-images.scss';

class CollegeImages extends React.Component {
  static propTypes = {
    images: PropTypes.object,
    name: PropTypes.string,
    collegeId: PropTypes.string,
    userId: PropTypes.string,
    slug: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      lightboxOpen: false,
      currentLightboxPage: 0,
    };
  }

  openLightbox = (e) => {
    e.preventDefault();
    const currentPage = e.currentTarget.id.split('-')[2];
    this.trackCollegeClick(currentPage);
    this.setState({lightboxOpen: true, currentLightboxPage: parseInt(currentPage)});
  };

  trackCollegeClick = (photoPosition) => {
    const {
      collegeId,
      userId,
      images: {ranked, added},
    } = this.props;

    track('college-photo-click', {
      collegeId: collegeId,
      studentId: userId,
      pictureURL: ranked.concat(added)[photoPosition],
      timeStamp: new Date(),
      photoPosition: parseInt(photoPosition) + 1,
    });
  };

  renderPhotos = () => {
    const {
      images: {added, ranked},
    } = this.props;
    const combinedPhotos = ranked.concat(added);
    const remainingCount = combinedPhotos.length - 5;
    return map(combinedPhotos.slice(0, 6), (url, i) => (
      <div
        key={`thumbnail${i}`}
        className="college-photo-thumbnail"
        data-testid="college-photo-thumbnail"
      >
        <button id={`college-photo-${i}`} onClick={this.openLightbox}>
          <img key={`College Photo ${i}`} alt={`College ${i}`} src={this.thumbnailUrl(url)} />
          {i === 5 && (
            <div className="college-photo-remaining" data-testid="college-photo-remaining">
              <span className="remaining-count">{`+${remainingCount}`}</span>
            </div>
          )}
        </button>
      </div>
    ));
  };

  thumbnailUrl = (url) => {
    const {slug} = this.props;

    const url_split = url.split('/');
    const thumb_url = `${url_split
      .slice(0, 3)
      .join('/')}/college-photos-uploaded/${slug}/thumbs/${url_split[url_split.length - 1]
      .split('.')
      .join('-thumb.')}`;
    return thumb_url;
  };

  photoTag = () => {
    const {
      images: {added, ranked},
    } = this.props;
    const page = this.state.currentLightboxPage;
    const combinedPhotos = ranked.concat(added);
    return <img alt={`lightbox ${page}`} src={combinedPhotos[page]} />;
  };

  imageCount = () => this.props.images.ranked.length + this.props.images.added.length;

  handleClose = () => {
    this.setState({lightboxOpen: false});
  };

  changeLightboxPage = (pageDirection) => () => {
    const nextPage = this.state.currentLightboxPage + pageDirection;
    this.trackCollegeClick(nextPage);
    this.setState({currentLightboxPage: nextPage});
  };

  render() {
    const {name} = this.props;
    const renderedPhotos = this.renderPhotos();
    return (
      <div>
        {!isEmpty(renderedPhotos) && <Carousel>{this.renderPhotos()}</Carousel>}
        <ImageModal
          show={this.state.lightboxOpen}
          onClose={this.handleClose}
          onPageChange={this.changeLightboxPage}
          image={this.photoTag()}
          name={name}
          currentPage={this.state.currentLightboxPage}
          imageCount={this.imageCount()}
        />
      </div>
    );
  }
}

export default CollegeImages;
