import {RECEIVE_OPPORTUNITY_CARDS} from '../actions/opportunity-cards';

const initialState = {
  cards: [],
};

export default function opportunityCardsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_OPPORTUNITY_CARDS:
      return {
        ...state,
        cards: action.payload.cards,
      };
    default:
      return state;
  }
}
