import req from 'shared/req';

export const GET_FOLLOWED_COLLEGES = 'GET_FOLLOWED_COLLEGES';
export const RECEIVE_FOLLOWED_COLLEGES = 'RECEIVE_FOLLOWED_COLLEGES';

export const COLLEGE_APPLICATION_STATUS_MODAL_IDENTIFIER = 'college-application-status-modal';

export function getFollowedColleges() {
  return (dispatch) =>
    req({
      url: '/students/followed_colleges?withoutApplicationStatusFirst=true',
      method: 'get',
    }).then((receivedFollowedColleges) => {
      dispatch(receiveCollegeAppStatuses(receivedFollowedColleges));
      return receiveCollegeAppStatuses(receivedFollowedColleges);
    });
}

export function receiveCollegeAppStatuses(followedColleges) {
  return {
    type: RECEIVE_FOLLOWED_COLLEGES,
    payload: followedColleges,
  };
}
