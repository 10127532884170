import {
  RECEIVE_EDUCATORS,
  CLEAR_EDUCATORS,
  RECEIVE_VERIFIED_EDUCATOR,
} from 'district-admin/actions/educators';

export default function educatorsReducer(state = [], action) {
  switch (action.type) {
    case RECEIVE_VERIFIED_EDUCATOR:
      return state.map((educators) => {
        if (action.id === educators._id) {
          return {...educators, isVerified: true};
        } else {
          return educators;
        }
      });
    case RECEIVE_EDUCATORS: {
      return action.educators;
    }
    case CLEAR_EDUCATORS: {
      return [];
    }
    default:
      return state;
  }
}
