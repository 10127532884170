import createReducer from './create-reducer';

export const objectReducer = (args) => {
  const {
    replaceObjectAction,
    mergeObjectAction,
    emptyObjectAction,
    actionProperty = 'payload',
    defaultState = {},
  } = args;

  const handlers = {};

  if (replaceObjectAction) {
    handlers[replaceObjectAction] = (state, action) => action[actionProperty];
  }

  if (mergeObjectAction) {
    handlers[mergeObjectAction] = (state, action) => ({...state, ...action[actionProperty]});
  }

  if (emptyObjectAction) {
    handlers[emptyObjectAction] = () => ({});
  }

  return createReducer(defaultState || {}, handlers);
};
