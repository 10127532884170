import browserHistory from 'shared/history';
import {forEach} from 'lodash';
import req from 'shared/req';

export const RECEIVE_LETTERS = 'RECEIVE_LETTERS';
export const REMOVE_LETTERS = 'REMOVE_LETTERS';

export const receiveLetters = (letters) => {
  const formattedLetters = {};

  forEach(letters, (letter) => {
    formattedLetters[letter.collegeListingId] = letter;
  });

  return {
    type: RECEIVE_LETTERS,
    letters: formattedLetters,
  };
};

export const findOrCreateLetter = (collegeListingId) => (dispatch, getState) => {
  const {financialAidAwardLetters} = getState();

  const letterExists = !!financialAidAwardLetters[collegeListingId];
  if (letterExists) {
    return;
  }

  req({
    url: '/v1/users/me/financial-aid-award-letters',
    method: 'post',
    data: {collegeListingId},
  }).then((letter) => {
    dispatch(receiveLetters([letter]));
  });
};

export const removeLetter = () => (dispatch, getState) => {
  const {
    college: {_id},
    financialAidAwardLetters,
  } = getState();
  const letterId = financialAidAwardLetters[_id]._id;

  return req({
    url: `/v1/users/me/financial-aid-award-letters/${letterId}`,
    method: 'delete',
  }).then(() => {
    browserHistory.push('/portfolio');
    dispatch({type: REMOVE_LETTERS});
  });
};

export const getLetterForCollege = () => (dispatch, getState) => {
  const {
    college: {_id},
  } = getState();
  const slug = location.pathname.split('/financial-aid-award-letters/')[1];

  dispatch(findOrCreateLetter(_id));
  browserHistory.push(`/financial-aid-award-letters/${slug}/annual-costs`);
};

const LETTER_FIELDS = [
  'tuitionAndFees',
  'tuitionStatus',
  'roomAndBoardStatus',
  'roomAndBoardCost',
  'otherPersonalExpenses',
  'booksAndSuppliesCost',
  'collegeListingId',
  'institutionalGrantsAndScholarships',
  'stateLocalGrants',
  'pellGrants',
  'otherGrants',
  'federalSubsidizedLoans',
  'federalUnsubsidizedLoans',
  'financialAidOfferSatisfaction',
  'workStudyStatus',
  'workStudyAmount',
  'costCoverage',
].join(',');

export const getLetters = () => (dispatch) =>
  req({
    url: '/v1/users/me/financial-aid-award-letters',
    method: 'get',
    data: {
      fields: LETTER_FIELDS,
    },
  }).then((letters) => {
    dispatch(receiveLetters(letters));
  });

export const updateLetterInfo = (letterInfo) => (dispatch, getState) => {
  const {
    college: {_id},
    financialAidAwardLetters,
  } = getState();

  const letterId = financialAidAwardLetters[_id]._id;

  return req({
    url: `/v1/users/me/financial-aid-award-letters/${letterId}?fields=${LETTER_FIELDS}`,
    method: 'patch',
    data: letterInfo,
  }).then((letter) => {
    dispatch(receiveLetters([letter]));
  });
};

export const clearLetters = () => ({
  type: REMOVE_LETTERS,
});
