import {verifyLeadershipClaimConfig} from 'src/components/MicroScholarships/VerifyLeadershipClaim';
import {clubClaimedConfig} from 'src/components/MicroScholarships/ClubClaimed';
import {honorAwardsModalConfig} from 'src/components/HonorAwardsModal';
import {workExperienceModalConfig} from 'src/components/WorkExperienceModal';
import {activityModalConfig} from 'src/components/ActivityModal';

const hsModals = [
  verifyLeadershipClaimConfig,
  clubClaimedConfig,
  honorAwardsModalConfig,
  workExperienceModalConfig,
  activityModalConfig,
];

export default hsModals;
