// admin/university
import {RECEIVE_UNIVERSITY} from '../actions/university';

export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_UNIVERSITY:
      return {
        ...state,
        ...action.university,
      };
    default:
      return state;
  }
};
