import req from 'shared/req';
import {queryParamsToString} from 'shared/utils/url-helpers';
import JWTService from './JWTService';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ItemValue = Record<string, any>;

interface RequestOptions {
  url: string;
  method?: string;
  data?: ItemValue;
}

/*
 * Provide a baseUrl and optionally a type to create a service that can perform all of the normal CRUD requests
 * based on normal RESTful patterns.
 *
 * Example usage:
 *
 * export default SomeCoolResourceService = new CRUDService('/v1/some-cool-resources');
 *
 * Can now use it like SomeCoolResourceService.index((resources) => setResources(resources));
 */
export default class CRUDService<T = ItemValue> {
  BASE_URL: string;
  USE_JWT: boolean;
  TOKEN: string | null = null;

  constructor(baseUrl: string, useJWT = false, token: string | null = null) {
    this.BASE_URL = baseUrl;
    this.USE_JWT = useJWT;
    this.TOKEN = token;
  }

  index<Type = T[]>(query = {}): Promise<Type> {
    return this.request<T[]>({url: this.BASE_URL, data: query}) as Promise<Type>;
  }

  show<Type = T>(id, queryParams = {}, url: string | null = null): Promise<Type> {
    return this.request<Type>({
      url: this.createFullUrl(url || this.persistedUrl(id), queryParams),
    });
  }

  create(data, queryParams = {}, url: string | null = null): Promise<T> {
    return this.request<T>({
      url: this.createFullUrl(url || this.BASE_URL, queryParams),
      method: 'post',
      data,
    });
  }

  update(id, data, queryParams = {}): Promise<T> {
    return this.request<T>({
      url: this.createFullUrl(this.persistedUrl(id), queryParams),
      method: 'patch',
      data,
    });
  }

  createOrUpdate(data, queryParams = {}): Promise<T> {
    const id = data._id || data.id;

    if (id) {
      return this.update(id, data, queryParams);
    } else {
      return this.create(data, queryParams);
    }
  }

  destroy(id, queryParams = {}): Promise<Record<string, never>> {
    return this.request<Record<string, never>>({
      url: this.createFullUrl(this.persistedUrl(id), queryParams),
      method: 'delete',
    });
  }

  persistedUrl(id): string {
    return `${this.BASE_URL}${id !== null ? `/${id}` : ''}`;
  }

  createFullUrl(url, queryParams = {}): string {
    if (!Object.keys(queryParams).length) {
      return url;
    }

    return `${url}?${queryParamsToString(queryParams)}`;
  }

  async request<Type>(options: RequestOptions): Promise<Type> {
    const headers: Record<string, string> = {};

    if (this.USE_JWT) {
      headers.Authorization = `Bearer ${await JWTService.token()}`;
    }

    if (this.TOKEN) {
      headers.Authorization = `Bearer ${this.TOKEN}`;
    }

    return await req({
      ...options,
      headers,
    });
  }
}
