import {reducer as formReducer} from 'redux-form';
import {FINISH_UPLOADING, START_UPLOADING} from 'shared/actions/minerva';
import {booleanReducer} from 'shared/reducers/boolean-reducer';
import {loadingReducer} from 'shared/reducers/loading';
import {notificationsReducer} from 'shared/reducers/notifications';
import {popoversReducer} from 'shared/reducers/popovers';
import currentInstitutionReducer from 'src/redux/admin/reducers/currentInstitution';
import toolbarNotificationReducer from 'src/redux/toolbar-notification/reducer';
import universityReducer from 'src/redux/adminUniversity/reducers/university';
import photoListingsReducer from 'src/redux/adminUniversity/reducers/photoListing';
import {listReducer} from '../../redux/lists';
import checklistReducer from '../../redux/checklist/reducer';
import checklistUserStatusReducer from '../../redux/checklistUserStatus/reducer';

export const adminReducers = {
  checklist: checklistReducer,
  checklistUserStatus: checklistUserStatusReducer,
  currentInstitution: currentInstitutionReducer,
  form: formReducer,
  lists: listReducer,
  loading: loadingReducer,
  notifications: notificationsReducer,
  photoListings: photoListingsReducer,
  popovers: popoversReducer,
  toolbarNotification: toolbarNotificationReducer,
  university: universityReducer,
  uploading: booleanReducer({
    defaultState: false,
    falseType: FINISH_UPLOADING,
    trueType: START_UPLOADING,
  }),
};
