export const RECEIVE_URL_PARAMETERS = 'RECEIVE_URL_PARAMETERS';

export const saveUrlParameters = () => (dispatch, getState) => {
  const {urlParameters} = getState();

  // Only save url parameters if there is nothing currently saved.
  // Prevents overriding if a user jumps between multiple sign up pages.
  if (!urlParameters) {
    dispatch({
      type: RECEIVE_URL_PARAMETERS,
      params: params(),
    });
  }
};

export const PARAMS_STORAGE_KEY = 'RAISEME_AUTH_PARAMS';

export const params = () => {
  let fetchedParams;

  if (window.sessionStorage) {
    fetchedParams = window.sessionStorage.getItem(PARAMS_STORAGE_KEY);
  }

  fetchedParams = fetchedParams || window.location.search;

  if (window.sessionStorage) {
    window.sessionStorage.setItem(PARAMS_STORAGE_KEY, fetchedParams);
  }

  return fetchedParams;
};

export const removeParams = () => {
  if (!window.sessionStorage) {
    return;
  }

  window.sessionStorage.removeItem(PARAMS_STORAGE_KEY);
};
