import React, {createContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {flashMessage} from 'shared/actions/notifications';
import {ChecklistItem} from 'src/services/ChecklistService';
import {receiveChecklistUserStatus} from '../redux/checklistUserStatus/actions';
import {ChecklistUserStatus} from '../services/ChecklistUserStatus';

interface Context {
  notificationCenterAlert: boolean;
  setNotificationCenterAlert?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RaiseEventListenerContext = createContext<Context>({
  notificationCenterAlert: false,
});

interface Props {
  children: React.ReactNode;
}

interface ChecklistEvaluatedEvent {
  type: 'evaluated';
  data: ChecklistUserStatus;
}

interface ChecklistItemCheckedEvent {
  type: 'item-checked';
  data: ChecklistItem;
}

type ChecklistEvent = ChecklistEvaluatedEvent | ChecklistItemCheckedEvent;

export const RaiseEventListenerProvider = ({children}: Props) => {
  const [notificationCenterAlert, setNotificationCenterAlert] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    window.Raise.vent.on('notification-center:new', () => {
      setNotificationCenterAlert(true);
    });

    window.Raise.vent.on<ChecklistEvent>('checklist', (event: ChecklistEvent) => {
      if (event.type === 'evaluated') {
        dispatch(receiveChecklistUserStatus(event.data));
      } else if (event.type === 'item-checked') {
        dispatch(
          flashMessage(
            `You’ve completed ${event.data.name}`,
            'success',
            {
              template: 'action',
              action: () => history.push('/checklist'),
              actionText: 'View Checklist',
            },
            7000
          )
        );
      }
    });
  }, []);

  return (
    <RaiseEventListenerContext.Provider
      value={{notificationCenterAlert, setNotificationCenterAlert}}
    >
      {children}
    </RaiseEventListenerContext.Provider>
  );
};
