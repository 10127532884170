import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Educator from 'shared/img/educator.svg';

export default class EducatorSelection extends React.Component {
  static propTypes = {
    setNavigationAnnouncement: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.props.setNavigationAnnouncement('Sign Up - Educator Selection');
  }

  render() {
    return (
      <>
        <div className="auth-signup-user-icon">
          <Educator width={this.props.size} height={this.props.size} />
        </div>
        <h1 className="auth-signup-user-header">Sign Up as an Educator</h1>
        <p className="auth-signup-educator-selection-text">
          Which of the following roles best
          <br /> describes you?
        </p>
        <Link to="/signup/educator/teacher" className="auth-signup-educator-selection-button">
          Teacher at a High School
        </Link>
        <Link to="/signup/educator/counselor" className="auth-signup-educator-selection-button">
          Counselor at a High School
        </Link>
        <Link to="/signup/educator/mentor" className="auth-signup-educator-selection-button">
          Mentor at a College Access Organization
        </Link>
        <Link
          to="/signup/educator/communityCollegeStaff"
          className="auth-signup-educator-selection-button"
        >
          Community College Staff
        </Link>
      </>
    );
  }
}
