import {isEmpty} from 'lodash';
import {SELECT_TAB} from '../actions/tab';
import {parseQueryString} from 'shared/utils';

const currentTab = parseQueryString(window.location.hash.slice(1));
let initialState = null;
if (!isEmpty(currentTab)) {
  currentTab.name = decodeURIComponent(currentTab.tab);
  initialState = currentTab;
}

function tabReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_TAB:
      return action.tab;
    default:
      return state;
  }
}

export default tabReducer;
