import {filter} from 'lodash';
import {RECEIVE_STUDENTS, REMOVE_STUDENT} from 'educator/action-types/students';

const studentsReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_STUDENTS: {
      return [...action.students];
    }
    case REMOVE_STUDENT: {
      return filter(state, (student) => student._id !== action.student._id);
    }
    default:
      return state;
  }
};

export default studentsReducer;
