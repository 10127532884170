import PropTypes from 'prop-types';
import React from 'react';
import {sum, sumBy, includes} from 'lodash';
import StackedBarChart from 'shared/college-profile/components/stacked-bar-chart';
import HighlightedText from 'shared/college-profile/components/highlighted-text';
import GraphAttachedTooltip from 'shared/college-profile/components/graph-attached-tooltip';
import {stateGrantLinks, EARNING_STATUSES} from 'shared/college-profile/utils';
import InfoTooltip from 'shared/components/info-tooltip';
import {withCommas} from 'shared/utils';
import Text from 'shared/components/text';
import Icon from 'shared/components/icon';

const formatValue = (value) => `$${withCommas(value)} /year`;

const propTypes = {
  college: PropTypes.shape({
    tuition: PropTypes.number,
    onCampusHousingCost: PropTypes.number,
    onCampusOtherCosts: PropTypes.number,
    booksAndSuppliesCost: PropTypes.number,
    state: PropTypes.string.isRequired,
    control: PropTypes.string.isRequired,
    amount: PropTypes.number,
    divisor: PropTypes.number,
    financialAidWebsite: PropTypes.string,
    financialAidBreakdown: PropTypes.shape({
      federalGrants: PropTypes.shape({averageAmount: PropTypes.number}),
      stateOrLocalGovtGrants: PropTypes.shape({averageAmount: PropTypes.number}),
      institutionalAid: PropTypes.shape({averageAmount: PropTypes.number}),
    }),
    earningRelationship: PropTypes.shape({
      statusType: PropTypes.string,
    }),
  }),
  userInfo: PropTypes.object.isRequired,
  userState: PropTypes.string.isRequired,
};

const EstimatedFinancialAid = ({college, userInfo, userState}) => {
  const {
    amount,
    booksAndSuppliesCost,
    divisor,
    onCampusHousingCost,
    onCampusOtherCosts,
    state,
    tuition,
    financialAidWebsite,
    financialAidBreakdown: {federalGrants, stateOrLocalGovtGrants, institutionalAid},
    earningRelationship: {statusType},
  } = college;

  const {highSchoolFrlPercentage, highSchoolInfo, image} = userInfo;

  const totalCost = sum([tuition, onCampusHousingCost, onCampusOtherCosts, booksAndSuppliesCost]);

  const yearlyEarnings = Math.floor(amount / divisor);

  const groups = [];

  let aidAmount = 0;

  const isEarning = includes(EARNING_STATUSES, statusType);

  let institutionalGrantsSubtext = null;

  if (isEarning && yearlyEarnings) {
    institutionalGrantsSubtext = (
      <div className="estimated-financial-aid-section-secured-earnings">
        <img
          src={image}
          alt=""
          aria-hidden="true"
          className="estimated-financial-aid-section-avatar"
        />
        <span className="estimated-financial-aid-section-secured-earnings-text">
          ${withCommas(yearlyEarnings)} /year secured so far in micro-scholarships
        </span>
        <InfoTooltip className="college-profile-tooltip">
          Micro-scholarships reflect the minimum grants & scholarships you&#39;ll receive directly
          from the college if you apply, are admitted and enroll. You may be eligible for more based
          on your complete application!
        </InfoTooltip>
      </div>
    );
  }

  if (institutionalAid.averageAmount) {
    let institutionalGrantsDisplay;

    if (financialAidWebsite) {
      institutionalGrantsDisplay = (
        <a href={financialAidWebsite} target="_blank" rel="noopener noreferrer">
          Average Institutional&nbsp;
          <div className="connected-words">
            Grants &amp; Scholarships
            <Icon
              iconType="external-link-alt"
              className="estimated-financial-aid-external-link-icon"
            />
          </div>
        </a>
      );
    } else {
      institutionalGrantsDisplay = <span>Average Institutional Grants &amp; Scholarships</span>;
    }

    groups.push({
      name: institutionalGrantsDisplay,
      value: institutionalAid.averageAmount,
      subText: institutionalGrantsSubtext,
    });

    aidAmount += institutionalAid.averageAmount;
  }

  const inState = userState === state;

  if (inState && stateOrLocalGovtGrants.averageAmount) {
    aidAmount += stateOrLocalGovtGrants.averageAmount;

    let stateGrantsDisplay;

    const stateGrantLink = stateGrantLinks[state];

    if (stateGrantLink) {
      stateGrantsDisplay = (
        <a href={stateGrantLink} target="_blank" rel="noopener noreferrer">
          Average State&nbsp;
          <div className="connected-words">
            Grants
            <Icon
              iconType="external-link-alt"
              className="estimated-financial-aid-external-link-icon"
            />
          </div>
        </a>
      );
    } else {
      stateGrantsDisplay = <span>Average State Grants</span>;
    }

    groups.push({name: stateGrantsDisplay, value: stateOrLocalGovtGrants.averageAmount});
  }

  const studentIndicatedFrl = highSchoolInfo && highSchoolInfo.lunchPlan === 'FRL';
  const highschoolFrlPercentageHigh = highSchoolFrlPercentage && highSchoolFrlPercentage > 50;

  const lowIncome = studentIndicatedFrl || highschoolFrlPercentageHigh;

  if (lowIncome && federalGrants.averageAmount) {
    aidAmount += federalGrants.averageAmount;

    const federalGrantsDisplay = (
      <a href="https://studentaid.ed.gov/sa/fafsa" target="_blank" rel="noopener noreferrer">
        Average Federal&nbsp;
        <div className="connected-words">
          Grants
          <Icon
            iconType="external-link-alt"
            className="estimated-financial-aid-external-link-icon"
          />
        </div>
      </a>
    );

    groups.push({name: federalGrantsDisplay, value: federalGrants.averageAmount});
  }

  const netCost = totalCost - aidAmount;

  const divider = 100 - sumBy(groups, (group) => (group.value > 0 ? 1 : 0));

  const startOfNetCost = (aidAmount / totalCost / divider) * 100;
  const centerOfNetCost = (1 - startOfNetCost) / 2 + startOfNetCost;

  if (totalCost <= 0) return null;

  const chartText = (
    <div>
      <Icon iconType="tag" />
      {formatValue(totalCost)}
    </div>
  );

  if (groups.length === 0) {
    return null;
  }

  return (
    <div className="estimated-financial-aid-section">
      <GraphAttachedTooltip style={{left: `calc((100% - 134px) * ${centerOfNetCost})`}}>
        <div className="estimated-financial-aid-section-tooltip">
          <Text emphasis>Your Est. Net Price</Text>
          <Text emphasis>{formatValue(netCost)}</Text>
          <div className="estimated-financial-aid-section-formula-text">(Cost - Financial Aid)</div>
        </div>
      </GraphAttachedTooltip>
      <div
        className="estimated-financial-aid-section-range-outline"
        style={{
          width: `calc((100% - 140px) * ${1 - startOfNetCost})`,
          left: `calc((100% - 136px) * ${centerOfNetCost})`,
        }}
      ></div>
      <StackedBarChart
        withTooltip
        caption="Estimated Financial Aid"
        chartText={chartText}
        groups={groups}
        divisor={totalCost}
        colorScheme="blue"
        formatValue={formatValue}
        sort={false}
        footer={
          <HighlightedText
            iconType="gift"
            text={[
              <span key="est-cost">Total Est. Financial Aid</span>,
              <span key="est-cost-amount">{formatValue(aidAmount)}</span>,
            ]}
          />
        }
      />
    </div>
  );
};

EstimatedFinancialAid.propTypes = propTypes;

export default EstimatedFinancialAid;
