import React from 'react';
import Card from 'src/components/Cards/Card';

const LoadingBody = () => (
  <section className="college-profile-loading-body" data-testid="college-profile-loading">
    <div className="college-profile-loading-body-title shimmer"></div>
    <div className="college-profile-loading-body-text shimmer"></div>
    <div className="college-profile-loading-body-group">
      <div className="college-profile-loading-body-mini-card">
        <div className="college-profile-loading-body-icon shimmer"></div>
        <div className="college-profile-loading-body-text shimmer"></div>
        <div className="college-profile-loading-body-text shimmer"></div>
      </div>
      <div className="college-profile-loading-body-mini-card">
        <div className="college-profile-loading-body-icon shimmer"></div>
        <div className="college-profile-loading-body-text shimmer"></div>
        <div className="college-profile-loading-body-text shimmer"></div>
      </div>
      <div className="college-profile-loading-body-mini-card">
        <div className="college-profile-loading-body-icon shimmer"></div>
        <div className="college-profile-loading-body-text shimmer"></div>
        <div className="college-profile-loading-body-text shimmer"></div>
      </div>
    </div>
    <Card>
      <div className="college-profile-loading-body-title shimmer"></div>
      <div className="college-profile-loading-body-text shimmer"></div>
      <div className="college-profile-loading-body-text shimmer"></div>
      <div className="college-profile-loading-body-text shimmer"></div>
    </Card>
    <Card>
      <div className="college-profile-loading-body-title shimmer"></div>
      <div className="college-profile-loading-body-text shimmer"></div>
      <div className="college-profile-loading-body-text shimmer"></div>
      <div className="college-profile-loading-body-text shimmer"></div>
    </Card>
    <Card>
      <div className="college-profile-loading-body-title shimmer"></div>
      <div className="college-profile-loading-body-text shimmer"></div>
      <div className="college-profile-loading-body-text shimmer"></div>
      <div className="college-profile-loading-body-text shimmer"></div>
    </Card>
  </section>
);

export default LoadingBody;
