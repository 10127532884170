import {
  RECEIVE_EDUCATOR_CONNECTIONS,
  RECEIVE_EDUCATOR_CONNECTION,
} from 'src/redux/educatorConnections/actions';

const connectionsReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_EDUCATOR_CONNECTIONS: {
      return action.connections;
    }
    case RECEIVE_EDUCATOR_CONNECTION: {
      const connections = state.map((connection) => {
        if (action.connection._id === connection._id) {
          return action.connection;
        } else {
          return connection;
        }
      });
      return connections;
    }
    default:
      return state;
  }
};

export default connectionsReducer;
