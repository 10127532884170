import PropTypes from 'prop-types';
import React from 'react';
import HorizontalBoxPlot from './horizontal-box-plot';
import AcceptanceClassificationTooltip from './acceptance-likelihood/acceptance-classification-tooltip';
import {acceptanceLikelihoodClassification} from 'shared/college-profile/utils';
import GpaTooltip from 'shared/components/gpa-modal/tooltip';

class GPASection extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    showAdmissability: PropTypes.bool,
  };

  acceptanceLikelihoodClassification() {
    const {
      college: {averageGPA},
      userInfo: {
        highSchoolInfo: {gpa},
      },
    } = this.props;

    const lowerBound = averageGPA - 0.2;
    const upperBound = averageGPA + 0.2;

    return acceptanceLikelihoodClassification(gpa, lowerBound, upperBound);
  }

  formatGPA(gpa) {
    if (gpa % 1 === 0) {
      return `${gpa}.0`;
    }

    return gpa;
  }

  render() {
    const {
      college: {averageGPA, name},
      userInfo: {
        highSchoolInfo: {gpa},
      },
      showAdmissability,
    } = this.props;

    if (!averageGPA) {
      return null;
    }

    const points = [
      {
        value: averageGPA,
        color: showAdmissability ? 'gray' : 'blue',
        label: `Avg GPA: ${this.formatGPA(averageGPA)}`,
      },
    ];

    if (showAdmissability && gpa) {
      points.push({
        value: gpa,
        color: 'blue',
      });
    }

    return (
      <div className="college-profile-sat-act">
        <header className="college-profile-sat-act-header">
          Average GPA
          {showAdmissability && <GpaTooltip />}
        </header>
        {showAdmissability && (
          <div className="college-profile-sat-act-subheader">
            {gpa ? (
              `Compare your GPA to ${name}'s averages. `
            ) : (
              <a href="/portfolio#courses" className="">
                Enter more courses to compare GPA averages.
              </a>
            )}
          </div>
        )}
        <div className="college-profile-sat-act-range">
          <div className="college-profile-sat-act-plot">
            {(showAdmissability || true) && gpa && (
              <AcceptanceClassificationTooltip
                label="Your GPA"
                studentValue={this.formatGPA(gpa)}
                classification={this.acceptanceLikelihoodClassification()}
                xValue={(gpa / 4) * 100}
              />
            )}
            <HorizontalBoxPlot
              min={0.0}
              minLabel="0.0"
              max={4.0}
              maxLabel="4.0"
              points={points}
              tooltip={(showAdmissability || true) && !!gpa}
            />
          </div>
          <div className="college-score-range-label">
            <div className="college-score-range-label-content">{averageGPA}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default GPASection;
