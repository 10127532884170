import {RouteComponentProps} from 'react-router';
import {createContext, ComponentType, LazyExoticComponent} from 'react';
import {Modals} from 'src/types/enums';

export type ModalType = Modals | string;
export type ModalProps<P = object | null | undefined> = P;
export interface ModalContextType {
  show: (modalType: ModalType, modalProps: ModalProps) => void;
  hide: () => void;
  activeModal: ModalType | null;
}

const unimplementedFn = (msg: string) => (): void => {
  throw new Error(`Unimplemented method ${msg}`);
};

export const ModalContext = createContext<ModalContextType>({
  activeModal: null,
  show: unimplementedFn('show'),
  hide: unimplementedFn('hide'),
});

ModalContext.displayName = 'ModalContext';

export type ControlledModal<Props> = Props & {
  onClose: () => void;
};

export type RoutedModal<Props> = ControlledModal<
  RouteComponentProps & {
    data: Props;
  }
>;

export type RoutedModalConfig<Props> = ModalConfig<RoutedModal<Props>, Props> & {routed: true};
export type ControlledModalConfig<Props> = ModalConfig<ControlledModal<Props>>;

export interface ModalConfig<ComponentProps, ValidateProps = ComponentProps> {
  // Name of modal, used to match on url
  type: string;
  // Lazy import of modal to render
  Component: LazyExoticComponent<ComponentType<ComponentProps>>;
  // [optional] Returns T/f if required props are present
  validateProps?: (props: ValidateProps) => boolean;
  routed?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyModalConfig = ModalConfig<any>;

export type ModalProviderProps = RouteComponentProps & {
  configs: AnyModalConfig[];
};
