import {connect} from 'react-redux';
import {googleLogin, handleRedirect, onSubmit} from '../../../redux/auth/actions/login';
import Login from './login';
import {SessionStorage} from '../../../utils/session-storage';

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const followCollegeId = window.sessionStorage.getItem(SessionStorage.FOLLOW_COLLEGE_ID);

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: async function submitHandler(values) {
      return dispatchProps.onSubmit(values, followCollegeId, false).then((response) => {
        // get rid of any query params
        window.history.pushState({}, '', window.location.pathname);

        if (ownProps.setUserAuthenticated) {
          ownProps.setUserAuthenticated();
        } else {
          handleRedirect(response);
        }
      });
    },
  };
};

export default connect(
  null,
  {
    onSubmit,
    googleLogin,
  },
  mergeProps
)(Login);
