import {getFeatureInteraction} from 'shared/actions/feature-interactions';
import {initializeFilters} from 'shared/actions/filters';
import {flashMessage} from 'shared/actions/notifications';
import browserHistory from 'shared/history';
import req from 'shared/req';
import {SORT_TO_FIELD} from 'shared/utils';
import {UserType} from 'src/types/enums';
import {formatColleges, formatFilters, formatInitialFilters} from 'src/utils/collegeSearch';
import {AbortedRequest} from 'src/utils/errors';
import {
  getCollegeSearch,
  updateSavedSearchAndURLParameters as updateCollegeSearch,
} from '../../services/saved-college-search-service';
import {isEmpty} from 'lodash';
import {getIpLocation} from '../../utils/geolocation';

export const FOLLOW_COLLEGE = 'COLLEGE_SEARCH/FOLLOW_COLLEGE';
export const UNFOLLOW_COLLEGE = 'COLLEGE_SEARCH/UNFOLLOW_COLLEGE';
export const LOAD_MORE_COLLEGES = 'COLLEGE_SEARCH/LOAD_MORE_COLLEGES';
export const LOAD_COLLEGES = 'COLLEGE_SEARCH/LOAD_COLLEGES';
export const RESET_PAGE = 'COLLEGE_SEARCH/RESET_PAGE';
export const UPDATE_SORT_BY = 'COLLEGE_SEARCH/UPDATE_SORT_BY';
export const UPDATE_SORT_ORDER = 'COLLEGE_SEARCH/UPDATE_SORT_ORDER';
export const GET_SAVED_SORT = 'COLLEGE_SEARCH/GET_SAVED_SORT';
export const GET_USER_INFO = 'COLLEGE_SEARCH/GET_USER_INFO';
export const GET_CITIZEN_NOTIFICATION = 'COLLEGE_SEARCH/GET_CITIZEN_NOTIFICATION';
export const FINISHED_LOADING = 'COLLEGE_SEARCH/FINISHED_LOADING';
export const UPDATE_COLLEGE_RELATIONSHIP = 'COLLEGE_SEARCH/UPDATE_COLLEGE_RELATIONSHIP';
export const RECEIVE_TAGS = 'COLLEGE_SEARCH/RECEIVE_TAGS';

export const PER_PAGE = 16;
export const FIELDS = `
name logo banner city state isFollowing slug amount
partnerSubmissionDeadlineMonth partnerSubmissionDeadlineDay
partnerSubmissionDeadlineYear partnerSubmissionDeadlineTerm earningRelationship
isPartner category earningsPossible totalUndergraduates acceptanceRate distance
studentFacultyRatio majorsParticipation forbesRank usNewsRank
highSchoolFollowerCount highSchoolApplicationStatusBreakdown studentSubmissionYear
studentSubmissionTerm
`
  .split(/\s/)
  .filter(Boolean)
  .join(',');

const HS_FIELDS = ['highSchoolInfo', 'country', 'totalCredits', 'isUsCitizenOrResident'].join(',');
const CC_FIELDS = ['country', 'communityCollegeInfo'].join(',');
const EDUCATOR_FIELDS = 'institution';

export function followCollege(id) {
  return (dispatch) => {
    dispatch({
      type: FOLLOW_COLLEGE,
      id,
    });

    return req({
      url: `/v1/colleges/${id}/follow`,
      method: 'post',
    })
      .then((responseJSON) => {
        dispatch({
          type: UPDATE_COLLEGE_RELATIONSHIP,
          id,
          college: responseJSON,
        });
      })
      .catch(() => {
        dispatch({
          type: UNFOLLOW_COLLEGE,
          id,
        });
        dispatch(
          flashMessage(
            'Whoops! Something went wrong on our end. Please refresh and try again.',
            'error'
          )
        );
      });
  };
}

export function unfollowCollege(id) {
  return (dispatch) => {
    dispatch({
      type: UNFOLLOW_COLLEGE,
      id,
    });

    return req({
      url: `/v1/colleges/${id}/unfollow`,
      method: 'post',
    }).catch(() => {
      dispatch({
        type: FOLLOW_COLLEGE,
        id,
      });
      dispatch(
        flashMessage(
          'Whoops! Something went wrong on our end. Please refresh and try again.',
          'error'
        )
      );
    });
  };
}

export function showWelcomeModal() {
  return () => {
    getFeatureInteraction('college-search-welcome-modal').catch(() => {
      browserHistory.push({
        pathname: `${browserHistory.location.pathname}/welcome`,
        state: {skipScrolling: true},
      });
    });
  };
}

export function getColleges(options = {append: false}) {
  return (dispatch, getState) => {
    const type = options.append ? LOAD_MORE_COLLEGES : LOAD_COLLEGES;
    const {
      filters,
      collegeSearch: {page, sortBy, sortOrder},
    } = getState();

    const fields = SORT_TO_FIELD[sortBy] ? `${FIELDS},${SORT_TO_FIELD[sortBy]}` : FIELDS;

    return req({
      url: '/v1/colleges',
      method: 'get',
      data: {
        ...formatFilters(filters),
        limit: PER_PAGE,
        skip: page * PER_PAGE,
        fields,
        sort: sortBy,
        direction: sortOrder,
      },
      cancelPendingRequests: true,
      responseTransformer: (colleges, dummy, response) => ({colleges, response}),
    })
      .then(({colleges, response}) => {
        const totalColleges = +response.getResponseHeader('X-Total-Results');
        const totalLoaded = options.append ? page * PER_PAGE + colleges.length : colleges.length;
        const hasMore = totalLoaded < totalColleges;

        dispatch({
          type,
          hasMore,
          totalColleges,
          loadedColleges: formatColleges(colleges),
        });

        dispatch(setCurrentCollegeSearch());
      })
      .catch((error) => {
        if (!(error instanceof AbortedRequest)) {
          dispatch(
            flashMessage('Whoops! Something went wrong on our end. Please try again.', 'error')
          );
        }
      });
  };
}

// Save to local storage and url from redux
function setCurrentCollegeSearch() {
  return (dispatch, getState) => {
    const {
      filters,
      collegeSearch: {sortBy, sortOrder},
    } = getState();
    updateCollegeSearch({filters, sort: sortBy, order: sortOrder});
  };
}

// Put the page's current search (from the URL || saved search || default fallback) in redux
export const initializeCollegeSearch = (userType) => async (dispatch) => {
  const search = getCollegeSearch();

  const filters = formatInitialFilters(search.filters);

  if (isEmpty(filters)) {
    await addDefaultLocationFilter(filters);
  }

  dispatch({type: GET_SAVED_SORT, search});
  dispatch(initializeFilters(filters));

  const promises = [];

  if (userType !== UserType.PUBLIC) {
    dispatch(getUserInfo(userType));
  }

  if (userType === UserType.HS_STUDENT) {
    promises.push(dispatch(getCitizenNotification()));
  }

  Promise.all(promises).then(() => {
    dispatch(finishedLoading());
  });
};

async function addDefaultLocationFilter(filters) {
  try {
    const locationFilter = await getCurrentLocationFilter(getIpLocation);

    filters.location = [locationFilter];
  } catch (e) {
    console.error('Error adding default location', e);
  }
}

export async function getCurrentLocationFilter(getLocation) {
  const {coordinates, name} = await getLocation();

  return {city: name, loc: [coordinates.longitude, coordinates.latitude]};
}

export function getUserInfo(userType) {
  let fields;
  let user;

  switch (userType) {
    case UserType.HS_STUDENT:
      fields = HS_FIELDS;
      user = 'users';
      break;
    case UserType.CC_STUDENT:
      fields = CC_FIELDS;
      user = 'users';
      break;
    case UserType.EDUCATOR:
      fields = EDUCATOR_FIELDS;
      user = 'educators';
      break;
    default:
      fields = HS_FIELDS;
      user = 'users';
      break;
  }

  return (dispatch) => {
    const url = `/v1/${user}/me`;
    return req({
      url,
      method: 'get',
      data: {fields},
    }).then((info) => {
      dispatch({type: GET_USER_INFO, info});
    });
  };
}

function getCitizenNotification() {
  return (dispatch) => {
    const url = '/v1/users/me/feature-interactions/citizenship-notification';
    return req({
      url,
      method: 'get',
      data: {fields: 'dismissed'},
    })
      .then((dismissed) => {
        dispatch({type: GET_CITIZEN_NOTIFICATION, dismissed});
      })
      .catch(() => {
        dispatch({type: GET_CITIZEN_NOTIFICATION, dismissed: {dismissed: false}});
      });
  };
}

export function finishedLoading() {
  return {type: FINISHED_LOADING, loading: false};
}

export function dismissCitizenNotification() {
  return () => {
    const url = '/v1/users/me/feature-interactions/citizenship-notification';
    return req({
      url,
      method: 'put',
      data: {featureData: {dismissed: true}},
    });
  };
}

export function resetPage() {
  return {type: RESET_PAGE};
}

export function updateSortBy(sort) {
  return {type: UPDATE_SORT_BY, sort};
}

export function updateSortOrder(order) {
  return {type: UPDATE_SORT_ORDER, order};
}

export function fetchTags() {
  return (dispatch) => {
    const url = '/v1/lists/college-tags';
    return req({
      url,
      method: 'get',
    }).then((tags) => {
      dispatch({type: RECEIVE_TAGS, tags});
    });
  };
}
