import {saveUrlParameters} from 'src/redux/auth/actions/url-parameters';

import React, {lazy, ReactElement, useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import MessageAndRedirect from 'shared/components/message-and-redirect';
import Notifications from 'shared/components/notifications';
import browserHistory from 'shared/history';
import CopyrightPolicy from 'src/components/CopyrightPolicy';
import ForgotPassword from 'src/components/ForgotPassword';
import MobileVerificationCode from 'src/components/MobileVerificationCode';
import PrivacyPolicy from 'src/components/privacy-policy';
import ResetPassword from 'src/components/ResetPassword';
import SetPassword from 'src/components/SetPassword';
import Suspense from 'src/components/Suspense';
import TermsOfUse from 'src/components/TermsOfUse';
import Unsubscribe from 'src/components/Unsubscribe/Unsubscribe';
import {successfulLoginRedirectPath} from 'src/utils/storage';
import TabbedCollegeSearch from 'src/views/public/public-tabbed-college-search/public-tabbed-college-search';
import SignUp from 'src/views/public/sign-up';
import 'stylesheets/auth.scss';
import AppLayout from '../../components/app-layout';
import CollegeProfile from './college-profile';
import Login from './login/connected-login';
import {useDispatch} from 'react-redux';

const PublicNavbar = lazy(() => import('./public-navbar'));
const CollegeSearchSignUpModal = lazy(
  () => import('src/components/college-search-sign-up-modal/college-search-sign-up-modal')
);
const CollegeEmployeeModal = lazy(
  () => import('src/components/college-employee-modal/college-employee-modal')
);

const CLEVER_ERROR_MESSAGE =
  'Something went wrong while trying to connect to Clever, please try again or log in ' +
  'with your email and password';

const ShowCleverError = (): ReactElement => (
  <MessageAndRedirect message={CLEVER_ERROR_MESSAGE} redirectTo="/signup" type="error" />
);

const notAnAuthRoute = (pathname: string): boolean =>
  !['/auth', '/signup', '/privacy-policy', '/terms_of_use', '/login'].some((route) =>
    pathname.includes(route)
  );

const setLastVisitedPublicRoute = (pathname: string) => {
  if (notAnAuthRoute(pathname)) {
    sessionStorage.setItem('lastVisitedPublicRoute', pathname);
  }
};

export interface PublicRootAppProps {
  setUserAuthenticated: () => void;
}

function PublicRootApp(props: PublicRootAppProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saveUrlParameters());
  }, []);

  setLastVisitedPublicRoute(location.pathname);

  useEffect(() => {
    browserHistory.listen(({pathname}) => {
      setLastVisitedPublicRoute(pathname);
    });
  }, []);

  return (
    <div data-testid="public-root-app">
      <AppLayout
        topToolbars={
          <Suspense fallback={null}>
            <Switch>
              <Route path={['/colleges', '/edu/:slug']} component={PublicNavbar} />
            </Switch>
          </Suspense>
        }
      >
        <Notifications />
        <Suspense fallback={null}>
          <Switch>
            <Route path="/(.*)/sign-up-first" component={CollegeSearchSignUpModal} />
            <Route path="/(.*)/college-employee" component={CollegeEmployeeModal} />
          </Switch>
        </Suspense>
        <Switch>
          <Route
            path="/login"
            render={() => <Login setUserAuthenticated={props.setUserAuthenticated} />}
          />
          <Route path="/set-password" component={SetPassword} />
          <Route path="/signup/clever-error" component={ShowCleverError} />
          <Route path="/signup" component={SignUp} />
          <Route path="/terms_of_use" component={TermsOfUse} />
          <Route path="/copyright_policy" component={CopyrightPolicy} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/unsubscribe" component={Unsubscribe} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/mobile-verification-code" component={MobileVerificationCode} />
          <Route path="/reset-password/:resetToken?" component={ResetPassword} />
          <Route path="/edu/:slug" component={CollegeProfile} />
          <Route path="/colleges" component={TabbedCollegeSearch} />
          <Route
            path="*"
            render={(fallBackProps) => {
              const nextPath = fallBackProps.location.pathname + fallBackProps.location.search;
              successfulLoginRedirectPath.set(nextPath);
              return <Redirect to="/login" />;
            }}
          />
        </Switch>
      </AppLayout>
    </div>
  );
}

export default PublicRootApp;
