import React from 'react';
import PropTypes from 'prop-types';

class EmptyCollegeCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <span className={this.props.className}>
        <svg
          width="294px"
          height="252px"
          viewBox="0 0 294 252"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <rect id="path-1" x="0" y="0" width="294" height="252" rx="6"></rect>
          </defs>
          <g id="Master" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
              id="[ACDC]-College-Search----Desktop---No-Results"
              transform="translate(-701.000000, -378.000000)"
            >
              <g id="Group-31" transform="translate(701.000000, 378.000000)">
                <g id="Group-7-Copy-2">
                  <g id="Group">
                    <g id="Group-2">
                      <g id="Group-30">
                        <g id="Rectangle-6-Copy-15">
                          <use fill="#FFFFFF" fillRule="evenodd"></use>
                          <rect
                            stroke="#DBE4EB"
                            strokeWidth="1"
                            x="0.5"
                            y="0.5"
                            width="293"
                            height="251"
                            rx="6"
                          ></rect>
                        </g>
                        <rect
                          id="Rectangle-7"
                          fill="#DBE4EB"
                          x="18"
                          y="145"
                          width="256"
                          height="17.5888299"
                          rx="8.79441494"
                        ></rect>
                        <rect
                          id="Rectangle-7-Copy"
                          fill="#DBE4EB"
                          x="18"
                          y="173"
                          width="150.302805"
                          height="17.5888299"
                          rx="8.79441494"
                        ></rect>
                      </g>
                      <path
                        d="M6,0 L288,0 L288,-8.8817842e-16 C291.313708,3.08779114e-14 294,2.6862915 294,6 L294,103.96948 L0,103.96948 L0,6 L-8.8817842e-16,6 C-1.29399067e-15,2.6862915 2.6862915,-2.79460044e-16 6,-8.8817842e-16 Z"
                        id="Rectangle"
                        fill="#DBE4EB"
                        opacity="0.400000006"
                      ></path>
                      <g id="Group-18" transform="translate(15.000000, 36.000000)">
                        <rect
                          id="Rectangle-8"
                          fill="#DBE4EB"
                          x="-7.10542736e-15"
                          y="0"
                          width="62"
                          height="60"
                          rx="4"
                        ></rect>
                        <polygon
                          id="Fill-5"
                          fill="#FFFFFF"
                          points="49 13 33.7992727 13 33.7992727 21.3513315 36.9901818 21.3513315 36.9901818 39.8933545 25.0098182 39.8933545 25.0098182 21.3513315 28.2007273 21.3513315 28.2007273 13 13 13 13 21.3513315 16.7232727 21.3513315 16.7232727 42.7485952 22.9261818 49 39.0738182 49 45.2767273 42.7485952 45.2767273 21.3513315 49 21.3513315"
                        ></polygon>
                      </g>
                    </g>
                    <g id="Page-1" transform="translate(141.653465, 79.801980)"></g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
    );
  }
}

export default EmptyCollegeCard;
