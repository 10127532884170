import req, {rejectGenericError} from 'shared/req';
import {changesSaved, errorMessage, flashMessage} from 'shared/actions/notifications';
import {parseCommaSeparatedValue, submitCollegePhotos} from 'src/apps/AdminUniversity/utils';
import {deletePhotoListings, fetchPhotoListings, submitUseRanked} from './photoListing';

export const RECEIVE_UNIVERSITY = 'RECEIVE_UNIVERSITY';

export const shutdown = (formValues, universityId) => (dispatch) =>
  req({
    url: `/admin/university/${universityId}`,
    method: 'delete',
    data: formValues,
  })
    .then(() => {
      dispatch(flashMessage('Partner will be shut down'));
      setTimeout(() => {
        window.location.href = '/admin/college-listings';
      }, 3000);
    })
    .catch(rejectGenericError);

const handleUniversityDidNotSave = (dispatch) => (response) => {
  if (!(response && response.errors)) return dispatch(errorMessage('Unable to save this partner'));
  return Promise.reject({
    ...response.errors,
    joinPageSlug: response.errors.slug,
  });
};

export const saveUniversity = (values, universityId) => (dispatch) => {
  const data = {
    ...values,
    primaryMarketSubdivisions: parseCommaSeparatedValue(values.primaryMarketSubdivisions),
    primaryMarketHighSchools: parseCommaSeparatedValue(values.primaryMarketHighSchools),
    primaryMarketColleges: parseCommaSeparatedValue(values.primaryMarketColleges),
    addCollegeImages: [],
    deleteCollegeImages: [],
    useRanked: '',
    learningNeeds: values.learningNeeds.filter((value) => !!value),
  };

  submitCollegePhotos(universityId, values.addCollegeImages, dispatch)
    .catch(() => {
      dispatch(errorMessage('Oops! Something went wrong with the upload!'));
    })
    .then(() => {
      dispatch(fetchPhotoListings(universityId));
    });

  dispatch(deletePhotoListings(universityId, values.deleteCollegeImages));
  dispatch(submitUseRanked(universityId, values.useRanked));

  const url = universityId ? `/admin/university/${universityId}` : '/admin/university';
  return req({
    url,
    method: universityId ? 'patch' : 'post',
    data,
  })
    .then((updatedUniversity) => dispatch(receiveUniversity(updatedUniversity)))
    .then(() => dispatch(changesSaved()))
    .catch(handleUniversityDidNotSave(dispatch));
};

const receiveUniversity = (university) => {
  history.replaceState(
    {},
    `Editing ${university.name}`,
    `/admin/university/edit?id=${university._id}`
  );
  return {
    type: RECEIVE_UNIVERSITY,
    university: university,
  };
};
