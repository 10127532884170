import PropTypes from 'prop-types';
import React from 'react';
import {map} from 'lodash';
import StatisticsTable from './statistics-table';

class PopularMajorsTable extends React.Component {
  static propTypes = {
    college: PropTypes.object.isRequired,
  };

  getRows() {
    const {
      college: {majors},
    } = this.props;

    if (majors) {
      return map(majors, (major) => ({
        name: major.name,
        highlighted: !!major.highlighted,
        values: [`${major.percent.toFixed(1)}%`],
      }));
    }

    return [];
  }

  render() {
    const rows = this.getRows();
    return <StatisticsTable caption="Popular Majors" rows={rows} />;
  }
}

export default PopularMajorsTable;
