import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CheckboxGroupFilter from 'shared/components/filters/checkbox-group-filter';
import LocationFilter from 'shared/components/filters/location-filter';
import Icon from 'shared/components/icon';
import LocationCheckboxFilter from 'src/components/CollegeSearch/Filters/LocationCheckboxFilter';
import {useBoolean} from 'src/hooks/useBoolean';
import {Store} from 'src/types/store';
import {getBrowserLocation} from '../../../../utils/geolocation';
import {expandFilter} from '../../../../../shared/actions/filters';
import InfoTooltip from '../../../../../shared/components/info-tooltip';
import {getCurrentLocationFilter} from '../../../../redux/collegeSearch/actions';
import TextButton from '../../../TextButton';
import ExpandableFilter from '../expandable-filter';
import RadiusDropdown from '../radius-dropdown';

export const LOCATIONS = {
  'non-us': 'Non-US Only',
};

interface Props {
  getColleges: () => void;
  transfer: boolean;
  adjustRadiusFilter: (radius: string) => void;
  removeCityFilter: (city: object | undefined) => void;
  filters: object[];
}

const LocationFilters = ({
  getColleges,
  transfer,
  adjustRadiusFilter,
  filters,
  removeCityFilter,
}: Props) => {
  const [showRadius, setShowRadius] = useState(false);
  const authenticated = useSelector((state: Store) => state.authenticated);
  const loading = useBoolean(false);
  const dispatch = useDispatch();
  const locationFilter = filters['location'];
  const hasCityFilter = locationFilter?.some((location) => location['city']);
  const errorRetrievingLocation = useBoolean(false);

  useEffect(() => {
    const updatePermissionStatus = (permissionStatus: PermissionStatus) => {
      switch (permissionStatus.state) {
        case 'denied':
          errorRetrievingLocation.setTrue();
          break;
        case 'granted':
          errorRetrievingLocation.setFalse();
          break;
      }
    };

    navigator?.permissions?.query({name: 'geolocation'})?.then((permissionStatus) => {
      updatePermissionStatus(permissionStatus);

      permissionStatus.onchange = () => {
        updatePermissionStatus(permissionStatus);
      };
    });
  }, []);

  useEffect(() => {
    if (hasCityFilter) {
      setShowRadius(true);
    } else {
      setShowRadius(false);
    }
  }, [locationFilter]);

  const fetchBrowserCity = async () => {
    try {
      loading.setTrue();
      removeCityFilter(locationFilter);
      await setCurrentLocationFilter();
      loading.setFalse();
    } catch (error) {
      console.error('Error getting browser location:', error);
    }
  };

  const setCurrentLocationFilter = async () => {
    try {
      const currentLocationFilter = await getCurrentLocationFilter(getBrowserLocation);

      dispatch(
        expandFilter({
          name: 'location',
          value: currentLocationFilter,
        })
      );
      getColleges();
    } catch (error) {
      console.error('Error getting browser location:', error);
      errorRetrievingLocation.setTrue();
    }
  };

  return (
    <div className="college-search-filters-section">
      <fieldset role="group" aria-label="Filter by Location">
        <legend className="college-search-filters-section-legend">
          <label className="college-search-filters-section-label" htmlFor="typeahead-location">
            Location
          </label>
        </legend>
        <div className="college-search-filters-section-typeahead pb-0">
          <LocationFilter
            noZip
            onChange={getColleges}
            filters={filters}
            removeCityFilter={removeCityFilter}
            adjustRadiusFilter={adjustRadiusFilter}
          />
        </div>
        <TextButton
          margin={false}
          disabled={loading.value || errorRetrievingLocation.value}
          onClick={fetchBrowserCity}
          className="use-location-button"
        >
          <Icon iconType="map-marker-alt" className="icon" />
          <span className="shared-font bold">
            {loading.value ? ' Getting Browser Location...' : ' Current Location'}
            {errorRetrievingLocation.value && (
              <InfoTooltip>
                Location sharing is currently disallowed for this website. To enable location
                sharing for this site, you must turn on location sharing in your device settings.
                Click{' '}
                <a
                  target="_blank"
                  href="https://help.raise.me/hc/en-us/articles/24964537288091-How-to-Update-Your-Location-Sharing-Settings"
                  rel="noreferrer"
                >
                  here
                </a>{' '}
                for instructions on how.
              </InfoTooltip>
            )}
          </span>
        </TextButton>
        {!transfer && (
          <LocationCheckboxFilter options={{'non-us': 'Non-US Only'}} onChange={getColleges} />
        )}
        {showRadius && (
          <RadiusDropdown
            getColleges={getColleges}
            adjustRadiusFilter={adjustRadiusFilter}
            filters={filters}
          />
        )}
        <ExpandableFilter filterType="Location" startClosed={authenticated}>
          <div className="college-search-subdivider"></div>
          <CheckboxGroupFilter
            options={{City: 'City', Suburb: 'Suburb', Town: 'Small town', Rural: 'Rural'}}
            name="settings"
            onChange={getColleges}
          />
        </ExpandableFilter>
      </fieldset>
    </div>
  );
};

export default LocationFilters;
