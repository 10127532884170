import {fetchExperimentTreatmentFor} from 'shared/actions/experiments';
import {fetchFeatureFlags} from 'shared/actions/feature-flags';
import {startLoading, stopLoading} from 'shared/actions/loading';
import {
  getOpportunityFeatureFlag,
  getPopularActivityTreatment,
} from 'shared/micro-scholarships/selectors';
import {Action} from 'src/actions/utils';
import {ActiveExperiments} from 'src/services/ExperimentService';
import MicroScholarshipService from 'src/services/MicroScholarshipService';

export const RECEIVE_OPPORTUNITY_CARDS = 'RECEIVE_OPPORTUNITY_CARDS';
export const RECEIVE_OPPORTUNITY_CARD = 'RECEIVE_OPPORTUNITY_CARD';
export const LOADING_IDENTIFIER = 'microscholarships';

export const receiveOpportunityCards = (cards) => Action(RECEIVE_OPPORTUNITY_CARDS, {cards});
export const receiveOpportunityCard = (opportunityCard) =>
  Action(RECEIVE_OPPORTUNITY_CARD, {opportunityCard});

const getHighSchoolActivities = async (dispatch, getState) => {
  await dispatch(fetchFeatureFlags());

  const showPopularActivities = getOpportunityFeatureFlag(getState());

  if (showPopularActivities) {
    await dispatch(
      fetchExperimentTreatmentFor(
        ActiveExperiments.PopularActivities.name,
        ActiveExperiments.PopularActivities.control
      )
    );

    const treatment = getPopularActivityTreatment(getState());
    const cards = await MicroScholarshipService.getPopularActivities(treatment);
    return cards;
  }

  return [];
};

export const fetchOpportunityCards = () => async (dispatch, getState) => {
  dispatch(startLoading(LOADING_IDENTIFIER));

  const [highSchoolActivities, opportunities] = await Promise.all([
    getHighSchoolActivities(dispatch, getState),
    MicroScholarshipService.getOpportunityCards(),
  ]);

  const cards = highSchoolActivities.concat(opportunities);

  dispatch(stopLoading(LOADING_IDENTIFIER));
  return dispatch(receiveOpportunityCards(cards));
};

export const fetchOpportunityCard = (opportunityCardType) => async (dispatch) => {
  opportunityCardType = opportunityCardType.charAt(0).toUpperCase() + opportunityCardType.substr(1);
  const activity = await MicroScholarshipService.getOpportunityCard(opportunityCardType);
  dispatch(receiveOpportunityCard(activity));
};
