import {connect} from 'react-redux';
import {saveNotification, saveNotifications} from '../actions/notification-settings';
import NotificationSettingsForm from '../components/notification-settings-form';

function mapStateToProps(state, ownProps) {
  return {
    notificationSettings: ownProps.notificationSettings || state.notificationSettings,
  };
}

export default connect(mapStateToProps, {saveNotification, saveNotifications})(
  NotificationSettingsForm
);
