import React from 'react';
import PropTypes from 'prop-types';
import Criterion from './Criterion';
import OrCriterion from './OrCriterion';
import HighSchoolCriterion from './HighSchoolCriterion';

// This component returns the appropriate criterion component
// based on the passed in criterion's criterionType
class CriterionFactory extends React.Component {
  static propTypes = {
    criterion: PropTypes.object.isRequired,
    onHighSchoolListClick: PropTypes.func,
  };

  render() {
    const {criterion, onHighSchoolListClick} = this.props;
    let criteria;
    let criterionType;
    let displayString;

    // this logic is used for setting props correctly depending on if the criterion is an
    // And/Or criterion, child of nestedCriteria, or a regular non-nested criterion
    if (criterion.nestedCriteria) {
      criteria = criterion.nestedCriteria;
      criterionType = criterion.criterionType;
    } else {
      criterionType = criterion.criterionType;
      displayString = criterion.displayString;
    }

    let criterionComponent = null;

    if (criterionType === 'or') {
      criterionComponent = (
        <OrCriterion criteria={criteria} onHighSchoolListClick={onHighSchoolListClick} />
      );
    } else if (['Target High Schools', 'Ineligible High Schools'].indexOf(criterionType) > -1) {
      if (onHighSchoolListClick) {
        criterionComponent = (
          <HighSchoolCriterion
            criterionType={criterionType}
            displayString={displayString}
            id={criterion.id}
            onHighSchoolListClick={onHighSchoolListClick}
          />
        );
      }
    } else {
      criterionComponent = (
        <Criterion criterionType={criterionType} displayString={displayString} />
      );
    }

    return criterionComponent;
  }
}

export default CriterionFactory;
