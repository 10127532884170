import {activity, activities} from './activities';
import {course, courses} from './courses';
import {collegeCourses} from './college-courses';
import {institutions} from './shared';
import {communityServiceEvents} from './community-service';
import workExperiences from './work-experiences';
import testScores from './test-scores';
import {collegeEvents} from './college-events';
import {honorAwards} from './honor-awards';
import {multimediaItems, multimedia} from './multimedia';

export default {
  activity,
  activities,
  course,
  courses,
  collegeCourses,
  institutions,
  collegeEvents,
  honorAwards,
  communityServiceEvents,
  workExperiences,
  testScores,
  multimediaItems,
  multimedia,
};
