import PropTypes from 'prop-types';
import React from 'react';

class LabeledHorizontalPlot extends React.Component {
  static propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    labelText: PropTypes.string.isRequired,
    labelValue: PropTypes.number.isRequired,
  };

  render() {
    const {max, min, value, labelText, labelValue} = this.props;

    if (max === 0) {
      return null;
    }

    const scaledMax = max - min;
    const valueScaled = (value / scaledMax) * 100;
    const labelValueScaled = (labelValue / scaledMax) * 100;
    const lineLength = '14px';
    const labelOffset = '15px';

    // Decorative graph so aria-hidden is set to true.
    return (
      <svg className="labeled-horizontal-plot" width="100%" height="30px" aria-hidden="true" alt="">
        <rect
          height="10px"
          width="100%"
          rx="6"
          ry="6"
          className="labeled-horizontal-plot-background"
        />
        <rect
          className="labeled-horizontal-plot-box"
          height="10px"
          x="0"
          rx="4"
          ry="4"
          width={`${valueScaled}%`}
        />
        <line
          className="labeled-horizontal-plot-line"
          x1={`${labelValueScaled}%`}
          x2={`${labelValueScaled}%`}
          y1="0"
          y2={lineLength}
        />
        <text
          className="labeled-horizontal-plot-label"
          textAnchor="middle"
          x={`${labelValueScaled}%`}
          y={labelOffset}
          dy="1em"
        >
          {labelText}
        </text>
      </svg>
    );
  }
}

export default LabeledHorizontalPlot;
