import {clone} from 'lodash';
import {TOGGLE_NOTIFICATION, RECEIVE_NOTIFICATION_SETTINGS} from '../actions/notification-settings';

export default function notificationSettingsReducer(state = {}, action) {
  switch (action.type) {
    case TOGGLE_NOTIFICATION: {
      const {notificationTopic, channel, enabled} = action;
      const notificationSetting = clone(state[notificationTopic]) || {};
      notificationSetting[channel] = enabled;
      return {
        ...state,
        [notificationTopic]: notificationSetting,
      };
    }
    case RECEIVE_NOTIFICATION_SETTINGS:
      return action.notificationSettings;
    default:
      return state;
  }
}
