import {RefObject, useEffect, useRef, useState} from 'react';
import {Dimensions, initialDimensions} from 'src/utils/helpers';

/**
 * This hook uses the ResizeObserver class to watch for size
 * changes on the incoming reference.
 */
export function useResizeObserver(ref: RefObject<HTMLElement | null>): Dimensions {
  const [dimensions, setdDimensions] = useState<Dimensions>(initialDimensions);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries: ResizeObserverEntry[] = []): void => {
      entries.forEach((entry) => {
        setdDimensions({
          width: entry.borderBoxSize[0].inlineSize,
          height: entry.borderBoxSize[0].blockSize,
        });
      });
    });

    if (ref.current) resizeObserverRef.current.observe(ref.current);

    return (): void => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [ref.current]);

  return dimensions;
}
