import req from 'shared/req';
import {getUserId} from 'shared/notification-center/actions/user';
import JWTService from 'src/services/JWTService';

export const NOTIFICATION_BUNDLES_EVENT = 'NOTIFICATION_BUNDLES_EVENT';

export const buildNotificationBundlesEvent = () => () => {
  JWTService.token().then((token) => {
    getUserId().then((userId) =>
      req({
        method: 'POST',
        url: `/v1/bundle-events/${userId}`,
        headers: {Authorization: `Bearer ${token}`},
      }).catch((buildNotificationBundlesEventErr) => {
        console.error({buildNotificationBundlesEventErr});
      })
    );
  });
};
