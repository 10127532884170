import PropTypes from 'prop-types';
import React from 'react';

class SectionHeader extends React.Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const {title, subtitle, className, onClick} = this.props;

    return (
      <header
        className={`college-profile-section-header ${className ? className : ''}`}
        onClick={onClick}
      >
        <div className="college-profile-section-header-title">{title}</div>
        <div className="college-profile-section-header-subtitle">{subtitle}</div>
      </header>
    );
  }
}

export default SectionHeader;
